import { Box, Typography, IconButton } from "@mui/material";
import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from "@mui/material/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description"; // For CSV
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { api } from "../../utils/api";

const FileField = ({
  handleChange,
  handleDelete,
  setUploadProgress,
  setUploadedFiles,
  title,
  name,
  setErrors,
  errors,
  fileUrls,
  progress,
}) => {
  const imageFiles = fileUrls.length > 0 && fileUrls.filter((url) => {
    const fileExtension = url.split(".").pop().toLowerCase();
    return ["png", "jpg", "jpeg"].includes(fileExtension);
  });

  const documentFiles = fileUrls.length > 0 && fileUrls.filter((url) => {
    const fileExtension = url.split(".").pop().toLowerCase();
    return ["csv", "xls", "xlsx"].includes(fileExtension);
  });

  const formatFileName = (fileName) => {
    return decodeURIComponent(fileName);
  };

  const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;
        resolve({ width, height });
      };
      img.onerror = () => {
        reject(new Error("Failed to load image"));
      };
    });
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: [] }));

    if (!files || files.length === 0) return;

    if (fileUrls.length + files.length > 5) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: ["You cannot upload more than 5 files."],
      }));
      return;
    }

    const newErrors = [];
    const uploaded = [];
    const progressTracker = Array(files.length).fill(0);

    if (files.length > 5) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: ["You can only upload a maximum of 5 files."],
      }));
      return;
    }

    const allowedTypes = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "image/png",
      "image/jpeg",
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!allowedTypes.includes(file.type)) {
        newErrors.push(`File type not allowed: ${file.name}`);
        continue;
      }

      if (file.size > maxFileSize) {
        newErrors.push(`File size exceeds 5MB: ${file.name}`);
        continue;
      }

      if (file.type.startsWith("image/")) {
        const { width, height } = await checkImageDimensions(file);
        // if (width !== 1200 || height !== 800) {
        //   newErrors.push(`Image dimensions must be 1200x800 pixels: ${file.name}`);
        //   continue;
        // }
      }

      const formData = new FormData();
      formData.append("files", file);

      try {
        const res = await axios.post(`${api}/cloud/upload`, formData, {
          onUploadProgress: (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            progressTracker[i] = percent;
            setUploadProgress((prev) => ({ ...prev, [name]: progressTracker }));
          },
        });
        uploaded.push(...res.data.urls);
      } catch (error) {
        newErrors.push(`Failed to upload documents. Please try again.`);
      }
    }

    setUploadedFiles((prev) => [...prev, ...uploaded]);
    setUploadProgress((prev) => ({ ...prev, [name]: [] }));

    if (newErrors.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: newErrors }));
    }
  };

  const handleDeleteFile = (fileUrl) => {
    setUploadedFiles((prev) => prev.filter((file) => file !== fileUrl));
  };

  return (
    <Box>
      <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 1, mt: 2 }}>
        {title}
      </Typography>

      {/* Display images first */}
      {imageFiles.length > 0 && (
        <Box mb={2} sx={{ width: "100%" }}>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {imageFiles.map((fileUrl, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  backgroundColor: "#f9f9f9",
                  width: "100px",
                }}
              >
                <img
                  src={fileUrl}
                  alt={`Document ${index + 1}`}
                  style={{
                    width: "100px",
                    height: "80px",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
                <IconButton
                  onClick={() =>
                    handleDelete ? handleDelete(name, fileUrl) : handleDeleteFile(fileUrl)
                  }
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    width: "20px",
                    height: "20px",
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.7)",
                    },
                  }}
                >
                  <CloseIcon sx={{ fontSize: "10px" }} />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {/* Display documents below images */}
      {documentFiles.length > 0 && (
        <Box mb={2} sx={{ width: "100%" }}>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {documentFiles.map((fileUrl, index) => {
              const fileName = formatFileName(fileUrl.split("/").pop());
              const fileExtension = fileName.split(".").pop().toLowerCase();
              const isCsv = fileExtension === "csv";
              const isExcel = ["xls", "xlsx"].includes(fileExtension);

              return (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    backgroundColor: "#f9f9f9",
                    width: "45%",
                    minWidth: "100px",
                    overflowX: "hidden"
                  }}
                >
                  {isCsv ? (
                    <Box sx={{ display: "flex", alignItems: "center", padding: 1, width: "100%" }}>
                      <DescriptionIcon sx={{ fontSize: "20px", color: "#1A73E8", marginRight: "5px" }} />
                      <Typography variant="caption" noWrap sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                        {fileName}
                      </Typography>
                    </Box>
                  ) : isExcel ? (
                    <Box sx={{ display: "flex", alignItems: "center", padding: 1, width: "100%" }}>
                      <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: "5px", color: "#0a8f08" }} />
                      <Typography variant="caption" noWrap sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                        {fileName}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{
                      width: "80px",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginBottom: "10px",
                      backgroundColor: "#f5f5f5",
                    }}>
                      <Typography variant="caption" noWrap>{fileName}</Typography>
                    </Box>
                  )}
                  <IconButton
                    onClick={() =>
                      handleDelete ? handleDelete(name, fileUrl) : handleDeleteFile(fileUrl)
                    }
                    sx={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      color: "white",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      width: "20px",
                      height: "20px",
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.7)",
                      },
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "10px" }} />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}

      {progress > 0 && (
        <Box sx={{ mt: 1, mb: 1, width: "100%" }}>
          <LinearProgress value={progress} sx={{ width: "100%" }} />
        </Box>
      )}

      <Box
        sx={{
          border: "1px dashed grey",
          borderRadius: 1,
          p: 2,
          textAlign: "center",
          cursor: "pointer",
          width: "100%",
        }}
      >
        <input
          type="file"
          hidden
          id={`upload-document-${name}`}
          name={name}
          onChange={handleChange ? handleChange : handleFileChange}
          multiple
        />
        <label htmlFor={`upload-document-${name}`}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CloudUploadIcon />
            <Typography>Drop files here or click to upload</Typography>
            <Typography variant="caption">
              Upload your file (CSV, Excel, PNG, max 5 MB, recommended size 1200x800 pixels).
            </Typography>
          </Box>
        </label>
      </Box>

      {errors && (
        <Typography variant="body2" color="#D34040">
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default FileField;
