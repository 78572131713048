import { Box, Card, CardContent, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
 
const SelectCard = ({title,content,list,value,handleChange}) => {
  return (
    <Card sx={{ width: "30%", display: "flex", flexDirection: "column",height:"140px",borderRadius:"10px" }}>
    <CardContent sx={{ paddingBottom: "2px !important" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <FormControl variant="standard" sx={{ minWidth: 80 }}>
          <Select
            value={value}
            onChange={handleChange}
            disableUnderline
            sx={{
              border: "1px solid #ccc",
              borderRadius: "4px",    
              padding: "1px 6px",      
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Set max height (around 5 items)
                  overflowY: 'auto', // Enable vertical scrolling
                },
              },
            }}
          >
            {list.map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography  sx={{marginTop: "15%"}}>{content}</Typography>
    </CardContent>
  </Card>
  )
}
 
export default SelectCard