import React from 'react';
import DashboardLayout from '../../../../Layout/DashboardLayout';
import ElectricityConsumptionByFacility from './ConsumptionByEquipment';
const index = () => {
    return (
        <DashboardLayout>
            {/* <ElectricityConsumption /> */}
            <ElectricityConsumptionByFacility/>
        </DashboardLayout>
    )
}

export default index;
