import logo from './logo.svg';
import './App.css';
import Home from './Home';
import { UserProvider } from './Components/Quetionary/ContextAPI';
import { GlobalStyles } from "@mui/material";

function App() {
  return (
    <div className="App">
      <GlobalStyles
        styles={{
          html: {
            scrollBehavior: "smooth",
            "-webkit-overflow-scrolling": "touch",
            overscrollBehavior: "none",
          },
          body: {
            overscrollBehavior: "none",
            margin: 0,
            padding: 0,
            overflow: "hidden", // Disable body scroll
          },
          "@supports (-ms-ime-align: auto)": {
            body: {
              overflowY: "hidden",
            },
            ".scrollable-container": {
              overflowY: "auto",
            },
          },
        }}
      />
      <UserProvider>
      <Home/>
      </UserProvider>
    </div>
  );
}

export default App;
