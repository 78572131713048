import React, { useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";
import { ProgressContext } from "../ContextAPI";
import { numberError, percentageError } from "../../../utils/validationMessage";
import {
  validateNumber,
  validatePercentage,
} from "../../../utils/validateFields";

const WaterManagement = () => {
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    totalWaterWithdrawal: { value: "" },
    waterIntensity: { value: "" },
    waterRecycled: { value: "" },
    waterDischargeQuality: { value: "" },
    companyId,
  });
  const { setUpdate } = useContext(ProgressContext);

  const [errors, setErrors] = useState({
    totalWaterWithdrawal: "",
    waterIntensity: "",
    waterRecycled: "",
    waterDischargeQuality: "",
  });

  // water data for creating input cards
  const waterData = [
    {
      title: "Total Water Withdrawal",
      placeholder: "1000",
      name: "totalWaterWithdrawal",
      adornmentText: "Cubic meter",
    },
    {
      title: "Water Intensity",
      placeholder: "0.5",
      name: "waterIntensity",
      adornmentText: "Water use per unit of output",
    },
    {
      title: "Water Recycled and Reused",
      placeholder: "64",
      name: "waterRecycled",
      adornmentText: "% of total water use",
    },
    {
      title: "Water Discharge Quality",
      placeholder: "8",
      name: "waterDischargeQuality",
      adornmentText: "Compliance with local standards",
    },
  ];

  // navigate back
  const Back = () => {
    navigate("/environment/ghg-emissions");
  };

  // validating input fields
  const validateForm = () => {
    const {
      totalWaterWithdrawal,
      waterIntensity,
      waterRecycled,
      waterDischargeQuality,
    } = formData;

    let newErrors = {};

    // Total Water Withdrawal Validation
    if (!validateNumber(totalWaterWithdrawal.value)) {
      newErrors.totalWaterWithdrawal = numberError;
    }

    // Water Intensity Validation
    if (!validateNumber(waterIntensity.value)) {
      newErrors.waterIntensity = numberError;
    }

    // Water Recycled and Reused Validation
    if (!validatePercentage(waterRecycled.value)) {
      newErrors.waterRecycled = percentageError;
    }

    // Water Discharge Quality Validation
    if (!validateNumber(waterDischargeQuality.value)) {
      newErrors.waterDischargeQuality = numberError;
    }

    setErrors(newErrors);

    // Check if there are any errors
    return Object.keys(newErrors).length === 0;
  };

  // fetching data if already exists
  useEffect(() => {
    setUpdate("ESG");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/water-management/${companyId}`
        );
        console.log(response);
        if (response.data && response.data.waterData) {
          setFormData(response.data.waterData);
          setIsExist(true);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // Save the formData and make the post request
  const Save = async () => {
    console.log(formData);
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/water-management${
          isExist ? `/${companyId}` : ""
        }`,
        data: { ...formData, companyId },
      });
      // alert(response.data.message);
      navigate("/environment/waste-management");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);
    }
  };

  // Handling the input value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: {
          ...prevFormData[name],
          value: value ,
        },
      };
    });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error on change
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#EDEDED",
        height: "100vh",
        overflow: "hidden", // Ensure no content gets hidden by scrollbars
      }}
    >
      {" "}
      <Sidebar title="Water Management" />
      <Box
        sx={{
          p: 2,
          mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            zIndex: 1,
            padding: "10px",
            borderRadius: "4px",
            alignSelf: "flex-start",
            ml: "5rem",
          }}
        >
          ENVIRONMENT: WATER MANAGEMENT
        </Typography>
        <Box sx={{ mt: 3, width: "80%" }}>
          {/* Maps the water data */}
          {waterData.map((item, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <InputCard
                title={item.title}
                placeholder={item.placeholder}
                name={item.name}
                value={formData[item.name].value}
                handleChange={handleChange}
                adornmentText={item.adornmentText}
                borderColor={errors[item.name] && "#D34040"}
              />
              {errors[item.name] && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {errors[item.name]}
                </Typography>
              )}
            </Box>
          ))}

          {/* Back, Save Buttons */}
          <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};

export default WaterManagement;
