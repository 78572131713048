import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  Grid,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DocumentItem from "../../../../../Components/Masters/DocumentItem";
import ViewDate from "../../../../../Components/Masters/ViewDate";
import { formatDate } from "../../../../../utils/formatDate";
import { Data } from "@react-google-maps/api";

function ViewVehicle({ inputData, data, viewData, setViewData, handleEdit }) {
  const [uploadedFiles, setUploadedFiles] = useState({
    pucDocs: [],
    pollutionDocs: [],
    rcDocs: [],
    insuranceDocs: [],
  });

  const [filteredData, setFilteredData] = useState({});
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);

  // const handleDownload = (url) => {
  //   const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/");
  //   const link = document.createElement("a");
  //   link.href = downloadUrl;
  //   document.body.appendChild(link);
  //   link.click();
  //   link.parentNode.removeChild(link);
  // };

  // const formatDate = (date) => {
  //   if (!date) return "-";
  //   const d = new Date(date);
  //   return `${String(d.getDate()).padStart(2, "0")}/${String(
  //     d.getMonth() + 1
  //   ).padStart(2, "0")}/${String(d.getFullYear()).slice(-2)}`;
  // };

  useEffect(() => {
    if (data) {
      const { pucDocs, pollutionDocs, rcDocs, insuranceDocs, ...filteredData } =
        data;

        console.log(data)
      setUploadedFiles({
        pucDocs: pucDocs || [],
        pollutionDocs: pollutionDocs || [],
        rcDocs: rcDocs || [],
        insuranceDocs: insuranceDocs || [],
      });
      setIsDocumentUploaded(
        pucDocs.length > 0 ||
          pollutionDocs.length > 0 ||
          rcDocs.length > 0 ||
          insuranceDocs.length > 0
      );

      setFilteredData(filteredData);
    }
  }, [data]);

  const list = () => {
    const currentDate = new Date().toLocaleDateString(); // Define the new Date field
  
    return (
      <Box
        display="flex"
        gap={3}
        sx={{
          width: "400px",
          maxWidth: "100%",
          height: "100vh",
          justifyContent: "space-between",
          backgroundColor: "transparent",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            height: "3%",
            padding: "13px",
            margin: "30px 0 0 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "80%",
            width: "5%",
          }}
        >
          <IconButton onClick={() => setViewData(false)} sx={{ color: "black" }}>
            <CloseIcon sx={{ fontSize: "1.2rem" }} />
          </IconButton>
        </Box>
        <Box
          sx={{ p: 3, width: "100%", backgroundColor: "#fff", overflow: "auto" }}
        >
          <Typography
            variant="h6"
            sx={{
              textDecoration: "underline",
              fontWeight: "bold",
              mb: 2,
            }}
          >
            Vehicle Details
          </Typography>
  
          <Grid container spacing={2}>
            {/* Render the new Date field at the beginning */}
            <Grid item xs={12} sm={6}>
              <ViewDate data={data}/> {/* Display the current date */}
            </Grid>
  
            {/* Render other fields excluding the date fields */}
            {inputData.map((input) => {
              const value = filteredData?.[input.fieldName];
  
              if (value === undefined || value === null || value==="") return null;
  
              let displayValue;
  
             if (
                (input.fieldName === "purchaseDate" ||
                  input.fieldName === "lastServiceDate") &&
                !isNaN(Date.parse(value))
              ) {
                displayValue = formatDate(value);
              }  else {
                displayValue = value;
              }
  
              return (
                <Grid item xs={12} sm={6} key={input.fieldName}>
                  <Tooltip title={input.title} arrow>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {input.title}
                    </Typography>
                  </Tooltip>
                  <div>
                    {Array.isArray(input.options)
                      ? input.options.includes(displayValue)
                        ? displayValue
                        : "-"
                      : displayValue ?? "-"}
                  </div>
                </Grid>
              );
            })}
          </Grid>
  
          {isDocumentUploaded && (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mt: 2, textDecoration: "underline" }}
              >
                Documents
              </Typography>
              {Object.entries(uploadedFiles).map(
                ([key, files]) =>
                  files?.length > 0 && (
                    <Box key={key} sx={{ mt: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold"}}
                      >
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .trim()
                          .replace(/^./, (str) => str.toUpperCase())}
                      </Typography>
  
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        {files
                          .filter((url) =>
                            ["png", "jpg", "jpeg"].includes(
                              url.split(".").pop().toLowerCase()
                            )
                          )
                          .map((url, index) => (
                            <DocumentItem key={`image-${index}`} url={url} />
                          ))}
                      </Box>
  
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        {files
                          .filter(
                            (url) =>
                              !["png", "jpg", "jpeg"].includes(
                                url.split(".").pop().toLowerCase()
                              )
                          )
                          .map((url, index) => (
                            <DocumentItem key={`file-${index}`} url={url} />
                          ))}
                      </Box>
                    </Box>
                  )
              )}
            </Box>
          )}
  
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10%",
            }}
          >
           <Button
        variant="contained"
        sx={{
          marginTop: '20px',
          backgroundColor: '#1c486b',
          color: '#FFFFFF',
          '&:hover': { backgroundColor: '#003366' },
          width: '10%',
          position:"relative",
          bottom:0
         
        }}
      onClick={handleEdit}
            >
              Edit
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
  
  return (
    <Drawer
      anchor="right"
      open={viewData}
      onClose={() => setViewData(false)}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      {list()}
    </Drawer>
  );
}

export default ViewVehicle;
