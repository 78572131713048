import React from "react";
import { Box, Chip, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const MaterialChips = ({ selected, category, handleDelete }) => {
  const maxChipsToShow = 10;

  const data = selected
    .slice(0, maxChipsToShow)
    .filter((value) => value !== "Select");
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 0.5,
        color: "white",
        mt: 2,
        mb: data.length > 0 ? 5 : 2,
      }}
    >
      {data.map((value) => (
        <Chip
          key={value}
          label={value}
          onDelete={() => handleDelete(value, category)}
          sx={{
            bgcolor: "#1C486B",
            color: "white",
            "& .MuiChip-deleteIcon": {
              color: "white",
              "&:hover": {
                color: "white",
              },
            },
          }}
          deleteIcon={
            <IconButton sx={{ color: "white" }}>
              <CancelIcon />
            </IconButton>
          }
        />
      ))}
    </Box>
  );
};

export default MaterialChips;
