import React from "react";
import DashboardLayout from "../../../../Layout/DashboardLayout";
import CO2EmissionEstimator from "./CO2EmissionEstimator";
const index = () => {
  return (
    <DashboardLayout>
      <CO2EmissionEstimator />
      {/* <ElectricityConsumptionByFacility/> */}
    </DashboardLayout>
  );
};

export default index;
