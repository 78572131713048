import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from '../Masters/MasterSidebar/Layout';

const DashboardRoutes = () => {
  const location = useLocation();
  // Check if the path starts with /masters
  const showLayout = location.pathname.startsWith('/setup');

  return (
    <div className='d-flex'>
      {showLayout && <Layout />}
      <Routes>
        <Route path="/overview" element={""}/>
        <Route path="/water-management" element={""}/>
        <Route path="/waste-management" element={""}/>
        <Route path="/scope-1" element={""}/>
        <Route path="/scope-2" element={""}/>
        <Route path="/scope-3" element={""}/>
        <Route path="/setup/*" element={""}/> {/* Note: Added wildcard for nested routes */}
        <Route path="/support" element={""}/>
        <Route path="/documentation" element={""}/>
      </Routes>
    </div>
  );
};

export default DashboardRoutes;
