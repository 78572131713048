import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, styled, Box, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import VerifyOtp from './VerifyOtp';
import InputField from './InputField';
import { formatTime } from '../../utils/formatTime';
import { api } from '../../utils/api';
import axios from 'axios';


const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        height: "40px",
    },
}));

const EmailField = ({title, value,fieldName, placeholder,element,setFormData,formData, showIcon,setErrors,errors,verify,setVerify,verifybtn,setverifybtn,TaskAltIcons,setTaskAltIcons}) => {

    const [otp, setOTP] = useState({
        email: null,
      });

//       const [verifybtn, setverifybtn] = useState({ email: false, phone: false });
//   const [verify, setVerify] = useState({
//     phone: false,
//     phoneMsg: "",
//   });

  const [timerEmail, setTimerEmail] = useState(120);
  const [timerActive, setTimerActive] = useState({
    email: false,
  });

  useEffect(() => {
    if (timerActive.email && timerEmail > 0) {
      const timer = setInterval(() => {
        setTimerEmail((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setTimerActive((prev) => ({ ...prev, email: false }));
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timerActive.email]);


  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value)

    setTaskAltIcons({ ...TaskAltIcons, email: false });
    setVerify({ ...verify, email: false, emailMsg: "" });
    setverifybtn({ ...verifybtn, email: false });
    setTimerEmail(120);
    setErrors({ ...errors, email: "" });
    setOTP((prevOtp) => ({
      ...prevOtp,
      email: "", // Update the OTP value
    }));
    // setEmail(value);
    const trimmedValue = value.trim();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if the email matches the pattern
    console.log(emailPattern.test(trimmedValue), trimmedValue);
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });

    if (value === element?.email) {
      setTaskAltIcons({ ...TaskAltIcons, email: true });
      return;
    }
    // setErrorMessage(""); // Clear the error message if the email is valid
    if (emailPattern.test(trimmedValue)) {
      console.log("Invalid email format");
      setverifybtn({ ...verifybtn, email: true });
    }
  };

  const handleVerifyEmail = async (time) => {
    if (time > 0) {
      // setVerify({...verify,email:true})
      setverifybtn({ ...verifybtn, email: false });
      setTimerActive((prev) => ({ ...prev, email: true }));
      await axios
        .post(`${api}/auth/sendOtpToEmail`, {
          email: formData.email,
          module: "Purchase",
        })
        .then((res) => {
          console.log("resForSendOtpEmail", res);
          // setOTP({...otp,})
          if (res.status === 200) setVerify({ ...verify, email: true });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.statusCode === false) {
            setErrors({ ...errors, email: "Email already registered." });
            // setVerify({ ...verify, email: true });
            // setverifybtn({ ...verifybtn, email: false });
          }
        });
    } else {
      setVerify({ ...verify, email: false });
      setverifybtn({ ...verifybtn, email: false });
    }
  };
  const handleResend = () => {
    setTimerEmail(120); // Reset time to 120 seconds
    setTimerActive((prev) => ({ ...prev, email: true })); // Start the timer
    handleVerifyEmail(120); // Call the email verification function
  };


  const handleOTPVerify = (e) => {
    const { value } = e.target;

    // Only allow numeric values and ensure length does not exceed 4
    const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion

    // Update state only if the value is numeric and has a length <= 4
    if (isNumeric && value.length <= 4) {
      setVerify({ ...verify, emailMsg: "" });
      setOTP((prevOtp) => ({
        ...prevOtp,
        email: value, // Update the OTP value
      }));
      // Trigger API call when exactly 4 digits are entered
      if (value.length === 4) {
        axios
          .post(`${api}/auth/verifyOtpForEmail`, {
            email: formData.email,
            otp: value,
          })
          .then((res) => {
            console.log("OTP verified successfully:", res, res.status);
            if (res.status === 200) {
              setVerify({ ...verify, email: false });
              setverifybtn({ ...verifybtn, email: false });
              setTaskAltIcons({ ...TaskAltIcons, email: true });
              setErrors({ ...errors, email: "" });
            }
          })
          .catch((err) => {
            console.error("Error verifying OTP:", err);
            setVerify({ ...verify, emailMsg: "Invalid OTP" });
            // setverifybtn({ ...verifybtn, email: true });
            setTaskAltIcons({ ...TaskAltIcons, email: false });
          });
      }
    }
  };

  return (
    <Box>
    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        {title}
    </Typography>
    <CustomTextField
        variant="outlined"
        fullWidth
        value={value}
        placeholder={placeholder}
    name={fieldName}
        // disabled={!countryCode}
        onChange={handleEmailChange} // Use the new input change handler
        InputProps={{
            endAdornment: (
                showIcon && <TaskAltIcon sx={{ color: "green" }} />
            ),
        }}
    />
    {errors && (
        <Typography variant="body2" color="#D34040">
            {errors}
        </Typography>
    )}
    {verifybtn.email && formData.email !== "" && (
            <VerifyOtp
              handleChange={() => handleVerifyEmail(120)}
              text={"Verify"}
            />
          )}
          {verify.email && (
            <Box>
              <InputField
                value={otp.email}
                handleChange={handleOTPVerify}
                fieldName="otpEmail"
                placeholder="Enter OTP"
                title={"Enter OTP For Email"}
              />
              <Typography variant="body2" color="#D34040">
                {verify.emailMsg}
              </Typography>
              {timerActive.email ? (
                <Box sx={{ textAlign: "right" }}>{formatTime(timerEmail)}</Box>
              ) : (
                <VerifyOtp handleChange={() => handleResend()} text="Resend" />
              )}
              </Box>
          )}
</Box>
  )
}

export default EmailField