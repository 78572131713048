import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ViewDate from "../../../../Components/Masters/ViewDate";

function ViewPurchasedGoods({ inputData, data, viewData, setViewData, handleEdit }) {
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: "400px",
        maxWidth: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton onClick={() => setViewData(false)} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ p: 3, width: "100%", backgroundColor: "#fff", overflow: "auto" }}>
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Purchased Goods and Services Vendor Details
        </Typography>
        <Grid>
          <ViewDate data={data}/>
        </Grid>

        <Grid container spacing={2} sx={{mt:1}}>
          {inputData.map((input) => {
            let value = filteredData?.[input.fieldName];

            if (input.fieldName === "contactNumber" && typeof value === "object") {
              // Handle contactNumber specifically if it's an object
              const { countryCode, mobileNo } = value || {};
              value = `${countryCode ?? ''} ${mobileNo ?? ''}`.trim() || '-';
            }

            if (value === undefined || value === null) return null;

            return (
              <Grid item xs={12} sm={12} key={input.fieldName}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {input.title}
                </Typography>
                <div>
                  {value ?? "-"}
                </div>
              </Grid>
            );
          })}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10%",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0a3f73", textTransform: "none" }}
            onClick={handleEdit}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={viewData}
      onClose={() => setViewData(false)}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      {list()}
    </Drawer>
  );
}

export default ViewPurchasedGoods;
