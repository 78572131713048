import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  styled,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import InputField from "../../../../Components/Masters/InputField";
import SelectField from "../../../../Components/Masters/SelectField";
import contries from "../../Roles and Permissions/Country.json";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import VerifyOtp from "../../../../Components/Masters/VerifyOtp";
import { validateNumber } from "../../../../utils/validateFields";
import { numberError } from "../../../../utils/validationMessage";
import PhoneNumberInput from "../../../../Components/Masters/PhoneNumberInput";
import PhoneField from "../../../../Components/Masters/PhoneField";
import EmailField from "../../../../Components/Masters/EmailField";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px",
  },
}));

function AddPurchasedGoods({
  inputData,
  fetchData,
  id,
  element,
  data,
  setAddData,
  addData,
  setsnackOpen,
  addUrl,
  updateUrl,
}) {
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const initialState = {
    vendorLegalName: element?.vendorLegalName || "",
    country: element?.country || "",
    goodsAndServicesName: element?.goodsAndServicesName || "",
    gstNumber: element?.gstNumber || "",
    address: element?.address || "",
    personInChargeName: element?.personInChargeName || "",
    contactNumber: {
      countryCode: element?.contactNumber?.countryCode || "", // Use optional chaining
      mobileNo: element?.contactNumber?.mobileNo || "", // Use optional chaining
    },
    email: element?.email || "",
  };
  const [formData, setFormData] = useState(initialState);
  const [timerEmail, setTimerEmail] = useState(120);
  const [timerPhone, setTimerPhone] = useState(120);
  const [timerActive, setTimerActive] = useState({
    email: false,
    phone: false,
  });
  const [verifybtn, setverifybtn] = useState({ email: false, phone: false });
  const [verify, setVerify] = useState({
    phone: false,
    email: false,
    emailMsg: "",
    phoneMsg: "",
  });
  const [TaskAltIcons, setTaskAltIcons] = useState({
    email: false,
    phone: false,
  });
  // Time state
  const [otp, setOTP] = useState({
    phone: null,
    email: null,
  });

  useEffect(() => {
    setFormData(initialState);
    if (id && element) {
      setTaskAltIcons({ ...TaskAltIcons, phone: true, email: true });
    } else if (!id && !element) {
      setTaskAltIcons({ ...TaskAltIcons, phone: false, email: false });
    }
  }, [element, id]); // Ensure this runs when data changes

  // useEffect(() => {
  //   if (timerActive.email && timerEmail > 0) {
  //     const timer = setInterval(() => {
  //       setTimerEmail((prevTime) => {
  //         if (prevTime <= 1) {
  //           clearInterval(timer);
  //           setTimerActive((prev) => ({ ...prev, email: false }));
  //           return 0;
  //         }
  //         return prevTime - 1;
  //       });
  //     }, 1000);
  //     return () => clearInterval(timer);
  //   }
  // }, [timerActive.email]);

  // useEffect(() => {
  //   if (timerActive.phone && timerPhone > 0) {
  //     const timer = setInterval(() => {
  //       setTimerPhone((prevTime) => {
  //         if (prevTime <= 1) {
  //           clearInterval(timer);
  //           setTimerActive((prev) => ({ ...prev, phone: false }));
  //           return 0;
  //         }
  //         return prevTime - 1;
  //       });
  //     }, 1000);
  //     return () => clearInterval(timer);
  //   }
  // }, [timerActive.phone]); // Corrected dependency

  // Function to format the time
  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60);
  //   const seconds = time % 60;
  //   return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "gstNumber" && value.length > 15) {
      // setErrors({ ...errors, [name]: "GST Number must be 15 digits only" });
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "vendorLegalName") {
      const regex = new RegExp(`^${value}$`, "i"); // 'i' flag makes it case-insensitive

      const vendor = data.filter((el) => regex.test(el.vendorLegalName));
      if (
        vendor.length > 0 &&
        (element ? element._id !== vendor[0]._id : true)
      ) {
        setErrors({ ...errors, [name]: "Vendor Legal Name already exists." });
      }
      return;
    }

    if (name === "gstNumber") {
    //   // Regular expression for GST Number validation
    //    const gstNumberRegex = /^[A-Z]{2}[A-Z0-9]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}$/;
    
    //   // Check if the GST Number format is valid
    //   if (!gstNumberRegex.test(value)) {
    //       setErrors({...errors,[name] :
    //           "Please enter a valid GST Number (XX-AAAAA-####A-#)."
    //   })
    //   return;
    // }
     const regex = new RegExp(`^${value}$`, "i"); // 'i' flag makes it case-insensitive

      const gst = data.filter((el) => regex.test(el.gstNumber));

      // Check if the GST Number already exists and the IDs don't match (or if no GST record exists, skip the validation

      if (gst.length > 0 && (element ? element._id !== gst[0]._id : true)) {
        setErrors({
          ...errors,
          [name]: "GST Number already exists.",
        });
        return
      }
      ;
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountryCode = event.target.value;
    console.log(selectedCountryCode, event.target.name);
    setErrors({ ...errors, country: "" });
    // setVerify({ ...verify, phone: false, phoneMsg: "" });
    const selectedCountry = contries.countries.find(
      (country) => country.name === selectedCountryCode
    );

    if (selectedCountry) {
      // setErrors({ ...errors, contactNumber: "" });
      setTaskAltIcons({ ...TaskAltIcons, phone: false });
      setFormData((prevData) => ({
        ...prevData,
        country: selectedCountry.name,
        contactNumber: {
          ...prevData.contactNumber,
          countryCode: selectedCountry.dial_code,
          mobileNo: "",
        },
      }));
    } else {
      console.error("Country not found in data");
    }
  };

  // const handlePhoneChange = (e) => {
  //   const { value } = e.target;

  //   // Reset the icons and error state when phone changes
  //   setTaskAltIcons({ ...TaskAltIcons, phone: false });
  //   setVerify({ ...verify, phone: false, phoneMsg: "" });
  //   setverifybtn({ ...verifybtn, phone: false });
  //   setTimerPhone(120); // Reset timer
  //   setErrors({ ...errors, contactNumber: "" });

  //   // Ensure country code is selected before validating the phone number
  //   if (formData?.contactNumber.countryCode === "") {
  //     setErrors({
  //       ...errors,
  //       contactNumber: "Country Code is required. Please Select Country",
  //     });
  //     return;
  //   }

  //   // Reset OTP field when phone number is updated
  //   setOTP((prevOtp) => ({
  //     ...prevOtp,
  //     phone: "", // Reset OTP value
  //   }));

  //   // Check if the input value is a valid 10-digit mobile number (only numeric)
  //   const phoneNumberPattern = /^[0-9]{0,10}$/; // Accept only up to 10 digits (numeric)

  //   // Validate if the number is numeric and doesn't exceed 10 digits
  //   if (!phoneNumberPattern.test(value)) {
  //     return; // If the value is not numeric or exceeds 10 digits, do nothing
  //   }

  //   // If value has a length greater than 10, stop further action
  //   if (value.length > 10) return;

  //   // Update formData only when phone number is valid
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     contactNumber: {
  //       ...prevFormData.contactNumber,
  //       mobileNo: value, // Update the mobile number
  //     },
  //   }));

  //    // If the phone number hasn't changed, just return and update TaskAltIcon
  //    if (value === element?.contactNumber.mobileNo) {
  //     setTaskAltIcons({ ...TaskAltIcons, phone: true });
  //     return;
  //   }

  //   // Enable the verify button if phone number is exactly 10 digits
  //   if (value.length === 10) {
  //     setverifybtn({ ...verifybtn, phone: true });
  //   }
  // };

  // const handleEmailChange = (e) => {
  //   const { name, value } = e.target;

  //   setTaskAltIcons({ ...TaskAltIcons, email: false });
  //   setVerify({ ...verify, email: false, emailMsg: "" });
  //   setverifybtn({ ...verifybtn, email: false });
  //   setTimerEmail(120);
  //   setErrors({ ...errors, email: "" });
  //   setOTP((prevOtp) => ({
  //     ...prevOtp,
  //     email: "", // Update the OTP value
  //   }));
  //   setEmail(value);
  //   const trimmedValue = value.trim();
  //   const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  //   // Check if the email matches the pattern
  //   console.log(emailPattern.test(trimmedValue), trimmedValue);
  //   setFormData({
  //     ...formData,
  //     [name]: trimmedValue,
  //   });

  //   if (value === element?.email) {
  //     setTaskAltIcons({ ...TaskAltIcons, email: true });
  //     return;
  //   }
  //   // setErrorMessage(""); // Clear the error message if the email is valid
  //   if (emailPattern.test(trimmedValue)) {
  //     console.log("Invalid email format");
  //     setverifybtn({ ...verifybtn, email: true });
  //   }
  // };

  // const handleVerifyEmail = async (time) => {
  //   if (time > 0) {
  //     // setVerify({...verify,email:true})
  //     setverifybtn({ ...verifybtn, email: false });
  //     setTimerActive((prev) => ({ ...prev, email: true }));
  //     await axios
  //       .post(`${api}/auth/sendOtpToEmail`, {
  //         email: formData.email,
  //         module: "Purchase",
  //       })
  //       .then((res) => {
  //         console.log("resForSendOtpEmail", res);
  //         // setOTP({...otp,})
  //         if (res.status === 200) setVerify({ ...verify, email: true });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         if (err.response.data.statusCode === false) {
  //           setErrors({ ...errors, email: "Email already registered." });
  //           // setVerify({ ...verify, email: true });
  //           // setverifybtn({ ...verifybtn, email: false });
  //         }
  //       });
  //   } else {
  //     setVerify({ ...verify, email: false });
  //     setverifybtn({ ...verifybtn, email: false });
  //   }
  // };

  // const handleVerifyPhone = async (timePhone) => {
  //   if (timePhone > 0) {
  //     setverifybtn({ ...verifybtn, phone: false });
  //     setTimerActive((prev) => ({ ...prev, phone: true })); // Start timer
  //     await axios
  //       .post(`${api}/auth/sendOtpToPhone`, {
  //         phone: formData.contactNumber.mobileNo,
  //         countryCode: formData.contactNumber.countryCode,
  //         module: "Purchase",
  //       })
  //       .then((res) => {
  //         console.log("res", res);
  //         if(res.status===200)
  //           setVerify({ ...verify, phone: true });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         if (err.response.data.statusCode===false) {
  //           setErrors({...errors,contactNumber:"Mobile Number already registered."})
  //           // setVerify({ ...verify, email: true });
  //           // setverifybtn({ ...verifybtn, email: false });
  //         }
  //       });
  //   } else {
  //     setVerify({ ...verify, phone: false });
  //     setverifybtn({ ...verifybtn, phone: false });
  //   }
  // };

  // const handleResend = () => {
  //   setTimerEmail(120); // Reset time to 120 seconds
  //   setTimerActive((prev) => ({ ...prev, email: true })); // Start the timer
  //   handleVerifyEmail(120); // Call the email verification function
  // };

  // const handleResendPhone = () => {
  //   setTimerPhone(120); // Reset time to 120 seconds
  //   setTimerActive((prev) => ({ ...prev, phone: true })); // Start the timer
  //   handleVerifyPhone(120); // Call the phone verification function
  // };

  // const handleOTPVerify = (e) => {
  //   const { value } = e.target;

  //   // Only allow numeric values and ensure length does not exceed 4
  //   const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion

  //   // Update state only if the value is numeric and has a length <= 4
  //   if (isNumeric && value.length <= 4) {
  //     setVerify({ ...verify, emailMsg: "" });
  //     setOTP((prevOtp) => ({
  //       ...prevOtp,
  //       email: value, // Update the OTP value
  //     }));
  //     // Trigger API call when exactly 4 digits are entered
  //     if (value.length === 4) {
  //       axios
  //         .post(`${api}/auth/verifyOtpForEmail`, {
  //           email: formData.email,
  //           otp: value,
  //         })
  //         .then((res) => {
  //           console.log("OTP verified successfully:", res, res.status);
  //           if (res.status === 200) {
  //             setVerify({ ...verify, email: false });
  //             setverifybtn({ ...verifybtn, email: false });
  //             setTaskAltIcons({ ...TaskAltIcons, email: true });
  //             setErrors({ ...errors, email: "" });
  //           }
  //         })
  //         .catch((err) => {
  //           console.error("Error verifying OTP:", err);
  //           setVerify({ ...verify, emailMsg: "Invalid OTP" });
  //           // setverifybtn({ ...verifybtn, email: true });
  //           setTaskAltIcons({ ...TaskAltIcons, email: false });
  //         });
  //     }
  //   }
  // };

  // const handleOTPVerifyPhone = (e) => {
  //   const { value } = e.target;

  //   // Only allow numeric values and ensure length does not exceed 4
  //   const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion

  //   // Update state only if the value is numeric and has a length <= 4
  //   if (isNumeric && value.length <= 4) {
  //     setVerify({ ...verify, phoneMsg: "" });
  //     setOTP((prevOtp) => ({
  //       ...prevOtp,
  //       phone: value, // Update the OTP value
  //     }));
  //     // Trigger API call when exactly 4 digits are entered
  //     if (value.length > 4)
  //       setVerify({ ...verify, phoneMsg: "Enter 4 digit OTP" });
  //     if (value.length === 4) {
  //       axios
  //         .post(`${api}/auth/verifyOtpForPhone`, {
  //           phone: formData.contactNumber.mobileNo,
  //           countryCode: formData.contactNumber.countryCode,
  //           otp: value,
  //         })
  //         .then((res) => {
  //           console.log("OTP verified successfully:", res, res.status);
  //           if (res.status === 200) {
  //             setVerify({ ...verify, phone: false });
  //             setverifybtn({ ...verifybtn, phone: false });
  //             setTaskAltIcons({ ...TaskAltIcons, phone: true });
  //             setErrors({ ...errors, contactNumber: "" });
  //           }
  //         })
  //         .catch((err) => {
  //           console.error("Error verifying OTP:", err);
  //           setVerify({ ...verify, phoneMsg: "Invalid OTP" });
  //           setTaskAltIcons({ ...TaskAltIcons, phone: false });
  //         });
  //     }
  //   }
  // };

  const handleClose = () => {
    setAddData(false);
    setErrors({});
    setverifybtn({ phone: false, email: false });
    setVerify({ phone: false, email: false });
    setFormData(initialState);
        if(data && id)
        setTaskAltIcons({phone:true,email:true});
    //     setverifybtn({phone:false,email:false})
    if (!data && !id) {
      setTaskAltIcons({ phone: false, email: false });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    inputData.forEach((input) => {
      const value = formData[input.fieldName];
      console.log(input.fieldName, value);

      if (input.fieldName === "contactNumber") {
        const mobileNo = formData?.contactNumber.mobileNo;

        console.log(TaskAltIcon);
        if (!mobileNo)
          newErrors[input.fieldName] = `${input.title} is required.`;
        else if (formData.contactNumber.countryCode === "")
          newErrors[input.fieldName] =
            "Country Code is required.Please Select Country";
        else if (!validateNumber(mobileNo) || mobileNo.length !== 10)
          newErrors[input.fieldName] = "Please enter 10 digit Mobile Number";
        // Set the error message for mobileNo
        else if (!TaskAltIcons.phone)
          newErrors[input.fieldName] = "Mobile Number Verification required"; // Set the error message for mobileNo
      } else if (!value || value === "") {
        newErrors[input.fieldName] = `${input.title} is required.`;
      } else if (input.fieldName === "email" && !TaskAltIcons.email) {
        newErrors[input.fieldName] = "Email Verification Required";
      } else if (input.fieldName === "gstNumber" && value.length!==15) {
       newErrors[input.fieldName] =
                "GST Number must be 15 digit";
        
    }
    
    });

    setErrors({...errors,...newErrors});
    return Object.keys(newErrors).length === 0;
  };

  const Save = async () => {

    setverifybtn({});
    setVerify({})
    if (!validateForm()) return;

    console.log(formData);
    await axios({
      method: id ? "put" : "post",
      url: id ? `${updateUrl}/${id}` : addUrl,
      data: formData,
    })
      .then((res) => {
        setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        fetchData();
        handleClose();
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response && err.response.data && err.response.data.errors) {
          const errorMessages = err.response.data.errors
            .map((error) => error.msg)
            .join(", ");
          setsnackOpen({
            open: true,
            message: errorMessages,
            severity: "error",
          });
        }
      });
  };

  const renderInputField = (el) => {
    if (el.fieldName === "contactNumber")
      return (
        <Box>
          <PhoneField
            value={formData?.contactNumber?.mobileNo || ""} // Use optional chaining
            errors={errors[el.fieldName]}
            fieldName={el.fieldName}
            placeholder={el.placeholder}
            title={el.title}
            showIcon={TaskAltIcons.phone === true}
            {...{
              setTaskAltIcons,
              TaskAltIcons,
              setErrors,
              setFormData,
              element,
              formData,
              setVerify,
              verify,
              setverifybtn,
              verifybtn,
            }}
            countryCode={formData?.contactNumber?.countryCode || ""} // Use optional chaining
          />

          {/* {verifybtn.phone && formData.contactNumber?.mobileNo !== "" && (
            <VerifyOtp
              handleChange={() => handleVerifyPhone(120)}
              text={"Verify"}
            />
          )}
          {verify.phone && (
            <Box>
              <InputField
                value={otp.phone}
                title="Enter OTP For Phone"
                handleChange={handleOTPVerifyPhone}
                name="otpPhone"
                placeholder="Enter OTP"
                inputProps={{
                  maxLength: 4,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
              <Typography variant="body2" color="#D34040">
                {verify.phoneMsg}
              </Typography>
              {timerActive.phone ? (
                <Box sx={{ textAlign: "right" }}>{formatTime(timerPhone)}</Box>
              ) : (
                <VerifyOtp
                  handleChange={() => handleResendPhone()}
                  text="Resend"
                />
              )}
            </Box>
          )} */}
        </Box>
      );

    if (el.fieldName === "email")
      return (
        <Box>
          <EmailField
            title={el.title}
            // handleChange={handleEmailChange}
            placeholder={el.placeholder}
            value={formData?.email || ""}
            errors={errors[el.fieldName]}
            fieldName={el.fieldName}
            showIcon={TaskAltIcons.email === true}
            {...{
              setTaskAltIcons,
              TaskAltIcons,
              setErrors,
              setFormData,
              element,
              formData,
              setVerify,
              verify,
              setverifybtn,
              verifybtn,
            }}
          />
          {/* {verifybtn.email && formData.email !== "" && (
            <VerifyOtp
              handleChange={() => handleVerifyEmail(120)}
              text={"Verify"}
            />
          )}
          {verify.email && (
            <Box>
              <InputField
                value={otp.email}
                handleChange={handleOTPVerify}
                fieldName="otpEmail"
                placeholder="Enter OTP"
                title={"Enter OTP For Email"}
              />
              <Typography variant="body2" color="#D34040">
                {verify.emailMsg}
              </Typography>
              {timerActive.email ? (
                <Box sx={{ textAlign: "right" }}>{formatTime(timerEmail)}</Box>
              ) : (
                <VerifyOtp handleChange={() => handleResend()} text="Resend" />
              )}
            </Box>
          )} */}
        </Box>
      );

    if (el.type === "select")
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleCountryChange}
          options={contries.countries}
          errors={errors[el.fieldName]}
        />
      );

    return (
      <InputField
        title={el.title}
        handleChange={handleChange}
        placeholder={el.placeholder}
        value={formData[el.fieldName] || ""}
        errors={errors[el.fieldName]}
        fieldName={el.fieldName}
      />
    );
  };

  const list = () => (
    <Box display="flex" gap={3} sx={{ width: "400px" }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton onClick={handleClose} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "90%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {id
            ? "Edit Purchased Goods and Services Vendor"
            : "Add Purchased Goods and Services Vendor"}
        </Typography>
        {inputData.map((el) => (
          <div key={el.fieldName}>{renderInputField(el)}</div>
        ))}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      sx={{
        backgroundColor: "transparent",
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          height: "100vh",
          minHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
      anchor="right"
      open={addData}
      onClose={handleClose}
    >
      {list()}
    </Drawer>
  );
}

export default AddPurchasedGoods;
