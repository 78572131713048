import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Stack,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Checkbox,
  Drawer,
  Pagination,
  PaginationItem,
  Alert,
  Snackbar,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Category,
  ExpandLess,
  ExpandMore,
  MoreHoriz,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { api } from "../../../../utils/api";
import FilterPage from "../../../../Components/Masters/Filter/FilterPage";
import FilterSidebar from "../../../../Components/Masters/Filter/FilterSidebar";
import AddMachinery from "./AddMachinery";
import DateFilter from "./Filters/Date";
import MachineTypeFilter from "./Filters/MachineTypeFilter";

import CategoryFilter from "./Filters/CategoryFilter";
import ApplyButton from "../../Roles and Permissions/Filters/ApplyButton";
import ResetButton from "../../Roles and Permissions/Filters/ResetButton";
import MachineryNameFilter from "./Filters/MachineryName";
import FuelTypeFilter from "./Filters/FuelType";
import View from "./View";
import NoDataFound from "../../../../Components/Masters/NoDataFound";
import PaginationBox from "../../../../Components/Masters/Pagination";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Machinery = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);
  const [machinery, setMachinery] = useState([]);
  const [filteredMachinery, setFilteredMachinery] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [indexMachinery, setIndexMachinery] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    Category: false,
    MachineryName: false,
    FuelType: false,
    MachineType: false,
  });
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const filters = ["Date", "Machinery Category", "Machinery Type", "Fuel Type"];
  const [DeleteIndex, setDeleteIndex] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [errors, setErrors] = useState({
    Date: false,
    MachineryName: false,
    Category: false,
    FuelType: false,
    MachineType: false,
    // CompanyName: false,
    // Facility: false,
    // FunctionalityOfRole: false,
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [selectedMachineryNames, setSelectedMachineryNames] = useState([]);
  const [machineryNames, setMachineryNames] = useState([]);
  const [selectedFuelTypes, setSelectedFuelTypes] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [machineTypes, setMachineTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const [viewMachinery, setViewMachinery] = useState(false);
  const [dataView, setDataView] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [selectedMachinery, setSelectedMachinery] = useState(null);

  const fetchMachinery = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    // const fetchMachinery = async (filters = {}) => {
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage,
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      // console.log("Query Params sent to API:", queryParams); // Debugging line

      const response = await axios.get(
        `${api}/master/getAllMachinery?${queryParams}`
      );

      // console.log("Fetched data after filtering:", response.data); // Debugging line
      setMachinery(response.data.data);
      setTotalPages(response.data.totalPages);
      setFilteredMachinery(response.data.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };


  useEffect(() => {
    fetchMachinery();
  }, [page, rowsPerPage, sortConfig]);

  const handleAddRole = () => {
    setIsDrawerOpen(true);
    setIndexMachinery(undefined);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserIndex(null);
  };
  const open = Boolean(anchorEl);

  const handleMenuClick = (event, machinery, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserIndex(machinery);
    setDataView(machinery);
  };


  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const handleView = () => {
    handleMenuClose();
    setViewMachinery(true);
  };

  // console.log("machine1", machinery);

  const handleEdit = () => {
    setIndexMachinery(dataView);
    setAnchorEl(null);
    setSelectedUserIndex(null);
    setIsDrawerOpen(true);
    setViewMachinery(false);
    //  setIsDrawerOpenEdit(false)
    // openDrawerEdit();
  };

  const handleDelete = () => {
    // setindexUserView(index)
    setDeleteIndex(dataView._id);
    setDeleteDialog(true);
    setAnchorEl(null);
    setSelectedUserIndex(null);

    setDeleteDialog(true);
    setAnchorEl(null);
    setSelectedUserIndex(null);
  };

  const handleDeleteAggree = () => {
    axios
      .put(
        `${api}/master/deleteMachinery`,
        { active: false },
        {
          params: {
            id: DeleteIndex, // Pass as query parameter
          },
        }
      )
      .then((res) => {
        console.log(res, "res++");
        setDeleteDialog(false);
        setsnackOpen({
          open: true,
          message: "Machinery deleted successfully",
          severity: "success",
        });
        setAnchorEl(null);
        setSelectedUserIndex(null);

        // Remove the deleted machinery from the current list
        const deletedId = res.data.data._id; // Ensure this matches the response structure
        setMachinery((prevMachinery) =>
          prevMachinery.filter((item) => item._id !== deletedId)
        );

        // Optionally, update filteredMachinery if you're using it to render the displayed list
        setFilteredMachinery((prevFiltered) =>
          prevFiltered.filter((item) => item._id !== deletedId)
        );

        // Fetch the next page's machinery data if necessary
        if (machinery.length === 1 && page > 1) {
          setPage(page - 1); // Move to the previous page if the current page is empty after deletion
        } else {
          fetchMachinery(); // Re-fetch machinery to reflect the deletion and fill the page
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterChange = (selectedCategories) => {
    setCategoryFilter(selectedCategories);
  };

  const handleNameFilterChange = (selectedMachineryNames) => {
    setMachineryNames(selectedMachineryNames);
  };

  const handleFuelFilterChange = (selectedFuelTypes) => {
    setFuelTypes(selectedFuelTypes);
  };

  const handleTypeFilterChange = (selectedTypes) => {
    setMachineTypes(selectedTypes);
  };

  const handleApplyFilters = () => {
    if (!validateFilters()) return;
    console.log("Applying filters...");

    // Reset to first page when applying filters
    setPage(1);
    console.log("Selected categories:", selectedCategories);
    console.log("Selected names:", selectedMachineryNames);
    console.log("Selected names:", selectedFuelTypes);
    console.log("Selected Types:", selectedTypes);

    // setPage(1); // Reset to first page on filter application

    fetchMachinery({
      filterMachineCategoryType: selectedCategories.join(","),
      filterMachineTypeName: selectedTypes.join(","),
      filterName: selectedMachineryNames.join(","),
      filterFuelType: selectedFuelTypes.join(","),
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    });

    setDrawerFilterOpen(false);
    handleAppliedFilters();
  };

  const filtersToApply = {
    filterMachineCategoryType: selectedCategories.join(","),
    filterMachineTypeName: selectedTypes.join(","),
    filterName: selectedMachineryNames.join(","),
    filterFuelType: selectedFuelTypes.join(","),
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
  };

  //  Applying reset filters
  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedCategories([]);
    setSelectedTypes([]);
    setSelectedMachineryNames([]);
    setSelectedFuelTypes([]);
    setAppliedFilters({});
    setIsFiltersApplied(false);
    fetchMachinery({});
    setErrors({});
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate ? true : false;
    const isCategroryApplied = selectedCategories.length > 0;
    const isMachineryNameApplied = selectedMachineryNames.length > 0;
    const isMachineTypeApplied = selectedTypes.length > 0;
    // const isFunctionalityOfRoleApplied = functionalityOfRoleFilter.length > 0;
    const isFuelTypeApplied = selectedFuelTypes.length > 0;

    setIsFiltersApplied(
      isDateApplied ||
        isCategroryApplied ||
        isMachineryNameApplied ||
        isMachineTypeApplied ||
        // isFunctionalityOfRoleApplied ||
        isFuelTypeApplied
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      Category: isCategroryApplied,
      MachineType: isMachineTypeApplied,
      MachineryName: isMachineryNameApplied,
      FuelType: isMachineryNameApplied,

      // FunctionalityOfRole: isFunctionalityOfRoleApplied,
    }));
  };

  const validateFilters = () => {
    let isValid = true;
    const newErrors = { Date: "", Role: "" };
    if (selectedFilter === "Date") {
      if (startDate > endDate) {
        console.log(startDate, endDate, Date.now());
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
      if (!startDate && !endDate) {
        newErrors.Date = "Please select a date range";
        isValid = false;
      }
    }

    if (
      selectedFilter === "Machinery Category" &&
      selectedCategories.length === 0
    ) {
      newErrors.Category = "Please select Machinery Category";
      isValid = false;
    }

    if (selectedFilter === "Machinery Type" && selectedTypes.length === 0) {
      newErrors.MachineType = "Please select Machinery Type";
      isValid = false;
    }

    if (
      selectedFilter === "MachineryName" &&
      selectedMachineryNames.length === 0
    ) {
      newErrors.MachineryName = "Please select MachineryName";
      isValid = false;
    }

    if (selectedFilter === "Fuel Type" && selectedFuelTypes.length === 0) {
      newErrors.FuelType = "Please select Fuel Type";
      isValid = false;
    }
    console.log(newErrors);

    setErrors(newErrors);
    return isValid;
  };

  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            errors={errors.Date}
          />
        );

      case "Machinery Category":
        return (
          <CategoryFilter
            url={`${api}/master/getAllMachinery`}
            onFilterChange={handleFilterChange}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            errors={errors.Category}
          />
        );

      case "Machinery Type":
        return (
          <MachineTypeFilter
            url={`${api}/master/getAllMachinery`}
            onFilterChange={handleTypeFilterChange}
            selectedTypes={selectedTypes}
            setSelectedTypes={setSelectedTypes}
            errors={errors.MachineType}
          />
        );

      case "MachineryName":
        return (
          <MachineryNameFilter
            url={`${api}/master/getAllMachinery`}
            onFilterChange={handleNameFilterChange}
            selectedMachineryNames={selectedMachineryNames}
            setSelectedMachineryNames={setSelectedMachineryNames}
            errors={errors.MachineryName}
          />
        );

      case "Fuel Type":
        return (
          <FuelTypeFilter
            url={`${api}/master/getAllMachinery`}
            onFilterChange={handleFuelFilterChange}
            selectedFuelTypes={selectedFuelTypes}
            setSelectedFuelTypes={setSelectedFuelTypes}
            errors={errors.FuelType}
          />
        );

      default:
        return (
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            errors={errors.Date}
          />
        );
    }
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // fetchMachinery({ ...filters, page: newPage });
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        // margin: " 0px 0px 0px 50px",
        zIndex: 1000,
      }}
    >
      <Box sx={{ width: 600 }}>
        <Snackbar
          open={snackOpen.open}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setsnackOpen({ ...snackOpen, open: false })}
        >
          <Alert
            // onClose={handleClose}
            severity={snackOpen.severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackOpen.message}
            {/* Add user Successfully */}
          </Alert>
        </Snackbar>
        <AddMachinery
          value={isDrawerOpen}
          setValue={setIsDrawerOpen}
          index={index}
          fetchMachinery={fetchMachinery}
          setsnackOpen={setsnackOpen}
          machinery={selectedMachinery}
          indexMachinery={indexMachinery}
        />
        <View
          viewFacility={viewMachinery}
          setViewFacility={setViewMachinery}
          selectedFacility={dataView}
          setsnackOpen={setsnackOpen}
          fetchFacilities={fetchMachinery}
          handleEdit={handleEdit}
        />
        {/* <ViewUser
        values={isDrawerOpenEdit}
        // values ={ViewInner}
        setValues={setIsDrawerOpenEdit}
        indexView={indexUserView}
      /> */}
      </Box>
      {drawerFilterOpen && (
        <Drawer
          anchor="right"
          open={drawerFilterOpen}
          onClose={() => setDrawerFilterOpen(false)}
          sx={{
            backgroundColor: "transparent",
            "& .MuiDrawer-paper": {
              // This targets the paper component within the Drawer
              backgroundColor: "transparent", // Make paper transparent
              boxShadow: "none", // Optional: Remove any shadow
              width: "50%",
            },
          }}
        >
          <Box
            display="flex"
            gap={3}
            sx={{
              width: "100%",
              display: "flex",
              overflowX: "hidden",
              maxWidth: "100%",
              justifyContent: "space-btween",
              height: "100%",
              backgroundColor: "transparent",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                height: "3%",
                padding: "13px",
                margin: "30px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "80%",
                width: "4%",
              }}
            >
              <IconButton
                onClick={() => setDrawerFilterOpen(false)}
                sx={{ color: "black" }}
              >
                <CloseIcon sx={{ fontSize: "1.2rem" }} />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
              {/* Sidebar for selecting the filter */}
              <FilterSidebar
                {...{
                  setSelectedFilter,
                  selectedFilter,
                  filters,
                  appliedFilters,
                }}
              />

              {/* Filter content area */}
              <Box
                sx={{
                  flexGrow: 1,
                  backgroundColor: "#F9F9F9",
                  padding: "20px",
                }}
              >
                {renderSelectedFilter()}
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "3%",
                    position: "fixed",
                    bottom: "20px",
                    ml: 3,
                    mr: 3,
                  }}
                >
                  <ApplyButton handleApply={handleApplyFilters} />
                  <ResetButton handleReset={handleResetFilters} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}{" "}
      {deleteDialog && (
        <Dialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          BackdropProps={{
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust the opacity as needed (0.3 is lighter)
            },
          }}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to delete this entry?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteAggree} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        <Grid
          container
          alignItems="center"
          sx={{ width: "80%", margin: "5% 0.5% 0% 22%" }}
        >
          <Grid item xs={6}>
            {/* <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                margin: "8px 0px 20px 0px",
                // marginLeft: "5px",
                textDecoration: "underline",
              }}
            >
              Masters
            </Typography> */}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              // endIcon={<FilterListIcon />}
              sx={{
                // margin: "20px 10px 0px 472px",
                margin: "10px 10px 0px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                "&:hover .dot-icon": {
                  color: "#FFFFFF", // Change dot color on button hover
                },
                // display:"flex"
              }}
            >
              Filter
              <FilterListIcon />
              {/* <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                /> */}
              {isFiltersApplied && (
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              )}
            </Button>
            <Button
              variant="contained"
              sx={{
                // margin: "20px -46px 0px 0px",
                margin: "10px -36px 0px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              endIcon={<AddIcon />}
              onClick={handleAddRole}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
        <Box>
          <Box sx={{ margin: "16px" }}>
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                textAlign: "left",
                // padding: "0px",
              }}
            >
              Machinery Master
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "13% 15% 15% 18% 15% 15% 9%",
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 18px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Date
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("createdAt", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "createdAt" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("createdAt", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "createdAt" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Machinery Name
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("name", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "name" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("name", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "name" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Machinery Category
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("machineCategory", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "machineCategory" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("machineCategory", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "machineCategory" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Machinery Type
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("machineType", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "machineType" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("machineType", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "machineType" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>

            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Quantity of Machines
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("numberOfMachines", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "numberOfMachines" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("numberOfMachines", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "numberOfMachines" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Fuel Type
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("fuelType", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "fuelType" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("fuelType", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "fuelType" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px 8px 16px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Action
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              ></Box>
            </Typography>
          </Box>

          {filteredMachinery.length === 0 ? (
            <NoDataFound />
          ) : (
            filteredMachinery.map((machinery, index) => (
              <Box
                key={machinery._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "13% 15% 15% 18% 15% 15% 9%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Typography
                  sx={{
                    //   padding: "1px 4px",
                    padding: " 9px 8px",
                    textAlign: "center",
                    borderRight: "1px solid #ddd",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    //   margin: "7px 0px -7px 0px",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  {/* {new Date(equipments.createdAt).toLocaleDateString()} */}
                  {new Date(machinery.createdAt).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </Typography>

                <Typography
                  sx={{
                    //   padding: " 1px 6px",
                    padding: " 9px 8px",
                    textAlign: "left",
                    borderRight: "1px solid #ddd",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "14px",
                    color: "black",
                    // margin: "7px 0px -7px 0px",
                  }}
                >
                  {machinery.name}
                </Typography>

                <Typography
                  sx={{
                    //   padding: " 1px 6px",
                    padding: " 9px 8px",
                    textAlign: "left",
                    borderRight: "1px solid #ddd",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "14px",
                    color: "black",
                    // margin: "7px 0px -7px 0px",
                  }}
                >
                  {machinery.machineCategory?.type || "N/A"}
                </Typography>
                <Typography
                  sx={{
                    //   padding: " 1px 6px",
                    padding: " 9px 8px",
                    textAlign: "left",
                    borderRight: "1px solid #ddd",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "14px",
                    color: "black",
                    // margin: "7px 0px -7px 0px",
                  }}
                >
                  {machinery.machineType?.name || "N/A"}
                </Typography>

                <Typography
                  sx={{
                    //   padding: " 1px 6px",
                    padding: " 9px 8px",
                    textAlign: "left",
                    borderRight: "1px solid #ddd",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "14px",
                    color: "black",
                    // margin: "7px 0px -7px 0px",
                  }}
                >
                  {machinery.numberOfMachines}
                </Typography>
                <Typography
                  sx={{
                    //   padding: " 1px 6px",
                    padding: " 9px 8px",
                    textAlign: "left",
                    borderRight: "1px solid #ddd",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "14px",
                    color: "black",
                    // margin: "7px 0px -7px 0px",
                  }}
                >
                  {machinery.fuelType}
                </Typography>
                <Box
                  sx={{
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    onClick={(event) =>
                      handleMenuClick(event, machinery, index)
                    }
                  >
                    <MoreHorizIcon
                      sx={{ fontWeight: "bold", color: "black" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </TableContainer>
      {/* Action Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem
          onClick={() => handleView(machinery)}
          sx={{ fontSize: "14px" }}
        >
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} />
          View Logs
        </MenuItem>
        <MenuItem
          // onClick={() => handleEdit(roleEdit)}
          onClick={() => handleEdit()}
          sx={{ fontSize: "14px" }}
        >
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>

        <MenuItem onClick={() => handleDelete()} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
      {totalPages > 1 && (
        <PaginationBox
          {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
        />
      )}
    </Box>
  );
};

export default Machinery;
