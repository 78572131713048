import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import AddFacility from "./AddIndustrialProcess";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DescriptionIcon from "@mui/icons-material/Description"; // For CSV
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import DocumentItem from "../../../../../Components/Masters/DocumentItem";

function ViewIndusrialProcess(props) {
  const [addFacility, setAddFacility] = useState(false);

  const handleDownload = (url) => {
    const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/");
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const Edit = () => {
    props.handleEdit();
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 400,
        maxWidth: "100%",
        height: "100vh",
        justifyContent: "space-between",
        backgroundColor: "transparent",
        overflowX: "hidden", // Prevent horizontal overflow
      }}
    >
      <AddFacility
        addFacility={addFacility}
        setAddFacility={setAddFacility}
        selectedFacility={props.selectedFacility}
        setsnackOpen={props.setsnackOpen}
        fetchFacilities={props.fetchFacilities}
      />
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setViewFacility(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          p: 3,
          width: "100%",
          backgroundColor: "#fff",
          height: "100%",
          overflowY: "auto", // Enable vertical scrolling only
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          View Industrial Process Master
        </Typography>
        <Box>
          <Box display="flex" gap={10}>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mt: 1 }}
              >
                Date
              </Typography>
              <p>
                {/* {props.selectedFacility?.createdAt.split('T')[0]??""} */}
                {new Date(props.selectedFacility?.createdAt).toLocaleDateString(
                  "en-GB",
                  { day: "2-digit", month: "2-digit", year: "numeric" }
                ) ?? ""}
              </p>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mt: 1 }}
              >
                Location
              </Typography>
              <p>{props.selectedFacility?.location ?? ""}</p>
            </Box>
          </Box>
        </Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Name of Facility
        </Typography>
        <p>{props.selectedFacility?.facility?.facilityName ?? ""}</p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Industry Process
        </Typography>
        <p>{props.selectedFacility?.industryProcess ?? ""}</p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Final Manufactured Product
        </Typography>
        <p>{props.selectedFacility?.finalManufacturedProduct ?? ""}</p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Remark
        </Typography>
        <p>
          {props.selectedFacility?.remark
            ? props.selectedFacility?.remark
            : "-"}
        </p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Documents
        </Typography>
        {/* <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {props.selectedFacility?.documents &&
          props.selectedFacility.documents.length > 0
            ? props.selectedFacility.documents.map((doc, index) => {
                const fileName = doc.split("/").pop();
                const fileExtension = fileName.split(".").pop().toLowerCase();
                const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                  fileExtension
                );
                const isCsv = fileExtension === "csv";
                const isExcel = ["xls", "xlsx"].includes(fileExtension);

                return (
                  <Box key={index} sx={{ position: "relative" }}>
                    {isImage ? (
                      <img
                        src={doc}
                        alt={`Document ${index + 1}`}
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "cover",
                          borderRadius: "4px",
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "80px",
                          height: "80px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                      >
                        {isCsv ? (
                          <DescriptionIcon
                            sx={{ fontSize: "20px", color: "#1A73E8" }}
                          />
                        ) : isExcel ? (
                          <FontAwesomeIcon
                            icon={faFileExcel}
                            style={{ color: "#0a8f08" }}
                          />
                        ) : (
                          <InsertDriveFileIcon
                            sx={{ fontSize: "2rem", color: "#757575" }}
                          />
                        )}
                      </Box>
                    )}
                    <Typography
                      variant="caption"
                      noWrap
                      sx={{ mt: 1, textAlign: "center" }}
                    >
                      {fileName}
                    </Typography>
                    <IconButton
                      onClick={() => handleDownload(doc)}
                      sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        color: "white",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        p: 0.5,
                      }}
                    >
                      <ArrowDownwardIcon sx={{ fontSize: "0.7rem" }} />
                    </IconButton>
                  </Box>
                );
              })
            : "-"}
        </Box> */}

        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          sx={{ width: "100%" }}
        >
          {/* Render Images First */}
          {props.selectedFacility &&
            props.selectedFacility?.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedFacility?.documents
                  .filter((url) =>
                    ["png", "jpg", "jpeg"].includes(
                      url.split(".").pop().toLowerCase()
                    )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`image-${index}`} url={url} />
                  ))}
              </Box>
            )}

          {/* Render Other Documents */}
          {props.selectedFacility &&
            props.selectedFacility?.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedFacility?.documents
                  .filter(
                    (url) =>
                      !["png", "jpg", "jpeg"].includes(
                        url.split(".").pop().toLowerCase()
                      )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`file-${index}`} url={url} />
                  ))}
              </Box>
            )}

          {props.selectedFacility?.documents.length === 0 ? <p>-</p> : null}
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Edit}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        anchor="right"
        open={props.viewFacility}
        onClose={() => props.setViewFacility(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default ViewIndusrialProcess;
