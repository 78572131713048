import  React ,{useState,useEffect} from 'react';
import { Box, TextField, Typography,MenuItem,Button,Checkbox,
  Chip ,IconButton,OutlinedInput,Select,
  FormGroup,
  FormControlLabel}  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { api } from '../../../utils/api';
import { companyId } from '../../../utils/companyId';

function AddRole(props) {
  const [selectedModules, setSelectedModules] = useState([]);
      const [moduleName, setmoduleName] = useState([
        "Select All",
        "Environment Dashboard",
"Environment | Scope 1",
"Environment | Scope 2",
"Environment | Scope 3",
"Social Dashboard",
"Governance Dashboard",
"Setup",
"Roles & Permission",
"Facility Master",
"Masters",
"ESG Reporting",
"GRI",
"BRSR",
"CDP",
"Value Chain",
"Water Management"
      ]);
      const [openSelect, setOpen] = useState(false);
      const [selectedModuleName,setselectedModuleName] = useState([]);
      const [PermissionsName, setPermissionsName] = useState([]);
      const [alreadyRole,setAlreadyUser] = useState(false);
      const [errors,setErrors]=useState({});
      const [selectedPermissions, setSelectedPermissions] = useState([]);

const initialState = {
  company:companyId,
  remark:"",
  name:"",
  allowed_permissions:PermissionsName,
  moduleName:selectedModuleName
}

  const [fromValue,setFromValue]= useState(initialState);


  console.log("_idddddd",props.index,props.index?._id)

  const resetForm = () => {
     setselectedModuleName(props.index===0 ?[]:props.index?.moduleName);
     setSelectedModules(props.index===0 ?[]:props.index?.moduleName);
     setSelectedPermissions(props.index===0 ?[]:props.index?.allowed_permissions)
    setFromValue(props.index===0 ?initialState:props.index);
    setErrors({});
  };
  useEffect(() => {
axios.get(`${api}/auth/getPermissions`).then((res)=>{
  console.log(res.data.data,"resres")
  setPermissionsName(res.data.data)
}).catch((err)=>{
  console.log(err,"err")
})
  }, []); 
  useEffect(()=>{
//     const fetchUserData = async () => {
//  await   axios.get(`${api}/auth/getRoleById`,{
//       params: {
//         Id: props.index // Pass as query parameter
//       }
//     }).then((res)=>{
//       console.log("data after fetch roleId",res.data.data)
//       setFromValue({
//         company:res.data.data.company,
//         remark:"",
//         name:res.data.data.name,
//         allowed_permissions:res.data.data.allowed_permissions.map((item) => item.name).join(',')       
//       })
//       setAlreadyUser(props.index===0? false: true)
//     }).catch((err)=>{
//       console.log(err)
//     })
//   }
    if (props.index._id !== 0) {
      // Fetch user data when props.index is not 0
      // fetchUserData();
   const  moduleNames = moduleName.filter(module => module !== "Select All")
   console.log("giiiPropsModuleName",props.index)
      setFromValue({
                company:companyId,
                remark:props.index?.remark,
                name:props.index?.name||"",
                moduleName:props.index?.moduleName||[],
                allowed_permissions: (props.index?.allowed_permissions || []).map((item) => item._id)   
              })
              setselectedModuleName(props.index?.moduleName||[])
              setSelectedModules(props.index?.moduleName||[])
              setSelectedPermissions(props.index?.allowed_permissions || [])
              setAlreadyUser(props.index?._id?true:false)
              // setPermissionsName(props.index?.allowed_permissions || [])
    } else {
      setFromValue({
      company:companyId,
      remark:"",
      name:"",
      allowed_permissions:[],
      moduleName:selectedModuleName
    })
    }
  },[props.index])
  
  console.log("role",props.index)

  
  const handleModuleModleChange = (event) => {
    const value = Array.isArray(event.target.value)
      ? event.target.value.filter((item) => item !== "Select")
      : [];
    // const value =  event.target.value
    console.log("Filtered value:", value); // Debugging the selected values
    // let newSelectedData = [...selectedModules]
    let newSelectedData = value.includes("Select All") ? moduleName.filter((item) => item !== "Select All") : [...selectedModules];
    if (value.includes("Select All")) {
      // if (newSelectedData.length === 1) {
      //   // Select all modules
      //   console.log("newSelectedData",newSelectedData,moduleName)
      //   setSelectedModules(moduleName.filter((item) => item !== "Select All"));
      //   setselectedModuleName(["Select All"]);
      // } else if (newSelectedData.length > 1 && newSelectedData[newSelectedData.length - 1] === "Selected All") {
      //   // Deselect all modules
      //   console.log("newSelectedData--",newSelectedData,moduleName)
      //   setSelectedModules([]);
      //   setselectedModuleName([]);
      // }
      setSelectedModules(moduleName.filter((item) => item !== "Select All"));
      setselectedModuleName(moduleName.filter((item) => item !== "Select All"));
      setFromValue({
        ...fromValue,
        moduleName: moduleName.filter((item) => item !== "Select All")// Ensure no duplicates in `fromValue`
    });
      setmoduleName((prev) => ["Unselect All", ...prev.slice(1)])
     } else if (value.includes("Unselect All")) {
        setSelectedModules([]);
        setselectedModuleName([]);
        // setmoduleName((prev) => prev.filter((item) => item !== "Unselected"))
        setmoduleName((prev) => ["Select All", ...prev.slice(1)])
        setFromValue({
          ...fromValue,
          moduleName: []// Ensure no duplicates in `fromValue`
      });
      }
      //  else {
      //   console.log("newSelectedData++",newSelectedData,moduleName)
      //   // Remove "Selected All" and keep other selected modules
      //   setSelectedModules(moduleName.filter((item) => item !== "Selected All"));
      //   setselectedModuleName(moduleName.filter((item) => item !== "Selected All"));
      //   setmoduleName(["Unselected",...moduleName])
      // }
     else {
      // console.log("newSelectedData++--",newSelectedData,moduleName,value)
      // Regular selection
      
      
      value.forEach((item) => {
        newSelectedData.push(item); // Push each value individually
      });

      const uniqueValues = [...new Set(newSelectedData)];
      // if (value.includes("Selected All")){
      //   setSelectedModules(moduleName.filter((item) => item !== "Selected All"));
      //   setmoduleName(["Unselected",...moduleName])
      // }else if (value.includes("Unselected")) {
      //   setSelectedModules([]);
      //   setselectedModuleName([]);
      //   setmoduleName([...moduleName])
      // }
      console.log(newSelectedData,"uniqueValues")
      // Use Set to remove duplicates
      console.log(uniqueValues,"uniqueValues")
     setSelectedModules(uniqueValues);
    setselectedModuleName(uniqueValues);
       setFromValue({
      ...fromValue,
      moduleName: uniqueValues// Ensure no duplicates in `fromValue`
  });
    }

    // Update the `fromValue` object
  //   setFromValue({
  //     ...fromValue,
  //     moduleName: value.includes("Selected All")
  //     ? moduleName.filter((module) => module !== "Selected All")
  //     : [...new Set(value)], // Ensure no duplicates in `fromValue`
  // });

    // Close dropdown
    setOpen(false);
  };



  

  // const handleModuleModleSelection = (id) => {
  //   // Toggle selection of the module
  //   let newSelected = [...selectedModuleName];
  //   console.log("newSelected====",newSelected)
  //   // if (newSelected.includes(id)) {
  //     // If the id is already selected, remove it
      
  //     // newSelected = newSelected.filter((selectedId) => selectedId !== id);
  //     console.log("newSelected====",newSelected)
  //   // } else {
  //     // If not selected, add it to the list
  //     newSelected.push(id);
  //     // console.log("newSelected====",newSelected)
  //   // }
  //   // Update the state
  //   // setFromValue({
  //   //   ...fromValue,
  //   //   moduleName   : newSelected,
  //   // });
  //   setFromValue({
  //     ...fromValue,
  //     moduleName:newSelected
  //   })
    
  //   setselectedModuleName(newSelected)
  // };


  // const handleModuleChange = (event) => {
  //   const selectedValues = event.target.value; // MUI returns an array of selected values
  //   const selectedCheckeds = event.target.checked; 
  //   console.log("Selected values:", selectedValues,selectedCheckeds); // Log the selected values
  //   const uniqueValues = [...PermissionsName,selectedValues]
  //   // Update the state with the new selected values
  //    setFromValue({
  //   ...fromValue,
  //      allowed_permissions: selectedValues, // Set the updated array
  //    });
  //   setSelectedPermissions(() => {
  //     // Map selectedValues (IDs) to corresponding objects in PermissionsName
  //     return uniqueValues.map((id) => PermissionsName.find((perm) => perm._id === id));
  //   });
  //   // setSelectedPermissions(event.target.value)
  //   // setPermissionsName(selectedValues)

  //   setSelectedPermissions((prev) => {
  //     // Check if the selected item (object) is already in selectedPermissions
  //     const isSelected = prev.some((perm) => perm._id === selectedValues);
  //   console.log("isSelected",isSelected,prev,selectedValues,prev.some((perm) => perm._id))
  //     if (isSelected) {
  //       // If the item is already selected, remove it
  //       return prev.filter((perm) => perm._id !== selectedValues);
  //     } else {
        
  //       const selectedItem = PermissionsName.find((perm) => perm._id === selectedValues);
    
  //       // Ensure the selected item exists before adding it
  //       if (selectedItem) {
  //         console.log("Adding Item: ", selectedItem);
  //         return [...prev, selectedItem];  // Add selected item object to the array
  //       } else {
  //         // console.error("Item not found in PermissionsName");
  //         return prev; // Return previous state if item doesn't exist
  //       }
  //     }
  //   });
  // };
  



  // const handleModuleChange = (event) => {
  //   const { value, checked } = event.target; // Get value and checked status from the event
  //   console.log("Selected value:", value, "Checked:", checked);
  
  //   // Update selectedPermissions based on whether the checkbox is checked or unchecked
  //   setSelectedPermissions((prevSelected) => {
  //     if (checked) {
  //       // If checked, add the new permission to selectedPermissions
  //       const selectedItem = PermissionsName.find((perm) => perm._id === value);
  //       if (selectedItem && !prevSelected.some((perm) => perm._id === value)) {
  //         return [...prevSelected, selectedItem]; // Add the new permission to the array
  //       }
  //     } else {
  //       // If unchecked, remove the permission from selectedPermissions
  //       return prevSelected.filter((perm) => perm._id !== value);
  //     }
  //     return prevSelected; // Return the previous state if no changes are made
  //   });
  
  //   // Optionally, you can also update other state values like fromValue
  //   setFromValue((prevFromValue) => ({
  //     ...prevFromValue,
  //     allowed_permissions: selectedPermissions.map((perm) => perm._id), // Sync allowed_permissions with selectedPermissions
  //   }));
  // };
  



  const handleModuleChange = (event) => {
    const { value, checked } = event.target; // Get value and checked status from the event
    console.log("Selected value:", value, "Checked:", checked);
  
    // Update selectedPermissions based on whether the checkbox is checked or unchecked
    setSelectedPermissions((prevSelected) => {
      let updatedPermissions;
  // if  value === "66de8fb8f978a340f62bdcb9"?setErrors({...errors,moduleName:"Delete"})
      if (checked) {
        // If checked, add the new permission to selectedPermissions
        const selectedItem = PermissionsName.find((perm) => perm._id === value);
        if (selectedItem && !prevSelected.some((perm) => perm._id === value)) {
          updatedPermissions = [...prevSelected, selectedItem]; // Add the new permission to the array
        } else {
          updatedPermissions = prevSelected; // No changes
        }
      } else {
        // If unchecked, remove the permission from selectedPermissions
        updatedPermissions = prevSelected.filter((perm) => perm._id !== value);
      }
      const  Delete = updatedPermissions.filter((perm) => perm.name === "Delete").join('')

      if(updatedPermissions.length === 1 && Delete){
   setFromValue((prevFromValue) => ({
    ...prevFromValue,
    allowed_permissions: "Delete", // Sync allowed_permissions with selectedPermissions
  }));
      }else{
      setErrors({...errors,allowed_permissions:""}) 
      setFromValue((prevFromValue) => ({
        ...prevFromValue,
        allowed_permissions: updatedPermissions.map((perm) => perm._id), // Sync allowed_permissions with selectedPermissions
      }));
    }
       console.log(Delete,"updatedPermissionsDeleted=")
      // Now, update fromValue's allowed_permissions with the latest state
      // setFromValue((prevFromValue) => ({
      //   ...prevFromValue,
      //   allowed_permissions: updatedPermissions.map((perm) => perm._id), // Sync allowed_permissions with selectedPermissions
      // }));
  
      // Return the updated selectedPermissions
      return updatedPermissions;
    });
  };
  
 
  const handleModuleSelection = (id) => {
    setSelectedPermissions((prevSelected) => {
      // Check if selectedPermissions is storing objects or IDs
      const isObject = typeof prevSelected[0] === 'object';
      const isSelected = isObject 
        ? prevSelected.some((perm) => perm._id === id)
        : prevSelected.includes(id);
  
      if (isSelected) {
        // Remove the permission if it's already selected
        return isObject
          ? prevSelected.filter((perm) => perm._id !== id)
          : prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // Add the permission if it's not selected
        const selectedItem = PermissionsName.find((perm) => perm._id === id);
        return selectedItem 
          ? [...prevSelected, isObject ? selectedItem : id]
          : prevSelected;
      }
    });
  };
  

  // const handleModuleSelection = (id) => {
  //   console.log(id,"setSelectedPermissions")
  //   setSelectedPermissions((prevSelected) => {
  //     if (prevSelected.includes(id)) {
  //       return prevSelected.filter((selectedId) => selectedId !== id);
  //     } else {
  //       return [...prevSelected, id];
  //     }
  //   });


  //   // setSelectedPermissions((prev) => {
  //   //   // Check if the selected item (object) is already in selectedPermissions
  //   //   const isSelected = prev.some((perm) => perm._id !== id);
  //   // console.log("isSelected",isSelected)
  //   //   if (isSelected) {
  //   //     // If the item is already selected, remove it
  //   //     return prev.filter((perm) => perm._id !== id);
  //   //   } else {
  //   //     // If the item is not selected, add the entire item object
  //   //     const selectedItem = PermissionsName.find((perm) => perm._id === id);
  //   //     // Ensure no duplicate is added
  //   //     return selectedItem ? [...prev, selectedItem] : prev;
  //   //   }
  //   // });
    
  // };
  
  const handleChange =(e)=>{
    const {name,value}=e.target
    setFromValue({
      ...fromValue,
      [name]:value
    })
  }
  // const handleModule =(e)=>{
  //   const {name,value}=e.target
  // console.log(e.key,value,"forModule")
  //   setFromValue({
  //     ...fromValue,
  //     [name]:value
  //   })

  // }
  const handleModule = (event) => {
    const {
      target: { value },
    } = event;
    setFromValue({
      ...fromValue,
      allowed_permissions: typeof value === 'string' ? value.split(',') : value,
    });
  };
  

  const validateForm = () => {
    const newErrors = {};
    
    const { 
      name,
      allowed_permission,
      moduleName
    } = fromValue;
  
  
    // Validate `name`
    if (!name || name.trim() === "") {
      newErrors.name = "Name is required.";
    }
  
    // Validate `allowed_permissions`
    // if (!allowed_permissions || allowed_permissions === "Tap to Select") {
    //   newErrors.allowed_permissions = "Please select the any option";
    // }

    // Validate `allowed_permissions`
if (!fromValue.allowed_permissions || fromValue.allowed_permissions.length === 0 ) {
  newErrors.allowed_permissions = "Please select at least one permission.";
}
else if (fromValue.allowed_permissions  === "Delete") {
  newErrors.allowed_permissions = "Only delete access cann't be given";
}

if (!fromValue.moduleName || fromValue.moduleName.length === 0) {
  newErrors.moduleName = "Please select at least one module.";
}
console.log(fromValue.moduleName,fromValue.moduleName.length,"fromValue======")
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleDeleteModule = (item)=>{
    console.log("handleDeleteModule",item)
    const updatedValues = selectedModuleName.filter((value) => value !== item);
    setselectedModuleName(updatedValues);
    setSelectedModules(updatedValues);
  console.log("handleDeleteModule",fromValue.moduleName)
    // Optionally update any other related state if needed (like form values)
    setFromValue({
      ...fromValue,
      moduleName: updatedValues,
    });
  }
  const handleDelete = (item) => {
   
    setSelectedPermissions((prevSelected) => {
      // If `selectedPermissions` contains objects, filter by _id
      if (typeof prevSelected[0] === 'object') {
        return prevSelected.filter((perm) => perm._id !== item._id);
      } 
      // If `selectedPermissions` contains IDs, filter by the ID directly
      else {
        return prevSelected.filter((id) => id !== item);
      }
    });
  
    // Optionally update any other related state if needed (like form values)
    setFromValue({
      ...fromValue,
      allowed_permissions: fromValue.allowed_permissions.filter((id) => id !== (item._id || item)),
    });
  };
  //  console.log("propssetsnackOpen",props.setsnackOpen)
const Save =()=>{
  if (!validateForm()) {
    return;
  }
  console.log(fromValue.moduleName,fromValue,"fromValue======")
  axios({
    method: alreadyRole ? 'put' : 'post',
     url: alreadyRole?`${api}/auth/updateRole`:`${api}/auth/createRole`,
    data:  fromValue,  // Send data only if it's a POST request
    params: alreadyRole ? { Id: props.index?._id } : null  // Send params only for PUT request
  })
  // axios.post(`${api}/auth/createRole`,fromValue)
  .then((res)=>{
    console.log(res,res.data.data)
    props.setValue(false)
    console.log("res.data.data.message",res.data.message,res.data)
    props.setsnackOpen({open:true,message:res.data.message,severity:"success"});
    props.fetchRoles()
    resetForm()
    if (!alreadyRole) {
      localStorage.setItem("roleId", res.data.data._id);
    }
    
  }).catch((err)=>{
    console.log(err.response.data.message,"props",props.setsnackOpen,"errShowForRole")
    if (err?.response?.data?.message) {
      // If there is a message in the error response, use it
      props.setsnackOpen({ open: true, message: err.response.data.message, severity: "error" });
    }
    //  err?.response?.data?. props.setsnackOpen({open:true,message:err?.response?.data?.message,severity:"error"})
  })
}

  const list = () => (
    <Box display="flex" gap={3}sx={{width:400,
      // maxWidth:"100%",
      height: '100vh',
      display:"flex",justifyContent:"space-btween",
        backgroundColor:"transparent"
    }}>
        <Box sx={{backgroundColor:"#fff",height:"3%",padding:"13px",margin:"30px 0 0 0",display:"flex",justifyContent:"center",alignItems:"center",
            borderRadius:"80%", width:"5%"}}>
        <IconButton onClick={()=>{props.setValue(false);resetForm()}} sx={{color:"black"}}>
          <CloseIcon sx={{fontSize:"1.2rem"}} />
        </IconButton>
        </Box>
    <Box  sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll",
}}>
      <Typography  variant="h6" sx={{ textDecoration: 'underline',
          fontWeight: 'bold', 
          
          mb: 2 
       }}>{props.index !== 0?"Edit Role":"Add New Role"}</Typography>  
       
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Name of Role     
          </Typography>
         
          <CustomTextField
        //   select
      variant="outlined"
      value={fromValue.name}
      onChange={handleChange}
      fullWidth
      name="name"
      placeholder="Enter Name"
      error={!!errors.name} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.name ? '#D34040' : undefined },
      }}
    >
    </CustomTextField>
    {errors.name && (
          <Typography variant="body2" color="#D34040">
            {errors.name}
          </Typography>
        )}
        {console.log("selectedModuleName++--",selectedModuleName)}
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
       Module's Name    
          </Typography>
     {Array.isArray(selectedModuleName) && selectedModuleName.length > 0 &&  selectedModuleName.slice(0, 5).map((item)=>{
      return  <Chip
        key={item}
        label={item}
         onDelete={() => handleDeleteModule(item)}
        sx={{
          bgcolor: "#1C486B",
          color: "white",
          height:"28px",
          mb:0.5,
          "& .MuiChip-deleteIcon": {
            color: "white",
            "&:hover": {
              color: "white",
            },
          },
        }}
        deleteIcon={
          <IconButton sx={{ color: "white"}}>
            <CancelIcon sx={{height:"18px",width:"18px"}}/>
          </IconButton>
        }
      />
     })}  
       
          <Select
          // select
           multiple
           open={openSelect}
        
          //  input={<OutlinedInput />}
         variant="outlined"
        //  value={fromValue.moduleName}
        value={["Select"]}
          onChange={handleModuleModleChange}
         fullWidth
         name="modulesName"
         onClose={() => setOpen(false)} 
         onOpen={() => setOpen(true)}  
        placeholder="Tap to Select"
      renderValue={(selected) => {
        console.log("selectedselected",selected)
        if (!Array.isArray(selected)) return "";
        if (selected.length === 0) return "Tap to Select";
    
        return selected.join(", ");; // Join selected values with a comma
      }}
      error={!!errors.moduleName}
      displayEmpty
      InputLabelProps={{
        'aria-label': 'Without label',
        style: { color: errors.moduleName ? '#D34040' : undefined },
      }}
      sx={{height:"40px"}}
    >
       <MenuItem disabled value="">
    {/* Tap to Select */}
  </MenuItem>
      {/* <MenuItem value={fromValue.moduleName===""?"Tap to Select":fromValue.moduleName} disabled>
      {fromValue.moduleName===""?"Tap to Select":fromValue.moduleName} 
  </MenuItem> */}
       {moduleName.map((item,i) => (
        // console.log("item",item.name,i)
        <MenuItem key={i} 
        value={item}
        // onClick={()=>handleModuleModleSelection(item)}
        //    backgroundColor: selectedModuleName.includes(item) ? 'rgba(0, 123, 255, 0.2)' : 'transparent',
        sx={{
          backgroundColor: selectedModules.includes(item) ? 'rgba(0, 123, 255, 0.2)' : 'transparent',
          
          '&:hover': {
            backgroundColor: 'rgba(0, 123, 255, 0.2)', // Light hover effect
          },
          display: 'flex',
          justifyContent: 'space-between',
        }}
       >
          {item}
        </MenuItem>
      ))}
   

    </Select>
    {errors.moduleName && (
          <Typography variant="body2" color="#D34040">
            {errors.moduleName}
          </Typography>
        )}


<Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
  Permissions
</Typography>

{/* {selectedPermissions.map((item)=>{
  console.log("console.log(fromValue.allowed_permissions)",selectedPermissions, item, 
    PermissionsName.filter((data) => data._id === item? data.name:""));

      return  <Chip
        key={item}
        // label={item.name}
        label={typeof item === 'object'?
          item.name:
          // PermissionsName.filter((data) => data._id === item)
          // .map((data) => data.name)
          // .join(", ") 
           PermissionsName.find((perm) => perm._id === item)?.name || ''
        }
         onDelete={() => handleDelete(item)}
        sx={{
          bgcolor: "#1C486B",
          color: "white",
          height:"28px",
          mb:0.5,
          "& .MuiChip-deleteIcon": {
            color: "white",
            "&:hover": {
              color: "white",
            },
          },
        }}
        deleteIcon={
          <IconButton sx={{ color: "white"}}>
            <CancelIcon sx={{height:"18px",width:"18px"}}/>
          </IconButton>
        }
      />
     })}  */}

{/* <Select
   multiple // Enables multi-select functionality
  variant="outlined"
  input={<OutlinedInput />}
  // value={selectedPermissions} // Ensure this is always an array
  value={selectedPermissions.length > 0 && typeof selectedPermissions[0] === 'object'?
    selectedPermissions.map(perm => perm._id):
    selectedPermissions
  } 
   onChange={handleModuleChange}
  // onChange={(event) => setSelectedPermissions(event.target.value)}
  fullWidth
  name="allowed_permission"
  renderValue={(selected) => {
    if (!Array.isArray(selected)) return ""; // Prevent error if not an array
    if (selected.length === 0) return "Tap to Select"; // Placeholder

    // Return a comma-separated string of selected item names
    return selected
      .map((id) => {
        const selectedItem = PermissionsName.find((item) => item?._id === id);
        return selectedItem ? selectedItem.name : null; // Ensure we return null instead of undefined
      })
      .filter(Boolean) // Filter out null values before joining
      .join(", ");
  }}
  error={!!errors.allowed_permissions}
  displayEmpty
  inputProps={{ 'aria-label': 'Without label' }}
  sx={{ mt: 1, height: "40px" }}
>
  <MenuItem disabled value="">
    {/* Tap to Select */}
  {/* </MenuItem> */}
  {/* {console.log("selectedPermissions----",selectedPermissions,PermissionsName)}
  {PermissionsName.map((item) => (
    
    <MenuItem
      key={item._id}
      value={item._id}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      {item.name}
      <Checkbox
      //  checked={selectedPermissions.includes(item._id)} 
        //  checked={selectedPermissions.some((perm) => perm._id === item._id)} 
        checked={
          selectedPermissions.length > 0 && typeof selectedPermissions[0] === 'object'// Check if first item is an object (indicating array of objects)
            ? selectedPermissions.some((perm) => perm._id === item._id) // If it's an array of objects, use `some()`
            : selectedPermissions.includes(item._id) // If it's an array of `_id`s, use `includes()`
        }
       onChange={() => handleModuleSelection(item._id)} 
        sx={{ ml: 2}} // Adds margin to the left for spacing
      color="success"
      />
      {console.log("selectedPermissions----", Array.isArray(selectedPermissions[0]),selectedPermissions.some((perm) => perm._id === item._id),selectedPermissions.includes(item._id))}
    </MenuItem>
  ))}
</Select> */}

<FormGroup>
{PermissionsName.map((item) => (
      <FormControlLabel control={<Checkbox 
        onChange={handleModuleChange}
        value={item._id}
        checked={selectedPermissions.some((perm) => perm._id === item._id)} />}
     
      
      label={item.name}/>
))}
</FormGroup>


{errors.allowed_permissions && (
  <Typography variant="body2" color="#D34040">
    {errors.allowed_permissions}
  </Typography>
)}




    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Remark  
          </Typography>
         
          <TextField
          // select
          rows={2}
      variant="outlined"
      value={fromValue.remark}
       onChange={handleChange}
      fullWidth
      name="remark"
      placeholder="Brief"
      multiline
    >
      
    </TextField>
 
       <Box>
       <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none",    marginTop: "10%"}} 
             onClick={Save}

            >
              Submit
            </Button>
       </Box>
    </Box>
    </Box>
  );
 
    return (
        <div>
            

              <Drawer
               sx={{
                backgroundColor: "transparent",
            '& .MuiDrawer-paper': { // This targets the paper component within the Drawer
                   backgroundColor: "transparent", // Make paper transparent
                   boxShadow: 'none', // Optional: Remove any shadow
                 }
             }}
                anchor="right"
                open={props.value}
                onClose={()=>{props.setValue(false);resetForm();}}
              >
                {list()}
              </Drawer>
              
      
      
        </div>
      );
    }
export default AddRole;





//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '40px', // Adjust height as needed
    },
    '& .MuiSelect-select': {
      height: '56px', // Adjust height as needed
      display: 'flex',
      alignItems: 'center',
    },
  }));
  