import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, styled, Box, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import VerifyOtp from './VerifyOtp';
import InputField from './InputField';
import { formatTime } from '../../utils/formatTime';
import { api } from '../../utils/api';
import axios from 'axios';

const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        height: "40px",
    },
}));

const PhoneField = ({ title, value,fieldName, placeholder,element,countryCode,setFormData,formData, showIcon,setErrors,errors,verify,setVerify,verifybtn,setverifybtn,TaskAltIcons,setTaskAltIcons }) => {
      const [otp, setOTP] = useState({
        phone: null,
      });

//       const [verifybtn, setverifybtn] = useState({ email: false, phone: false });
//   const [verify, setVerify] = useState({
//     phone: false,
//     phoneMsg: "",
//   });

  const [timerPhone, setTimerPhone] = useState(120);
  const [timerActive, setTimerActive] = useState({
    phone: false,
  });
    
  useEffect(() => {
    if (timerActive.phone && timerPhone > 0) {
      const timer = setInterval(() => {
        setTimerPhone((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setTimerActive((prev) => ({ ...prev, phone: false }));
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timerActive.phone]); // Corrected dependency


    const handleInputChange = (event) => {
        const inputValue = event.target.value;

        // Allow only numeric input and restrict to 10 digits
        if (/^\d{0,10}$/.test(inputValue)) {
            handlePhoneChange(event);
        }
    };


    const handlePhoneChange = (e) => {
        const { value } = e.target;
      
        // Reset the icons and error state when phone changes
        setTaskAltIcons({ ...TaskAltIcons, phone: false });
        setVerify({ ...verify, phone: false, phoneMsg: "" });
        setverifybtn({ ...verifybtn, phone: false });
        setTimerPhone(120); // Reset timer
        setErrors({ ...errors, contactNumber: "" });
      
        // Ensure country code is selected before validating the phone number
        if (formData?.contactNumber.countryCode === "") {
          setErrors({
            ...errors,
            contactNumber: "Country Code is required. Please Select Country",
          });
          return;
        }
      
        // Reset OTP field when phone number is updated
        setOTP((prevOtp) => ({
          ...prevOtp,
          phone: "", // Reset OTP value
        }));
      
        // Check if the input value is a valid 10-digit mobile number (only numeric)
        const phoneNumberPattern = /^[0-9]{0,10}$/; // Accept only up to 10 digits (numeric)
      
        // Validate if the number is numeric and doesn't exceed 10 digits
        if (!phoneNumberPattern.test(value)) {
            setErrors({...errors,contactNumber:"Please Enter 0 - 9 digit only."})
            return; // If the value is not numeric or exceeds 10 digits, do nothing
        }

      
        // If value has a length greater than 10, stop further action
        if (value.length > 10) return;
      
        // Update formData only when phone number is valid
        setFormData((prevFormData) => ({
          ...prevFormData,
          contactNumber: {
            ...prevFormData.contactNumber,
            mobileNo: value, // Update the mobile number
          },
        }));
    
         // If the phone number hasn't changed, just return and update TaskAltIcon
         if (value === element?.contactNumber.mobileNo) {
          setTaskAltIcons({ ...TaskAltIcons, phone: true });
          return;
        }
      
        // Enable the verify button if phone number is exactly 10 digits
        if (value.length === 10) {
          setverifybtn({ ...verifybtn, phone: true });
        }
      };
      

      const handleVerifyPhone = async (timePhone) => {
        if (timePhone > 0) {
          setverifybtn({ ...verifybtn, phone: false });
          setTimerActive((prev) => ({ ...prev, phone: true })); // Start timer
          await axios
            .post(`${api}/auth/sendOtpToPhone`, {
              phone: formData.contactNumber.mobileNo,
              countryCode: formData.contactNumber.countryCode,
              module: "Purchase",
            })
            .then((res) => {
              console.log("res", res);
              if(res.status===200)
                setVerify({ ...verify, phone: true });
            })
            .catch((err) => {
              console.log(err);
              if (err.response.data.statusCode===false) {
                setErrors({...errors,contactNumber:"Mobile Number already registered."})
                // setVerify({ ...verify, email: true });
                // setverifybtn({ ...verifybtn, email: false });
              }
            });
        } else {
          setVerify({ ...verify, phone: false });
          setverifybtn({ ...verifybtn, phone: false });
        }
      };
    

      const handleOTPVerifyPhone = (e) => {
        const { value } = e.target;
    
        // Only allow numeric values and ensure length does not exceed 4
        const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion
    
        // Update state only if the value is numeric and has a length <= 4
        if (isNumeric && value.length <= 4) {
          setVerify({ ...verify, phoneMsg: "" });
          setOTP((prevOtp) => ({
            ...prevOtp,
            phone: value, // Update the OTP value
          }));
          // Trigger API call when exactly 4 digits are entered
          if (value.length > 4)
            setVerify({ ...verify, phoneMsg: "Enter 4 digit OTP" });
          if (value.length === 4) {
            axios
              .post(`${api}/auth/verifyOtpForPhone`, {
                phone: formData?.contactNumber.mobileNo,
                countryCode: formData?.contactNumber.countryCode,
                otp: value,
              })
              .then((res) => {
                console.log("OTP verified successfully:", res, res.status);
                if (res.status === 200) {
                  setVerify({ ...verify, phone: false });
                  setverifybtn({ ...verifybtn, phone: false });
                  setTaskAltIcons({ ...TaskAltIcons, phone: true });
                  setErrors({ ...errors, contactNumber: "" });
                }
              })
              .catch((err) => {
                console.error("Error verifying OTP:", err);
                setVerify({ ...verify, phoneMsg: "Invalid OTP" });
                setTaskAltIcons({ ...TaskAltIcons, phone: false });
              });
          }
        }
      };
    
    
    const handleResendPhone = () => {
        setTimerPhone(120); // Reset time to 120 seconds
        setTimerActive((prev) => ({ ...prev, phone: true })); // Start the timer
        handleVerifyPhone(120); // Call the phone verification function
      };

    return (
        <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
                {title}
            </Typography>
            <CustomTextField
                variant="outlined"
                fullWidth
                value={value}
                placeholder={placeholder}

                // disabled={!countryCode}
                onChange={handleInputChange} // Use the new input change handler
                InputProps={{
                    maxLength: 10, // Visual limit
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    endAdornment: (
                        showIcon && <TaskAltIcon sx={{ color: "green" }} />
                    ),
                    startAdornment: (
                        <InputAdornment position="start" sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* Render placeholder or countryCode */}
                            {countryCode ? <div style={{ width: "40px", marginRight: "8px", fontWeight: "bold", }}>
                                {countryCode}
                            </div>
                            :<div style={{ width: "40px", marginRight: "8px",color: "#B0B0B0",}}>
                            {"code"}
                        </div>}
                            {/* Vertical Line */}
                            <div style={{ borderRight: "1px solid #000", height: "24px", marginRight: "8px" }} />
                        </InputAdornment>
                    ),
                }}
            />
            {errors && (
                <Typography variant="body2" color="#D34040">
                    {errors}
                </Typography>
            )}

{verifybtn.phone && formData?.contactNumber?.mobileNo !== "" && (
            <VerifyOtp
              handleChange={() => handleVerifyPhone(120)}
              text={"Verify"}
            />
          )}
          {verify.phone && (
            <Box>
              <InputField
                value={otp.phone}
                title="Enter OTP For Phone"
                handleChange={handleOTPVerifyPhone}
                name="otpPhone"
                placeholder="Enter OTP"
                inputProps={{
                  maxLength: 4,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
              <Typography variant="body2" color="#D34040">
                {verify.phoneMsg}
              </Typography>
              {timerActive.phone ? (
                <Box sx={{ textAlign: "right" }}>{formatTime(timerPhone)}</Box>
              ) : (
                <VerifyOtp
                  handleChange={() => handleResendPhone()}
                  text="Resend"
                />
              )}
            </Box>
          )}
        </Box>
    );
};

export default PhoneField;
