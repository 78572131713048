import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import axios from "axios";

const MachineryNameFilter = ({ selectedMachineryNames = [], setSelectedMachineryNames, url, errors }) => {
  const [machineryNames, setMachineryNames] = useState([]);

  // Fetch all machinery names from the backend
  useEffect(() => {
    const fetchMachineryNames = async () => {
      try {
        const response = await axios.get(url);  // Fetch machine data from the given URL
        console.log("Response from backend:", response);
        
        // Extract unique machinery names from the response data
        const uniqueNames = response.data.data
          ? [...new Set(response.data.data.map((machinery) => machinery.name))]
          : [];
          
        console.log("Unique Machinery Names:", uniqueNames);
        setMachineryNames(uniqueNames);
      } catch (error) {
        console.error("Error fetching machinery names:", error);
      }
    };

    fetchMachineryNames();
  }, [url]);  // Reload machinery names when the URL changes

  const handleNameChange = (event) => {
    const selectedName = event.target.value;
    if (event.target.checked) {
      // Add selected name to the state
      setSelectedMachineryNames((prevSelected) => [...prevSelected, selectedName]);
    } else {
      // Remove the name from the state
      setSelectedMachineryNames((prevSelected) =>
        prevSelected.filter((name) => name !== selectedName)
      );
    }
  };

  return (
    <Box sx={{ m: "5%", mt: 0 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", marginBottom: "30px" }}
      >
        Machinery Names
      </Typography>
      <Box className="scrollable-container">
        {machineryNames.length > 0 ? (
          machineryNames.map((name, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={name}
                  checked={selectedMachineryNames?.includes(name)}
                  onChange={handleNameChange}
                />
              }
              label={name || "Unknown Name"} // Fallback for missing names
            />
          ))
        ) : (
          <Typography>Loading machinery names...</Typography>
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default MachineryNameFilter;
