import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import style from "../../../../styles/common/formModal.module.css";
import { formatDate } from "../../../../utils/formatDate";

const View = ({
  isOpen,
  onClose,
  header,
  initialData,
  handleEdit,
}) => {
  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className={style.main_div}>
        <Typography
          id="modal-title"
          variant="h5"
          component="h2"
          align="left"
          style={{
            textDecoration: "underline",
            marginBottom: "1rem",
            fontSize: "24px",
            fontWeight: "600",
          }}
        >
          {header}
        </Typography>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>Date</p>
            <p>{formatDate(initialData?.createdAt)}</p>
          </div>
          <div>
            <p>Facility</p>
            <p>{initialData?.data?.facility?.facilityName}</p>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>Origin</p>
            <p>{initialData?.origin}</p>
          </div>
          <div>
            <p>Destination</p>
            <p>{initialData?.destination}</p>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>Shipment Category</p>
            <p>{initialData?.categoryOfShipment}</p>
          </div>
          <div>
            <p>Shipment Type</p>
            <p>{initialData?.typeOfShipment}</p>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>Freight Load (Tonnes)</p>
            <p>{initialData?.freightLoad}</p>
          </div>
        </div>

        <Button
          onClick={() => handleEdit(initialData)}
          color="secondary"
          variant="outlined"
        >
          Edit
        </Button>
      </Box>
    </Modal>
  );
};

export default View;
