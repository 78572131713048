import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  Drawer,
  IconButton,
  Collapse,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
// import  from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../utils/api";
import { companyId1, userId } from "../../../../utils/companyId";
import { fuelConsumptionUnits, FuelTypeEnum } from "../../../../utils/constant";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { capitalizeFirstLetter } from "../../../../utils/common";

function AddEquipment(props) {
  const [alreadyFacility, setAlreadyFacility] = useState(false);
  const [facilities, setFacilities] = useState(["Select"]); // Store list of facilities
  const [equipmentType, setEquipmentType] = useState(["Select"]); // Store list of facilities
  const [fuelType, setFuelType] = useState(["Select"]); // Store list of facilities
  const [unitConsumption, setUnitConsumption] = useState(["Select"]); // Store list of facilities
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null); // to track which equipment is being deleted

  const [equipments, setEquipments] = useState([]);

  console.log("props", props.selectedFacility);

  const [noOfEquipment, setNoOfEquipment] = useState(0);

  useEffect(() => {
    if (props.selectedFacility) {
      setEquipments(props.selectedFacility.equipments);
      setNoOfEquipment(props.selectedFacility.noOfEquipment);
    }
  }, [props]);
  // Dynamically toggle collapse for each equipment
  const [openMachines, setOpenMachines] = useState(
    new Array(equipments.length).fill(false)
  );
  const [showAddForm, setShowAddForm] = useState(false);
  const [newEquipmentType, setNewEquipmentType] = useState("");
  const initialState = {
    location: "",
    facility: "",
    equipmentType: "",
    name: "",
    fuelType: "",
    noOfEquipment: "",
    remark: "",
    companyId: companyId1,
    userId: userId,
    equipments: [],
  };

  const [errors, setErrors] = useState({});
  const [fromValue, setFromValue] = useState(initialState);

  useEffect(() => {
    fetchFacilities();
    setFromValue({
      location: props.selectedFacility?.location ?? "",
      facility: props.selectedFacility?.facility?._id ?? "",
      equipmentType: props.selectedFacility?.equipmentType?._id ?? "",
      name: props.selectedFacility?.name ?? "",
      fuelType: props.selectedFacility?.fuelType ?? "",
      equipments: props.selectedFacility?.equipments ?? [],
      noOfEquipment: props.selectedFacility?.noOfEquipment ?? "",
      remark: props.selectedFacility?.remark ?? "",
      companyId: companyId1,
      userId: userId,
    });
    setAlreadyFacility(props.selectedFacility === null ? false : true);
    setEquipments(props.selectedFacility?.equipments ?? []);
  }, [props.selectedFacility]);

  useEffect(() => {
    setNoOfEquipment(equipments.length);
  }, [equipments]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFromValue({
      ...fromValue,
      [name]: name === "noOfEquipment" ? Number(value) : value, // Ensure noOfEquipment is stored as a number
    });

    // When noOfEquipment changes, dynamically create forms
    if (name === "noOfEquipment") {
      const numOfForms = parseInt(value);
      const equipmentArray = Array.from({ length: numOfForms }, (_, index) => ({
        uniqueID: "",
        brand: "",
        model: "",
        ratedFuelConsumption: "",
        unitOfFuelConsumption: "",
      }));
      setEquipments(equipmentArray);
    }

    if (name === "equipmentType" && value !== undefined) {
      setShowAddForm(false); // Hide the Add More form when an option is selected
    }
    // Clear the error for the specific field if there was one
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the specific field error
      }));
    }
  };

  const handleDeleteAgree = () => {
    // if (selectedDeleteIndex !== null) {
    //   const updatedEquipments = equipments.filter((_, index) => index !== selectedDeleteIndex);
    //   setEquipments(updatedEquipments);
    //   setSelectedDeleteIndex(null);
    //   setNumberOfMachines(updatedEquipments.length);
    // }
    setEquipments((prevEquipments) => {
      const updatedEquipments = [...prevEquipments];
      updatedEquipments.splice(deleteIndex, 1);
      return updatedEquipments;
    });

    // Update the number of equipment
    setNoOfEquipment((prevCount) => prevCount - 1);

    // Optionally update the collapse state if needed
    setOpenMachines((prevOpenMachines) => {
      const updatedOpenMachines = [...prevOpenMachines];
      updatedOpenMachines.splice(deleteIndex, 1);
      return updatedOpenMachines;
    });
    setDeleteDialog(false);
  };

  const handleAddClick = async () => {
    if (newEquipmentType.trim()) {
      try {
        // Call the API with the new equipment type
        const response = await axios.post(`${api}/master/addEquipmentType`, {
          name: newEquipmentType,
        });

        if (response.status === 200 || response.status === 201) {
          // refreshEquipmentTypes(); // Refresh the equipment type options if needed
          setNewEquipmentType(""); // Clear input
          setShowAddForm(false); // Hide the form
          // fetchFacilities();
          getAllEQuipment();
        }
      } catch (error) {
        props.setsnackOpen({
          open: true,
          message: "Equipment already exists",
          severity: "error",
        });
        setNewEquipmentType(""); // Clear input
        setShowAddForm(false);
        console.error("Error adding new equipment type:", error);
      }
    }
  };

  const deleteEquipment = (index) => {
    setDeleteIndex(index);
    setDeleteDialog(true);
    // setEquipments((prevEquipments) => {
    //   const updatedEquipments = [...prevEquipments];
    //   updatedEquipments.splice(index, 1);
    //   return updatedEquipments;
    // });

    // // Update the number of equipment
    // setNoOfEquipment((prevCount) => prevCount - 1);

    // // Optionally update the collapse state if needed
    // setOpenMachines((prevOpenMachines) => {
    //   const updatedOpenMachines = [...prevOpenMachines];
    //   updatedOpenMachines.splice(index, 1);
    //   return updatedOpenMachines;
    // });
  };

  const toggleEquipment = (index) => {
    const updatedOpen = [...openMachines];
    updatedOpen[index] = !updatedOpen[index];
    setOpenMachines(updatedOpen); //
  };

  const handleFieldChange = (index, field, value) => {
    const updatedEquipments = [...equipments];
    updatedEquipments[index][field] = value;

    if (field === "ratedFuelConsumption") {
      // Allow only numeric values
      const numericValue = value.replace(/[^0-9.]/g, ""); // Only allow numbers and decimal point
      updatedEquipments[index][field] = numericValue;

      // Validate numeric input for the Rated Fuel Consumption
      if (
        numericValue.trim() === "" ||
        isNaN(numericValue) ||
        parseFloat(numericValue) <= 0
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`equipment_${index}_ratedFuelConsumption`]: `Rated Fuel Consumption must be a valid positive number for Equipment ${
            index + 1
          }.`,
        }));
      } else {
        // Clear the specific field error if the input is valid
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`equipment_${index}_ratedFuelConsumption`]: "",
        }));
      }
    } else {
      updatedEquipments[index][field] = value;

      // Clear the specific field error if it exists
      if (errors[`equipment_${index}_${field}`]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`equipment_${index}_${field}`]: "", // Clear the specific field error
        }));
      }
    }

    setEquipments(updatedEquipments);

    // if (errors[`equipment_${index}_${field}`]) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [`equipment_${index}_${field}`]: "", // Clear the specific field error
    //   }));
    // }
  };

  const addMoreEquipment = () => {
    const newEquipment = {
      uniqueID: "",
      brand: "",
      model: "",
      ratedFuelConsumption: "",
      unitOfFuelConsumption: "",
    };

    // Add new equipment and update state
    setEquipments((prevEquipments) => [...prevEquipments, newEquipment]);
    // Update the number of equipment
    setNoOfEquipment((prevCount) => prevCount + 1);
    // Update openMachines state to include a new entry
    setOpenMachines((prevOpenMachines) => [...prevOpenMachines, false]);
    console.log(noOfEquipment, "noOfEquipmentnoOfEquipment", equipments);
  };

  const getDropDownFieldId = (data) => {
    if (typeof data === "string") {
      return data; // If it's a direct ID, return it
    }
    return data?._id || ""; // If it's an object, return the _id
  };

  const fetchFacilities = async () => {
    try {
      const response = await axios.get(`${api}/master/getAllFacility`);
      const equipmentTypeResponse = await axios.get(
        `${api}/master/getAllEquipmentType`
      );

      setFacilities(response.data.data);
      setEquipmentType(equipmentTypeResponse.data.data);
      setFuelType(FuelTypeEnum);
      setUnitConsumption(fuelConsumptionUnits);
    } catch (error) {
      console.log("Error fetching facilities:", error);
    }
  };

  const getAllEQuipment = async () => {
    try {
      const equipmentTypeResponse = await axios.get(
        `${api}/master/getAllEquipmentType`
      );
      setEquipmentType(equipmentTypeResponse.data.data);
    } catch (error) {
      console.log("Error fetching facilities:", error);
    }
  };

  const Save = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const updatedNoOfEquipment = equipments.length;

    const dataToSubmit = {
      ...fromValue, // all the current form values
      equipments: equipments,
      noOfEquipment: updatedNoOfEquipment, // Use the updated value
    };

    dataToSubmit.location = dataToSubmit.location;
    dataToSubmit.name = dataToSubmit.name;

    console.log(dataToSubmit, "dataToSubmitdataToSubmitdataToSubmit");
    if (!validateForm()) {
      return;
    }

    axios({
      method: alreadyFacility ? "put" : "post",
      url: alreadyFacility
        ? `${api}/master/updateIndustrialEquipment`
        : `${api}/master/addIndustrialEquipment`,
      data: dataToSubmit, // Send data only if it's a POST request
      params: alreadyFacility ? { id: props.selectedFacility?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        props.setAddFacility(false);
        props.fetchFacilities();
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        // setNoOfEquipment(null);
        resetForm();

        //  localStorage.setItem("roleId",res.data.data._id)
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          // If there is a message in the error response, use it
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const validateForm = () => {
    const newErrors = {};
    const { location, facility, equipmentType, name, fuelType, noOfEquipment } =
      fromValue;

    // Validate main fields
    if (!location || location.trim() === "") {
      newErrors.location = "Location is required.";
    }

    if (!facility || facility === "Select") {
      newErrors.facility = "Please select a facility.";
    }

    if (
      !equipmentType ||
      equipmentType === "Select" ||
      equipmentType == "other"
    ) {
      newErrors.equipmentType = "Please select an equipment type.";
    }

    if (!name || name.trim() === "") {
      newErrors.name = "Equipment name is required.";
    }

    if (!fuelType || fuelType.trim() === "") {
      newErrors.fuelType = "Fuel type is required.";
    }

    if (
      (!noOfEquipment ||
        isNaN(noOfEquipment) ||
        parseInt(noOfEquipment) <= 0) &&
      equipments.length === 0
    ) {
      newErrors.noOfEquipment = "Number of equipment must be a valid number.";
    }

    // Validate each equipment form
    equipments.forEach((equipment, index) => {
      if (!equipment.uniqueID || equipment.uniqueID.trim() === "") {
        newErrors[
          `equipment_${index}_uniqueID`
        ] = `Unique ID is required for Equipment ${index + 1}.`;
      }
      if (!equipment.brand || equipment.brand.trim() === "") {
        newErrors[
          `equipment_${index}_brand`
        ] = `Brand is required for Equipment ${index + 1}.`;
      }
      if (!equipment.model || equipment.model.trim() === "") {
        newErrors[
          `equipment_${index}_model`
        ] = `Model is required for Equipment ${index + 1}.`;
      }
      if (
        !equipment.ratedFuelConsumption ||
        equipment.ratedFuelConsumption.trim() === ""
      ) {
        newErrors[
          `equipment_${index}_ratedFuelConsumption`
        ] = `Rated Fuel Consumption is required for Equipment ${index + 1}.`;
      }
      if (
        !equipment.unitOfFuelConsumption ||
        equipment.unitOfFuelConsumption.trim() === ""
      ) {
        newErrors[
          `equipment_${index}_unitOfFuelConsumption`
        ] = `Unit of Fuel Consumption is required for Equipment ${index + 1}.`;
      }
    });

    // Check for duplicate unique IDs
    const uniqueIds = equipments.map((equipment) => equipment.uniqueID);
    uniqueIds.forEach((id, idx) => {
      if (uniqueIds.indexOf(id) !== idx) {
        newErrors[`equipment_${idx}_uniqueID`] = "Unique ID must be unique.";
      }
    });

    if (equipments.length < parseInt(noOfEquipment)) {
      for (let i = equipments.length; i < parseInt(noOfEquipment); i++) {
        newErrors[`equipment_${i}_data`] = `Please select Equipment ${
          i + 1
        } data.`;
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const resetForm = () => {
    // setFromValue(
    //   props.selectedFacility === null ? initialState : props.selectedFacility
    // );
    // setFacilities([]);

    setEquipments([]); // Reset equipments
    setNoOfEquipment(null); // Reset number of equipment
    setOpenMachines([]); // Reset open machines state if necessary
    setErrors({}); // Clear any existing errors
    setAlreadyFacility(false);
    setShowAddForm(false);
    setNewEquipmentType("");
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 400,
        // maxWidth:"100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-btween",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {
            props.setAddFacility(false);
            props.fetchFacilities();
            resetForm();
          }}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: "24px",
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          {props.selectedFacility?._id === undefined
            ? "Add Indusrial Equipment Master"
            : "Edit Indusrial Equipment Master"}
        </Typography>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Location
        </Typography>

        <CustomTextField
          //   select
          variant="outlined"
          value={fromValue.location}
          onChange={handleChange}
          fullWidth
          name="location"
          placeholder="Enter Name"
          error={!!errors.location} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.location ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.location && (
          <Typography variant="body2" color="#D34040">
            {errors.location}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Facility
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          value={
            fromValue.facility === "" || undefined
              ? "Tap to Select"
              : getDropDownFieldId(fromValue.facility)
          }
          onChange={handleChange}
          fullWidth
          name="facility"
          placeholder="Tap to Select"
          error={!!errors.facility} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.facility ? "#D34040" : undefined },
          }}
        >
          {/* {console.log("fromValue.facility",fromValue.facility)} */}
          <MenuItem
            value={
              fromValue.facility === "" || undefined
                ? "Tap to Select"
                : fromValue.facility
            }
            disabled
          >
            {/* Tap to Select */}
            {fromValue.facility === "" || undefined
              ? "Tap to Select"
              : fromValue.facility?.facilityName || fromValue.facility}
          </MenuItem>{" "}
          {facilities.map((item, i) => (
            <MenuItem key={i} value={item._id}>
              {item.facilityName}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.facility && (
          <Typography variant="body2" color="#D34040">
            {errors.facility}
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Equipment Type
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          value={
            fromValue.equipmentType === "" || undefined
              ? "Tap to Select"
              : getDropDownFieldId(fromValue.equipmentType)
          }
          onChange={handleChange}
          fullWidth
          name="equipmentType"
          placeholder="Tap to Select"
          error={!!errors.equipmentType} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.equipmentType ? "#D34040" : undefined },
          }}
        >
          <MenuItem
            value={
              fromValue.equipmentType === "" || undefined
                ? "Tap to Select"
                : fromValue.equipmentType
            }
            disabled
          >
            {fromValue.equipmentType === "" || undefined
              ? "Tap to Select"
              : fromValue.equipmentType?.name}
          </MenuItem>{" "}
          {equipmentType.map((item, i) => (
            <MenuItem key={i} value={item._id}>
              {item.name}
            </MenuItem>
          ))}
          <MenuItem onClick={() => setShowAddForm(true)} value="other">
            Other
          </MenuItem>
        </CustomTextField>
        {showAddForm && (
          <Box sx={{ mt: 1 }}>
            <CustomTextField
              variant="outlined"
              fullWidth
              placeholder="Enter name"
              value={newEquipmentType}
              onChange={(e) => setNewEquipmentType(e.target.value)}
            />
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleAddClick}
              sx={{ mt: 1 }}
            >
              Add Equiment
            </Button> */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#0a3f73",
                textTransform: "none",
                marginTop: "3%",
              }}
              onClick={handleAddClick}
            >
              Add
            </Button>
          </Box>
        )}
        {errors.equipmentType && (
          <Typography variant="body2" color="#D34040">
            {errors.equipmentType}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Equipment Name
        </Typography>

        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.name}
          onChange={handleChange}
          fullWidth
          name="name"
          placeholder="Enter name"
          error={!!errors.name} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.name ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.name && (
          <Typography variant="body2" color="#D34040">
            {errors.name}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Fuel type
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          fullWidth
          name="fuelType"
          value={
            fromValue.fuelType === "" || undefined
              ? "Tap to Select"
              : fromValue.fuelType
          } // Set empty string as default if no value is selected
          onChange={handleChange}
          placeholder="Select fuel type"
          error={!!errors.fuelType}
          InputLabelProps={{
            style: { color: errors.fuelType ? "#D34040" : undefined },
          }}
        >
          {/* Disabled option as the default prompt */}
          <MenuItem
            value={
              fromValue.fuelType === "" || undefined
                ? "Tap to Select"
                : fromValue.fuelType
            }
            disabled
          >
            Tap to Select
          </MenuItem>

          {/* Map through FuelTypeEnum for dropdown options */}
          {fuelType.map((item, index) => (
            <MenuItem
              key={`fuelType__${item}${index}`}
              value={item}
              sx={{
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
                padding: "10px 20px",
                borderRadius: "4px",
              }}
            >
              {item}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.fuelType && (
          <Typography variant="body2" color="#D34040">
            {errors.fuelType}
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          No of Equipment
        </Typography>

        <CustomTextField
          //  select
          disabled={alreadyFacility} // Disable if editing an existing facility
          variant="outlined"
          // value={fromValue.noOfEquipment}
          value={noOfEquipment || ""} // Dynamically updated by addMoreEquipment or deleteEquipment
          onChange={(e) => {
            setNoOfEquipment(Number(e.target.value)); // Update state on change
            handleChange(e); // Call your existing handleChange function
          }}
          fullWidth
          name="noOfEquipment"
          placeholder="Enter No of Equipment"
          error={!!errors.noOfEquipment} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.noOfEquipment ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        <br />
        {errors.noOfEquipment && (
          <Typography variant="body2" color="#D34040">
            {errors.noOfEquipment}
          </Typography>
        )}

        <Dialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          BackdropProps={{
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.02)", // Adjust the opacity as needed (0.3 is lighter)
            },
          }}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to delete this entry?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            {/* onClick={() => deleteEquipment(index)} */}
            <Button onClick={handleDeleteAgree} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dynamically Render Equipment Forms */}
        {equipments.length > 0 &&
          equipments.map((equipment, index) => (
            <Box key={index} sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Equipment {index + 1}
                </Typography>

                <Box>
                  <IconButton onClick={() => toggleEquipment(index)}>
                    {openMachines[index] ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </IconButton>

                  <IconButton
                    onClick={() => deleteEquipment(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              {errors[`equipment_${index}_brand`] && (
                <Typography variant="body2" color="#D34040">
                  {`Please fill Equipment ${index + 1} form`}
                </Typography>
              )}

              <Collapse in={openMachines[index]} timeout="auto" unmountOnExit>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Unique ID
                  </Typography>
                  <CustomTextField
                    variant="outlined"
                    value={equipment.uniqueID}
                    placeholder="Enter ID"
                    fullWidth
                    onChange={(e) =>
                      handleFieldChange(index, "uniqueID", e.target.value)
                    }
                  />
                  {errors[`equipment_${index}_uniqueID`] && (
                    <Typography variant="body2" color="#D34040">
                      {errors[`equipment_${index}_uniqueID`]}
                    </Typography>
                  )}
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Brand
                  </Typography>
                  <CustomTextField
                    variant="outlined"
                    value={equipment.brand}
                    placeholder="Enter Brand"
                    fullWidth
                    onChange={(e) =>
                      handleFieldChange(index, "brand", e.target.value)
                    }
                  />
                  {errors[`equipment_${index}_brand`] && (
                    <Typography variant="body2" color="#D34040">
                      {errors[`equipment_${index}_brand`]}
                    </Typography>
                  )}
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Model
                  </Typography>
                  <CustomTextField
                    variant="outlined"
                    value={equipment.model}
                    placeholder="Enter Model"
                    fullWidth
                    onChange={(e) =>
                      handleFieldChange(index, "model", e.target.value)
                    }
                    // error={!!errors[`equipment_${index}_model`]}
                    // helperText={errors[`equipment_${index}_model`]}
                  />
                  {errors[`equipment_${index}_model`] && (
                    <Typography variant="body2" color="#D34040">
                      {errors[`equipment_${index}_model`]}
                    </Typography>
                  )}
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Rated Fuel Consumption
                  </Typography>
                  <CustomTextField
                    variant="outlined"
                    value={equipment.ratedFuelConsumption}
                    placeholder="Amount"
                    fullWidth
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        "ratedFuelConsumption",
                        e.target.value
                      )
                    }
                  />
                  {errors[`equipment_${index}_ratedFuelConsumption`] && (
                    <Typography variant="body2" color="#D34040">
                      {errors[`equipment_${index}_ratedFuelConsumption`]}
                    </Typography>
                  )}

                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Unit of Fuel Consumption
                  </Typography>
                  <CustomTextField
                    select
                    variant="outlined"
                    fullWidth
                    value={
                      equipment.unitOfFuelConsumption === "" || undefined
                        ? "Tap to Select"
                        : equipment.unitOfFuelConsumption
                    }
                    // value={equipment.unitOfFuelConsumption || "Tap to Select"}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        "unitOfFuelConsumption",
                        e.target.value
                      )
                    }
                    placeholder="Tap to Select"
                    error={!!errors[`equipment_${index}_unitOfFuelConsumption`]}
                    InputLabelProps={{
                      style: {
                        color: errors[
                          `equipment_${index}_unitOfFuelConsumption`
                        ]
                          ? "#D34040"
                          : undefined,
                      },
                    }}
                  >
                    <MenuItem
                      value={
                        equipment?.unitOfFuelConsumption === "" || undefined
                          ? "Tap to Select"
                          : equipment.unitOfFuelConsumption
                      }
                      disabled
                    >
                      Tap to Select
                    </MenuItem>

                    {unitConsumption.map((item, index) => (
                      <MenuItem
                        key={`unitConsumption__${item}${index}`}
                        value={item}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#f5f5f5",
                          },
                          padding: "10px 20px",
                          borderRadius: "4px",
                        }}
                      >
                        {item} {/* Ensure this is a string */}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  {errors[`equipment_${index}_unitOfFuelConsumption`] && (
                    <Typography variant="body2" color="#D34040">
                      {errors[`equipment_${index}_unitOfFuelConsumption`]}
                    </Typography>
                  )}
                </Box>
              </Collapse>
            </Box>
          ))}

        {alreadyFacility && (
          <Button
            onClick={addMoreEquipment}
            variant="contained"
            sx={{ mt: 2, backgroundColor: "#0a3f73" }}
          >
            Add Equipment
          </Button>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Remark
        </Typography>

        <TextField
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          name="remark"
          value={fromValue.remark}
          onChange={handleChange}
          placeholder="Brief"
        />
        {errors.incharge && (
          <Typography variant="body2" color="#D34040">
            {errors.incharge}
          </Typography>
        )}

        {/* <Box> */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0a3f73",
            textTransform: "none",
            marginTop: "10%",
          }}
          onClick={Save}
        >
          Submit
        </Button>
        {/* </Box> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addFacility}
        onClose={() => {
          props.setAddFacility(false);
          props.fetchFacilities();
          resetForm();
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddEquipment;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
