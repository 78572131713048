import React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import WasteGenerated from "./WasteGenerated";
const index = () => {
  return (
    <DashboardLayout>
      <WasteGenerated />
    </DashboardLayout>
  );
};

export default index;
