import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import React from "react";

const InputCard = ({
  title,
  placeholder,
  value,
  name,
  handleChange,
  adornmentText,
  units,
  unitValue,
  handleUnitChange,
  borderColor,
  unitError, // New prop for unit error message
  multiline,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>
      <TextField
        fullWidth
        type="text"
        multiline={multiline}
        rows={1}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        variant="outlined"
        sx={{
          borderRadius: 1,
          backgroundColor: "#EDEDED",
          mt: 1,
          // "& .MuiInputBase-root": {
          //   height: "45px",
          // },
          "& .MuiOutlinedInput-input": {
            // height: "100%",
            overflowY: multiline ? "auto" : "hidden",
            display: "block",
            // maxHeight: "45px",
            "&::-webkit-scrollbar": {
              width: "10px",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "grey",
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#EDEDED",
            },
            scrollbarWidth: "thin",
            scrollbarColor: "grey transparent",
          },
        }}
        InputProps={{
          endAdornment: (
            <>
              {units && (
                <InputAdornment position="end">
                  <Select
                    name={name}
                    value={unitValue}
                    onChange={(e) => handleUnitChange(e, name)}
                    displayEmpty
                    variant="outlined"
                    sx={{
                      height: 55,
                      py:2,
                      marginRight: "-14px",
                      borderTopLeftRadius: 0,
                      width: "140px",
                      borderBottomLeftRadius: 0,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                      '& .MuiOutlinedInput-root': {
                  height: '50px',
                }
                    }}
                  >
                    <MenuItem value={""}>Select Unit</MenuItem>
                    {units.map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              )}
              {adornmentText && (
                <InputAdornment
                  position="end"
                  // sx={{ borderLeft: "1px solid black", height: "100%" }}
                >
                   <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: '44px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
                 
                  {/* <Typography
                    sx={{
                      px: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // height: "100%",
                      whiteSpace: "nowrap",
                      marginLeft: "-1px",
                    }}
                  > */}
                    {adornmentText}
                    </Box>
                  {/* </Typography> */}
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
      {/* Display unit error message here */}
     
    </Box>
  );
};

export default InputCard;
