import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../utils/api";
import { companyId, userId } from "../../../../utils/companyId";
import InputField from "../../../../Components/Masters/InputField";
import SelectField from "../../../../Components/Masters/SelectField";
import FileField from "../../../../Components/Masters/FileField";
import { validateNumber } from "../../../../utils/validateFields";
import { numberError } from "../../../../utils/validationMessage";
import Multiline from "../../../../Components/Masters/Multiline";
import AutoComplete from "../../../../Components/Common/AutoComplete";

function AddData({
  inputData,
  fetchData,
  id,
  title,
  element,
  setAddData,
  addData,
  setsnackOpen,
  addUrl,
  updateUrl,
}) {
  const [errors, setErrors] = useState({});

  const initialState = {
    purchaseVendor: element?.purchaseVendor._id || "",
    categoryType: element?.categoryType || "",
    activityName: element?.activityName || "",
    typeOfActivity: element?.typeOfActivity || "",
    amountSpend: element?.amountSpend || { value: "" },
    quantityBought: element?.quantityBought || { value: "" },
    remark: element?.remark || "",
    documents: element?.documents || [],
    company: element?.company || companyId,
    createdBy: element?.createdBy || userId,
  };

  const [formData, setFormData] = useState(initialState);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  useEffect(() => {
    setFormData(initialState);

    setUploadedFiles(element?.documents || []);
  }, [element, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name,value)
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    if (name === "amountSpend" || name === "quantityBought")
      setFormData((prevState) => ({
        ...prevState,
        [name]: { ...prevState[name], value: value },
      }));
    else setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClose = () => {
    setAddData(false);
    setErrors({});
    setFormData(initialState);
    setUploadedFiles(element?.documents || []); // Populate with existing documents
  };

  const validateForm = () => {
    const newErrors = {};

    inputData.forEach((input) => {
      const value = formData[input.fieldName];
      console.log(input.fieldName, value);


      // Handle specific field validations
      if (
        input.fieldName === "quantityBought" ||
        input.fieldName === "amountSpend"
      ) {
        // const qbUnit = formData.quantityBought?.unit || "";

        if (value.value==="") {
          newErrors[input.fieldName] = `${input.title} is required.`;
        }
        else if (!validateNumber(value.value)) {
         newErrors[input.fieldName]= numberError;
        }

        return;
      }

      // Handle required fields
      if (input.required && !value?.trim()) {
        newErrors[input.fieldName] = `${input.title} is required.`;
      }

      if (input.fieldName === "documents" && uploadedFiles.length === 0) {
        newErrors[input.fieldName] = "Please upload Documents";
      }
    });

    setErrors(newErrors);
    console.log(newErrors)
    return Object.keys(newErrors).length === 0;
  };

  const Save = async () => {
    console.log(validateForm())
    if (!validateForm()) return;

    const dataToSend = { ...formData, documents: uploadedFiles };

    // Remove empty fields
    Object.keys(dataToSend).forEach((key) => {
      if (
        dataToSend[key] === "" ||
        (typeof dataToSend[key] === "object" &&
          Object.keys(dataToSend[key]).length === 0)
      ) {
        delete dataToSend[key];
      }
    });

    await axios({
      method: id ? "put" : "post",
      url: id ? `${updateUrl}` : addUrl,
      data: dataToSend,
      params: id ? { Id: id } : null,
    })
      .then((res) => {
        setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        fetchData();
        setFormData(initialState);
        setUploadedFiles([]);
        handleClose();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          const errorMessages = err.response.data.errors
            .map((error) => error.msg)
            .join(", ");
          setsnackOpen({
            open: true,
            message: errorMessages,
            severity: "error",
          });
        }
      });
  };

  const renderInputField = (el) => {
    if (el.fieldName === "categoryType") {
      return (
        <AutoComplete
          title={el.title}
          name={el.fieldName}
          handleChange={handleChange}
          value={formData[el.fieldName]}
          options={el.options}
          errors={errors[el.fieldName]}
        />
      );
    }

    if(el.fieldName==="typeOfActivity")
    {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          // options={[...el.options,element?.typeOfActivity]}  // Pass the unique options
          options={el.options}
          newOption={element?.typeOfActivity}
          url={el.url}
          errors={errors[el.fieldName]}
          field={el.field}
          addNewOption={el.fieldName === "typeOfActivity" ? true : false}
        />
      );
    }
    if (el.type === "select" || el.options) {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          options={el.options}
          url={el.url}
          errors={errors[el.fieldName]}
          field={el.field}
        />
      );
    }

    if (el.type === "file") {
      return (
        <FileField
          title={el.title}
          name={el.fieldName}
          errors={errors[el.fieldName]}
          fileUrls={uploadedFiles}
          progress={uploadProgress[el.fieldName] || []}
          {...{ setUploadedFiles, setUploadProgress, setErrors }}
          // onDelete={handleDeleteFile}
        />
      );
    }

    if (el.fieldName === "remark") {
      return (
        <Multiline
          title={el.title}
          handleChange={handleChange}
          placeholder={el.placeholder}
          value={formData[el.fieldName] || ""}
          errors={errors[el.fieldName]}
          fieldName={el.fieldName}
        />
      );
    }

    return (
      <InputField
        title={el.title}
        handleChange={handleChange}
        placeholder={el.placeholder}
        value={
          el.nested ? formData[el.fieldName]?.value : formData[el.fieldName]
        }
        errors={errors[el.fieldName] || ""}
        fieldName={el.fieldName}
        adornmentText={el.adornmentText || ""}
      />
    );
  };

  const list = () => (
    <Box display="flex" gap={3} sx={{ width: "400px", maxWidth: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton onClick={handleClose} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "88%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {id ? `Edit ${title} Data` : `Add ${title} Data`}
        </Typography>
        {inputData.map((el) => {
          // Conditional rendering for hours and industrial equipment

          return <div key={el.fieldName}>{renderInputField(el)}</div>;
        })}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      sx={{
        backgroundColor: "transparent",
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          height: "100vh",
          minHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
      anchor="right"
      open={addData}
      onClose={handleClose}
    >
      {list()}
    </Drawer>
  );
}

export default AddData;
