import React, { useState } from "react";
import { Box, Typography, Button, Tooltip, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import AddEquipment from "./AddDriver";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DescriptionIcon from "@mui/icons-material/Description"; // For CSV
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import DocumentItem from "../../../../../Components/Masters/DocumentItem";

function ViewEquipment(props) {
  const [addFacility, setAddEquipment] = useState(false);

  const handleDownload = (url) => {
    const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/");
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const Edit = () => {
    props.handleEdit();
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 400,
        maxWidth: "100%",
        height: "100vh",
        justifyContent: "space-between",
        backgroundColor: "transparent",
        overflowX: "hidden", // Prevent horizontal overflow
      }}
    >
      <AddEquipment
        addFacility={addFacility}
        setAddFacility={setAddEquipment}
        selectedFacility={props.selectedFacility}
        setsnackOpen={props.setsnackOpen}
        fetchFacilities={props.fetchFacilities}
      />

      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setViewFacility(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>

      <Box
        sx={{
          p: 3,
          width: "100%",
          backgroundColor: "#fff",
          height: "100%",
          overflowY: "auto", // Enable vertical scrolling only
          overflowX: "hidden", // Ensure no horizontal scrolling
        }}
      >
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          View Driver Master
        </Typography>

        {/* Display selected facility information */}
        <Box display="flex" gap={12}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              Date
            </Typography>
            <p
              style={{
                marginLeft: "2px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {new Date(props.selectedFacility?.createdAt).toLocaleDateString(
                "en-GB",
                { day: "2-digit", month: "2-digit", year: "numeric" }
              ) ?? ""}
            </p>{" "}
          </Box>

          <Box>
            <Tooltip title="Logistics Vendor name" arrow>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  mt: 1,
                  cursor: "pointer", // Show pointer cursor on hover
                }}
              >
                Logistics Vendor
              </Typography>
            </Tooltip>

            <p style={{ marginLeft: "10px" }}>
              {props.selectedFacility?.logisticsVendor
                ?.logisticVendorLegalName ?? ""}
            </p>
          </Box>
        </Box>

        <Box display="flex" gap={15}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              Facility
            </Typography>
            <p>{props.selectedFacility?.facility?.facilityName ?? ""}</p>
          </Box>
        </Box>

        <Box display="flex" gap={10}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              Driver Name
            </Typography>
            <p>{props.selectedFacility?.driverName ?? ""}</p>
          </Box>

          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", mt: 1, ml: 2 }}
            >
              Aadhar No.
            </Typography>
            <p style={{ marginLeft: "6px" }}>
              {props.selectedFacility?.aadharNumber ?? ""}
            </p>
          </Box>
        </Box>

        <Box display="flex" gap={10}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              Driver License No
            </Typography>
            <p>{props.selectedFacility?.licenseNumber ?? ""}</p>
          </Box>

          <Box>
            <Tooltip title="License Expiry Date" arrow>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  mt: 1,
                  cursor: "pointer", // Show pointer cursor on hover
                }}
              >
                License Expiry
              </Typography>
            </Tooltip>
            <p>
              {props.selectedFacility?.licenseExpiryDate
                ? new Date(
                    props.selectedFacility.licenseExpiryDate
                  ).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                : ""}
            </p>{" "}
          </Box>
        </Box>

        <Box display="flex" gap={4}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              Mobile No.
            </Typography>
            <p>{props.selectedFacility?.phone ?? ""}</p>
          </Box>

          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                mt: 1,
                ml: 7,
              }}
            >
              Email
            </Typography>
            <Tooltip title={props.selectedFacility?.email ?? ""} arrow>
              <p
                style={{
                  marginLeft: "32px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  cursor: "pointer", // Show pointer cursor on hover

                  textOverflow: "ellipsis",
                  maxWidth: "150px", // Adjust width as needed for ellipsis to work
                }}
              >
                {props.selectedFacility?.email ?? ""}
              </p>
            </Tooltip>
          </Box>
        </Box>

        {/* Aadhar Document */}
        {/* 
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {props.selectedFacility?.aadharDoc?.map((doc, index) => {
            const fileName = doc.split("/").pop();
            const fileExtension = fileName.split(".").pop().toLowerCase();
            const isImage = ["jpg", "jpeg", "png"].includes(fileExtension);
            const isCsv = fileExtension === "csv";
            const isExcel = ["xls", "xlsx"].includes(fileExtension);

            return (
              <Box key={index} sx={{ position: "relative" }}>
                {isImage ? (
                  <img
                    src={doc}
                    alt={`Document ${index + 1}`}
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "80px",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    {isCsv ? (
                      <DescriptionIcon
                        sx={{ fontSize: "20px", color: "#1A73E8" }}
                      />
                    ) : isExcel ? (
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        style={{ color: "#0a8f08" }}
                      />
                    ) : null}
                    <Typography variant="caption" noWrap>
                      {fileName}
                    </Typography>
                  </Box>
                )}
                <IconButton
                  onClick={() => handleDownload(doc)}
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.5)",
                  }}
                >
                  <ArrowDownwardIcon sx={{ fontSize: "0.7rem" }} />
                </IconButton>
              </Box>
            );
          })}
        </Box> */}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Aadhar doc
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          sx={{ width: "100%" }}
        >
          {/* Render Images First */}
          {props.selectedFacility &&
            props.selectedFacility?.aadharDoc.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedFacility?.aadharDoc
                  .filter((url) =>
                    ["png", "jpg", "jpeg"].includes(
                      url.split(".").pop().toLowerCase()
                    )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`image-${index}`} url={url} />
                  ))}
              </Box>
            )}

          {/* Render Other Documents */}
          {props.selectedFacility &&
            props.selectedFacility?.aadharDoc.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedFacility?.aadharDoc
                  .filter(
                    (url) =>
                      !["png", "jpg", "jpeg"].includes(
                        url.split(".").pop().toLowerCase()
                      )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`file-${index}`} url={url} />
                  ))}
              </Box>
            )}

          {props.selectedFacility?.aadharDoc.length === 0 ? <p>-</p> : null}
        </Box>

        {/* License Document */}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          License
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          sx={{ width: "100%" }}
        >
          {/* Render Images First */}
          {props.selectedFacility &&
            props.selectedFacility?.licenseDoc.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedFacility?.licenseDoc
                  .filter((url) =>
                    ["png", "jpg", "jpeg"].includes(
                      url.split(".").pop().toLowerCase()
                    )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`image-${index}`} url={url} />
                  ))}
              </Box>
            )}

          {/* Render Other Documents */}
          {props.selectedFacility &&
            props.selectedFacility?.licenseDoc.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedFacility?.licenseDoc
                  .filter(
                    (url) =>
                      !["png", "jpg", "jpeg"].includes(
                        url.split(".").pop().toLowerCase()
                      )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`file-${index}`} url={url} />
                  ))}
              </Box>
            )}

          {props.selectedFacility?.licenseDoc.length === 0 ? <p>-</p> : null}
        </Box>

        {/* <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {props.selectedFacility?.licenseDoc?.map((doc, index) => {
            const fileName = doc.split("/").pop();
            const fileExtension = fileName.split(".").pop().toLowerCase();
            const isImage = ["jpg", "jpeg", "png"].includes(fileExtension);
            const isCsv = fileExtension === "csv";
            const isExcel = ["xls", "xlsx"].includes(fileExtension);

            return (
              <Box key={index} sx={{ position: "relative" }}>
                {isImage ? (
                  <img
                    src={doc}
                    alt={`License Document ${index + 1}`}
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "80px",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    {isCsv ? (
                      <DescriptionIcon
                        sx={{ fontSize: "20px", color: "#1A73E8" }}
                      />
                    ) : isExcel ? (
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        style={{ color: "#0a8f08" }}
                      />
                    ) : null}
                    <Typography variant="caption" noWrap>
                      {fileName}
                    </Typography>
                  </Box>
                )}
                <IconButton
                  onClick={() => handleDownload(doc)}
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    p: 0.5,
                  }}
                >
                  <ArrowDownwardIcon sx={{ fontSize: "0.7rem" }} />
                </IconButton>
              </Box>
            );
          })}
        </Box> */}

        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Edit}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      sx={{
        backgroundColor: "transparent",
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      anchor="right"
      open={props.viewFacility}
      onClose={() => props.setViewFacility(false)}
    >
      {list()}
    </Drawer>
  );
}

export default ViewEquipment;
