// src/components/QuestionCard.js
import React from "react";
import { Box, TextField, Typography } from "@mui/material";

const QuestionCard = ({ question, name, value, onChange, borderColor }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", mt: 4 }}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {question}
      </Typography>
      <TextField
        placeholder="Brief"
        name={name}
        value={value}
        onChange={onChange}
        multiline
        rows={3}
        sx={{
          backgroundColor: "#EDEDED",
        }}
      />
    </Box>
  );
};

export default QuestionCard;
