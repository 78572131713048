import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import "./Scrollbar.css"

const MultiSelect = ({ 
  title, 
  data = [], // Default to an empty array
  selectedFields,
  setSelectedFields, 
  field,
  errors,
  setErrors
}) => {

console.log("MultiselectedTitle",selectedFields)
  const handleFieldChange = (event) => {
    const { value, checked } = event.target;
    setErrors((prev) => ({
        ...prev,
        [title]: "", // Clear the error for specific field
      }));
    // Update the selected fields based on whether the checkbox is checked or not
    if (checked) {
      // Add the value to the selectedFields
      setSelectedFields(prev => ({
        ...prev,
        [field]: [...(prev[field] || []), value], // Add value to the array
      }));
    } else {
      // Remove the value from the selectedFields
      setSelectedFields(prev => ({
        ...prev,
        [field]: (prev[field] || []).filter(item => item !== value), // Filter out the value
      }));
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column',ml:2,maxHeight:"90%" }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", marginBottom: "30px" }}>
        {title}
      </Typography>
      <Box className="scrollable-container">
        {data && data.length > 0 ? (
          data.map((Field, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={Field}
                  checked={selectedFields?.[field]?.includes(Field)} // Check if the field is selected
                  onChange={handleFieldChange}
                />
              }
              label={Field}
            />
          ))
        ) : (
          <Typography>No options available</Typography>
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default MultiSelect;
