import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Stack,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Checkbox,
  Drawer,
  Pagination,
  PaginationItem,
  Alert,
  Snackbar,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ExpandLess, ExpandMore, MoreHoriz } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import AddRole from "./AddRole";
import ViewRole from "./ViewRole";
import User from "./User";
import DateRangePickerComponent from "./Filters/Date";
import { api } from "../../../utils/api";
import FilterPage from "../../../Components/Masters/Filter/FilterPage";
import FilterSidebar from "../../../Components/Masters/Filter/FilterSidebar";
import ApplyButton from "./Filters/ApplyButton";
import CheckboxDrawer from "./Filters/Checkbox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";
import ResetButton from "./Filters/ResetButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Role = () => {
  const [showUsers, setShowUsers] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [roleEdit, setRoleEdit] = useState(0);
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);
  const [roles, setRoles] = useState([]); // Initialize roles as an empty array
  const [filteredRoles, setFilteredRoles] = useState([]); // Store filtered roles
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenEdit, setIsDrawerOpenEdit] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteIndexEdit, setDeleteIndexEdit] = useState(0);
  const [allowPermision, setAllowPermission] = useState(false);
  const [index, setIndex] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [filterRoleNames, setFilterRoleNames] = useState([]); // Track selected roles for filtering
  const [startDate, setStartDate] = useState(null); // Start date state
  const [endDate, setEndDate] = useState(null); // End date state
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [selectedRoles, setSelectedRoles] = useState([]); // Store selected countries
  const [totalPages, setTotalPages] = useState(1);

  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);

  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    Role: false,
  });
  const [errors, setErrors] = useState({
    Date: false,
    Role: false,
  });
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const filters = ["Date", "Role"];

  const fetchRoles = async (filters = filtersToApply) => {
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        // sortField: "name", // Sort configuration
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      console.log("Query Params sent to API:", queryParams, filters); // Debugging line

      const response = await axios.get(`${api}/auth/getRoles?${queryParams}`);

      console.log("Fetched data after filtering:", response.data); // Debugging line
      setRoles(response.data.data);
      setFilteredRoles(response.data.data);
      setTotalPages(response.data.totalPages); // Update total pages for pagination
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [page, rowsPerPage, sortConfig]);

  const handleAddRole = (i) => {
    setIsDrawerOpen(true);
    setIndex(i);
  };

  const handleDelete = () => {
    console.log(roleEdit._id, "indexDelete");
    // setRoleEdit(index)
    axios
      .put(
        `${api}/auth/DeleteRole`,
        {},
        {
          params: {
            Id: roleEdit._id, // Pass as query parameter
          },
        }
      )
      .then((res) => {
        console.log(
          "res",
          res.data.data._id,
          filteredRoles.filter((item) => item._id !== res.data.data._id)
        );
        // setsnackOpen({open:true,message:res.data.message,severity:"success"})
        setDeleteDialog(true);
        setAllowPermission(true);
        setFilteredRoles(
          filteredRoles.filter((item) => item._id !== res.data.data._id)
        );
      })
      .catch((err) => {
        console.log("errMessageDelete", err, err.response);
        // setsnackOpen({open:true,message:err.data?.response.message??"network Error",severity:"error"})
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === "user not found"
        ) {
          setDeleteDialog(true);
          setAllowPermission(false);
        }
      });
    // setDeleteDialog(true);
    // setAllowPermission(false);
    setAnchorEl(null);
    setSelectedUserIndex(null);
  };

  const handleDeleteAggree = (index) => {
    console.log(index, "index++");
    axios
      .put(
        `${api}/auth/DeleteRole`,
        { deactivated: true },
        {
          params: {
            Id: index, // Pass as query parameter
          },
        }
      )
      .then(async (res) => {
        console.log(
          "res",
          res.data.data._id,
          filteredRoles.filter((item) => item._id !== res.data.data._id)
        );
        setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        setDeleteDialog(false);

        // Update filteredRoles by removing the deleted role
        const updatedRoles = filteredRoles.filter(
          (item) => item._id !== res.data.data._id
        );
        setFilteredRoles(updatedRoles);

        // If roles count after deletion is less than rowsPerPage, fetch additional roles
        if (updatedRoles.length < rowsPerPage && page < totalPages) {
          const rolesToFetch = rowsPerPage - updatedRoles.length; // Calculate the number of roles to fetch
          try {
            const queryParams = new URLSearchParams({
              page: page + 1, // Fetch from the next page
              limit: rolesToFetch, // Fetch only the number of missing roles
              sortField: sortConfig.key,
              sortOrder: sortConfig.direction,
            }).toString();

            const response = await axios.get(
              `${api}/auth/getRoles?${queryParams}`
            );
            const nextPageRoles = response.data.data;

            // Append only the necessary roles to fill the current page
            setFilteredRoles((prevRoles) => [...prevRoles, ...nextPageRoles]);
          } catch (error) {
            console.error("Error fetching next page roles:", error);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        setsnackOpen({
          open: true,
          message: err.response?.data?.message ?? "Network Error",
          severity: "error",
        });
      });

    setDeleteDialog(true);
    setAnchorEl(null);
    setIndex(index);
    setSelectedUserIndex(null);
  };

  const handleRoleAssign = () => {
    // axios.put(`${api}/auth/DeleteRole`,{}).then((res)=>{}).catch((err)=>{})
    // setIsDrawerOpen(true);
    setDeleteDialog(false);
    handleEdit();
    // setIndex(roleEdit._id);
  };

  const handleView = () => {
    setIndex(roleEdit._id);
    setAnchorEl(null);
    setSelectedUserIndex(null);
    setIsDrawerOpenEdit(true);
    // setIsDrawerOpen(false);
  };

  const handleEdit = () => {
    console.log("roleEditFRomEdit", roleEdit);
    setIndex(roleEdit);
    setAnchorEl(null);
    setSelectedUserIndex(null);
    setIsDrawerOpen(true);
    setIsDrawerOpenEdit(false);
  };

  const handleMenuClick = (event, index, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserIndex(index);
    setRoleEdit(data);
    setDeleteIndexEdit(
      // data.allowed_permissions.map((item) => item.name).join("") ===
      //   "delete Access"
      //   ? data._id
      //   : 0
      data._id
    );

    console.log(
      data.allowed_permissions.map((item) => item.name).join(""),
      "data++"
    );
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserIndex(null);
  };

  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleApplyFilters = () => {
    if (!validateFilters()) return;
    setPage(1); // Reset to the first page when applying filters

    fetchRoles();
    setDrawerFilterOpen(false);
    handleAppliedFilters();
  };

  // default filters to apply
  const filtersToApply = {
    name: selectedRoles.join(","),
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
  };

  const handleAppliedFilters = () => {
    setIsFiltersApplied(startDate || endDate || selectedRoles.length > 0);

    setAppliedFilters({
      ...appliedFilters,
      Date: startDate || endDate ? true : false,
      Role: selectedRoles.length > 0 ? true : false,
    });
  };

  // Applying reset filters
  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedRoles([]);
    setErrors({ Date: "", Role: "" });
    setAppliedFilters({ Date: false, Role: false });
    setIsFiltersApplied(false);
    fetchRoles({
      name: [],
      startDate: "",
      endDate: "",
    });
    setDrawerFilterOpen(false);
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = { Date: "", Role: "" };
    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date

      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }

      // Check if both dates are selected
      if (!startDate && !endDate) {
        newErrors.Date = "Please select a date range";
        isValid = false;
      }
    }

    if (selectedFilter === "Role" && selectedRoles.length === 0) {
      newErrors.Role = "Please select Role";
      isValid = false;
    }

    // console.log(newErrors);

    setErrors(newErrors);
    return isValid;
  };
  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/auth/getRoles`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        ); // Date range picker component
      case "Role":
        return (
          <CheckboxDrawer
            {...{
              setSelectedRoles,
              selectedRoles,
              errors: errors.Role,
              setErrors,
            }}
          />
        ); // Checkbox filter for roles
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/auth/getRoles`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
    }
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    setErrors({ Date: false, Facility: false, Country: false });
  };

  if (showUsers) {
    return <User />;
  }

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        // margin: " 0px 0px 0px 50px",
      }}
    >
      <Snackbar
        open={snackOpen.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setsnackOpen({ ...snackOpen, open: false })}
      >
        <Alert
          // onClose={handleClose}
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackOpen.message}
          {/* Add user Successfully */}
        </Alert>
      </Snackbar>
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        <AddRole
          value={isDrawerOpen}
          setValue={setIsDrawerOpen}
          index={index}
          fetchRoles={fetchRoles}
          setsnackOpen={setsnackOpen}
        />
        <ViewRole
          values={isDrawerOpenEdit}
          setValues={setIsDrawerOpenEdit}
          index={index}
          handleEdit={handleEdit}
        />

        {/* Filter Drawer */}
        {drawerFilterOpen && (
          <Drawer
            anchor="right"
            open={drawerFilterOpen}
            onClose={() => setDrawerFilterOpen(false)}
            sx={{
              backgroundColor: "transparent",
              "& .MuiDrawer-paper": {
                // This targets the paper component within the Drawer
                backgroundColor: "transparent", // Make paper transparent
                boxShadow: "none", // Optional: Remove any shadow
                width: "50%",
              },
            }}
          >
            <Box
              display="flex"
              gap={3}
              sx={{
                width: "100%",
                display: "flex",
                overflowX: "hidden",
                maxWidth: "100%",
                justifyContent: "space-btween",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  height: "3%",
                  padding: "13px",
                  margin: "30px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "80%",
                  width: "3%",
                }}
              >
                <IconButton
                  onClick={() => handleFilterDrawer()}
                  sx={{ color: "black" }}
                >
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100vh",
                  overflow: "hidden",
                }}
              >
                {/* Sidebar for selecting the filter */}
                <FilterSidebar
                  {...{
                    setSelectedFilter,
                    selectedFilter,
                    filters,
                    appliedFilters,
                  }}
                />

                {/* Filter content area */}
                <Box
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#F9F9F9",
                    padding: "20px",
                    // overflow:"scroll",
                    height: "100vh",
                    paddingBottom: "20%",
                  }}
                >
                  {renderSelectedFilter()}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: "3%",
                      position: "fixed",
                      bottom: "20px",
                      ml: 3,
                      mr: 3,
                      mt: "10%",
                    }}
                  >
                    <ApplyButton handleApply={handleApplyFilters} />
                    <ResetButton handleReset={handleResetFilters} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}

        {deleteDialog && allowPermision === false ? (
          // <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
          //   <DialogContent>
          //     <DialogContentText>
          //       Are you sure you want to delete this role?
          //     </DialogContentText>
          //   </DialogContent>
          //   <DialogActions>
          //     <Button
          //       sx={{ textTransform: "none" }}
          //       onClick={() => setDeleteDialog(false)}
          //     >
          //       Cancle
          //     </Button>
          //     <Button
          //       sx={{ textTransform: "none" }}
          //       onClick={() => handleDeleteAggree(deleteIndexEdit)}
          //     >
          //       Confirm
          //     </Button>
          //   </DialogActions>
          // </Dialog>
          <Dialog open={deleteDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Do you really want to delete this entry?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialog(false)} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteAggree(deleteIndexEdit)}
                color="secondary"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
            <DialogContent>
              <DialogContentText>
                Please assign this role before deleting it.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {console.log("deleteIndexEdit___", deleteIndexEdit)}
              <Button
                sx={{ textTransform: "none", fontSize: "16px" }}
                onClick={() => handleRoleAssign()}
              >
                Assign
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <Grid
          container
          alignItems="center"
          sx={{ width: "90%", margin: "5% 0.5% 0% 22%" }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "18px",
                textDecoration: "underline",
              }}
            >
              Roles and Permission
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => setShowUsers(true)}
                sx={{
                  backgroundColor: "#e0e0e0",
                  fontSize: "14px",
                  color: "black",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                }}
              >
                List of Users
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1c486b",
                  fontSize: "14px",
                  color: "#FFFFFF",
                  textTransform: "none",
                }}
              >
                List of Roles
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              // endIcon={<FilterListIcon />}
              sx={{
                margin: "20px 10px -29px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                "&:hover .dot-icon": {
                  color: "#FFFFFF", // Change dot color on button hover
                },
                // display:"flex"
              }}
            >
              Filter
              <FilterListIcon />
              {isFiltersApplied && (
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              )}
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "20px -36px -29px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              endIcon={<AddIcon />}
              onClick={() => handleAddRole(0)}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Toolbar>

      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "left",
            padding: "16px",
          }}
        >
          List of Roles
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "12% 20% 29% 29% 10%",
            width: "100%",
            border: "1px solid #ddd",
          }}
        >
          <Box
            sx={{
              padding: "12px 12px 12px 19px",
              fontWeight: "bold",
              textAlign: "left",
              borderRight: "1px solid #ddd",
              borderBottom: "1px solid #ddd",
              width: "115px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            Date
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2px",
                marginLeft: "10px",
              }}
            >
              <IconButton
                size="small"
                onClick={() => handleSort("createdAt", "asc")}
              >
                <ExpandLess
                  sx={{
                    color:
                      sortConfig.key === "createdAt" &&
                      sortConfig.direction === "asc"
                        ? "blue"
                        : "black",
                    margin: "-10px",
                    fontSize: "20px",
                    color: "black",
                  }}
                />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleSort("createdAt", "desc")}
              >
                <ExpandMore
                  sx={{
                    color:
                      sortConfig.key === "createdAt" &&
                      sortConfig.direction === "desc"
                        ? "blue"
                        : "black",
                    margin: "-10px",
                    fontSize: "20px",
                    color: "black",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "12px 12px 12px 15px",
              fontWeight: "bold",
              textAlign: "left",
              borderRight: "1px solid #ddd",
              borderBottom: "1px solid #ddd",
              maxWidth: "200px",
              display: "flex",
              flexDirection: "row",
              //  marginLeft: "9px",
            }}
          >
            Name of Role
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2px",
                marginLeft: "10px",
              }}
            >
              <IconButton
                size="small"
                onClick={() => handleSort("name", "asc")}
              >
                <ExpandLess
                  sx={{
                    color:
                      sortConfig.key === "name" &&
                      sortConfig.direction === "asc"
                        ? "blue"
                        : "black",
                    margin: "-10px",
                    fontSize: "20px",
                    color: "black",
                  }}
                />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleSort("name", "desc")}
              >
                <ExpandMore
                  sx={{
                    color:
                      sortConfig.key === "name" &&
                      sortConfig.direction === "desc"
                        ? "blue"
                        : "black",
                    margin: "-10px",
                    fontSize: "20px",
                    color: "black",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "12px",
              fontWeight: "bold",
              textAlign: "left",
              borderRight: "1px solid #ddd",
              borderBottom: "1px solid #ddd",
              display: "flex",
              flexDirection: "row",
            }}
          >
            Module's Name
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2px",
                marginLeft: "10px",
              }}
            >
              <IconButton
                size="small"
                onClick={() => handleSort("moduleName", "asc")}
              >
                <ExpandLess
                  sx={{
                    color:
                      sortConfig.key === "moduleName" &&
                      sortConfig.direction === "asc"
                        ? "blue"
                        : "black",
                    margin: "-10px",
                    fontSize: "20px",
                    color: "black",
                  }}
                />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleSort("moduleName", "desc")}
              >
                <ExpandMore
                  sx={{
                    color:
                      sortConfig.key === "moduleName" &&
                      sortConfig.direction === "desc"
                        ? "blue"
                        : "black",
                    margin: "-10px",
                    fontSize: "20px",
                    color: "black",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "12px",
              fontWeight: "bold",
              textAlign: "left",
              borderRight: "1px solid #ddd",
              borderBottom: "1px solid #ddd",
              display: "flex",
              flexDirection: "row",
            }}
          >
            Permissions
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2px",
                marginLeft: "10px",
              }}
            >
              <IconButton
                size="small"
                onClick={() => handleSort("allowed_permissions", "asc")}
              >
                <ExpandLess
                  sx={{
                    color:
                      sortConfig.key === "allowed_permissions" &&
                      sortConfig.direction === "asc"
                        ? "blue"
                        : "black",
                    margin: "-10px",
                    fontSize: "20px",
                    color: "black",
                  }}
                />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleSort("allowed_permissions", "desc")}
              >
                <ExpandMore
                  sx={{
                    color:
                      sortConfig.key === "allowed_permissions" &&
                      sortConfig.direction === "desc"
                        ? "blue"
                        : "black",
                    margin: "-10px",
                    fontSize: "20px",
                    color: "black",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "12px",
              fontWeight: "bold",
              textAlign: "center",
              borderBottom: "1px solid #ddd",
            }}
          >
            Action
          </Box>

          {/* Table Body */}
          {filteredRoles.map((role, index) => (
            <>
              <Typography
                key={`date-${index}`}
                sx={{
                  // padding: "2px 5px",
                  padding: "9px 14px 9px 19px",
                  textAlign: "left",
                  fontSize: "14px",
                  color: "black",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  alignItems: "center",
                  width: "115px",
                  marginRight: "9px",
                }}
              >
                {/* {new Date(role.createdAt).toLocaleDateString()} */}
                {new Date(role.createdAt).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </Typography>

              <Box
                key={`name-${index}`}
                sx={{
                  padding: "2px 14px",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "200px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "black",
                    lineHeight: "1.5",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "block",
                    maxWidth: "100%",
                  }}
                >
                  {role.name}
                </Typography>
              </Box>

              <Box
                key={`modules-${index}`}
                sx={{
                  padding: "2px 14px",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "100%", // Set a max width to control when to show ellipsis
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "black",
                    lineHeight: "1.5",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "100%",
                  }}
                >
                  {/* {role.moduleName.map((mod, modIndex) => (
                    <span key={modIndex} style={{ whiteSpace: "nowrap" }}>
                      {mod}
                      {modIndex < role.moduleName.length - 1 ? ", " : ""}
                      </span>
                  ))} */}
                  {role.moduleName && role.moduleName.length > 0
                    ? role.moduleName.join(", ")
                    : " "}
                </Typography>
              </Box>

              <Box
                key={`permission-${index}`}
                sx={{
                  padding: "2px 14px",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "100%", // Set a max width to control when to show ellipsis
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "black",
                    lineHeight: "1.5",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "100%",
                  }}
                >
                  {/* {role.allowed_permissions.map((perm, permIndex) => (
                <span key={permIndex} style={{ whiteSpace: "nowrap" }}>
                  {perm.name}
                  {permIndex < role.allowed_permissions.length - 1 ? ", " : ""}
                </span>
              ))} */}
                  {role.allowed_permissions?.length > 0 ? (
                    role.allowed_permissions.map((perm, permIndex) => (
                      <span key={permIndex} style={{ whiteSpace: "nowrap" }}>
                        {perm.name}
                        {permIndex < role.allowed_permissions.length - 1
                          ? ", "
                          : ""}
                      </span>
                    ))
                  ) : (
                    <span>No permissions available</span>
                  )}
                </Typography>
              </Box>

              <Typography
                key={`action-${index}`}
                sx={{
                  padding: "2px 8px 2px 8px",
                  textAlign: "center",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <IconButton
                  onClick={(event) => handleMenuClick(event, index, role)}
                >
                  <MoreHorizIcon sx={{ fontWeight: "bold", color: "black" }} />
                </IconButton>
              </Typography>
            </>
          ))}
        </Box>
      </TableContainer>

      {/* Action Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        {console.log("filteredRoles", filteredRoles, selectedUserIndex)}
        <MenuItem onClick={() => handleView()} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={() => handleEdit()} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>

        <MenuItem onClick={() => handleDelete()} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            backgroundColor: "#ffffff",
            width: "80%",
            margin: "2% -0.5% 0% 23%",
            borderRadius: "2%",
            padding: "3px",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            renderItem={(item) => (
              <StyledPaginationItem
                // <PaginationItem
                {...item}
                components={{
                  previous: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 27,
                      }}
                    >
                      <ArrowBackIcon sx={{ mr: 0.5, color: "green" }} />
                      Previous
                    </Box>
                  ),
                  next: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: 27,
                      }}
                    >
                      Next
                      <ArrowForwardIcon sx={{ ml: 0.5, color: "green" }} />
                    </Box>
                  ),
                }}
                sx={{
                  "& .MuiPaginationItem-root": {
                    mx: 1,
                  },
                  "& .MuiPagination-ul": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Role;
