// import React, { useState, useEffect } from "react";
// import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
// import axios from "axios";

// const FuelTypeFilter = ({ selectedFuelTypes = [], setSelectedFuelTypes, url, errors }) => {
//   const [fuelTypes, setFuelTypes] = useState([]);

//   // Fetch fuel types from the backend
//   useEffect(() => {
//     const fetchFuelTypes = async () => {
//       try {
//         const response = await axios.get(url); // Fetch fuel types from the given URL
//         console.log("Response from backend:", response);

//         // Extract unique fuel types from the response data
//         const uniqueFuelTypes = response.data.data
//           ? [...new Set(response.data.data.map((fuel) => fuel.name))] // Adjust according to your API response structure
//           : [];

//         console.log("Unique Fuel Types:", uniqueFuelTypes);
//         setFuelTypes(uniqueFuelTypes);
//       } catch (error) {
//         console.error("Error fetching fuel types:", error);
//       }
//     };

//     fetchFuelTypes();
//   }, [url]); // Reload fuel types when the URL changes

//   const handleFuelTypeChange = (event) => {
//     const selectedFuelType = event.target.value;
//     if (event.target.checked) {
//       // Add selected fuel type to the state
//       setSelectedFuelTypes((prevSelected) => [...prevSelected, selectedFuelType]);
//     } else {
//       // Remove the fuel type from the state
//       setSelectedFuelTypes((prevSelected) =>
//         prevSelected.filter((type) => type !== selectedFuelType)
//       );
//     }
//   };

//   return (
//     <Box sx={{ m: "5%", mt: 0 }}>
//       <Typography
//         variant="h5"
//         gutterBottom
//         sx={{ fontWeight: "bold", marginBottom: "30px" }}
//       >
//         Fuel Types
//       </Typography>
//       <Box className="scrollable-container">
//         {fuelTypes.length > 0 ? (
//           fuelTypes.map((type, index) => (
//             <FormControlLabel
//               key={index}
//               control={
//                 <Checkbox
//                   value={type}
//                   checked={selectedFuelTypes.includes(type)}
//                   onChange={handleFuelTypeChange}
//                 />
//               }
//               label={type || "Unknown Fuel Type"} // Fallback for missing types
//             />
//           ))
//         ) : (
//           <Typography>Loading fuel types...</Typography>
//         )}
//       </Box>
//       {errors && (
//         <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
//           {errors}
//         </Typography>
//       )}
//     </Box>
//   );
// };

// export default FuelTypeFilter;



import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import axios from "axios";

const FuelTypeFilter = ({ selectedFuelTypes = [], setSelectedFuelTypes, url, errors }) => {
  const [fuelTypes, setFuelTypes] = useState([]);

  // Fetch fuel types from the backend
  useEffect(() => {
    const fetchFuelTypes = async () => {
      try {
        const response = await axios.get(url); // Fetch fuel types from the given URL
        console.log("Response from backend:", response);


        const uniqueFuelTypes = response.data.data
          ? [...new Set(response.data.data.map((machinery) => machinery.fuelType))]
          : [];
          
        console.log("Unique Fuel Types:", uniqueFuelTypes);
        setFuelTypes(uniqueFuelTypes);
      } catch (error) {
        console.error("Error fetching fuel types:", error);
      }
    };

    fetchFuelTypes();
  }, [url]); // Reload fuel types when the URL changes

  const handleFuelTypeChange = (event) => {
    const selectedFuelType = event.target.value;
    if (event.target.checked) {
      // Add selected fuel type to the state
      setSelectedFuelTypes((prevSelected) => [...prevSelected, selectedFuelType]);
    } else {
      // Remove the fuel type from the state
      setSelectedFuelTypes((prevSelected) =>
        prevSelected.filter((type) => type !== selectedFuelType)
      );
    }
  };

  return (
    <Box sx={{ m: "5%", mt: 0 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", marginBottom: "30px" }}
      >
        Fuel Type
      </Typography>
      <Box className="scrollable-container">
        {fuelTypes.length > 0 ? (
          fuelTypes.map((type, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={type}
                  checked={selectedFuelTypes.includes(type)}
                  onChange={handleFuelTypeChange}
                />
              }
              label={type || "Unknown Fuel Type"} // Fallback for missing types
            />
          ))
        ) : (
          <Typography>Loading fuel types...</Typography>
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default FuelTypeFilter;
