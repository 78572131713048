import React, { useEffect, useState } from "react";
import ViewTable from "../../../Components/Common/ViewTable";
import { get } from "../../../utils/api";
import Cards from "../../../Components/Common/Cards";
import FilterModal from "../../../Components/Common/FilterModal";
import Form from "./Form";
import View from "./View";
import ActionButtons from "../../../Components/Common/ActionButtons";

const WasteGenerated = () => {
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [selectedValue, setSelectedValue] = useState(null);

  const [filters, setFilters] = useState({
    wasteType: [],
    wasteManagementMethod: [],
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);

  const headers = [
    { key: "createdAt", label: "Date", sortable: true },
    { key: "facility", label: "Facility", sortable: true },
    { key: "wasteName", label: "Waste Type", sortable: true },
    {
      key: "quantityOfWasteGenerated",
      label: "Quantity of Waste Generated",
      sortable: true,
    },
    {
      key: "wasteManagementMethod",
      label: "Waste Management Method",
      sortable: true,
    },
  ];

  let bannerData = [
    {
      title: "Total Quantity of Waste Generated",
      description: "2,00,000 Kg",
    },
    {
      title: "Top Waste Types",
      description: ["Waste Type 1", "Waste Type 2"],
    },
    {
      title: "Total Quantity of Waste Managed",
      description: "1,50,000 Kg",
      filter: true,
      filterTitle: "Waste Category",
      filterValues: ["Recycled", "Non Recycled"],
    },
  ];

  const filterData = [
    { title: "Date", type: "date", key: "createdAt", value: [] },
    {
      title: "Waste Type",
      type: "option",
      key: "wasteType",
      value: filters?.wasteType,
    },
    {
      title: "Quantity of Waste Generated",
      type: "limit",
      key: "quantityOfWasteGenerated",
      value: [],
    },
    {
      title: "Waste Management Method",
      type: "option",
      key: "wasteManagementMethod",
      value: filters?.wasteManagementMethod,
    },
  ];

  const fetchData = async () => {
    let filter = { ...filterObj };
    if (filter.load) {
      filter.minLoad = parseInt(filterObj.load.min);
      filter.maxLoad = parseInt(filterObj.load.max);
    }
    const queryParams = new URLSearchParams({
      ...filter,
      page: currentPage,
      limit: 10,
      sortField: sortConfig.key,
      sortOrder: sortConfig.direction,
    }).toString();
    await get(`/scope3/wasteGeneratedInOperations/get?${queryParams}`)
      .then((res) => {
        console.log(res?.data);
        let arr = res?.data?.data?.map((item) => {
          return {
            createdAt: new Date(item.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
            facility: item?.facility,
            wasteName: item?.wasteName,
            quantityOfWasteGenerated: item?.quantityOfWasteGenerated,
            wasteManagementMethod: item?.wasteManagementMethod,
            remark: item?.remark,
            action: "View",
            id: item?._id,
            data: item,
          };
        });
        setTableData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [sortConfig, filterObj]);

  const fetchFilters = async () => {
    await get(`/scope3/wasteGeneratedInOperations/filter`)
      .then((res) => {
        const { wasteType, wasteQuantity, wasteManagementMethod } =
          res?.data?.data || {};
        setFilters({
          wasteType: wasteType || [],
          wasteManagementMethod: wasteManagementMethod || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      console.log("data", dataForEdit);
      dataForEdit.createdAt = dataForEdit?.data?.createdAt.split("T")[0];
      setEditData(dataForEdit);
    }
  };

  const openViewModal = (data) => {
    console.log("data12", data);
    data.createdAt = data?.data?.createdAt.split("T")[0];
    setEditData(data);
    setViewModal(true);
  };

  const handleSort = (key, direction) => {
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (newFilterObj) => {
    setFilterObj(newFilterObj);
    console.log("Applied Filters:", newFilterObj);
  };

  console.log("editdata", editData);

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleSubmit = (data) => {
    console.log("Form submitted:", data);
    setIsModalOpen(false);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <>
      <Cards
        data={bannerData}
        title={"Waste Generated in Operations"}
        onFilterChange={handleSelectChange}
      />
      <ActionButtons
        onFilterClick={() => setIsFilterModalOpen(true)}
        onAddClick={() => openModal("add")}
        filterObj={filterObj}
      />

      <ViewTable
        headers={headers}
        data={tableData ?? []}
        onSort={handleSort}
        sortConfig={sortConfig}
        page={currentPage}
        count={pageSize}
        onPageChange={handlePageChange}
        onView={(row) => {
          openViewModal(row);
        }}
        onEdit={(row) => openModal("edit", row)}
        onDelete={() => {
          console.log("first");
        }}
      />

      <Form
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        header={editModal ? "Edit Data" : "Add Data"}
        initialData={editData}
        isEditing={editModal}
      />

      <View
        isOpen={viewModal}
        onClose={() => setViewModal(false)}
        header={"View Data"}
        initialData={editData}
        handleEdit={(row) => openModal("edit", row)}
      />

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        filters={filterData}
        onFilterChange={handleFilterChange}
        title="Filter"
      />
    </>
  );
};

export default WasteGenerated;
