import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const DeleteDialog = ({deleteDialog,setDeleteDialog,handleDeleteAggree}) => {
  return (
    // <Dialog
    //       open={deleteDialog}
    //       keepMounted
    //       aria-describedby="alert-dialog-slide-description"
    //       maxWidth="lg"
    //     >
    //       <DialogContent>
    //         <DialogContentText id="alert-dialog-slide-description">
    //           Are you sure you want to Delete?
    //         </DialogContentText>
    //       </DialogContent>
    //       <DialogActions>
    //         <Button
    //           sx={{ textTransform: "none" }}
    //           onClick={() => setDeleteDialog(false)}
    //         >
    //           Cancle
    //         </Button>
    //         <Button sx={{ textTransform: "none" }} onClick={handleDeleteAggree}>
    //           Delete
    //         </Button>
    //       </DialogActions>
    //     </Dialog>
    <Dialog open={deleteDialog} onClose={handleDeleteAggree}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Do you really want to delete this entry?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button  onClick={() => setDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteAggree} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
  )
}

export default DeleteDialog