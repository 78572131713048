import {
    Box,
    FormControl,
    TextField,
    styled,
    MenuItem,
    Typography,
    CircularProgress,
    Button,
  } from "@mui/material";
  import axios from "axios";
  import React, { useEffect, useState } from "react";
  import InputField from "./InputField";
  import { updatePosition } from "../../utils/updatePosition";
  
  // Styled MenuItem for ellipsis with text wrapping
  const EllipsisMenuItem = styled(MenuItem)(({ theme }) => ({
    overflow: "hidden",
    whiteSpace: "normal", // Allow text to wrap
    textOverflow: "clip", // No text overflow, allow wrap
    maxWidth: "300px", // You can adjust this or use width: '100%' for flexible sizing
    wordBreak: "break-word", // Ensures that long words break correctly and wrap to the next line
  }));
  
  // CustomTextField styled to match input style
  const CustomTextField = styled(TextField)(({ theme, error }) => ({
    "& .MuiInputBase-root": {
      height: "40px",
    },
    ...(error && {
      "& .MuiOutlinedInput-root": {
        borderColor: "#D34040",
        "&:hover": {
          borderColor: "#D34040",
        },
        "&.Mui-focused": {
          borderColor: "#D34040",
        },
      },
    }),
  }));
  
  const SelectField = ({
    defaultValue,
    newOption,
    title,
    value,
    handleChange,
    name,
    options,
    errors,
    url,
    field,
    keyName,
  }) => {
    const [newOptions, setNewOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [newOptionValue, setNewOptionValue] = useState("");
    const [isAdded, setIsAdded] = useState(false);
  
    // Fetch options
    const fetchOptions = async () => {
      setLoading(true);
      try {
        const res = await axios.get(url);
        if (Array.isArray(res.data.data) && res.data.data.length > 0) {
          const fetchedOptions = res.data.data.map((item) => ({
            id: item._id,
            name: field ? item[field] : item.name || item.facilityName,
          }));
          setNewOptions(fetchedOptions);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      setNewOptionValue(newOption);
      if (url) {
        fetchOptions();
      }
    }, [url]);
  
    // Customize MenuProps to control dropdown behavior
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: "55%",
          overflowY: "auto",
        },
      },
    };
  
    // Handle adding new option
    const handleAddNew = () => {
      if (newOptionValue.trim()) {
        const updatedOptions = [...newOptions, newOptionValue]; // Add the new option
        setNewOptions(updatedOptions);
        setIsAdded(true); // Mark as added to prevent adding again
        setNewOptionValue(""); // Clear the input
        setIsAddingNew(false); // Close the input field
        handleChange({ target: { name, value: newOptionValue } }); // Automatically select the new option
      }
    };
  
    // Combine static options with new ones
    const allOptions = updatePosition([
      ...(options || []), // Spread the existing options, fallback to empty array if undefined
      ...(newOptions || []), // Spread the fetched options
      ...(newOption ? [newOption] : []), // Add the new option value if it's not empty
    ]);
  
    return (
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          {title}
        </Typography>
        <FormControl fullWidth margin="normal" sx={{ mt: 0 }}>
          <CustomTextField
            select
            name={name}
            value={value || "Tap to select"} // Default to "Tap to select" if no value is set
            onChange={handleChange}
            error={!!errors}
            SelectProps={{ MenuProps }}
          >
            <MenuItem value="Tap to select" disabled>
              {defaultValue || "Tap to select"}
            </MenuItem>
  
            {loading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              allOptions.map((option, index) => {
                return option === "Others" ? (
                  <EllipsisMenuItem
                    key={index}
                    onClick={() => {
                      setIsAddingNew(true);
                      setNewOptionValue(""); // Clear any previous value
                    }}
                  >
                    Others
                  </EllipsisMenuItem>
                ) : (
                  <EllipsisMenuItem
                    key={option?.id || index}
                    value={option?.id || option?.name || option}
                  >
                    {option?.name || option} {/* Render the option's name */}
                  </EllipsisMenuItem>
                );
              })
            )}
          </CustomTextField>
  
          {/* Add New Option Input */}
          {isAddingNew && (
            <Box sx={{ mt: 2 }}>
              <InputField
                value={newOptionValue}
                handleChange={(e) => setNewOptionValue(e.target.value)}
                label="New Option"
                fullWidth
                placeholder="Enter New Option"
              />
              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                <Button onClick={() => setIsAddingNew(false)} color="secondary">
                  Cancel
                </Button>
                <Button onClick={handleAddNew} color="primary">
                  Add
                </Button>
              </Box>
            </Box>
          )}
  
          {errors && (
            <Typography variant="body2" color="#D34040">
              {errors}
            </Typography>
          )}
        </FormControl>
      </Box>
    );
  };
  
  export default SelectField;
  