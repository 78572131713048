import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Toolbar,
  Typography,
  Box,
  PaginationItem,
  Grid,
} from "@mui/material";
import axios from "axios";
import { api } from "../../../../utils/api";
import { styled } from "@mui/material/styles";
// import VehicleFilter from "../../Roles and Permissions/Filters/VehicleFilter";
import PaginationBox from "../../../../Components/Masters/Pagination";
import DeleteDialog from "../../../../Components/Masters/DeleteDialog";
import ColumnHeadings from "../../../../Components/Masters/ColumnHeadings";
import MenuActions from "../../../../Components/Masters/MenuActions";
import TableContent from "../../../../Components/Masters/TableContent";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import MessagePopup from "../../../../Components/Masters/MessagePopup";
import TableHeading from "../../../../Components/Masters/TableHeading";
import MenuIcon from "../../../../Components/Masters/MenuIcon";
import { companyId } from "../../../../utils/companyId";
import DateRangePickerComponent from "../../../Masters/Roles and Permissions/Filters/Date";
import MultiSelect from "../../../Masters/Roles and Permissions/Filters/MultiSelect";
import Range from "../../../Masters/Roles and Permissions/Filters/Range";
import Cards from "../../../../Components/Masters/Cards";
import SelectCard from "../../../../Components/Masters/SelectCard";
import NoDataFound from "../../../../Components/Masters/NoDataFound";
import ViewTable from "../../../../Components/Common/ViewTable";
import ViewData from "./ViewData";
import AddData from "./AddData";

const Emissions = () => {
  const [selectedTypeOfActivity, setSelectedTypeOfActivity] = React.useState("Type of Activity");
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  // const months = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addData, setAddData] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    "Name of Activity":false,
    "Category Type": false,
    "Type of Activity": false,
    "Amount Spend": false,
    "Quantity Bought": false,
  });
  const columnWidth = "12% 18% 18% 21% 23% 8%";
  const [viewData, setViewData] = useState(false);
  const [element, setElement] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [errors, setErrors] = useState({
    Date: false,
    "Name of Activity":false,
    "Category Type": false,
    "Type of Activity": false,
    "Amount Spend": false,
    "Quantity Bought": false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    activityName:[],
    categoryType: [],
    typeOfActivity: [],
  });
  const [quantityRange, setQuantityRange] = useState({ min: "", max: "" });
  const [amountRange, setAmountRange] = useState({ min: "", max: "" });

  const filters = [
    "Date",
    "Name of Activity",
    "Category Type",
    "Type of Activity",
    "Amount Spend",
    "Quantity Bought",
  ];

  const inputData = [
    {
      fieldName: "purchaseVendor",
      title: "Vendor",
      placeholder: "Select vendor",
      type: "select",
      url: `${api}/master/purchasedGoods`, // Assuming this API will provide the vendor list.
      field: "vendorLegalName",
      required: true,
    },
    {
      fieldName: "categoryType",
      title: "Category Type",
      placeholder: "Select category type",
      type: "select",
      field: "categoryType",
      required:true,
      options: [
        // Agriculture & Oilseeds
        "Fresh Soybeans, Canola, Flaxseeds, and Other Oilseeds",
        "Fresh Wheat, Corn, Rice, and Other Grains",
        "Fresh Vegetables, Melons, and Potatoes",
        "Fresh Fruits and Tree Nuts",
        "Greenhouse Crops, Mushrooms, Nurseries, and Flowers",
        "Tobacco, Cotton, Sugarcane, Peanuts, Sugar Beets, Herbs and Spices, and Other Crops",

        // Animal Agriculture & Aquaculture
        "Dairies",
        "Cattle Ranches and Feedlots",
        "Poultry Farms",
        "Animal Farms and Aquaculture Ponds (Except Cattle and Poultry)",

        // Forestry & Timber
        "Timber and Raw Forest Products",

        // Fisheries & Wildlife
        "Wild-Caught Fish and Game",

        // Agriculture & Forestry Support
        "Agriculture and Forestry Support",

        // Mining & Raw Materials
        "Unrefined Oil and Gas",
        "Coal",
        "Copper, Nickel, Lead, and Zinc",
        "Iron, Gold, Silver, and Other Metal Ores",
        "Dimensional Stone",
        "Sand, Gravel, Clay, Phosphate, Other Nonmetallic Minerals",
        "Well Drilling",
        "Other Support Activities for Mining",

        // Energy
        "Electricity",
        "Natural Gas",

        // Water & Wastewater
        "Drinking Water and Wastewater Treatment",

        // Building & Construction
        "Nonresidential Building Repair and Maintenance",
        "Residential Building Repair and Maintenance",
        "Health Care Buildings",
        "Manufacturing Buildings",
        "Utilities Buildings and Infrastructure",
        "Schools and Vocational Buildings",
        "Highways, Streets, and Bridges",
        "Commercial Structures, Including Farm Structures",
        "Other Nonresidential Structures",
        "Single-Family Homes",
        "Multifamily Homes",
        "Other Residential Structures",

        // Food Manufacturing & Processing
        "Dog and Cat Food",
        "Other Animal Food",
        "Flours and Malts",
        "Corn Products",
        "Refined Vegetable, Olive, and Seed Oils",
        "Vegetable Oils and By-Products",
        "Breakfast Cereals",
        "Sugar, Candy, and Chocolate",
        "Frozen Food",
        "Fruit and Vegetable Preservation",
        "Cheese",
        "Dry, Condensed, and Evaporated Dairy",
        "Fluid Milk and Butter",
        "Ice Cream and Frozen Desserts",
        "Packaged Poultry",
        "Packaged Meat (Except Poultry)",
        "Seafood",
        "Bread and Other Baked Goods",
        "Cookies, Crackers, Pastas, and Tortillas",
        "Snack Foods",
        "Coffee and Tea",
        "Flavored Drink Concentrates",
        "Seasonings and Dressings",
        "All Other Foods",
        "Soft Drinks, Bottled Water, and Ice",
        "Breweries and Beer",
        "Wineries and Wine",
        "Distilleries and Spirits",
        "Tobacco Products",

        // Textile & Apparel
        "Fiber, Yarn, and Thread",
        "Fabric",
        "Finished and Coated Fabric",
        "Carpets and Rugs",
        "Curtains and Linens",
        "Other Textiles",
        "Clothing",
        "Leather",

        // Wood & Paper Products
        "Lumber and Treated Lumber",
        "Plywood and Veneer",
        "Wooden Windows, Doors, and Flooring",
        "Veneer, Plywood, and Engineered Wood",
        "Wood Pulp",
        "Paper",
        "Cardboard",
        "Cardboard Containers",
        "Paper Bags and Coated Paper",
        "Stationery",
        "Sanitary Paper (Tissues, Napkins, Diapers, etc.)",
        "All Other Converted Paper Products",

        // Print Media
        "Books, Newspapers, Magazines, and Other Print Media",
        "Printing Support",

        // Petroleum & Coal Products
        "Gasoline, Fuels, and By-Products of Petroleum Refining",
        "Asphalt Pavement",
        "Asphalt Shingles",
        "Other Petroleum and Coal Products",
      ],
    },
    {
      fieldName: "activityName",
      title: "Name of Activity",
      placeholder: "Enter activity name",
      type: "text",
      required: true,
    },
    {
      fieldName: "typeOfActivity",
      title: "Type of Activity",
      placeholder: "Select activity type",
      type: "select",
      options: [
        "Production Materials",
        "Finished Goods",
        "Transportation and Distribution (not included in Upstream and Downstream Transportation and Distribution category)",
        "Information Technology and Communication",
        "Professional Services",
        "Others",
      ],
      required: true,
    },
    {
      fieldName: "amountSpend",
      title: "Amount Spend in USD",
      placeholder: "Enter amount spent",
      type: "number",
      required: true,
      nested:true,
      // options: ["USD", "INR", "EUR"], // Example of currency units
      adornmentText: "USD", // The unit could be currency
    },
    {
      fieldName: "quantityBought",
      title: "Quantity Bought(in KG)",
      placeholder: "Enter quantity",
      type: "number",
      nested:true,
      required: true,
      adornmentText: "KG", // The unit could be currency
      // field: "unit",
      // options: ["MT", "kg", "liters"], // Example of possible units
    },
    // {
    //   fieldName: "quantityUnit",
    //   title: "Unit of Quantity",
    //   placeholder: "Select unit",
    //   type: "select",
    //   options: ["MT", "kg", "liters"], // Example of possible units
    //   required: true,
    // },
    // {
    //   fieldName: "amountUnit",
    //   title: "Unit of Amount Spent",
    //   placeholder: "Select unit",
    //   type: "select",
    //   options: ["USD", "INR", "EUR"], // Example of currency units
    //   required: true,
    // },
    {
      fieldName: "remark",
      title: "Remark",
      placeholder: "Enter additional remarks",
      type: "text",
    },
    {
      fieldName: "documents",
      title: "Upload Documents",
      type: "file",
    },
  ];

  const fetchUniqueValues = async () => {
    // Extract unique values for "Category Type"
    try {
      const response = await axios.get(
        `${api}/scope3/emission/get`
      );
      const data = response.data.data;
      console.log(data);

      
     const uniqueActivityName = [
      ...new Set(
        data?.map((el) => el.activityName).filter(Boolean) // Assuming categoryType is a property of the object
      ),
    ];

     const uniqueCategoryTypes = [
      ...new Set(
        data?.map((el) => el.categoryType).filter(Boolean) // Assuming categoryType is a property of the object
      ),
    ];

    // Extract unique values for "Name of the activity"
    const uniqueTypeOfActivity = [
      ...new Set(
        data?.map((el) => el.typeOfActivity
      ).filter(Boolean) // Assuming nameOfTheActivity is a property of the object
      ),
    ];
console.log(uniqueCategoryTypes,uniqueTypeOfActivity)
    return {
      activityName:uniqueActivityName,
      categoryType: uniqueCategoryTypes,
      typeOfActivity: uniqueTypeOfActivity,
    };
  }catch(error){
    console.error("Error fetching unique values:", error);
    return {};
  }
  };

  // fetching Vehicle data
  const fetchData = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    console.log(filters);
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
        company: companyId,
        createdBy: companyId,
      }).toString();
      const response = await axios.get(
        `${api}/scope3/emission/get?${queryParams}`
      );

      setData(response.data.data); // Update the user list
      console.log(response.data.data);
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortConfig]);

  const handleMenuClick = (event, element) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(element);
    setElement(element);
    console.log(element);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedData(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddData(true);
    setViewData(false);
  };

  const handleView = () => {
    handleMenuClose();
    setViewData(true);
    setAddData(false);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = async () => {
    // console.log("indexhandleDeleteAggree", vehicle, vehicle._id);
    try {
      console.log(element._id);
      const res = await axios.put(`${api}/scope3/emission/delete?Id=${element._id}`);
      setDeleteDialog(false);
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
    } catch (err) {
      console.log(err);
      setsnackOpen({
        open: true,
        message: err.data?.response.message ?? "network Error",
        severity: "error",
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filtersToApply = {
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        console.log(key)
        acc[key] = selectedOptions.join(key==="categoryType" ? "|" : ","); // Change to an array if needed
      }
      return acc;
    }, {}),
    minQuantity: quantityRange.min || "",
    maxQuantity: quantityRange.max || "",
    minAmount: amountRange.min || "",
    maxAmount: amountRange.max || "",
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
      "Name of Activity":false,
      "Category Type": false,
      "Type of Activity": false,
      "Amount Spend": false,
      "Quantity Bought": false,
    };

    console.log(selectedFilter, "hi");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select a date range";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }
    if (
      selectedFilter === "Name of Activity" &&
      selectedFields.activityName.length === 0
    ) {
      newErrors["Name of Activity"] = "Please select Name of Activity";
      isValid = false;
    }


    if (
      selectedFilter === "Category Type" &&
      selectedFields.categoryType.length === 0
    ) {
      newErrors["Category Type"] = "Please select Category Type";
      isValid = false;
    }

    if (
      selectedFilter === "Type of Activity" &&
      selectedFields.typeOfActivity.length === 0
    ) {
      newErrors["Type of Activity"] = "Please select Type of Activity";
      isValid = false;
    }

    if (selectedFilter === "Quantity Bought") {
      const min = parseFloat(quantityRange.min);
      const max = parseFloat(quantityRange.max);

      if (!min || !max) {
        newErrors["Quantity Bought"] = "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Quantity Bought"] = "Min quantity must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Quantity Bought"] = "Max quantity must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Quantity Bought"] =
          "Min quantity cannot be greater than max Quantity";
        isValid = false;
      }
    }
    if (selectedFilter === "Amount Spend") {
      const min = parseFloat(amountRange.min);
      const max = parseFloat(amountRange.max);

      if (!min || !max) {
        newErrors["Amount Spend"] = "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Amount Spend"] = "Min amount must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Amount Spend"] = "Max amount must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Amount Spend"] =
          "Min amount cannot be greater than max amount";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    console.log(selectedFields);
    if (!validateFilters()) return;
    setPage(1);
    fetchData(filtersToApply);
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
      activityName:[],
      typeOfACtivity: [],
      categoryType: [],
    });
    setQuantityRange({ min: "", max: "" });
    setAmountRange({ min: "", max: "" });

    // Call fetchData without any filters
    await fetchData({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate;
    const isNameOfActivityApplied=selectedFields.activityName?.length>0;
    const isTypeOfActivityApplied = selectedFields.typeOfActivity?.length > 0;
    const isCategoryTypeApplied = selectedFields.categoryType?.length > 0;
    const isQuantityRangeApplied = quantityRange.min && quantityRange.max;
    const isAmountRangeApplied = amountRange.min && amountRange.max;

    setIsFiltersApplied(
      isDateApplied ||
      isNameOfActivityApplied ||
        isTypeOfActivityApplied ||
        isCategoryTypeApplied ||
        isQuantityRangeApplied ||
        isAmountRangeApplied
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      "Name of Activity":isNameOfActivityApplied,
      "Category Type": isCategoryTypeApplied,
      "Type of Activity": isTypeOfActivityApplied,
      "Amount Spend": isAmountRangeApplied,
      "Quantity Bought": isQuantityRangeApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    // setErrors({ Date: false, Role: false });
  };

  const handleActivityChange = (event) => {
    setSelectedTypeOfActivity(event.target.value);
  };

  const headers = [
    { key: "createdAt", label: "Date", sortable: true },
    { key: "activityName", label: "Name of Activity", sortable: true },
    { key: "typeOfActivity", label: "Type of Activity", sortable: true },
    {
      key: "amountSpend",
      label: "Amount Spend(USD)",
      sortable: true,
      field: "value",
    },
    {
      key: "quantityBought",
      label: "Quantity Bought(in KG)",
      sortable: true,
      field: "value",
    },
    // { key: "unit", label: "Vendor Name", sortable: true },
  ];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/scope3/emission/get`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
        case "Name of Activity":
        return (
          <MultiSelect
            title="Name of Activity"
            selectedField={selectedFields.activityName}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Name of Activity"]}
            setErrors={setErrors}
            field="activityName"
            data={uniqueValues.activityName}
          />
        );
      case "Category Type":
        return (
          <MultiSelect
            title="Category Type"
            selectedField={selectedFields.categoryType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Category Type"]}
            setErrors={setErrors}
            field="categoryType"
            data={uniqueValues.categoryType}
          />
        );
      case "Type of Activity":
        return (
          <MultiSelect
            title="Type of Activity"
            selectedField={selectedFields.typeOfActivity}            
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Type of Activity"]}
            setErrors={setErrors}
            field="typeOfActivity"
            data={uniqueValues.typeOfActivity}
          />
        );

      case "Quantity Bought":
        return (
          <Range
            title="Quantity Bought"
            selectedRange={quantityRange}
            setSelectedRange={setQuantityRange}
            errors={errors["Quantity Bought"]}
            setErrors={setErrors}
            placeholderMax="Max (Unit)"
            placeholderMin="Min (Unit)"
          />
        );
      case "Amount Spend":
        return (
          <Range
            title="Amount Spend"
            selectedRange={amountRange}
            setSelectedRange={setAmountRange}
            errors={errors["Amount Spend"]}
            setErrors={setErrors}
            placeholderMin="Min (USD)"
            placeholderMax="Max (USD)"
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/scope3/emission/get`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <MessagePopup {...{ snackOpen, setsnackOpen }} />{" "}
      {/*Message popup for success or failure */}
      {/* View log, Edit, delete menus */}
      <AddData
        id={element ? element._id : ""}
        title="Emissions"
        addUrl={`${api}/scope3/emission/add`}
        updateUrl={`${api}/scope3/emission/update`}
        {...{fetchData,
        setsnackOpen,uniqueValues,
        inputData,data,element,addData,setAddData}}
      />
      <ViewData
        id={element ? element._id : ""}
        title={"Emissions"}
        {...{element,viewData,
        setViewData,
        inputData,
        handleEdit}}
      />
      <Grid item xs={6} sx={{ margin: "0% 5% -3% 23%", width: "100%" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Purchased Goods & Services
        </Typography>
        <Box sx={{ display: "flex", width: "83%", mt: 2, gap: 2 }}>
          {/* Card 1: Total Amount Spent */}
          <Cards title="Total Amount Spent" content="10,000 USD" />
          {/* Card 2: Amount Spent On type of Activity */}
          <SelectCard
            title="Amount Spent On"
            content="200 USD"
            handleChange={handleActivityChange}
            value={selectedTypeOfActivity}
            width={"50%"}
            list={ ["Type of Activity","Production Materials",
              "Finished Goods",
              "Transportation and Distribution (not included in Upstream and Downstream Transportation and Distribution category)",
              "Information Technology and Communication",
              "Professional Services",
              "Others"]}
          />
          {/* Card 3: Consumption Period */}

          <Cards
            title="Top Purchased Items"
            content="Item 1  |  Item 2  |  Item 3"
          />
        </Box>
      </Grid>
      {deleteDialog && (
        <DeleteDialog
          {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
        />
      )}
      {/* Filter Drawer */}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setElement}
          setAddData={setAddData}
          {...{
            // heading: "Coal",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>
      {/* <ViewTable
        headers={headers}
        data={data}
        onSort={handleSort}
        sortConfig={sortConfig}
        onMenuClick={handleMenuClick}
        page={2}
        count={10}
        onPageChange={() => {
          console.log("first");
        }}
        onView={handleView}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
  */}
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "3% 5% 0% 23%" }}
      >
        <Box>
          <TableHeading heading={"Emissions"} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: columnWidth,
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            {headers.map((el) => (
              <ColumnHeadings
                key={el}
                {...{
                  title: el.label,
                  key: el.label,
                  handleSort,
                  field: el.key,
                  sortConfig,
                }}
              />
            ))}
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {data.length === 0 ? (
            <NoDataFound />
          ) : (
            data.map((item) => (
              <Box
                key={item._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: columnWidth,
                  borderBottom: "1px solid #ddd",
                }}
              >
                {headers.map((el) => (
                  <TableContent
                    key={el.key}
                    {...{
                      fieldData: el.field ? item[el.key].value : item[el.key],
                      dateField: el.key === "createdAt",
                    }}
                  />
                ))}
                {/* Menu Action Icon */}
                <MenuIcon {...{ handleMenuClick, field: item }} />
              </Box>
            ))
          )}
        </Box>
      </TableContainer>
      {/* Pagination */}
      {totalPages > 1 && (
        <PaginationBox
          {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
        />
      )}
      <MenuActions
        {...{
          id: element?._id,
          handleView,
          handleEdit,
          handleDelete,
          anchorEl,
          handleMenuClose,
        }}
      />
      {/*Add,Edit,Delete Icons & function calls */}
    </Box>
  );
};

export default Emissions;
