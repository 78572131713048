import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PaginationItem,
  Drawer,
  Alert,
  Snackbar,
  DialogTitle,
  Card,
  CardContent,
  Select,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
import AddEquipment from "./Add";
import UserDateFilter from "../../../Masters/Roles and Permissions/Filters/UserFilters/UserDateFilter";
import { api } from "../../../../utils/api";
import FilterSidebar from "../../../../Components/Masters/Filter/FilterSidebar";
import UnitFilter from "../../../Masters/Scope2/HeatMaster/EquipmentMaster/Filters/unitFilter";
import Cards from "../../../../Components/Masters/Cards";
import SelectCard from "../../../../Components/Masters/SelectCard";
import NoDataFound from "../../../../Components/Masters/NoDataFound";
import PaginationBox from "../../../../Components/Masters/Pagination";

import ApplyButton from "../../../Masters/Roles and Permissions/Filters/ApplyButton";

import CountryFilter from "../../../Masters/Roles and Permissions/Filters/UserFilters/CountryFilter";

import BrandFilter from "../../../Masters/Roles and Permissions/Filters/BrandFilter";
import ModelFilter from "../../../Masters/Scope2/HeatMaster/EquipmentMaster/Filters/modelFilter";

import ResetButton from "../../../Masters/Roles and Permissions/Filters/ResetButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from "@mui/material/styles";
import ViewEquipment from "./View";
// import RatedConsumptionFilter from "./Filters/ratedConsumptionFilter";
import RatedConsumptionFilter from "../../../Masters/Scope2/HeatMaster/EquipmentMaster/Filters/ratedConsumptionFilter";
import DateRangePickerComponent from "../../../Masters/Roles and Permissions/Filters/Date";
import MultiSelect from "../../../Masters/Roles and Permissions/Filters/MultiSelect";
import Range from "../../../Masters/Roles and Permissions/Filters/Range";

const NaturalGasEquipment = () => {
  const [selectedPeriod, setSelectedPeriod] = React.useState("July");
  const [equipments, setEquipments] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addEquipment, setAddEquipment] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    date: false,
    industrialProcess: false,
    equipmentType: false,
    industrialEquipment: false,
    quantityConsumed: false,
    hoursUsed: false,
  });
  const [selectedFields, setSelectedFields] = useState({
    industrialProcess: [],
    equipmentType: [],
    industrialEquipment: [],
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [viewEquipment, setViewEquipment] = useState(false);
  const [dataView, setdataView] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries
  const [selectedEqupments, setSelectedEquipments] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [errors, setErrors] = useState({
    date: false,
    industrialProcess: false,
    equipmentType: false,
    industrialEquipment: false,
    quantityConsumed: false,
    hoursUsed: false,
  });
  const [quantityRange, setQuantityRange] = useState({ min: "", max: "" });
  const [hoursRange, setHoursRange] = useState({ min: "", max: "" });

  const filters = [
    "Date",
    "Industrial Process",
    "Equipment Type",
    "Industrial Equipment",
    "Quantity Consumed",
    "Hours Used",
  ];
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const facilityData = [
    { header: "Date", field: "createdAt" },
    // { header: "Facility Name", field: "facilityName" },
    { header: "Brand", field: "brand" },
    // { header: "Outsource Facility", field: "outsourceFacility" },
    { header: "Country", field: "country" },
    // { header: "Action" },
  ];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/stationaryCombustion/getAllNaturalGas`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.date,
            }}
          />
        );
      case "Industrial Process":
        return (
          <MultiSelect
            title="Industrial Process"
            selectedField={selectedFields.industrialProcess}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Industrial Process"]}
            setErrors={setErrors}
            field="industrialProcess"
            data={uniqueValues.industrialProcess}
          />
        );
      case "Equipment Type":
        return (
          <MultiSelect
            title="Equipment Type"
            url={`${api}/master/getAllEquipmentTypes`}
            selectedField={selectedFields.equipmentType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Equipment Type"]}
            setErrors={setErrors}
            field="equipmentType"
            data={uniqueValues.equipmentType}
          />
        );
      case "Industrial Equipment":
        return (
          <MultiSelect
            title="Industrial Equipment"
            selectedField={selectedFields.industrialEquipment}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Industrial Equipment"]}
            setErrors={setErrors}
            field="industrialEquipment"
            data={uniqueValues.industrialEquipment}
          />
        );
      case "Quantity Consumed":
        return (
          <Range
            title="Quantity Consumed"
            selectedRange={quantityRange}
            setSelectedRange={setQuantityRange}
            errors={errors["Quantity Consumed"]}
            setErrors={setErrors}
          />
        );

      case "Hours Used":
        return (
          <Range
            title="Hours Used"
            selectedRange={hoursRange}
            setSelectedRange={setHoursRange}
            errors={errors["Hours Used"]}
            setErrors={setErrors}
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/stationaryCombustion/getAllCoal`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.date,
            }}
          />
        );
    }
  };
  const handleMenuClick = (event, equipment) => {
    setAnchorEl(event.currentTarget);
    setSelectedEquipment(equipment);
    setdataView(equipment);
    console.log("equipmenthandle", equipment);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedEquipment(null);
  };

  const handleEdit = () => {
    console.log("Edit equipment:", selectedEquipment);
    handleMenuClose();
    setAddEquipment(true);
    setViewEquipment(false);
  };

  const handleView = () => {
    console.log("View equipment:", selectedEquipment);
    handleMenuClose();
    setViewEquipment(true);
  };

  const handleDelete = () => {
    console.log("Delete:", selectedEquipment);
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = () => {
    console.log("indexhandleDeleteAggree", dataView, dataView._id);
    axios
      .put(
        `${api}/stationaryCombustion/deleteNaturalGas`,
        {}, // Empty object because no data needs to be passed in the body
        {
          params: {
            Id: dataView._id, // Correctly pass as query parameter
          },
        }
      )
      .then((res) => {
        console.log(res, "res++");
        setDeleteDialog(false);
        setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });

        // Ensure state is updated correctly:
        setEquipments((prevEquipments) =>
          prevEquipments.filter((item) => item._id !== dataView._id)
        );

        if (equipments.length === 1 && page > 1) {
          setPage(page - 1);
        } else {
          fetchHeatEquipment();
        }
      })
      .catch((err) => {
        console.log(err, "errOfEquipment");
        setsnackOpen({
          open: true,
          message: err?.response?.data?.message ?? "network Error",
          severity: "error",
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchHeatEquipment(filtersToApply);
  };

  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(
        `${api}/stationaryCombustion/getAllNaturalGas`
      );
      const data = response.data.data;
      console.log(data);

      const uniqueIndustrialProcess = [
        ...new Set(
          data
            .map((el) => el.industrialProcess?.industryProcess)
            .filter(Boolean) // Filter out undefined values
        ),
      ];

      console.log(uniqueIndustrialProcess);

      const uniqueEquipmentType = [
        ...new Set(
          data.map((el) => el.equipmentType?.name).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniqueIndustrialEquipment = [
        ...new Set(
          data.map((el) => el.industrialEquipment?.name).filter(Boolean) // Filter out undefined values
        ),
      ];

      return {
        industrialProcess: uniqueIndustrialProcess,
        equipmentType: uniqueEquipmentType,
        industrialEquipment: uniqueIndustrialEquipment,
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  // fetching  data
  //   const fetchHeatEquipment = async (filters = filtersToApply) => {
  const fetchHeatEquipment = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    console.log("filters", filters);
    try {
      console.log(sortConfig);
      const queryParams = new URLSearchParams({
        ...filters,
        minConsumption: filters.minConsumption || "", // Handle empty values
        maxConsumption: filters.maxConsumption || "",
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      console.log("Query Params sent to API:", queryParams, filters); // Debugging line
      const response = await axios.get(
        `${api}/stationaryCombustion/getAllNaturalGas?${queryParams}`
      );

      console.log("Fetched data after filtering:", response.data); // Debugging line
      setEquipments(response.data.data); // Update the user list
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
      // setUniqueValues(await fetchUniqueValues());
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    // fetchHeatEquipment();
    console.log("Current Filters Applied:", filtersToApply);
    fetchHeatEquipment(filtersToApply);
  }, [page, rowsPerPage, sortConfig]);

  // default filters to apply
  const filtersToApply = {
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        acc[key] = selectedOptions.join(","); // Change to an array if needed
      }
      return acc;
    }, {}),
    minQuantity: quantityRange.min || "",
    maxQuantity: quantityRange.max || "",
    minHours: hoursRange.min || "",
    maxHours: hoursRange.max || "",
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
      "Industrial Process": false,
      "Equipment Type": false,
      "Industrial Equipment": false,
      "Quantity Consumed": false,
      "Hours Used": false,
    };

    console.log(selectedFilter, "hi");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select start date & end date";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }

    if (
      selectedFilter === "Industrial Process" &&
      selectedFields.industrialProcess.length === 0
    ) {
      newErrors["Industrial Process"] =
        "Please select atleast one Industrial Processs";
      isValid = false;
    }

    if (
      selectedFilter === "Industrial Equipment" &&
      selectedFields.industrialEquipment.length === 0
    ) {
      newErrors["Industrial Equipment"] =
        "Please select atleast one Industrial Equipment";
      isValid = false;
    }

    if (
      selectedFilter === "Equipment Type" &&
      selectedFields.equipmentType.length === 0
    ) {
      newErrors["Equipment Type"] = "Please select atleast one Equipment Type";
      isValid = false;
    }

    if (selectedFilter === "Quantity Consumed") {
      const min = parseFloat(quantityRange.min);
      const max = parseFloat(quantityRange.max);

      if (!min && !max) {
        newErrors["Quantity Consumed"] =
          "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Quantity Consumed"] =
          "Min consumption must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Quantity Consumed"] =
          "Max consumption must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Quantity Consumed"] =
          "Min consumption cannot be greater than max consumption";
        isValid = false;
      }
    }
    if (selectedFilter === "Hours Used") {
      const min = parseFloat(hoursRange.min);
      const max = parseFloat(hoursRange.max);

      if (!min && !max) {
        newErrors["Hours Used"] = "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Hours Used"] = "Min hours must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Hours Used"] = "Max hours must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Hours Used"] =
          "Min hours cannot be greater than max consumption";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    console.log(selectedFields, "apply filters");
    if (!validateFilters()) return;
    setPage(1);
    fetchHeatEquipment(filtersToApply);
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  // const handleResetFilters = async () => {
  //   // Reset local state for date selection and selected fields
  //   setStartDate(null);
  //   setEndDate(null);
  //   setSelectedFields({
  //       industrialProcess: [],
  //       equipmentType: [],
  //       industrialEquipment: [],
  //   });

  //   // Call fetchData without any filters
  //   await fetchHeatEquipment({});
  //   setErrors({});
  //   setAppliedFilters({});
  //   setIsFiltersApplied(false);
  //   setDrawerFilterOpen(false);
  // };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
      industrialProcess: [],
      equipmentType: [],
      industrialEquipment: [],
    });

    // Reset the filtersToApply state
    setIsFiltersApplied(false); // Ensure you set this to false
    setAppliedFilters({}); // Reset applied filters state
    setErrors({}); // Reset errors if any
    setQuantityRange({ min: "", max: "" }); // Reset quantity range
    setHoursRange({ min: "", max: "" }); // Reset hours range

    // Call fetchData without any filters
    await fetchHeatEquipment({});
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate;
    const isIndustryEquipmentApplied =
      selectedFields.industrialEquipment?.length > 0;
    const isEquipmentTypeApplied = selectedFields.equipmentType?.length > 0;
    const isIndustryProcessApplied =
      selectedFields.industrialProcess?.length > 0;
    const isQuantityRangeApplied = quantityRange.min && quantityRange.max;

    const isHoursRangeApplied = hoursRange.min && hoursRange.max;

    setIsFiltersApplied(
      isDateApplied ||
        isIndustryEquipmentApplied ||
        isEquipmentTypeApplied ||
        isQuantityRangeApplied ||
        isHoursRangeApplied ||
        isIndustryProcessApplied
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      "Industrial Process": isIndustryProcessApplied,
      "Equipment Type": isEquipmentTypeApplied,
      "Industrial Equipment": isIndustryEquipmentApplied,
      "Quantity Consumed": isQuantityRangeApplied,
      "Hours Used": isHoursRangeApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    // setErrors({Date:false,Role:false});
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Snackbar
        open={snackOpen.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setsnackOpen({ ...snackOpen, open: false })}
      >
        <Alert
          // onClose={handleClose}
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackOpen.message}
          {/* Add user Successfully */}
        </Alert>
      </Snackbar>
      <AddEquipment
        addEquipment={addEquipment}
        setAddEquipment={setAddEquipment}
        fetchHeatEquipment={fetchHeatEquipment}
        setsnackOpen={setsnackOpen}
        //  addFacility={addFacility}
        selectedEquipment={dataView}
      />

      <ViewEquipment
        viewEquipment={viewEquipment}
        setViewEquipment={setViewEquipment}
        selectedEquipment={dataView}
        handleEdit={handleEdit}
      />
      <Grid item xs={6}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            textDecoration: "underline",
            margin: "0% 5% 1% 23%",
          }}
        >
          Natural Gas
        </Typography>
      </Grid>
      <Box
        sx={{
          display: "flex",
          width: "83.5%",
          gap: 2,
          margin: "0% 5% 0% 22.5%",
        }}
      >
        {/* Card 1: Total Natural Gas Consumed */}
        <Cards title="Total Natural Gas Consumed" content="10,000 m³" />
        <Cards
          title="Top Used Equipments"
          content="Equipment 1 | Equipment 2"
        />
        <SelectCard
          title="Consumption Period"
          content="700 m³"
          handleChange={handlePeriodChange}
          value={selectedPeriod}
          list={months}
        />
        {/* Card 3: Consumption Period */}
      </Box>
      {deleteDialog && (
        <Dialog open={deleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to delete this entry?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteAggree} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <Drawer
            anchor="right"
            open={drawerFilterOpen}
            onClose={() => handleFilterDrawer()}
            sx={{
              backgroundColor: "transparent",
              "& .MuiDrawer-paper": {
                // This targets the paper component within the Drawer
                backgroundColor: "transparent", // Make paper transparent
                boxShadow: "none", // Optional: Remove any shadow
                width: "50%",
              },
            }}
          >
            <Box
              display="flex"
              gap={3}
              sx={{
                width: "100%",
                display: "flex",
                overflowX: "hidden",
                maxWidth: "100%",
                justifyContent: "space-btween",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  height: "3%",
                  padding: "13px",
                  margin: "30px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "80%",
                  width: "3%",
                }}
              >
                <IconButton
                  onClick={() => setDrawerFilterOpen(false)}
                  sx={{ color: "black" }}
                >
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
                {/* Sidebar for selecting the filter */}
                <FilterSidebar
                  {...{
                    setSelectedFilter,
                    selectedFilter,
                    filters,
                    appliedFilters,
                  }}
                />

                {/* Filter content area */}
                <Box
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#F9F9F9",
                    padding: "20px",
                  }}
                >
                  {renderSelectedFilter()}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: "3%",
                      position: "fixed",
                      bottom: "20px",
                      ml: 3,
                      mr: 3,
                    }}
                  >
                    <ApplyButton handleApply={handleApplyFilters} />
                    <ResetButton handleReset={handleResetFilters} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}{" "}
        <Grid
          container
          alignItems="center"
          sx={{ width: "80%", margin: "0% 0.5% 0% 22%" }}
        >
          <Grid
            item
            xs={6}
            style={{ textAlign: "right", margin: "0% 0.5% 0.4% 66%" }}
          >
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              // endIcon={<FilterListIcon />}
              sx={{
                margin: "10px 10px -15px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                "&:hover .dot-icon": {
                  color: "#FFFFFF", // Change dot color on button hover
                },
                // display:"flex"
              }}
            >
              Filter
              <FilterListIcon />
              {isFiltersApplied && (
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              )}
            </Button>

            <Button
              variant="contained"
              endIcon={<AddIcon />}
              sx={{
                margin: "10px -36px -15px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              onClick={() => {
                setAddEquipment(true);
                setdataView(null);
              }}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Toolbar>

      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 22.6%" }}
      >
        <Box>
          <Box sx={{ margin: "16px" }}>
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                textAlign: "left",
                // padding: "0px",
              }}
            >
              Natural Gas Combustion
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "12% 27% 26% 26% 9%",
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "12px 18px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Date
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("createdAt", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "createdAt" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("createdAt", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "createdAt" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "12px 12px 14px 12px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Industrial Process
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("industrialProcess", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "industrialProcess" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("industrialProcess", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "industrialProcess" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "12px 12px 14px 12px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Equipment Type
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("equipmentType", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "equipmentType" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("equipmentType", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "equipmentType" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "12px 12px 14px 12px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Quantity Consumed(m3)
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    handleSort("quantityOfNaturalGasConsumed", "asc")
                  }
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "quantityOfNaturalGasConsumed" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() =>
                    handleSort("quantityOfNaturalGasConsumed", "desc")
                  }
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "quantityOfNaturalGasConsumed" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>

            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px 8px 12px",
                marginBottom: "-10px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Action
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              ></Box>
            </Typography>
          </Box>

          {equipments.length === 0 ? (
            <NoDataFound />
          ) : (
            /* Render the equipment list if there is data */
            // {console.log("equipment++",equipments)}
            equipments.map((equipments) => (
              <Box
                key={equipments._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "12% 27% 26% 26% 9%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Typography
                  sx={{
                    //   padding: "1px 4px",
                    padding: " 9px 8px",
                    textAlign: "center",
                    borderRight: "1px solid #ddd",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    //   margin: "7px 0px -7px 0px",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  {/* {new Date(equipments.createdAt).toLocaleDateString()} */}
                  {new Date(equipments.createdAt).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </Typography>
                <Typography
                  sx={{
                    //   padding: " 1px 6px",
                    padding: " 9px 8px",
                    textAlign: "left",
                    borderRight: "1px solid #ddd",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "14px",
                    color: "black",
                    // margin: "7px 0px -7px 0px",
                  }}
                >
                  {equipments.industrialProcess
                    ? equipments.industrialProcess.industryProcess
                    : "N/A"}
                </Typography>
                <Typography
                  sx={{
                    //   padding: " 1px 6px",
                    padding: " 9px 8px",
                    textAlign: "left",
                    borderRight: "1px solid #ddd",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "14px",
                    color: "black",
                    // margin: "7px 0px -7px 0px",
                  }}
                >
                  {equipments.equipmentType
                    ? equipments.equipmentType.name
                    : "N/A"}
                </Typography>
                <Typography
                  sx={{
                    //   padding: " 1px 6px",
                    padding: " 9px 8px",
                    textAlign: "left",
                    borderRight: "1px solid #ddd",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "14px",
                    color: "black",
                    // margin: "7px 0px -7px 0px",
                  }}
                >
                  {`${equipments.quantityOfNaturalGasConsumed.value || ""} ${
                    equipments.quantityOfNaturalGasConsumed.unit || ""
                  }`}
                </Typography>

                <Box
                  sx={{
                    padding: "1px",
                    textAlign: "center",
                  }}
                >
                  <IconButton onClick={(e) => handleMenuClick(e, equipments)}>
                    <MoreHorizIcon
                      sx={{ fontWeight: "bold", color: "black" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </TableContainer>

      {/* </TableContainer> */}

      {/* Pagination */}
      {totalPages > 1 && (
        <PaginationBox
          {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
        />
      )}
      {/* Menu for Actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>

        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default NaturalGasEquipment;
