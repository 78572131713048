import { Typography } from '@mui/material';
import React from 'react';

const TableContent = ({ fieldData, dateField }) => {

    return (
    <Typography
      sx={{
        // padding: "1px 6px",
        textAlign: "left",
        borderRight: "1px solid #ddd",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "14px",
        color: "black",
        // margin: "7px 0px -7px 0px",
        padding: " 9px 8px",
 
      }}
    >
      {dateField ? (
        fieldData ? (
          new Date(fieldData).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })
        ) : (
          "N/A" // Placeholder for null/undefined dates
        )
      ) : (
        typeof fieldData === 'object' && fieldData !== null && fieldData.name
          ? fieldData.name // Show the name field
          : fieldData || "N/A" // Show the fieldData or "N/A" if it's falsy
      )}
    </Typography>
  );
};

export default TableContent;
