import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import axios from "axios";
import "../Scrollbar.css";

const FacilitySelectionFilter = ({
  selectedFacilities = [],
  setSelectedFacilities,
  url,
  errors,
}) => {
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    // Fetch all facilities from the backend
    const fetchFacilities = async () => {
      try {
        const response = await axios.get(url);
        // Extract unique facility names from the user data
        const uniqueFacilities = response.data.data
          ? [
              ...new Set(
                response.data.data.map((user) => user.facility.facilityName)
              ),
            ]
          : [
              ...new Set(
                response.data.data.map((user) => user.facility.facilityName)
              ),
            ];
        setFacilities(uniqueFacilities);
      } catch (error) {
        console.error("Error fetching facilities:", error);
      }
    };

    fetchFacilities();
  }, []);

  const handleFacilityChange = (event) => {
    const selectedFacility = event.target.value;
    if (event.target.checked) {
      // Add facility to selected list
      setSelectedFacilities((prevSelected) => [
        ...prevSelected,
        selectedFacility,
      ]);
    } else {
      // Remove facility from selected list
      setSelectedFacilities((prevSelected) =>
        prevSelected.filter((facility) => facility !== selectedFacility)
      );
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", ml: 2, height: "90%" }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: "bold",
          marginBottom: "30px",
        }}
      >
        Facility
      </Typography>
      <Box className="scrollable-container">
        {facilities.length > 0 ? (
          facilities.map((facility, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={facility}
                  checked={selectedFacilities?.includes(facility)}
                  onChange={handleFacilityChange}
                />
              }
              label={facility}
            />
          ))
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default FacilitySelectionFilter;
