

// import React, {useState, useEffect} from 'react';
// import Sidebar from  '../sidebar';
// import { useNavigate } from 'react-router-dom';
// import {
//   Box,
//   Button,
//   InputAdornment,
//   TextField,
//   Typography,
//   IconButton,
// } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import EastIcon from '@mui/icons-material/East';
// import AddIcon from '@mui/icons-material/Add';
// import { ArrowBack, ArrowForward } from '@mui/icons-material';
// import { companyId } from "../../../utils/companyId";
// import { api } from "../../../utils/api";

// const DevelopmentAndIncusion= () => {
//   const navigate = useNavigate();

//   const [womenInWorkforce, setWomenInWorkforce] = useState('');
//   const [womenInManagement, setWomenInManagement] = useState('');
//   const [womenInBoard, setWomenInBoard] = useState('');
//   const [minorityEmployees, setMinorityEmployees] = useState('');
//   const [malePayGap, setMalePayGap] = useState('');
//   const [femalePayGap, setFemalePayGap] = useState('');
//   const [minorityPayGap, setMinorityPayGap] = useState('');
//   const [majorityPayGap, setMajorityPayGap] = useState('');
//   const [initiative1, setInitiative1] = useState('');
//   const [effectiveness1, setEffectiveness1] = useState('');
//   const [fields, setFields] = useState([{ initiative: '', effectiveness: '' }]);
  
//   // const [initiative2, setInitiative2] = useState('');
//   // const [effectiveness2, setEffectiveness2] = useState('');
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [isExist, setIsExist] = useState(false);
 

//   useEffect(() => {
//     if (companyId) {
//       fetchData();
//     }
//   }, [companyId]);

//   const fetchData = async () => {
//     try {
//       const response = await fetch(`${api}/social/diversity_inclusion/${companyId}`);
//       if (response.ok) {
//         const data = await response.json();
//         console.log('Fetched data:', data); // Debug: Check the structure of data

//         setWomenInWorkforce(data.genderDiversity?.womenInWorkforce?.value || '');
//         setWomenInManagement(data.genderDiversity?.womenInManagement?.value || '');
//         setWomenInBoard(data.genderDiversity?.womenInBoard?.value || '');
//         setMinorityEmployees(data.representationOfMinorities?.minorityEmployees?.value || '');
//         setMalePayGap(data.payEquity?.malePayGap || '');
//         setFemalePayGap(data.payEquity?.femalePayGap || '');
//         setMinorityPayGap(data.payEquity?.minorityPayGap || '');
//         setMajorityPayGap(data.payEquity?.majorityPayGap || '');

//         // Check if initiativesForDiversityAndInclusion is part of data
//         setFields(data.initiativesForDiversityAndInclusion || [{ initiative: '', effectiveness: '' }]);

//         setIsUpdating(true);
//         setIsExist(true);
//       } else {
//         console.error("Failed to fetch data");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };


//   const Save = async () => {
//     const payload = {
//       genderDiversity: {
//         womenInWorkforce: { value: womenInWorkforce, unit: "%" },
//         womenInManagement: { value: womenInManagement, unit: "%" },
//         womenInBoard: { value: womenInBoard, unit: "%" }
//       },
//       representationOfMinorities: {
//         minorityEmployees: { value: minorityEmployees, unit: "%" }
//       },
//       payEquity: {
//         malePayGap: malePayGap,
//         femalePayGap: femalePayGap,
//         minorityPayGap: minorityPayGap,
//         majorityPayGap: majorityPayGap
//       },
//       initiativesForDiversityAndInclusion: fields, // Ensure `fields` is correctly formatted
//       companyId: companyId
//     };
  
//     // Determine the request method and URL based on isExist
//     const method = isExist ? 'PUT' : 'POST';
//     const url = isExist
//       ? `${api}/social/diversity_inclusion/${companyId}`
//       : `${api}/social/diversity_inclusion`;
  
//     try {
//       const response = await fetch(url, {
//         method: method,
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(payload),
//       });
  
//       if (response.ok) {
//         const responseData = await response.json();
//         const successMessage = isExist ? 'Data updated successfully' : 'Data saved successfully';
//         alert(successMessage);
//         console.log(successMessage, responseData);
//         navigate("/social/community");
//       } else {
//         const errorText = await response.text();
//         const errorMessage = isExist ? 'Failed to update data' : 'Failed to save data';
//         alert(errorMessage);
//         console.error(errorMessage, errorText);
//       }
//     } catch (error) {
//       console.error('Error saving data:', error);
//       const alertMessage = isExist ? 'An error occurred while updating data' : 'An error occurred while saving data';
//       alert(alertMessage);
//     }
//   };
  
  
  
//   const Back = () => {
//     navigate("/social/employee-development");
//   };

  

//   const handleAddClick = () => {
//     setFields([...fields, { initiative: '', effectiveness: '' }]);
//   };


//   const handleFieldChange = (index, field, value) => {
//     const newFields = [...fields];
//     newFields[index][field] = value;
//     setFields(newFields);
//   };


//   return (

// <Box display="flex" width="100%" bgcolor="rgb(214, 215, 216)" sx={{ height: '100%'}}>
// <Sidebar title="Diversity and inclusion" />
// <Box width="100%" ml={1} mt={6} position="relative">
//   <Typography variant="h5" gutterBottom sx={{ marginTop: "-20px", marginBottom: "20px", fontWeight: 'bold' }}>
//     SOCIAL: DIVERSITY AND INCLUSION
//   </Typography>

//   <Box>
//     <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold' }}>
//       Gender Diversity
//     </Typography>

//     <TextField
//       fullWidth
//       placeholder="% of women in workforce"
//       value={womenInWorkforce}
//       onChange={(e) => setWomenInWorkforce(e.target.value)}
//       InputProps={{
//         endAdornment: <InputAdornment position="end">
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//           %
//         </Box>
//       </InputAdornment>,
//         className: 'inputfield',
//       }}
//       sx={{ 
//         mb: 2, 
//         width: '100%',
//         '& .MuiInputBase-input': {
//           height: '40px', // Ensure input text height matches
//           padding: '0 14px', // Adjust padding if necessary
//         },
//       }}
//     />

//     <TextField
//       fullWidth
//       placeholder="% of women in management"
//       value={womenInManagement}
//       onChange={(e) => setWomenInManagement(e.target.value)}
//       InputProps={{
//         endAdornment: <InputAdornment position="end">
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//           %
//         </Box>
//       </InputAdornment>,
//         className: 'inputfield',
//       }}
//       sx={{ 
//         mb: 2, 
//         width: '100%',
//         '& .MuiInputBase-input': {
//           height: '40px', // Ensure input text height matches
//           padding: '0 14px', // Adjust padding if necessary
//         },
//       }}
//     />

//     <TextField
//       fullWidth
//       placeholder="% of women in board"
//       value={womenInBoard}
//       onChange={(e) => setWomenInBoard(e.target.value)}
//       InputProps={{
//         endAdornment: <InputAdornment position="end">
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//           %
//         </Box>
//       </InputAdornment>,
//         className: 'inputfield',
//       }}
//       sx={{ 
//         mb: 2, 
//         width: '100%',
//         '& .MuiInputBase-input': {
//           height: '40px', // Ensure input text height matches
//           padding: '0 14px', // Adjust padding if necessary
//         },
//       }}
//     />

//     <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '10px'  }}>
//       Representation of Minorities
//     </Typography>

//     <TextField
//       fullWidth
//       placeholder="% of minority employees"
//       value={minorityEmployees}
//       onChange={(e) => setMinorityEmployees(e.target.value)}
//       InputProps={{
//         endAdornment: <InputAdornment position="end">
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//           %
//         </Box>
//       </InputAdornment>,
//         className: 'inputfield',
//       }}
//       sx={{ 
//         mb: 2, 
//         width: '100%',
//         '& .MuiInputBase-input': {
//           height: '40px', // Ensure input text height matches
//           padding: '0 14px', 
//         },
//       }}
//     />

//     <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
//       Pay Equity
//     </Typography>

//     <Box display="flex" gap={2} mb={2} sx={{ 
//         width: '100%',
//         '& .MuiInputBase-input': {
//           height: '44px',
//           padding: '0 14px', 
//         },
//       }}>
//       <TextField
//         fullWidth
//         placeholder="Male pay gap"
//         value={malePayGap}
//         onChange={(e) => setMalePayGap(e.target.value)}
//         InputProps={{ className: 'inputfieldEquity' }}
//       /> <Typography variant="body1" fontWeight="bold" sx={{margin:'1%'}}>
//       :
//     </Typography>
//       <TextField
//         fullWidth
//         placeholder="Female pay gap"
//         value={femalePayGap}
//         onChange={(e) => setFemalePayGap(e.target.value)}
//         InputProps={{ className: 'inputfieldEquity' }}
//       />
//     </Box>

//     <Box display="flex" gap={2} mb={2} sx={{ 
//         width: '100%',
//         '& .MuiInputBase-input': {
//           height: '44px',
//           padding: '0 14px', 
//         },
//       }}>
//       <TextField
//         fullWidth
//         placeholder="Minority pay gap"
//         value={minorityPayGap}
//         onChange={(e) => setMinorityPayGap(e.target.value)}
//         InputProps={{ className: 'inputfieldEquity' }}
//       /> <Typography variant="body1" fontWeight="bold" sx={{margin:'1%'}}>
//       :
//     </Typography>
//       <TextField
//         fullWidth
//         placeholder="Majority pay gap"
//         value={majorityPayGap}
//         onChange={(e) => setMajorityPayGap(e.target.value)}
//         InputProps={{ className: 'inputfieldEquity' }}
//       />
//     </Box>


// <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '30px' }}>
//           Initiatives for Diversity and Inclusion
//         </Typography>

//         {fields.map((field, index) => (
//           <Box display="flex" gap={2} mb={2} sx={{ width: '100%' }} key={index}>
//             <TextField
//               fullWidth
//               multiline
//               rows={3}
//               placeholder="Initiative"
//               value={field.initiative}
//               onChange={(e) => handleFieldChange(index, 'initiative', e.target.value)}
//               InputProps={{ className: 'brief' }}
//             />
//             <TextField
//               fullWidth
//               multiline
//               rows={3}
//               placeholder="Effectiveness"
//               value={field.effectiveness}
//               onChange={(e) => handleFieldChange(index, 'effectiveness', e.target.value)}
//               InputProps={{ className: 'brief' }}
//             />
//           </Box>
//         ))}

//         <Button
//           variant="outlined"
//           startIcon={<AddIcon />}
//           onClick={handleAddClick}
//           sx={{
//             backgroundColor: 'transparent',
//             color: '#204769',
//             borderColor: '#204769',
//             borderRadius: '4px',
//             padding: '6px 16px',
//             fontSize: '0.875rem',
//             fontWeight: 500,
//             lineHeight: 1.75,
//             letterSpacing: '0.02857em',
//             textTransform: 'uppercase',
//             margin: '2px 0px 0px 590px',
//             '&:hover': {
//               backgroundColor: 'rgba(25, 118, 210, 0.04)',
//               color: '#115293',
//               borderColor: '#115293',
//             },
//             display: 'inline-flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             transition: 'background-color 0.3s, color 0.3s',
//           }}
//         >
//           Add
//         </Button>

//     <Box display="flex" justifyContent="space-between" sx={{ margin: '70px 10px 25px 0px' }}>
//           <Button
//             variant="outlined"
//             startIcon={<ArrowBackIcon />}
//             onClick={Back}
//             sx={{
//               color: '#204769',
//               borderColor: '#204769',
//               '&:hover': {
//                 backgroundColor: 'rgba(25, 118, 210, 0.04)',
//                 color: '#115293',
//                 borderColor: '#115293',
//               },
//             }}
//           >
//             Back
//           </Button>
//           <Button
//             variant="contained"
//             endIcon={<EastIcon />}
//             onClick={Save}
//             sx={{
//               backgroundColor: '#1C486B',
//               '&:hover': {
//                 backgroundColor: '#163B57',
//               },
//             }}
//           >
//             Save and Continue
//           </Button>
//         </Box>
//   </Box>
// </Box>
// </Box>




// );
// };


// export default DevelopmentAndIncusion;





// import React, { useState, useEffect } from 'react';
// import Sidebar from '../sidebar';
// import { useNavigate } from 'react-router-dom';
// import {
//   Box,
//   Button,
//   InputAdornment,
//   TextField,
//   Typography,
//   IconButton,
// } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
// import EastIcon from '@mui/icons-material/East';
// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { companyId } from "../../../utils/companyId";
// import { api } from "../../../utils/api";
// import { percentageError, twoFieldError } from "../../../utils/validationMessage";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";

// const DevelopmentAndIncusion = () => {
//   const navigate = useNavigate();

//   const [womenInWorkforce, setWomenInWorkforce] = useState('');
//   const [womenInManagement, setWomenInManagement] = useState('');
//   const [womenInBoard, setWomenInBoard] = useState('');
//   const [minorityEmployees, setMinorityEmployees] = useState('');
//   const [malePayGap, setMalePayGap] = useState('');
//   const [femalePayGap, setFemalePayGap] = useState('');
//   const [minorityPayGap, setMinorityPayGap] = useState('');
//   const [majorityPayGap, setMajorityPayGap] = useState('');
//   const [fields, setFields] = useState([{ initiative: '', effectiveness: '' }]);
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [isExist, setIsExist] = useState(false);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [deleteIndex, setDeleteIndex] = useState(null);

//   // Validation states
//   const [errors, setErrors] = useState({
//     womenInWorkforce: '',
//     womenInManagement: '',
//     womenInBoard: '',
//     minorityEmployees: '',
//     malePayGap: '',
//     femalePayGap: '',
//     minorityPayGap: '',
//     majorityPayGap: '',
//     initiatives: ''
//   });

//   console.log("Stored companyId:", companyId); 

//   useEffect(() => {
//     if (companyId) {
//       fetchData();
//     }
//   }, [companyId]);

//   const fetchData = async () => {
//     try {
//       const response = await fetch(`${api}/social/diversity_inclusion/${companyId}`);
//       if (response.ok) {
//         const data = await response.json();
//         console.log('Fetched data:', data); // Debug: Check the structure of data

//         // setWomenInWorkforce(data.genderDiversity?.womenInWorkforce?.value || '');
//         setWomenInWorkforce(data.genderDiversity?.womenInWorkforce?.value ?? '');
//         setWomenInManagement(data.genderDiversity?.womenInManagement?.value ?? '');
//         setWomenInBoard(data.genderDiversity?.womenInBoard?.value ?? '');
//         setMinorityEmployees(data.representationOfMinorities?.minorityEmployees?.value ?? '');
//         setMalePayGap(data.payEquity?.malePayGap ?? '');
//         setFemalePayGap(data.payEquity?.femalePayGap ?? '');
//         setMinorityPayGap(data.payEquity?.minorityPayGap ?? '');
//         setMajorityPayGap(data.payEquity?.majorityPayGap ?? '');
//         setFields(data.initiativesForDiversityAndInclusion || [{ initiative: '', effectiveness: '' }]);

//         setIsUpdating(true);
//         setIsExist(true);
//       } else {
//         console.error("Failed to fetch data");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };


   
  
  


 

//   const validate = () => {
//     const newErrors = {};
//     let isValid = true;

//     // Validate percentages
//     if (womenInWorkforce === '' || isNaN(womenInWorkforce) || womenInWorkforce < 0 || womenInWorkforce > 100) {
//       newErrors.womenInWorkforce = percentageError;
//       isValid = false;
//     }
//     if (womenInManagement === '' || isNaN(womenInManagement) || womenInManagement < 0 || womenInManagement > 100) {
//       newErrors.womenInManagement = percentageError;
//       isValid = false;
//     }
//     if (womenInBoard === '' || isNaN(womenInBoard) || womenInBoard < 0 || womenInBoard > 100) {
//       newErrors.womenInBoard = percentageError;
//       isValid = false;
//     }
//     if (minorityEmployees === '' || isNaN(minorityEmployees) || minorityEmployees < 0 || minorityEmployees > 100) {
//       newErrors.minorityEmployees = percentageError;
//       isValid = false;
//     }

//     // Validate pay equity fields
//     if (malePayGap === '' || isNaN(malePayGap) || malePayGap < 0 || malePayGap > 100) {
//       newErrors.malePayGap = percentageError;
//       isValid = false;
//     }
//     if (femalePayGap === '' || isNaN(femalePayGap) || femalePayGap < 0 || femalePayGap > 100) {
//       newErrors.femalePayGap = percentageError;
//       isValid = false;
//     }
//     if (minorityPayGap === '' || isNaN(minorityPayGap) || minorityPayGap < 0 || minorityPayGap > 100) {
//       newErrors.minorityPayGap = percentageError;
//       isValid = false;
//     }
//     if (majorityPayGap === '' || isNaN(majorityPayGap) || majorityPayGap < 0 || majorityPayGap > 100) {
//       newErrors.majorityPayGap = percentageError;
//       isValid = false;
//     }

//     // Validate Initiatives
//     const initiativeErrors = [];
//     fields.forEach((field, index) => {
//       if (field.initiative.trim() === '' || field.effectiveness.trim() === '') {
//         initiativeErrors[index] = twoFieldError;
//         isValid = false;
//       }
//     });
//     if (initiativeErrors.length > 0) {
//       newErrors.initiatives = initiativeErrors;
//     }

//     setErrors(newErrors);
//     return isValid;
//   };

//   const Save = async () => {
//     if (!validate()) return; // Validate before saving

//     const data = {
//       genderDiversity: {
//         womenInWorkforce: { value: womenInWorkforce, unit: "%" },
//         womenInManagement: { value: womenInManagement, unit: "%" },
//         womenInBoard: { value: womenInBoard, unit: "%" }
//       },
//       representationOfMinorities: {
//         minorityEmployees: { value: minorityEmployees, unit: "%" }
//       },
//       payEquity: {
//         malePayGap,
//         femalePayGap,
//         minorityPayGap,
//         majorityPayGap
//       },
//       initiativesForDiversityAndInclusion: fields
//     };

//     try {
//       const response = isUpdating
//         ? await fetch(`${api}/social/diversity_inclusion/${companyId}`, {
//             method: 'PUT',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(data),
//           })
//         : await fetch(`${api}/social/diversity_inclusion`, {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ ...data, company_id: companyId }),
//           });

//       if (response.ok) {
//         navigate('/social/community'); // Redirect after successful save
//       } else {
//         console.error("Failed to save data");
//       }
//     } catch (error) {
//       console.error("Error saving data:", error);
//     }
//   };

//   const Back = () => {
//     navigate("/social/employee-development");
//   };

//   const handleAddClick = () => {
//     setFields([...fields, { initiative: '', effectiveness: '' }]);
//   };

//   const handleFieldChange = (index, field, value) => {
//     const newFields = [...fields];
//     newFields[index][field] = value;
//     setFields(newFields);
//   };

//   // const handleDeleteClick = (index) => {
//   //   const newFields = fields.filter((_, i) => i !== index);
//   //   setFields(newFields);
//   // };

//   const handleDeleteClick = (index) => {
//     setDeleteIndex(index);
//     setDialogOpen(true); // Open the dialog instead of deleting immediately
//   };

//   const confirmDelete = () => {
//     const newFields = fields.filter((_, i) => i !== deleteIndex);
//     setFields(newFields);
//     setDialogOpen(false);
//     setDeleteIndex(null);
//   };

//   const cancelDelete = () => {
//     setDialogOpen(false);
//     setDeleteIndex(null);
//   };


//   return (
//     <Box>
     
//        <Dialog open={dialogOpen} onClose={cancelDelete}>
//         <DialogTitle>Confirm Delete</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Are you sure you want to delete this field?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={cancelDelete} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={confirmDelete} color="secondary">
//             Confirm
//           </Button>
//         </DialogActions>
//       </Dialog>
    
//     <Box sx={{
//       display: "flex",
//       backgroundColor: "#EDEDED",
//       height: "100vh",
//       overflow: "hidden" // Ensure no content gets hidden by scrollbars
//     }}>
//        <Sidebar title="Diversity and inclusion" />
//       <Box sx={{
//         p: 2,
//         mt: 3,
//         width: "100%",
//         height: "100vh", // Full viewport height
//         overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "flex-start", // Adjust to start at the top of the box
//       }}>
//         <Typography variant="h5" sx={{
//           fontWeight: "bold",
//           zIndex: 1,
//           padding: "10px",
//           borderRadius: "4px",
//           alignSelf: "flex-start",
//           ml: "5rem"
//         }}>
//          SOCIAL: DIVERSITY AND INCLUSION
//         </Typography>

//         <Box sx={{ marginTop: '20px', width: '80%' }}>
//         <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold' }}>
//             Gender Diversity
//           </Typography>

//           <TextField
//             fullWidth
//             placeholder="% of women in workforce"
//             value={womenInWorkforce}
//             onChange={(e) => setWomenInWorkforce(e.target.value)}
//             error={!!errors.womenInWorkforce}
//             helperText={errors.womenInWorkforce}
//             InputProps={{
//               endAdornment: <InputAdornment position="end">
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//                 %
//               </Box>
//             </InputAdornment>,
//                className: 'inputfield',
//             }}
//             sx={{ 
//               mb: 2, 
//               width: '100%',
//               '& .MuiInputBase-input': {
//                 height: '47px', 
//                 padding: '0 14px',
//               },
//               '& .MuiFormHelperText-root': {
//               color: '#D34040',  // Change the error message color
//               fontSize: '14px', 
//               marginLeft: '0px'
//               },
//             }}
//           />

//           <TextField
//             fullWidth
//             placeholder="% of women in management"
//             value={womenInManagement}
//             onChange={(e) => setWomenInManagement(e.target.value)}
//             error={!!errors.womenInManagement}
//             helperText={errors.womenInManagement}
//             InputProps={{
//               endAdornment: <InputAdornment position="end">
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//                 %
//               </Box>
//             </InputAdornment>,
//                className: 'inputfield',
//             }}
//             sx={{ 
//               mb: 2, 
//               width: '100%',
//               '& .MuiInputBase-input': {
//                 height: '47px', 
//                 padding: '0 14px',
//               },
//               '& .MuiFormHelperText-root': {
//                 color: '#D34040',  
//                 fontSize: '14px', 
//                 marginLeft: '0px'
//                 },
//             }}
//           />

//           <TextField
//             fullWidth
//             placeholder="% of women in board"
//             value={womenInBoard}
//             onChange={(e) => setWomenInBoard(e.target.value)}
//             error={!!errors.womenInBoard}
//             helperText={errors.womenInBoard}
//             InputProps={{
//               endAdornment: <InputAdornment position="end">
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//                 %
//               </Box>
//             </InputAdornment>,
//                className: 'inputfield',
//             }}
//             sx={{ 
//               mb: 2, 
//               width: '100%',
//               '& .MuiInputBase-input': {
//                 height: '47px', 
//                 padding: '0 14px',
//               },
//               '& .MuiFormHelperText-root': {
//                 color: '#D34040',  // Change the error message color
//                 fontSize: '14px', 
//                 marginLeft: '0px'
//                 },
//             }}
//           />

//           <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '10px'  }}>
//             Representation of Minorities
//           </Typography>

//           <TextField
//             fullWidth
//             placeholder="% of minority employees"
//             value={minorityEmployees}
//             onChange={(e) => setMinorityEmployees(e.target.value)}
//             error={!!errors.minorityEmployees}
//             helperText={errors.minorityEmployees}
//             InputProps={{
//               endAdornment: <InputAdornment position="end">
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//                 %
//               </Box>
//             </InputAdornment>,
//                className: 'inputfield',
//             }}
//             sx={{ 
//               mb: 2, 
//               width: '100%',
//               '& .MuiInputBase-input': {
//                 height: '47px', 
//                 padding: '0 14px',
//               },
//               '& .MuiFormHelperText-root': {
//                 color: '#D34040',  // Change the error message color
//                 fontSize: '14px', 
//                 marginLeft: '0px'
//                 },
//             }}
//           />

//         <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
//             Pay Equity
//           </Typography>

          
//         <Box display="flex" gap={2} mb={2} sx={{ 
//           width: '100%',
//           '& .MuiInputBase-input': {
//           height: '47px',
//           padding: '0 14px', 
//          },
//          '& .MuiFormHelperText-root': {
//           color: '#D34040',  // Change the error message color
//           fontSize: '14px', 
//           marginLeft: '0px'
//           },
//         }}>
//           <TextField
//             fullWidth
//             placeholder="Male Pay Gap"
//             value={malePayGap}
//             onChange={(e) => setMalePayGap(e.target.value)}
//             error={!!errors.malePayGap}
//             helperText={errors.malePayGap}
//           />
//           <b style={{marginTop: '8px'}}>:</b>

//           <TextField
//             fullWidth
//             placeholder="Female Pay Gap"
//             value={femalePayGap}
//             onChange={(e) => setFemalePayGap(e.target.value)}
//             error={!!errors.femalePayGap}
//             helperText={errors.femalePayGap}
//           />
//           </Box>
//           <Box display="flex" gap={2} mb={2} sx={{ 
//             width: '100%',
//             '& .MuiInputBase-input': {
//             height: '47px',
//             padding: '0 14px', 
//           },
//           '& .MuiFormHelperText-root': {
//             color: '#D34040',  // Change the error message color
//             fontSize: '14px', 
//             marginLeft: '0px'
//             },
//         }}>
//           <TextField
//             fullWidth
//             placeholder="Minority Pay Gap"
//             value={minorityPayGap}
//             onChange={(e) => setMinorityPayGap(e.target.value)}
//             error={!!errors.minorityPayGap}
//             helperText={errors.minorityPayGap}
//           />
//           <b style={{marginTop: '8px'}}>:</b>

//           <TextField
//             fullWidth
//             placeholder="Majority Pay Gap"
//             value={majorityPayGap}
//             onChange={(e) => setMajorityPayGap(e.target.value)}
//             error={!!errors.majorityPayGap}
//             helperText={errors.majorityPayGap}
//           />
//           </Box>

//           <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '30px' }}>
//             Initiatives for Diversity and Inclusion
//           </Typography>

//           {fields.map((field, index) => (
//               <Box
//               key={index}
//               sx={{ width: "110%", display: "flex", gap: 1 }}
//             >
//             <Box display="flex" gap={2} mb={2} sx={{ width: '91%',              
//               '& .MuiFormHelperText-root': {
//               color: '#D34040',  // Change the error message color
//               fontSize: '14px', 
//               marginLeft: '0px'
//               }, }} key={index}>
//               <TextField
//                 fullWidth
//                 placeholder="Initiative"
//                 value={field.initiative}
//                 onChange={(e) => handleFieldChange(index, 'initiative', e.target.value)}
//                 error={!!errors.initiatives && errors.initiatives[index]}
//                 helperText={errors.initiatives && errors.initiatives[index]}
//               />
//               <TextField
//                 fullWidth
//                 placeholder="Effectiveness"
//                 value={field.effectiveness}
//                 onChange={(e) => handleFieldChange(index, 'effectiveness', e.target.value)}
//                 error={!!errors.initiatives && errors.initiatives[index]}
//                 helperText={errors.initiatives && errors.initiatives[index]}
//               />
//               {/* <IconButton onClick={() => handleDeleteClick(index)} sx={{color:'#D34040'}}>
//                 <DeleteIcon />
//               </IconButton> */}
//                </Box>
//               {index > 0 && (
//                 <IconButton   sx={{border: 'none'}} onClick={() => handleDeleteClick(index)}>
//                   <DeleteIcon />
//                 </IconButton>
//               )}
             
              
//             </Box>
            
//           ))}

//         <Button
//           variant="outlined"
//           startIcon={<AddIcon />}
//           onClick={handleAddClick}
//           sx={{
//             backgroundColor: 'transparent',
//             color: '#204769',
//             borderColor: '#204769',
//             borderRadius: '4px',
//             padding: '6px 16px',
//             fontSize: '0.875rem',
//             fontWeight: 500,
//             lineHeight: 1.75,
//             letterSpacing: '0.02857em',
//             textTransform: 'uppercase',
//             margin: '2px 0px 0px 670px',
//             '&:hover': {
//               backgroundColor: 'rgba(25, 118, 210, 0.04)',
//               color: '#115293',
//               borderColor: '#115293',
//             },
//             display: 'inline-flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             transition: 'background-color 0.3s, color 0.3s',
//           }}
//         >
//           Add
//         </Button>
//         <ActionButtons Back={Back} Move={Save} />
//         </Box>
//       </Box>
//     </Box>
//     </Box>
//   );
// };

// export default DevelopmentAndIncusion;







import React, {useState, useEffect,useContext} from 'react';
import Sidebar from  '../sidebar';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import AddIcon from '@mui/icons-material/Add';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { companyId } from "../../../utils/companyId";
import { api } from "../../../utils/api";
import { percentageError, twoFieldError } from "../../../utils/validationMessage";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import DeleteIcon from '@mui/icons-material/Delete';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {ProgressContext} from "../ContextAPI";
const DevelopmentAndIncusion= () => {
  const navigate = useNavigate();
  const { setUpdate } = useContext(ProgressContext);

  const [womenInWorkforce, setWomenInWorkforce] = useState('');
  const [womenInManagement, setWomenInManagement] = useState('');
  const [womenInBoard, setWomenInBoard] = useState('');
  const [minorityEmployees, setMinorityEmployees] = useState('');
  const [malePayGap, setMalePayGap] = useState('');
  const [femalePayGap, setFemalePayGap] = useState('');
  const [minorityPayGap, setMinorityPayGap] = useState('');
  const [majorityPayGap, setMajorityPayGap] = useState('');
  const [initiative1, setInitiative1] = useState('');
  const [effectiveness1, setEffectiveness1] = useState('');
  const [fields, setFields] = useState([{ initiative: '', effectiveness: '' }]);
  
  // const [initiative2, setInitiative2] = useState('');
  // const [effectiveness2, setEffectiveness2] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  // Validation states
  const [errors, setErrors] = useState({
    womenInWorkforce: '',
    womenInManagement: '',
    womenInBoard: '',
    minorityEmployees: '',
    malePayGap: '',
    femalePayGap: '',
    minorityPayGap: '',
    majorityPayGap: '',
    initiatives: ''
  });

  console.log("Stored companyId:", companyId); 
 

  useEffect(() => {
    setUpdate("SOCIAL");
    if (companyId) {
      fetchData();
    }
  }, [companyId]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${api}/social/diversity_inclusion/${companyId}`);
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched data:', data); // Debug: Check the structure of data

        // setWomenInWorkforce(data.genderDiversity?.womenInWorkforce?.value || '');
        // setWomenInManagement(data.genderDiversity?.womenInManagement?.value || '');
        setWomenInWorkforce(data.genderDiversity?.womenInWorkforce?.value ?? '');
        setWomenInManagement(data.genderDiversity?.womenInManagement?.value ?? '');
        // setWomenInBoard(data.genderDiversity?.womenInBoard?.value || '');
        // setMinorityEmployees(data.representationOfMinorities?.minorityEmployees?.value || '');
        // setMalePayGap(data.payEquity?.malePayGap || '');
        // setFemalePayGap(data.payEquity?.femalePayGap || '');
        // setMinorityPayGap(data.payEquity?.minorityPayGap || '');
        // setMajorityPayGap(data.payEquity?.majorityPayGap || '');
        setWomenInBoard(data.genderDiversity?.womenInBoard?.value ?? '');
        setMinorityEmployees(data.representationOfMinorities?.minorityEmployees?.value ?? '');
        setMalePayGap(data.payEquity?.malePayGap ?? '');
        setFemalePayGap(data.payEquity?.femalePayGap ?? '');
        setMinorityPayGap(data.payEquity?.minorityPayGap ?? '');
        setMajorityPayGap(data.payEquity?.majorityPayGap ?? '');

        // Check if initiativesForDiversityAndInclusion is part of data
        setFields(data.initiativesForDiversityAndInclusion || [{ initiative: '', effectiveness: '' }]);

        setIsUpdating(true);
        setIsExist(true);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  
  const validate = () => {
    const newErrors = {};
    let isValid = true;

    // Validate percentages
    if (womenInWorkforce === '' || isNaN(womenInWorkforce) || womenInWorkforce < 0 || womenInWorkforce > 100) {
      newErrors.womenInWorkforce = percentageError;
      isValid = false;
    }
    if (womenInManagement === '' || isNaN(womenInManagement) || womenInManagement < 0 || womenInManagement > 100) {
      newErrors.womenInManagement = percentageError;
      isValid = false;
    }
    if (womenInBoard === '' || isNaN(womenInBoard) || womenInBoard < 0 || womenInBoard > 100) {
      newErrors.womenInBoard = percentageError;
      isValid = false;
    }
    if (minorityEmployees === '' || isNaN(minorityEmployees) || minorityEmployees < 0 || minorityEmployees > 100) {
      newErrors.minorityEmployees = percentageError;
      isValid = false;
    }

    // Validate pay equity fields
    if (malePayGap === '' || isNaN(malePayGap) || malePayGap < 0 || malePayGap > 100) {
      newErrors.malePayGap = percentageError;
      isValid = false;
    }
    if (femalePayGap === '' || isNaN(femalePayGap) || femalePayGap < 0 || femalePayGap > 100) {
      newErrors.femalePayGap = percentageError;
      isValid = false;
    }
    if (minorityPayGap === '' || isNaN(minorityPayGap) || minorityPayGap < 0 || minorityPayGap > 100) {
      newErrors.minorityPayGap = percentageError;
      isValid = false;
    }
    if (majorityPayGap === '' || isNaN(majorityPayGap) || majorityPayGap < 0 || majorityPayGap > 100) {
      newErrors.majorityPayGap = percentageError;
      isValid = false;
    }

    // Validate Initiatives
    const initiativeErrors = [];
    fields.forEach((field, index) => {
      if (field.initiative.trim() === '' || field.effectiveness.trim() === '') {
        initiativeErrors[index] = twoFieldError;
        isValid = false;
      }
    });
    if (initiativeErrors.length > 0) {
      newErrors.initiatives = initiativeErrors;
    }

    setErrors(newErrors);
    return isValid;
  };

  const Save = async () => {
    if (!validate()) return; // Validate before saving
    const payload = {
      genderDiversity: {
        womenInWorkforce: { value: womenInWorkforce, unit: "%" },
        womenInManagement: { value: womenInManagement, unit: "%" },
        womenInBoard: { value: womenInBoard, unit: "%" }
      },
      representationOfMinorities: {
        minorityEmployees: { value: minorityEmployees, unit: "%" }
      },
      payEquity: {
        malePayGap: malePayGap,
        femalePayGap: femalePayGap,
        minorityPayGap: minorityPayGap,
        majorityPayGap: majorityPayGap
      },
      initiativesForDiversityAndInclusion: fields, // Ensure `fields` is correctly formatted
      companyId: companyId
    };
  
    // Determine the request method and URL based on isExist
    const method = isExist ? 'PUT' : 'POST';
    const url = isExist
      ? `${api}/social/diversity_inclusion/${companyId}`
      : `${api}/social/diversity_inclusion`;
  
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        const successMessage = isExist ? 'Data updated successfully' : 'Data saved successfully';
        // alert(successMessage);
        console.log(successMessage, responseData);
        navigate("/social/community");
      } else {
        const errorText = await response.text();
        const errorMessage = isExist ? 'Failed to update data' : 'Failed to save data';
        // alert(errorMessage);
        console.error(errorMessage, errorText);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      const alertMessage = isExist ? 'An error occurred while updating data' : 'An error occurred while saving data';
      // alert(alertMessage);
    }
  };
  
  
  
  const Back = () => {
    navigate("/social/employee-development");
  };


  const handleAddClick = () => {
    setFields([...fields, { initiative: '', effectiveness: '' }]);
  };

  const handleFieldChange = (index, field, value) => {
    const newFields = [...fields];
    newFields[index][field] = value;
    setFields(newFields);
  };


  const handleDeleteClick = (index) => {
    setDeleteIndex(index);
    setDialogOpen(true); // Open the dialog instead of deleting immediately
  };

  const confirmDelete = () => {
    const newFields = fields.filter((_, i) => i !== deleteIndex);
    setFields(newFields);
    setDialogOpen(false);
    setDeleteIndex(null);
  };

  const cancelDelete = () => {
    setDialogOpen(false);
    setDeleteIndex(null);
  };


  return (


<Box>
     
     <Dialog open={dialogOpen} onClose={cancelDelete}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this field?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDelete} color="primary">
          Cancel
        </Button>
        <Button onClick={confirmDelete} color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  
  <Box sx={{
    display: "flex",
    backgroundColor: "#EDEDED",
    height: "100vh",
    overflow: "hidden" // Ensure no content gets hidden by scrollbars
  }}>
     <Sidebar title="Diversity and inclusion" />
    <Box sx={{
      p: 2,
      mt: 3,
      width: "100%",
      height: "100vh", // Full viewport height
      overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start", // Adjust to start at the top of the box
    }}>
      <Typography variant="h5" sx={{
        fontWeight: "bold",
        zIndex: 1,
        padding: "10px",
        borderRadius: "4px",
        alignSelf: "flex-start",
        ml: "5rem"
      }}>
       SOCIAL: DIVERSITY AND INCLUSION
      </Typography>

      <Box sx={{ marginTop: '20px', width: '80%' }}>
      <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold' }}>
          Gender Diversity
        </Typography>

        <TextField
          fullWidth
          placeholder="% of women in workforce"
          value={womenInWorkforce}
          onChange={(e) => setWomenInWorkforce(e.target.value)}
          error={!!errors.womenInWorkforce}
          helperText={errors.womenInWorkforce}
          InputProps={{
            endAdornment: <InputAdornment position="end">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
              %
            </Box>
          </InputAdornment>,
             className: 'inputfield',
          }}
          sx={{ 
            mb: 2, 
            width: '100%',
            '& .MuiInputBase-input': {
              height: '47px', 
              padding: '0 14px',
            },
            '& .MuiFormHelperText-root': {
            color: '#D34040',  // Change the error message color
            fontSize: '14px', 
            marginLeft: '0px'
            },
          }}
        />

        <TextField
          fullWidth
          placeholder="% of women in management"
          value={womenInManagement}
          onChange={(e) => setWomenInManagement(e.target.value)}
          error={!!errors.womenInManagement}
          helperText={errors.womenInManagement}
          InputProps={{
            endAdornment: <InputAdornment position="end">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
              %
            </Box>
          </InputAdornment>,
             className: 'inputfield',
          }}
          sx={{ 
            mb: 2, 
            width: '100%',
            '& .MuiInputBase-input': {
              height: '47px', 
              padding: '0 14px',
            },
            '& .MuiFormHelperText-root': {
              color: '#D34040',  
              fontSize: '14px', 
              marginLeft: '0px'
              },
          }}
        />

        <TextField
          fullWidth
          placeholder="% of women in board"
          value={womenInBoard}
          onChange={(e) => setWomenInBoard(e.target.value)}
          error={!!errors.womenInBoard}
          helperText={errors.womenInBoard}
          InputProps={{
            endAdornment: <InputAdornment position="end">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
              %
            </Box>
          </InputAdornment>,
             className: 'inputfield',
          }}
          sx={{ 
            mb: 2, 
            width: '100%',
            '& .MuiInputBase-input': {
              height: '47px', 
              padding: '0 14px',
            },
            '& .MuiFormHelperText-root': {
              color: '#D34040',  // Change the error message color
              fontSize: '14px', 
              marginLeft: '0px'
              },
          }}
        />

        <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '10px'  }}>
          Representation of Minorities
        </Typography>

        <TextField
          fullWidth
          placeholder="% of minority employees"
          value={minorityEmployees}
          onChange={(e) => setMinorityEmployees(e.target.value)}
          error={!!errors.minorityEmployees}
          helperText={errors.minorityEmployees}
          InputProps={{
            endAdornment: <InputAdornment position="end">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
              %
            </Box>
          </InputAdornment>,
             className: 'inputfield',
          }}
          sx={{ 
            mb: 2, 
            width: '100%',
            '& .MuiInputBase-input': {
              height: '47px', 
              padding: '0 14px',
            },
            '& .MuiFormHelperText-root': {
              color: '#D34040',  // Change the error message color
              fontSize: '14px', 
              marginLeft: '0px'
              },
          }}
        />

      <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
          Pay Equity
        </Typography>

        
      <Box display="flex" gap={2} mb={2} sx={{ 
        width: '100%',
        '& .MuiInputBase-input': {
        height: '47px',
        padding: '0 14px', 
       },
       '& .MuiFormHelperText-root': {
        color: '#D34040',  // Change the error message color
        fontSize: '14px', 
        marginLeft: '0px'
        },
      }}>
        <TextField
          fullWidth
          placeholder="Male Pay Gap"
          value={malePayGap}
          onChange={(e) => setMalePayGap(e.target.value)}
          error={!!errors.malePayGap}
          helperText={errors.malePayGap}
        />
        <b style={{marginTop: '8px'}}>:</b>

        <TextField
          fullWidth
          placeholder="Female Pay Gap"
          value={femalePayGap}
          onChange={(e) => setFemalePayGap(e.target.value)}
          error={!!errors.femalePayGap}
          helperText={errors.femalePayGap}
        />
        </Box>
        <Box display="flex" gap={2} mb={2} sx={{ 
          width: '100%',
          '& .MuiInputBase-input': {
          height: '47px',
          padding: '0 14px', 
        },
        '& .MuiFormHelperText-root': {
          color: '#D34040',  // Change the error message color
          fontSize: '14px', 
          marginLeft: '0px'
          },
      }}>
        <TextField
          fullWidth
          placeholder="Minority Pay Gap"
          value={minorityPayGap}
          onChange={(e) => setMinorityPayGap(e.target.value)}
          error={!!errors.minorityPayGap}
          helperText={errors.minorityPayGap}
        />
        <b style={{marginTop: '8px'}}>:</b>

        <TextField
          fullWidth
          placeholder="Majority Pay Gap"
          value={majorityPayGap}
          onChange={(e) => setMajorityPayGap(e.target.value)}
          error={!!errors.majorityPayGap}
          helperText={errors.majorityPayGap}
        />
        </Box>

        <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '30px' }}>
          Initiatives for Diversity and Inclusion
        </Typography>

        {fields.map((field, index) => (
            <Box
            key={index}
            sx={{ width: "110%", display: "flex", gap: 1 }}
          >
          <Box display="flex" gap={2} mb={2} sx={{ width: '91%',              
            '& .MuiFormHelperText-root': {
            color: '#D34040',  // Change the error message color
            fontSize: '14px', 
            marginLeft: '0px'
            }, }} key={index}>
            <TextField
              fullWidth
              placeholder="Initiative"
              value={field.initiative}
              onChange={(e) => handleFieldChange(index, 'initiative', e.target.value)}
              error={!!errors.initiatives && errors.initiatives[index]}
              helperText={errors.initiatives && errors.initiatives[index]}
            />
            <TextField
              fullWidth
              placeholder="Effectiveness"
              value={field.effectiveness}
              onChange={(e) => handleFieldChange(index, 'effectiveness', e.target.value)}
              error={!!errors.initiatives && errors.initiatives[index]}
              helperText={errors.initiatives && errors.initiatives[index]}
            />
            {/* <IconButton onClick={() => handleDeleteClick(index)} sx={{color:'#D34040'}}>
              <DeleteIcon />
            </IconButton> */}
             </Box>
            {index > 0 && (
              <IconButton   sx={{border: 'none'}} onClick={() => handleDeleteClick(index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        ))}

     
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
        sx={{
          alignSelf: "flex-end",
          color: "#74757E",
          borderColor: "#74757E",
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
          "&:hover": {
            backgroundColor: "rgba(25, 118, 210, 0.04)",
            color: "#115293",
            borderColor: "#115293",
          },
        }}
      >
        Add
      </Button>
    </Box>
      <ActionButtons Back={Back} Move={Save} />
      </Box>
    </Box>
  </Box>
  </Box>

);
};


export default DevelopmentAndIncusion;