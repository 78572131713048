import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import contries from "../../Roles and Permissions/Country.json";
import { api } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import {textError,numberError,listError} from "../../../../utils/validationMessage";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from '@mui/material/LinearProgress';




function AddVendor(props) {
  const [alreadyVendor, setAlreadyVendor] = useState(false);
  const [errors, setErrors] = useState({});
const initialState ={
    company: companyId,
    VendorLegalFullName:"",
   
  };
  const resetForm = () => {
    console.log("_idddddd",props.selectedVendor?._id)
    setFromValue(props.selectedVendor?._id === undefined?initialState:props.selectedVendor);
    setErrors({});
  };

  const [fromValue, setFromValue] = useState(initialState);
  console.log("propsEquipment",props)
  // useEffect(()=>{
  //   axios.get(`${api}/master/getAllFacility`).then((res)=>{console.log("resForPermission",res.data.data)
  //     setmoduleName(res.data.data)
  //   }).catch((err)=>{console.log("err",err)})
  // },[])


  useEffect(() => {
    setFromValue({
      company: companyId,
      VendorLegalFullName:props.selectedVendor?.VendorLegalFullName ??"",
  
    });
    setAlreadyVendor(props.selectedVendor === null ? false : true);
  }, [props.selectedVendor]);

 
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("nam===e", name, value);
    setFromValue({
      ...fromValue,
      [name]: value,
    });
    setErrors({...errors,[name]:""});
  };
  const validateForm = () => {
    const newErrors = {};

    const {
      VendorLegalFullName,
     
    } = fromValue;

    
    if (!VendorLegalFullName || VendorLegalFullName.trim() === "") {
      newErrors.VendorLegalFullName = textError;
    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  const Save = () => {
    if (!validateForm()) {
      return;
    }
    console.log(fromValue);

    axios({
      method: alreadyVendor ? "put" : "post",
      url: alreadyVendor
        ? `${api}/master/Steam/updateVendor`
        : `${api}/master/Steam/creatVendor`,
      data: fromValue, // Send data only if it's a POST request
      params: alreadyVendor ? { Id: props.selectedVendor?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        console.log(res, res.data.data);
        props.setAddVendor(false);
        props.fetchSteamVendor();
        resetForm();
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        setFromValue((pre)=>{
          return{
            ...pre
          }
        })
        //  localStorage.setItem("roleId",res.data.data._id)
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          // If there is a message in the error response, use it
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{width:400,
        // maxWidth:"100%",
        height: '100vh',
        display:"flex",justifyContent:"space-btween",
          backgroundColor:"transparent"
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {props.setAddVendor(false);resetForm();}}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll"}}>
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          {props.selectedVendor?._id === undefined?
          " Add Vendor"
             : "Edit Vendor"}
        </Typography>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        Vendor Legal Full Name
        </Typography>

        <CustomTextField
          //   select
          variant="outlined"
          value={fromValue.VendorLegalFullName}
          onChange={handleChange}
          fullWidth
          name="VendorLegalFullName"
          placeholder="Vendor Legal Full Name"
          error={!!errors.VendorLegalFullName} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.VendorLegalFullName ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.VendorLegalFullName && (
          <Typography variant="body2" color="#D34040">
            {errors.VendorLegalFullName}
          </Typography>
        )}
         
        <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    minHeight: '70%' // Ensures the box takes up the full height of the page
  }}
>
  {/* Other content goes here */}

  <Box
    sx={{
      marginTop: 'auto', // Pushes the button to the bottom
      // textAlign: 'center', // Center the button horizontally
    }}
  >
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#0a3f73",
        textTransform: "none",
        marginTop: "10%", // Can adjust this to control spacing
      }}
      onClick={Save}
    >
      Submit
    </Button>
  </Box>
</Box>

       
        {/* </Box> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addVendor}
        onClose={() =>{ props.setAddVendor(false); resetForm();}}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddVendor;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
