import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Toolbar,
  Typography,
  Box,
  PaginationItem,
} from "@mui/material";
import axios from "axios";
import { api } from "../../../../../utils/api";
import { styled } from "@mui/material/styles";
import AddVehicle from "../Vehicle/AddVehicle";
import ViewVehicle from "../Vehicle/ViewVehicle";
// import VehicleFilter from "../../Roles and Permissions/Filters/VehicleFilter";
import DateRangePickerComponent from "../../../Roles and Permissions/Filters/Date";
import PaginationBox from "../../../../../Components/Masters/Pagination";
import DeleteDialog from "../../../../../Components/Masters/DeleteDialog";
import ColumnHeadings from "../../../../../Components/Masters/ColumnHeadings";
import MenuActions from "../../../../../Components/Masters/MenuActions";
import TableContent from "../../../../../Components/Masters/TableContent";
import FilterDrawer from "../../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../../Components/Masters/TopMenu";
import MessagePopup from "../../../../../Components/Masters/MessagePopup";
import TableHeading from "../../../../../Components/Masters/TableHeading";
import MenuIcon from "../../../../../Components/Masters/MenuIcon";
import MultiSelect from "../../../Roles and Permissions/Filters/MultiSelect";
import { companyId } from "../../../../../utils/companyId";
import NoDataFound from "../../../../../Components/Masters/NoDataFound";

const Vehicle = () => {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addVehicle, setAddVehicle] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    // "Transport Type": false,
    // Ownership: false,
    "Logistics Vendor": false,
    Brand: false,
    Model: false,
    "Vehicle Category": false,
    "Vehicle Type": false,
  });
  const [viewVehicle, setViewVehicle] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [errors, setErrors] = useState({
    Date: false,
    // "Transport Type": false,
    // Ownership: false,
    "Logistics Vendor": false,
    Brand: false,
    Model: false,
    "Vehicle Category": false,
    "Vehicle Type": false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    logisticsVendor: [],
    brand: [],
    model: [],
    vehicleType: [],
    vehicleCategory: [],
  });
  const filters = [
    "Date",
    "Logistics Vendor",
    "Brand",
    "Model",
    "Vehicle Category",
    "Vehicle Type",
  ];

  const inputData = [
    // {
    //   fieldName: "transportType",
    //   title: "Transport type",
    //   options: ["Passenger", "Freight"],
    //   placeholder: "Tap to select",
    // },
    // {
    //   fieldName: "ownership",
    //   title: "Ownership",
    //   options: ["Company owned", "Leased"],
    //   placeholder: "Tap to select",
    // },
    {
      fieldName: "logisticsVendor",
      title: "Logistics vendor",
      type: "select",
      url: `${api}/master/logistic/vendorMaster`,
      field: "logisticVendorLegalName",
    },
    {
      fieldName: "vehicleNumber",
      title: "Vehicle number",
      placeholder: "Type here",
    },
    {
      fieldName: "vehicleCategory",
      title: "Vehicle Category",
      options: [
        "Heavy Commercial Vehicles (HCV)",
        "Medium Commerical Vehicles (MCV)",
        "Light Commerical Vehicles (LCV)",
      ],
      type: "select",
    },
    {
      fieldName: "vehicleType",
      title: "Vehicle Type",
      type: "select",
    },
    {
      fieldName: "brand",
      title: "Brand",
      // type: "select",
      placeholder: "Type here",
      // url:`${api}/master/getAllBrand`
    },
    {
      fieldName: "model",
      title: "Model",
      // type: "select",
      placeholder: "Type here",
      // url:`${api}/master/getAllModel`
    },
    {
      fieldName: "bharatStageEmissionType",
      title: "Bharat Stage Emission Type",
      options: [
        "India 2000 (Euro 1)",
        "BS II (Euro 2)",
        "BS III (Euro 3)",
        "BS IV (Euro 4)",
        "BS V (Euro 5)",
        "BS VI (Euro 6)",
      ],
      type: "select",
    },
    {
      fieldName: "purchaseDate",
      title: "Purchase Date",
      type: "date",
      placeholder: "dd/mm/yyyy",
    },
    {
      fieldName: "lastServiceDate",
      title: "Last service",
      type: "date",
      placeholder: "dd/mm/yyyy",
    },
    {
      fieldName: "fuelType",
      title: "Fuel type",
      options: [
        "Diesel (average biofuel blend)",
        "Diesel (100% mineral diesel)",
        "Petrol (average biofuel blend)",
        "Petrol (100% mineral petrol)",
        "CNG",
        "Electricity",
      ],
      type: "select",
    },
    {
      fieldName: "declaredEfficiency",
      title: "Declared efficiency",
      placeholder: "Enter Number (km/ltr)",
    },
    {
      fieldName: "loadCapacity",
      title: "Load capacity (tons)",
      placeholder: "Enter Number",
    },
    { fieldName: "uploadDocuments", title: "Upload documents" },
    { fieldName: "pucDocs", title: "PUC docs" },
    { fieldName: "pollutionDocs", title: "Pollution docs" },
    { fieldName: "rcDocs", title: "RC docs" },
    { fieldName: "insuranceDocs", title: "Insurance docs" },
  ];

  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(`${api}/master/getAllVehicle`);
      const vehicles = response.data.data;
      console.log(vehicles);

      const uniqueModels = [
        ...new Set(
          vehicles.map((vehicle) => vehicle.model).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniqueBrands = [
        ...new Set(
          vehicles.map((vehicle) => vehicle.brand).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniqueVehicleTypes = [
        ...new Set(
          vehicles.map((vehicle) => vehicle.vehicleType).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniqueVehicleCategory = [
        ...new Set(
          vehicles.map((vehicle) => vehicle.vehicleCategory).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniqueVendors = [
        ...new Set(
          vehicles.map((vehicle) => vehicle.logisticsVendor).filter(Boolean) // Filter out undefined values
        ),
      ];

      return {
        models: uniqueModels,
        brands: uniqueBrands,
        vendors: uniqueVendors,
        vehicleCategory: uniqueVehicleCategory,
        vehicleType: uniqueVehicleTypes,
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  // fetching Vehicle data
  const fetchData = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    console.log(filters, sortConfig.key, sortConfig.direction);
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      const response = await axios.get(
        `${api}/master/getAllVehicle?${queryParams}`
      );

      setData(response.data.data); // Update the user list
      console.log(response.data);
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
      console.log(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortConfig]);

  const handleMenuClick = (event, vehicle) => {
    setAnchorEl(event.currentTarget);
    setSelectedVehicle(vehicle);
    setVehicle(vehicle);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedVehicle(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddVehicle(true);
    setViewVehicle(false);
  };

  const handleView = () => {
    handleMenuClose();
    setViewVehicle(true);
    setAddVehicle(false);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = async () => {
    // console.log("indexhandleDeleteAggree", vehicle, vehicle._id);
    try {
      const res = await axios.delete(
        `${api}/master/deleteVehicle/${vehicle._id}`
      );
      setDeleteDialog(false);
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
    } catch (err) {
      console.log(err);
      setsnackOpen({
        open: true,
        message: err.data?.response.message ?? "network Error",
        severity: "error",
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filtersToApply = {
    company: companyId,
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        acc[key] = selectedOptions.join(","); // Change to an array if needed
      }

      return acc;
    }, {}),
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
      // "Transport Type": false,
      // Ownership: false,
      "Logistics Vendor": false,
      Brand: false,
      Model: false,
      "Vehicle Category": false,
      "Vehicle Type": false,
    };

    console.log(selectedFilter, "hi");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select a date range";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }
    if (
      selectedFilter === "Vehicle Type" &&
      selectedFields.vehicleType.length === 0
    ) {
      newErrors["Vehicle Type"] = "Please select Vehicle Type";
      isValid = false;
    }

    if (
      selectedFilter === "Vehicle Category" &&
      selectedFields.vehicleCategory.length === 0
    ) {
      newErrors["Vehicle Category"] = "Please select Vehicle Category";
      isValid = false;
    }

    if (selectedFilter === "Brand" && selectedFields.brand.length === 0) {
      newErrors.Brand = "Please select Brand";
      isValid = false;
    }

    if (selectedFilter === "Model" && selectedFields.model.length === 0) {
      newErrors.Model = "Please select Model";
      isValid = false;
    }
    if (
      selectedFilter === "Logistics Vendor" &&
      selectedFields.logisticsVendor.length === 0
    ) {
      newErrors["Logistics Vendor"] = "Please select Logistics Vendor";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    if (!validateFilters()) return;
    setPage(1);
    fetchData(filtersToApply);
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
      logisticsVendor: [],
      brand: [],
      model: [],
      vehicleType: [],
      vehicleCategory: [],
    });

    // Call fetchData without any filters
    await fetchData({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate ? true : false;
    const isVehicleTypeApplied =
      selectedFields.vehicleType?.length > 0 ? true : false;
    const isVehicleCategoryApplied =
      selectedFields.vehicleCategory?.length > 0 ? true : false;
    const isLogisticsVendorApplied =
      selectedFields.logisticsVendor?.length > 0 ? true : false;
    const isBrandApplied = selectedFields.brand?.length > 0 ? true : false;
    const isModelApplied = selectedFields.model?.length > 0 ? true : false;

    setIsFiltersApplied(
      isDateApplied ||
        isVehicleTypeApplied ||
        isVehicleCategoryApplied ||
        isLogisticsVendorApplied ||
        isBrandApplied ||
        isModelApplied
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      "Vehicle Type": isVehicleTypeApplied,
      "Vehicle Category": isVehicleCategoryApplied,
      "Logistics Vendor": isLogisticsVendorApplied,
      Brand: isBrandApplied,
      Model: isModelApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    setErrors({
      Date: false,
      Brand: false,
      Model: false,
      "Vehicle Category": false,
      "Vehicle Type": false,
    });
  };

  const columnHeadings = [
    { title: "Date", field: "createdAt" },
    // { title: "Transport Type", field: "transportType" },
    // { title: "Ownership", field: "ownership" },
    { title: "Logistics Vendor", field: "logisticsVendor" },
    { title: "Brand", field: "brand" },
    { title: "Model", field: "model" },
    { title: "Vehicle Category", field: "vehicleCategory" },
    { title: "Vehicle Type", field: "vehicleType" },
  ];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/master/getAllVehicle`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
      case "Model":
        return (
          <MultiSelect
            title="Model"
            url={`${api}/master/getAllVehicle`}
            selectedField={selectedFields.model}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors.Model}
            setErrors={setErrors}
            field="model"
            data={uniqueValues.models}
          />
        );
      case "Brand":
        return (
          <MultiSelect
            title="Brand"
            url={`${api}/master/getAllVehicle`}
            selectedFields={selectedFields}
            selectedField={selectedFields.brand}
            setSelectedFields={setSelectedFields}
            errors={errors.Brand}
            setErrors={setErrors}
            field="brand"
            data={uniqueValues.brands}
          />
        );
      case "Logistics Vendor":
        return (
          <MultiSelect
            title="Logistics Vendor"
            url={`${api}/master/getAllVehicle`}
            selectedFields={selectedFields}
            selectedField={selectedFields.logisticsVendor}
            setSelectedFields={setSelectedFields}
            errors={errors["Logistics Vendor"]}
            setErrors={setErrors}
            field="logisticsVendor"
            data={uniqueValues.vendors}
          />
        );
      case "Vehicle Category":
        return (
          <MultiSelect
            title="Vehicle Category"
            url={`${api}/master/getAllVehicle`}
            selectedField={selectedFields.vehicleCategory}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Vehicle Category"]}
            setErrors={setErrors}
            data={uniqueValues.vehicleCategory}
            field="vehicleCategory"
          />
        );
      case "Vehicle Type":
        return (
          <MultiSelect
            title="Vehicle Type"
            url={`${api}/master/getAllVehicle`}
            selectedField={selectedFields.vehicleType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Vehicle Type"]}
            setErrors={setErrors}
            field="vehicleType"
            data={uniqueValues.vehicleType}
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/master/getAllVehicle`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <MessagePopup {...{ snackOpen, setsnackOpen }} />{" "}
      {/*Message popup for success or failure */}
      {/* View log, Edit, delete menus */}
      <AddVehicle
        id={vehicle ? vehicle._id : ""}
        addData={addVehicle}
        setAddData={setAddVehicle}
        data={data}
        element={vehicle}
        addUrl={`${api}/master/addVehicle`}
        updateUrl={`${api}/master/updateVehicle`}
        // details={vehicle}
        fetchData={fetchData}
        setsnackOpen={setsnackOpen}
        inputData={inputData}
      />
      <ViewVehicle
        id={vehicle ? vehicle._id : ""}
        data={vehicle}
        viewData={viewVehicle}
        setViewData={setViewVehicle}
        inputData={inputData}
        handleEdit={handleEdit}
      />
      {deleteDialog && (
        <DeleteDialog
          {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
        />
      )}
      {/* Filter Drawer */}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setVehicle}
          setAddData={setAddVehicle}
          {...{
            heading: "Transportation & Distribution",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
        <Box>
          <TableHeading heading={"Vehicle Master"} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "12% 18% 12% 12% 20% 15% 8%",
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            {columnHeadings.map((el) => (
              <ColumnHeadings
                key={el}
                {...{
                  title: el.title,
                  key: el.title,
                  handleSort,
                  field: el.field,
                  sortConfig,
                }}
              />
            ))}
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {data.length === 0 ? (
            <NoDataFound />
          ) : (
            data.map((Vehicle) => (
              <Box
                key={Vehicle._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "12% 18% 12% 12% 20% 15% 8%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {columnHeadings.map((el) => (
                  <TableContent
                    key={el.field}
                    {...{
                      fieldData:
                        el.field === "logisticsVendor"
                          ? Vehicle.logisticsVendor
                          : typeof Vehicle[el.field] === "object" &&
                            Vehicle[el.field] !== null
                          ? Vehicle[el.field]
                          : Vehicle[el.field],
                      dateField: el.field === "createdAt",
                    }}
                  />
                ))}
                {/* Menu Action Icon */}
                <MenuIcon {...{ handleMenuClick, field: Vehicle }} />
              </Box>
            ))
          )}
        </Box>
      </TableContainer>
      {/* Pagination */}
      {totalPages > 1 && (
        <PaginationBox
          {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
        />
      )}
      <MenuActions
        {...{
          id: Vehicle._id,
          handleView,
          handleEdit,
          handleDelete,
          anchorEl,
          handleMenuClose,
        }}
      />
      {/*Add,Edit,Delete Icons & function calls */}
    </Box>
  );
};

export default Vehicle;
