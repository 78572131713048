import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import InputField from "../../../../Components/Masters/InputField";
import SelectField from "../../../../Components/Masters/SelectField";
import FileField from "../../../../Components/Masters/FileField";
import { validateNumber } from "../../../../utils/validateFields";
import { numberError } from "../../../../utils/validationMessage";
import Multiline from "../../../../Components/Masters/Multiline";

function AddRefrigeration({
  inputData,
  fetchData,
  id,
  data,
  setAddData,
  addData,
  setsnackOpen,
  addUrl,
  updateUrl,
}) {
  const [errors, setErrors] = useState({});
  const initialState = {
    emissionSource: "",
    typeOfSubstance: "",
    // quantityReleased: "",
    quantityReleased:{ value: "", unit: "" },
    remarks: "",
    company: companyId,
    createdBy: companyId,
  };
  const [formData, setFormData] = useState(initialState);

 

  useEffect(() => {
    // if (addData) {
    //   setFormData(initialState); // Reset form data when opening the drawer
    // }
    if (id && data) {
      setFormData({
        emissionSource: data.emissionSource || "",
        typeOfSubstance: data.typeOfSubstance || "",
        // quantityReleased: data.quantityReleased?.value || "",
        quantityReleased: {
          value: data.quantityReleased?.value || "",
          unit: data.quantityReleased?.unit || "kg",
        },
        remarks: data.remarks || "",
      });
    } else if(!id) {
      setFormData(initialState);
    }
  }, [data,id]);

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  
    if (name === "quantityReleased.value") {
      // Ensure only integers are allowed in quantityReleased.value
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue) && Number.isInteger(parsedValue)) {
        setFormData((prevState) => ({
          ...prevState,
          quantityReleased: {
            ...prevState.quantityReleased,
            value: parsedValue,
          },
        }));
      } else {
        // Optionally show an error message if the input is not an integer
        setErrors((prevErrors) => ({
          ...prevErrors,
          "quantityReleased.value": "Quantity Released should be an integer",
        }));
      }
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  
  const handleClose = () => {
    setAddData(false);
    setErrors({});
   
};


const validateForm = () => {
  const newErrors = {};
  console.log("Form data for validation:", formData);

  inputData.forEach((input) => {
      const value = formData[input.fieldName] || "";
      console.log(`Validating ${input.fieldName}:`, value); // Log value being checked

      // Special handling for quantityReleased
      if (input.fieldName === "quantityReleased") {
          const quantityValue = value.value; // Access the value property of quantityReleased
          if (!validateNumber(quantityValue)) {
              newErrors[input.fieldName] = numberError; // Change this to your desired error message
              console.log(`Error: quantityReleased.value is not a valid number.`);
              return; // Stop further checks for this input
          }
          // No need to check unit since it's not required
      }

      // Additional checks for required fields, excluding quantityReleased.unit
      if (input.required && input.type !== "number" && (!value || value.trim() === "")) {
          newErrors[input.fieldName] = `${input.title} is required.`;
          console.log(`Error: ${input.fieldName} is required.`);
      }
  });

  console.log("Validation errors found:", newErrors);
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};



const Save = async () => {
  // if (!validateForm()) return;
  console.log("Attempting to save...");
  if (!validateForm()) {
      console.log("Form validation failed, not calling post request.");
      return;
  }

  // Prepare data to send
  const dataToSend = {
    ...formData,
     quantityReleased: {
      value: formData.quantityReleased.value,
      unit: "kg", // Default unit or as per requirement
      // unit: formData.quantityReleased.unit || "kg",
    },
    // documents: uploadedFiles,
  };

 

  await axios({
    method: id ? "put" : "post",
    url: id ? `${updateUrl}` : addUrl,
    data: dataToSend,
    params: id ? { Id: id } : null, // Send params only for PUT request
  })
    .then((res) => {
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
      setFormData({});
      handleClose();
    })
    .catch((err) => {
      if (err.response && err.response.data && err.response.data.errors) {
        const errorMessages = err.response.data.errors
          .map((error) => error.msg)
          .join(", ");
        setsnackOpen({
          open: true,
          message: errorMessages,
          severity: "error",
        });
      }
    });
};

  const renderInputField = (el) => {
    if (el.options || el.type === "select") {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          options={el.options}
          errors={errors[el.fieldName]}
          url={el.url}
          field={el.field}
        />
      );
    }

    if (el.fieldName === "quantityReleased") {
      return (
        <Box>
          <InputField
            title="Quantity Released (Value)"
            handleChange={(e) => handleChange(e, "quantityReleased.value")}
            placeholder="Enter quantity"
            value={formData.quantityReleased?.value || ""}
            errors={errors["quantityReleased.value"]}
            fieldName="quantityReleased.value"
          />
          {/* Uncomment if unit field is required
          <InputField
            title="Quantity Released (Unit)"
            handleChange={(e) => handleChange(e, "quantityReleased.unit")}
            placeholder="kg"
            value={formData.quantityReleased?.unit || "kg"}
            errors={errors["quantityReleased.unit"]}
            fieldName="quantityReleased.unit"
          /> */}
        </Box>
      );
    }



    if (el.fieldName === "remarks")
      return (
        <Multiline
          title={el.title}
          handleChange={handleChange}
          placeholder={el.placeholder}
          value={formData[el.fieldName] || ""}
          errors={errors[el.fieldName]}
          fieldName={el.fieldName}
        />
      );


    return (
      <InputField
        title={el.title}
        handleChange={handleChange}
        placeholder={el.placeholder}
        value={formData[el.fieldName] || ""}
        errors={errors[el.fieldName]}
        fieldName={el.fieldName}
      />
    );
  };

  const list = () => (
    <Box display="flex" gap={3} sx={{ width: "400px", height: "100vh",maxWidth:"100%" }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton onClick={handleClose} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "88%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {id ? "Edit Entry" : "Add Entry"}
        </Typography>
        {inputData.map((el) => {
          // Conditional rendering for hours and industrial equipment
          if (
            (el.fieldName === "hours" ||
              el.fieldName === "industrialEquipment") &&
            formData.typeOfEntry !== "Individual"
          ) {
            return null; // Skip rendering these fields if typeOfEntry is not "Individual"
          }

          return <div key={el.fieldName}>{renderInputField(el)}</div>;
        })}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "60%",
            }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      sx={{
        backgroundColor: "transparent",
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          height: "100vh",
          minHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
      anchor="right"
      open={addData}
      onClose={handleClose}
    >
      {list()}
    </Drawer>
  );
}

export default AddRefrigeration;
