export const FuelTypeEnum = [
  "Diesel (average biofuel blend)",
  "Diesel (100% mineral diesel)",
  "Petrol (average biofuel blend)",
  "Petrol (100% mineral petrol)",
  "CNG",
  "Electricity",
];
export const fuelConsumptionUnits = ["Ltr per hour", "Kg per hour", "Kwh"];

export const allowedTypes = [
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "application/vnd.ms-excel", // .xls
  "image/png",
];

export const maxFileSize = 5 * 1024 * 1024; // 5 MB
