import {
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Typography,
  Collapse,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Box,
} from "@mui/material";
import {
  DashboardOutlined,
  SupportOutlined,
  DescriptionOutlined,
  ReceiptLongOutlined,
  HeadphonesOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import styles from "../../src/styles/layout/dashboard.module.css";
import React, { useEffect, useState } from "react";
import Logo from "../Components/Logo";
import NirantaraLogo from "../assets/images/NirantaraLogo.png";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Description as ScopeIcon,
  SaveAs as SaveAsIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  CheckCircle as CheckCircleIcon,
  Padding,
} from "@mui/icons-material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import StarBorder from "@mui/icons-material/StarBorder";

const Sidebar = () => {
  const location = useLocation();
  const [subSidebar, setSubSidebar] = useState(false);
  const [open, setOpen] = useState({});
  const [secondarySidebarContent, setSecondarySidebarContent] = useState(null);
  const [subSidebarContent, setSubSidebarContent] = useState(null);

  useEffect(() => {
    if (location.pathname === "/scope1/dashboard") {
      setSubSidebar(true);
    }
  }, [location.pathname]);

  const elements = [
    {
      subData: [
        {
          path: "/test1",
          primary: "Overview",
          Icon: DashboardOutlined,
          openAnotherSidebar: true,
          nextSidebar: "subElements",
        },
        {
          path: "/test",
          primary: "Water Management",
          Icon: SupportOutlined,
        },
        {
          path: "/waste-management",
          primary: "Waste Management",
          Icon: SupportOutlined,
        },
      ],
    },
    {
      heading: "Reports",
      subData: [
        {
          path: "/scope1/dashboard",
          primary: "Scope 1",
          Icon: DescriptionOutlined,
          openAnotherSidebar: true,
          subsection: "scope1",
        },
        {
          path: "/scope1/dashboard",
          primary: "Scope 2",
          Icon: DescriptionOutlined,
          openAnotherSidebar: true,
          subsection: "scope2",
        },
        {
          path: "/scope-3",
          primary: "Scope 3",
          Icon: DescriptionOutlined,
        },
      ],
    },
    {
      heading: "Setup",
      subData: [
        {
          path: "/setup",
          primary: "Setup",
          Icon: ReceiptLongOutlined,
        },
      ],
    },
    {
      heading: "Misc",
      subData: [
        {
          path: "/support",
          primary: "Support",
          Icon: HeadphonesOutlined,
        },
        {
          path: "/documentation",
          primary: "Documentation",
          Icon: DescriptionOutlined,
        },
      ],
    },
  ];

  const menuItems = [
    { text: "Overview", icon: SendIcon, path: "/scope1/dashboard" },
    {
      text: "Stationary Combustion",
      icon: InboxIcon,
      path: "/inbox",
      children: [
        { text: "Natural Gas", icon: StarBorder, path: "/inbox/starred" },
        { text: "Coal", icon: StarBorder, path: "/inbox/starred" },
        {
          text: "Petroleum/ Gasoline",
          icon: StarBorder,
          path: "/inbox/starred",
        },
        { text: "Diesel", icon: StarBorder, path: "/inbox/starred" },
        { text: "Fuel Oil", icon: StarBorder, path: "/inbox/starred" },
      ],
    },
    {
      text: "Mobile Combustion",
      icon: InboxIcon,
      path: "/inbox",
      children: [
        { text: "Natural Gas", icon: StarBorder, path: "/inbox/starred" },
        { text: "Coal", icon: StarBorder, path: "/inbox/starred" },
        {
          text: "Petroleum/ Gasoline",
          icon: StarBorder,
          path: "/inbox/starred",
        },
        { text: "Diesel", icon: StarBorder, path: "/inbox/starred" },
        { text: "Fuel Oil", icon: StarBorder, path: "/inbox/starred" },
      ],
    },
    { text: "Fugitive Emissions", icon: DraftsIcon, path: "/test" },
  ];

  const menuItemsScope2 = [
    { text: "Overview", icon: SendIcon, path: "/scope2/dashboard" },
    { text: "Electricity", icon: SendIcon, path: "/scope2/dashboard" },
    { text: "Heat", icon: SendIcon, path: "/scope2/dashboard" },
    { text: "Steam", icon: SendIcon, path: "/scope2/dashboard" },
    { text: "Fugitive Emissions", icon: DraftsIcon, path: "/test" },
  ];

  const renderSidebarItems = (elements) => {
    return elements.map((section, index) => (
      <div key={index}>
        {section.heading && (
          <Typography sx={{ mt: 2, mb: 1, paddingLeft: "1rem" }}>
            {section.heading}
          </Typography>
        )}
        {section.subData.map((item) => (
          <a
            href={item.path}
            key={item.primary}
            style={{
              textDecoration: "none",
              color: "#000",
              padding: "1rem",
              display: "flex",
            }}
            onClick={(e) => {
              e.preventDefault();
              if (item.openAnotherSidebar) {
                setSubSidebar(true);
                setSubSidebarContent(item.subsection);
                setSecondarySidebarContent(item.primary);
              } else {
                setSubSidebar(false);
              }
            }}
          >
            <item.Icon sx={{ fontSize: 20, marginRight: "8px" }} />
            <Typography variant="body2">{item.primary}</Typography>
          </a>
        ))}
      </div>
    ));
  };

  const renderMiniSidebarItems = (elements) =>
    elements.map((section, index) => (
      <div key={index}>
        {section.subData.map((item) => (
          <a
            href={item.path}
            key={item.primary}
            style={{
              textDecoration: "none",
              color: location.pathname.includes(item.path)
                ? "#488257"
                : "#E6E6E6",
              display: "flex",
              padding: "10px",
            }}
          >
            <item.Icon style={{ fontSize: 24, margin: "6px 0" }} />
          </a>
        ))}
      </div>
    ));

  const handleClick = (index) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [index]: !prevOpen[index],
    }));
  };

  const renderSecondarySidebarItems = (elements, heading) => {
    return (
      <div style={{ width: "100%", fontFamily: "Inter" }}>
        {heading && (
          <p
            style={{
              color: "#fff",
              fontWeight: "700",
              fontSize: "24px",
              padding: "0 2rem 0 0.5rem",
              marginLeft: "1rem",
              borderBottom: "2px solid #fff",
              display: "inline-block",
            }}
          >
            {heading}
          </p>
        )}
        {elements.map((item, index) => (
          <div key={index}>
            <a
              href={item.path}
              onClick={(e) => {
                e.preventDefault();
                if (item.children) {
                  handleClick(index);
                } else {
                  window.location.href = item.path;
                }
              }}
              style={{
                textDecoration: "none",
                color: location.pathname.includes(item.path)
                  ? "#488257"
                  : "#ffffff",
                display: "flex",
                padding: "0.5rem",
                margin: "4px 8px",
                backgroundColor: location.pathname.includes(item.path)
                  ? "#ffffff"
                  : "#488257",
                borderRadius: "4px",
                alignItems: "center",
              }}
            >
              <item.icon
                sx={{
                  fontSize: 20,
                }}
              />
              <span
                style={{
                  marginLeft: "1rem",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "inherit",
                }}
              >
                {item.text}
              </span>
              {item.children && (
                <span style={{ marginLeft: "auto", fontSize: "20px" }}>
                  {open[index] ? <ExpandLess /> : <ExpandMore />}
                </span>
              )}
            </a>
            {item.children && open[index] && (
              <div style={{ paddingLeft: "1rem" }}>
                {item.children.map((child, childIndex) => (
                  <a
                    href={child.path}
                    key={childIndex}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(child.path)
                        ? "#488257"
                        : "#ffffff",
                      display: "flex",
                      padding: "0 0 0.5rem 1rem",
                      margin: "4px",
                      backgroundColor: location.pathname.includes(child.path)
                        ? "#ffffff"
                        : "#488257",
                      borderRadius: "4px",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = child.path;
                    }}
                  >
                    <child.icon
                      sx={{
                        fontSize: 20,
                        marginRight: "4px",
                      }}
                    />
                    <span
                      style={{
                        marginLeft: "1rem",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "inherit",
                      }}
                    >
                      {child.text}
                    </span>
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Box display="flex" height="100vh">
      <Box
        sx={{
          width: subSidebar ? "20%" : "100%",
          transition: "width 0.3s",
        }}
      >
        <Box display="flex" justifyContent="center" p={2}>
          {subSidebar ? <img src={NirantaraLogo} alt="Logo" /> : <Logo />}
        </Box>
        <Divider />
        {subSidebar
          ? renderMiniSidebarItems(elements)
          : renderSidebarItems(elements)}
      </Box>

      {subSidebar && (
        <div
          style={{
            width: "80%",
            backgroundColor: "#488257",
            height: "100vh",
            borderRadius: "4px",
          }}
        >
          <div
            style={{
              marginTop: "1rem",
              backgroundColor: "#488257",
              borderRadius: "4px",
            }}
          >
            {secondarySidebarContent === "scope1"
              ? renderSecondarySidebarItems(menuItems, "Scope 1")
              : renderSecondarySidebarItems(menuItemsScope2, "Scope 2")}
          </div>
        </div>
      )}
    </Box>
  );
};
export default Sidebar;
