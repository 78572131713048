import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Toolbar,
  Typography,
  Box,
  PaginationItem,
  Grid,
} from "@mui/material";
import axios from "axios";
import { api } from "../../../../utils/api";
import { styled } from "@mui/material/styles";
// import VehicleFilter from "../../Roles and Permissions/Filters/VehicleFilter";
import PaginationBox from "../../../../Components/Masters/Pagination";
import DeleteDialog from "../../../../Components/Masters/DeleteDialog";
import ColumnHeadings from "../../../../Components/Masters/ColumnHeadings";
import MenuActions from "../../../../Components/Masters/MenuActions";
import TableContent from "../../../../Components/Masters/TableContent";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import MessagePopup from "../../../../Components/Masters/MessagePopup";
import TableHeading from "../../../../Components/Masters/TableHeading";
import MenuIcon from "../../../../Components/Masters/MenuIcon";
import { companyId } from "../../../../utils/companyId";
import AddRefrigeration from "./AddRefrigeration";
import ViewRefrigeration from "./ViewRefrigeration";
import DateRangePickerComponent from "../../../Masters/Roles and Permissions/Filters/Date";
import MultiSelect from "../../../Masters/Roles and Permissions/Filters/MultiSelect";
import Range from "../../../Masters/Roles and Permissions/Filters/Range";
import Cards from "../../../../Components/Masters/Cards";
import SelectCard from "../../../../Components/Masters/SelectCard";
import CustomCard from "./CardComponent";
import NoDataFound from "../../../../Components/Masters/NoDataFound";

const Refrigeration = () => {
  const [selectedPeriod, setSelectedPeriod] = React.useState("July");
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  // const months = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addData, setAddData] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    "Emission Source": false,
    "Type Of Substance": false,
    "Quantity": false,
  });
  const [viewData, setViewData] = useState(false);
  const [element, setElement] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [errors, setErrors] = useState({
    Date: false,
    "Emission Source": false,
    "Type Of Substance": false,
    "Quantity": false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    emissionSource: [],
    typeOfSubstance: [],
    industrialEquipment: [],
  });
  const [quantityRange, setQuantityRange] = useState({ min: "", max: "" });
  



  const filters = [
    "Date",
    "Emission Source",
    "Type Of Substance",
    "Quantity",
   
  ];

 

  const inputData = [
    {
      fieldName: "emissionSource",
      title: "Emission Source",
      placeholder: "Select",
      type: "select",
      options: ["Air Conditioning", "Refrigeration"],
      required: true,
    },
    {
      fieldName: "typeOfSubstance",
      title: "Type Of Substance",
      placeholder: "Select",
      type: "select",
      options:  [
        "HFC-23",
        "HFC-32",
        "HFC-41",
        "HFC-125",
        "HFC-134",
        "HFC-134a",
        "HFC-143",
        "HFC-143a",
        "HFC-152a",
        "HFC-227ea",
        "HFC-236fa",
        "HFC-245fa",
        "HFC-43-I0mee",
        "Perfluoromethane (PFC-14)",
        "Perfluoroethane (PFC-116)",
        "Perfluoropropane (PFC-218)",
        "Perfluorocyclobutane (PFC-318)",
        "Perfluorobutane (PFC-3-1-10)",
        "Perfluoropentane (PFC-4-1-12)",
        "Perfluorohexane (PFC-5-1-14)",
        "PFC-9-1-18",
        "Perfluorocyclopropane",
        "Sulphur hexafluoride (SF6)",
        "HFC-152",
        "HFC-161",
        "HFC-236cb",
        "HFC-236ea",
        "HFC-245ca",
        "HFC-365mfc",
        "Nitrogen trifluoride",
      ],
      required: true,
    },
    {
      fieldName: "quantityReleased",
      title: "Quantity Released(Kg)",
      placeholder: "Enter quantity",
      type: "number",
      required: true,
    },
    
    {
      fieldName: "remarks",
      title: "Remark",
      placeholder: "Additional remarks",
      type: "text",
    },
   
  ];

  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(
        `${api}/scope1/fugitiveEmission/getAllRefrigerationAirConditioning`
      );
      const data = response.data.data;
      console.log(data);

      const uniqueEmissionSource = [
        ...new Set(
          data
            .map((el) => el.emissionSource)
            .filter(Boolean) // Filter out undefined values
        ),
      ];

      console.log(uniqueEmissionSource);

      const uniqueTypeOfSubstance = [
        ...new Set(
          data.map((el) => el.typeOfSubstance).filter(Boolean) // Filter out undefined values
        ),
      ];
     
      return {
        emissionSource: uniqueEmissionSource,
        typeOfSubstance: uniqueTypeOfSubstance,
        
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  // fetching Vehicle data
  const fetchData = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    console.log(filters);
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        // minQuantityReleased: quantityRange.min || undefined, // Include min value if present
        // maxQuantityReleased: quantityRange.max || undefined, 
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
        company: companyId,
        createdBy: companyId,
      }).toString();
      const response = await axios.get(
        `${api}/scope1/fugitiveEmission/getAllRefrigerationAirConditioning?${queryParams}`
      );

      setData(response.data.data); // Update the user list
      console.log(response.data.data);
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortConfig]);

  const handleMenuClick = (event, element) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(element);
    setElement(element);
    console.log(element);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedData(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddData(true);
    setViewData(false);
  };

  const handleView = () => {
    handleMenuClose();
    setViewData(true);
    setAddData(false);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = async () => {
    // console.log("indexhandleDeleteAggree", vehicle, vehicle._id);
    try {
      console.log(element._id);
      const res = await axios.put(
        `${api}/scope1/fugitiveEmission/deleteRefrigerationAirConditioning?Id=${element._id}`
      );
      setDeleteDialog(false);
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
    } catch (err) {
      console.log(err);
      setsnackOpen({
        open: true,
        message: err.data?.response.message ?? "network Error",
        severity: "error",
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filtersToApply = {
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        acc[key] = selectedOptions.join(","); // Change to an array if needed
      }
      return acc;
    }, {}),
    minQuantityReleased: quantityRange.min || "",
    maxQuantityReleased: quantityRange.max || "",
   
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
      "Emission Source": false,
      "Type Of Substance": false,
      "Quantity": false,
    };

    console.log(selectedFilter, "hi");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select start date & end date";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }

    if (
      selectedFilter === "Emission Source" &&
      selectedFields.emissionSource.length === 0
    ) {
      newErrors["Emission Source"] =
        "Please select atleast one Industrial Processs";
      isValid = false;
    }

   

    if (
      selectedFilter === "Type Of Substance" &&
      selectedFields.typeOfSubstance.length === 0
    ) {
      newErrors["Type Of Substance"] = "Please select atleast one Type Of Substance";
      isValid = false;
    }

    if (selectedFilter === "Quantity") {
      const min = parseFloat(quantityRange.min);
      const max = parseFloat(quantityRange.max);

      if (!min || !max) {
        newErrors["Quantity"] =
          "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Quantity"] =
          "Min consumption must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Quantity"] =
          "Max consumption must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Quantity"] =
          "Min consumption cannot be greater than max consumption";
        isValid = false;
      }
    }
    
    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    console.log(selectedFields);
    if (!validateFilters()) return;
    setPage(1);
    fetchData(filtersToApply);
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
      emissionSource: [],
      typeOfSubstance: [],
    });
    setQuantityRange({ min: "", max: "" });
 

    // Call fetchData without any filters
    await fetchData({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate;
   
    const isTypeOfSubstanceApplied = selectedFields.typeOfSubstance?.length > 0;
    const isEmissionSourceApplied =
      selectedFields.emissionSource?.length > 0;
    const isQuantityRangeApplied = quantityRange.min && quantityRange.max;

    setIsFiltersApplied(
      isDateApplied ||
        isEmissionSourceApplied ||
        isTypeOfSubstanceApplied ||
        isQuantityRangeApplied 
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      "Emission Source": isEmissionSourceApplied,
      "Type Of Substance": isTypeOfSubstanceApplied,
      
      "Quantity": isQuantityRangeApplied,
     
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    // setErrors({ Date: false, Role: false });
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const columnHeadings = [
    { title: "Date", fieldName: "createdAt" },
    { fieldName: "emissionSource", title: "Emission Source" },
    { fieldName: "typeOfSubstance", title: "Type Of Substance" },
    { fieldName: "quantityReleased", title: "Quantity Released(Kg)" },
    
  ];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/stationaryCombustion/getAllCoal`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
      case "Emission Source":
        return (
          <MultiSelect
            title="Emission Source"
            selectedField={selectedFields.emissionSource}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Emission Source"]}
            setErrors={setErrors}
            field="emissionSource"
            data={uniqueValues.emissionSource}
          />
        );
      case "Type Of Substance":
        return (
          <MultiSelect
            title="Type Of Substance"
            url={`${api}/master/getAllEquipmentTypes`}
            selectedField={selectedFields.typeOfSubstance}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Type Of Substance"]}
            setErrors={setErrors}
            field="typeOfSubstance"
            data={uniqueValues.typeOfSubstance}
          />
        );
      
      case "Quantity":
        return (
          <Range
            // title="Quantity"
            // selectedRange={quantityRange}
            // setSelectedRange={setQuantityRange}
            // errors={errors["Quantity"]}
            // setErrors={setErrors}

            title="Quantity"
            selectedRange={quantityRange}
            setSelectedRange={setQuantityRange}
            placeholderMin="Min Quantity"
            placeholderMax="Max Quantity"
            errors={errors["Quantity"]}
            setErrors={setErrors}
          />
        );
      
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/stationaryCombustion/getAllCoal`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <MessagePopup {...{ snackOpen, setsnackOpen }} />{" "}
      {/*Message popup for success or failure */}
      {/* View log, Edit, delete menus */}
      <AddRefrigeration
        id={element ? element._id : ""}
        addData={addData}
        setAddData={setAddData}
        data={element}
        addUrl={`${api}/scope1/fugitiveEmission/addRefrigerationAirConditioning`}
        updateUrl={`${api}/scope1/fugitiveEmission/updateRefrigerationAirConditioning`}
        // details={vehicle}
        fetchData={fetchData}
        setsnackOpen={setsnackOpen}
        inputData={inputData}
      />
      <ViewRefrigeration
        id={element ? element._id : ""}
        data={element}
        viewData={viewData}
        setViewData={setViewData}
        inputData={inputData}
        handleEdit={handleEdit}
      />
      <Grid item xs={6} sx={{ margin: "0% 5% -3% 23%", width: "100%" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textDecoration: "underline" }}
        >
         Fugitive Emission
        </Typography>
        <Box sx={{ display: "flex", width: "83%", mt: 2, gap: 2}}>
       
          <CustomCard 
            title="Most Released Substance" 
            value="200" 
            unit="KG Substance Name" 
            width="25%"
            marginTop= "27%"
          />
          <CustomCard 
            title="Total Quantity Released" 
            value="400" 
            unit="kg" 
            width="25%"
            marginTop= "16%"
            dropdowns={[{ label: 'Select', options: ["Company A", "Company B", "Company C"] }]}
          />
          <CustomCard 
            title="Consumption Period" 
            value="700" 
            unit="kg" 
            width="40%"
            marginTop= "10%"
            dropdowns={[
              { label: 'Month', options: ["January", "February", "March", "April",  "May","June","July","August","September","October","November","December"] },
              { label: 'Year', options: ["2022", "2023", "2024"] }
            ]}
          />
        </Box>
      </Grid>

      {deleteDialog && (
        <DeleteDialog
          {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
        />
      )}
      {/* Filter Drawer */}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setElement}
          setAddData={setAddData}
          {...{
            // heading: "Coal",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "3% 5% 0% 23%" }}
      >
        <Box>
          <TableHeading heading={"Refrigeration & Air Conditioning System"} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "12% 24% 24% 29% 8%",
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            {columnHeadings.map((el) => (
              <ColumnHeadings
                key={el}
                {...{
                  title: el.title,
                  key: el.title,
                  handleSort,
                  field: el.fieldName,
                  sortConfig,
                }}
              />
            ))}
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {data.length === 0 ? (
            <NoDataFound />
          ) : (
          data &&
            data.map((item) => (
              <Box
                key={item._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "12% 24% 24% 29% 8%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {columnHeadings.map((el) => (
                  <TableContent
                    key={el.fieldName}
                    {...{
                      fieldData:
                    el.fieldName === "quantityReleased" 
                      ? `${item[el.fieldName]?.value || 0} ${item[el.fieldName]?.unit || ''}`
                      : typeof item[el.fieldName] === "object" &&
                        item[el.fieldName] !== null &&
                        item[el.fieldName].name
                      ? item[el.fieldName].name
                      : item[el.fieldName],
                  dateField: el.fieldName === "createdAt",
                    }}
                  />
                ))}
                {/* Menu Action Icon */}
                <MenuIcon {...{ handleMenuClick, field: item }} />
              </Box>
            ))
          )}
        </Box>
      </TableContainer>
      {/* Pagination */}
      {totalPages>1 && (
        <PaginationBox
          {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
        />
      )}
      <MenuActions
        {...{
          id: element?._id,
          handleView,
          handleEdit,
          handleDelete,
          anchorEl,
          handleMenuClose,
        }}
      />
      {/*Add,Edit,Delete Icons & function calls */}
    </Box>
  );
};

export default Refrigeration;
