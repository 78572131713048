import styled from "styled-components";
export const Container = styled.div`
    //  display: flex;
height:"100vh"
`;

export const Wrapper = styled.div`
  
`;
export const TimelineSection = styled.div`
    width: 100%;
    // max-width: 1000px;
    // margin-top: 10px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // gap: 12px;
`;
  export const Maindocment = styled.div`
  display:none;
  gap:20px;
`

export const Company = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-top:-4px; 
    padding-left:10px;
    width:110px;
    color: ${({ theme }) => theme.text_primary + 99};
`
export const Kickoff = styled.div`
   font-size: 14px;
    font-weight: 400;
    margin-top:-4px; 
    padding-left:10px;
    width:110px;
    position:relative;
    color: ${({ theme }) => theme.text_primary + 99};
`
export const Dropdownrender = styled.div`
position:relative;
`;