import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../../utils/api";
import { companyId } from "../../../../../utils/companyId";
import InputField from "../../../../../Components/Masters/InputField";
import RadioField from "../../../../../Components/Masters/RadioField";
import FileField from "../../../../../Components/Masters/FileField";
import SelectField from "../../../../../Components/Masters/SelectField";
import DateField from "../../../../../Components/Masters/DateField";
import { validateNumber } from "../../../../../utils/validateFields";
import { numberError } from "../../../../../utils/validationMessage";

function AddData({
  inputData,
  fetchData,
  id,
  data,
  element,
  setAddData,
  addData,
  setsnackOpen,
  addUrl,
  updateUrl,
}) {
  const [errors, setErrors] = useState({});
  const [upload, setUpload] = useState("yes");
  const [transportType, setTransportType] = useState("");

  // Dynamically build initial state based on inputData
  const initialState = {
    transportType: "",
    ownership: "",
    vehicleNumber: "",
    vehicleCategory: "",
    vehicleType: "",
    brand: "",
    model: "",
    purchaseDate: "",
    lastServiceDate: "",
    fuelType: "",
    declaredEfficiency: "",
    bharatStageEmissionType: "",
    loadCapacity: "",
    passengerCapacity: "",
    uploadDocuments: "yes", // Defaulting to "yes" if undefined
    pucDocs: [],
    pollutionDocs: [],
    rcDocs: [],
    insuranceDocs: [],
    company: companyId, // Assuming `companyId` is a fallback
    logisticsVendor: "", // Assuming it's part of the `data
  };

  const [uploadProgress, setUploadProgress] = useState({}); // Holds progress for each file
  const [formData, setFormData] = useState(initialState);
  const [uploadedFiles, setUploadedFiles] = useState({
    pucDocs: [],
    pollutionDocs: [],
    rcDocs: [],
    insuranceDocs: [],
  });

  const [docError, setDocError] = useState({
    pucDocs: [],
    pollutionDocs: [],
    rcDocs: [],
    insuranceDocs: [],
  });

  useEffect(() => {
    console.log(element);
    if (id && element) {
      // If we're editing existing data, set the form data and uploaded files
      resetForm();
    } else if (!id && !element) {
      setFormData(initialState);
      // If we're adding new data, reset the form and uploaded files
      setUploadedFiles({
        pucDocs: [],
        pollutionDocs: [],
        rcDocs: [],
        insuranceDocs: [],
      });
      setUpload("yes"); // Set to "yes" or whatever default you want
    }
  }, [element, id]);

  const handleChange = (e) => {
    const { name, value } = e.target; // Correctly extract name and value from e.target

    console.log(`Input changed: ${name}, Value: ${value}`);

    // Clear the specific error for the field being changed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined, // Remove error for the specific field
    }));

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "vehicleNumber") {
      const regex = new RegExp(`^${value}$`, "i"); // 'i' flag makes it case-insensitive
      const vehicle = data.filter((el) => regex.test(el.vehicleNumber));

      // Check if the GST Number already exists and the IDs don't match (or if no GST record exists, skip the validation)
      if (
        vehicle.length > 0 &&
        (element ? element._id !== vehicle[0]._id : true)
      ) {
        setErrors({
          ...errors,
          [name]: "Vehicle Number already exists.",
        });
      }

      return;
    }

    // Handling specific conditions based on field names
    if (name === "transportType") {
      setTransportType(value);
      if (value === "Passenger") {
        setFormData((prev) => ({
          ...prev,
          loadCapacity: "",
          vehicleCategory: "",
        }));
      } else if (value === "Freight") {
        setFormData((prev) => ({
          ...prev,
          passengerCapacity: "",
          ownership: "",
          // logisticsVendor:prev.logisticsVendor ||  "",
        }));
      }
    }

    // Handling specific conditions based on field names
    if (name === "ownership") {
      // If ownership is set to "Leased", show the logisticsVendor field
      if (value !== "Leased") {
        setFormData((prev) => ({
          ...prev,
          logisticsVendor: "", // Make sure logisticsVendor is set if it's not already
        }));
      }
      // else {
      //   setFormData((prev) => ({
      //     ...prev,
      //     logisticsVendor: "", // Clear logisticsVendor when it's not Leased
      //   }));
      // }
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e; // Correctly extract name and value from e.target

    console.log(`Input changed: ${name}, Value: ${value}`);

    // Clear the specific error for the field being changed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined, // Remove error for the specific field
    }));

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;

    // Clear previous errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: [], // Initialize as an empty array to accumulate errors
    }));

    // Clear document errors
    setDocError((prevErrors) => ({
      ...prevErrors,
      [name]: [], // Initialize as an empty array to accumulate errors
    }));

    if (!files || files.length === 0) return;

    const newErrors = [];
    const uploadedFiles = []; // Store the uploaded files
    const progressTracker = Array(files.length).fill(0); // Initialize an array for tracking progress
    const currentUploadedFiles = formData[name] || []; // Existing uploaded files

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check if the total count exceeds the limit
      if (currentUploadedFiles.length + uploadedFiles.length > 5) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: ["You can only upload a maximum of 5 files."],
        }));
        return; // Exit the loop if limit is reached
      }

      const allowedTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.ms-excel", // .xls,
        "image/png",
      ];
      const maxFileSize = 5 * 1024 * 1024; // 5 MB

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        newErrors.push(`File type not allowed: ${file.name}`);
        continue; // Continue checking the next file
      }

      // Check file size
      if (file.size > maxFileSize) {
        newErrors.push(`File size exceeds 5MB: ${file.name}`);
        continue; // Continue checking the next file
      }

      // Create a FormData object
      const formData2 = new FormData();
      formData2.append("files", file);

      try {
        // Upload the file
        const res = await axios.post(`${api}/cloud/upload`, formData2, {
          onUploadProgress: (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            progressTracker[i] = percent; // Update progress for the specific file
            setUploadProgress((prev) => ({ ...prev, [name]: progressTracker })); // Update state
          },
        });

        // Append newly uploaded file URLs
        uploadedFiles.push(...res.data.urls); // Use spread operator to add URLs
      } catch (error) {
        newErrors.push(`Failed to upload ${file.name}. Please try again.`);
      }
    }

    // Update uploaded files state only if there are valid uploads
    if (uploadedFiles.length > 0) {
      setUploadedFiles((prev) => ({
        ...prev,
        [name]: [...prev[name], ...uploadedFiles],
      }));
    }

    setUploadProgress((prev) => ({ ...prev, [name]: [] })); // Reset progress after upload

    // Update errors if any
    if (newErrors.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: newErrors, // Store all accumulated errors for this field
      }));
    }
  };

  const handleClose = () => {
    setAddData(false);
    setErrors({});
    setDocError({});
    if (id && element) resetForm();
    else {
      setUpload("yes");
      setFormData({});
    }
  };

  const resetForm = () => {
    setFormData({
      ...element,
      logisticsVendor: element.logisticVendor?._id || "",
    });
    setUploadedFiles({
      pucDocs: element.pucDocs || [],
      pollutionDocs: element.pollutionDocs || [],
      rcDocs: element.rcDocs || [],
      insuranceDocs: element.insuranceDocs || [],
    });
    setUpload(
      element.pucDocs?.length > 0 &&
        element.pollutionDocs?.length > 0 &&
        element.rcDocs?.length > 0 &&
        element.insuranceDocs?.length > 0
        ? "yes"
        : "no"
    );
  };

  const handleRadioChange = (e) => {
    setUpload(e.target.value);
    if (e.target.value === "no")
      setErrors({
        ...errors,
        pucDocs: "",
        insuranceDocs: "",
        pollutionDocs: "",
        rcDocs: "",
      });
  };

  const validateForm = () => {
    const newErrors = {}; // Create a copy of the current errors to preserve previous ones
    inputData.forEach((input) => {
      const value = formData[input.fieldName];
console.log(input.fieldName,)
      // Skip validation for upload documents fields if not required
      if (input.fieldName.endsWith("Docs")) {
        console.log(upload);
        // Check if files have been uploaded for this field
        if (upload === "no") return;
        if (upload === "yes") {
          if (
            !uploadedFiles[input.fieldName] ||
            uploadedFiles[input.fieldName].length === 0
          ) {
            newErrors[input.fieldName] = `Please upload ${input.title} `;
          }
        }
        return; // Skip other validations for this input
      }

      // Skip ownership validation if transportType is Freight
      if (
        formData.transportType === "Freight" &&
        input.fieldName === "ownership"
      ) {
        return; // Skip this validation
      }

      // Check for lastServiceDate not being earlier than purchaseDate
      if (input.fieldName === "lastServiceDate" && formData.purchaseDate) {
        const purchaseDate = new Date(formData.purchaseDate);
        const lastServiceDate = new Date(value);

        if (lastServiceDate < purchaseDate) {
          newErrors.lastServiceDate =
            "Last service date cannot be earlier than purchase date.";
        }
      }
      // Handle transport type specific validations
      if (
        formData.transportType === "Passenger" &&
        (input.fieldName === "loadCapacity" ||
          input.fieldName === "vehicleCategory")
      ) {
        return;
      }
      if (
        formData.transportType === "Freight" &&
        input.fieldName === "passengerCapacity"
      ) {
        return;
      }
      if (
        formData.ownership === "Company owned" &&
        input.fieldName === "logisticsVendor"
      ) {
        return;
      }

      // Check for empty strings for fields that should be filled
      if (typeof value === "string" && (!value || value.trim() === "")) {
        newErrors[input.fieldName] = `${input.title} is required.`;
        return; // Skip other validations for this input
      }

      if (
        input.fieldName === "declaredEfficiency" &&
        !validateNumber(formData[input.fieldName])
      ) {
        newErrors[input.fieldName] = numberError;
        // return; // Skip other validations for this input
      }

      // Additional validations based on transport type
      if (formData.transportType === "Passenger") {
        if (input.fieldName === "ownership" && !formData.ownership) {
          newErrors.ownership = "Ownership is required.";
        }
        if (
          input.fieldName === "passengerCapacity" &&
          !validateNumber(formData.passengerCapacity)
        ) {
          newErrors.passengerCapacity = numberError;
        }

        // if (
        //   input.fieldName === "logisticsVendor" &&
        //   formData.ownership === "Leased" &&
        //   !formData.associatedVendor
        // ) {
        //   newErrors.associatedVendor = "Logistics vendor is required.";
        // }
        if (
          input.fieldName === "logisticsVendor" &&
          // formData.ownership === "Leased" &&
          !formData.logisticsVendor
        ) {
          newErrors.logisticsVendor = "Logistics vendor is required.";
        }
      } else if (formData.transportType === "Freight") {
        if (input.fieldName === "loadCapacity" && !formData.loadCapacity) {
          newErrors.loadCapacity = "Load capacity is required";
        }

        if (
          input.fieldName === "loadCapacity" &&
          !validateNumber(formData.loadCapacity)
        ) {
          newErrors.loadCapacity = numberError;
        }
      }

      if (input.fieldName === "vehicleNumber") {
        const regex = new RegExp(`^${value}$`, "i"); // 'i' flag makes it case-insensitive
        const vehicle = data.filter((el) => regex.test(el.vehicleNumber));

        // Check if the GST Number already exists and the IDs don't match (or if no GST record exists, skip the validation)
        if (
          vehicle.length > 0 &&
          (element ? element._id !== vehicle[0]._id : true)
        ) {
          newErrors.vehicleNumber = "Vehicle Number already exists";
        }
      }
    });
    console.log(newErrors); // Log newErrors to see current validation results
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const filterFormData = (data) => {
    const filteredData = { ...data, ...uploadedFiles };

    console.log(filteredData);

    // Remove unnecessary fields based on conditions
    if (data.transportType === "Passenger") {
      delete filteredData.loadCapacity; // Don't send loadCapacity
      delete filteredData.vehicleCategory;
    }

    if (data.transportType === "Freight") {
      delete filteredData.passengerCapacity; // Don't send passengerCapacity
    }

    if (data.ownership === "Company owned") {
      delete filteredData.logisticsVendor;
    }

    // Optionally remove uploadDocuments if not necessary
    if (upload === "no") {
      Object.keys(filteredData).forEach((key) => {
        if (key.endsWith("Docs")) {
          delete filteredData[key]; // Don't send document fields
        }
      });
    }

    return filteredData;
  };

  const handleDeleteFile = (fieldName, fileUrl) => {
    setUploadedFiles((prevUploadedFiles) => ({
      ...prevUploadedFiles,
      [fieldName]: prevUploadedFiles[fieldName].filter(
        (file) => file !== fileUrl
      ), // Filter out the specific file
    }));
  };

  const Save = async () => {
    if (!validateForm()) return;

    console.log(formData);
    const dataToSend = filterFormData(formData); // Filter form data based on conditions

    console.log(dataToSend); // Log the data being sent

    setErrors({});
    setDocError({});
    await axios({
      method: id ? "put" : "post",
      url: id ? updateUrl : addUrl,
      data: dataToSend, // Use filtered data
      params: id ? { id } : null,
    })
      .then((res) => {
        fetchData();
        setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        // setFormData({})
        setUploadedFiles({
          pucDocs: [],
          pollutionDocs: [],
          rcDocs: [],
          insuranceDocs: [],
        });
        setUpload("yes");
        handleClose();
      })
      .catch((err) => {
        console.log(err.response.data.message);
        if (err?.response?.data?.message) {
          setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const renderInputField = (el) => {
    if (el.fieldName === "uploadDocuments") {
      return (
        <RadioField
          handleChange={handleRadioChange}
          title={el.title}
          formData={formData}
          value={upload}
          fieldName={el.fieldName}
          errors={errors}
        />
      );
    }

    if (el.fieldName === "vehicleType") {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          options={
            formData.vehicleCategory
              ? (formData.vehicleCategory ===
                  "Heavy Commercial Vehicles (HCV)" && [
                  "Heavy Duty Trucks",
                  "Tanker Trucks",
                  "Refrigerated trucks",
                ]) ||
                (formData.vehicleCategory ===
                  "Medium Commerical Vehicles (MCV)" && [
                  "Medium Duty trucks",
                  "Flatbed trucks",
                ]) ||
                (formData.vehicleCategory ===
                  "Light Commerical Vehicles (LCV)" && ["Light duty trucks"])
              : [
                  "Sedan",
                  "SUV",
                  "Hatchback",
                  "Motorcycle / Scooter",
                  "Bus - Heavy",
                  "Bus - Medium ",
                ]
          }
          errors={errors[el.fieldName]}
          url={el.url}
          field={el.field || ""}
        />
      );
    }
    if (el.type === "select") {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          options={el.options}
          errors={errors[el.fieldName]}
          url={el.url}
          field={el.field || ""}
        />
      );
    }

    if (el.type === "date") {
      return (
        <DateField
          title={el.title}
          name={el.fieldName}
          lastServiceDate={formData.lastServiceDate}
          purchaseDate={formData.purchaseDate}
          value={formData[el.fieldName]}
          handleChange={handleDateChange}
          errors={errors[el.fieldName]}
        />
      );
    }

    if (el.fieldName.endsWith("Docs")) {
      return (
        <FileField
          title={el.title}
          name={el.fieldName}
          handleChange={handleFileChange}
          errors={errors[el.fieldName] || docError[el.fieldName] || []}
          fileUrls={uploadedFiles[el.fieldName] || []}
          progress={uploadProgress[el.fieldName] || []}
          // {...{setUploadedFiles,setUploadProgress,setErrors}}
          handleDelete={handleDeleteFile}
        >
          {/* {uploadedFiles[el.fieldName]?.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Already Uploaded Documents:</Typography>
            {renderUploadedFiles(el.fieldName)}
          </Box>
        )} */}
        </FileField>
      );
    }

    return (
      <InputField
        title={el.title}
        handleChange={handleChange}
        placeholder={el.placeholder}
        value={formData[el.fieldName] || ""}
        errors={errors[el.fieldName]}
        fieldName={el.fieldName}
      />
    );
  };

  const list = () => (
    <Box display="flex" gap={3} sx={{ width: "400px" }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {
            setAddData(false);
            setErrors({});
          }}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "90%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {id ? "Edit Vehicle" : "Add Vehicle"}
        </Typography>
        {inputData.map((el) => {
          const shouldRender = el.condition === undefined || el.condition;
          if (
            el.fieldName === "ownership" &&
            (transportType === "Freight" ||
              formData?.transportType === "Freight")
          ) {
            return null;
          }
          if (
            el.fieldName === "vehicleCategory" &&
            (transportType === "Passenger" ||
              formData?.transportType === "Passenger")
          ) {
            return null;
          }
          if (
            el.fieldName === "loadCapacity" &&
            (transportType === "Passenger" ||
              formData?.transportType === "Passenger")
          ) {
            return null;
          }
          if (
            el.fieldName === "passengerCapacity" &&
            (transportType === "Freight" ||
              formData?.transportType === "Freight")
          ) {
            return null;
          }
          if (
            el.fieldName === "logisticsVendor" &&
            formData?.ownership === "Company owned"
          ) {
            return null;
          }
          if (el.fieldName.endsWith("Docs") && upload === "no") return null;

          if (shouldRender) {
            return <div key={el.fieldName}>{renderInputField(el)}</div>;
          }
          return null;
        })}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      sx={{
        backgroundColor: "transparent",
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          height: "100vh",
          minHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
      anchor="right"
      open={addData}
      onClose={handleClose}
    >
      {list()}
    </Drawer>
  );
}

export default AddData;
