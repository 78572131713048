import React, { useEffect, useState } from "react";
import ViewTable from "../../../../Components/Common/ViewTable";
import { get } from "../../../../utils/api";
import Cards from "../../../../Components/Common/Cards";
import FilterModal from "../../../../Components/Common/FilterModal";
import Form from "./Form";
import View from "./View";
import ActionButtons from "../../../../Components/Common/ActionButtons";

const RailTransport = () => {
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterObj, setFilterObj] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);

  const headers = [
    { key: "createdAt", label: "Date", sortable: true },
    { key: "facilityName", label: "Facility", sortable: true },
    { key: "origin", label: "Source", sortable: true },
    {
      key: "destination",
      label: "Destination",
      sortable: true,
    },
    { key: "freightLoad", label: "Freight Load (Tonnes)", sortable: true },
    { key: "unit", label: "Distance Travelled (Km)", sortable: true },
  ];

  let bannerData = [
    {
      title: "Most Common Rail Route",
      description: ["Delhi", "Pune"],
    },
    {
      title: "Total Freight Load",
      description: "10000 MT",
    },
    {
      title: "Total Distance Travelled",
      description: "1000 Km",
    },
  ];

  const filterData = [
    { title: "Date", type: "date", key: "createdAt", value: [] },
    {
      title: "Origin",
      type: "option",
      key: "origin",
      value: ["one", "two"],
    },
    {
      title: "Destination",
      type: "option",
      key: "destination",
      value: ["one", "two"],
    },
    {
      title: "Freight Load (Tonnes)",
      type: "limit",
      key: "load",
      value: ["one", "two"],
    },
  ];

  const fetchData = async () => {
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: 10,
      sortField: sortConfig.key,
      sortOrder: sortConfig.direction,
    }).toString();
    await get(`/scope3/railTransportation/get?${queryParams}`)
      .then((res) => {
        console.log(res?.data);
        let arr = res?.data?.data?.map((item) => {
          return {
            createdAt: new Date(item.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
            facilityName: item?.facility?.facilityName,
            origin: item?.origin,
            destination: item?.destination,
            freightLoad: item?.freightLoad,
            remark: item?.remark,
            action: "View",
            id: item?._id,
            data: item,
          };
        });
        setTableData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [sortConfig]);

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      dataForEdit.createdAt = dataForEdit?.data?.createdAt.split("T")[0];
      setEditData(dataForEdit);
    }
  };

  const openViewModal = (data) => {
    data.createdAt = data?.data?.createdAt.split("T")[0];
    setEditData(data);
    setViewModal(true);
  };

  const handleSort = (key, direction) => {
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (newFilterObj) => {
    setFilterObj(newFilterObj);
  };

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleSubmit = (data) => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Cards data={bannerData} title={"Rail Transport"} />

      <ActionButtons
        onFilterClick={() => setIsFilterModalOpen(true)}
        onAddClick={() => openModal("add")}
      />

      <ViewTable
        headers={headers}
        data={tableData ?? []}
        onSort={handleSort}
        sortConfig={sortConfig}
        page={currentPage}
        count={pageSize}
        onPageChange={handlePageChange}
        onView={(row) => {
          openViewModal(row);
        }}
        onEdit={(row) => openModal("edit", row)}
        onDelete={() => {
          console.log("first");
        }}
      />

      <Form
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        header={editModal ? "Edit Data" : "Add Data"}
        initialData={editData}
        isEditing={editModal}
      />

      <View
        isOpen={viewModal}
        onClose={() => setViewModal(false)}
        header={"View Data"}
        initialData={editData}
        handleEdit={(row) => openModal("edit", row)}
      />

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        filters={filterData}
        onFilterChange={handleFilterChange}
        title="Filter"
      />
    </>
  );
};

export default RailTransport;
