import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import style from "../../styles/common/filter.module.css";

const FilterModal = ({ isOpen, onClose, filters, onFilterChange, title }) => {
  const [filterObj, setFilterObj] = useState({});
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    if (filters.length > 0) {
      setSelectedFilter(filters[0]);
    }
  }, [filters]);

  const handleOptionChange = (key, option, checked) => {
    setFilterObj((prev) => {
      const selectedOptions = prev[key] || [];
      const updatedOptions = checked
        ? [...selectedOptions, option]
        : selectedOptions.filter((item) => item !== option);
      return { ...prev, [key]: updatedOptions };
    });
  };

  const handleDateChange = (key, dateType, value) => {
    const formattedDate = value
      ? new Date(value).toISOString().split("T")[0]
      : "";
    setFilterObj((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [dateType]: formattedDate,
      },
    }));
  };

  const handleLimitChange = (key, type, value) => {
    setFilterObj((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [type]: value,
      },
    }));
  };

  const applyFilters = () => {
    onFilterChange(filterObj);
    onClose();
  };

  const handleReset = () => {
    setFilterObj({});
    onFilterChange({});
  };

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-title">
      <Box className={style.main_div}>
        <div style={{ width: "30%", backgroundColor: "#488257" }}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            style={{
              textDecoration: "underline",
              color: "white",
              padding: "1rem",
            }}
          >
            {title}
          </Typography>
          <Grid item xs={4} style={{}}>
            <div>
              {filters.map((filter) => (
                <ListItem key={filter.key} disablePadding>
                  <ListItemButton
                    onClick={() => setSelectedFilter(filter)}
                    selected={selectedFilter?.key === filter.key}
                    sx={{
                      backgroundColor:
                        selectedFilter?.key === filter.key
                          ? "#ffffff"
                          : "inherit",
                      "&.Mui-selected": {
                        backgroundColor: "#ffffff",
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={filter.title}
                      sx={{
                        color:
                          selectedFilter?.key === filter.key
                            ? "#488257"
                            : "#ffffff",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </div>
          </Grid>
        </div>

        <div
          style={{
            width: "70%",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={8}>
            {selectedFilter && (
              <div>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#488257",
                    fontSize: "24px",
                    padding: "0 0 1rem 1rem",
                    fontWeight: "700",
                  }}
                >
                  {selectedFilter.title}
                </Typography>
                {selectedFilter.type === "option" ? (
                  <FormGroup>
                    {selectedFilter.value.map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={
                              filterObj[selectedFilter.key]?.includes(option) ||
                              false
                            }
                            onChange={(e) =>
                              handleOptionChange(
                                selectedFilter.key,
                                option,
                                e.target.checked
                              )
                            }
                          />
                        }
                        label={option}
                      />
                    ))}
                  </FormGroup>
                ) : selectedFilter.type === "date" ? (
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <TextField
                      type="date"
                      sx={{ width: "40%" }}
                      onChange={(e) =>
                        handleDateChange(
                          selectedFilter.key,
                          "start",
                          e.target.value
                        )
                      }
                      value={filterObj[selectedFilter.key]?.start || ""}
                      name="start"
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      type="date"
                      sx={{ width: "40%" }}
                      value={filterObj[selectedFilter.key]?.end || ""}
                      onChange={(e) =>
                        handleDateChange(
                          selectedFilter.key,
                          "end",
                          e.target.value
                        )
                      }
                      name="end"
                      variant="outlined"
                      size="small"
                    />
                  </div>
                ) : selectedFilter.type === "limit" ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      // alignItems: "center",
                    }}
                  >
                    <TextField
                      type="text"
                      sx={{ width: "40%" }}
                      onChange={(e) =>
                        handleLimitChange(
                          selectedFilter.key,
                          "min",
                          e.target.value
                        )
                      }
                      value={filterObj[selectedFilter.key]?.min || ""}
                      name="min"
                      variant="outlined"
                      size="small"
                    />
                    <p>To</p>
                    <TextField
                      type="text"
                      sx={{ width: "40%" }}
                      onChange={(e) =>
                        handleLimitChange(
                          selectedFilter.key,
                          "max",
                          e.target.value
                        )
                      }
                      value={filterObj[selectedFilter.key]?.max || ""}
                      name="max"
                      variant="outlined"
                      size="small"
                    />
                  </div>
                ) : null}
              </div>
            )}
          </Grid>
          <Box display="flex" justifyContent="space-evenly" mt={2}>
            <Button
              variant="contained"
              onClick={applyFilters}
              sx={{ width: "30%", backgroundColor: "#488257" }}
            >
              Apply
            </Button>
            <Button
              variant="outlined"
              onClick={handleReset}
              sx={{ width: "30%" }}
            >
              Reset
            </Button>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default FilterModal;
