import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Toolbar,
  Typography,
  Box,
  PaginationItem,
  Grid,
} from "@mui/material";
import axios from "axios";
import { api } from "../../../../utils/api";
import { styled } from "@mui/material/styles";
// import VehicleFilter from "../../Roles and Permissions/Filters/VehicleFilter";
import PaginationBox from "../../../../Components/Masters/Pagination";
import DeleteDialog from "../../../../Components/Masters/DeleteDialog";
import ColumnHeadings from "../../../../Components/Masters/ColumnHeadings";
import MenuActions from "../../../../Components/Masters/MenuActions";
import TableContent from "../../../../Components/Masters/TableContent";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import MessagePopup from "../../../../Components/Masters/MessagePopup";
import TableHeading from "../../../../Components/Masters/TableHeading";
import MenuIcon from "../../../../Components/Masters/MenuIcon";
import { companyId } from "../../../../utils/companyId";
import AddOnRoadVahicle from "./AddVehicle";
import ViewOnRoadVahicle from "./View";
import DateRangePickerComponent from "../../../Masters/Roles and Permissions/Filters/Date";
import MultiSelect from "../../../Masters/Roles and Permissions/Filters/MultiSelect";
import Range from "../../../Masters/Roles and Permissions/Filters/Range";
import SelectCard from "./SelectCard";
import PieChartCard from './PieChartCard';
import NoDataFound from "../../../../Components/Masters/NoDataFound";

const OnRoadVahicle = () => {
    const [selectedPeriodCard1, setSelectedPeriodCard1] = useState("Biofuel blend petrol");
    const [selectedPeriodCard2, setSelectedPeriodCard2] = useState("July");
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addData, setAddData] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    "OwnerShip Type": false,
    "Vehicle Type": false,
    "Fuel Type": false,
    "Fuel Consumed": false,
    "Distance Covered": false,
  });
  const [viewData, setViewData] = useState(false);
  const [element, setElement] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [errors, setErrors] = useState({
    Date: false,
    "OwnerShip Type": false,
    "Vehicle Type": false,
    "Fuel Type": false,
    "Fuel Consumed": false,
    "Distance Covered": false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    ownershipType: [],
    vehicleType: [],
    fuelType: [],
  });
  const [quantityRange, setQuantityRange] = useState({ min: "", max: "" });
  const [distanceRange, setDistanceRange] = useState({ min: "", max: "" });




  const filters = [
    "Date",
    "OwnerShip Type",
    "Vehicle Type",
    "Fuel Consumed",
    "Fuel Type",
    "Distance Covered",
  ];



  const inputData = [
    {
      fieldName: "ownershipType",
      title: "OwnerShip Type",
      placeholder: "Select Ownership Type",
      type: "select",
      options: [
        "Company Owned",
        "Leased"
      ],
      required: true,
    },
    {
      fieldName: "vehicleType",
      title: "Vehicle Type",
      placeholder: "Select Vehicle Type",
      type: "select",
      options: [
       "Passenger Cars",
       "Trucks", 
       "Buses", 
       "Vans", 
       "Motorcycles"
      ],
      required: true,
    },
    {
      fieldName: "vehicleNumber",
      title: "Vehicle Number",
      placeholder: "Type here",
      type: "String",
      required: true,
    },
    {
      fieldName: "logisticsVendor",
      title: "Logistic Vendor",
      placeholder: "Select Logistic Vendor",
      type: "select",
      url: `${api}/master/logistic/vendorMaster`,
      field: "logisticVendorLegalName",
      required: true,
    },
    {
      fieldName: "fuelType",
      title: "Fuel Type",
      placeholder: "Select Fuel Type",
      type: "select",
      options: [
        "Petrol (biofuel blend)",
        "Petrol (mineral petrol)",
        "Diesel (biofuel blend)",
        "Diesel (mineral diesel)",
        "CNG",
        "Electricity",
       ],
      required: true,
    },
    {
      fieldName: "fuelConsumed",
      title: "Fuel Consumed",
      placeholder: "Enter Fuel Consumed ",
      type: "number",
      condition: true,
    },
    {
      fieldName: "distanceCovered",
      title: "Distance Covered",
      placeholder: "Km",
      type: "number",
      required: true,
    },
    {
      fieldName: "remarks",
      title: "Remark",
      placeholder: "Additional remarks",
      type: "text",
    },
    {
      fieldName: "documents",
      title: "Upload Documents",
      type: "file",
    },
  ];

  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(
        `${api}/scope1/mobileCombustion/getAllOnRoadVehicles`
      );
      const data = response.data.data;
      console.log(data);

      const uniqueOwnershipType = [
        ...new Set(
          data
            .map((el) => el.ownershipType)
            .filter(Boolean) // Filter out undefined values
        ),
      ];

      console.log(uniqueOwnershipType);

      const uniqueVehicleType = [
        ...new Set(
          data.map((el) => el.vehicleType).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniqueFuelType = [
        ...new Set(
          data.map((el) => el.fuelType).filter(Boolean) // Filter out undefined values
        ),
      ];

      return {
        ownershipType: uniqueOwnershipType,
        vehicleType: uniqueVehicleType,
        fuelType: uniqueFuelType,
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  // fetching Vehicle data
  const fetchData = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    console.log(filters);
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
        company: companyId,
        createdBy: companyId,
      }).toString();
      const response = await axios.get(
        `${api}/scope1/mobileCombustion/getAllOnRoadVehicles?${queryParams}`
      );
      setData(response.data.data); // Update the user list
      console.log(response.data.data);
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortConfig]);

  const handleMenuClick = (event, element) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(element);
    setElement(element);
    console.log(element);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedData(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddData(true);
    setViewData(false);
  };

  const handleView = () => {
    handleMenuClose();
    setViewData(true);
    setAddData(false);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = async () => {
    // console.log("indexhandleDeleteAggree", vehicle, vehicle._id);
    try {
      console.log(element._id);
      const res = await axios.put(
        `${api}/scope1/mobileCombustion/deleteOnRoadVehicles?Id=${element._id}`
      );
      setDeleteDialog(false);
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
    } catch (err) {
      console.log(err);
      setsnackOpen({
        open: true,
        message: err.data?.response.message ?? "network Error",
        severity: "error",
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filtersToApply = {
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        acc[key] = selectedOptions.join(","); // Change to an array if needed
      }
      return acc;
    }, {}),
    minFuelConsumed: quantityRange.min || "",
    maxFuelConsumed: quantityRange.max || "",
    minDistance: distanceRange.min || "",
    maxDistance: distanceRange.max || "",
  };

  
  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
      "OwnerShip Type": false,
      "Vehicle Type": false,
      "Fuel Type": false,
      "Fuel Consumed": false,
      "Distance Covered": false,
    };

    console.log(selectedFilter, "hi");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select start date & end date";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }

    if (
      selectedFilter === "OwnerShip Type" &&
      selectedFields.ownershipType.length === 0
    ) {
      newErrors["OwnerShip Type"] =
        "Please select atleast one Industrial Processs";
      isValid = false;
    }

    if (
      selectedFilter === "Fuel Type" &&
      selectedFields.fuelType.length === 0
    ) {
      newErrors["Fuel Type"] =
        "Please select atleast one Fuel Type";
      isValid = false;
    }

    if (
      selectedFilter === "Vehicle Type" &&
      selectedFields.vehicleType.length === 0
    ) {
      newErrors["Vehicle Type"] = "Please select atleast one Vehicle Type";
      isValid = false;
    }

    if (selectedFilter === "Fuel Consumed") {
      const min = parseFloat(quantityRange.min);
      const max = parseFloat(quantityRange.max);

      if (!min && !max) {
        newErrors["Fuel Consumed"] =
          "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Fuel Consumed"] =
          "Min consumption must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Fuel Consumed"] =
          "Max consumption must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Fuel Consumed"] =
          "Min consumption cannot be greater than max consumption";
        isValid = false;
      }
    }
    if (selectedFilter === "Distance Covered") {
      const min = parseFloat(distanceRange.min);
      const max = parseFloat(distanceRange.max);

      if (!min && !max) {
        newErrors["Distance Covered"] = "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Distance Covered"] = "Min hours must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Distance Covered"] = "Max hours must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Distance Covered"] =
          "Min hours cannot be greater than max consumption";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    console.log(selectedFields);
    if (!validateFilters()) return;
    setPage(1);
    fetchData(filtersToApply);
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
      ownershipType: [],
      vehicleType: [],
      fuelType: [],
    });
    setQuantityRange({ min: "", max: "" });
    setDistanceRange({ min: "", max: "" });

    // Call fetchData without any filters
    await fetchData({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate;
    const isFuelTypeApplied =
      selectedFields.fuelType?.length > 0;
    const isVehicleTypeApplied = selectedFields.vehicleType?.length > 0;
    const isOwnershipTypeApplied =
      selectedFields.ownershipType?.length > 0;
    const isQuantityRangeApplied = quantityRange.min && quantityRange.max;

    const isHoursRangeApplied = distanceRange.min && distanceRange.max;

    setIsFiltersApplied(
      isDateApplied ||
        isFuelTypeApplied ||
        isVehicleTypeApplied ||
        isQuantityRangeApplied ||
        isHoursRangeApplied ||
        isOwnershipTypeApplied
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      "OwnerShip Type": isOwnershipTypeApplied,
      "Vehicle Type": isVehicleTypeApplied,
      "Fuel Type": isFuelTypeApplied,
      "Fuel Consumed": isQuantityRangeApplied,
      "Distance Covered": isHoursRangeApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    // setErrors({ Date: false, Role: false });
  };

  const handlePeriodChangeCard1 = (event) => {
    setSelectedPeriodCard1(event.target.value);
  };
  
  const handlePeriodChangeCard2 = (event) => {
    setSelectedPeriodCard2(event.target.value);
  };

  const columnHeadings = [
    { title: "Date", fieldName: "createdAt" },
    { fieldName: "vehicleType", title: "Vehicle Type " },
    { fieldName: "fuelType", title: "Fuel Type" },
    { fieldName: "fuelConsumed", title: "Quantity Of Fuel Consumed" },
    { fieldName: "ownershipType", title: "OwnerShip Type" },
    { fieldName: "distanceCovered", title: "Distance Covered" },
  ];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/stationaryCombustion/getAllCoal`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
      case "OwnerShip Type":
        return (
          <MultiSelect
            title="OwnerShip Type"
            selectedField={selectedFields.ownershipType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["OwnerShip Type"]}
            setErrors={setErrors}
            field="ownershipType"
            data={uniqueValues.ownershipType}
          />
        );
      case "Vehicle Type":
        return (
          <MultiSelect
            title="Vehicle Type"
            url={`${api}/master/getAllEquipmentTypes`}
            selectedField={selectedFields.vehicleType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Vehicle Type"]}
            setErrors={setErrors}
            field="vehicleType"
            data={uniqueValues.vehicleType}
          />
        );
      case "Fuel Type":
        return (
          <MultiSelect
            title="Fuel Type"
            selectedField={selectedFields.fuelType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Fuel Type"]}
            setErrors={setErrors}
            field="fuelType"
            data={uniqueValues.fuelType}
          />
        );
      case "Fuel Consumed":
        return (
          <Range
            title="Fuel Consumed"
            selectedRange={quantityRange}
            setSelectedRange={setQuantityRange}
            placeholderMin="Min Consumed"
            placeholderMax="Max Consumed"
            errors={errors["Fuel Consumed"]}
            setErrors={setErrors}
          />
        );
      case "Distance Covered":
        return (
          <Range
            title="Distance Covered"
            selectedRange={distanceRange}
            placeholderMin="Min Covered"
            placeholderMax="Max Covered"
            setSelectedRange={setDistanceRange}
            errors={errors["Distance Covered"]}
            setErrors={setErrors}
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/stationaryCombustion/getAllCoal`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.date,
            }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <MessagePopup {...{ snackOpen, setsnackOpen }} />{" "}
      {/*Message popup for success or failure */}
      {/* View log, Edit, delete menus */}
      <AddOnRoadVahicle
        id={element ? element._id : ""}
        addData={addData}
        setAddData={setAddData}
        data={element}
        addUrl={`${api}/scope1/mobileCombustion/addOnRoadVehicles`}
        updateUrl={`${api}/scope1/mobileCombustion/updateOnRoadVehicles`}
        // details={vehicle}
        fetchData={fetchData}
        setsnackOpen={setsnackOpen}
        inputData={inputData}
      />
      <ViewOnRoadVahicle
        id={element ? element._id : ""}
        data={element}
        viewData={viewData}
        setViewData={setViewData}
        inputData={inputData}
        handleEdit={handleEdit}
      />
      <Grid item xs={6} sx={{ margin: "0% 5% -3% 23%", width: "100%" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Mobile Combustion
        </Typography>

     <Box sx={{ display: "flex", width: "83%", mt: 2, gap: 2 }}>
      {/* Card 1: Total Natural Gas Consumed */}
      <SelectCard title="Total Fuel Consumption" content="10,000 litre" handleChange={handlePeriodChangeCard1} value={selectedPeriodCard1} list={['Biofuel blend petrol', 'other']} />
      
      {/* Pie Chart */}
      <PieChartCard
        title="Ownership Fuel Consumption (L)"
        chartData={[100, 300]} // Example data
        labels={['Company Owned', 'Third Party Vendors']}
        backgroundColors={['#ff6384', '#36a2eb']}
      />

      {/* Card 3: Consumption Period */}
      <SelectCard title="Consumption Period" content="700 litre" handleChange={handlePeriodChangeCard2} value={selectedPeriodCard2} list={months} />
    </Box>
      </Grid>
      {deleteDialog && (
        <DeleteDialog
          {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
        />
      )}
      {/* Filter Drawer */}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setElement}
          setAddData={setAddData}
          {...{
            // heading: "Coal",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "3% 5% 0% 23%" }}
      >
        <Box>
          <TableHeading heading={"On Road Vehicles"} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "12% 15% 15% 19% 19% 12% 8%",
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            {columnHeadings.map((el) => (
              <ColumnHeadings
                key={el}
                {...{
                  title: el.title,
                  key: el.title,
                  handleSort,
                  field: el.fieldName,
                  sortConfig,
                }}
              />
            ))}
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {data.length === 0 ? (
            <NoDataFound />
          ) : (
          data &&
            data.map((item) => (
              <Box
                key={item._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "12% 15% 15% 19% 19% 12% 8%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {columnHeadings.map((el) => (
                  <TableContent
                    key={el.fieldName}
                    {...{
                    //   fieldData:
                    //     typeof item[el.fieldName] === "object" &&
                    //     item[el.fieldName] !== null &&
                    //     // item[el.fieldName].industryProcess
                    //     //   ? item[el.fieldName].industryProcess
                    //     //   : typeof item[el.fieldName] === "object" &&
                    //     //     item[el.fieldName] !== null &&
                    //         item[el.fieldName].name
                    //       ? item[el.fieldName].name
                    //       : item[el.fieldName],
                    //   dateField: el.fieldName === "createdAt",
                    fieldData:
                    el.fieldName === "fuelConsumed" 
                      ? `${item[el.fieldName]?.value || 0} ${item[el.fieldName]?.unit || ''}`
                      : typeof item[el.fieldName] === "object" &&
                        item[el.fieldName] !== null &&
                        item[el.fieldName].name
                      ? item[el.fieldName].name
                      : item[el.fieldName],
                  dateField: el.fieldName === "createdAt",
                    }}
                  />
                ))}
                {/* Menu Action Icon */}
                <MenuIcon {...{ handleMenuClick, field: item }} />
              </Box>
            ))
            )}
        </Box>
      </TableContainer>
      {/* Pagination */}
      {totalPages>1 && (
        <PaginationBox
          {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
        />
      )}
      <MenuActions
        {...{
          id: element?._id,
          handleView,
          handleEdit,
          handleDelete,
          anchorEl,
          handleMenuClose,
        }}
      />
      {/*Add,Edit,Delete Icons & function calls */}
    </Box>
  );
};

export default OnRoadVahicle;
