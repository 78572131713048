import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Toolbar,
  Typography,
  Box,
  PaginationItem,
} from "@mui/material";
import axios from "axios";
import { api } from "../../../../utils/api";
import { styled } from "@mui/material/styles";
// import VehicleFilter from "../../Roles and Permissions/Filters/VehicleFilter";
import DateRangePickerComponent from "../../Roles and Permissions/Filters/Date";
import PaginationBox from "../../../../Components/Masters/Pagination";
import DeleteDialog from "../../../../Components/Masters/DeleteDialog";
import ColumnHeadings from "../../../../Components/Masters/ColumnHeadings";
import MenuActions from "../../../../Components/Masters/MenuActions";
import TableContent from "../../../../Components/Masters/TableContent";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import MessagePopup from "../../../../Components/Masters/MessagePopup";
import TableHeading from "../../../../Components/Masters/TableHeading";
import MenuIcon from "../../../../Components/Masters/MenuIcon";
import MultiSelect from "../../Roles and Permissions/Filters/MultiSelect";
import { companyId } from "../../../../utils/companyId";
import AddWaste from "./AddWaste";
import ViewWaste from "./ViewWaste";
import NoDataFound from "../../../../Components/Masters/NoDataFound";

const WasteData = () => {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addData, setAddData] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    Facility: false,
    "Type of waste management": false,
  });
  const [viewData, setViewData] = useState(false);
  const [element, setElement] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [errors, setErrors] = useState({
    Date: false,
    Facility: false,
    "Type of waste management": false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    facility: [],
    wasteManagementType: [],
  });
  const filters = ["Date", "Facility", "Type of waste management"];

  const inputData = [
    {
      fieldName: "vendorLegalName",
      title: "Vendor Legal Name",
      placeholder: "Enter Name",
    },
    {
      fieldName: "facility",
      title: "Facility",
      placeholder: "Select type",
      type: "select",
      url: `${api}/master/getAllFacility`,
      field:"facilityName"
    },
    {
      fieldName: "wasteManagementType",
      title: "Type of Waste Management",
      type: "select",
      options: ["Recycling", "Landfilling", "Incineration", "Composting"],
      placeholder: "Select type",
    },
    {
      fieldName: "govtVendorRegistrationNumber",
      title: "Govt. Vendor Registration Number",
      placeholder: "Enter Registration Number",
    },
    {
      fieldName: "documents",
      title: "Upload Documents",
      type: "file",
    },
  ];

  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(`${api}/master/wasteManagement`);
      const data = response.data.data;
      console.log(data);

      const uniqueFacilities = [
        ...new Set(
          data.map((el) => el.facilityName).filter(Boolean) // Filter out undefined values
        ),
      ];

      const uniqueTypeofWasteGenerated = [
        ...new Set(
          data.map((el) => el.wasteManagementType).filter(Boolean) // Filter out undefined values
        ),
      ];

      return {
        facility: uniqueFacilities,
        wasteManagementType: uniqueTypeofWasteGenerated,
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  // fetching Vehicle data
  const fetchData = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    console.log(filters);
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      const response = await axios.get(
        `${api}/master/wasteManagement?${queryParams}`
      );

      setData(response.data.data); // Update the user list
      console.log(response.data);
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortConfig]);

  const handleMenuClick = (event, element) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(element);
    setElement(element);
    console.log(element);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedData(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddData(true);
    setViewData(false);
  };

  const handleView = () => {
    handleMenuClose();
    setViewData(true);
    setAddData(false);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = async () => {
    // console.log("indexhandleDeleteAggree", vehicle, vehicle._id);
    try {
      console.log(element._id);
      const res = await axios.delete(
        `${api}/master/wasteManagement/${element._id}`
      );
      setDeleteDialog(false);
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
    } catch (err) {
      console.log(err);
      setsnackOpen({
        open: true,
        message: err.data?.response.message ?? "network Error",
        severity: "error",
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filtersToApply = {
    company: companyId,
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        acc[key] = selectedOptions.join(","); // Change to an array if needed
      }

      return acc;
    }, {}),
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
      Facility: false,
      "Type of waste management": false,
    };

    console.log(selectedFilter, "hi");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select a date range";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }

    if (selectedFilter === "Facility" && selectedFields.facility.length === 0) {
      newErrors.Facility = "Please select Facility";
      isValid = false;
    }

    if (
      selectedFilter === "Type of waste management" &&
      selectedFields.wasteManagementType?.length === 0
    ) {
      newErrors["Type of waste management"] =
        "Please select Waste Management Type";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    if (!validateFilters()) return;
    setPage(1);
    fetchData(filtersToApply);
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
      country: [],
      wasteManagementType: [],
    });

    // Call fetchData without any filters
    await fetchData({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate ? true : false;
    const isFacilityApplied =
      selectedFields.facility?.length > 0 ? true : false;
    const isWasteManagementTypeApplied =
      selectedFields.wasteManagementType?.length > 0 ? true : false;

    setIsFiltersApplied(
      isDateApplied || isFacilityApplied || isWasteManagementTypeApplied
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      Facility: isFacilityApplied,
      "Type of waste management": isWasteManagementTypeApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    setErrors({ Date: false,
      Facility: false,
      "Type of waste management": false,});
  };

  const columnHeadings = [
    { title: "Date", field: "createdAt" },
    { title: "Vendor Legal Name", field: "vendorLegalName" },
    { title: "Facility", field: "facilityName" },
    { title: "Type of Waste Management", field: "wasteManagementType" },
    {
      title: "Govt. Vendor Registration Number",
      field: "govtVendorRegistrationNumber",
    },
  ];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/master/wasteManagement`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
      case "Facility":
        return (
          <MultiSelect
            title="Facility"
            url={`${api}/master/wasteManagement`}
            selectedField={selectedFields.facility}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors.Facility}
            setErrors={setErrors}
            field="facility"
            data={uniqueValues.facility}
          />
        );
      case "Type of waste management":
        return (
          <MultiSelect
            title="Type of waste management"
            url={`${api}/master/wasteManagement`}
            selectedFields={selectedFields}
            selectedField={selectedFields.wasteManagementType}
            setSelectedFields={setSelectedFields}
            errors={errors["Type of waste management"]}
            setErrors={setErrors}
            field="wasteManagementType"
            data={uniqueValues.wasteManagementType}
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/master/getAllVehicle`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <MessagePopup {...{ snackOpen, setsnackOpen }} />{" "}
      {/*Message popup for success or failure */}
      {/* View log, Edit, delete menus */}
      <AddWaste
        id={element ? element._id : ""}
        addData={addData}
        setAddData={setAddData}
        data={data}
        element={element}
        addUrl={`${api}/master/wasteManagement`}
        updateUrl={`${api}/master/wasteManagement`}
        // details={vehicle}
        fetchData={fetchData}
        setsnackOpen={setsnackOpen}
        inputData={inputData}
      />
      <ViewWaste
        id={element ? element._id : ""}
        data={element}
        viewData={viewData}
        setViewData={setViewData}
        inputData={inputData}
        handleEdit={handleEdit}
      />
      {deleteDialog && (
        <DeleteDialog
          {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
        />
      )}
      {/* Filter Drawer */}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setElement}
          setAddData={setAddData}
          {...{
            heading: "Waste Generated in Operations",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
        <Box>
          <TableHeading heading={"Waste Management Vendor"} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "12% 17% 17% 20% 25% 8%",
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            {columnHeadings.map((el) => (
              <ColumnHeadings
                key={el}
                {...{
                  title: el.title,
                  key: el.title,
                  handleSort,
                  field: el.field,
                  sortConfig,
                }}
              />
            ))}
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {data.length === 0 ? (
            <Box sx={{mt:"14%",mb:"14%"}}>
            <NoDataFound />
            </Box>
          ) : (
            data.map((item) => (
              <Box
                key={item._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "12% 17% 17% 20% 25% 8%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {columnHeadings.map((el) => (
                  <TableContent
                    key={el.field}
                    {...{
                      fieldData:
                       el.field==="facilityName"? item.facility.facilityName: item[el.field],
                      dateField: el.field === "createdAt",
                    }}
                  />
                ))}
                {/* Menu Action Icon */}
                <MenuIcon {...{ handleMenuClick, field: item }} />
              </Box>
            ))
          )}
        </Box>
      </TableContainer>
      {/* Pagination */}
      {totalPages>1  && (
        <PaginationBox
          {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
        />
      )}
      <MenuActions
        {...{
          id: element?._id,
          handleView,
          handleEdit,
          handleDelete,
          anchorEl,
          handleMenuClose,
        }}
      />
      {/*Add,Edit,Delete Icons & function calls */}
    </Box>
  );
};

export default WasteData;
