import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import './Filter.css';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const FilterSidebar = ({ setSelectedFilter, selectedFilter, filters, appliedFilters }) => {
  const [selectedItem, setSelectedItem] = useState("");
  const handleItemClick = (filter) => {
    setSelectedItem(filter); // Update selectedItem when clicked
    setSelectedFilter(filter); // Notify parent component
  };

  return (
    <Box sx={{ width: '35%', backgroundColor: '#488257', height: '100vh' }}>
      <Typography variant="h5" sx={{ color: 'white', mt: 1, p: 2 }}>Filter</Typography>
      <List sx={{ width: "100%" }}>
        {filters.map((filter) => (
          <ListItem
            key={filter}
            button
            sx={{ 
              color: 'white', 
              fontSize: '16px', 
              mb: 1, 
              backgroundColor: selectedItem === filter ? '#fff' : 'inherit', // Change background color when active
              color: selectedItem === filter ? '#488257' : 'white', // Change text color when active
              justifyContent: 'space-between', // Ensure space between text and icon
            }}
            onClick={() => handleItemClick(filter)}
            className={`list-item ${selectedItem === filter || selectedFilter === filter ? "active" : ""}`} // Add active class when selected
          >
            <ListItemText primary={filter} />
            
            {appliedFilters[filter] && (
              <ListItemIcon
                sx={{
                  fontSize: "10px",
                  color: selectedFilter === filter ? "#488257" : 'white',
                  minWidth: 'auto', // Prevents the default padding on ListItemIcon
                }}
              >
                <FiberManualRecordIcon 
                  sx={{
                    fontSize: "10px",
                    '&:hover': { color: '#FFFFFF' }, // Icon color on hover
                  }}
                />
              </ListItemIcon>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default FilterSidebar;
