import { Box, Card, CardContent,styled, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'

// Styled MenuItem for ellipsis
const EllipsisMenuItem = styled(MenuItem)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '300px',
}));
const SelectCard = ({title,content,list,value,width,handleChange, contentMarginTop = "18%"}) => {
  return (
    <Card sx={{ width: "30%", display: "flex", flexDirection: "column",height:"140px",borderRadius:"10px" }}>
    <CardContent sx={{ paddingBottom: "2px !important" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <FormControl variant="standard" sx={{width: width || "30%" }}>
          <Select
            value={value}
            onChange={handleChange}
            disableUnderline
            sx={{ 
              border: "1px solid #ccc", 
              borderRadius: "4px",     
              padding: "1px 6px", 
              fontSize:"13px"
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Set max height (around 5 items)
                  overflowY: 'auto', // Enable vertical scrolling
                },
              },
            }}
          >
            {list.map((el) => (
              <EllipsisMenuItem key={el} value={el} >
                {el}
              </EllipsisMenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography  sx={{ marginTop: contentMarginTop }}>{content}</Typography>
    </CardContent>
  </Card>
  )
}

export default SelectCard