import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import {
  Menu as MenuIcon,
  DashboardOutlined,
  SupportOutlined,
  DescriptionOutlined,
  ReceiptLongOutlined,
  HeadphonesOutlined,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import "./DashboardSidebar.css";
import NirantaraLogo from "./NirantaraLogo";
import MasterSidebar from "../Masters/MasterSidebar/MasterSidebar"; // Import the MasterSidebar component

const drawerWidth = 230;

const elements = [
  {
    subData: [
      { path: "/overview", primary: "Overview", Icon: DashboardOutlined },
      {
        path: "/water-management",
        primary: "Water Management",
        Icon: SupportOutlined,
      },
      {
        path: "/waste-management",
        primary: "Waste Management",
        Icon: SupportOutlined,
      },
    ],
  },
  {
    heading: "Reports",
    subData: [
      { path: "/scope-1", primary: "Scope 1", Icon: DescriptionOutlined },
      { path: "/scope-2", primary: "Scope 2", Icon: DescriptionOutlined },
      { path: "/scope-3", primary: "Scope 3", Icon: DescriptionOutlined },
    ],
  },
  {
    heading: "Setup",
    subData: [
      { path: "/setup", primary: "Setup", Icon: ReceiptLongOutlined },
    ],
  },
  {
    heading: "Misc",
    subData: [
      { path: "/support", primary: "Support", Icon: HeadphonesOutlined },
      {
        path: "/documentation",
        primary: "Documentation",
        Icon: DescriptionOutlined,
      },
    ],
  },
];

const DashboardSidebar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [isMasterSidebarOpen, setIsMasterSidebarOpen] = useState(false); // State to toggle MasterSidebar
  const location = useLocation();

  const path=location.pathname;
  // Automatically open MasterSidebar when path starts with /masters
  useEffect(() => {
    if (location.pathname.startsWith("/setup")) {
      setIsMasterSidebarOpen(true);
      setCollapsed(true);
    }
  }, [location]);

  const handleClick = (event, item) => {
    event.preventDefault();
    setActiveLink(item.primary);

    if (item.primary === "Setup") {
      setIsMasterSidebarOpen(!isMasterSidebarOpen); // Toggle MasterSidebar on click
      setCollapsed(!collapsed);
    } else {
      setIsMasterSidebarOpen(false);
      setCollapsed(false);
    }

    if (mobileOpen) {
      setMobileOpen(false);
    }
  };

  const renderSidebarItems = (elements) => {
    return elements.map((section,index) => (
      <div key={index}>
        <Typography
          sx={{
            ml: collapsed ? 0 : 2,
            mt: 2,
            mb: 2,
            fontSize: "10px",
            textAlign: collapsed ? "center" : "start",
          }}
        >
          {section.heading}
        </Typography>
        {section.subData.map((item) => (
          <ListItem
            component={Link}
            to={item.path}
            onClick={(e) => handleClick(e, item)}
            className={`custom-link ${(activeLink === item.primary || path.startsWith(item.path)) ? "active" : ""}`}
            key={item.primary}
            sx={{
              paddingLeft: collapsed ? 0 : 1, // Adjust padding when collapsed
              paddingRight: collapsed ? 0 : 1,
              paddingTop: collapsed ? 1 : "5px",
              paddingBottom: collapsed ? 1 : "5px",
            }}
          >
            <ListItemIcon
              sx={{
                justifyContent: "center",
                color: "inherit",
                padding: 0,
              }}
            >
              <item.Icon
                sx={{
                  fontSize: 20,
                  color: activeLink === item.primary ? "white" : "inherit",
                }}
              />
            </ListItemIcon>
            {!collapsed && (
              <ListItemText
                primary={item.primary}
                primaryTypographyProps={{
                  fontSize: "13px",
                  marginLeft: "0px",
                  color: activeLink === item.primary ? "white" : "inherit",
                }}
              />
            )}
          </ListItem>
        ))}
      </div>
    ));
  };

  return (
    <Box sx={{ display: "flex", width: "10%", backgroundColor: "#f4f4f4" }}>
      <Drawer
        variant={mobileOpen ? "temporary" : "permanent"}
        open={mobileOpen ? mobileOpen : true}
        onClose={mobileOpen ? () => setMobileOpen(false) : undefined}
        sx={{
          width: collapsed ? 50 : drawerWidth, // Adjust drawer width when collapsed
          flexShrink: 0,
          overflowX: "hidden", // Prevent horizontal scrollbar
          "& .MuiDrawer-paper": {
            width: collapsed ? 50 : drawerWidth, // Adjust drawer paper width when collapsed
            overflow: "hidden", // Prevent horizontal scrollbar inside the drawer
            boxSizing: "border-box",
            px: 1, // Reduce padding inside the drawer
          },
        }}
      >
        <NirantaraLogo collapsed={collapsed} sx={{ mb: 2 }} />{" "}
        {/* Hide logo when collapsed */}
        <List sx={{ mt: "-30px" }}>
          {renderSidebarItems(elements)}
        </List>
      </Drawer>

      {/* Conditional rendering of MasterSidebar */}
      {isMasterSidebarOpen && collapsed && (
        <Box
          sx={{
            flexGrow: 1,
            position: "fixed", // Make MasterSidebar position fixed
            zIndex: 1200, // Ensure it's on top
            top: 0,
            left: collapsed ? "50px" : `${drawerWidth}px`, // Adjust based on collapse state
            width: "18%", // Fixed width for MasterSidebar
            height: "100vh", // Ensure it takes full height
          }}
        >
          <MasterSidebar />
        </Box>
      )}
    </Box>
  );
};

export default DashboardSidebar;
