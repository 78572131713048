import { Box, Button } from "@mui/material";
import React from "react";
import { Add as AddIcon } from "@mui/icons-material";

const AddButton = ({ onClick }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={onClick}
        sx={{
          alignSelf: "flex-end",
          color: "#74757E",
          borderColor: "#74757E",
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
          "&:hover": {
            backgroundColor: "rgba(25, 118, 210, 0.04)",
            color: "#115293",
            borderColor: "#115293",
          },
        }}
      >
        Add
      </Button>
    </Box>
  );
};

export default AddButton;
