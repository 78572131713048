import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PaginationItem,
  Drawer,
  Alert,
  Snackbar,
  DialogTitle,
  Card,
  CardContent,
  CardHeader,
  Select,
  FormControl
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
import AddPetroleumGasoline from "./AddPetroleumGasoline";
import ViewPetroleum from "./ViewPetroleumGasoline";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import { api } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import FilterSidebar from "../../../../Components/Masters/Filter/FilterSidebar";
import ApplyButton from "../../../Masters/Roles and Permissions/Filters/ApplyButton";
import DateRangePickerComponent from "../../../Masters/Roles and Permissions/Filters/Date";
import MultiSelect from "../../../Masters/Roles and Permissions/Filters/MultiSelect";
import Range from "../../../Masters/Roles and Permissions/Filters/Range";
import ResetButton from "../../../Masters/Roles and Permissions/Filters/ResetButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from '@mui/material/styles';
import Cards from "../../../../Components/Masters/Cards";
import SelectCard from "../../../../Components/Masters/SelectCard";
import NoDataFound from "../../../../Components/Masters/NoDataFound";

 
const PetroleumGasoline = () => {
  const [petroleums, setPetroleums] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [element, setElement] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPetroleum, setSelectedPetroleum] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [AddPetroleumGas, setAddPetrolGas] = useState(false);
  const [uniqueValues, setUniqueValues] = useState({});
  const [selectedFields, setSelectedFields] = useState({
    industrialProcess: [],
    equipmentType: [],
    industrialEquipment: [],
  });
  const [appliedFilters, setAppliedFilters] = useState({
    date: false,
    industrialProcess: false,
    equipmentType: false,
    industrialEquipment: false,
    quantityConsumed: false,
    hoursUsed: false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [viewPetroleum, setViewPetroleum] = useState(false);
  const [dataView, setdataView] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  // const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries
  const [selectedFacilities, setSelectedFacilities] = useState([]); // Store selected petroleum name
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen,setsnackOpen]= useState({open:false,message:"",severity:""});
  const [selectedPeriod, setSelectedPeriod] = React.useState("July");
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const[errors, setErrors] = useState({
    Date: false,
    industrialProcess: false,
    equipmentType: false,
    industrialEquipment: false,
    quantityConsumed: false,
    hoursUsed: false,
  });
  const [quantityRange,setQuantityRange]=useState({min:"",max:""});
  const [hoursRange,setHoursRange]=useState({min:"",max:""});
  const [cardConsumptions, setCardConsumptions] = useState({
    'Total Petrol Consumed': '',
    'Top Equipment By Consumption': '',
    'Consumption Period': '',
  });
  

  const filters = [
    "Date",
    "Industrial Process",
    "Equipment Type",
    "Industrial Equipment",
    "Quantity Consumed",
    "Hours Used",
  ];
  const inputData = [
    {
      fieldName: "industrialProcess",
      title: "Industrial Process",
      placeholder: "Select industrial process",
      type: "select",
      url: `${api}/master/getAllIndustrialProcess`,
      field:"industryProcess",
      required: true,
    },
    {
      fieldName: "equipmentType",
      title: "Equipment Type",
      placeholder: "Select equipment type",
      type: "select",
      url: `${api}/master/getAllEquipmentType`,
      required:true,
    },
    {
        fieldName: "typeOfEntry",
        title: "Type of Entry",
        placeholder: "Type here",
        type: "select",
        options:["Cumulative","Individual"],
        required:true
      },
      {
      fieldName: "industrialEquipment",
      title: "Industrial Equipment",
      placeholder: "Select industrial equipment",
      type: "select",
      url: `${api}/master/getAllIndustrialEquipment`,
    condition:true,
    },
    {
      fieldName: "quantityConsumed",
      title: "Quantity Consumed",
      placeholder: "Enter quantity",
      type: "number",
      required: true,
    },
    {
      fieldName: "hours",
      title: "Hours Used",
      placeholder: "Enter hours",
      type: "number",
      condition:true,
    },
    {
      fieldName: "unit",
      title: "Unit of Quantity",
      placeholder: "Select unit",
      type: "select",
      options: [
        "NG-m3",
        "Coal-MT",
        "Petrol-litres",
        "Diesel-litres",
        "Fuel Oil-litres",
      ],
      required: true,
    },
    {
      fieldName: "calorificValue",
      title: "Calorific Value",
      placeholder: "10000",
      type: "number",
      required: true,
    },
    // {
    //   fieldName: "calorificValue.unit",
    // //   title: "Calorific Value Unit",
    //   placeholder: "Select unit",
    //   type: "select",
    //   options: ["MJ/kg", "MJ/m3"],
    //   required: true,
    // },
    {
      fieldName: "remarks",
      title: "Remark",
      placeholder: "Additional remarks",
      type: "text",
    },
    {
      fieldName: "documents",
      title: "Upload Documents",
      type: "file",
    },
  ];
  // Function to render the selected filter component based on user's choice
 
  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/stationaryCombustion/getAllPetrolOrGasoline`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.date,
            }}
          />
        );
      case "Industrial Process":
        return (
          <MultiSelect
            title="Industrial Process"
            selectedField={selectedFields.industrialProcess}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Industrial Process"]}
            setErrors={setErrors}
            field="industrialProcess"
            data={uniqueValues.industrialProcess}
          />
        );
      case "Equipment Type":
        return (
          <MultiSelect
            title="Equipment Type"
            url={`${api}/master/getAllEquipmentTypes`}
            selectedField={selectedFields.equipmentType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors.equipmentType}
            setErrors={setErrors}
            field="equipmentType"
            data={uniqueValues.equipmentType}
          />
        );
      case "Industrial Equipment":
        return (
          <MultiSelect
            title="Industrial Equipment"
            selectedField={selectedFields.industrialEquipment}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors.industrialEquipment}
            setErrors={setErrors}
            field="industrialEquipment"
            data={uniqueValues.industrialEquipment}
          />
        );
        case "Quantity Consumed":
            return (
              <Range
                title="Quantity Consumed"
                selectedRange={quantityRange}
                setSelectedRange={setQuantityRange}
                errors={errors.quantityConsumed}
                placeholderMax={"Min"}
            placeholderMin={"Max"}
                setErrors={setErrors}
              />
            );
          

      case "Hours Used":
        return (
          <Range
            title="Hours Used"
            selectedRange={hoursRange}
            setSelectedRange={setHoursRange}
            // setSelectedFields={setSelectedFields}
            placeholderMax={"Min(Hours)"}
            placeholderMin={"Max(Hours)"}
            errors={errors.hoursUsed}
            setErrors={setErrors}
            // field="hoursUsed"
            // data={uniqueValues.hoursUsed} // Adjust data source as needed
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/stationaryCombustion/getAllPetrolOrGasoline`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.date,
            }}
          />
        );
    }
  };


  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(`${api}/stationaryCombustion/getAllPetrolOrGasoline`);
      const data = response.data.data;
      console.log(data);

      const uniqueIndustrialProcess = [
        ...new Set(
          data.map((el) => el.industrialProcess?.industryProcess).filter(Boolean) // Filter out undefined values
        ),
      ];

      console.log(uniqueIndustrialProcess)

      const uniqueEquipmentType = [
        ...new Set(
          data.map((el) => el.equipmentType?.name).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniqueIndustrialEquipment = [
        ...new Set(
          data.map((el) => el.industrialEquipment?.name).filter(Boolean) // Filter out undefined values
        ),
      ];

      return {
        industrialProcess: uniqueIndustrialProcess,
        equipmentType: uniqueEquipmentType,
        industrialEquipment:uniqueIndustrialEquipment
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  const columnHeadings = [
    { title: "Date", fieldName: "createdAt" },
    { fieldName: "industrialProcess", title: "Industrial Process" },
    { fieldName: "equipmentType", title: "Equipment Type" },
    { fieldName: "quantityConsumed", title: "Quantity Consumed" },
    {
      fieldName: "unit",
      title: "Unit",
    },
  ];
  const handleMenuClick = (event, petroleum) => {
    setAnchorEl(event.currentTarget);
    setSelectedPetroleum(petroleum);
    setdataView(petroleum);
  };
 
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPetroleum(null);
  };
 




  const handleEdit = () => {
    console.log("Edit petroleum:", selectedPetroleum);
    handleMenuClose();
    setAddPetrolGas(true);
    setViewPetroleum(false);
  };
 
  const handleView = () => {
    console.log("View petroleum:", selectedPetroleum);
    handleMenuClose();
    setViewPetroleum(true);
    setAddPetrolGas(false)
  };
 
  const handleDelete = () => {
    console.log("Delete petroleum:", selectedPetroleum);
    handleMenuClose();
    setDeleteDialog(true);
  };
 
 
  const handleDeleteAggree = () => {
    console.log("indexhandleDeleteAggree", dataView, dataView._id);
    axios
      .put(
        `${api}/stationaryCombustion/deletePetrolOrGasoline`,
        { },
        {
          params: {
            Id: dataView._id, // Pass as query parameter
          },
        }
      )
      .then(async (res) => {
        console.log(res, "res++");
        setDeleteDialog(false);
        setsnackOpen({ open: true, message: res.data.message, severity: "success" });
 
        // Remove the deleted petroleum from the current list
        const updatedFacilities = petroleums.filter((item) => item._id !== res.data.data._id);
        setPetroleums(updatedFacilities);
 
        // If the remaining number of petroleums is less than the rowsPerPage limit, fetch more
        if (updatedFacilities.length < rowsPerPage && page < totalPages) {
          const facilitiesToFetch = rowsPerPage - updatedFacilities.length; // Calculate the number of petroleums to fetch
          try {
            const queryParams = new URLSearchParams({
              page: page + 1, // Fetch from the next page
              limit: facilitiesToFetch, // Fetch only the number of missing petroleums
              sortField: sortConfig.key,
              sortOrder: sortConfig.direction,
            }).toString();
 
            const response = await axios.get(`${api}/stationaryCombustion/getAllPetrolOrGasoline?${queryParams}`);
            const nextPageFacilities = response.data.data;
 
            // Append only the necessary petroleums to fill the current page
            setPetroleums((prevFacilities) => [...prevFacilities, ...nextPageFacilities]);
          } catch (error) {
            console.error("Error fetching next page petroleums:", error);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setsnackOpen({ open: true, message: err.response?.data?.message ?? "Network Error", severity: "error" });
      });
  };
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
 
// fetching petroleum data
  const fetchPetroleum = async (filters = isFiltersApplied?filtersToApply:{}
  ) => {
  // const fetchPetroleum = async (filters = {}) => {
 
    console.log(filters,"fetchPetroleumData")
    try {
      console.log(sortConfig);
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
        company:companyId,
        createdBy:companyId
      }).toString();
      console.log("Query Params sent to API:", queryParams, filters); // Debugging line
      const response = await axios.get(
        `${api}/stationaryCombustion/getAllPetrolOrGasoline?${queryParams}`
      );
 
      console.log("Fetched data after filtering:", response.data,response.data.data,response.data.totalPages); // Debugging line
      setPetroleums(response.data.data); // Update the user list
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
 
  useEffect(() => {
     fetchPetroleum(filtersToApply);
    // fetchPetroleum()
  }, [page, rowsPerPage, sortConfig]);
 
  // default filters to apply
  const filtersToApply = {
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        acc[key] = selectedOptions.join(","); // Change to an array if needed
      }
      return acc;
    }, {}),
    minQuantity:quantityRange.min || "",
    maxQuantity:quantityRange.max || "",
    minHours:hoursRange.min || "",
    maxHours:hoursRange.max || "",
  };
 
// function to validate filters
const validateFilters = () => {
  let isValid = true;
  const newErrors = {
    Date: false,
    "Industrial Process": false,
    "Equipment Type": false,
    "Industrial Equipment": false,
    "Quantity Consumed": false,
    "Hours Used": false,
  };

  console.log(selectedFilter, "hi");

  if (selectedFilter === "Date") {
    const today = new Date(); // Get the current date
    if (!startDate && !endDate) {
      newErrors.Date = "Please select start date & end date";
      isValid = false;
    }
    // Ensure that startDate and endDate are not greater than today's date
    if (startDate && startDate > today) {
      newErrors.Date = "Start date cannot be in the future";
      isValid = false;
    }

    if (endDate && endDate > today) {
      newErrors.Date = "End date cannot be in the future";
      isValid = false;
    }

    // Ensure that the startDate is less than or equal to the endDate
    if (startDate && endDate && startDate > endDate) {
      newErrors.Date = "End date must be greater than Start date";
      isValid = false;
    }
  }

  if (
    selectedFilter === "Industrial Process" &&
    selectedFields.industrialProcess.length === 0
  ) {
    newErrors["Industrial Process"] =
      "Please select atleast one Industrial Processs";
    isValid = false;
  }

  if (
    selectedFilter === "Industrial Equipment" &&
    selectedFields.industrialEquipment.length === 0
  ) {
    newErrors["Industrial Equipment"] =
      "Please select atleast one Industrial Equipment";
    isValid = false;
  }

  if (
    selectedFilter === "Equipment Type" &&
    selectedFields.equipmentType.length === 0
  ) {
    newErrors["Equipment Type"] = "Please select atleast one Equipment Type";
    isValid = false;
  }

  if (selectedFilter === "Quantity Consumed") {
    const min = parseFloat(quantityRange.min);
    const max = parseFloat(quantityRange.max);

    if (!min && !max) {
      newErrors["Quantity Consumed"] =
        "Please provide both min and max values";
      isValid = false;
    }

    if (min && isNaN(min)) {
      newErrors["Quantity Consumed"] =
        "Min consumption must be a valid number";
      isValid = false;
    }

    if (max && isNaN(max)) {
      newErrors["Quantity Consumed"] =
        "Max consumption must be a valid number";
      isValid = false;
    }

    if (!isNaN(min) && !isNaN(max) && min > max) {
      newErrors["Quantity Consumed"] =
        "Min consumption cannot be greater than max consumption";
      isValid = false;
    }
  }
  if (selectedFilter === "Hours Used") {
    const min = parseFloat(hoursRange.min);
    const max = parseFloat(hoursRange.max);

    if (!min && !max) {
      newErrors["Hours Used"] = "Please provide both min and max values";
      isValid = false;
    }

    if (min && isNaN(min)) {
      newErrors["Hours Used"] = "Min hours must be a valid number";
      isValid = false;
    }

    if (max && isNaN(max)) {
      newErrors["Hours Used"] = "Max hours must be a valid number";
      isValid = false;
    }

    if (!isNaN(min) && !isNaN(max) && min > max) {
      newErrors["Hours Used"] =
        "Min hours cannot be greater than max consumption";
      isValid = false;
    }
  }

  setErrors(newErrors);
  return isValid;
};
 
 
  // Applying selected filters
  const handleApplyFilters = () => {
    if(!validateFilters())
      return;
    console.log("Applying filters...");
 
    // Reset to first page when applying filters
    setPage(1);
    fetchPetroleum(filtersToApply);
    setDrawerFilterOpen(false);
    handleAppliedFilters();
  };
 


  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
        industrialProcess: [],
        equipmentType: [],
        industrialEquipment: [],
    });

    // Call fetchData without any filters
    await fetchPetroleum({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };
  // Applying reset filters
//   const handleResetFilters = () => {
   
//     setStartDate(null);
//       setEndDate(null);
//        setSelectedCountries([]);
//        setSelectedFacilities([]);
//        setIsFiltersApplied(false);
//       setAppliedFilters({ Facility: false,Date:false,Country:false });
//       setErrors({})
//     fetchPetroleum({
//       country:  [] ,
//       facilityName:[] ,
//       startDate:  "" ,
//       endDate:  "",
//     });
//     setDrawerFilterOpen(false);
 
//   };
 
  // handle the applied filters for styling

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate ? true : false;
    const isFacilityApplied =
      selectedFields.facility?.length > 0 ? true : false;
    const isWasteManagementTypeApplied =
      selectedFields.wasteManagementType?.length > 0 ? true : false;

    setIsFiltersApplied(
      isDateApplied || isFacilityApplied || isWasteManagementTypeApplied
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      Facility: isFacilityApplied,
      "Type of waste management": isWasteManagementTypeApplied,
    }));
  };
 
  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };
 
  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));
 
 
  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    // setErrors({ Date: false, Role: false });
  };
 
  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
       
      }}
    >
       <Snackbar open={snackOpen.open} autoHideDuration={3000}
         anchorOrigin={{ vertical:"top", horizontal:"center" }}
          onClose={()=>setsnackOpen({...snackOpen,open:false})}
 
         >
        <Alert
          // onClose={handleClose}
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackOpen.message}
          {/* Add user Successfully */}
        </Alert>
      </Snackbar>
 
      <AddPetroleumGasoline
        AddPetroleumGas={AddPetroleumGas}
        setAddPetrolGas={setAddPetrolGas}
        selectedPetroleum={dataView}
        fetchPetroleum={fetchPetroleum}
        setsnackOpen={setsnackOpen}
      />
      <ViewPetroleum
        viewPetroleum={viewPetroleum}
        setViewPetroleum={setViewPetroleum}
        selectedPetroleum={dataView}
        handleEdit={handleEdit}
      />
         
<Box sx={{    margin:"0% 13% -3% 3%"}}>
         <Grid item xs={6} >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "8px 0px 20px 21%", textDecoration: "underline" }}
            >
            Petroleum / Gasoline
            </Typography>
            {/* <Grid container spacing={2}  justifyContent="center"> */}
         
          </Grid>   
          <Box sx={{ display: "flex",width: "100%",  gap: 2 , margin: "5% 55% 0% 22%" }}>
      {/* Card 1: Total Natural Gas Consumed */}
        <Cards title="Total Natural Gas Consumed"
         content="10,000 m³"/>
          <Cards title="Top Uesd Equipments"
         content="Equipment 1 | Equipment 2"/>
          <SelectCard title="Consumption Period"
         content="700 m³" handleChange={handlePeriodChange} value={selectedPeriod} list={months}/>
      {/* Card 3: Consumption Period */}
 
    </Box>
    </Box>
      {deleteDialog && (
        
        <Dialog open={deleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Facility?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAggree} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      )}




<Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setElement}
          setAddData={setAddPetrolGas}
          {...{
            // heading: "Coal",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>
 
     
 
        <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
      <Box>
      <Box sx={{ margin: '16px' }}>
   
         <Box
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          textAlign: "left",
          // padding: "0px",
          }}>Petroleum/PetroleumGasoline Combustion</Box>
       </Box>
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "12% 22% 20% 15% 21% 10%",
      borderBottom: "1px solid #ddd",
      borderTop: "1px solid #ddd",
    }}
  >
    <Typography sx={{ fontWeight: "bold", padding: "8px 19px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd", display: "flex",  
         flexDirection: "row" }}>
      Date
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("createdAt", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "createdAt" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("createdAt","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "createdAt" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd",display: "flex",  
         flexDirection: "row" }}>
      Industrial Process
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("facilityName", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "facilityName" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("facilityName","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "facilityName" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd",display: "flex",  
         flexDirection: "row" }}>
      Equipment Type
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("incharge", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "incharge" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("incharge","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "incharge" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd" ,display: "flex", flexDirection: "row"}}>
      Quantity Consumed
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("outsourceFacility", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "outsourceFacility" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("outsourceFacility","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "outsourceFacility" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px", marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd", display: "flex", flexDirection: "row" }}>
      Unit
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("country", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "country" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("country","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "country" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px", textAlign: "center" }}>
      Action
     
    </Typography>
  </Box>
 
  {petroleums.map((petroleum) => (
    <Box
      key={petroleum._id}
      sx={{
        display: "grid",
        gridTemplateColumns: "12% 22% 20% 15% 21% 10%",
        borderBottom: "1px solid #ddd",
      }}
    >
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "center",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // margin: "7px 0px -1px 0px",
          fontSize: "14px",
          color: "black"
        }}
      >
        {console.log("petroleumOfTabulardata",petroleum.createdAt,petroleum)}
        {/* {new Date(petroleum.createdAt).toLocaleDateString()} */}
        {new Date(petroleum.createdAt).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -1px 0px",
        }}
      >
        {petroleum.industrialProcess?.industryProcess||petroleum.industrialProcess}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -1px 0px",
        }}
      >
        {petroleum.equipmentType?.name}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -1px 0px",
        }}
      >
        {petroleum.quantityOfPetrolConsumed}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -1px 0px",
        }}
      >
        {petroleum.unit}
      </Typography>
      <Box
        sx={{
          padding: "1px",
          textAlign: "center",
        }}
      >
        <IconButton onClick={(e) => handleMenuClick(e, petroleum)}>
          <MoreHorizIcon sx={{ fontWeight: "bold", color: "black" }} />
        </IconButton>
      </Box>
    </Box>
  ))}
</Box>
 
 
 
 
       
</TableContainer>
 
 
 
      {/* </TableContainer> */}
 
      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            backgroundColor: "#ffffff",
            width: "80%",
            margin: "2% -0.5% 0% 23%",
            borderRadius: "2%",
            padding: "3px",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            renderItem={(item) => (
              <StyledPaginationItem
              // <PaginationItem
                {...item}
                components={{
                  previous: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 27,
                      }}
                    >
                      <ArrowBackIcon sx={{ mr: 0.5, color: "green" }} />
                      Previous
                    </Box>
                  ),
                  next: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: 27,
                      }}
                    >
                      Next
                      <ArrowForwardIcon sx={{ ml: 0.5, color: "green" }} />
                    </Box>
                  ),
                }}
                sx={{
                  "& .MuiPaginationItem-root": {
                    mx: 1,
                  },
                  "& .MuiPagination-ul": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
      {/* Menu for Actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>
       
        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};
 
export default PetroleumGasoline;
 
 