import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const CustomTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputBase-root": {
    height: "40px",
  },
  ...(error && {
    "& .MuiOutlinedInput-root": {
      borderColor: "#D34040",
      "&:hover": {
        borderColor: "#D34040",
      },
      "&.Mui-focused": {
        borderColor: "#D34040",
      },
    },
  }),
}));

const AutoComplete = ({
  title,
  name,
  options,
  errors,
  value,
  handleChange,
}) => {
  return (
    <Box>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        {title}
      </Typography>
      <Autocomplete
        disablePortal
        options={options} // Ensure options are properly passed
        sx={{ width: 300 }}
        value={value || null} // Ensure value is correctly passed (null is safe if nothing selected)
        onChange={(event, newValue) => {
          // Here we manually trigger handleChange to pass both `name` and the selected value (`newValue`)
          handleChange({
            target: {
              name, // Use the name of the field
              value: newValue ||  "", // Pass the `id` as value (or empty string if nothing is selected)
            },
          });
        }}
        // getOptionLabel={(option) => option.name || option} // Display name for objects in options
        renderInput={(params) => (
          <CustomTextField
            placeholder="Search"
            error={!!errors}
            {...params}
          />
        )}
      />
      {errors && (
        <Typography variant="body2" color="#D34040">
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default AutoComplete;
