import React, {useEffect,useState, useContext } from 'react';
import Sidebar from '../sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField, Button, 
  InputAdornment, 
  Typography, Box,Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
 } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ProgressContext } from "../ContextAPI";
import {api} from "../../../utils/api";
import { companyId } from "../../../utils/companyId";
import ActionButtons from '../InitialKickoff/MaterialTopics/ActionButtons';
import {unitError,listError,textError,percentageError, numberError} from "../../../utils/validationMessage";

const BoardComposition = () => {
  const navigate = useNavigate();
  const { setUpdate } = useContext(ProgressContext);
  const [isExist,setIsExist] = useState(false);
    const [addTextField,setAddTextField] = useState(['']);
    const [openDialog, setOpenDialog] = useState(false);
    const [DeleteIndex,setIndexDelete]=useState(0);
  const [form,setFrom] = useState({
    companyId:companyId||"",
    boardIndependance: 0,
    boardDiversity:{
      Male:0,
      Female:0,
      MinAge:0,
      MaxAge:0,
      MinYears:0,
      MaxYears:0
    },
    boardMeetingAttendance:0,
    boardCommittees:{
      Total:0,
      Type:[''],
      Range:[0]
    }
  });
  const [fieldToRemove, setFieldToRemove] = React.useState({
    fieldName: "",
    index: null,
  });
  const [errors, setErrors] = useState({});
  const handleChange =(e)=>{
    const {name,value} = e.target;
    setFrom({
      ...form,
      [name]: value
  })
  setErrors({
    ...errors,
    [name]:""
  })
  }
  
  const handleChangeCommitteesTotal =(e)=>{
    const {name,value} = e.target;
    setFrom({
      ...form,
      boardCommittees:{ 
        ...form.boardCommittees,
        [name]: value}
  })
  setErrors({
    ...errors,
    boardCommittees:{
    ...errors.boardCommittees,
        [name]: ""
    }
  })
  }

  const handleChangeCommittees =(e,index,fieldType)=>{
    const {name,value} = e.target;
  //   setFrom({
  //     ...form,
  //     boardCommittees:{ 
  //       ...form.boardCommittees,
  //       [name]: value}
  // })
  const updatedCommittees = { ...form.boardCommittees };
    updatedCommittees[fieldType][index] = value;
    setFrom({
      ...form,
      boardCommittees: updatedCommittees,
    });
  }
  const handleRemoveField = (fieldName, index) => {
    setOpenDialog(true);
    // setFieldToRemove({ fieldName, index });
    setIndexDelete(index)
  };
  const validateForm = () => {
    const newErrors = {};
  
    const { 
      boardIndependance,
      boardDiversity,
      boardMeetingAttendance,
      boardCommittees,
    } = form;
  
  
    // Validate `boardIndependance`
    if (!boardIndependance || boardIndependance < 0 || boardIndependance >= 100 
      // ||typeof (boardIndependance) !== 'string' 
      ||!/^\d*\.?\d*$/.test(boardIndependance) 
    ) {
      newErrors.boardIndependance = percentageError;
    }
  
    // Validate `boardDiversity`
    if (!boardDiversity.Male || isNaN(boardDiversity.Male) || boardDiversity.Male <= 0 
    // ||typeof (boardDiversity.Male) !== 'string'
     ||!/^\d*\.?\d*$/.test(boardDiversity.Male) 
  ) {
      newErrors.boardDiversityMale = numberError;
    }
    if (!boardDiversity.Female || isNaN(boardDiversity.Female) || boardDiversity.Female <= 0
    // ||typeof (boardDiversity.Female) !== 'string' 
    ||!/^\d*\.?\d*$/.test(boardDiversity.Female)
  ) {
      newErrors.boardDiversityFemale = numberError;
    }
    if (boardDiversity.MinAge <= 0 
      // ||typeof (boardDiversity.MinAge) !== 'string' 
      ||!/^\d*\.?\d*$/.test(boardDiversity.MinAge)
    ) {
      newErrors.boardDiversityMinAge = numberError;
    }
    if (boardDiversity.MaxAge <= 0 
      // ||typeof (boardDiversity.MaxAge) !== 'string'
       ||!/^\d*\.?\d*$/.test(boardDiversity.MaxAge)
    ) {
      newErrors.boardDiversityMaxAge = numberError;
    }
    if (boardDiversity.MinYears <= 0 
      // ||typeof (boardDiversity.MinYears) !== 'string'
       ||!/^\d*\.?\d*$/.test(boardDiversity.MinYears)
    ) {
      newErrors.boardDiversityMinYears = numberError;
    }
    if (boardDiversity.MaxYears <= 0 
      // ||typeof (boardDiversity.MaxYears) !== 'string' 
      ||!/^\d*\.?\d*$/.test(boardDiversity.MaxYears)
       || boardDiversity.MaxYears < boardDiversity.MinYears) {
      newErrors.boardDiversityMaxYears = numberError;
    }
  
    // Validate `boardMeetingAttendance`
    if (!boardMeetingAttendance 
      // ||typeof (boardMeetingAttendance) !== 'string'
       ||!/^\d*\.?\d*$/.test(boardMeetingAttendance)
       || boardMeetingAttendance < 0 || boardMeetingAttendance >= 100) {
      newErrors.boardMeetingAttendance = percentageError;
    }
  
    // Validate `boardCommittees`
    if (!boardCommittees.Total
      // ||typeof (boardCommittees.Total) !== 'string'
       ||!/^\d*\.?\d*$/.test(boardCommittees.Total)
        || boardCommittees.Total <= 0) {
      newErrors.boardCommitteesTotal = numberError;
    }
    // if (boardCommittees.Type.length === 0 || boardCommittees.Type.includes("")) {
    //   newErrors.boardCommitteesType = listError;
    // }
    // if (boardCommittees.Range.length === 0 ) {
    //   newErrors.boardCommitteesRange = listError;
    // }
    boardCommittees.Type.forEach((type, index) => {
      if (!type || type.trim() === "") {
        newErrors[`boardCommitteesType${index}`] = textError; // Index-specific error for Type
      }
    });
    
    // Validate boardCommittees.Range array index-wise
    boardCommittees.Range.forEach((value, index) => {
      if (isNaN(value)
        //  || typeof (value) !== 'string' 
      ||!/^\d*\.?\d*$/.test(value)
        || value <= 0 ||  value >= 100) {
        newErrors[`boardCommitteesRange${index}`] = percentageError; // Index-specific error for Range
      }
    });
   console.log(newErrors,"newErrors")
  
   setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  


  const handleChangeDiversity =(e)=>{
    const {name,value} = e.target;
    setFrom({
      ...form,
      boardDiversity:{ 
        ...form.boardDiversity,
        [name]: value}
  })
  setErrors({
    ...errors,
    boardDiversity:{ 
      ...errors.boardDiversity,
      [name]: ""}
  })
  }
  // console.log(form)
  useEffect(()=>{
    setUpdate("GOVERNANCE");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/governance/Board-composition/${form.companyId}`
        );
        console.log(response.data)
        setFrom({
          companyId:companyId||"",
          boardIndependance: response.data.boardIndependance,
          boardDiversity:{
            Male:response.data.boardDiversity.Male,
            Female:response.data.boardDiversity.Female,
            MinAge:response.data.boardDiversity.MinAge,
            MaxAge:response.data.boardDiversity.MaxAge,
            MinYears:response.data.boardDiversity.MinYears,
            MaxYears:response.data.boardDiversity.MaxYears},
            boardMeetingAttendance:response.data.boardMeetingAttendance,
            boardCommittees:{
              Total:response.data.boardCommittees.Total,
              Type:response.data.boardCommittees.Type,
              Range:response.data.boardCommittees.Range,
            }
          });
          //  console.log("dataforthedata",response.data.boardCommittees,response.data.boardCommittees.Range,response.data.boardCommittees.Type)
          setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
        // alert(err);
      }
    };
    fetchData();
  },[])
  // const AddTextfield = (e, index, fieldType) => {
  //   // const { name, value } = e.target;
  
  //   // // Create a copy of the current boardCommittees
  //   // const updatedCommittees = { ...form.boardCommittees };
  
  //   // // Update the specific field (Type or Range) at the given index
  //   // updatedCommittees[fieldType][index] = value;
  
  //   // // Set the updated state
  //   // setFrom({
  //   //   ...form,
  //   //   boardCommittees: updatedCommittees,
  //   // });

  // };
  const AddTextField = () => {
    const updatedCommittees = { ...form.boardCommittees };
    updatedCommittees.Type.push('');
    updatedCommittees.Range.push('');
    setFrom({
      ...form,
      boardCommittees: updatedCommittees,
    });
  };

  const confirmRemoveField = () => {
    console.log("fieldToRemove",fieldToRemove)
    const updatedCommittees = { ...form.boardCommittees };

    // Remove the element at the specific index from both Type and Range arrays
    updatedCommittees.Type.splice(DeleteIndex, 1);
    updatedCommittees.Range.splice(DeleteIndex, 1);
  
    setFrom({
      ...form,
      boardCommittees: updatedCommittees,
    });
    setOpenDialog(false);
  };
  const cancelRemoveField = () => {
    setOpenDialog(false);
  };
  const Save = () => {
    if (!validateForm()) {
      return;
    }
      axios({method:isExist?'put':'post',
        url:`${api}/governance/Board-composition${isExist ? `/${form.companyId}` : ""}`,
        data:form}).then((response)=>{
        console.log(response)
        // alert(response.data.message)
         navigate("/governance/exclutive-compensation");
      }).catch((err)=>{
        console.log(err,err.data)
        alert(err.message)
      })

  };

  const Back = () => {
    navigate("/social/supply-chain");
    setUpdate("SOCIAL");
  };

  return (
    <Box width="100%"   sx={{ display: "flex",overflow:"hidden",height:"100vh", backgroundColor: "#EDEDED" }}>
       <Dialog open={openDialog} onClose={cancelRemoveField}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this field?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelRemoveField} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRemoveField} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    <Sidebar title="board composition & effectiveness" />
    <Box sx={{
          p: 2,
          mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}
      >
     <Typography variant="h5"  sx={{
          fontWeight: "bold",
          // mt: "7rem",
          zIndex: 1,
          padding: "10px",
          borderRadius: "4px",
          alignSelf: "flex-start",
          ml:"5rem"          }}>
          GOVERNANCE: BOARD COMPOSITION & EFFECTIVENESS
        </Typography>

        <Box sx={{width:"80%"}}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Board Independence
          </Typography>
          <TextField
            variant="outlined"
            placeholder="12"
            // type='number'
            name='boardIndependance'
            // inputMode="numeric"
            value={form.boardIndependance===0?"":form.boardIndependance}
            // InputProps={{
            //   endAdornment: <InputAdornment position="end">%</InputAdornment>,
            // }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
                    %
                  </Box>
                </InputAdornment>
              ),
              style: { borderColor: errors.boardIndependance ? '#D34040' : undefined,
               
              },
            }}
            
            sx={{
              // '& input[type=number]': {
              //   MozAppearance: 'textfield',
              //   WebkitAppearance: 'none',
              // },
               mb: 2,
                 width:"90%"
            }}
            onChange = {handleChange}
            error={!!errors.boardIndependance} // Show error styling if there's an error
           
            InputLabelProps={{
              style: { color: errors.boardIndependance ? '#D34040' : undefined },
            }}
    
          />
            {errors.boardIndependance && (
          <Typography variant="body2" color="#D34040">
            {errors.boardIndependance}
          </Typography>
        )}
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Board Diversity
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, mb: 2 ,
             width:"90%"
            }}>
          <Box sx={{
            display:"flex",
            flexDirection:"column",
             width:"100%"
          }}>

            <TextField
              variant="outlined"
              name='Male'
              type='text'
              placeholder="Male"
              value={form.boardDiversity.Male===0?"":form.boardDiversity.Male}
             fullWidth
             onChange = {handleChangeDiversity}
             error={!!errors.boardDiversityMale} // Show error styling if there's an error
             InputProps={{
               style: { borderColor: errors.boardDiversityMale ? '#D34040' : undefined},
             }}
             InputLabelProps={{
               style: { color: errors.boardDiversityMale ? '#D34040' : undefined},
             }}
     
            /> 
              {errors.boardDiversityMale && (
          <Typography variant="body2" color="#D34040">
            {errors.boardDiversityMale}
          </Typography>
        )}
        </Box>
            :
            <Box  sx={{
            display:"flex",
            flexDirection:"column",
             width:"100%"
          }}>
            <TextField
              variant="outlined"
              name='Female'
              placeholder="Female"
              value={form.boardDiversity.Female=== 0?"":form.boardDiversity.Female}
             fullWidth
             onChange={handleChangeDiversity}
             error={!!errors.boardDiversityFemale} // Show error styling if there's an error
             InputProps={{
               style: { borderColor: errors.boardDiversityFemale ? '#D34040' : undefined,
                
                },
             }}
             InputLabelProps={{
               style: { color: errors.boardDiversityFemale ? '#D34040' : undefined },
             }}
            />
               {errors.boardDiversityFemale && (
          <Typography variant="body2" color="#D34040">
            {errors.boardDiversityFemale}
          </Typography>
        )}
            </Box>
          </Box>
          
          <Box sx={{ display: 'flex', gap: 1, mb: 2,
            width:"90%"
           }}>
          <Box  sx={{
            display:"flex",
            flexDirection:"column",
              width:"100%"
          }}>
            <TextField
              variant="outlined"
              placeholder="Minimum Age"
              name="MinAge"
              value={form.boardDiversity.MinAge === 0?"":form.boardDiversity.MinAge}
             fullWidth
             onChange={handleChangeDiversity}
             error={!!errors.boardDiversityMinAge} // Show error styling if there's an error
             InputProps={{
               style: { borderColor: errors.boardDiversityMinAge ? '#D34040' : undefined,
                
                },
             }}
             InputLabelProps={{
               style: { color: errors.boardDiversityMinAge ? '#D34040' : undefined },
             }}
            /> 
               {errors.boardDiversityMinAge && (
          <Typography variant="body2" color="#D34040">
            {errors.boardDiversityMinAge}
          </Typography>
        )}
            </Box>
            _
            <Box  sx={{
            display:"flex",
            flexDirection:"column",
             width:"100%"
          }}>
            <TextField
              variant="outlined"
              placeholder="Maximum Age"
              name = "MaxAge"
              value={form.boardDiversity.MaxAge===0?"":form.boardDiversity.MaxAge}
              fullWidth
              onChange={handleChangeDiversity}
              error={!!errors.boardDiversityMaxAge} // Show error styling if there's an error
              InputProps={{
                style: { borderColor: errors.boardDiversityMaxAge ? '#D34040' : undefined,
                 
                 },
              }}
              InputLabelProps={{
                style: { color: errors.boardDiversityMaxAge ? '#D34040' : undefined },
              }}
            />
               {errors.boardDiversityMaxAge && (
          <Typography variant="body2" color="#D34040">
            {errors.boardDiversityMaxAge}
          </Typography>
        )}
            
            </Box>
          </Box>

          <Box sx={{ display: 'flex',
             width:"90%",
             gap: 1, mb: 2 }}>
          <Box  sx={{
            display:"flex",
            flexDirection:"column",
             width:"100%"
          }}>
            <TextField
              variant="outlined"
              placeholder="Min. years of experience"
               fullWidth
               name='MinYears'
               value={form.boardDiversity.MinYears===0?"":form.boardDiversity.MinYears}
               onChange={handleChangeDiversity}
               error={!!errors.boardDiversityMinYears} // Show error styling if there's an error
               InputProps={{
                 style: { borderColor: errors.boardDiversityMinYears ? '#D34040' : undefined,
                  
                  },
               }}
               InputLabelProps={{
                 style: { color: errors.boardDiversityMinYears ? '#D34040' : undefined },
               }}
            />
               {errors.boardDiversityMinYears && (
          <Typography variant="body2" color="#D34040">
            {errors.boardDiversityMinYears}
          </Typography>
        )}
            </Box>
             _
             <Box  sx={{
            display:"flex",
            flexDirection:"column",
            width:"100%"
          }}>
            <TextField
              variant="outlined"
              placeholder="Max. years of experience"
              fullWidth
              name='MaxYears'
              value={form.boardDiversity.MaxYears===0?"":form.boardDiversity.MaxYears}
              onChange={handleChangeDiversity}
              error={!!errors.boardDiversityMaxYears} // Show error styling if there's an error
              InputProps={{
                style: { borderColor: errors.boardDiversityMaxYears ? '#D34040' : undefined,
                 
                 },
              }}
              InputLabelProps={{
                style: { color: errors.boardDiversityMaxYears ? '#D34040' : undefined },
              }}
            />
               {errors.boardDiversityMaxYears && (
          <Typography variant="body2" color="#D34040">
            {errors.boardDiversityMaxYears}
          </Typography>
        )}
          </Box>
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Board Meeting Attendance
          </Typography>
          <TextField
            variant="outlined"
            placeholder="80"
            name='boardMeetingAttendance'
            value={form.boardMeetingAttendance === 0?"":
              form.boardMeetingAttendance
            }
            // InputProps={{
            //   endAdornment: <InputAdornment position="end">%</InputAdornment>,
            // }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
                    %
                  </Box>
                </InputAdornment>
              ),
              style: { borderColor: errors.boardMeetingAttendance ? '#D34040' : undefined,
               
              },
            }}
            sx={{ mb: 0 ,width:"90%"}}
            error={!!errors.boardMeetingAttendance} // Show error styling if there's an error
            InputLabelProps={{
              style: { color: errors.boardMeetingAttendance ? '#D34040' : undefined },
            }}
            onChange={handleChange}
          />
               {errors.boardMeetingAttendance && (
          <Typography variant="body2" color="#D34040">
            {errors.boardMeetingAttendance}
          </Typography>
        )}
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1,mt:2 }}>
            Board Committees
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Total number of committees"
            sx={{ mb:errors.boardCommitteesTotal?0:2,width:"90%"}}
            name='Total'
            value={form.boardCommittees.Total===0?"":form.boardCommittees.Total}
            onChange={handleChangeCommitteesTotal}
            error={!!errors.boardCommitteesTotal} // Show error styling if there's an error
            InputProps={{
              style: { borderColor: errors.boardCommitteesTotal ? '#D34040' : undefined,
               
               },
            }}
            InputLabelProps={{
              style: { color: errors.boardCommitteesTotal ? '#D34040' : undefined },
            }}
          />
             {errors.boardCommitteesTotal && (
          <Typography sx={{mb:1}} variant="body2" color="#D34040">
            {errors.boardCommitteesTotal}
          </Typography>
        )}
{/* <Box width="91%"> */}
{form.boardCommittees.Type.map((item,i)=>{
 return <Box
 key={i}
 sx={{ width: "99%", display: "flex", gap: 1 }}
>
   <Box sx={{ display: 'flex', gap: 1, mb: 2,
  width:"91%"
  }}>
  <Box sx={{
            display:"flex",
            flexDirection:"column",
             width:"100%"
          }}>
  <TextField
    variant="outlined"
    placeholder="Type of committees"
    fullWidth
    name='Type'
    value={item}
    onChange={(e) => handleChangeCommittees(e, i, 'Type')}
    error={!!errors[`boardCommitteesType${i}`]} // Show error styling if there's an error
            InputProps={{
              style: { borderColor: errors[`boardCommitteesType${i}`] ? '#D34040' : undefined,
               
               },
            }}
            InputLabelProps={{
              style: { color: errors[`boardCommitteesType${i}`] ? '#D34040' : undefined },
            }}
  />
   {errors[`boardCommitteesType${i}`] && (
          <Typography variant="body2" color="#D34040">
            {errors[`boardCommitteesType${i}`]}
          </Typography>
        )}
        </Box>
        <Box sx={{
            display:"flex",
            flexDirection:"column",
             width:"100%"
          }}>
            <Box display="flex" sx={{width:"100%",gap:"10px"}}>
  <TextField
    variant="outlined"
    placeholder="Attendance Rate"
    fullWidth
    // InputProps={{
    //   endAdornment: <InputAdornment position="end">%</InputAdornment>,
    // }}
   
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
            %
          </Box>
        </InputAdornment>
      ),
      style: { borderColor: errors[`boardCommitteesRange${i}`] ? '#D34040' : undefined,
               
      },
    }} 
    error={!!errors[`boardCommitteesRange${i}`]} // Show error styling if there's an error
    InputLabelProps={{
      style: { color: errors[`boardCommitteesRange${i}`] ? '#D34040' : undefined },
    }}
    name="Range"
    value={form.boardCommittees.Range[i]===0?"":form.boardCommittees.Range[i]} 
    sx={{ mb: 0 }}
    // onChange={handleChangeCommittees}
    onChange={(e) => handleChangeCommittees(e, i, 'Range')}
  />
  
                  </Box>
 {errors[`boardCommitteesRange${i}`] && (
          <Typography variant="body2" color="#D34040">
            {errors[`boardCommitteesRange${i}`]}
          </Typography>
        )}
</Box>
  
</Box>
{i > 0 && (
  <IconButton sx={{ mt: 0, }}>
    <DeleteIcon
      onClick={() =>
        handleRemoveField("", i)
      }
    />
  </IconButton>
)}</Box>
})}
{/* </Box> */}


           
          <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end', width:"90%"  }}>
            <Button variant="outlined" endIcon={<AddIcon />} sx={{boarderColor:"#0a3f73",
              color:"#0a3f73", textTransform: 'none' }}
              onClick={AddTextField}
              >
              Add
            </Button>
          </Box>

          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 , margin:"0 20px 10px 0"}}>
          <Button
            variant="outlined"
            onClick={Back}
            startIcon={<ArrowBackIcon />}
            sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}}
          >
            Back
          </Button>
            <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none"}} 
            onClick={Save} endIcon={<ArrowForwardIcon />}>
              Save and Continue
            </Button>
          </Box> */}
          <ActionButtons Back={Back} Move={Save}/>
        </Box>
      </Box>
    </Box>
  );
};

export default BoardComposition;
