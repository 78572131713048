



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Box, Typography, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
// import { api } from '../../../../../utils/api';

// const FunctionalityOfRole = ({ onRoleFilterChange, selectedFunctionalityOfRole, errors }) => {
//   const [permissions, setPermissions] = useState([]);

//   useEffect(() => {
//     const loadRoles = async () => {
//       try {
//         const response = await axios.get(`${api}/auth/getAllUsers`);
//         const usersData = response.data.users || [];

//         // Create a Set to store unique permissions
//         const uniquePermissions = new Map();

//         usersData.forEach(user => {
//           user.role?.allowed_permissions.forEach(permission => {
//             // Assuming permission has an id and a name field
//             uniquePermissions.set(permission._id, permission.name);
//           });
//         });

//         // Convert the Map back to an array of objects
//         setPermissions(Array.from(uniquePermissions, ([id, name]) => ({ id, name })));
//       } catch (error) {
//         console.error('Error fetching roles:', error);
//         setPermissions([]);
//       }
//     };
//     loadRoles();
//   }, []);

//   // const handleCheckboxChange = (event) => {
//   //   const { value, checked } = event.target;
//   //   const updatedSelection = checked 
//   //     ? [...selectedFunctionalityOfRole, value] 
//   //     : selectedFunctionalityOfRole.filter((functionality) => functionality !== value);
    
//   //   console.log("Selected functionalities:", updatedSelection); // Debugging line
  
//   //   onRoleFilterChange(updatedSelection);
//   // };
//   const handleCheckboxChange = (event) => {
//     const { value, checked } = event.target;
//     const updatedSelection = checked 
//       ? [...selectedFunctionalityOfRole, value] 
//       : selectedFunctionalityOfRole.filter((functionality) => functionality !== value);
  
//     console.log("Selected functionalities:", updatedSelection); // Debugging line
//     onRoleFilterChange(updatedSelection);
//   };
  

//   return (
//     <Box>
//       <Typography variant="h5" sx={{ margin: '20px 0px 0px 30px', fontWeight: 'bold' }}>
//         Role
//       </Typography>

//       <Box sx={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
//         {permissions.length > 0 ? (
//           <FormGroup>
//             {permissions.map(({ id, name }) => (
//               <FormControlLabel
//                 key={id}
//                 control={
//                   <Checkbox
//                     value={id}
//                     checked={selectedFunctionalityOfRole.includes(id)} 
//                     onChange={handleCheckboxChange}
//                   />
//                 }
//                 label={name} // Show the permission name
//               />
//             ))}
//           </FormGroup>
//         ) : (
//           <Typography>Loading...</Typography>
//         )}
//       </Box>
//       {errors && <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
//         {errors}
//       </Typography>}
//     </Box>
//   );
// };

// export default FunctionalityOfRole;






import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Checkbox, FormControlLabel, FormGroup, Button } from '@mui/material';
import { api } from '../../../../../utils/api';

const FunctionalityOfRole = ({ onRoleFilterChange, selectedFunctionalityOfRole, errors }) => {
  const [permissions, setPermissions] = useState([]);
  
  useEffect(() => {
    const loadRoles = async () => {
      try {
        const response = await axios.get(`${api}/auth/getAllUsers`);
        const usersData = response.data.users || [];

        // Create a Set to store unique permissions
        const uniquePermissions = new Map();

        usersData.forEach(user => {
          user.role?.allowed_permissions.forEach(permission => {
            // Assuming permission has an id and a name field
            uniquePermissions.set(permission._id, permission.name);
          });
        });

        // Convert the Map back to an array of objects
        setPermissions(Array.from(uniquePermissions, ([id, name]) => ({ id, name })));
      } catch (error) {
        console.error('Error fetching roles:', error);
        setPermissions([]);
      }
    };
    loadRoles();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const updatedSelection = checked 
      ? [...selectedFunctionalityOfRole, value] 
      : selectedFunctionalityOfRole.filter((functionality) => functionality !== value);
  
    console.log("Selected functionalities:", updatedSelection); // Debugging line
    onRoleFilterChange(updatedSelection);
  };

  const handleApplyFilter = async () => {
    try {
      const response = await axios.get(`${api}/auth/getAllUsers`, {
        params: {
          permissionName: selectedFunctionalityOfRole.join(','), // Passing selected permissions
        },
      });

      console.log('Filtered Users:', response.data.users);
      // You can handle the filtered users data here (e.g., set to a state for further usage)
    } catch (error) {
      console.error('Error applying filter:', error);
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ margin: '20px 0px 0px 30px', fontWeight: 'bold' }}>
        Role
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
        {permissions.length > 0 ? (
          <FormGroup>
            {permissions.map(({ id, name }) => (
              <FormControlLabel
                key={id}
                control={
                  <Checkbox
                    value={name} // Using name for filtering
                    checked={selectedFunctionalityOfRole.includes(name)} 
                    onChange={handleCheckboxChange}
                  />
                }
                label={name} // Show the permission name
              />
            ))}
          </FormGroup>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
      )}
      
      <Button variant="contained" onClick={handleApplyFilter}>
        Apply Filter
      </Button>
    </Box>
  );
};

export default FunctionalityOfRole;
