import React from "react";
import DashboardLayout from "../../../../Layout/DashboardLayout";
import ShipTransport from "./ShipTransport";
const index = () => {
  return (
    <DashboardLayout>
      <ShipTransport />
    </DashboardLayout>
  );
};

export default index;
