import React from "react";
import { Button, Badge } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";

const ActionButtons = ({ onFilterClick, onAddClick, filterObj }) => {
  // const isFilterApplied = Object.values(filterObj).some((filter) => filter && filter.length > 0);
  const isFilterApplied = Object.keys(filterObj).some(
    key => filterObj[key] && (
      Array.isArray(filterObj[key]) ? filterObj[key].length > 0 : filterObj[key]
    )
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: "1rem",
        gap: "1rem",
      }}
    >
      <Button
        style={{
          backgroundColor: "#fff",
          color: "#000000",
          borderRadius: "4px",
          // gap: "1rem",
        }}
        onClick={onFilterClick}
      >
        Filter 
        <Badge
          color="success" 
          variant="dot"
          invisible={!isFilterApplied}
        >
          <FilterListIcon />
        </Badge>

      
      </Button>
      <Button
        style={{
          backgroundColor: "#1C486B",
          color: "#ffffff",
          borderRadius: "4px",
          gap: "0.5rem",
        }}
        onClick={onAddClick}
      >
        Add Data
        <AddIcon />
      </Button>
    </div>
  );
};

export default ActionButtons;
