import React from 'react';
import { TextField, InputAdornment, styled, Box, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        height: "40px",
    },
}));

const PhoneNumberInput = ({ title, value, placeholder, showIcon, errors, handleChange, countryCode }) => {
    // Handle input change with validation
    const handleInputChange = (event) => {
        const inputValue = event.target.value;

        // Allow only numeric input and restrict to 10 digits
        if (/^\d{0,10}$/.test(inputValue)) {
            handleChange(event);
        }
    };

    return (
        <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
                {title}
            </Typography>
            <CustomTextField
                variant="outlined"
                fullWidth
                value={value}
                placeholder={placeholder}

                // disabled={!countryCode}
                onChange={handleInputChange} // Use the new input change handler
                InputProps={{
                    maxLength: 10, // Visual limit
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    endAdornment: (
                        showIcon && <TaskAltIcon sx={{ color: "green" }} />
                    ),
                    startAdornment: (
                        <InputAdornment position="start" sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* Render placeholder or countryCode */}
                            {countryCode ? <div style={{ width: "40px", marginRight: "8px", fontWeight: "bold", }}>
                                {countryCode}
                            </div>
                            :<div style={{ width: "40px", marginRight: "8px"}}>
                            {"Code"}
                        </div>}
                            {/* Vertical Line */}
                            <div style={{ borderRight: "1px solid #000", height: "24px", marginRight: "8px" }} />
                        </InputAdornment>
                    ),
                }}
            />
            {errors && (
                <Typography variant="body2" color="#D34040">
                    {errors}
                </Typography>
            )}
        </Box>
    );
};

export default PhoneNumberInput;
