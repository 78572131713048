import { Box, Typography, TextField, styled, InputAdornment } from '@mui/material';
import React from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

// Move CustomTextField outside the InputField to prevent re-creation on each render
const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        height: "auto", // Adjust height as needed
    },
    "& .MuiSelect-select": {
        height: "auto", // Adjust height as needed
        display: "flex",
        // alignItems: "center",
    },
}));

const Multiline = ({title, handleChange, placeholder, value, errors, fieldName }) => {
    return (
        <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
                {title}
            </Typography>
            <CustomTextField
            multiline
            rows={2}
                variant="outlined"
                value={value || ""}
                onChange={handleChange}
                fullWidth
                name={fieldName}
                placeholder={placeholder}
                error={!!errors} // Show error styling if there's an error
                InputLabelProps={{
                    style: { color: errors ? "#D34040" : undefined },
                }}
                
                      
            />
            {errors && (
                <Typography variant="body2" color="#D34040">
                    {errors}
                </Typography>
            )}
        </Box>
    );
};

export default Multiline;
