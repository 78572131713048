import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton,styled, MenuItem,TextField,FormControl } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import InputField from "../../../../Components/Masters/InputField";
import SelectField from "../../../../Components/Masters/SelectField";
import FileField from "../../../../Components/Masters/FileField";
import { validateNumber } from "../../../../utils/validateFields";
import { numberError } from "../../../../utils/validationMessage";
import Multiline from "../../../../Components/Masters/Multiline";

function AddOffRoad({
  inputData,
  fetchData,
  id,
  data,
  setAddData,
  addData,
  setsnackOpen,
  addUrl,
  updateUrl,
}) {
  const [errors, setErrors] = useState({});
  const [fuelType,setFuelType]=useState("");
  const initialState = {
    machineryCategory: "",
    machineryType: "",
    machineryName: "",
    typeOfEntry: "",
    uniqueIdOfEquipment: "",
    fuelType: "",
    quantityOfFuelConsumed: "",
    hoursUsed:  "" ,
    remarks: "",
    documents: [],
    company: companyId,
    createdBy:companyId
  };
  const [formData, setFormData] = useState(initialState);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  const resetForm = () => {
    setFormData(data === null? 
     initialState:data
     )
     setFuelType(data === null? 
      "":data?.fuelType)
     setErrors({}); // Reset errors
       console.log("FromValue:",data)
   };

const fetchFuelType= ()=>{
axios.get(`${api}/master/scope1/getByIDMachinery`,{
          params: {
            Id: formData.machineryType  // Pass as query parameter
          }}).then((response)=>{console.log("responseForFuelType",response.data.data?.fuelType)
            setFuelType(response.data.data?.fuelType)
            setFormData((prevState) => ({ ...prevState, fuelType: response.data.data?.fuelType }));
          }).catch(
            (err)=>console.log("err",err)
          )
}

  useEffect(() => {
    // if (addData) {
    //   setFormData(initialState); // Reset form data when opening the drawer
    // }
    console.log("UploadingData:",inputData.machineType)
    if (id && data) {
      setFormData({
        machineryCategory: data.machineryCategory?._id || "",
        machineryType: data.machineryType?._id || "",
        machineryName: data.machineryName?._id || "",
        typeOfEntry: data.typeOfEntry || "",
        uniqueIdOfEquipment: data.uniqueIdOfEquipment?._id || "",
        fuelType: data.fuelType || "",
        quantityOfFuelConsumed: data.quantityOfFuelConsumed || "",
        hoursUsed: data.hoursUsed || "",
        // unit: data.unit || "",
        // calorificValue: {
        // unit: data.unit || "",
        // calorificValue: {
        //   value: data.calorificValue?.value || "",
        //   unit: data.calorificValue?.unit || "",
        // },
        remarks: data.remarks || "",
        documents: data.documents || [],
      });
      setUploadedFiles(data.documents || []);
    } else if(!id) {
      setFormData(initialState);
      setUploadedFiles([]);
    }


  }, [data,id]);

  const handleChange = (e) => {
    console.log("hii fr testing")
    const { name, value } = e.target;
console.log("handleChangeForField", name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
      
   
      setFormData((prevState) => ({ ...prevState, [name]: value }));
      if(name==="machineryType"){
        fetchFuelType();
      }
    
  };

  
  const handleClose = () => {
    setAddData(false);
    setErrors({});
    resetForm();
    // setFormData(initialState); // Reset form data to initial state
    // setUploadedFiles([]); // Reset uploaded files
};


  const validateForm = () => {
    const newErrors = {};
    console.log(formData)

    inputData.forEach((input) => {
        const value = formData[input.fieldName];

        // Check for calorificValue separately
        // if (input.fieldName === "calorificValue") {
        //     const cvValue = formData[input.fieldName]?.value || ""; // Ensure it's a string
        //     const cvUnit = formData[input.fieldName]?.unit || ""; // Ensure it's a string

        //   if (!validateNumber(cvValue)) {
        //         newErrors[input.fieldName] = {
        //             ...newErrors[input.fieldName],
        //             value: numberError,
        //         };
        //     }

        //     if (!cvUnit.trim()) {
        //         newErrors[input.fieldName] = {
        //             ...newErrors[input.fieldName],
        //             unit: "Unit is required.",
        //         };
        //     }
        //     return; // Ensure to return after handling errors for calorificValue
        // }

        // Skip validation for hours and industrialEquipment if not in Individual mode
        if (formData.typeOfEntry !== "Individual" && 
            (input.fieldName === "hoursUsed" || input.fieldName === "uniqueIdOfEquipment"  && (!value || value.trim() === "Tap to select"))) {
            return; // Skip validation for these fields
        }

        // Validation for numeric inputs
        if (input.type === "number") {
            if (!validateNumber(value)) {
                newErrors[input.fieldName] = numberError;
                return; // Stop further checks for this input
            }
        }

        if (input.fieldName === "documents" && uploadedFiles.length === 0) {
          console.log(input.fieldName,uploadedFiles)
           newErrors[input.fieldName] = `Please upload Documents`;
         }

        // Additional checks for required fields
        if (input.required && input.type!=="number" && (!value || value.trim() === ""))
         {
            newErrors[input.fieldName] = `${input.title} is required.`;
        }
    });

    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};



const Save = async () => {
  if (!validateForm()) return;

  // Prepare data to send
  const dataToSend = {
    ...formData,
    documents: uploadedFiles,
  };

  // Conditionally delete fields if typeOfEntry is not "Individual"
 
  if (formData.typeOfEntry !== "Individual") {
    delete dataToSend.hoursUsed;
    delete dataToSend.uniqueIdOfEquipment;
  }
  console.log("formData.typeOfEntry",dataToSend)
  await axios({
    method: id ? "put" : "post",
    url: id ? `${updateUrl}` : addUrl,
    data: dataToSend,
    params: id ? { Id: id } : null, // Send params only for PUT request
  })
    .then((res) => {
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
      setFormData({});
      handleClose();
      resetForm();
    })
    .catch((err) => {
      if (err.response && err.response.data && err.response.data.errors) {
        const errorMessages = err.response.data.errors
          .map((error) => error.msg)
          .join(", ");
        setsnackOpen({
          open: true,
          message: errorMessages,
          severity: "error",
        });
      }
    });
};

  const renderInputField = (el) => {
    console.log("elForRenderInput",el.url,el.field,formData[el.fieldName])
    if (el.options || el.type === "select") {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          options={el.options}
          errors={errors[el.fieldName]}
          url={el.url}
          field={"name"}
          keyName={"name"}
        />
      );
    }

    if (el.type === "machineType") {
      return (
        <SelectFieldMachineType
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChangeMachine={handleChange}
          options={el.options}
          errors={errors[el.fieldName]}
          url={el.url}
          field={el.type}
        />
      );
    }
    
    if (el.type === "file") {
      return (
        <FileField
          title={el.title}
          name={el.fieldName}
          errors={errors[el.fieldName]}
          fileUrls={uploadedFiles}
          progress={uploadProgress[el.fieldName] || []}
          {...{setUploadedFiles,setUploadProgress,setErrors}}
          // onDelete={handleDeleteFile}
        />
      );
    }

    if (el.fieldName === "fuelType")
      return (
        <InputField
          title={el.title}
          handleChange={handleChange}
          placeholder={el.placeholder}
          value={fuelType|| ""}
          errors={errors[el.fieldName]}
          fieldName={el.fieldName}
        />
      );
    if (el.fieldName === "remarks")
      return (
        <Multiline
          title={el.title}
          handleChange={handleChange}
          placeholder={el.placeholder}
          value={formData[el.fieldName] || ""}
          errors={errors[el.fieldName]}
          fieldName={el.fieldName}
        />
      );
      if (el.fieldName === "fuelType")
        return (
          <InputField
            title={el.title}
            handleChange={handleChange}
            placeholder={el.placeholder}
            value={fuelType|| ""}
            errors={errors[el.fieldName]}
            fieldName={el.fieldName}
          />
        );

    return (
      <InputField
        title={el.title}
        handleChange={handleChange}
        placeholder={el.placeholder}
        value={formData[el.fieldName] || ""}
        errors={errors[el.fieldName]}
        fieldName={el.fieldName}
      />
    );
  };

  const list = () => (
    <Box display="flex" gap={3} sx={{ width: "400px",maxWidth:"100%" }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton onClick={handleClose} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "88%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {id ? "Edit OffRoad Data" : "Add OffRoad Data"}
        </Typography>
        {inputData.map((el) => {
          // Conditional rendering for hours and industrial equipment
          if (
            (el.fieldName === "hoursUsed" ||
              el.fieldName === "uniqueIdOfEquipment") &&
            formData.typeOfEntry !== "Individual"
          ) {
            return null; // Skip rendering these fields if typeOfEntry is not "Individual"
          }

          return <div key={el.fieldName}>{renderInputField(el)}</div>;
        })}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      sx={{
        backgroundColor: "transparent",
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          height: "100vh",
          minHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
      anchor="right"
      open={addData}
      onClose={handleClose}
    >
      {list()}
    </Drawer>
  );
}

export default AddOffRoad;





const SelectFieldMachineType = ({ title, value, handleChangeMachine, name, options, errors, url, field }) => {
  const [newOptions, setNewOptions] = useState([]);

  // Styled TextField with conditional error styling
  const CustomTextField = styled(TextField)(({ theme, error }) => ({
      "& .MuiInputBase-root": {
          height: "40px", // Adjust height as needed
      },
      "& .MuiSelect-select": {
          height: "56px", // Adjust height as needed
          display: "flex",
          alignItems: "center",
      },
      ...(error && {
          "& .MuiOutlinedInput-root": {
              borderColor: "#D34040", // Red border on error
              "&:hover": {
                  borderColor: "#D34040", // Keep red border on hover
              },
              "&.Mui-focused": {
                  borderColor: "#D34040", // Keep red border when focused
              },
          },
      }),
  }));


  
  console.log("Props received by SelectFieldMachineType:", { handleChangeMachine, name, value });
  // Fetch options
  const fetchOptions = async () => {
      try {
          const res = await axios.get(url);
          if (Array.isArray(res.data.data) && res.data.data.length > 0) {
              // Store both name and id in the state
              setNewOptions(res.data.data.map(item => ({
                  id: item._id, // Assuming the ID is stored in _id
                  name: field ? item[field] : item.name || item.facilityName,
              })));
              
          }
      } catch (err) {
          console.log(err);
      }
  };

  useEffect(() => {
      if (url) fetchOptions();
  }, [url]);

  return (
      <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              {title}
          </Typography>
          <FormControl fullWidth margin="normal" sx={{ mt: 0 }}>
              <CustomTextField 
                  select
                  name={name}
                  value={value || "Tap to Select"} // Set value to an empty string if undefined
                  onChange={handleChangeMachine}
                  error={!!errors} // Pass error state to CustomTextField
              >
                  <MenuItem value="Tap to Select" disabled>
                      Tap to select
                  </MenuItem>
                  {(options && options.length > 0 ? options : newOptions).map(option => (
                    //  <>
                    //  {console.log("option.id || option",option.id , option)}
                     <MenuItem key={option.id || option} value={option.id || option.name?.name || option}
                      // onChange={handleChange}
                     >
                          {option.name?.name || option}
                      </MenuItem>
                      
                      // </>
                  ))}
              </CustomTextField>
              {errors && (
                  <Typography variant="body2" color="#D34040">
                      {errors}
                  </Typography>
              )}
          </FormControl>
      </Box>
  );
};