import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { companyId } from "../../../../utils/companyId";
import { api } from "../../../../utils/api";
import MaterialCategory from "./MaterialCategory";
import MaterialChips from "./MaterialChips";
import ActionButtons from "./ActionButtons";
import { ProgressContext } from "../../ContextAPI";
import { Box, Typography } from "@mui/material";
import Sidebar from "../../sidebar";
import { listError } from "../../../../utils/validationMessage";

const MaterialTopics = () => {
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const { setUpdate } = useContext(ProgressContext);
  const [formData, setFormData] = useState({
    environment: ["Select"],
    social: ["Select"],
    governance: ["Select"],
  });
  const [open, setOpen] = useState({
    environment: false,
    social: false,
    governance: false,
  });

  const [errors, setErrors] = useState({
    environment: "",
    social: "",
    governance: "",
  });
  //array of dropdown fields
  const data = [
    {
      title: "Environment",
      key: "environment",
      list: [
        "Select",
        "Select All",
        "Carbon Emissions",
        "Product Carbon Footprint",
        "Financing Environmental Impact",
        "Water Stress",
        "Biodiversity & Land Use",
        "Raw Material Sourcing",
        "Toxic Emissions & Waste",
        "Packaging Material & Waste",
        "Electronic Waste",
        "Opportunities in Clean Tech",
        "Opportunities in Green Building",
        "Opportunities in Renewable Energy",
        "Energy Management",
        "Waste Management",
        "Ecological Impacts",
        "Greenhouse Gas (GHG) Emissions",
        "Air Quality",
        "Water & Wastewater Management",
        "Climate Change Adaptation",
        "Materials Sourcing & Efficiency",
        "Product Design & Lifecycle Management",
        "Environmental & Social Impacts of Ingredient Supply Chain",
        "Management of Energy Infrastructure Integration & Related Regulations",
        "Hazardous Waste Management",
        "Environmental Footprint of Operations",
        "End-Use Efficiency",
        "Effluent Quality Management",
        "Drinking Water Quality",
        "Water Supply Resilience",
        "Fuel Economy & Emissions in Use-phase",
      ],
    },
    {
      title: "Social",
      key: "social",
      list: [
        "Select",
        "Select All",
        "Labour Management",
        "Health & Safety",
        "Human Capital Development",
        "Supply Chain Labour Standards",
        "Product Safety & Quality",
        "Chemical Safety",
        "Consumer Financial Protection",
        "Privacy & Data Security",
        "Responsible Investment",
        "Community Relations",
        "Controversial Sourcing",
        "Access to Finance",
        "Access to Health Care",
        "Opportunities in Nutrition & Health",
        "Employee Engagement, Diversity & Inclusion",
        "Human Rights & Community Relations",
        "Business Model Resilience",
        "Critical Incident Risk Management",
        "Competitive Behavior",
        "Systemic Risk Management",
        "Physical Impacts of Climate Change",
        "Customer Welfare",
        "Workforce Health & Safety",
        "Employee Recruitment, Development & Retention",
        "Professional Integrity",
        "Customer Privacy & Technology Standards",
        "Affordable & Low-Income Patient Access",
        "Quality of Care & Patient Satisfaction",
        "Pricing & Billing Transparency",
        "Fraud & Unnecessary Procedures",
        "Patient Health Outcomes",
        "Patient Privacy & Electronic Health Records",
        "Product Innovation",
        "Pricing Integrity & Transparency",
        "Workforce Conditions, Health & Safety",
        "Health & Nutrition",
        "Food Safety",
        "Public Health",
        "Safety of Clinical Trial Participants",
        "Access to Medicines",
        "Affordability & Pricing",
        "Drug Safety",
        "Counterfeit Drugs",
        "Ethical Marketing",
        "Management of Controlled Substances",
        "Climate Change Impacts on Human Health & Infrastructure",
        "Workforce Diversity & Engagement",
      ],
    },
    {
      title: "Governance",
      key: "governance",
      list: [
        "Select",
        "Select All",
        "Board",
        "Pay",
        "Accounting",
        "Business Ethics",
        "Tax Transparency",
        "Management of the Legal & Regulatory Environment",
        "Selling Practices & Product Labeling",
        "Transparent Information & Fair Advice for Customers",
        "Sustainable Investment Management",
        "Financed Emissions",
        "Financial Inclusion & Capacity Building",
        "Systemic Risk Management",
        "Policies Designed to Incentivize Responsible Behavior",
        "Professional Integrity",
        "Lending Practices",
        "Discriminatory Lending",
        "Environmental Risk to Mortgaged Properties",
        "Promoting Transparent & Efficient Capital Markets",
        "Managing Conflicts of Interest",
        "Managing Business Continuity & Technology Risks",
        "Intellectual Property Protection & Media Piracy",
        "Management of Leachate & Hazardous Waste",
        "Recycling & Resource Recovery",
        "Accident & Safety Management",
      ],
    },
  ];

  // fetching data if already exists
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/company/material-topics/${companyId}`
        );
        const { social, governance, environment } =
          response.data.materialTopics;
        // setFormData(response.data.materialTopics);
        setFormData({
          social: social,
          environment: environment,
          governance: governance,
        });
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e, category) => {
    const { value } = e.target;
  
    // Fetch all available values for the category except "Select" and "Select All"
    const allValues = data
      .find((item) => item.key === category)
      .list.filter((item) => item !== "Select" && item !== "Select All");
  
    if (value.includes("Select All")) {
      // If "Select All" is chosen, select all options except "Select" and "Select All"
      setFormData((prev) => ({
        ...prev,
        [category]: allValues,
      }));
    } else if (value.includes("Unselect All")) {
      // Reset to "Select" when "Unselect All" is chosen
      setFormData((prev) => ({
        ...prev,
        [category]: ["Select"],
      }));
    } else {
      // For other selections, update state normally
      setFormData((prev) => {
        // Filter out "Select" if other options are selected
        let updatedValues = value.filter((item) => item !== "Select");
  
        // Ensure "Select" is shown when no other options are selected
        if (updatedValues.length === 0) {
          updatedValues = ["Select"];
        }
  
        return {
          ...prev,
          [category]: updatedValues,
        };
      });
    }
  
  

    // Clear the error message for the specific category
    setErrors((prevErrors) => ({
      ...prevErrors,
      [category]: "",
    }));

    // Close the dropdown after selection
    setOpen((prevState) => ({ ...prevState, [category]: false }));
  };

  // remove the array values
  const handleDelete = (valueToDelete, category) => {
    setFormData((prev) => {
      const updatedValues = prev[category].filter(
        (value) => value !== valueToDelete
      );
      // Check if the array is empty and add "Select" if needed
      if (updatedValues.length === 0) {
        updatedValues.push("Select");
      }
      return {
        ...prev,
        [category]: updatedValues,
      };
    });
  };

  const validateForm = (filteredFormData) => {
    let valid = true;
    let newErrors = { environment: "", social: "", governance: "" };

    if (filteredFormData.environment.length === 0) {
      newErrors.environment = listError;
      valid = false;
    }

    if (filteredFormData.social.length === 0) {
      newErrors.social = listError;
      valid = false;
    }

    if (filteredFormData.governance.length === 0) {
      newErrors.governance = listError;
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  // Navigate back
  const Back = () => {
    navigate("/future-plans");
  };

  const Move = async () => {
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [
        key,
        Array.isArray(value) ? value.filter((item) => item !== "Select") : [],
      ])
    );

    if (!validateForm(filteredFormData)) {
      return;
    }

    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/company/material-topics${isExist ? `/${companyId}` : ""}`,
        data: { ...filteredFormData, companyId },
      });
      setUpdate("ESG");
      navigate("/environment/energy-management");
    } catch (error) {
      console.error("There was an error submitting the data!", error);
      alert("There was an error submitting the data!");
    }
  };

  const isAllSelected = (category) => {
    const selectedItems = formData[category].filter(
      (item) => item !== "Select"
    );
    const allValues = data
      .find((item) => item.key === category)
      .list.filter((item) => item !== "Select" && item !== "Select All");
    return selectedItems.length === allValues.length;
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#EDEDED",
        height: "100vh",
        overflow: "hidden", // Ensure no content gets hidden by scrollbars
      }}
    >
      <Sidebar title="Material Topics" />
      <Box
        sx={{
          p: 2,
          mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            zIndex: 1,
            padding: "10px",
            borderRadius: "4px",
            alignSelf: "flex-start",
            ml: "5.5rem",
          }}
        >
          MATERIAL TOPICS
        </Typography>
        <Box sx={{ width: "80%" }}>
          {/* Maps the data */}
          {data.map((category) => (
            <MaterialCategory
              key={category.key}
              category={{
                ...category,
                list: isAllSelected(category.key)
                  ? category.list.map((item) =>
                      item === "Select All" ? "Unselect All" : item
                    )
                  : category.list,
              }}
              formData={formData}
              handleChange={handleChange}
              renderChips={(selected, key) => (
                <MaterialChips
                  selected={selected}
                  category={key}
                  handleDelete={handleDelete}
                />
              )}
              open={open}
              setOpen={setOpen}
              error={errors[category.key]}
              borderColor={errors[category.key] && "#D34040"}
            />
          ))}
          <ActionButtons Back={Back} Move={Move} />
        </Box>
      </Box>
    </Box>
  );
};

export default MaterialTopics;
