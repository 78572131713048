import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  Grid,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DocumentItem from "../../../../Components/Masters/DocumentItem";

function ViewWaste({ inputData, data, viewData, setViewData, handleEdit }) {
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if (data) {
      console.log(data);
      setFilteredData(data);
    }
  }, [data]);

  const formatDate = (date) => {
    if (!date) return "-";
    const d = new Date(date);
    return `${String(d.getDate()).padStart(2, "0")}/${String(
      d.getMonth() + 1
    ).padStart(2, "0")}/${String(d.getFullYear()).slice(-2)}`;
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: "400px",
        maxWidth: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton onClick={() => setViewData(false)} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ p: 3, width: "100%", backgroundColor: "#fff", overflow: "auto" }}>
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Waste Management Vendor Details
        </Typography>

        <Grid container spacing={2}>
          {/* Render the new Date field at the beginning */}
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Date
            </Typography>
            <div>{formatDate(new Date())}</div> {/* Display current date */}
          </Grid>

          {inputData.map((input) => {
            const value = filteredData?.[input.fieldName];

            if (value === undefined || value === null) return null;
            if (input.fieldName === "documents") return null;

            let displayValue;
            if (
              input.fieldName === "facility" &&
              typeof value === "object" &&
              value !== null
            ) {
              displayValue = value.facilityName;
            } else {
              displayValue = value;
            }

            return (
              <Grid item xs={12} sm={12} key={input.fieldName}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {input.title}
                </Typography>
                <div>
                  {Array.isArray(input.options)
                    ? input.options.includes(displayValue)
                      ? displayValue
                      : "-"
                    : displayValue ?? "-"}
                </div>
              </Grid>
            );
          })}
        </Grid>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold"}}>
            Documents
          </Typography>
          <Box display="flex" flexDirection="column" gap={1} sx={{ width: "100%" }}>
            {/* Render Images First */}
            {data && data.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.documents
                  .filter((url) =>
                    ["png", "jpg", "jpeg"].includes(
                      url.split(".").pop().toLowerCase()
                    )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`image-${index}`} url={url} />
                  ))}
              </Box>
            )}

            {/* Render Other Documents */}
            {data && data.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.documents
                  .filter(
                    (url) =>
                      !["png", "jpg", "jpeg"].includes(
                        url.split(".").pop().toLowerCase()
                      )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`file-${index}`} url={url} />
                  ))}
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10%",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0a3f73", textTransform: "none" }}
            onClick={handleEdit}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={viewData}
      onClose={() => setViewData(false)}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      {list()}
    </Drawer>
  );
}

export default ViewWaste;
