import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton,styled, MenuItem,TextField,FormControl } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../utils/api";
import { companyId } from "../../../utils/companyId";
import InputField from "../../../Components/Masters/InputField";
import SelectField from "../../../Components/Masters/SelectField";
import FileField from "../../../Components/Masters/FileField";
import { validateNumber } from "../../../utils/validateFields";
import { numberError } from "../../../utils/validationMessage";
import Multiline from "../../../Components/Masters/Multiline";

function AddFireExitingul({
  inputData,
  fetchData,
  id,
  data,
  setAddData,
  addData,
  setsnackOpen,
  addUrl,
  updateUrl,
}) {
  const [errors, setErrors] = useState({});
  const [fuelType,setFuelType]=useState("");
  const initialState = {
    fugitiveEmissionSource:"",
    typeOfSubstance:"",
    quantityReleased:"",
    remarks: "",
    company: companyId,
    createdBy:companyId
  };
  const [formData, setFormData] = useState(initialState);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  const resetForm = () => {
    setFormData(data === null? 
     initialState:data
     )
     setFuelType(data === null? 
      "":data?.fuelType)
     setErrors({}); // Reset errors
       console.log("FromValue:",data)
   };

const fetchFuelType= ()=>{
axios.get(`${api}/master/scope1/getByIDMachinery`,{
          params: {
            Id: formData.machineryType  // Pass as query parameter
          }}).then((response)=>{console.log("responseForFuelType",response.data.data?.fuelType)
            setFuelType(response.data.data?.fuelType)
            setFormData((prevState) => ({ ...prevState, fuelType: response.data.data?.fuelType }));
          }).catch(
            (err)=>console.log("err",err)
          )
}

  useEffect(() => {
    // if (addData) {
    //   setFormData(initialState); // Reset form data when opening the drawer
    // }
    console.log("UploadingData:",inputData.machineType)
    if (id && data) {
      setFormData({
        fugitiveEmissionSource: data.fugitiveEmissionSource || "",
        typeOfSubstance: data.typeOfSubstance || "",
        machineryName: data.machineryName?._id || "",
        quantityReleased: data.quantityReleased || "",
        uniqueIdOfEquipment: data.uniqueIdOfEquipment?._id || "",
        fuelType: data.fuelType || "",
        quantityOfFuelConsumed: data.quantityOfFuelConsumed || "",
        hoursUsed: data.hoursUsed || "",
        // unit: data.unit || "",
        // calorificValue: {
        // unit: data.unit || "",
        // calorificValue: {
        //   value: data.calorificValue?.value || "",
        //   unit: data.calorificValue?.unit || "",
        // },
        remarks: data.remarks || "",
      
      });
    
    } else if(!id) {
      setFormData(initialState);
      setUploadedFiles([]);
    }


  }, [data,id]);

  const handleChange = (e) => {
    console.log("hii fr testing")
    const { name, value } = e.target;
console.log("handleChangeForField", name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
      
   
      setFormData((prevState) => ({ ...prevState, [name]: value }));
      if(name==="machineryType"){
        fetchFuelType();
      }
    
  };

  
  const handleClose = () => {
    setAddData(false);
    setErrors({});
    resetForm();
    // setFormData(initialState); // Reset form data to initial state
    // setUploadedFiles([]); // Reset uploaded files
};


  const validateForm = () => {
    const newErrors = {};
    console.log(formData)

    inputData.forEach((input) => {
        const value = formData[input.fieldName];

        // Check for calorificValue separately
        // if (input.fieldName === "calorificValue") {
        //     const cvValue = formData[input.fieldName]?.value || ""; // Ensure it's a string
        //     const cvUnit = formData[input.fieldName]?.unit || ""; // Ensure it's a string

        //   if (!validateNumber(cvValue)) {
        //         newErrors[input.fieldName] = {
        //             ...newErrors[input.fieldName],
        //             value: numberError,
        //         };
        //     }

        //     if (!cvUnit.trim()) {
        //         newErrors[input.fieldName] = {
        //             ...newErrors[input.fieldName],
        //             unit: "Unit is required.",
        //         };
        //     }
        //     return; // Ensure to return after handling errors for calorificValue
        // }

        // Skip validation for hours and industrialEquipment if not in Individual mode
      

        // Validation for numeric inputs
        if (input.type === "number") {
            if (!validateNumber(value)) {
                newErrors[input.fieldName] = numberError;
                return; // Stop further checks for this input
            }
        }


        // Additional checks for required fields
        if (input.required && input.type!=="number" && (!value || value.trim() === ""))
         {
            newErrors[input.fieldName] = `${input.title} is required.`;
        }
    });

    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};



const Save = async () => {
  if (!validateForm()) return;

  // Prepare data to send
  const dataToSend = {
    ...formData,
    quantityReleased: parseFloat(formData.quantityReleased),
  };
  await axios({
    method: id ? "put" : "post",
    url: id ? `${updateUrl}` : addUrl,
    data: dataToSend,
    params: id ? { Id: id } : null, // Send params only for PUT request
  })
    .then((res) => {
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
      setFormData({});
      handleClose();
      resetForm();
    })
    .catch((err) => {
      if (err.response && err.response.data && err.response.data.errors) {
        const errorMessages = err.response.data.errors
          .map((error) => error.msg)
          .join(", ");
        setsnackOpen({
          open: true,
          message: errorMessages,
          severity: "error",
        });
      }
    });
};

  const renderInputField = (el) => {
    console.log("elForRenderInput",el.url,el.fieldName,formData,formData[el.fieldName])
    if (el.options || el.type === "select") {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          options={el.options}
          errors={errors[el.fieldName]}
          url={el.url}
          field={"name"}
        //   keyName={"name"}
        />
      );
    }
    
    if (el.fieldName === "remarks")
      return (
        <Multiline
          title={el.title}
          handleChange={handleChange}
          placeholder={el.placeholder}
          value={formData[el.fieldName] || ""}
          errors={errors[el.fieldName]}
          fieldName={el.fieldName}
        />
      );

    return (
      <InputField
        title={el.title}
        handleChange={handleChange}
        placeholder={el.placeholder}
        // value={formData[el.fieldName] || ""}
        value={typeof formData[el.fieldName]=== "object" ? formData[el.fieldName]?.value : formData[el.fieldName]}
        errors={errors[el.fieldName]}
        fieldName={el.fieldName}
      />
    );
  };

  const list = () => (
    <Box display="flex" gap={3} sx={{ width: "400px",maxWidth:"100%",height:"100%" }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton onClick={handleClose} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "88%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {id ? "Edit Fire extinguisher" : "Add Fire extinguisher"}
        </Typography>
        {inputData.map((el) => {
          // Conditional rendering for hours and industrial equipment
         

          return <div key={el.fieldName}>{renderInputField(el)}</div>;
        })}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "30%",
            }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      sx={{
        backgroundColor: "transparent",
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          height: "100vh",
          minHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
      anchor="right"
      open={addData}
      onClose={handleClose}
    >
      {list()}
    </Drawer>
  );
}

export default AddFireExitingul;

