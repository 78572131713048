import React,{useEffect, useState,useContext} from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  InputAdornment,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,

} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './Ethics.css';
import axios from 'axios';
import { api } from '../../../utils/api';
import { companyId } from "../../../utils/companyId";
import {unitError,numberError,textError,percentageError} from "../../../utils/validationMessage";
import ActionButtons from '../InitialKickoff/MaterialTopics/ActionButtons';
import {ProgressContext} from "../ContextAPI";

const Ethics = () => {
  const { setUpdate } = useContext(ProgressContext);
  const navigate = useNavigate();
  const[IsExist,setISExist]=useState(false);
  const [openDialog, setOpenDialog] = useState({});
  const [DeleteIndex,setIndexDelete]=useState(0);
  const [fieldToRemove, setFieldToRemove] = React.useState({
    fieldName: "",
    index: null,
  });
  const [formData,setFromData]=useState({
    companyId:companyId,
    codeConductViolations:{
      type_violation:[""],
      number_incidence:[0]
    },
    antiCorruptionMeasures:{
      policy:"yes",
      training_program:"yes",
      minyears_experience_policy:0,
      minyears_experience_training:0
    },
    whistleblowerPolicies:{
      existence:"yes",
      reports:0,
      resolution:0

    },
    complianceLawsRegulations:{
      type_compliance:[""],
      frequency:[""],
      fine_paid:[0]
    }


});
const [errors, setErrors] = useState({});
const validateForm = () => {
  const newErrors = {};
  
  const { 
    codeConductViolations,
    antiCorruptionMeasures,
    whistleblowerPolicies,
    complianceLawsRegulations
  } = formData;

  // Validate `codeConductViolations`
  codeConductViolations.type_violation.forEach((violation, index) => {
    if (!violation || violation.trim() === "") {
      newErrors[`codeConductViolationsTypeViolation${index}`] = textError;
    }
  });
  
  codeConductViolations.number_incidence.forEach((incidence, index) => {
    if (isNaN(incidence) || incidence <= 0) {
      newErrors[`codeConductViolationsNumberIncidence${index}`] = numberError;
    }
  });

  // Validate `antiCorruptionMeasures`
  if (antiCorruptionMeasures.minyears_experience_policy <= 0 && formData.antiCorruptionMeasures.policy==="yes" || isNaN(antiCorruptionMeasures.minyears_experience_policy)) {
    newErrors.antiCorruptionMeasuresMinYearsPolicy = numberError;
  }

  if (antiCorruptionMeasures.minyears_experience_training <= 0 && formData.antiCorruptionMeasures.training_program ==="yes" || isNaN(antiCorruptionMeasures.minyears_experience_training)) {
    newErrors.antiCorruptionMeasuresMinYearsTraining = numberError;
  }

  // Validate `whistleblowerPolicies`
  if (isNaN(whistleblowerPolicies.reports) || whistleblowerPolicies.reports <= 0 && formData.whistleblowerPolicies.existence==="yes") {
    newErrors.whistleblowerPoliciesReports = numberError;
  }

  if (isNaN(whistleblowerPolicies.resolution) || whistleblowerPolicies.resolution <= 0 && formData.whistleblowerPolicies.existence==="yes") {
    newErrors.whistleblowerPoliciesResolution = percentageError;
  }

  // Validate `complianceLawsRegulations`
  complianceLawsRegulations.type_compliance.forEach((compliance, index) => {
    if (!compliance || compliance.trim() === "") {
      newErrors[`complianceLawsRegulationsTypeCompliance${index}`] = textError;
    }
  });
  
  complianceLawsRegulations.frequency.forEach((freq, index) => {
    if (!freq || freq.trim() === "") {
      newErrors[`complianceLawsRegulationsFrequency${index}`] = textError;
    }
  });

  complianceLawsRegulations.fine_paid.forEach((fine, index) => {
    if (isNaN(fine) || fine <= 0) {
      newErrors[`complianceLawsRegulationsFinePaid${index}`] = numberError;
    }
  });

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};
// const handleRemoveField = (fieldName, index) => {
//   setOpenDialog(true);
//   setFieldToRemove({ fieldName, index });
//   setIndexDelete(index)
// };
const handleRemoveField = (fieldName, index) => {
  console.log(fieldName,"fieldName")
  setOpenDialog({...openDialog,[fieldName]:true});
  setFieldToRemove({ fieldName, index });
  setIndexDelete(index)
};
// const confirmRemoveField = () => {
//   console.log("fieldToRemove",fieldToRemove)
//   const updatedIncidenceResponsePlan = { ...formData.IncidenceResponsePlan };

//   // Ensure index is valid before removing
//   if (updatedIncidenceResponsePlan.existence.length > DeleteIndex) {
//     updatedIncidenceResponsePlan.existence.splice(DeleteIndex, 1);  // Remove item at specific index
//   }
  
//   if (updatedIncidenceResponsePlan.effectiveness.length > DeleteIndex) {
//     updatedIncidenceResponsePlan.effectiveness.splice(DeleteIndex, 1);  // Remove item at specific index
//   }

//   setFromData({
//     ...formData,
//     IncidenceResponsePlan: updatedIncidenceResponsePlan,  // Update formData with modified arrays
//   });

  

//   setOpenDialog(false);
// };

const confirmRemoveField = () => {
  if (!fieldToRemove) return;  // Ensure fieldToRemove exists

  // Clone current state for both objects
  const updatedPlan = { ...formData.codeConductViolations };
  const updatedPlan1 = { ...formData.complianceLawsRegulations };

  console.log("fieldToRemove:", fieldToRemove, "DeleteIndex:", DeleteIndex);

  // Remove items from both `existence` and `effectiveness` in IncidenceResponsePlan simultaneously
  if (fieldToRemove.fieldName === "number_incidence" || fieldToRemove.fieldName === "type_violation") {
    if (updatedPlan.type_violation?.length > DeleteIndex) {
      console.log("Before splice (type_violation):", updatedPlan.type_violation);
      updatedPlan.type_violation.splice(DeleteIndex, 1);  // Remove from type_violation
      console.log("After splice (type_violation):", updatedPlan.type_violation);
    }

    if (updatedPlan.number_incidence?.length > DeleteIndex) {
      console.log("Before splice (number_incidence):", updatedPlan.number_incidence);
      updatedPlan.number_incidence.splice(DeleteIndex, 1);  // Remove from number_incidence
      console.log("After splice (number_incidence):", updatedPlan.number_incidence);
    }
  } 
  // Remove items from both `esg_risk` and `mitigation` in indentificationMitigationEsgRisk simultaneously
  else if (fieldToRemove.fieldName === "type_compliance" || fieldToRemove.fieldName === "fine_paid" ||
    fieldToRemove.fieldName === "frequency"
  ) {
    if (updatedPlan1.type_compliance?.length > DeleteIndex) {
      console.log("Before splice (type_compliance):", updatedPlan1.type_compliance);
      updatedPlan1.type_compliance.splice(DeleteIndex, 1);  // Remove from type_compliance
      console.log("After splice (type_compliance):", updatedPlan1.type_compliance);
    }
    if (updatedPlan1.fine_paid?.length > DeleteIndex) {
      console.log("Before splice (fine_paid):", updatedPlan1.fine_paid);
      updatedPlan1.fine_paid.splice(DeleteIndex, 1);  // Remove from fine_paid
      console.log("After splice (fine_paid):", updatedPlan1.fine_paid);
    }

    if (updatedPlan1.frequency?.length > DeleteIndex) {
      console.log("Before splice (frequency):", updatedPlan1.frequency);
      updatedPlan1.frequency.splice(DeleteIndex, 1);  // Remove from frequency
      console.log("After splice (frequency):", updatedPlan1.frequency);
    }
  } 
  else {
    console.warn(`No valid field found for ${fieldToRemove.fieldName}`);
    return;
  }

  // Merge the updated plans back into formData
  setFromData((prevState) => ({
    ...prevState,
    IncidenceResponsePlan: updatedPlan,  // Update IncidenceResponsePlan
    indentificationMitigationEsgRisk: updatedPlan1  // Update indentificationMitigationEsgRisk
  }));

  console.log("Updated formData:", formData);

  // Close the dialog and reset state
  setOpenDialog({ ...openDialog, [fieldToRemove.fieldName]: false });
  setFieldToRemove(null);
  setIndexDelete(null);
};

const cancelRemoveField = () => {
  setOpenDialog(false);
};
// const handleChangeConductViolations = (e,index,fieldType)=>{
//   const {name,value}=e.target
// setFromData({
//   ...formData,
//   codeConductViolations:{
//     ...formData.codeConductViolations,
//     [name]:value
//   }
// })
// }
const handleChangeConductViolations = (e, index, fieldType) => {
  const { name, value } = e.target;

  setFromData((prevData) => {
    const updatedViolations = [...prevData.codeConductViolations[fieldType]]; // Clone the array
    updatedViolations[index] = value; // Update the specific index with the new value

    return {
      ...prevData,
      codeConductViolations: {
        ...prevData.codeConductViolations,
        [fieldType]: updatedViolations, // Update the correct fieldType array
      }
    };
  });
};

const handleChangeAntiCorruption=(e)=>{
  const {name,value}=e.target
  setFromData({
    ...formData,
    antiCorruptionMeasures:{
      ...formData.antiCorruptionMeasures,
      [name]:value
    }
  })
}
const handleWhistle=(e)=>{
  const {name,value}=e.target
  setFromData({
    ...formData,
    whistleblowerPolicies:{
      ...formData.whistleblowerPolicies,
      [name]:value
    }
  })
}
const handleCompliance=(e,index,fieldType)=>{
const {name,value}=e.target
// setFromData({
//   ...formData,
//   complianceLawsRegulations:{
//     ...formData.complianceLawsRegulations,
//     [name]:value
//   }
// })
const updatedCommittees = { ...formData.complianceLawsRegulations };
updatedCommittees[fieldType][index] = value;
setFromData({
  ...formData,
  complianceLawsRegulations: updatedCommittees,
});
}
const AddConductViolations=()=>{
  const updatedCommittees = { ...formData.codeConductViolations };
  updatedCommittees.number_incidence.push('');
  updatedCommittees.type_violation.push('');
  setFromData({
    ...formData,
    codeConductViolations: updatedCommittees,
  });
}
const AddCompliance=()=>{
  const updatedCommittees = { ...formData.complianceLawsRegulations };
  updatedCommittees.type_compliance.push('');
  updatedCommittees.frequency.push('');
  updatedCommittees.fine_paid.push('');
  setFromData({
    ...formData,
    complianceLawsRegulations: updatedCommittees,
  });
}
console.log(formData)
useEffect(()=>{
  setUpdate("GOVERNANCE");
//  const companyId = companyId
  axios.get(`${api}/governance/ethic-compensation/${companyId}`).then((res)=>{
    console.log(res,res.data.codeConductViolations.type_violation)
    setFromData({
      codeConductViolations:{
        type_violation:res.data.codeConductViolations.type_violation,
        number_incidence:res.data.codeConductViolations.number_incidence
      },
      antiCorruptionMeasures:{
        policy:res.data.antiCorruptionMeasures.policy===true?"yes":"no",
        training_program:res.data.antiCorruptionMeasures.training_program===true?"yes":"no",
        minyears_experience_policy:res.data.antiCorruptionMeasures.minyears_experience_policy,
        minyears_experience_training:res.data.antiCorruptionMeasures.minyears_experience_training
      },
      whistleblowerPolicies:{
        existence:res.data.whistleblowerPolicies.existence===true?"yes":"no",
        reports:res.data.whistleblowerPolicies.reports,
        resolution:res.data.whistleblowerPolicies.resolution,
  
      },
      complianceLawsRegulations:{
        type_compliance:res.data.complianceLawsRegulations.type_compliance,
        frequency:res.data.complianceLawsRegulations.frequency,
        fine_paid:res.data.complianceLawsRegulations.fine_paid
      }
    })
    setISExist(true)
  }).catch(err=>console.log(err))
},[])
  const Save = () => {
  // const  companyId= companyId;
  if (!validateForm()) {
    return;
  }
    axios({method:IsExist?'put':'post',
      url:`${api}/governance/ethic-compensation${IsExist ? `/${companyId}` : ""}`,
      data:formData}).then((res)=>{
      console.log(res)
      // alert(res.data.message)
      navigate('/governance/risk-management');
    }).catch((err)=>{
      alert(err.message)
      console.log(err)
    })
  };

  const Back = () => {
    navigate('/governance/exclutive-compensation');
  };

  return (
    <Box  display="flex"  sx={{backgroundColor: "#EDEDED",  display: "flex",
      backgroundColor: "#EDEDED",
      height: "100vh",
      overflow: "hidden"}}>
      <Dialog open={openDialog[fieldToRemove?.fieldName]} onClose={()=>cancelRemoveField(openDialog)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this field?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>cancelRemoveField(openDialog)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRemoveField} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Sidebar title="Ethics and Compliance" />
      <Box  sx={{
          p: 2,
          // mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}
      >
        <Typography variant="h5"
          sx={{
            fontWeight: "bold",
             mt: "2rem",
            zIndex: 1,
            padding: "10px",
            borderRadius: "4px",
            alignSelf: "flex-start",
            ml:"2rem"}}
        >
          GOVERNANCE: ETHICS AND COMPLIANCE
        </Typography>

        <Box sx={{width:"90%"}}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading" gutterBottom>
            Code of Conduct Violations
          </Typography>
          
       
       {/* <Grid container spacing={2} mb={2}  alignItems="center" sx={{width: '93%'}}> */}
     {formData.codeConductViolations.type_violation.map((item,i)=>{
      return  <Box
      key={i}
      sx={{ width: "101%", display: "flex", gap: 1 }}
    > <Grid container spacing={2} mb={2}  alignItems="center" sx={{width: '91%'}}>
      <Grid item xs={12}  md={6}>
              <TextField
                variant="outlined"
                placeholder="Types of Violation"
                className="inputfieldEquity"
                name ="type_violation"
                value={item}
                onChange={(e) => handleChangeConductViolations(e, i, 'type_violation')}
                // onChange={handleChangeConductViolations}
                fullWidth
                InputProps={{
                  style: { borderColor: errors[`codeConductViolationsTypeViolation${i}`]? '#D34040' : undefined},
                }} sx={{marginRight:"5px",width:"100%"}}
                error={!!errors[`codeConductViolationsTypeViolation${i}`]} // Show error styling if there's an error
                InputLabelProps={{
                  style: { color: errors[`codeConductViolationsTypeViolation${i}`] ? '#D34040' : undefined },
                }}
              />
                {errors[`codeConductViolationsTypeViolation${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`codeConductViolationsTypeViolation${i}`]}
            </Typography>
          )}
            </Grid>
            
            <Grid item xs={12} md={6}>
            <Box  display="flex">
              
              <TextField
                variant="outlined"
                placeholder="Number of Incidence"
                className="inputfieldEquity"
                name='number_incidence'
                onChange={(e) => handleChangeConductViolations(e, i, 'number_incidence')}
                // onChange={handleChangeConductViolations}
                // value={formData.codeConductViolations.number_incidence===0?"":
                //   formData.codeConductViolations.number_incidence}
                value={formData.codeConductViolations.number_incidence[i] === 0 ? '' : formData.codeConductViolations.number_incidence[i]}
                fullWidth
                InputProps={{
                  style: { borderColor: errors[`codeConductViolationsNumberIncidence${i}`] ? '#D34040' : undefined},
                }} sx={{marginRight:"5px",width:"100%"}}
                error={!!errors[`codeConductViolationsNumberIncidence${i}`]} // Show error styling if there's an error
                InputLabelProps={{
                  style: { color: errors[`codeConductViolationsNumberIncidence${i}`] ? '#D34040' : undefined },
                }}
              />
              </Box>
                {errors[`codeConductViolationsNumberIncidence${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`codeConductViolationsNumberIncidence${i}`]}
            </Typography>
          )}
          
            </Grid>
            </Grid>
            {i > 0 && (
                    <IconButton sx={{ mb: 2,width:"5%" }}>
                      <DeleteIcon
                        onClick={() =>
                          handleRemoveField("number_incidence", i)
                        }
                      />
                    </IconButton>
                  )}  
            </Box> })}  
            <Grid item xs={12} display="flex" justifyContent="flex-end" sx={{ mt: 1 ,width:"90%"}}>
  <Button 
    variant="outlined" 
   sx={{color:"#0a3f73",textTransform:"none"}} 
    className="add" 
    onClick={AddConductViolations}
    endIcon={<AddIcon />} // Use startIcon to place the icon before the text
  >
    Add
  </Button>
</Grid>

          {/* </Grid> */}
      
        

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }} className="heading" gutterBottom>
            Anti-Corruption Measures
          </Typography>
         <Grid display="flex" gap="70px" width={"70%"} justifyContent={"space-between"}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
              Policy
            </Typography>
            <RadioGroup row name="policy"
            value={formData.antiCorruptionMeasures.policy}
            onChange={handleChangeAntiCorruption}  sx={{gap:"20px"}}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
              Training Program
            </Typography>
            <RadioGroup row name="training_program" 
            value={formData.antiCorruptionMeasures.training_program}
            onChange={handleChangeAntiCorruption}  sx={{gap:"20px"}} defaultValue="yes">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
          </Grid>
          <Box display="flex" width="90%" gap="13px">
      {formData.antiCorruptionMeasures.policy==="yes"? 
      <Box sx={{width:"100%"}}>  
       <TextField
            variant="outlined"
            placeholder="Min. years of Policy experience"
            className="inputfieldcommittees"
            name = "minyears_experience_policy"
            value={formData.antiCorruptionMeasures.minyears_experience_policy===0?"":
              formData.antiCorruptionMeasures.minyears_experience_policy
            }
            onChange={handleChangeAntiCorruption}
            fullWidth
            InputProps={{
              style: { borderColor: errors.antiCorruptionMeasuresMinYearsPolicy ? '#D34040' : undefined},
            }} sx={{marginRight:"5px",width:"100%",mb:"3"}}
            error={!!errors.antiCorruptionMeasuresMinYearsPolicy} // Show error styling if there's an error
            InputLabelProps={{
              style: { color: errors.antiCorruptionMeasuresMinYearsPolicy ? '#D34040' : undefined },
            }}
          />
            {errors.antiCorruptionMeasuresMinYearsPolicy && (
            <Typography variant="body2" color="#D34040">
              {errors.antiCorruptionMeasuresMinYearsPolicy}
            </Typography>
          )}
          </Box>:<Box sx={{width:"100%"}}></Box>}
     {formData.antiCorruptionMeasures.training_program ==="yes"?    
     <Box sx={{width:"100%"}}>
     <TextField
            variant="outlined"
            placeholder="Min. years of Training experience"
            className="inputfieldcommittees"
            name = "minyears_experience_training"
            value={formData.antiCorruptionMeasures.minyears_experience_training===0?"":
              formData.antiCorruptionMeasures.minyears_experience_training
            }
            onChange={handleChangeAntiCorruption}
          fullWidth
            InputProps={{
              style: { borderColor: errors.antiCorruptionMeasuresMinYearsTraining ? '#D34040' : undefined},
            }} sx={{marginRight:"5px",width:"100%",mb: 0}}
            error={!!errors.antiCorruptionMeasuresMinYearsTraining} // Show error styling if there's an error
            InputLabelProps={{
              style: { color: errors.antiCorruptionMeasuresMinYearsTraining ? '#D34040' : undefined },
            }}
          />
            {errors.antiCorruptionMeasuresMinYearsTraining && (
            <Typography variant="body2" color="#D34040">
              {errors.antiCorruptionMeasuresMinYearsTraining}
            </Typography>
          )}
          </Box>
          :
          <Box  sx={{width:"100%"}}></Box>}
          </Box>
          

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }} className="heading" gutterBottom>
            Whistleblower Policies
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Existence
          </Typography>
          <RadioGroup row name="existence"
          value={formData.whistleblowerPolicies.existence}
          onChange={handleWhistle}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>

          {/* <Box > */}
      {formData.whistleblowerPolicies.existence==="yes"?
       <Grid container xs={12} sx={{width:"92%"}} spacing={2} alignItems="center">
       <Grid item xs={12} md={6} fullWidth>
        <>
       <TextField
        variant="outlined"
        placeholder="Number of Reports"
        name='reports'
        value={formData.whistleblowerPolicies.reports===0?"":
          formData.whistleblowerPolicies.reports
        }
        onChange={handleWhistle}
        className="inputfieldcommittee"
        InputProps={{
          style: { borderColor: errors.linkageExecutiveCompensationEsgPerformanceBrief ? '#D34040' : undefined},
        }} sx={{marginRight:"5px",width:"100%", mb: 0}}
        error={!!errors.whistleblowerPoliciesReports} // Show error styling if there's an error
        InputLabelProps={{
          style: { color: errors.whistleblowerPoliciesReports ? '#D34040' : undefined },
        }}
      />
        {errors.whistleblowerPoliciesReports && (
            <Typography variant="body2" color="#D34040">
              {errors.whistleblowerPoliciesReports}
            </Typography>
          )}
          </>
       </Grid>
        <Grid item xs={12} md={6} sx={{mt:2}} fullWidth>
          <>
          <TextField
            variant="outlined"
            placeholder="Resolution"
            name='resolution'
            value={formData.whistleblowerPolicies.resolution===0?"":
              formData.whistleblowerPolicies.resolution
            }
            fullWidth
            onChange={handleWhistle}
            sx={{mb:errors.whistleblowerPoliciesResolution ?0:2,mt:errors.whistleblowerPoliciesResolution ?-2:0}}
            
            InputProps={{
              style: { 
                borderColor: errors.whistleblowerPoliciesResolution ? '#D34040' : undefined 
              },
              sx: { marginRight: "5px", width: "100%" },
              endAdornment:  <InputAdornment position="end" sx={{ marginLeft: "8px" }}>
              <div
                style={{
                  borderLeft: "1px solid #000", // Color of the vertical line
                  height: "24px", // Height of the line
                  marginRight: "8px", // Space between the line and the percentage symbol
                }}
              />
              %
            </InputAdornment>,
                
              // <InputAdornment position="end">%</InputAdornment>,
            }}
           
            error={!!errors.whistleblowerPoliciesResolution} // Show error styling if there's an error
            InputLabelProps={{
              style: { color: errors.whistleblowerPoliciesResolution ? '#D34040' : undefined },
            }}
          />
            {errors.whistleblowerPoliciesResolution && (
            <Typography variant="body2" color="#D34040">
              {errors.whistleblowerPoliciesResolution}
            </Typography>
          )}
          </>
        </Grid>
        {/* <Grid item xs={2}>
          <Typography variant="body1" className='ghenergy'>%</Typography>
        </Grid> */}
      </Grid>
      :""}     
          {/* </Box> */}

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }} className="heading" gutterBottom>
          Compliance with Laws and Regulations
          </Typography>

   {formData.complianceLawsRegulations.type_compliance.map((item,i)=>{
    return   <Box
    key={i}
    sx={{ width: "101%", display: "flex", gap: 1 }}
  >
     <Grid container spacing={2} mb={2} sx={{width:"91%"}}>
    <Grid item xs={12} md={4}>
      <TextField
        variant="outlined"
        placeholder="Type of Compliance"
        name='type_compliance'
        value={item}
        // onChange={handleCompliance}
        onChange={(e) => handleCompliance(e, i, 'type_compliance')}
        fullWidth
        InputProps={{
          style: { borderColor:errors[`complianceLawsRegulationsTypeCompliance${i}`]? '#D34040' : undefined},
        }}
         sx={{marginRight:"5px",width:"100%"}}
        error={!!errors[`complianceLawsRegulationsTypeCompliance${i}`]} // Show error styling if there's an error
        InputLabelProps={{
          style: { color: errors[`complianceLawsRegulationsTypeCompliance${i}`] ? '#D34040' : undefined },
        }}
      />
        {errors[`complianceLawsRegulationsTypeCompliance${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`complianceLawsRegulationsTypeCompliance${i}`]}
            </Typography>
          )}
    </Grid>
    <Grid item xs={12} md={4}>
      <TextField
        variant="outlined"
        placeholder="frequency"
        name='frequency'
        value={formData.complianceLawsRegulations.frequency[i]===0?"":
          formData.complianceLawsRegulations.frequency[i]
        }
        onChange={(e) => handleCompliance(e, i, 'frequency')}
        fullWidth
        InputProps={{
          style: { borderColor:errors[`complianceLawsRegulationsFrequency${i}`]? '#D34040' : undefined},
        }}
         sx={{marginRight:"5px",width:"100%"}}
        error={!!errors[`complianceLawsRegulationsFrequency${i}`]} // Show error styling if there's an error
        InputLabelProps={{
          style: { color: errors[`complianceLawsRegulationsFrequency${i}`] ? '#D34040' : undefined },
        }}
      />
        {errors[`complianceLawsRegulationsFrequency${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`complianceLawsRegulationsFrequency${i}`]}
            </Typography>
          )}
    </Grid>
    <Grid item xs={12} md={4}>
      <Box display="flex">
      <TextField
        variant="outlined"
        placeholder="Fine Paid"
        name='fine_paid'
        value={formData.complianceLawsRegulations.fine_paid[i]===0?"":
          formData.complianceLawsRegulations.fine_paid[i]
        }
        onChange={(e) => handleCompliance(e, i, 'fine_paid')}
        fullWidth
        InputProps={{
          style: { borderColor:errors[`complianceLawsRegulationsFinePaid${i}`]? '#D34040' : undefined},
        }}
         sx={{marginRight:"5px",width:"100%"}}
        error={!!errors[`complianceLawsRegulationsFinePaid${i}`]} // Show error styling if there's an error
        InputLabelProps={{
          style: { color: errors[`complianceLawsRegulationsFinePaid${i}`] ? '#D34040' : undefined },
        }}
      />
     
</Box>
        {errors[`complianceLawsRegulationsFinePaid${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`complianceLawsRegulationsFinePaid${i}`]}
            </Typography>
          )}
    </Grid>
  </Grid>
  {i > 0 && (
                    <IconButton sx={{ mt: 0,width:"5%" }}>
                      <DeleteIcon
                        onClick={() =>
                          handleRemoveField("fine_paid", i)
                        }
                      />
                    </IconButton>
                  )} 
  </Box>
   })}  
        
          <Box sx={{width:"90%",display:"flex",
            justifyContent:"flex-end"
          }}>
          <Button 
    variant="outlined" 
   sx={{color:"#0a3f73",margin:"15px 0px",textTransform:"none"}} 
    className="add" 
    endIcon={<AddIcon />} // Use startIcon to place the icon before the text
 onClick={AddCompliance}
 >
    Add
  </Button >
  </Box>
  <ActionButtons Back={Back} Move={Save}/>
        </Box>

        <Box display="flex" justifyContent="space-between" width="70%" sx={{ mt: 4 }}>
          {/* <Button
            variant="outlined"
            onClick={Back}
            startIcon={<ArrowBackIcon />}
            sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{backgroundColor:"#0a3f73",textTransform:"none"}}
            onClick={Save}
            endIcon={<ArrowForwardIcon />}
          >
            Save and continue
          </Button> */}
           {/* <ActionButtons Back={Back} Move={Save}/> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Ethics;
