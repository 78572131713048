import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


function ViewPetroleum(props) {


  useEffect(() => {
    
  }, [props]);

  
  const handleDownload = (url) => {
    const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/"); // Force download as an attachment
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{width:400,
        // maxWidth:"100%",
        height: '100vh',
        display:"flex",justifyContent:"space-btween",
          backgroundColor:"transparent"
      }}
    >
    
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setViewPetroleum(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll"}}
      >
        <Typography
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          Petroleum/Gasoline Details
        </Typography>
        <Box>
          <Box display="flex" gap={10}>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                Date
              </Typography>

              <p>
                
                {new Date(props.selectedPetroleum?.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) ?? ""}
                {/* {new Date(props.selectedPetroleum?.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }) ?? ""} */}
              </p>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                IndustrialProcess
              </Typography>
              <p> {props.selectedPetroleum?.industrialProcess.industryProcess?? ""}</p>
            </Box>
          </Box>
        </Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Equipment Type
        </Typography>
        <p>
          {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
          {props.selectedPetroleum?.equipmentType?.name ?? ""}
        </p>
        <Box display="flex" gap={10}>
          <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Type of Entry
        </Typography>
        <p>
          {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
          {props.selectedPetroleum?.typeOfEntry ?? ""}
        </p>
        </Box>
        <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Equipment
        </Typography>
        <p>{props.selectedPetroleum?.industrialEquipment?.name ?? ""}</p>
        </Box>
        </Box>
        <Box display="flex" gap={10}>
          <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
         Quantity Consumed
        </Typography>
        <p>
          {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
          {props.selectedPetroleum?.quantityOfPetrolConsumed ?? ""}
        </p>
        </Box>
        <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Unit of Quantity
        </Typography>
        <p>{props.selectedPetroleum?.unit ?? ""}</p>
        </Box>
        </Box>
        <Box display="flex" gap={10}>
          <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Hours Used
        </Typography>
        <p>
          {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
          {props.selectedPetroleum?.hours ?? ""}
        </p>
        </Box>
        <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Calorific Value
        </Typography>
        <p>{props.selectedPetroleum?.calorificValue?.unit ?? ""}</p>
        </Box>
        </Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Remark
        </Typography>
        <p>{props.selectedPetroleum?.remarks ?? ""}</p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Documents
        </Typography>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {props.selectedPetroleum?.documents?.map((doc, index) => (
            <Box key={index} sx={{ position: "relative" }}>
              <img
                src={doc}
                alt={`Document ${index + 1}`}
                style={{
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
              <IconButton
                onClick={() => handleDownload(doc)}
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  color: "white",
                  backgroundColor: "rgba(0,0,0,0.5)",
                }}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
          ))}
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={props.handleEdit}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.viewPetroleum}
        onClose={() => props.setViewPetroleum(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default ViewPetroleum;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
