import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import InputField from "../../../../Components/Masters/InputField";
import SelectField from "../../../../Components/Masters/SelectField";
import FileField from "../../../../Components/Masters/FileField";

function AddWaste({
  inputData,
  fetchData,
  id,
  data,
  element,
  setAddData,
  addData,
  setsnackOpen,
  addUrl,
  updateUrl,
}) {
  const [errors, setErrors] = useState({});
  const initialState={
    vendorLegalName: element?.vendorLegalName || "",
    facility: element?.facility._id || "",
    wasteManagementType: element?.wasteManagementType || "",
    govtVendorRegistrationNumber: element?.govtVendorRegistrationNumber || "",
    documents: element?.documents || [], // Use an array here
    company:element?.company  || companyId
   };

  const [formData, setFormData] = useState(initialState);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [uploadProgress, setUploadProgress] = useState({}); // Holds progress for each file

  useEffect(() => {
    setFormData(initialState)
   setUploadedFiles(element?.documents || []); // Populate with existing documents
  }, [element, id]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));


    if (name === "vendorLegalName") {
      const regex = new RegExp(`^${value}$`, "i"); // 'i' flag makes it case-insensitive

      const vendor=data.filter((el) =>regex.test(el.vendorLegalName))
      if (vendor.length > 0  && (element ? element._id !== vendor[0]._id : true)) {
        setErrors({ ...errors, [name]: "Vendor Legal Name already exists." });
      }
      return;
    }

    if (name === "govtVendorRegistrationNumber") {
      const regex = new RegExp(`^${value}$`, "i"); // 'i' flag makes it case-insensitive

      const govtNum=data.filter((el) =>regex.test(el.govtVendorRegistrationNumber))
      if (govtNum.length > 0  && (element ? element._id !== govtNum[0]._id : true)) {
        setErrors({ ...errors, [name]: "Registration Number already exists." });
      }
      return;
    }
  }
  

  const handleClose = () => {
    setAddData(false);
    setErrors({});
    setFormData(initialState)
    setUploadedFiles(element?.documents || []); // Populate with existing documents
  };

  const validateForm = () => {
    const newErrors = {};

    inputData.forEach((input) => {
      const value = formData[input.fieldName];
      console.log(input.fieldName,uploadedFiles)

      if (typeof value === "string" && (!value || value.trim() === "")) {
        newErrors[input.fieldName] = `${input.title} is required.`;
        return;
      }

      if (input.fieldName === "vendorLegalName") {
        const regex = new RegExp(`^${value}$`, "i"); // 'i' flag makes it case-insensitive
  
        const vendor=data.filter((el) =>regex.test(el.vendorLegalName))
        if (vendor.length > 0  && (element ? element._id !== vendor[0]._id : true)) {
         newErrors[input.fieldName]= "Vendor Legal Name already exists." ;
        }
      }
  
      if (input.fieldName === "govtVendorRegistrationNumber") {
        const regex = new RegExp(`^${value}$`, "i"); // 'i' flag makes it case-insensitive
  
        const govtNum=data.filter((el) =>regex.test(el.govtVendorRegistrationNumber))
        if (govtNum.length > 0  && (element ? element._id !== govtNum[0]._id : true)) {
          newErrors[input.fieldName]= "Registration Number already exists." ;
        }
      }

      if (input.fieldName === "documents" && uploadedFiles.length === 0) {
       console.log(input.fieldName,uploadedFiles)
        newErrors[input.fieldName] = `Please upload Documents`;
      }
    });
console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const Save = async () => {
    if (!validateForm()) return;
  
    console.log(uploadedFiles,formData)
    const dataToSend = {
      ...formData,
      documents:uploadedFiles, // This should include all uploaded files
    };

    console.log(dataToSend)
  
    await axios({
      method: id ? "put" : "post",
      url: id ? `${updateUrl}/${id}` : addUrl,
      data: dataToSend,
      // params: id ? { id } : null,
    })
      .then((res) => {
        setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        fetchData();
        setUploadedFiles([])
        handleClose();
      })
      .catch((err) => {
        console.log(err)
        if (err.response && err.response.data && err.response.data.message) {
          const errorMessages = err.response.data.message;
          setsnackOpen({
            open: true,
            message: errorMessages || err.response.data.message,
            severity: "error",
          });
        }
      });
  };
  
  
  const renderInputField = (el) => {
    if (el.options || el.type === "select") {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          options={el.options}
          errors={errors[el.fieldName]}
          url={el.url}
        />
      );
    }

    if (el.type === "file") {
      return (
        <FileField
          title={el.title}
          name={el.fieldName}
          errors={errors[el.fieldName]}
          fileUrls={uploadedFiles || []}
          progress={uploadProgress[el.fieldName] || []}
          {...{setUploadedFiles,setUploadProgress,setErrors}}
          // onDelete={handleDeleteFile}
        />
      );
    }

    return (
      <InputField
        title={el.title}
        handleChange={handleChange}
        placeholder={el.placeholder}
        value={formData[el.fieldName] || ""}
        errors={errors[el.fieldName]}
        fieldName={el.fieldName}
      />
    );
  };

  const list = () => (
    <Box display="flex" gap={3} sx={{ width: "400px" }}>
    <Box
      sx={{
        backgroundColor: "#fff",
        height: "20px",
        padding: "13px",
        margin: "30px 0 0 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "80%",
        width: "5%",
      }}
    >
        <IconButton
          onClick={handleClose}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "90%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {id ? "Edit Waste Management Vendor" : "Add Waste Management Vendor"}
        </Typography>
        {inputData.map((el) => (
          <div key={el.fieldName}>{renderInputField(el)}</div>
        ))}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
    sx={{
      backgroundColor: "transparent",
      "& .MuiDrawer-paper": {
        backgroundColor: "transparent",
        boxShadow: "none",
        height: "100vh",
        minHeight: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
      },
    }}
  anchor="right"
  open={addData}
  onClose={()=>{setUploadedFiles({});
  handleClose();}}
>
  {list()}
</Drawer>


  );
}

export default AddWaste;
