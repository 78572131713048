import React, { useState,useEffect,useContext } from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import {ProgressContext} from "../ContextAPI";
import "./ExcutiveCompensation.css";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextareaAutosize,
  Grid,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';
import {api} from '../../../utils/api';
import { companyId } from "../../../utils/companyId";
import ActionButtons from '../InitialKickoff/MaterialTopics/ActionButtons';
import {unitError,listError,textError,percentageError} from "../../../utils/validationMessage";

const ExclutiveCompensation = () => {
  const { setUpdate } = useContext(ProgressContext);
  const navigate = useNavigate();
  const [isExist,setIsExist] = useState(false);
  const [formData,setFormdata] = useState({
    companyId:companyId,
    ceoPayRatio:{
      ceo_pay:0,
      median_emp_pay:0,
    },
    linkageExecutiveCompensationEsgPerformance:{
      radio:"yes",
      brief:""
    }
  });
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
  
    const { 
      ceoPayRatio,
      linkageExecutiveCompensationEsgPerformance,
    } = formData;
  
    // Validate `ceoPayRatio`
    if (!ceoPayRatio.ceo_pay || isNaN(ceoPayRatio.ceo_pay) || ceoPayRatio.ceo_pay <= 0 || ceoPayRatio.ceo_pay > 100) {
      newErrors.ceoPayRatioCeoPay = percentageError;
    }
    if (!ceoPayRatio.median_emp_pay || isNaN(ceoPayRatio.median_emp_pay) || ceoPayRatio.median_emp_pay <= 0 || ceoPayRatio.median_emp_pay > 100) {
      newErrors.ceoPayRatioMedianEmpPay = percentageError;
    }

    if (linkageExecutiveCompensationEsgPerformance.radio === "yes" && 
        (!linkageExecutiveCompensationEsgPerformance.brief || linkageExecutiveCompensationEsgPerformance.brief.trim() === "")) {
      newErrors.linkageExecutiveCompensationEsgPerformanceBrief = textError;
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
useEffect(()=>{
  setUpdate("GOVERNANCE");
//  const companyId=companyId;
  axios.get(`${api}/governance/excutive-compensation/${companyId}`).then((res)=>{
    console.log(res,res.data.linkageExecutiveCompensationEsgPerformance.radio)
    setFormdata({
    ceoPayRatio:{
      ceo_pay:res.data.ceoPayRatio.ceo_pay,
      median_emp_pay:res.data.ceoPayRatio.median_emp_pay,
    },
    linkageExecutiveCompensationEsgPerformance:{
      radio:res.data.linkageExecutiveCompensationEsgPerformance.radio ===true? "yes":"no",
      brief:res.data.linkageExecutiveCompensationEsgPerformance.brief
    }
    
  });
  setIsExist(true);
  }).catch((err)=>{
     console.log(err)
  })
},[])
  const handleChange = (e)=>{
   const {name,value}=e.target
   setFormdata({
    ...formData,
    ceoPayRatio:{
      ...formData.ceoPayRatio,
      [name]:value
    }
   })
   console.log(name,value)
  }
  const handleChangeRadio = (e)=>{
    const {name,value}=e.target
    setFormdata({
      ...formData,
      linkageExecutiveCompensationEsgPerformance:{
      ...formData.linkageExecutiveCompensationEsgPerformance,
      [name]:value
    }
    })
    console.log(e.target.value)
  }
  console.log(formData)
  const Save = () => {
    if (!validateForm()) {
      return;
    }
    // const companyId=companyId
    axios({method:isExist?'put':'post',
      url:`${api}/governance/excutive-compensation${isExist ? `/${companyId}` : ""}`,
      data:formData}).then((res)=>{
    console.log(res)
    // alert(res.statusText);
    navigate('/governance/ethics');
   }).catch((err)=>{
    alert(err.message)
   })
  };

  const Back = () => {
    navigate('/governance/board-composition');
  };

  return (
    <Box display="flex"  sx={{backgroundColor: "#EDEDED",  display: "flex",
      backgroundColor: "#EDEDED",
      height: "100vh",
      overflow: "hidden"}}
     >
      <Sidebar title="Executive compensation" />
      <Box 
      sx={{
        p: 2,
        mt: 3,
        width: "100%",
        height: "100vh", // Full viewport height
        overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start", // Adjust to start at the top of the box
      }}>
        <Typography variant="h5" 
        sx={{
          fontWeight: "bold",
           mt: "2rem",
          zIndex: 1,
          padding: "10px",
          borderRadius: "4px",
          alignSelf: "flex-start",
          ml:"5rem"          }}
        >

          GOVERNANCE: EXECUTIVE COMPENSATION
        </Typography>

        <Box sx={{width:"80%"}}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} 
          gutterBottom>
            CEO Pay Ratio
          </Typography>

          <Grid item xs={12} md={12}  spacing={3} gap={1}
           className="CEOPay" alignItems="center">
            {/* <Grid item xs={12} md={6}> */}
            <Box sx={{width:"100%"}}>
              <TextField
                variant="outlined"
                placeholder="CEO Pay"
                // className="inputfieldPay"
                name='ceo_pay'
                value={formData.ceoPayRatio.ceo_pay===0?"":
                  formData.ceoPayRatio.ceo_pay
                }
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
                        %
                      </Box>
                    </InputAdornment>
                  ),
                  style: { borderColor: errors.ceoPayRatioCeoPay ? '#D34040' : undefined},
                }} sx={{marginRight:"5px",width:"100%"}}

                error={!!errors.ceoPayRatioCeoPay} // Show error styling if there's an error
                InputLabelProps={{
                  style: { color: errors.ceoPayRatioCeoPay ? '#D34040' : undefined },
                }}
              />
                {errors.ceoPayRatioCeoPay && (
          <Typography variant="body2" color="#D34040">
            {errors.ceoPayRatioCeoPay}
          </Typography>
        )}
              </Box>
              :
              <Box sx={{width:"100%"}}>
              <TextField
                variant="outlined"
                placeholder="Median Employee Pay"
                // className="inputfieldPay"
                name='median_emp_pay'
                onChange={handleChange}
                value={formData.ceoPayRatio.median_emp_pay===0?"":
                  formData.ceoPayRatio.median_emp_pay
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
                        %
                      </Box>
                    </InputAdornment>
                  ),
                  style: { borderColor: errors.ceoPayRatioMedianEmpPay ? '#D34040' : undefined},
                }} sx={{marginRight:"5px",width:"100%"}}

                error={!!errors.ceoPayRatioMedianEmpPay} // Show error styling if there's an error
                InputLabelProps={{
                  style: { color: errors.ceoPayRatioMedianEmpPay ? '#D34040' : undefined },
                }}
                
              />
              {errors.ceoPayRatioMedianEmpPay && (
          <Typography variant="body2" color="#D34040">
            {errors.ceoPayRatioMedianEmpPay}
          </Typography>
        )}
              </Box>
              
            {/* </Grid> */}
          </Grid>

          <Typography variant="subtitle1" className="paragraph" sx={{ fontWeight: 'bold', mb: 1 }} gutterBottom mt={2}>
            Linkage of Executive Compensation to ESG Performance
          </Typography>
          
          <RadioGroup className="radiobutton" name='radio'
          value={formData.linkageExecutiveCompensationEsgPerformance.radio}
          onChange={handleChangeRadio}
          row>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          {formData.linkageExecutiveCompensationEsgPerformance.radio==="yes"?
            <Box>
          <TextField
            rows={5}
            multiline
            placeholder="Brief"
            className="brief"
            name='brief'
            value={formData.linkageExecutiveCompensationEsgPerformance.brief}
            onChange={handleChangeRadio}
            style={{ width: '83%', marginTop: '15px' }}
            InputProps={{
              style: { borderColor: errors.linkageExecutiveCompensationEsgPerformanceBrief ? '#D34040' : undefined},
            }} sx={{marginRight:"5px",width:"100%"}}
            error={!!errors.linkageExecutiveCompensationEsgPerformanceBrief} // Show error styling if there's an error
            InputLabelProps={{
              style: { color: errors.linkageExecutiveCompensationEsgPerformanceBrief ? '#D34040' : undefined },
            }}
            
          />
          {errors.linkageExecutiveCompensationEsgPerformanceBrief && (
            <Typography variant="body2" color="#D34040">
              {errors.linkageExecutiveCompensationEsgPerformanceBrief}
            </Typography>
          )}
        </Box>
          :""}
         <ActionButtons Back={Back} Move={Save}/>
        </Box>
        {/* <Box className="savaAnBackExcutive"  display="flex" justifyContent="space-between" width="100%">
          <Button variant="outlined" sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}} onClick={Back} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
          <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none"}} onClick={Save} endIcon={<ArrowForwardIcon />}>
            Save and continue
          </Button>
        </Box> */}
      
      </Box>
    </Box>
  );
};

export default ExclutiveCompensation;
