

import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import React, { useRef, useEffect } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material'; // Import MUI components

// Register the necessary components for Chart.js
Chart.register(ArcElement, Tooltip, Legend);

const PieChartCard = ({ title, chartData, labels, backgroundColors }) => {
  const chartRef = useRef(null); // Create a reference to the chart

  useEffect(() => {
    // Cleanup function to destroy the chart on unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy(); // Ensure the chart is destroyed properly
      }
    };
  }, []);

  const data = {
    // labels: labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  };

  return (
    
    // <Card sx={{ width: "30%", display: "flex", flexDirection: "column", height: "140px", boxShadow: 3, 
    //      borderRadius: "10px"
    //  }}>
    <Card sx={{ width: "30%", display: "flex", flexDirection: "column",height:"140px",borderRadius:"10px" }}>
      <CardContent sx={{ paddingBottom: "8px !important" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: '14px' }}>
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: '10px' }}>
          <Box>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <Box sx={{ backgroundColor: '#ff6384', borderRadius: "50%", width: '10px', height: '10px', marginRight: '5px' }} />
              Company Owned
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ backgroundColor: '#36a2eb', borderRadius: "50%", width: '10px', height: '10px', marginRight: '5px' }} />
              Third Party Vendors
            </Typography>
          </Box>
          <Box sx={{ width: "30%" }}>
            <Pie ref={chartRef} data={data} options={{ responsive: true, maintainAspectRatio: true }} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PieChartCard;

