import { Box } from '@mui/material'
import React from 'react'

const TableHeading = ({heading}) => {
  return (
    <Box sx={{ margin: "16px" }}>
    <Box
      sx={{
        fontWeight: "bold",
        fontSize: "20px",
        textAlign: "left",
      }}
    >
      {heading}
    </Box>
  </Box>
  )
}

export default TableHeading