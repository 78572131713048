import { Button, TextField } from "@mui/material";
import React from "react";
import styles from "../../styles/login/login.module.css";
import loginImage from "../../assets/images/loginImage.svg";
import companyLogo from "../../assets/images/companyLogo.png";

const Login = () => {
  return (
    <>
      <div className={styles.main_div}>
        <div className={styles.image_div}>
          <img src={companyLogo} alt="logo" className={styles.image_div_logo} />
          <img
            src={loginImage}
            alt="loginImage"
            className={styles.image_div_banner}
          />
        </div>
        <div className={styles.form_div}>
          <div className={styles.form_div_inner}>
            <h4 className={styles.form_div_inner_p}>Welcome to Nirantara !</h4>
            <div className={styles.form_div_login}>
              <h4 className={styles.form_title}>Account Login</h4>
              <h5 className={styles.form_sub_title}>
                Login with your registered mobile number{" "}
              </h5>
              <p className={styles.form_heading}>Mobile Number</p>
              <div className={styles.form_input}>
                <TextField size="small" className={styles.form_country}>
                  Country
                </TextField>
                <TextField size="small" className={styles.form_mobile}>
                  Mobile
                </TextField>
              </div>
              <Button variant="contained" className={styles.submit_button}>
                Send OTP
              </Button>
              <p className={styles.resend_otp}>Resend OTP</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
