import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../utils/api";
import { companyId, userId } from "../../../../utils/companyId";
import InputField from "../../../../Components/Masters/InputField";
import SelectField from "../../../../Components/Masters/SelectField";
import FileField from "../../../../Components/Masters/FileField";
import { validateNumber } from "../../../../utils/validateFields";
import { numberError } from "../../../../utils/validationMessage";
import Multiline from "../../../../Components/Masters/Multiline";

function Add({
  inputData,
  fetchData,
  id,
  title,
  data,
  setAddData,
  addData,
  setsnackOpen,
  addUrl,
  updateUrl,
}) {
  const [errors, setErrors] = useState({});
  const initialState = {
    industrialProcess: "",
    equipmentType: "",
    industrialEquipment: "",
    typeOfEntry: "",
    quantityConsumed: {value:""},
    hours: "",
    unit: "",
    calorificValue: { value: "", unit: "" },
    remarks: "",
    documents: [],
    company: companyId,
    createdBy:userId,
  };
  const [formData, setFormData] = useState(initialState);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  useEffect(() => {
    // if (addData) {
    //   setFormData(initialState); // Reset form data when opening the drawer
    // }
    if (id && data) {
      setFormData({
        industrialProcess: data.industrialProcess?._id || "",
        equipmentType: data.equipmentType?._id || "",
        industrialEquipment: data.industrialEquipment?._id || "",
        typeOfEntry: data.typeOfEntry || "",
        quantityConsumed: {value:data.quantityConsumed?.value || ""},
        hours: data.hours || "",
        unit: data.unit || "",
        calorificValue: {
          value: data.calorificValue?.value || "",
          unit: data.calorificValue?.unit || "",
        },
        remarks: data.remarks || "",
        documents: data.documents || [],
        createdBy:userId
      });
      setUploadedFiles(data.documents || []);
    } else if(!id) {
      setFormData(initialState);
      setUploadedFiles([]);
    }
  }, [data,id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name,value)
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    if (name.startsWith("calorificValue.")) {
      const key = name.split(".")[1];
      setFormData((prevState) => ({
        ...prevState,
        calorificValue: {
          ...prevState.calorificValue,
          [key]: value,
        },
      }));

      setErrors((prevState) => ({
        ...prevState,
        calorificValue: {
          ...prevState.calorificValue,
          [key]: "",
        },
      }));
    }
      else if (name==="quantityConsumed") {
        setFormData((prevState) => ({
          ...prevState,
          quantityConsumed: {
            value: value,
          },}))
          setErrors((prevState) => ({
            ...prevState,
            quantityConsumed: "",
        }))
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  
  const handleClose = () => {
    setAddData(false);
    setErrors({});
    // setFormData(initialState); // Reset form data to initial state
    // setUploadedFiles([]); // Reset uploaded files
};


  const validateForm = () => {
    const newErrors = {};
    console.log(formData)

    inputData.forEach((input) => {
        const value = formData[input.fieldName];

        // Check for calorificValue separately
        if (input.fieldName === "calorificValue") {
            const cvValue = formData[input.fieldName]?.value || ""; // Ensure it's a string
            const cvUnit = formData[input.fieldName]?.unit || ""; // Ensure it's a string

          if (!validateNumber(cvValue)) {
                newErrors[input.fieldName] = {
                    ...newErrors[input.fieldName],
                    value: "Numeric Value required.",
                };
            }

            if (!cvUnit.trim()) {
                newErrors[input.fieldName] = {
                    ...newErrors[input.fieldName],
                    unit: "Unit is required.",
                };
            }
            return; // Ensure to return after handling errors for calorificValue
        }

        if (input.fieldName === "quantityConsumed") {
          const Value = formData[input.fieldName]?.value || ""; // Ensure it's a string

        if (!validateNumber(Value)) {
              newErrors[input.fieldName] = numberError;
          }

          return; // Ensure to return after handling errors for calorificValue
      }

        // Skip validation for hours and industrialEquipment if not in Individual mode
        if (formData.typeOfEntry !== "Individual" && 
            (input.fieldName === "hours" || input.fieldName === "industrialEquipment")) {
            return; // Skip validation for these fields
        }

        // Validation for numeric inputs
        if (input.type === "number") {
            if (!validateNumber(value)) {
                newErrors[input.fieldName] = numberError;
                return; // Stop further checks for this input
            }
        }

        if (input.fieldName === "documents" && uploadedFiles.length === 0) {
          console.log(input.fieldName,uploadedFiles)
           newErrors[input.fieldName] = `Please upload Documents`;
         }

        // Additional checks for required fields
        if (input.required && input.type!=="number" && (!value || value.trim() === ""))
         {
            newErrors[input.fieldName] = `${input.title} is required.`;
        }
    });

    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};



const Save = async () => {
  if (!validateForm()) return;

  // Prepare data to send, including only fields with values
  const dataToSend = {
    ...formData,
    documents: uploadedFiles,
  };
  console.log(dataToSend,uploadedFiles)

  // Conditionally delete fields if typeOfEntry is not "Individual"
  if (formData.typeOfEntry !== "Individual") {
    delete dataToSend.hours;
    delete dataToSend.industrialEquipment;
  }

  // Remove fields that have empty values
  Object.keys(dataToSend).forEach((key) => {
    if (
      dataToSend[key] === "" || // Empty strings
      (typeof dataToSend[key] === "object" && Object.keys(dataToSend[key]).length === 0) // Empty objects
    ) {
      delete dataToSend[key];
    }
  });

  await axios({
    method: id ? "put" : "post",
    url: id ? `${updateUrl}` : addUrl,
    data: dataToSend,
    params: id ? { Id: id } : null, // Send params only for PUT request
  })
    .then((res) => {
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
      setFormData(initialState);
      setUploadedFiles([])
      handleClose();
    })
    .catch((err) => {
      if (err.response && err.response.data && err.response.data.errors) {
        const errorMessages = err.response.data.errors
          .map((error) => error.msg)
          .join(", ");
        setsnackOpen({
          open: true,
          message: errorMessages,
          severity: "error",
        });
      }
    });
};

  const renderInputField = (el) => {
    if (el.options || el.type === "select") {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          options={el.options}
          errors={errors[el.fieldName]}
          url={el.url}
          field={el.field}
        />
      );
    }

    if (el.type === "file") {
      return (
        <FileField
          title={el.title}
          name={el.fieldName}
          errors={errors[el.fieldName]}
          fileUrls={uploadedFiles}
          progress={uploadProgress[el.fieldName] || []}
          {...{setUploadedFiles,setUploadProgress,setErrors}}
          // onDelete={handleDeleteFile}
        />
      );
    }

    if (el.fieldName === "calorificValue") {
      return (
        <Box display="flex" gap={1} sx={{ width: "100%" }}>
          <Box sx={{ width: "55%" }}>
            <InputField
              title={el.title}
              handleChange={handleChange}
              placeholder={el.placeholder}
              value={formData.calorificValue?.value || ""}
              errors={errors[el.fieldName]?.value} // Access error for value
              fieldName={"calorificValue.value"}
            />
          </Box>
          <Box sx={{ width: "45%", mt: "9%" }}>
            <SelectField
              name={"calorificValue.unit"}
              value={formData.calorificValue?.unit}
              handleChange={handleChange}
              options={["MJ/kg", "MJ/m3"]}
              errors={errors[el.fieldName]?.unit} // Access error for unit
              field={el.field}
              defaultValue="Select Unit"
            />
          </Box>
        </Box>
      );
    }

    if (el.fieldName === "remarks")
      return (
        <Multiline
          title={el.title}
          handleChange={handleChange}
          placeholder={el.placeholder}
          value={formData[el.fieldName] || ""}
          errors={errors[el.fieldName]}
          fieldName={el.fieldName}
        />
      );

    return (
      <InputField
        title={el.title}
        handleChange={handleChange}
        placeholder={el.placeholder}
        value={el.fieldName==="quantityConsumed"?formData[el.fieldName]?.value : formData[el.fieldName] || ""}
        errors={errors[el.fieldName]}
        fieldName={el.fieldName}
        adornmentText={el.adornmentText || ""} // Pass error prop
        />
    );
  };

  const list = () => (
    <Box display="flex" gap={3} sx={{ width: "400px",maxWidth:"100%" }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton onClick={handleClose} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "88%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {id ? `Edit ${title} Data` : `Add ${title} Data`}
        </Typography>
        {inputData.map((el) => {
          // Conditional rendering for hours and industrial equipment
          if (
            (el.fieldName === "hours" ||
              el.fieldName === "industrialEquipment") &&
            formData.typeOfEntry !== "Individual"
          ) {
            return null; // Skip rendering these fields if typeOfEntry is not "Individual"
          }

          return <div key={el.fieldName}>{renderInputField(el)}</div>;
        })}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      sx={{
        backgroundColor: "transparent",
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          height: "100vh",
          minHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
      anchor="right"
      open={addData}
      onClose={handleClose}
    >
      {list()}
    </Drawer>
  );
}

export default Add;
