import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  Drawer,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { api } from "../../../../../utils/api";
import DisableFutureDate from "../../../../../Components/Masters/DisableFutureDate";
import LinearProgress from "@mui/material/LinearProgress";
import contries from "../../../Roles and Permissions/Country.json";
import DescriptionIcon from "@mui/icons-material/Description"; // For CSV
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

function AddEquipment(props) {
  const [alreadyFacility, setAlreadyFacility] = useState(false);
  const [facilities, setFacilities] = useState(["Select Faciltiy"]); // Store list of facilities
  const [vendor, setVendors] = useState(["Select"]); // Store list of facilities
  const [loadingAadhar, setLoadingAadhar] = useState(false);
  const [loadingLicense, setLoadingLicense] = useState(false);
  const [verifybtn, setverifybtn] = useState({ email: false, phone: false });
  const [selectedEmail, setEmail] = useState("");

  const [verify, setVerify] = useState({
    phone: false,
    email: false,
    emailMsg: "",
    phoneMsg: "",
  });
  const taskIntial = {
    phone: false,
    email: false,
  };
  const [TaskAltIcons, setTaskAltIcons] = useState(taskIntial);
  // Time state
  const [timerActive, setTimerActive] = useState({
    email: false,
    phone: false,
  });
  const [otp, setOTP] = useState({
    phone: null,
    email: null,
  });
  const [timePhone, setTimePhone] = useState(120);
  const [time, setTime] = useState(120); // Initialize time to 2 minutes (120 seconds)
  const [timerPhone, setTimerPhone] = useState(120);

  const [errors, setErrors] = useState({});

  const initialState = {
    facility: "",
    logisticsVendor: "",
    ownershipType: "",
    driverName: "",
    aadharNumber: "",
    licenseNumber: "",
    licenseExpiryDate: "",
    phone: "",
    countryCode: "",
    country: "",
    email: "",
    aadharDoc: [],
    licenseDoc: [],
  };
  const [fromValue, setFromValue] = useState(initialState);

  useEffect(() => {
    fetchFacilities();

    // Only set form values if props.selectedFacility is not null or undefined
    if (props.selectedFacility) {
      setFromValue({
        facility: props.selectedFacility.facility?._id ?? "",
        logisticsVendor: props.selectedFacility.logisticsVendor?._id ?? "",
        ownershipType: props.selectedFacility.ownershipType ?? "Owned", // Default ownership type
        driverName: props.selectedFacility.driverName ?? "",
        aadharNumber: props.selectedFacility.aadharNumber ?? "",
        licenseNumber: props.selectedFacility.licenseNumber ?? "",
        licenseExpiryDate: props.selectedFacility.licenseExpiryDate ?? "",
        phone: props.selectedFacility.phone ?? "",
        email: props.selectedFacility?.email ?? "",
        countryCode: props.selectedFacility.countryCode ?? "", // Default country code
        country: props.selectedFacility.country ?? "",
        aadharDoc: props.selectedFacility.aadharDoc ?? [],
        licenseDoc: props.selectedFacility.licenseDoc ?? [],
      });
      setEmail(props.selectedFacility?.email);
      setAlreadyFacility(true);
    } else {
      resetForm(); // Reset form when there's no selected facility
      setAlreadyFacility(false);
    }
  }, [props.selectedFacility]);

  useEffect(() => {
    if (timerActive.email && time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setTimerActive((prev) => ({ ...prev, email: false }));
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timerActive.email]);

  useEffect(() => {
    if (timerActive.phone && timerPhone > 0) {
      const timer = setInterval(() => {
        setTimerPhone((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setTimerActive((prev) => ({ ...prev, phone: false }));
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timerActive.phone]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFromValue({
      ...fromValue,
      [name]: value,
    });

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the specific field error
      }));
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountryCode = event.target.value;
    const selectedCountry = contries.countries.find(
      (country) => country.name === selectedCountryCode
    ); // Find the selected country object

    if (selectedCountry) {
      setFromValue({
        ...fromValue,
        country: selectedCountry.name, // Set country name
        countryCode: selectedCountry.dial_code, // Set country code
      });
    }
  };
  const handleResendPhone = () => {
    setTimePhone(180); // Reset time to 180 seconds
    handleVerifyPhone(180); // Call the email verification function
  };

  const handleResend = () => {
    setTime(120); // Reset time to 180 seconds
    handleVerifyEmail(120); // Call the email verification function
  };

  const handleVerifyPhone = (timerPhone) => {
    // setVerify({...verify,phone:true})
    setErrors({ ...errors, phone: "" });

    if (timerPhone > 0) {
      axios
        .post(`${api}/auth/sendOtpToPhone`, {
          phone: fromValue.phone,
          countryCode: fromValue.countryCode,
          module: "Driver",
        })
        .then((res) => {
          console.log("res", res);
          // setOTP({...otp,})
          setVerify({ ...verify, phone: true });
          setTimerActive((prev) => ({ ...prev, phone: true }));
          setErrors({ ...errors, OTPphone: "" });
        })
        .catch((err) => {
          console.log(
            err?.response?.data?.statusCode,
            err?.response?.data?.statusCode === 0
          );

          fromValue.countryCode === ""
            ? setErrors({ ...errors, phone: "Select Country" })
            : setErrors({ ...errors, phone: err?.response?.data?.message });

          // setErrors({ ...errors, phone: err?.response?.data?.message });
          setVerify({ ...verify, phone: false });
          setverifybtn({ ...verifybtn, phone: false });
        });
    } else {
      setVerify({ ...verify, phone: false });
      setverifybtn({ ...verifybtn, phone: false });
    }
  };

  const handleVerifyEmail = (time) => {
    setErrors({ ...errors, email: "" });
    if (time > 0) {
      // setVerify({...verify,email:true})
      setErrors({ ...errors, email: "" });
      axios
        .post(`${api}/auth/sendOtpToEmail`, {
          email: fromValue.email,
          module: "Driver",
        })
        .then((res) => {
          // setOTP({...otp,})
          setTimerActive((prev) => ({ ...prev, email: true }));
          if (res.status === 200) {
            setVerify({ ...verify, email: true });
            setErrors({ ...errors, email: "", OTPemail: "" });
            // setverifybtn({...verifybtn,email:false});
          }
        })
        .catch((err) => {
          console.log(err, err?.response?.data?.message);
          setErrors({ ...errors, email: err?.response?.data?.message });
          setVerify({ ...verify, email: false });
          setverifybtn({ ...verifybtn, email: false });
        });
    } else {
      setVerify({ ...verify, email: false });
      setverifybtn({ ...verifybtn, email: false });
    }
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    // const numericValue = parseInt(value, /^[0-9]{10}$/) || 0;
    // const phoneNumberPattern = /^[0-9]{10}$/;
    const phoneNumberPattern = /^[0-9]*$/;
    if (phoneNumberPattern.test(value) && value.length <= 10) {
      setFromValue({
        ...fromValue,
        [name]: value,
      });

      value.length === 10
        ? setverifybtn({ ...verifybtn, phone: true })
        : setverifybtn({ ...verifybtn, phone: false });

      // setTaskAltIcons({ ...TaskAltIcons, phone: false });
      // setverifybtn({ ...verifybtn, phone: true });
    } else {
      setverifybtn({ ...verifybtn, phone: true });
    }
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
    const trimmedValue = value.trim();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if the email matches the pattern
    if (emailPattern.test(trimmedValue)) {
      setFromValue({
        ...fromValue,
        [name]: trimmedValue,
      });
      setverifybtn({ ...verifybtn, email: true });
    } else {
      setverifybtn({ ...verifybtn, email: false });
    }
  };

  const formatTimePhone = (timePhone) => {
    const timer = setInterval(() => {
      setTimePhone((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer); // Clear the interval when the countdown reaches 0
          return 0; // Ensure the timer doesn't go below 0
        }
        return prevTime - 1; // Decrease time by 1 second
      });
    }, 1000);
    const minutes = Math.floor(timePhone / 60);
    const seconds = timePhone % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleDateChange = ({ name, value }) => {
    setFromValue((prevValues) => ({
      ...prevValues,
      [name]: value, // Update date field with formatted value
    }));
  };

  const handleDeleteImage = (type, index) => {
    const updatedDocuments = fromValue[type + "Doc"].filter(
      (_, i) => i !== index
    );

    setFromValue((prev) => ({
      ...prev,
      [`${type}Doc`]: updatedDocuments,
    }));
  };

  const handleFileChange = async (e, type) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    const currentTotalForType = fromValue?.[`${type}Doc`]?.length || 0;

    // Limit to max 5 files for each document type
    if (currentTotalForType + files.length > 5) {
      setErrors({
        ...errors,
        [`${type}Doc`]: `You can only upload a maximum of 5 files for ${
          type === "aadhar" ? "Aadhar" : "License"
        } documents.`,
      });
      return;
    }
    const allowedTypes = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls
      "image/png",
      // "image/jpeg", // For .jpg and .jpeg files
    ];

    const maxFileSize = 5 * 1024 * 1024; // 5 MB
    const maxWidth = 1200;
    const maxHeight = 800;

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        setErrors({
          ...errors,
          [`${type}Doc`]: `File type not allowed: ${file.name}`,
        });
        return;
      }

      // Check file size
      if (file.size > maxFileSize) {
        setErrors({
          ...errors,
          [`${type}Doc`]: `File size exceeds 5MB: ${file.name}`,
        });
        return;
      }

      if (file.type.startsWith("image/")) {
        const image = new Image();
        const fileURL = URL.createObjectURL(file);
        image.src = fileURL;

        // Wait for image to load to get dimensions
        try {
          await new Promise((resolve, reject) => {
            image.onload = () => {
              if (image.width > maxWidth || image.height > maxHeight) {
                setErrors({
                  ...errors,
                  [`${type}Doc`]: `Image dimensions exceed 1200x800: ${file.name}`,
                });
                URL.revokeObjectURL(fileURL); // Release memory
                reject(
                  new Error(`Image dimensions exceed 1200x800: ${file.name}`)
                );
              } else {
                URL.revokeObjectURL(fileURL); // Release memory
                resolve();
              }
            };

            image.onerror = () => {
              URL.revokeObjectURL(fileURL); // Release memory
              reject(new Error(`Unable to load image: ${file.name}`));
            };
          });
        } catch (error) {
          console.error(error.message);
          continue; // Skip this file and proceed to the next
        }
      }

      // Add the file to FormData
      formData.append("files", file);
    }

    try {
      // Set loading for the specific type
      type === "aadhar" ? setLoadingAadhar(true) : setLoadingLicense(true);

      const uploadResponse = await axios.post(`${api}/cloud/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const uploadedUrls = uploadResponse.data.urls; // Assuming your backend sends back an array of URLs
      setFromValue((prev) => {
        const updatedDocuments =
          prev[`${type}Doc`].length > 0
            ? [...prev[`${type}Doc`], ...uploadedUrls]
            : uploadedUrls;

        return {
          ...prev,
          [`${type}Doc`]: updatedDocuments,
        };
      });
      // Clear errors for the document type after successful upload
      setErrors({
        ...errors,
        [`${type}Doc`]: "",
      });
    } catch (err) {
      console.error("Error uploading files:", err);
      setErrors({
        ...errors,
        [`${type}Doc`]: "Failed to upload files. Please try again.",
      });
    } finally {
      // Reset loading state after the upload
      type === "aadhar" ? setLoadingAadhar(false) : setLoadingLicense(false);
    }
  };

  const fetchFacilities = async () => {
    try {
      const response = await axios.get(`${api}/master/getAllFacility`);
      const result = await axios.get(`${api}/master/logistic/vendorMaster`);

      setFacilities(response.data.data);
      setVendors(result.data.data);
    } catch (error) {
      console.log("Error fetching facilities:", error);
    }
  };

  const Save = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const dataToSubmit = { ...fromValue };
    if (!validateForm()) {
      return;
    }

    axios({
      method: alreadyFacility ? "put" : "post",
      url: alreadyFacility
        ? `${api}/driver/master/update`
        : `${api}/driver/master/create`,
      data: dataToSubmit, // Send data only if it's a POST request
      params: alreadyFacility ? { id: props.selectedFacility?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        props.setAddFacility(false);
        props.fetchFacilities();
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        // setNoOfEquipment(null);
        resetForm();

        //  localStorage.setItem("roleId",res.data.data._id)
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          // If there is a message in the error response, use it
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const handleOTPVerifyPhone = (e) => {
    const { value } = e.target;

    // Only allow numeric values and ensure length does not exceed 4
    const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion

    // Update state only if the value is numeric and has a length <= 4
    if (isNumeric && value.length <= 4) {
      setOTP((prevOtp) => ({
        ...prevOtp,
        phone: value, // Update the OTP value
      }));
      setErrors({ ...errors, phone: "" });

      // Trigger API call when exactly 4 digits are entered
      if (value.length === 4) {
        axios
          .post(`${api}/auth/verifyOtpForPhone`, {
            phone: fromValue.phone,
            countryCode: fromValue.countryCode,
            otp: value,
          })
          .then((res) => {
            // console.log("OTP verified successfully:", res, res.status);
            if (res.status === 200) {
              setVerify({ ...verify, phone: false });
              setverifybtn({ ...verifybtn, phone: false });
              setTaskAltIcons({ ...TaskAltIcons, phone: true });
              setErrors({ ...errors, phoneMsg: "" });
            }
          })
          .catch((err) => {
            setVerify({
              ...verify,
              phone: true,
              phoneMsg: err?.response?.data?.message,
            });
            setverifybtn({ ...verifybtn, phone: true });
            setTaskAltIcons({ ...TaskAltIcons, phone: false });
            setErrors({
              ...errors,
              phone: "",
              OTPphone: err?.response?.data?.message,
            });
          });
      }
    }
  };
  const handleOTPVerify = (e) => {
    const { value } = e.target;

    const isNumeric = /^\d+$/.test(value) || value === "";
    if (isNumeric && value.length <= 4) {
      setOTP((prevOtp) => ({
        ...prevOtp,
        email: value, // Update the OTP value
      }));
      // Trigger API call when exactly 4 digits are entered
      if (value.length === 4) {
        axios
          .post(`${api}/auth/verifyOtpForEmail`, {
            email: fromValue.email,
            otp: value,
          })
          .then((res) => {
            console.log("OTP verified successfully:", res, res.status);
            if (res.status === 200) {
              setVerify({ ...verify, email: false });
              setverifybtn({ ...verifybtn, email: false });
              setTaskAltIcons({ ...TaskAltIcons, email: true });
              setErrors({
                ...errors,
                email: "",
                OTPemail: "",
              });
            }
          })
          .catch((err) => {
            console.error("Error verifying OTP:", err);
            setVerify({
              ...verify,
              email: true,
              emailMsg: err?.response?.data?.message,
            });
            setverifybtn({ ...verifybtn, email: true });
            setTaskAltIcons({ ...TaskAltIcons, email: false });
            setErrors({
              ...errors,
              email: "",
              OTPemail: err?.response?.data?.message,
            });
          });
      }
    }
  };

  const getDropDownFieldId = (data) => {
    if (typeof data === "string") {
      return data; // If it's a direct ID, return it
    }
    return data?._id || ""; // If it's an object, return the _id
  };

  const validateForm = () => {
    const newErrors = {};
    const {
      facility,
      logisticsVendor,
      driverName,
      aadharNumber,
      licenseNumber,
      email,
      licenseExpiryDate,
      aadharDoc, // Ensure this is included
      licenseDoc,
      phone,
      countryCode,
      country,
    } = fromValue;

    if (!facility || facility === "Select Facility") {
      newErrors.facility = "Please select a facility.";
    }

    if (!country || country === "Select Country") {
      newErrors.country = "Please select a country.";
    }

    if (phone) {
      if (verifybtn.phone === true) {
        newErrors.phone = "Please Validate ur phone Click on verify";
      }
      // If phone number is present but
      if (!countryCode || countryCode.trim() === "") {
        newErrors.phone = "Country code is required,  select the country.";
      } else if (!/^\d{10,15}$/.test(phone)) {
        newErrors.phone = "A valid phone number is required."; // Validates phone number format
      }
    } else {
      // If phone is missing but countryCode is provided
      if (countryCode && countryCode.trim() !== "") {
        newErrors.phone = "Phone number is required .";
      } else {
        newErrors.phone = "A valid phone number is required."; // Generic phone validation
      }
    }

    if (!logisticsVendor || logisticsVendor === "Select") {
      newErrors.logisticsVendor = "Please select a logistics vendor.";
    }

    if (!driverName || driverName.trim() === "") {
      newErrors.driverName = "Driver name is required.";
    }

    if (!aadharNumber || String(aadharNumber).length !== 12) {
      newErrors.aadharNumber = "Aadhar number must be 12 digits.";
    }

    if (!licenseNumber || licenseNumber.trim() === "") {
      newErrors.licenseNumber = "License number is required.";
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (verifybtn.email === true && email) {
      newErrors.email = "Please Validate ur email Click on verify";
    }

    if (verifybtn.email === true && !otp.email) {
      newErrors.OTPemail = "Please enter the OTP";
    }

    const currentDate = new Date();
    if (!licenseExpiryDate) {
      newErrors.licenseExpiryDate = "License expiry date is required.";
    }
    // else if (new Date(licenseExpiryDate) <= currentDate) {
    //   newErrors.licenseExpiryDate =
    //     "License expiry date must be in the future.";
    // }

    // Upload Aadhar validation
    if (!aadharDoc || aadharDoc.length === 0) {
      newErrors.aadharDoc = "Please upload an aadhar document.";
    }

    // Upload License validation
    if (!licenseDoc || licenseDoc.length === 0) {
      newErrors.licenseDoc = "Please upload a license document.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const resetForm = () => {
    setFromValue(
      props.selectedFacility === null ? initialState : props.selectedFacility
    );
    setTaskAltIcons(taskIntial);
    setEmail("");
    setOTP({
      phone: null,
      email: null,
    });

    setverifybtn({ phone: false, email: false });
    setErrors({}); // Reset errors
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 400,
        // maxWidth:"100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-btween",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {
            props.setAddFacility(false);
            resetForm();
          }}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: "24px",
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          {props.selectedFacility?._id === undefined
            ? "Add Driver Master"
            : "Edit Driver Master"}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Facility
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          fullWidth
          name="facility"
          // value={fromValue.facility}
          onChange={handleChange}
          value={
            fromValue.facility === "" || undefined
              ? "Tap to Select"
              : getDropDownFieldId(fromValue.facility)
          }
          // onChange={handleDropdownChange}
          placeholder="Select Facility"
          error={!!errors.facility} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.facility ? "#D34040" : undefined },
          }}
        >
          <MenuItem
            value={
              fromValue.facility === "" || undefined
                ? "Tap to Select"
                : fromValue.facility
            }
            disabled
          >
            {/* Tap to Select */}
            {fromValue.facility === "" || undefined
              ? "Tap to Select"
              : fromValue.facility?.facilityName || fromValue.facility}
          </MenuItem>
          {facilities.map((item, i) => (
            <MenuItem key={i} value={item._id}>
              {item.facilityName}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.facility && (
          <Typography variant="body2" color="#D34040">
            {errors.facility}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Country
        </Typography>
        <CustomTextField
          variant="outlined"
          select
          //   label="Select Country"
          value={fromValue.country || "Select Country"}
          onChange={handleCountryChange}
          fullWidth
          name="country"
          placeholder="Select Country"
          error={!!errors.country} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.country ? "#D34040" : undefined },
          }}
        >
          <MenuItem value="Select Country" disabled>
            Select Country
          </MenuItem>
          {contries.countries.map((country) => (
            // console.log(country.name,country.code)
            <MenuItem
              key={country.dial_code}
              value={country.name}
              sx={{
                display: "flex", // Flexbox for alignment
                justifyContent: "space-between", // Space between name and code
                padding: "3px 8px", // Further reduced padding for a smaller box size
                "&:hover": {
                  backgroundColor: "#f5f5f5", // Light background on hover
                },
                textOverflow: "ellipsis", // Add ellipsis for overflowed text

                fontSize: "15px", // Consistent font size
                color: "black", // Text color
                // maxWidth: "220px", // Set a max width to constrain the MenuItem size
              }}
            >
              {country.name}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.country && (
          <Typography variant="body2" color="#D34040">
            {errors.country}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Logistics Vendor
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          fullWidth
          name="logisticsVendor"
          // value={fromValue.logisticsVendor || "Tap to Select"} // Set default value as "Tap to Select"
          value={
            fromValue.logisticsVendor === "" || undefined
              ? "Tap to Select"
              : getDropDownFieldId(fromValue.logisticsVendor)
          }
          onChange={handleChange}
          placeholder="Select logisticsVendor"
          error={!!errors.logisticsVendor} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.logisticsVendor ? "#D34040" : undefined },
          }}
        >
          {/* Add the "Tap to Select" option as the first MenuItem */}
          <MenuItem value="Tap to Select">Tap to Select</MenuItem>
          {vendor.map((item) => (
            <MenuItem
              key={`equipment_${item._id}`}
              value={item._id}
              sx={{
                "&:hover": {
                  backgroundColor: "#f5f5f5", // Change background on hover
                  width: "100%",
                },
                padding: "10px 20px", // Adjust padding as needed
                borderRadius: "4px", // Add some border radius for a smoother look
              }}
            >
              {item.logisticVendorLegalName}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.logisticsVendor && (
          <Typography variant="body2" color="#D34040">
            {errors.logisticsVendor}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
          Driver's Name
        </Typography>
        <CustomTextField
          variant="outlined"
          value={fromValue.driverName}
          onChange={handleChange}
          fullWidth
          name="driverName"
          placeholder="Driver's Name"
          error={!!errors.driverName} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.driverName ? "#D34040" : undefined },
          }}
        />
        {errors.driverName && (
          <Typography variant="body2" color="#D34040">
            {errors.driverName}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
          Aadhar No.
        </Typography>
        <CustomTextField
          variant="outlined"
          value={fromValue.aadharNumber}
          // onChange={handleChange}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
            if (value.length <= 12) {
              handleChange(e);
            }
          }}
          fullWidth
          name="aadharNumber"
          placeholder="Aadhar No."
          error={!!errors.aadharNumber} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.aadharNumber ? "#D34040" : undefined },
          }}
        />
        {errors.aadharNumber && (
          <Typography variant="body2" color="#D34040">
            {errors.aadharNumber}
          </Typography>
        )}
        {/* drivering license */}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
          Driver License Number
        </Typography>
        <CustomTextField
          variant="outlined"
          value={fromValue.licenseNumber}
          onChange={handleChange}
          fullWidth
          name="licenseNumber"
          placeholder="Driver License Number"
          error={!!errors.licenseNumber} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.licenseNumber ? "#D34040" : undefined },
          }}
        />
        {errors.licenseNumber && (
          <Typography variant="body2" color="#D34040">
            {errors.licenseNumber}
          </Typography>
        )}
        <DisableFutureDate
          title="License Expiry Date"
          name="licenseExpiryDate"
          value={fromValue.licenseExpiryDate}
          handleChange={handleDateChange} // Handle date change
          placeholder="DD/MM/YYYY"
          errors={errors.licenseExpiryDate} // Error handling for the date field
        />
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Mobile No.
        </Typography>
        <Box
          sx={{
            display: `${TaskAltIcons.phone === true ? "flex" : "block"}`,
            width: `${TaskAltIcons.phone === true ? "105%" : "100%"}`,
          }}
        >
          <CustomTextField
            variant="outlined"
            value={fromValue.phone}
            onChange={handlePhoneChange}
            fullWidth
            name="phone"
            placeholder="Type Here"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "10px" }}>
                  <span style={{ marginLeft: "8px" }}>
                    {fromValue.countryCode}
                  </span>
                  <div
                    style={{
                      borderLeft: "1px solid #000", // Color of the vertical line
                      height: "24px", // Height of the line
                      marginRight: "8px", // Space between the line and the percentage symbol
                    }}
                  />
                </InputAdornment>
              ),
            }}
            error={!!errors.phone} // Show error styling if there's an error
            InputLabelProps={{
              style: { color: errors.phone ? "#D34040" : undefined },
            }}
          />
          {TaskAltIcons.phone === true && (
            <TaskAltIcon sx={{ color: "green", textAlign: "right" }} />
          )}
        </Box>
        {errors.phone && (
          <Typography variant="body2" color="#D34040">
            {errors.phone}
          </Typography>
        )}
        {verifybtn.phone && (
          <Box
            onClick={() => handleVerifyPhone(180)}
            sx={{
              fontSize: "14px",
              color: "green",
              textDecoration: "underline",
              textAlign: "right",
              cursor: "pointer",
            }}
          >
            Verify
          </Box>
        )}
        {verifybtn.phone === true && verify.phone && (
          <Box>
            <Typography>Enter OTP For Phone</Typography>
            <CustomTextField
              variant="outlined"
              value={otp.phone}
              onChange={handleOTPVerifyPhone}
              fullWidth
              name="otpPhone"
              placeholder="Enter OTP"
              inputProps={{
                maxLength: 4,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              error={!!errors.phone} // Show error styling if there's an error
              InputLabelProps={{
                style: { color: errors.phone ? "#D34040" : undefined },
              }}
            />
            {errors.OTPphone && (
              <Typography variant="body2" color="#D34040">
                {errors.OTPphone}
              </Typography>
            )}

            {timerPhone > 0 ? (
              <Box sx={{ textAlign: "right" }}>
                {formatTimePhone(timerPhone)}
              </Box>
            ) : (
              <Box
                sx={{
                  fontSize: "14px",
                  color: "green",
                  textDecoration: "underline",
                  textAlign: "right",
                  cursor: "pointer",
                }}
                onClick={() => handleResendPhone()}
              >
                Resend
              </Box>
            )}
          </Box>
        )}
        {/* email verification functionlity */}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Email
        </Typography>
        <Box
          sx={{
            display: `${TaskAltIcons.email === true ? "flex" : "block"}`,
            width: `${TaskAltIcons.email === true ? "105%" : "100%"}`,
          }}
        >
          <CustomTextField
            variant="outlined"
            value={selectedEmail}
            onChange={handleEmailChange}
            fullWidth
            name="email"
            placeholder="Type Here"
            error={!!errors.email} // Show error styling if there's an error
            InputLabelProps={{
              style: { color: errors.email ? "#D34040" : undefined },
            }}
          />
          {TaskAltIcons.email === true && (
            <TaskAltIcon sx={{ color: "green", textAlign: "right" }} />
          )}
        </Box>
        {errors.email && (
          <Typography variant="body2" color="#D34040">
            {errors.email}
          </Typography>
        )}
        {verifybtn.email && (
          <Box
            onClick={() => handleVerifyEmail(180)}
            sx={{
              fontSize: "14px",
              color: "green",
              textDecoration: "underline",
              textAlign: "right",
              cursor: "pointer",
            }}
          >
            Verify
          </Box>
        )}
        {verifybtn.email === true && verify.email && (
          <Box>
            <Typography>Enter OTP For Email</Typography>
            <CustomTextField
              variant="outlined"
              value={otp.email}
              onChange={handleOTPVerify}
              fullWidth
              name="otpEmail"
              placeholder="Enter OTP"
              inputProps={{
                maxLength: 4,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              error={!!errors.OTPemail} // Show error styling if there's an error
              InputLabelProps={{
                style: { color: errors.OTPemail ? "#D34040" : undefined },
              }}
            />
            {errors.OTPemail && (
              <Typography variant="body2" color="#D34040">
                {errors.OTPemail}
              </Typography>
            )}

            {time > 0 ? (
              <Box sx={{ textAlign: "right" }}>{formatTimePhone(time)}</Box>
            ) : (
              <Box
                sx={{
                  fontSize: "14px",
                  color: "green",
                  textDecoration: "underline",
                  textAlign: "right",
                  cursor: "pointer",
                }}
                onClick={() => handleResend()}
              >
                Resend
              </Box>
            )}
          </Box>
        )}
        {/* 
        {/* Aadhar Section */}
        <Typography variant="subtitle2" sx={{ fontWeight: "bold", mt: 2 }}>
          Upload Aadhar
        </Typography>
        {/* Aadhar Document Display */}
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 1 }}>
          {fromValue?.aadharDoc?.length > 0 &&
            fromValue?.aadharDoc?.map((doc, index) => {
              const fileName = doc.split("/").pop();
              const fileExtension = fileName.split(".").pop().toLowerCase();
              const isImage = ["jpg", "jpeg", "png"].includes(fileExtension);
              const isCsv = fileExtension === "csv";
              const isExcel = ["xls", "xlsx"].includes(fileExtension);

              return (
                <Box key={index} sx={{ position: "relative" }}>
                  {isImage ? (
                    <img
                      src={doc}
                      alt={`Aadhar Document ${index + 1}`}
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        borderRadius: "4px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "80px",
                        height: "80px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      {isCsv ? (
                        <DescriptionIcon
                          sx={{ fontSize: "20px", color: "#1A73E8" }}
                        />
                      ) : isExcel ? (
                        <FontAwesomeIcon
                          icon={faFileExcel}
                          style={{ color: "#0a8f08" }}
                        />
                      ) : null}
                      <Typography variant="caption" noWrap>
                        {fileName}
                      </Typography>
                    </Box>
                  )}
                  <IconButton
                    onClick={() => handleDeleteImage("aadhar", index)}
                    sx={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      color: "white",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "10px" }} />
                  </IconButton>
                </Box>
              );
            })}
        </Box>
        {loadingAadhar ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{
              border: "1px dashed grey",
              borderRadius: 1,
              p: 2,
              textAlign: "center",
              cursor: "pointer",
              display: fromValue?.aadharDoc?.length < 5 ? "block" : "none",
            }}
          >
            <input
              type="file"
              hidden
              id="upload-aadhar"
              onChange={(e) => handleFileChange(e, "aadhar")}
              multiple
            />
            <label htmlFor="upload-aadhar">
              <Box display="flex" flexDirection="column" alignItems="center">
                <CloudUploadIcon />
                <Typography>Drop files here or click to upload</Typography>
                <Typography variant="caption">
                  Upload your file (CSV, Excel, PNG, max 5 MB, recommended size
                  1200x800 pixels).
                </Typography>
              </Box>
            </label>
          </Box>
        )}
        {errors.aadharDoc && (
          <Typography variant="body2" color="#D34040">
            {errors.aadharDoc}
          </Typography>
        )}
        {/* License Section */}
        <Typography variant="subtitle2" sx={{ fontWeight: "bold", mt: 2 }}>
          Upload License
        </Typography>
        {/* License Document Display */}
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 1 }}>
          {fromValue?.licenseDoc?.length > 0 &&
            fromValue?.licenseDoc?.map((doc, index) => {
              const fileName = doc.split("/").pop();
              const fileExtension = fileName.split(".").pop().toLowerCase();
              const isImage = ["jpg", "jpeg", "png"].includes(fileExtension);
              const isCsv = fileExtension === "csv";
              const isExcel = ["xls", "xlsx"].includes(fileExtension);

              return (
                <Box key={index} sx={{ position: "relative" }}>
                  {isImage ? (
                    <img
                      src={doc}
                      alt={`License Document ${index + 1}`}
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        borderRadius: "4px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "80px",
                        height: "80px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      {isCsv ? (
                        <DescriptionIcon
                          sx={{ fontSize: "20px", color: "#1A73E8" }}
                        />
                      ) : isExcel ? (
                        <FontAwesomeIcon
                          icon={faFileExcel}
                          style={{ color: "#0a8f08" }}
                        />
                      ) : null}
                      <Typography variant="caption" noWrap>
                        {fileName}
                      </Typography>
                    </Box>
                  )}
                  <IconButton
                    onClick={() => handleDeleteImage("license", index)}
                    sx={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      color: "white",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "10px" }} />
                  </IconButton>
                </Box>
              );
            })}
        </Box>
        {loadingLicense ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{
              border: "1px dashed grey",
              borderRadius: 1,
              p: 2,
              textAlign: "center",
              cursor: "pointer",
              display: fromValue?.licenseDoc?.length < 5 ? "block" : "none",
            }}
          >
            <input
              type="file"
              hidden
              id="upload-license"
              onChange={(e) => handleFileChange(e, "license")}
              multiple
            />
            <label htmlFor="upload-license">
              <Box display="flex" flexDirection="column" alignItems="center">
                <CloudUploadIcon />
                <Typography>Drop files here or click to upload</Typography>
                <Typography variant="caption">
                  Upload your file (CSV, Excel, PNG, max 5 MB, recommended size
                  1200x800 pixels).
                </Typography>
              </Box>
            </label>
          </Box>
        )}
        {errors.licenseDoc && (
          <Typography variant="body2" color="#D34040">
            {errors.licenseDoc}
          </Typography>
        )}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0a3f73",
            textTransform: "none",
            marginTop: "10%",
          }}
          onClick={Save}
        >
          Submit
        </Button>
        {/* </Box> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addFacility}
        onClose={() => {
          props.setAddFacility(false);
          resetForm();
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddEquipment;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
