import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Toolbar,
  Typography,
  Box,
  PaginationItem,
  Grid,
} from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import { styled } from "@mui/material/styles";
// import VehicleFilter from "../../Roles and Permissions/Filters/VehicleFilter";
import PaginationBox from "../../../Components/Masters/Pagination";
import DeleteDialog from "../../../Components/Masters/DeleteDialog";
import ColumnHeadings from "../../../Components/Masters/ColumnHeadings";
import MenuActions from "../../../Components/Masters/MenuActions";
import TableContent from "../../../Components/Masters/TableContent";
import FilterDrawer from "../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../Components/Masters/TopMenu";
import MessagePopup from "../../../Components/Masters/MessagePopup";
import TableHeading from "../../../Components/Masters/TableHeading";
import MenuIcon from "../../../Components/Masters/MenuIcon";
import { companyId } from "../../../utils/companyId";
  import AddFireExtingulsher from "./AddFireExtingulsher";
 import ViewFireExtingulsher from "./ViewFireExtingulsher";
import DateRangePickerComponent from "../../Masters/Roles and Permissions/Filters/Date";
import MultiSelect from "../../Masters/Roles and Permissions/Filters/MultiSelect";
import Range from "../../Masters/Roles and Permissions/Filters/Range";
import Cards from "../../../Components/Masters/Cards";
import SelectCard from "../../../Components/Masters/SelectCard";

const FireExtingulsher = () => {
  const [selectedPeriod, setSelectedPeriod] = React.useState("July");
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addData, setAddData] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    "Industrial Process": false,
    "Equipment Type": false,
    "Industrial Equipment": false,
    "Fuel Type":false,
    "Quantity Consumed": false,
    "Hours Used": false,
  });
  const [viewData, setViewData] = useState(false);
  const [element, setElement] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [errors, setErrors] = useState({
    Date: false,
    "Industrial Process": false,
    "Equipment Type": false,
    "Industrial Equipment": false,
    "Fule Type": false,
    "Quantity of Fuel Consumed": false,
    "Hours Used": false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    fugitiveEmissionSource: [],
    typeOfSubstance: [],
    quantityReleased: [],
  });
  const [quantityRelsed, setQuantityRange] = useState({ min: "", max: "" });
  const [hoursRange, setHoursRange] = useState({ min: "", max: "" });

  //   const[minRange,setMinRange]=useState({
  //     quantityConsumed:{min:"",max:""},
  //     hoursUsed:{min:"",max:""}
  //   })

  const filters = [
    "Date",
    // "Fugitive EmissionSource",
    "Type Of Substance",
    "Quantity Released",
  ];

  const inputData = [
    // {
    //   fieldName: "fugitiveEmissionSource",
    //   title: "Emission source",
    //   placeholder: "Select equipment type",
    //   type: "select",
    //    options: ["Fire extinguisher"],
    //   required: true,
    // },
    {
      fieldName: "typeOfSubstance",
      title: "Type of Substance",
      placeholder: "Type of Substance",
      type: "select",
      options: [
        "ABC (Ammonium Phosphate : Sulphate 40:60)",
        "ABC (Ammonium Phosphate : Sulphate 60:40)",
        "ABC (Ammonium Phosphate : Sulphate 90:10)",
        "CO2",
        "Clean Agent Fire Extinguishers: HFC-227ea (FM-200)",
        "Clean Agent Fire Extinguishers: HFC-236fa (FE-36)",
        "Clean Agent Fire Extinguishers: Novec 1230",
        "Clean Agent Fire Extinguishers: FE-13",
      ],
    //   url: `${api}/master/scope1/getAllMachinery`,
      required: true,
    },
    {
      fieldName: "quantityReleased",
      title: "Quantity Released",
      placeholder: "kg",
      type: "number",
    //   url: `${api}/master/scope1/getAllMachinery`,
      required: true,
    },
    // {
    //   fieldName: "typeOfEntry",
    //   title: "Type of Entry",
    //   placeholder: "Type here",
    //   type: "select",
    //   options: ["Cumulative", "Individual"],
    //   required: true,
    // },
    // {
    //   fieldName: "uniqueIdOfEquipment",
    //   title: "Unique ID of Entry",
    //   placeholder: "Select industrial equipment",
    //   type: "number",
    //   url: `${api}/master/scope1/getAllMachinery`,
    //   // url: `${api}/master/getAllIndustrialEquipment`,
    //   condition: true,
    // },
    // {
    //   fieldName: "fuelType",
    //   title: "Fule Type",
    //   placeholder: "Auto filed",
    //   type: "text",
    //   required: true,
    // },
    // {
    //   fieldName: "quantityOfFuelConsumed",
    //   title: "Quantity of Fuel Consumed",
    //   placeholder: "Litre",
    //   type: "number",
    //   condition: true,
    // },
    // {
    //   fieldName: "hoursUsed",
    //   title: "Hours Used",
    //   placeholder: "Enter hours",
    //   type: "number",
    //   condition: true,
    // },
    {
      fieldName: "remarks",
      title: "Remark",
      placeholder: "Additional remarks",
      type: "text",
    },
    // {
    //   fieldName: "documents",
    //   title: "Upload Documents",
    //   type: "file",
    // },
  ];

  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(
        `${api}/scope1/fugitiveEmission/getAllFireExtinguishers`
      );
      const data = response.data.data;
      console.log(data,"FETCHUNQUIEVALUE");

      const uniquequantityReleased = [
        ...new Set(
          data
            .map((el) => el.quantityReleased)
            .filter(Boolean) // Filter out undefined values
        ),
      ];

      console.log(uniquequantityReleased);

      const uniquetypeOfSubstance = [
        ...new Set(
          data.map((el) => el.typeOfSubstance).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniquefugitiveEmissionSource = [
        ...new Set(
          data.map((el) => el.fugitiveEmissionSource).filter(Boolean) // Filter out undefined values
        ),
      ];

      return {
        quantityReleased: uniquequantityReleased,
        typeOfSubstance: uniquetypeOfSubstance,
        // fuelType: uniqueIndustrialEquipment,
        fugitiveEmissionSource:uniquefugitiveEmissionSource,
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  // fetching Vehicle data
  const fetchData = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    console.log(filters);
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
        company: companyId,
        createdBy: companyId,
      }).toString();
      const response = await axios.get(
        `${api}/scope1/fugitiveEmission/getAllFireExtinguishers?${queryParams}`
      );

      setData(response.data.data); // Update the user list
      console.log(response.data.data);
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortConfig]);

  const handleMenuClick = (event, element) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(element);
    setElement(element);
    console.log(element);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedData(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddData(true);
    setViewData(false);
  };

  const handleView = () => {
    handleMenuClose();
    setViewData(true);
    setAddData(false);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = async () => {
    // console.log("indexhandleDeleteAggree", vehicle, vehicle._id);
    try {
      console.log(element._id);
      const res = await axios.put(
        `${api}/scope1/fugitiveEmission/deleteFireExtinguisher?Id=${element._id}`
      );
      setDeleteDialog(false);
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
    } catch (err) {
      console.log(err);
      setsnackOpen({
        open: true,
        message: err.data?.response.message ?? "network Error",
        severity: "error",
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filtersToApply = {
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        acc[key] = selectedOptions.join(","); // Change to an array if needed
      }
      return acc;
    }, {}),
    minQuantity: quantityRelsed.min || "",
    maxQuantity: quantityRelsed.max || "",
    minHours: hoursRange.min || "",
    maxHours: hoursRange.max || "",
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
    //   "Fugitive EmissionSource": false,
      "Type Of Substance": false,
      "Quantity Released": false,
    };

    console.log(selectedFilter, "hiselectedFilter");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select start date & end date";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }

    // if (
    //   selectedFilter === "Fugitive EmissionSource" &&
    //   (selectedFields.fugitiveEmissionSource?.length || 0) === 0
    // ) {
    //   newErrors["Fugitive EmissionSource"] =
    //     "Please select atleast one Fugitive EmissionSource";
    //   isValid = false;
    // }

    if (
      selectedFilter === "Type Of Substance" &&
      selectedFields.typeOfSubstance.length === 0
    ) {
      newErrors["Type Of Substance"] =
        "Please select atleast one Type Of Substance";
      isValid = false;
    }


    if (selectedFilter === "Quantity Released") {
      const min = parseFloat(quantityRelsed.min);
      const max = parseFloat(quantityRelsed.max);

      if (!min && !max) {
        newErrors["Quantity Released"] =
          "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Quantity Released"] =
          "Min consumption must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Quantity Released"] =
          "Max consumption must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Quantity Released"] =
          "Min consumption cannot be greater than max consumption";
        isValid = false;
      }
    }
    if (selectedFilter === "Hours Used") {
      const min = parseFloat(hoursRange.min);
      const max = parseFloat(hoursRange.max);

      if (!min && !max) {
        newErrors["Hours Used"] = "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Hours Used"] = "Min hours must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Hours Used"] = "Max hours must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Hours Used"] =
          "Min hours cannot be greater than max consumption";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    console.log(selectedFields,"selectedFields");
    if (!validateFilters()) return;
    setPage(1);
    fetchData(filtersToApply);
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
        quantityReleased: [],
        typeOfSubstance: [],
        fugitiveEmissionSource: [],
    });
    setQuantityRange({ min: "", max: "" });
    setHoursRange({ min: "", max: "" });

    // Call fetchData without any filters
    await fetchData({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate;
    const isFugiiveApplied =
      selectedFields.fugitiveEmissionSource?.length > 0;
    const isTypeSubstanceApplied = selectedFields.typeOfSubstance?.length > 0;
    
    const isQuantityReleasedApplied = quantityRelsed.min && quantityRelsed.max;


    setIsFiltersApplied(
      isDateApplied ||
      isFugiiveApplied ||
        isTypeSubstanceApplied ||
         isQuantityReleasedApplied ||
        isFugiiveApplied 
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
    //   "Fugitive EmissionSource": isFugiiveApplied,
      "Type Of Substance": isTypeSubstanceApplied,
      "Quantity Released": isQuantityReleasedApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    // setErrors({ Date: false, Role: false });
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const columnHeadings = [
    { title: "Date", fieldName: "createdAt" },
    // { fieldName: "fugitiveEmissionSource", title: "Emission Source" },
    { fieldName: "typeOfSubstance", title: "Type of SubStance" },
    { fieldName: "quantityReleased", title: "Quantity Released(kg)" },
    // { fieldName: "quantityOfFuelConsumed", title: "Quantitye of Fuel" },
  ];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/scope1/fugitiveEmission/getAllFireExtinguishers`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
      case "Type Of Substance":
        return (
          <MultiSelect
            title="Type Of Substance"
            selectedField={selectedFields.typeOfSubstance}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Type Of Substance"]}
            setErrors={setErrors}
            field="typeOfSubstance"
            data={uniqueValues.typeOfSubstance}
          />
        );
     
    //   case "Fugitive EmissionSource":
    //     return (
    //       <MultiSelect
    //         title="Fugitive EmissionSource"
    //         selectedField={selectedFields.fugitiveEmissionSource}
    //         selectedFields={selectedFields}
    //         setSelectedFields={setSelectedFields}
    //         errors={errors["Fugitive EmissionSource"]}
    //         setErrors={setErrors}
    //         field="fugitiveEmissionSource"
    //         data={uniqueValues.fugitiveEmissionSource}
    //       />
    //     );
        case "Fuel Type":
          return (
            <MultiSelect
              title="Fuel Type"
              selectedField={selectedFields.fuelType}
              selectedFields={selectedFields}
              setSelectedFields={setSelectedFields}
              errors={errors["Fuel Type"]}
              setErrors={setErrors}
              field="fuelType"
              data={uniqueValues.fuelType}
            />
          );
      case "Quantity Released":
        return (
          <Range
            title="Quantity Released"
             selectedRange={quantityRelsed}
            setSelectedRange={setQuantityRange}
            errors={errors["Quantity Released"]}
            setErrors={setErrors}
            placeholderMin={"Min"}
            placeholderMax={"Max"}
          />
        );
      case "Hours Used":
        return (
          <Range
            title="Hours Used"
            selectedRange={hoursRange}
            setSelectedRange={setHoursRange}
            errors={errors["Hours Used"]}
            setErrors={setErrors}
            placeholderMin={"Min(hours)"}
            placeholderMax={"Max(hours)"}
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/scope1/fugitiveEmission/getAllFireExtinguishers`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.date,
            }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <MessagePopup {...{ snackOpen, setsnackOpen }} />{" "}
      {/*Message popup for success or failure */}
      {/* View log, Edit, delete menus */}
      <AddFireExtingulsher
        id={element ? element._id : ""}
        addData={addData}
        setAddData={setAddData}
        data={element}
        addUrl={`${api}/scope1/fugitiveEmission/addFireExtinguisher`}
        updateUrl={`${api}/scope1/fugitiveEmission/updateFireExtinguisher`}
        // details={vehicle}
        fetchData={fetchData}
        setsnackOpen={setsnackOpen}
        inputData={inputData}
      />
      <ViewFireExtingulsher
        id={element ? element._id : ""}
        data={element}
        viewData={viewData}
        setViewData={setViewData}
        inputData={inputData}
        handleEdit={handleEdit}
      />
      <Grid item xs={6} sx={{ margin: "0% 5% -3% 23%", width: "100%" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Fugitive Emissions
        </Typography>
        <Box sx={{ display: "flex", width: "83%", mt: 2, gap: 2 }}>
          {/* Card 1: Total Natural Gas Consumed */}
          <Cards title="Most Released Substance" content="10,000 m³" />
          <SelectCard
            title="Total Quantitty Released"
            content="400kg"
            handleChange={handlePeriodChange}
            value={"Company Owned"}
            list={["Company Owned"]}
          />

          <SelectCard
            title="Fugitive Released"
            content="700 kg"
            handleChange={handlePeriodChange}
            value={selectedPeriod}
            list={months}
          />
          {/* Card 3: Consumption Period */}
        </Box>
      </Grid>
      {deleteDialog && (
        <DeleteDialog
          {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
        />
      )}
      {/* Filter Drawer */}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setElement}
          setAddData={setAddData}
          {...{
            // heading: "Coal",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "3% 5% 0% 23%" }}
      >
        <Box>
          <TableHeading heading={"Fire Extinguisher"} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "12% 40% 37% 8%",
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            {columnHeadings.map((el) => (
              <ColumnHeadings
                key={el}
                {...{
                  title: el.title,
                  key: el.title,
                  handleSort,
                  field: el.fieldName,
                  sortConfig,
                }}
              />
            ))}
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {data &&
            data.map((item) => (
              <Box
                key={item._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "12% 40% 37% 8%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {columnHeadings.map((el) => (
                  <TableContent
                    key={el.fieldName}
                    {...{
                      fieldData:
                        typeof [el.fieldName] === "object" &&
                        // [el.fieldName] !== null &&
                         [el.fieldName].value
                          ? [el.fieldName]?.value
                          : typeof item[el.fieldName] === "object" &&
                            item[el.fieldName] !== null &&
                            item[el.fieldName].value
                          ? item[el.fieldName]?.value
                          : item[el.fieldName],
                      dateField: el.fieldName === "createdAt",
                    }}
                  />

                    // console.log(el.fieldName,item,item[el.fieldName],"el.fieldName")
                ))}
                {/* Menu Action Icon */}
               
                <MenuIcon {...{ handleMenuClick, field: item }} />
              </Box>
            ))}
        </Box>
      </TableContainer>
      {/* Pagination */}
      <PaginationBox
        {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
      />
      <MenuActions
        {...{
          id: element?._id,
          handleView,
          handleEdit,
          handleDelete,
          anchorEl,
          handleMenuClose,
        }}
      />
      {/*Add,Edit,Delete Icons & function calls */}
    </Box>
  );
};

export default FireExtingulsher;
