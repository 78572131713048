import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api, get, post } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import { useMemo } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";

function AddMachinery(props) {
  const [alreadyMachinery, setAlreadyMachinery] = useState(false);
  const [facilities, setFacilities] = useState(["Tap to Select"]);
  const [machineCategory, setMachineCategory] = useState([]);
  const [machineTypes, setMachineTypes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [errors, setErrors] = useState({});
  const [numberOfMachines, setNumberOfMachines] = useState(null);
  const [equipments, setEquipments] = useState([]);
  const [openMachines, setOpenMachines] = useState([]);
  const [selectedMachineType, setSelectedMachineType] = useState("");
  const initialState = {
    company: companyId,
    location: "",
    facility: "",
    machineCategory: "",
    machineType: "",
    name: "",
    fuelType: "",
    // noOfEquipment: "",
    numberOfMachines: "",
    equipments: [],
    remark: "",
  };
  const [fromValue, setFromValue] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [machineryNames, setMachineryNames] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedDeleteIndex, setSelectedDeleteIndex] = useState(null);
  const [customMachineType, setCustomMachineType] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  // console.log("fomrva", fromValue);
  console.log("props1s", props?.indexMachinery);

  useEffect(() => {
    // Fetch facilities data
    axios
      .get(`${api}/master/getAllFacility`)
      .then((res) => {
        setFacilities(res.data.data); // Assuming the response contains facility data
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  useEffect(() => {
    // Fetch machinery types from the API
    axios
      .get(`${api}/master/getAllMachineryType`)
      .then((res) => {
        // console.log(res.data);
        if (res.data && Array.isArray(res.data.data)) {
          // Update state with fetched machinery types, including _id and name
          setMachineCategory(
            res.data.data.map((item) => ({
              id: item._id, // Assuming your API returns _id
              name: item.type, // Adjust according to your API response structure
            }))
          );
          // setAlreadyMachinery(!!props.selectedEquipment);
        } else {
          console.log("Unexpected response structure:", res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (props?.indexMachinery) {
      setFromValue(props?.indexMachinery);
    } else {
      setFromValue(initialState);
    }
  }, [props?.indexMachinery]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFromValue((prev) => ({ ...prev, [name]: value }));

    // Clear specific field error if input is now valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      // Remove the error for 'name' if it's now valid
      if (name === "name" && value.trim() !== "") {
        const isDuplicate = machineryNames.includes(value.toLowerCase());
        if (isDuplicate) {
          newErrors[name] = "Machinery name already exists.";
        } else {
          delete newErrors[name];
        }
      }

      // Example: remove error for 'location' if not empty
      if (name === "location" && value.trim() !== "") {
        delete newErrors.location;
      }

      // Add similar conditions for other fields
      if (name === "facility" && value !== "Tap to Select") {
        delete newErrors.facility;
      }
      if (name === "machineCategory" && value !== "Tap to Select") {
        delete newErrors.machineCategory;
      }
      if (name === "machineType" && value !== "Tap to Select") {
        delete newErrors.machineType;
      }
      if (name === "fuelType" && value !== "Tap to Select") {
        delete newErrors.fuelType;
      }
      if (name === "numberOfMachines" && value > 0) {
        delete newErrors.numberOfMachines;
      }

      return newErrors;
    });
  };

  const handleCategoryChange = (e) => {
    const selectedId = e.target.value;
    const selectedCategory = machineCategory.find(
      (category) => category.id === selectedId
    );

    setSelectedCategory(selectedCategory?.name || "");
    setMachineTypes([]); // Clear previous machine types

    if (selectedCategory) {
      axios
        .get(`${api}/master/getAllMachineryType`, {
          params: { type: selectedCategory.name },
        })
        .then((res) => {
          if (res.data && Array.isArray(res.data.data)) {
            const machineTypesWithIds = res.data.data.map((type) => ({
              id: type._id,
              name: type.name,
            }));
            setMachineTypes(machineTypesWithIds);
          } else {
            console.log("Unexpected response structure:", res.data);
          }
        })
        .catch((err) => {
          console.error("Error fetching machine types:", err);
        });
    } else {
      console.log("Selected category not found.");
    }

    setFromValue((prevState) => ({
      ...prevState,
      machineCategory: selectedId,
      machineType: "", // Reset machineType if category changes
    }));
    validateField("machineCategory", selectedId);
  };

  const validateField = (fieldName, value) => {
    let errorMsg = "";
    if (fieldName === "machineCategory" && !value) {
      errorMsg = "Please select a machine category";
    }

    if (fieldName === "machineType" && !value) {
      errorMsg = "Please select a machine Type";
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMsg,
    }));
  };

  const handleMachineTypeChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "Other") {
      setIsOtherSelected(true);
      setCustomMachineType(""); // Clear any previous custom input
      setFromValue((prevState) => ({
        ...prevState,
        machineType: "", // Clear the machineType field
      }));
    } else {
      setIsOtherSelected(false);
      setFromValue((prevState) => ({
        ...prevState,
        machineType: selectedValue, // Set the selected machine type
      }));
    }

    validateField("machineType", selectedValue);
  };

  const handleCustomMachineTypeChange = (e) => {
    setCustomMachineType(e.target.value);
  };

  const addCustomMachineType = async () => {
    if (!customMachineType) return; // Prevent adding empty values

    setLoading(true);
    try {
      // Call API to add the new machine type with the selected category
      await post(`/master/addMachineryType`, {
        name: customMachineType,
        type: selectedCategory,
      })
        .then((res) => {
          setMachineTypes((prevTypes) => [
            ...prevTypes,
            { id: res?.data?.data?._id, name: res?.data?.data?.name },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   get(`/master/getAllMachineryType?type=${selectedCategory}`)
  //     .then((res) => {
  //       console.log("post1", res?.data);
  //       setMachineCategory(
  //         res.data.data.map((item) => ({
  //           id: item._id, // Assuming your API returns _id
  //           name: item.type, // Adjust according to your API response structure
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [machineTypes]);

  // console.log("post12", machineTypes);

  const handleNoOfEquipmentChange = (e) => {
    const value = parseInt(e.target.value, 10);

    setErrors((prevErrors) => ({
      ...prevErrors,
      numberOfMachines: "", // Remove the error for numberOfMachines
    }));

    if (!isNaN(value) && value > 0) {
      setNumberOfMachines(value); // Only set if it's a valid number

      const equipmentList = Array.from({ length: value }, (_, index) => ({
        id: `equipment-${index + 1}`,
        uniqueID: "",
        brand: "",
        model: "",
        ratedFuelConsumption: "",
        unitOfFuelConsumption: "",
      }));

      setEquipments(equipmentList);
      setOpenMachines(Array(value).fill(false)); // For toggling visibility
    } else {
      setNumberOfMachines(null); // Set to null if input is invalid
    }
  };

  const handleFieldChange = (index, field, value) => {
    // console.log(
    //   `Updating equipment ${index}, field ${field}, with value ${value}`
    // );
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${field}_${index}`]: "",
    }));
    const updatedEquipments = [...equipments];
    updatedEquipments[index][field] = value;
    setEquipments(updatedEquipments);
  };

  const toggleEquipment = (index) => {
    const updatedOpen = [...openMachines];
    updatedOpen[index] = !updatedOpen[index];
    setOpenMachines(updatedOpen);
  };

  const validateFields = () => {
    const newErrors = {};

    // Validate general fields
    if (!fromValue.location) {
      newErrors.location = "Location is required.";
    }
    if (!fromValue.facility || fromValue.facility === "Tap to Select") {
      newErrors.facility = "Facility must be selected.";
    }
    if (
      !fromValue.machineCategory ||
      fromValue.machineCategory === "Tap to Select"
    ) {
      newErrors.machineCategory = "Machinery Category must be selected.";
    }
    if (!fromValue.machineType || fromValue.machineType === "Tap to Select") {
      newErrors.machineType = "Machine Type must be selected.";
    }
    if (!fromValue.name) {
      newErrors.name = "Machinery Name is required.";
    }
    if (!fromValue.fuelType || fromValue.fuelType === "Tap to Select") {
      newErrors.fuelType = "Fuel Type must be selected.";
    }
    if (!numberOfMachines) {
      newErrors.numberOfMachines = "Number of Machines is required.";
    } else if (numberOfMachines <= 0) {
      newErrors.numberOfMachines = "Number of Machines must be greater than 0.";
    } else if (numberOfMachines > 0 && equipments.length === 0) {
      newErrors.equipments = "At least one equipment entry must be added.";
    }

    const uniqueIDs = equipments.map((equipment) => equipment.uniqueID);
    const duplicateUniqueIDs = uniqueIDs.reduce((acc, id, index) => {
      if (uniqueIDs.indexOf(id) !== index && id) {
        acc[id] = acc[id] || [];
        acc[id].push(index);
      }
      return acc;
    }, {});
    // Add error messages for each duplicate unique ID
    Object.values(duplicateUniqueIDs).forEach((indices) => {
      indices.forEach((index) => {
        newErrors[
          `uniqueID_${index}`
        ] = `Unique ID "${equipments[index].uniqueID}" is duplicated. Please provide a unique ID.`;
      });
    });

    // Validate individual equipment fields if numberOfMachines is greater than 0
    if (numberOfMachines > 0) {
      equipments.forEach((equipment, index) => {
        let machineryValidationMessage = "";

        // Validate each required field
        if (!equipment.uniqueID) {
          newErrors[`uniqueID_${index}`] = `Unique ID for Machinery ${
            index + 1
          } is required.`;
        }
        if (!equipment.brand) {
          newErrors[`brand_${index}`] = `Brand for Machinery ${
            index + 1
          } is required.`;
        }
        if (!equipment.model) {
          newErrors[`model_${index}`] = `Model for Machinery ${
            index + 1
          } is required.`;
        }
        if (!equipment.ratedFuelConsumption) {
          newErrors[
            `ratedFuelConsumption_${index}`
          ] = `Rated Fuel Consumption for Machinery ${index + 1} is required.`;
        } else if (isNaN(equipment.ratedFuelConsumption)) {
          newErrors[
            `ratedFuelConsumption_${index}`
          ] = `Please enter a valid numeric value for Machinery ${index + 1}.`;
        } else if (equipment.ratedFuelConsumption < 0) {
          newErrors[
            `ratedFuelConsumption_${index}`
          ] = `Please enter valid numeric value for Machinery ${index + 1}.`;
        }
        if (!equipment.unitOfFuelConsumption) {
          newErrors[
            `unitOfFuelConsumption_${index}`
          ] = `Unit of Fuel Consumption for Machinery ${
            index + 1
          } is required.`;
        }

        // Set machineryValidationMessage only if any field is missing for this equipment
        if (
          !equipment.uniqueID ||
          !equipment.brand ||
          !equipment.model ||
          !equipment.ratedFuelConsumption ||
          !equipment.unitOfFuelConsumption
        ) {
          machineryValidationMessage = `Please fill data of Machinery ${
            index + 1
          }.`;
          newErrors[`machinery_${index}`] = machineryValidationMessage;
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  useEffect(() => {
    // Fetch all machinery names from the API on component mount
    const fetchMachineryNames = async () => {
      try {
        const response = await axios.get(
          "http://localhost:8081/master/getAllMachineryType"
        );
        const names = response.data.data.map((item) => item.name.toLowerCase());
        setMachineryNames(names);
      } catch (error) {
        console.error("Error fetching machinery names:", error);
      }
    };

    fetchMachineryNames();
  }, []);

  useEffect(() => {
    // console.log("props.indexMachinery?._id", props.indexMachinery);
    setFromValue({
      company: companyId,
      location: props.indexMachinery?.location ?? "",
      facility: props.indexMachinery?.facility?._id ?? "",
      fuelType: props.indexMachinery?.fuelType ?? "",
      machineCategory: props.indexMachinery?.machineCategory?._id ?? "",
      // machineType: props.indexMachinery?.machineType?._id ?? "",
      machineType: props.indexMachinery?.machineType?._id ?? "",
      equipments: props.indexMachinery?.equipments ?? [],

      numberOfMachines: props.indexMachinery?.numberOfMachines ?? "",
      name: props.indexMachinery?.name ?? "",
      remark:
        props.indexMachinery?.remark === ""
          ? "-"
          : props.indexMachinery?.remark || "",
    });
    // setFacilities(props.indexMachinery?.facility?._id ?? "")
    setEquipments(props.indexMachinery?.equipments ?? []);
    setNumberOfMachines(props.indexMachinery?.numberOfMachines ?? "");
    setAlreadyMachinery(props.indexMachinery?._id === undefined ? false : true);
  }, [props]);

  useEffect(() => {
    if (fromValue.machineCategory) {
      let selectedId = fromValue.machineCategory;
      const selectedCategory = machineCategory.find(
        (category) => category.id === selectedId
      );

      if (selectedCategory) {
        // Check if selectedCategory exists
        const categoryType = selectedCategory.name;
        axios
          .get(`${api}/master/getAllMachineryType`, {
            params: { type: categoryType },
          })
          .then((res) => {
            if (res.data && Array.isArray(res.data.data)) {
              const machineTypesWithIds = res.data.data.map((type) => ({
                id: type._id,
                name: type.name,
              }));
              setMachineTypes(machineTypesWithIds);
            } else {
              console.log("Unexpected response structure:", res.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log("No matching machine category found");
      }
    }
  }, [fromValue.machineCategory, machineCategory]);

  // console.log("inis", initialState);

  const resetForm = () => {
    console.log("_idddddd", props.indexMachinery);
    // setFromValue(
    //   props.indexMachinery?._id === undefined
    //     ? initialState
    //     : props?.indexMachinery
    // );
    // setErrors({});
    // setFromValue(
    //   props.selectedEquipment === null ? initialState : props.selectedEquipment
    // );
    // setFromValue(initialState);
    setNumberOfMachines(null);
    setEquipments([]);
    setErrors({});
  };

  const formTitle =
    props.indexMachinery?._id !== undefined
      ? "Edit Machinery"
      : "Add Machinery";

  const handleDeleteAgree = () => {
    if (selectedDeleteIndex !== null) {
      const updatedEquipments = equipments.filter(
        (_, index) => index !== selectedDeleteIndex
      );
      setEquipments(updatedEquipments);
      setSelectedDeleteIndex(null);
      setNumberOfMachines(updatedEquipments.length);
    }
    setDeleteDialog(false);
  };

  // Open dialog on delete icon click
  const handleDeleteClick = (index) => {
    setSelectedDeleteIndex(index);
    setDeleteDialog(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateFields()) {
      const data = {
        date: new Date(),
        machineType: fromValue.machineType,
        machineCategory: fromValue.machineCategory,
        company: fromValue.company,
        facility: fromValue.facility,
        location: fromValue.location,
        name: fromValue.name,
        remark: fromValue.remark,
        equipments,
        fuelType: fromValue.fuelType,
        numberOfMachines: numberOfMachines || 0, // Default to 0 if null
      };

      axios({
        method: alreadyMachinery ? "put" : "post",
        url: alreadyMachinery
          ? `${api}/master/updateMachinery`
          : `${api}/master/addMachinery`,
        data: data, // Send data, with a valid numberOfMachines
        params: alreadyMachinery ? { id: props.indexMachinery?._id } : null, // Send params only for PUT request
      })
        .then((response) => {
          // console.log("Data saved successfully", response.data);
          resetForm();
          props.setValue(false);
          props.fetchMachinery();
          props.setsnackOpen({
            open: true,
            message: response.data.message,
            severity: "success",
          });
        })
        .catch((error) => {
          // console.error("Error saving data:", error);
          // props.setsnackOpen({ open: true, message: error?.response?.data?.message, severity: "error" });
          if (
            error.response &&
            error.response.data.message === "Machinery name already exists."
          ) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              name: "Machinery name already exists.",
            }));
          } else {
            console.error("An error occurred:", error);
          }
        });
    }
  };

  const fuelTypes = [
    "Diesel (average biofuel blend)",
    "Diesel (100% mineral diesel)",
    "Petrol (average biofuel blend)",
    "Petrol (100% mineral petrol)",
    "CNG",
    "Electricity",
  ];

  const UnitOfFuel = ["Kg", "Ltr", "Kwh"];

  const uniqueMachineCategories = useMemo(() => {
    const seen = new Set();
    return machineCategory.filter((category) => {
      const duplicate = seen.has(category.name);
      seen.add(category.name);
      return !duplicate;
    });
  }, [machineCategory]);

  const handleTypeChange = (event) => {
    const selectedTypeId = event.target.value;
    setFromValue((prevState) => ({
      ...prevState,
      machineType: selectedTypeId,
    }));
    // console.log("Selected Machine Type ID:", selectedTypeId);
  };

  //adding dyanamic

  const addEquipment = () => {
    const newEquipment = {
      // uniqueID: generateUniqueID(),
      // name: 'New Equipment',
      uniqueID: "",
      brand: "",
      model: "",
      ratedFuelConsumption: "",
      unitOfFuelConsumption: "",
      // Add other fields as necessary
    };

    setEquipments((prev) => [...prev, newEquipment]);
    setNumberOfMachines((prev) => prev + 1);
  };

  const removeEquipment = (index) => {
    setEquipments((prev) => {
      const updatedEquipments = prev.filter((_, i) => i !== index);
      setNumberOfMachines(updatedEquipments.length); // Update the count
      return updatedEquipments;
    });
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 400,
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "transparent",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {
            props.setValue(false);
            props.fetchMachinery();
            resetForm();
          }}
          // onClick={() => {props.setValue(false); resetForm()}}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          p: 3,
          backgroundColor: "#fff",
          overflowY: "scroll",
        }}
      >
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {/* {props.index !== 0 ? "Edit Role" : "Add Industrial Equipment Master"} */}
          {/* {props.selectedEquipment ? "Edit Machinery" : "Add Machinery"} */}
          {formTitle}
        </Typography>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Location
        </Typography>

        <CustomTextField
          variant="outlined"
          value={fromValue?.location}
          fullWidth
          name="location"
          placeholder="Enter Location"
          // onChange={(e) => setFromValue({ ...fromValue, location: e.target.value })}
          onChange={handleInputChange}
          error={!!errors.location}
          disabled={props.indexMachinery?.location === undefined ? false : true}
        />
        {errors.location && (
          <Typography variant="body2" color="#D34040">
            {errors.location}
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Facility
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          // value={fromValue.facility || ""}
          value={
            fromValue.facility === "" ? "Tap to Select" : fromValue.facility
          }
          fullWidth
          name="facility"
          placeholder="Tap to Select"
          // onChange={(e) => setFromValue({ ...fromValue, facility: e.target.value })}
          onChange={handleInputChange}
          error={!!errors.facility}
          disabled={props.indexMachinery?.facility === undefined ? false : true}
          InputLabelProps={{
            style: { color: errors.facility ? "#D34040" : undefined },
          }}
        >
          <MenuItem
            value={
              fromValue.facility === ""
                ? "Tap to Select"
                : fromValue.facility.facilityName
            }
            disabled
          >
            {fromValue.facility === ""
              ? "Tap to Select"
              : fromValue.facility.facilityName}
          </MenuItem>
          {facilities.map((facility) => (
            <MenuItem key={facility._id} value={facility._id}>
              {facility.facilityName}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.facility && (
          <Typography variant="body2" color="#D34040">
            {errors.facility}
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Machinery Category
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          // value={fromValue.machineCategory}
          value={
            fromValue.machineCategory === ""
              ? "Tap to Select"
              : fromValue.machineCategory
          }
          fullWidth
          name="machineCategory"
          onChange={handleCategoryChange}
          error={!!errors.machineCategory}
          disabled={
            props.indexMachinery?.machineCategory === undefined ? false : true
          }
        >
          <MenuItem
            value={
              fromValue.machineCategory === ""
                ? "Tap to Select"
                : fromValue.machineCategory.name
            }
            disabled
          >
            {fromValue.machineCategory === ""
              ? "Tap to Select"
              : fromValue.machineCategory.name}
          </MenuItem>
          {uniqueMachineCategories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.machineCategory && (
          <Typography variant="body2" color="#D34040">
            {errors.machineCategory}
          </Typography>
        )}

        {/* <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
      Machinery Type
      </Typography>
      <CustomTextField
          select
          variant="outlined"
          // value={fromValue.machineType}
          value = {fromValue.machineType==="" ? "Tap to Select" : fromValue.machineType}
          // value={fromValue.machineType ? fromValue.machineType.id : ""}
          fullWidth
          name="machineType"
          onChange={handleMachineTypeChange}
          error={!!errors.machineType}
          disabled={props.indexMachinery?.machineType === undefined ? false : true}
      >
          <MenuItem value = {fromValue.machineType==="" ? "Tap to Select" : fromValue.machineType.name}disabled>
          {fromValue.machineType==="" ? "Tap to Select" : fromValue.machineType.name}
          </MenuItem>
          
          {machineTypes.length === 0 ? (
              <MenuItem disabled>No Machine Types Available</MenuItem>
          ) : (
              machineTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}> 
                      {type.name}  
                  </MenuItem>
              ))
          )}
      </CustomTextField>
      {errors.machineType && (
              <Typography variant="body2" color="#D34040">
                  {errors.machineType}
              </Typography>
          )}
      */}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Machine Type
        </Typography>

        <CustomTextField
          select
          variant="outlined"
          value={
            fromValue.machineType === ""
              ? "Tap to Select"
              : fromValue.machineType
          }
          fullWidth
          name="machineType"
          onChange={handleMachineTypeChange}
          error={!!errors.machineType}
          disabled={
            props.indexMachinery?.machineType === undefined ? false : true
          }
        >
          <MenuItem value="Tap to Select" disabled>
            Tap to Select
          </MenuItem>
          {machineTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
          <MenuItem value="Other">Other</MenuItem>
        </CustomTextField>

        {errors.machineType && (
          <Typography variant="body2" color="#D34040">
            {errors.machineType}
          </Typography>
        )}

        {isOtherSelected && (
          <Box mt={2}>
            <CustomTextField
              placeholder="Custom Machine Type"
              value={customMachineType}
              onChange={handleCustomMachineTypeChange}
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              onClick={addCustomMachineType}
              sx={{
                backgroundColor: "#0a3f73",
                textTransform: "none",
                marginTop: "3%",
              }}
              disabled={loading}
            >
              Add
            </Button>
          </Box>
        )}

        {/* {console.log("Machine Types in State:", machineTypes)} */}
        {/* {console.log("Selected Machine Type:", fromValue.machineType)} */}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Machinery Name
        </Typography>
        <CustomTextField
          variant="outlined"
          value={fromValue.name}
          fullWidth
          name="name"
          placeholder="Enter name"
          // onChange={(e) => setFromValue({ ...fromValue, name: e.target.value })}
          onChange={handleInputChange}
          error={!!errors.name}
          disabled={props.indexMachinery?.name === undefined ? false : true}
        />
        {errors.name && (
          <Typography variant="body2" color="#D34040">
            {errors.name}
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Fuel Type
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          // value={fromValue.fuelType}
          value={
            fromValue.fuelType === "" ? "Tap to Select" : fromValue.fuelType
          }
          fullWidth
          name="fuelType"
          // onChange={(e) => setFromValue({ ...fromValue, fuelType: e.target.value })}
          onChange={handleInputChange}
          placeholder="Tap to Select"
          error={!!errors.fuelType}
          disabled={props.indexMachinery?.fuelType === undefined ? false : true}
          InputLabelProps={{
            style: { color: errors.fuelType ? "#D34040" : undefined },
          }}
        >
          <MenuItem
            value={
              fromValue.fuelType === "" ? "Tap to Select" : fromValue.fuelType
            }
            disabled
          >
            {fromValue.fuelType === "" ? "Tap to Select" : fromValue.fuelType}
          </MenuItem>
          {fuelTypes.map((fuel, index) => (
            <MenuItem key={index} value={fuel}>
              {fuel}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.fuelType && (
          <Typography variant="body2" color="#D34040">
            {errors.fuelType}
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          No of Machinery
        </Typography>
        <CustomTextField
          variant="outlined"
          value={numberOfMachines || ""}
          placeholder="Enter number of equipment"
          fullWidth
          onChange={handleNoOfEquipmentChange}
          disabled={props.indexMachinery?.fuelType === undefined ? false : true}
        />
        {errors.numberOfMachines && (
          <Typography variant="body2" color="#D34040">
            {errors.numberOfMachines}
          </Typography>
        )}

        {equipments.map((equipment, index) => (
          <Box key={equipment.id} sx={{ mt: 2 }}>
            {/* Equipment Title with Expand/Collapse Button */}
            <Dialog
              open={deleteDialog}
              onClose={() => setDeleteDialog(false)}
              BackdropProps={{
                style: {
                  backgroundColor: "rgba(0, 0, 0, 0.02)", // Adjust the opacity as needed (0.3 is lighter)
                },
              }}
            >
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Do you really want to delete this entry?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteDialog(false)} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteAgree} color="secondary">
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Machinery {index + 1}
              </Typography>
              <Box>
                <IconButton onClick={() => toggleEquipment(index)}>
                  {openMachines[index] ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
                {/* <button onClick={() => removeEquipment(index)}>Remove</button> */}
                {/* Delete button */}
                <IconButton
                  // onClick={() => removeEquipment(index)}
                  onClick={() => handleDeleteClick(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>

            {/* {errors[`machinery_${index}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`machinery_${index}`]}
            </Typography>
          )} */}
            {errors[`uniqueID_${index}`] && (
              <Typography variant="body2" color="#D34040">
                {`Please fill Equipment ${index + 1} form`}
              </Typography>
            )}

            {/* Collapse for Equipment Fields */}
            <Collapse in={openMachines[index]} timeout="auto" unmountOnExit>
              <Box sx={{ mt: 1 }}>
                {/* Unique ID */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                >
                  Unique ID
                </Typography>
                <CustomTextField
                  variant="outlined"
                  // value={equipment.uniqueID}
                  value={equipments[index].uniqueID}
                  placeholder="Enter ID"
                  fullWidth
                  onChange={(e) =>
                    handleFieldChange(index, "uniqueID", e.target.value)
                  }
                  error={!!errors[`uniqueID_${index}`]}
                  disabled={false}
                />
                {errors[`uniqueID_${index}`] && (
                  <Typography variant="body2" color="#D34040">
                    {errors[`uniqueID_${index}`]}
                  </Typography>
                )}

                {/* Brand */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                >
                  Brand
                </Typography>
                <CustomTextField
                  variant="outlined"
                  value={equipment.brand}
                  placeholder="Enter Brand"
                  fullWidth
                  onChange={(e) =>
                    handleFieldChange(index, "brand", e.target.value)
                  }
                  error={!!errors[`brand_${index}`]}
                  disabled={false}
                />
                {errors[`brand_${index}`] && (
                  <Typography variant="body2" color="#D34040">
                    {errors[`brand_${index}`]}
                  </Typography>
                )}

                {/* Model */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                >
                  Model
                </Typography>
                <CustomTextField
                  variant="outlined"
                  value={equipment.model}
                  placeholder="Enter Model"
                  fullWidth
                  onChange={(e) =>
                    handleFieldChange(index, "model", e.target.value)
                  }
                  error={!!errors[`model_${index}`]}
                  disabled={false}
                />
                {errors[`model_${index}`] && (
                  <Typography variant="body2" color="#D34040">
                    {errors[`model_${index}`]}
                  </Typography>
                )}

                {/* Rated Fuel Consumption */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                >
                  Rated Fuel Consumption
                </Typography>
                <CustomTextField
                  variant="outlined"
                  value={equipments[index].ratedFuelConsumption}
                  placeholder="Amount"
                  fullWidth
                  onChange={(e) =>
                    handleFieldChange(
                      index,
                      "ratedFuelConsumption",
                      e.target.value
                    )
                  }
                  error={!!errors[`ratedFuelConsumption_${index}`]}
                  disabled={false}
                />
                {errors[`ratedFuelConsumption_${index}`] && (
                  <Typography variant="body2" color="#D34040">
                    {errors[`ratedFuelConsumption_${index}`]}
                  </Typography>
                )}

                {/* Unit of Fuel Consumption */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                >
                  Unit of Fuel Consumption
                </Typography>
                {/* <CustomTextField
                              variant="outlined"
                              value={equipment.unitOfFuelConsumption}
                              placeholder="ltr/kg per hour"
                              fullWidth
                              onChange={(e) => handleFieldChange(index, 'unitOfFuelConsumption', e.target.value)}
                              error={!!errors[`unitOfFuelConsumption_${index}`]}
                          /> */}

                <CustomTextField
                  select
                  variant="outlined"
                  // value={fromValue.fuelType}
                  value={
                    equipment.unitOfFuelConsumption === ""
                      ? "Tap to Select"
                      : equipment.unitOfFuelConsumption
                  }
                  fullWidth
                  name="fuelType"
                  onChange={(e) =>
                    handleFieldChange(
                      index,
                      "unitOfFuelConsumption",
                      e.target.value
                    )
                  }
                  placeholder="Tap to Select"
                  error={!!errors[`unitOfFuelConsumption_${index}`]}
                  disabled={false}
                  InputLabelProps={{
                    style: { color: errors.fuelType ? "#D34040" : undefined },
                  }}
                >
                  <MenuItem
                    value={
                      equipment.unitOfFuelConsumption === ""
                        ? "Tap to Select"
                        : equipment.unitOfFuelConsumption
                    }
                    disabled
                  >
                    {equipment.unitOfFuelConsumption === ""
                      ? "Tap to Select"
                      : equipment.unitOfFuelConsumption}
                  </MenuItem>
                  {UnitOfFuel.map((fuel, index) => (
                    <MenuItem key={index} value={fuel}>
                      {fuel}
                    </MenuItem>
                  ))}
                </CustomTextField>

                {errors[`unitOfFuelConsumption_${index}`] && (
                  <Typography variant="body2" color="#D34040">
                    {errors[`unitOfFuelConsumption_${index}`]}
                  </Typography>
                )}
              </Box>
            </Collapse>
          </Box>
        ))}

        {alreadyMachinery && (
          <Button
            sx={{ mt: 2, backgroundColor: "#0a3f73" }}
            onClick={addEquipment}
            variant="contained"
          >
            Add Equipment
          </Button>
        )}

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Remark
        </Typography>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          name="remark"
          value={fromValue.remark}
          onChange={(e) =>
            setFromValue({ ...fromValue, remark: e.target.value })
          }
          placeholder="Brief"
        />

        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        anchor="right"
        open={props.value}
        onClose={() => {
          props.setValue(false);
          resetForm();
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddMachinery;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));