import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Button, Typography } from '@mui/material';
import axios from 'axios';
import "../../../../Roles and Permissions/Filters/Scrollbar.css"
const ModelFilter = ({ selectedModels = [], setSelectedModels,url,errors }) => {
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    // Fetch all Facilities from the backend
    const fetchFacilities = async () => {
      try {
        const response = await axios.get(url);
        // Extract unique Facility names from the user data
        console.log(response.data)
        const uniqueFacilities = [...new Set(response.data.data.map((item) => item.model))];
        setFacilities(uniqueFacilities);
        console.log("uniqueFacilities",uniqueFacilities)
      } catch (error) {
        console.error('Error fetching Facilities:', error);
      }
    };

    fetchFacilities();
  }, []);

  const handleFacilityChange = (event) => {
    const selectedEqupments = event.target.value;
    if (event.target.checked) {
      // Add Facility to selected list
      setSelectedModels((prevSelected) => [...prevSelected, selectedEqupments]);
    } else {
      // Remove Facility from selected list
      setSelectedModels((prevSelected) =>
        prevSelected.filter((Facility) => Facility !== selectedEqupments)
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column',ml:2, paddingBottom: "15%",height:"100%" }}>
     <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          marginBottom: '30px',
          // m:"10%"
         }}
      >
        Model
      </Typography>
      <Box className="scrollable-container">
      {facilities.length > 0 ? (
        facilities.map((Model, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                value={Model}
                checked={selectedModels?.includes(Model)} // Ensure selectedEqupments is an array
                onChange={handleFacilityChange}
              />
            }
            label={Model}
          />
        ))
      ) : (
        <Typography>No Models found</Typography>
      )}
    </Box>
    {errors && (
            <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
              {errors}
            </Typography>
          )}
    </Box>
  );
};

export default ModelFilter;