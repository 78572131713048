// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Sidebar from '../sidebar';
// import { Box, Typography, TextField, Button } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import EastIcon from '@mui/icons-material/East';
// import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
// import axios from 'axios';
// import { companyId } from "../../../utils/companyId";
// import { api } from "../../../utils/api";

// const CustomerRelation = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     netPromoterScore: '',
//     customerSatisfactionIndex: '',
//     highBreaches: '',
//     mediumBreaches: '',
//     lowBreaches: '',
//     totalBreaches: '',
//     product: '',
//     numberOfProductsRecalled: ''
//   });
//   const [isExist, setIsExist] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (companyId) {
//         try {
//           const response = await axios.get(`${api}/social/customer_relations/${companyId}`);
//           setFormData({
//             netPromoterScore: response.data.customerSatisfactionScore.netPromoterScore || '',
//             customerSatisfactionIndex: response.data.customerSatisfactionScore.customerSatisfactionIndex || '',
//             highBreaches: response.data.dataPrivacyAndSecurityIncidents.high.numberOfBreaches || '',
//             mediumBreaches: response.data.dataPrivacyAndSecurityIncidents.medium.numberOfBreaches || '',
//             lowBreaches: response.data.dataPrivacyAndSecurityIncidents.low.numberOfBreaches || '',
//             totalBreaches: response.data.dataPrivacyAndSecurityIncidents.totalBreaches || '',
//             product: response.data.productQualityAndSafety.product || '',
//             numberOfProductsRecalled: response.data.productQualityAndSafety.numberOfProductsRecalled || ''
            
//           });
//           setIsExist(true);
//         } catch (error) {
//           console.error("There was an error fetching the customer relations data!", error);
//         }
//       }
//     };

//     fetchData();
//   }, [companyId]);

  

//   // Calculate total breaches
//   useEffect(() => {
//     const high = parseInt(formData.highBreaches, 10) || 0;
//     const medium = parseInt(formData.mediumBreaches, 10) || 0;
//     const low = parseInt(formData.lowBreaches, 10) || 0;
  
//     const total = high + medium + low;
  
//     setFormData((prevData) => ({
//       ...prevData,
//       totalBreaches: total
//     }));
//   }, [formData.highBreaches, formData.mediumBreaches, formData.lowBreaches]);
  

//   // Handle input change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value
//     }));
//   };

//   const Save = async () => {
//     const postData = {
//       customerSatisfactionScore: {
//         netPromoterScore: formData.netPromoterScore,
//         customerSatisfactionIndex: formData.customerSatisfactionIndex
//       },
//       dataPrivacyAndSecurityIncidents: {
//         high: {
//           numberOfBreaches: parseInt(formData.highBreaches, 10)
//         },
//         medium: {
//           numberOfBreaches: parseInt(formData.mediumBreaches, 10)
//         },
//         low: {
//           numberOfBreaches: parseInt(formData.lowBreaches, 10)
//         },
//         totalBreaches: parseInt(formData.totalBreaches, 10)
//       },
//       productQualityAndSafety: {
//         product: formData.product,
//         numberOfProductsRecalled: parseInt(formData.numberOfProductsRecalled, 10)
//       },
//       companyId: companyId // Assuming companyId is already set correctly
//     };
  
//     try {
//       if (isExist) {
//         // Perform PUT request to update existing record
//         await axios.put(`${api}/social/customer_relations/${companyId}`, postData);
//         alert("Customer relations data updated successfully!");
//       } else {
//         // Perform POST request to create a new record
//         await axios.post(`${api}/social/customer_relations`, postData);
//         window.alert("Customer relations data created successfully!");
//       }
//       navigate("/social/supply-chain");
//     } catch (error) {
//       console.error("There was an error saving the customer relations data!", error);
//       window.alert("An error occurred while saving the customer relations data. Please try again.");
//     }
//   };

//   const Back = () => {
//     navigate("/social/human-right");
//   };

//   return (
//     <Box sx={{
//       display: "flex",
//       backgroundColor: "#EDEDED",
//       height: "100vh",
//       overflow: "hidden" // Ensure no content gets hidden by scrollbars
//     }}>
//      <Sidebar title="Customer Relations" />
//       <Box sx={{
//         p: 2,
//         mt: 3,
//         width: "100%",
//         height: "100vh", // Full viewport height
//         overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "flex-start", // Adjust to start at the top of the box
//       }}>
//         <Typography variant="h5" sx={{
//           fontWeight: "bold",
//           zIndex: 1,
//           padding: "10px",
//           borderRadius: "4px",
//           alignSelf: "flex-start",
//           ml: "5rem"
//         }}>
//         SOCIAL: CUSTOMER RELATIONS
//         </Typography>

//         {/* Customer Satisfaction Score */}
//         <Box sx={{width: '80%'}}>
//         <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '2px', marginTop: '20px' }}>
//           Customer Satisfaction Score
//         </Typography>
//         <Box display="flex" gap={2} mb={2} sx={{ width: '100%' }}>
//           <Box sx={{ flex: 1 }}>
//             <TextField
//               variant="outlined"
//               placeholder="Net Promoter Score"
//               name="netPromoterScore"
//               value={formData.netPromoterScore}
//               onChange={handleInputChange}
//               sx={{
//                 width: '100%',
//                 backgroundColor: '#EDEDED',
//                 borderRadius: '4px',
//                 '& .MuiOutlinedInput-root': {
//                   height: '50px',
//                 }
//               }}
//               inputProps={{ 'aria-label': 'Net Promoter Score' }}
//             />

//           </Box>
//           <Box sx={{ flex: 1 }}>
//             <TextField
//               variant="outlined"
//               placeholder="Customer Satisfaction Index"
//               name="customerSatisfactionIndex"
//               value={formData.customerSatisfactionIndex}
//               onChange={handleInputChange}
//               sx={{
//                 width: '100%',
//                 backgroundColor: '#EDEDED',
//                 borderRadius: '4px',
//                 '& .MuiOutlinedInput-root': {
//                   height: '50px',
//                 }
//               }}
//               inputProps={{ 'aria-label': 'Customer Satisfaction Index' }}
//             />
           
//           </Box>
//         </Box>
        
//         {/* Data Privacy and Security Incidents */}
//         <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginTop: '20px' }}>
//           Data Privacy and Security Incidents
//         </Typography>
//         <Box display="flex" flexDirection="column" gap={1} mb={2} sx={{ width: '100%' }}>
//           {["highBreaches", "mediumBreaches", "lowBreaches"].map((breachType, index) => (
//             <Box display="flex" alignItems="center" gap={2} key={index}>
//               <TextField
//                 variant="outlined"
//                 value={breachType.replace("Breaches", "")}
//                 sx={{
//                   width: '100%', 
//                   backgroundColor: '#EDEDED',
//                   borderRadius: '4px',
//                   '& .MuiOutlinedInput-root': {
//                     height: '50px',
//                   }
//                 }}
//                 InputProps={{
//                   readOnly: true,
//                 }}
//               />
//               <TextField
//                 variant="outlined"
//                 placeholder={`Number of Breaches (${breachType.replace("Breaches", "")})`}
//                 name={breachType}
//                 value={formData[breachType]}
//                 onChange={handleInputChange}
//                 sx={{ 
//                   width: '100%',
//                   backgroundColor: '#EDEDED',
//                   borderRadius: '4px',
//                   '& .MuiOutlinedInput-root': {
//                     height: '50px',
//                   }
//                 }}
//                 inputProps={{ 'aria-label': `Number of Breaches (${breachType.replace("Breaches", "")})` }}
//               />
//             </Box>
//           ))}
//           <Box display="flex" justifyContent="flex-end">
//             <TextField
//               variant="outlined"
//               placeholder="Total Breaches"
//               name="totalBreaches"
//               value={formData.totalBreaches}
//               onChange={handleInputChange}
//               sx={{ 
//                 width: '48.8%',
//                 backgroundColor: '#EDEDED',
//                 borderRadius: '4px',
//                 '& .MuiOutlinedInput-root': {
//                   height: '50px',
//                 }
//               }}
//               inputProps={{ 'aria-label': 'Total Breaches' }}
//             />
//           </Box>
//         </Box>

//         {/* Product Quality and Safety */}
//         <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginTop: '20px' }}>
//           Product Quality and Safety
//         </Typography>
//         <Box display="flex" gap={2} mb={2} sx={{ width: '100%' }}>
//           <TextField
//             variant="outlined"
//             placeholder="What was the product"
//             name="product"
//             value={formData.product}
//             onChange={handleInputChange}
//             multiline
//             rows={3}
//             sx={{ 
//               flex: 1,
//               backgroundColor: '#EDEDED',
//               borderRadius: '4px',
//               '& .MuiOutlinedInput-root': {
//                 height: '92px',
//               }
//             }}
//           />
//           <TextField
//             variant="outlined"
//             placeholder="Number of products recalled"
//             name="numberOfProductsRecalled"
//             value={formData.numberOfProductsRecalled}
//             onChange={handleInputChange}
//             multiline
//             rows={3}
//             sx={{ 
//               flex: 1,
//               backgroundColor: '#EDEDED',
//               borderRadius: '4px',
//               '& .MuiOutlinedInput-root': {
//                 height: '92px',
//               }
//             }}
//           />
//         </Box>

//         {/* Save and Back Buttons */}
//         {/* <Box display="flex" justifyContent="space-between" sx={{ marginTop: '70px' }}>
//           <Button
//             variant="outlined"
//             startIcon={<ArrowBackIcon />}
//             onClick={Back}
//             sx={{
//               color: '#204769',
//               borderColor: '#204769',
//               '&:hover': {
//                 backgroundColor: 'rgba(25, 118, 210, 0.04)',
//                 color: '#115293',
//                 borderColor: '#115293',
//               },
//             }}
//           >
//             Back
//           </Button>
//           <Button
//             variant="contained"
//             endIcon={<EastIcon />}
//             onClick={Save}
//             sx={{
//               backgroundColor: '#1C486B',
//               '&:hover': {
//                 backgroundColor: '#163B57',
//               },
//             }}
//           >
//             Save and Continue
//           </Button>
//         </Box> */}
//         <ActionButtons Back={Back} Move={Save} />
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default CustomerRelation;










import React, { useState, useEffect,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../sidebar';
import { Box, Typography, TextField, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import axios from 'axios';
import { companyId } from "../../../utils/companyId";
import { api } from "../../../utils/api";
import { textError, numberError, percentageError, twoFieldError } from "../../../utils/validationMessage";
import {ProgressContext} from "../ContextAPI";

const CustomerRelation = () => {
  const { setUpdate } = useContext(ProgressContext);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    netPromoterScore: '',
    customerSatisfactionIndex: '',
    highBreaches: '',
    mediumBreaches: '',
    lowBreaches: '',
    totalBreaches: '',
    product: '',
    numberOfProductsRecalled: ''
  });
  const [isExist, setIsExist] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setUpdate("SOCIAL");
    const fetchData = async () => {
      if (companyId) {
        try {
          const response = await axios.get(`${api}/social/customer_relations/${companyId}`);
          setFormData({
            netPromoterScore: response.data.customerSatisfactionScore.netPromoterScore || '',
            customerSatisfactionIndex: response.data.customerSatisfactionScore.customerSatisfactionIndex || '',
            highBreaches: response.data.dataPrivacyAndSecurityIncidents.high.numberOfBreaches ?? '',
            mediumBreaches: response.data.dataPrivacyAndSecurityIncidents.medium.numberOfBreaches ?? '',
            lowBreaches: response.data.dataPrivacyAndSecurityIncidents.low.numberOfBreaches ?? '',
            totalBreaches: response.data.dataPrivacyAndSecurityIncidents.totalBreaches || '',
            product: response.data.productQualityAndSafety.product || '',
            numberOfProductsRecalled: response.data.productQualityAndSafety.numberOfProductsRecalled ?? ''
            
          });
          setIsExist(true);
        } catch (error) {
          console.error("There was an error fetching the customer relations data!", error);
        }
      }
    };

    fetchData();
  }, [companyId]);

  

  // Calculate total breaches
  useEffect(() => {
    const high = parseInt(formData.highBreaches, 10) || 0;
    const medium = parseInt(formData.mediumBreaches, 10) || 0;
    const low = parseInt(formData.lowBreaches, 10) || 0;
  
    const total = high + medium + low;
  
    setFormData((prevData) => ({
      ...prevData,
      totalBreaches: total
    }));
  }, [formData.highBreaches, formData.mediumBreaches, formData.lowBreaches]);
  

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Required field validation
    if (!formData.netPromoterScore) newErrors.netPromoterScore = textError;
    if (!formData.customerSatisfactionIndex) newErrors.customerSatisfactionIndex = textError;
    if (formData.highBreaches === ''  || formData.highBreaches === null) newErrors.highBreaches = textError;
    if (formData.mediumBreaches === ''  || formData.mediumBreaches === null) newErrors.mediumBreaches = textError;
    if (formData.lowBreaches === ''  || formData.lowBreaches === null) newErrors.lowBreaches = textError;
    if (!formData.product) newErrors.product = textError;
    // if (!formData.numberOfProductsRecalled) 
    if (formData.numberOfProductsRecalled === ''  || formData.numberOfProductsRecalled === null) newErrors.numberOfProductsRecalled = textError;

    // Numeric field validation
    if (isNaN(formData.highBreaches) || formData.highBreaches < 0) newErrors.highBreaches = numberError;
    if (isNaN(formData.mediumBreaches) || formData.mediumBreaches < 0) newErrors.mediumBreaches = numberError;
    if (isNaN(formData.lowBreaches) || formData.lowBreaches < 0) newErrors.lowBreaches = numberError;
    if (isNaN(formData.numberOfProductsRecalled) || formData.numberOfProductsRecalled < 0) newErrors.numberOfProductsRecalled = numberError;

    setErrors(newErrors);
    
    // If no errors, return true
    return Object.keys(newErrors).length === 0;
  };



  const Save = async () => {
    if (!validateForm()) return; 
    const postData = {
      customerSatisfactionScore: {
        netPromoterScore: formData.netPromoterScore,
        customerSatisfactionIndex: formData.customerSatisfactionIndex
      },
      dataPrivacyAndSecurityIncidents: {
        high: {
          numberOfBreaches: parseInt(formData.highBreaches, 10)
        },
        medium: {
          numberOfBreaches: parseInt(formData.mediumBreaches, 10)
        },
        low: {
          numberOfBreaches: parseInt(formData.lowBreaches, 10)
        },
        totalBreaches: parseInt(formData.totalBreaches, 10)
      },
      productQualityAndSafety: {
        product: formData.product,
        numberOfProductsRecalled: parseInt(formData.numberOfProductsRecalled, 10)
      },
      companyId: companyId // Assuming companyId is already set correctly
    };
  
    try {
      if (isExist) {
        // Perform PUT request to update existing record
        await axios.put(`${api}/social/customer_relations/${companyId}`, postData);

      } else {
        // Perform POST request to create a new record
        await axios.post(`${api}/social/customer_relations`, postData);
      }
      navigate("/social/supply-chain");
    } catch (error) {
      console.error("There was an error saving the customer relations data!", error);
    }
  };

  const Back = () => {
    navigate("/social/human-right");
  };

  return (
    <Box sx={{
      display: "flex",
      backgroundColor: "#EDEDED",
      height: "100vh",
      overflow: "hidden" // Ensure no content gets hidden by scrollbars
    }}>
     <Sidebar title="Customer Relations" />
      <Box sx={{
        p: 2,
        mt: 3,
        width: "100%",
        height: "100vh", // Full viewport height
        overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start", // Adjust to start at the top of the box
      }}>
        <Typography variant="h5" sx={{
          fontWeight: "bold",
          zIndex: 1,
          padding: "10px",
          borderRadius: "4px",
          alignSelf: "flex-start",
          ml: "5rem"
        }}>
        SOCIAL: CUSTOMER RELATIONS
        </Typography>

        {/* Customer Satisfaction Score */}
        <Box sx={{width: '80%'}}>
        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '2px', marginTop: '20px' }}>
          Customer Satisfaction Score
        </Typography>
        <Box display="flex" gap={2} mb={2} sx={{ width: '100%' }}>
          <Box sx={{ flex: 1 }}>
            <TextField
              variant="outlined"
              placeholder="Net Promoter Score"
              name="netPromoterScore"
              value={formData.netPromoterScore}
              onChange={handleInputChange}
              error={!!errors.netPromoterScore}
              helperText={
                <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                  {errors.netPromoterScore}
                </Typography>
              }
              sx={{
                width: '100%',
                backgroundColor: '#EDEDED',
                borderRadius: '4px',
                '& .MuiOutlinedInput-root': {
                  height: '50px',
                }
              }}
              inputProps={{ 'aria-label': 'Net Promoter Score' }}
            />

          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField
              variant="outlined"
              placeholder="Customer Satisfaction Index"
              name="customerSatisfactionIndex"
              value={formData.customerSatisfactionIndex}
              onChange={handleInputChange}
              error={!!errors.customerSatisfactionIndex}
              helperText={
                <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                  {errors.customerSatisfactionIndex}
                </Typography>
              }
              sx={{
                width: '100%',
                backgroundColor: '#EDEDED',
                borderRadius: '4px',
                '& .MuiOutlinedInput-root': {
                  height: '50px',
                }
              }}
              inputProps={{ 'aria-label': 'Customer Satisfaction Index' }}
            />
           
          </Box>
        </Box>
        
        
        {/* Data Privacy and Security Incidents */}
        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginTop: '20px' }}>
          Product Quality and Safety
        </Typography>
        <Box display="flex" flexDirection="column" gap={1} mb={2} sx={{ width: '100%' }}>
          {["highBreaches", "mediumBreaches", "lowBreaches"].map((breachType, index) => (
            <Box display="flex" alignItems="center" gap={2} key={index}>
              {/* Wrapper for the label with margin */}
              <Box sx={{ width: '100%' }}>
                <Typography 
                  sx={{ 
                    // fontWeight: 'bold', 
                    border: '1px solid #000', 
                    padding: '14px',          
                    borderRadius: '4px',      
                    height: '49px',
                  }}
                >
                  {breachType.replace("Breaches", "").charAt(0).toUpperCase() + breachType.replace("Breaches", "").slice(1)}
                </Typography>
              </Box>
              <TextField
                variant="outlined"
                placeholder={`Number of Breaches (${breachType.replace("Breaches", "").charAt(0).toUpperCase() + breachType.replace("Breaches", "").slice(1)})`}
                name={breachType}
                value={formData[breachType]}
                onChange={handleInputChange}
                error={!!errors[breachType]}
                // helperText={errors[breachType]}
                helperText={
                  <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                    {errors[breachType]}
                  </Typography>
                }
                sx={{ 
                  width: '100%', /* No changes to input field size */
                  backgroundColor: '#EDEDED',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    height: '50px',
                  }
                }}
                inputProps={{ 'aria-label': `Number of Breaches (${breachType.replace("Breaches", "").charAt(0).toUpperCase() + breachType.replace("Breaches", "").slice(1)})` }}
              />
            </Box>
          ))}
          <Box display="flex" justifyContent="flex-end">
            <TextField
              variant="outlined"
              placeholder="Total Breaches"
              name="totalBreaches"
              value={formData.totalBreaches}
              onChange={handleInputChange}
              sx={{ 
              width: '48.8%',
                backgroundColor: '#EDEDED',
                borderRadius: '4px',
                '& .MuiOutlinedInput-root': {
                  height: '50px',
                }
              }}
              inputProps={{ 'aria-label': 'Total Breaches' }}
            />
          </Box>
        </Box>


        {/* Product Quality and Safety */}
        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginTop: '20px' }}>
          Product Quality and Safety
        </Typography>
        <Box display="flex" gap={2} mb={2} sx={{ width: '100%' }}>
          <TextField
            variant="outlined"
            placeholder="What was the product"
            name="product"
            value={formData.product}
            onChange={handleInputChange}
            error={!!errors.product}
            // helperText={errors.product}
            helperText={
              <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                {errors.product}
              </Typography>
            }
            multiline
            rows={3}
            sx={{ 
              flex: 1,
              backgroundColor: '#EDEDED',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                height: '92px',
              }
            }}
          />
          <TextField
            variant="outlined"
            placeholder="Number of products recalled"
            name="numberOfProductsRecalled"
            value={formData.numberOfProductsRecalled}
            onChange={handleInputChange}
            error={!!errors.numberOfProductsRecalled}
            helperText={
              <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                {errors.numberOfProductsRecalled}
              </Typography>
            }
            multiline
            rows={3}
            sx={{ 
              flex: 1,
              backgroundColor: '#EDEDED',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                height: '92px',
              }
            }}
          />
        </Box>
        <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerRelation;
