import React, { useEffect, useState } from "react";
import ViewTable from "../../../Components/Common/ViewTable";
import { get } from "../../../utils/api";
import Cards from "../../../Components/Common/Cards";
import FilterModal from "../../../Components/Common/FilterModal";
import Form from "./Form";
import View from "./View";
import ActionButtons from "../../../Components/Common/ActionButtons";

const BusinessTravel = () => {
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [selectedValue, setSelectedValue] = useState(null);

  const [filters, setFilters] = useState({
    origin: [],
    destination: [],
    travelMode: [],
    accommodation: [],
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);

  const headers = [
    { key: "dateOfTravel", label: "Date of Travel", sortable: true },
    { key: "employeeName", label: "Employee Name", sortable: true },
    { key: "origin", label: "Origin Location", sortable: true },
    {
      key: "destination",
      label: "Destination Location",
      sortable: true,
    },
    {
      key: "travelMode",
      label: "Mode of Travel",
      sortable: true,
    },
  ];

  let bannerData = [
    {
      title: "Total number of Business Trips",
      description: "100 Trips",
    },
    {
      title: "Top Travel Destination",
      description: ["Paris", "London"],
    },
    {
      title: "Popular Mode of Travel",
      description: "Road",
    },
  ];

  const filterData = [
    { title: "Date of Travel", type: "date", key: "dateOfTravel", value: [] },
    {
      title: "Travel Location",
      type: "option",
      key: "travelLocations",
      value: filters?.origin,
    },
    {
      title: "Origin Location",
      type: "option",
      key: "origin",
      value: filters?.origin,
    },
    {
      title: "Destination Location",
      type: "option",
      key: "destination",
      value: filters?.destination,
    },
    {
      title: "Travel Duration",
      type: "option",
      key: "numberOfNights",
      value: filters?.destination,
    },
    {
      title: "Travel Mode",
      type: "option",
      key: "travelMode",
      value: filters?.travelMode,
    },
    {
      title: "Accomodation",
      type: "option",
      key: "accommodation",
      value: filters?.accommodation,
    },
  ];

  const fetchData = async () => {
    let filter = { ...filterObj };
    if (filter.load) {
      filter.minLoad = parseInt(filterObj.load.min);
      filter.maxLoad = parseInt(filterObj.load.max);
    }
    const queryParams = new URLSearchParams({
      ...filter,
      page: currentPage,
      limit: 10,
      sortField: sortConfig.key,
      sortOrder: sortConfig.direction,
    }).toString();
    await get(`/scope3/businessTravelEmissions/get?${queryParams}`)
      .then((res) => {
        console.log(res?.data);
        let arr = res?.data?.data?.map((item) => {
          return {
            dateOfTravel: new Date(item.dateOfTravel).toLocaleDateString(
              "en-GB",
              {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }
            ),
            employeeName: item?.employeeName,
            origin: item?.origin,
            destination: item?.destination,
            travelMode: item?.travelMode,
            remark: item?.remark,
            action: "View",
            id: item?._id,
            data: item,
          };
        });
        setTableData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [sortConfig, filterObj]);

  const fetchFilters = async () => {
    try {
      const res = await get(`/scope3/businessTravelEmissions/filter`);
      const { origin, destination, travelMode, accommodation } =
        res?.data?.data || {};
      setFilters({
        origin: origin || [],
        destination: destination || [],
        travelMode: travelMode || [],
        accommodation: accommodation || [],
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      console.log("data", dataForEdit);
      dataForEdit.createdAt = dataForEdit?.data?.createdAt.split("T")[0];
      setEditData(dataForEdit);
    }
  };

  const openViewModal = (data) => {
    console.log("data12", data);
    data.createdAt = data?.data?.createdAt.split("T")[0];
    setEditData(data);
    setViewModal(true);
  };

  const handleSort = (key, direction) => {
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (newFilterObj) => {
    setFilterObj(newFilterObj);
    console.log("Applied Filters:", newFilterObj);
  };

  console.log("editdata", editData);

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleSubmit = (data) => {
    console.log("Form submitted:", data);
    setIsModalOpen(false);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <>
      <Cards
        data={bannerData}
        title={"Business Travel Emission"}
        onFilterChange={handleSelectChange}
      />
      <ActionButtons
        onFilterClick={() => setIsFilterModalOpen(true)}
        onAddClick={() => openModal("add")}
        filterObj={filterObj}
      />

      <ViewTable
        headers={headers}
        data={tableData ?? []}
        onSort={handleSort}
        sortConfig={sortConfig}
        page={currentPage}
        count={pageSize}
        onPageChange={handlePageChange}
        onView={(row) => {
          openViewModal(row);
        }}
        onEdit={(row) => openModal("edit", row)}
        onDelete={() => {
          console.log("first");
        }}
      />

      <Form
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        header={editModal ? "Edit Data" : "Add Data"}
        initialData={editData}
        isEditing={editModal}
      />

      <View
        isOpen={viewModal}
        onClose={() => setViewModal(false)}
        header={"View Data"}
        initialData={editData}
        handleEdit={(row) => openModal("edit", row)}
      />

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        filters={filterData}
        onFilterChange={handleFilterChange}
        title="Filter"
      />
    </>
  );
};

export default BusinessTravel;
