import { Menu, MenuItem } from '@mui/material'
import React from 'react'
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const MenuActions = ({handleView,handleEdit,handleDelete,anchorEl,handleMenuClose}) => {
  return (
    <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>
        
        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
  )
}

export default MenuActions