


import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Toolbar,
  Typography,
  Box,
  PaginationItem,
  Grid,
} from "@mui/material";
import axios from "axios";
import { api } from "../../../../utils/api";
import { styled } from "@mui/material/styles";
// import VehicleFilter from "../../Roles and Permissions/Filters/VehicleFilter";
import PaginationBox from "../../../../Components/Masters/Pagination";
import DeleteDialog from "../../../../Components/Masters/DeleteDialog";
import ColumnHeadings from "../../../../Components/Masters/ColumnHeadings";
import MenuActions from "../../../../Components/Masters/MenuActions";
import TableContent from "../../../../Components/Masters/TableContent";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import MessagePopup from "../../../../Components/Masters/MessagePopup";
import TableHeading from "../../../../Components/Masters/TableHeading";
import MenuIcon from "../../../../Components/Masters/MenuIcon";
import { companyId } from "../../../../utils/companyId";
 import AddConsumptionEquipment from "./AddConsumptionEquipment";
 import ViewConsumptionEquipment from "./ViewConsumptionEquipment";
import DateRangePickerComponent from "../../../Masters/Roles and Permissions/Filters/Date";
import MultiSelect from "../../../Masters/Roles and Permissions/Filters/MultiSelect";
import Range from "../../../Masters/Roles and Permissions/Filters/Range";
import Cards from "../../../../Components/Masters/Cards";
import SelectCard from "../../../../Components/Masters/SelectCard";
import NoDataFound from "../../../../Components/Masters/NoDataFound";
import { Padding } from "@mui/icons-material";

const ConsumptionByEquipment = () => {
  const [selectedPeriod, setSelectedPeriod] = React.useState("EquipmentName");
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const months = [
    "EquipmentName",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addData, setAddData] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    "Equipment": false,
    "Hours in Use": false,
  });
  const columnWidth=  "12% 19% 19% 19% 21% 10%";
  const [viewData, setViewData] = useState(false);
  const [element, setElement] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [errors, setErrors] = useState({
    Date: false,
    "Equipment": false,
    "Hours in Use": false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    equipment: [],
    vendor: [],
  });
  const [hoursRange, setHoursRange] = useState({ min: "", max: "" });
  

  const filters = [
    "Date",
    "Equipment",
    "Hours in Use",
  ];

  const inputData = [
    {
      fieldName: "equipment",
      title: "Equipment",
      placeholder: "Select equipment",
      type: "select",
      url: `${api}/master/Heat/getEquipment`,
      field: "equipment",
      required: true,
    },
    {
      fieldName: "vendor",
      title: "Vendor Name",
      placeholder: "Select vendor name",
      type: "select",
      url: `${api}/master/Heat/getVendor`,
      field: "VendorLegalFullName",
      required: true,
    },
    {
      fieldName: "brand",
      title: "Brand",
      placeholder: "Autofilled",
      type: "text",
    },
    {
      fieldName: "model",
      title: "Model",
      placeholder: "Autofilled",
      type: "text",
    },
    {
      fieldName: "usedHours",
      title: "Hours in use",
      placeholder: " Enter Hours",
      type: "number",
    },

    {
      fieldName: "previousMeterReading",
      title: "Previous Meter Reading",
      placeholder: " Enter previous Meter Reading",
      type: "number",
    },
    {
      fieldName: "currentMeterReading",
      title: "Current Meter Reading",
      placeholder: " Enter Current Meter Reading",
      type: "number",
    },

    {
      fieldName: "unitOfConsumption",
      title: "Unit Of Consumption",
      placeholder: " Enter unit",
      type: "text",
    },

    {
      fieldName: "reasonForDeviation",
      title: "Reason For Deviation",
      placeholder: "Reason For Deviation",
      type: "text",
    },
    
    {
      fieldName: "remarks",
      title: "Remark",
      placeholder: "Additional remarks",
      type: "text",
    },
    {
      fieldName: "documents",
      title: "Upload Documents",
      type: "file",
    },
  ];

  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(
        `${api}/scope2/heatEquipment/getHeatEquipement`
      );
      const data = response.data.data;
      console.log(data);

      const uniqueIndustrialProcess = [
        ...new Set(
          data
            .map((el) => el.equipment?.equipment)
            .filter(Boolean) // Filter out undefined values
        ),
      ];

      console.log(uniqueIndustrialProcess);

      const uniqueEquipmentType = [
        ...new Set(
          data.map((el) => el.vendor?.VendorLegalFullName).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniqueIndustrialEquipment = [
        ...new Set(
          data.map((el) => el.industrialEquipment?.name).filter(Boolean) // Filter out undefined values
        ),
      ];

      return {
        equipment: uniqueIndustrialProcess,
        vendor: uniqueEquipmentType,
        industrialEquipment: uniqueIndustrialEquipment,
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  // fetching Vehicle data
  const fetchData = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    console.log(filters);
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
        company: companyId,
        createdBy: companyId,
      }).toString();
      const response = await axios.get(
        `${api}/scope2/heatEquipment/getHeatEquipement?${queryParams}`
      );

      setData(response.data.data); // Update the user list
      console.log(response.data.data);
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortConfig]);

  const handleMenuClick = (event, element) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(element);
    setElement(element);
    console.log(element);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedData(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddData(true);
    setViewData(false);
  };

  const handleView = () => {
    handleMenuClose();
    setViewData(true);
    setAddData(false);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = async () => {
    // console.log("indexhandleDeleteAggree", vehicle, vehicle._id);
    try {
      console.log(element._id);
      const res = await axios.put(
        `${api}/scope2/heatEquipment/deleteHeatEquipement?Id=${element._id}`
      );
      setDeleteDialog(false);
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
    } catch (err) {
      console.log(err);
      setsnackOpen({
        open: true,
        message: err.data?.response.message ?? "network Error",
        severity: "error",
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filtersToApply = {
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        acc[key] = selectedOptions.join(","); // Change to an array if needed
      }
      return acc;
    }, {}),
    minHours: hoursRange.min || "",
    maxHours: hoursRange.max || "",
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
      "Equipment": false,
      "Hours in Use": false,
    };

    console.log(selectedFilter, "hi");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select start date & end date";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }

    if (
      selectedFilter === "Equipment" &&
      selectedFields.equipment.length === 0
    ) {
      newErrors["Equipment"] =
        "Please select atleast one Equipment";
      isValid = false;
    }

    
    if (selectedFilter === "Hours in Use") {
      const min = parseFloat(hoursRange.min);
      const max = parseFloat(hoursRange.max);

      if (!min || !max) {
        newErrors["Hours in Use"] = "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Hours in Use"] = "Min hours must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Hours in Use"] = "Max hours must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Hours in Use"] =
          "Min hours cannot be greater than max consumption";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    console.log(selectedFields);
    if (!validateFilters()) return;
    setPage(1);
    fetchData(filtersToApply);
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
      equipment: [],
      vendor: [],
    });
    setHoursRange({ min: "", max: "" });

    // Call fetchData without any filters
    await fetchData({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate;
    const isEquipmentTypeApplied = selectedFields.vendor?.length > 0;
    const isEquipmentApplied =
      selectedFields.equipment?.length > 0;

    const isHoursRangeApplied = hoursRange.min && hoursRange.max;

    setIsFiltersApplied(
      isDateApplied ||
        isEquipmentTypeApplied ||
        isHoursRangeApplied ||
        isEquipmentApplied
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      "Equipment": isEquipmentApplied,
      "Hours in Use": isHoursRangeApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    // setErrors({ Date: false, Role: false });
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

   const columnHeadings = [
    { title: "Date", fieldName: "createdAt" },
    { fieldName: "equipment", title: "Equipment" },
    { fieldName: "brand", title: "Brand" },
    { fieldName: "model", title: "Model" },
    { fieldName: "usedHours", title: "Hours in Use" },
  ];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/stationaryCombustion/getAllCoal`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
      case "Equipment":
        return (
          <MultiSelect
            title="Equipment"
            selectedField={selectedFields.equipment}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Equipment"]}
            setErrors={setErrors}
            field="equipment"
            data={uniqueValues.equipment}
          />
        );
      
      case "Hours in Use":
        return (
          <Range
            title="Hours in Use"
            selectedRange={hoursRange}
            setSelectedRange={setHoursRange}
            errors={errors["Hours in Use"]}
            setErrors={setErrors}
            placeholderMax="Max(Hours)"
            placeholderMin="Min(Hours)"
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/stationaryCombustion/getAllCoal`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <MessagePopup {...{ snackOpen, setsnackOpen }} />{" "}
      {/*Message popup for success or failure */}
      {/* View log, Edit, delete menus */}
      <AddConsumptionEquipment
        id={element ? element._id : ""}
        addData={addData}
        setAddData={setAddData}
        data={element}
        title="Heat"
        addUrl={`${api}/scope2/heatEquipment/addHeatEquipement`}
        updateUrl={`${api}/scope2/heatEquipment/updateHeatEquipement`}
        fetchData={fetchData}
        setsnackOpen={setsnackOpen}
        inputData={inputData}
      />
      <ViewConsumptionEquipment
        id={element ? element._id : ""}
        data={element}
        title={"Equipment Data"}
        viewData={viewData}
        setViewData={setViewData}
        inputData={inputData}
        handleEdit={handleEdit}
      />
      <Grid item xs={6} sx={{ margin: "0% 5% -3% 23%", width: "100%" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Heat
        </Typography>
        <Box sx={{ display: "flex", width: "83%", mt: 2, gap: 2 }}>
          {/* Card 1: Total Natural Gas Consumed */}
          <Cards title="Total Heat Consumed" content="10,000 Kwh" />
          <Cards
            title="Top Heat Consuming Equipment"
            content="Equipment Name"
          />
          <SelectCard
           sx={{Padding:"10px"}}
            title="Avg. Usage Hours Per Equipment"
            content="8"
            contentMarginTop="12%" 
            handleChange={handlePeriodChange}
            value={selectedPeriod}
            list={months}
          />
          {/* Card 3: Consumption Period */}
        </Box>
      </Grid>
      {deleteDialog && (
        <DeleteDialog
          {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
        />
      )}
      {/* Filter Drawer */}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setElement}
          setAddData={setAddData}
          {...{
            // heading: "Coal",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "3% 5% 0% 23%" }}
      >
        <Box>
          <TableHeading heading={"Consumption by Equipment"} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: columnWidth,
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            {columnHeadings.map((el) => (
              <ColumnHeadings
                key={el}
                {...{
                  title: el.title,
                  key: el.title,
                  handleSort,
                  field: el.fieldName,
                  sortConfig,
                }}
              />
            ))}
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {data.length === 0 ? (
            <NoDataFound />
          ) : (
            data.map((item) => (
              <Box
                key={item._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns:columnWidth,
                  borderBottom: "1px solid #ddd",
                }}
              >
                {columnHeadings.map((el) => (
                  <TableContent
                    key={el.fieldName}
                    {...{
                      fieldData:
                        el.fieldName === "equipment"
                      ? item.equipment?.equipment || "NA"
                      :el.fieldName === "brand"
                      ? item.equipment?.brand || "NA"
                      : el.fieldName === "model"
                      ? item.equipment?.model || "NA"
                      : el.fieldName === "quantityConsumed"
                      ? item[el.fieldName].value + " " + item[el.fieldName].unit
                      : item[el.fieldName],
                      dateField: el.fieldName === "createdAt",
                    }}
                  />
                ))}
                {/* Menu Action Icon */}
                <MenuIcon {...{ handleMenuClick, field: item }} />
              </Box>
            ))
          )}
        </Box>
      </TableContainer>
      {/* Pagination */}
      {totalPages>1 && (
        <PaginationBox
          {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
        />
      )}
      <MenuActions
        {...{
          id: element?._id,
          handleView,
          handleEdit,
          handleDelete,
          anchorEl,
          handleMenuClose,
        }}
      />
      {/*Add,Edit,Delete Icons & function calls */}
    </Box>
  );
};

export default ConsumptionByEquipment;
