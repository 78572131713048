

import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import axios from "axios";
// import "../Scrollbar.css";

const MachineTypeFilter = ({ selectedTypes = [], setSelectedTypes, url, errors }) => {
  const [types, setTypes] = useState([]);

  // Fetch all machine type from the backend
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(url);  // Fetch machine data from the given URL
        console.log("Response from backend:", response);
        
        // Extract unique machine type from the response data
        const uniqueCategories = response.data.data
          ? [...new Set(response.data.data.map((machinery) => machinery.machineType?.name))]
          : [];
          
        console.log("Unique type:", uniqueCategories);
        setTypes(uniqueCategories);
      } catch (error) {
        console.error("Error fetching type:", error);
      }
    };

    fetchCategories();
  }, [url]);  // Reload type when the URL changes

  const handleCategoryChange = (event) => {
    const selectedTypes = event.target.value;
    if (event.target.checked) {
      // Add selected category to the state
      setSelectedTypes((prevSelected) => [...prevSelected, selectedTypes]);
    } else {
      // Remove the category from the state
      setSelectedTypes((prevSelected) =>
        prevSelected.filter((category) => category !== selectedTypes)
      );
    }
  };

  return (
    <Box sx={{ m: "5%", mt: 0 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", marginBottom: "30px" }}
      >
        Machinery Type
      </Typography>
      <Box className="scrollable-container">
        {types.length > 0 ? (
          types.map((type, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={type}
                  checked={selectedTypes?.includes(type)}
                  onChange={handleCategoryChange}
                />
              }
              label={type || "Unknown Category"} // Fallback for missing category names
            />
          ))
        ) : (
          <Typography>Loading type...</Typography>
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default MachineTypeFilter;



