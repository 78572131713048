import React from "react";
import { Routes, Route } from "react-router-dom";
import User from "../../../Pages/Masters/Roles and Permissions/User";
import Facility from "../../../Pages/Masters/Facility Master/Facility";
import Machinery from "../../../Pages/Masters/Scope1/Machinery Master/Machinery";
import ListIndustrialProcess from "../../../Pages/Masters/Scope1/IndustrialMaster/IndustrialProcess/ListIndustrialProcess";
import Equipment from "../../../Pages/Masters/Scope2/EquipmentMaster";
import ListEquipment from "../../../Pages/Masters/Scope1/IndustrialEquipment/ListEquipment";
import VendorMaster from "../../../Pages/Masters/Scope2/VendorMaster/VendorMaster";
import Scope1Vehicle from "../../../Pages/Masters/Scope1/VehicalMaster/Scope1Vehicle";
import EquipmentList from "../../../Pages/Masters/Scope2/HeatMaster/EquipmentMaster/EquipmetList";
import HeatVendorMaster from "../../../Pages/Masters/Scope2/HeatMaster/VendorMaster/Vendor";
import WasteData from "../../../Pages/Masters/Scope3/WasteManagement/WasteData";
import ElectricityEquipment from "../../../Pages/Masters/Scope2/ElectricityMaster/EquipmentMaster/ElectricityList"
import TransportationLogisticVendor from "../../../Pages/Masters/Scope3/Transportation&Distribution/LogisticVendorMaster/LogisticsVendorMaster";
import ListDriver from "../../../Pages/Masters/Scope3/Transportation&Distribution/DriverMaster/ListDriver";
import PurchasedGoods from "../../../Pages/Masters/Scope3/PurchasedGoods/PurchasedGoods";
import ElectricityVendor from "../../../Pages/Masters/Scope2/ElectricityMaster/VendorMaster/VendorMaster";
import Vehicle from "../../../Pages/Masters/Scope3/Transportation&Distribution/Vehicle/Vehicle";
import Scope1LogisticsVendorMaster from "../../../Pages/Masters/Scope1/LogisticsVendor/Scope1LogisticsVendorMaster";

function MasterRoutes() {
  return (
    <Routes>
      {/* <Route path="/masters" element={<MasterFilterPage/>} /> */}
      <Route path="/setup/roles-permission" element={<User />} />
      {/* <Route path="/masters/roles-permission" element={<User/>} /> */}

      <Route path="/setup/facility-master" element={<Facility />} />
      {/* Scope1  */}
      <Route
        path="/setup/masters/scope1/industrial-process"
        element={<ListIndustrialProcess />}
      />
      <Route
        path="/setup/masters/scope1/industrial-equipment"
        element={<ListEquipment />}
      />
            <Route path="/setup/masters/scope1/logistics-vendor-master" element={<Scope1LogisticsVendorMaster/>} />

      <Route path="/setup/masters/scope1/vehicle-master" element={<Scope1Vehicle/>} />
      <Route
        path="/setup/masters/scope1/machinery-master"
        element={<Machinery />}
      />
      {/* Scope2 */}
      <Route
        path="/setup/masters/scope2/electricity-master/equipment-master"
        element={<ElectricityEquipment />}
      />
      <Route
        path="/setup/masters/scope2/electricity-master/vendor-master"
        element={<ElectricityVendor />}
      />
      <Route
        path="/setup/masters/scope2/heat-master/equipment-master"
        element={<EquipmentList/>}
      />
      <Route
        path="/setup/masters/scope2/heat-master/vendor-master"
       element={<HeatVendorMaster/>}
        // element={""}
      />
      <Route
        path="/setup/masters/scope2/steam-master/equipment-master"
        element={<Equipment />}
      />
      <Route
        path="/setup/masters/scope2/steam-master/vendor-master"
        element={<VendorMaster />}
      />
      {/* Scope3 */}
      <Route
        path="/setup/masters/scope3/transportation-distribution/logistics-vendor-master"
        element={<TransportationLogisticVendor/>}
      />
      <Route
        path="/setup/masters/scope3/transportation-distribution/vehicle-master"
        element={<Vehicle />}
      />
      <Route
        path="/setup/masters/scope3/transportation-distribution/driver-master"
        element={<ListDriver />}
      />
      <Route path="/setup/masters/scope3/waste-generated" element={<WasteData/>} />
      <Route path="/setup/masters/scope3/purchased-goods" element={<PurchasedGoods/>} />

    </Routes>
  );
}

export default MasterRoutes;
