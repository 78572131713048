import React, { useEffect, useState,useContext } from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './Risk.css';
import axios from 'axios';
import { api } from '../../../utils/api';
import { companyId } from "../../../utils/companyId";
import {unitError,listError,textError,percentageError} from "../../../utils/validationMessage";
import ActionButtons from '../InitialKickoff/MaterialTopics/ActionButtons';
import {ProgressContext} from "../ContextAPI";

const Stakeholder = () => {
  const { setUpdate } = useContext(ProgressContext);
  const navigate = useNavigate();
  const[isExist,setisExist]=useState(false);
  const [DeleteIndex,setIndexDelete]=useState(0);
  const [openDialog, setOpenDialog] = useState(false);
 const[formData,setFromData]=useState({
  companyId:companyId,
  stakeholderIdentificationEngagementProcesses:{
  radio:"yes",
  Scope:""
  },
  indentificationMitigationEsgRisk:{
    esg_risk:[""],
    mitigation:[""]
  }

 });
 const [fieldToRemove, setFieldToRemove] = React.useState({
  fieldName: "",
  index: null,
});
 const [errors, setErrors] = useState({});

 const validateForm = () => {
  const newErrors = {};
  
  const { 
    stakeholderIdentificationEngagementProcesses,
    indentificationMitigationEsgRisk
  } = formData;

  // Validate `stakeholderIdentificationEngagementProcesses`
  if (!stakeholderIdentificationEngagementProcesses.Scope && formData.stakeholderIdentificationEngagementProcesses.radio === "yes" || stakeholderIdentificationEngagementProcesses.Scope.trim() === "" && formData.stakeholderIdentificationEngagementProcesses.radio === "yes") {
    newErrors.stakeholderIdentificationEngagementProcessesScope = textError;
  }

  // Validate `indentificationMitigationEsgRisk`
  indentificationMitigationEsgRisk.esg_risk.forEach((risk, index) => {
    if (!risk || risk.trim() === "") {
      newErrors[`indentificationMitigationEsgRiskEsgRisk${index}`] = textError;
    }
  });

  indentificationMitigationEsgRisk.mitigation.forEach((mitigation, index) => {
    if (!mitigation || mitigation.trim() === "") {
      newErrors[`indentificationMitigationEsgRiskMitigation${index}`] = textError;
    }
  });

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

 const handleStakeholder=(e)=>{
const{name,value}=e.target;
setFromData({
  ...formData,
  stakeholderIdentificationEngagementProcesses:{
    ...formData.stakeholderIdentificationEngagementProcesses,
    [name]:value
  }
})
 }
 const handleIndentification=(e,index,fieldType)=>{
  const{name,value}=e.target;
//  setFromData({
//   ...formData,
//     indentificationMitigationEsgRisk:{
//    ...formData.indentificationMitigationEsgRisk,
//    [name]:value
//  }
// })
const updatedCommittees = { ...formData.indentificationMitigationEsgRisk };
updatedCommittees[fieldType][index] = value;
setFromData({
  ...formData,
  indentificationMitigationEsgRisk: updatedCommittees,
});
 }
 console.log(formData)
 const handleRemoveField = (index) => {
  setOpenDialog(true);
  // setFieldToRemove({ fieldName, index });
  setIndexDelete(index)
};
const confirmRemoveField = () => {

   console.log("fieldToRemove",DeleteIndex)
  const updatedCommittees = { ...formData.indentificationMitigationEsgRisk };
  if (updatedCommittees.esg_risk.length > DeleteIndex) {
  // Remove the element at the specific index from both esg_risk and mitigation arrays
  updatedCommittees.esg_risk.splice(DeleteIndex, 1);
  }
  if (updatedCommittees.mitigation.length > DeleteIndex) {
  updatedCommittees.mitigation.splice(DeleteIndex, 1);
  }
console.log(updatedCommittees,"updatedCommittees")
  setFromData({
    ...formData,
    indentificationMitigationEsgRisk: updatedCommittees,
  });
  setOpenDialog(false);
};

const cancelRemoveField = () => {
  setOpenDialog(false);
};
 useEffect(()=>{
  setUpdate("GOVERNANCE");
  const comapnyId = companyId
  axios.get(`${api}/governance/stakeholder-engagement/${comapnyId}`).then((res)=>{
console.log(res.data)
    setFromData({
  stakeholderIdentificationEngagementProcesses:{
  radio:res.data.stakeholderIdentificationEngagementProcesses.radio===true?"yes":"no",
  Scope:res.data.stakeholderIdentificationEngagementProcesses.Scope
  },
  indentificationMitigationEsgRisk:{
    esg_risk:res.data.indentificationMitigationEsgRisk.esg_risk,
    mitigation:res.data.indentificationMitigationEsgRisk.mitigation
  }

 })
 setisExist(true)
  }).catch(err=>console.log(err))
 },[])
 const AddTextfieldIndentification =(e)=>{
  const {name,value}=e.target
  const updatedCommittees = { ...formData.indentificationMitigationEsgRisk };
  updatedCommittees.esg_risk.push('');
  updatedCommittees.mitigation.push('');

  setFromData({
    ...formData,
    indentificationMitigationEsgRisk: updatedCommittees,
  });
 }
  const Save = () => {
    // const companyId = companyId
    if (!validateForm()) {
      return;
    }
    axios({method:isExist?'put':'post',
      url:`${api}/governance/stakeholder-engagement${isExist?`/${companyId}`:""}`,
      data:formData}).then((res)=>{
     console.log(res)
    //  alert(res.data.message)
     navigate('/governance/cybersecurity');
    }).catch((err)=>{
      console.log(err)
      alert(err.message)
    })
    // 
  };

  const Back = () => {
    navigate('/governance/risk-management');
  };

  return (
    <Box display="flex" sx={{backgroundColor: "#EDEDED",  display: "flex",
      backgroundColor: "#EDEDED",
      height: "100vh",
      overflow: "hidden"}}>
       <Dialog open={openDialog} onClose={cancelRemoveField}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this field?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelRemoveField} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRemoveField} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Sidebar title="Stakeholder Engagement" />
      <Box width="100%" sx={{
          p: 2,
          mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}>
        <Typography variant="h5" 
        sx={{
          fontWeight: "bold",
          mt: "2rem",
          zIndex: 1,
          padding: "10px",
          borderRadius: "4px",
          alignSelf: "flex-start",
          ml:"5rem"}}
        >
          GOVERNANCE: STAKEHOLDER ENGAGEMENT
        </Typography>

        <Box sx={{width:"90%",ml:"6rem"}}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading">
            Stakeholder Identification and Engagement Processes
          </Typography>
          <RadioGroup row
            name="radio" 
          value={formData.stakeholderIdentificationEngagementProcesses.radio}
          onChange={handleStakeholder}
          sx={{ mb: 2 }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>

     {formData.stakeholderIdentificationEngagementProcesses.radio==='yes'?
     <Box>
      <TextField
      variant="outlined"
      placeholder="Scope..."
      // className="brief"
      name='Scope'
      value={formData.stakeholderIdentificationEngagementProcesses.Scope}
      onChange={handleStakeholder}
      multiline
      rows={3}
      // sx={{ mb: 3, width: '90%' }}
      InputProps={{
        style: { borderColor:errors.stakeholderIdentificationEngagementProcessesScope? '#D34040' : undefined},
      }}
       sx={{marginRight:"5px",width:"88%"}}
      error={!!errors.stakeholderIdentificationEngagementProcessesScope} // Show error styling if there's an error
      InputLabelProps={{
        style: { color: errors.stakeholderIdentificationEngagementProcessesScope ? '#D34040' : undefined },
      }}
    />
    {errors.stakeholderIdentificationEngagementProcessesScope && (
      <Typography variant="body2" color="#D34040">
        {errors.stakeholderIdentificationEngagementProcessesScope}
      </Typography>
    )}
    </Box>
    :""}    

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading">
            Identification and Mitigation of ESG Risks
          </Typography>
         
      {formData.indentificationMitigationEsgRisk.esg_risk.map((item,i)=>{
        return  <Box
        key={i}
        sx={{ width: "101%", display: "flex", gap: 1 }}
      > 
         <Grid container spacing={2} sx={{ mb: 3 ,width:"90%"}}>
         <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Description of ESG Risk"
                // className="riskText"
                name='esg_risk'
                value={item}
               onChange={(e)=>handleIndentification(e,i,'esg_risk')}
                multiline
                rows={3}
                fullWidth
                InputProps={{
                  style: { borderColor:errors[`indentificationMitigationEsgRiskEsgRisk${i}`]? '#D34040' : undefined},
                }}
                 sx={{marginRight:"5px",width:"100%"}}
                error={!!errors[`indentificationMitigationEsgRiskEsgRisk${i}`]} // Show error styling if there's an error
                InputLabelProps={{
                  style: { color: errors[`indentificationMitigationEsgRiskEsgRisk${i}`] ? '#D34040' : undefined },
                }}
              />
                {errors[`indentificationMitigationEsgRiskEsgRisk${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`indentificationMitigationEsgRiskEsgRisk${i}`]}
            </Typography>
          )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex">
              <TextField
                variant="outlined"
                placeholder="Mitigation"
                // className="riskText"

                name='mitigation'
                value={formData.indentificationMitigationEsgRisk.mitigation[i]}
               onChange={(e)=>handleIndentification(e,i,'mitigation')}
                multiline
                rows={3}
                fullWidth
                InputProps={{
                  style: { borderColor:errors[`indentificationMitigationEsgRiskMitigation${i}`]? '#D34040' : undefined},
                }}
                 sx={{marginRight:"5px",width:"100%"}}
                error={!!errors[`indentificationMitigationEsgRiskMitigation${i}`]} // Show error styling if there's an error
                InputLabelProps={{
                  style: { color: errors[`indentificationMitigationEsgRiskMitigation${i}`] ? '#D34040' : undefined },
                }}
              />
                
                  </Box>
                {errors[`indentificationMitigationEsgRiskMitigation${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`indentificationMitigationEsgRiskMitigation${i}`]}
            </Typography>
          )}
            </Grid>
            </Grid>
            {i > 0 && (
                    <IconButton sx={{ mt: 0,width:"5%" }}>
                      <DeleteIcon
                        onClick={() =>
                          handleRemoveField (i)
                        }
                      />
                    </IconButton>
                  )}  
        </Box>
      })}     
            
            <Grid item xs={12} display="flex" sx={{width:"88%"}} justifyContent="flex-end">
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                sx={{ color: "#0a3f73",textTransform:"none" }}
                className="add"
                onClick={AddTextfieldIndentification}
              >
                Add
              </Button>
            </Grid>
          {/* </Grid> */}
          <ActionButtons Back={Back} Move={Save}/>
        </Box>

        {/* <Box display="flex" justifyContent="space-between" width="70%" sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}}
            onClick={Back}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{backgroundColor:"#0a3f73",textTransform:"none"}}
            onClick={Save}
            endIcon={<ArrowForwardIcon />}
          >
            Save and continue
          </Button>
        </Box> */}
       
      </Box>
    </Box>
  );
};

export default Stakeholder;
