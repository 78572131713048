import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Toolbar,
  Typography,
  Box,
  PaginationItem,
  Grid,
} from "@mui/material";
import axios from "axios";
import { api } from "../../../../utils/api";
import { styled } from "@mui/material/styles";
// import VehicleFilter from "../../Roles and Permissions/Filters/VehicleFilter";
import PaginationBox from "../../../../Components/Masters/Pagination";
import DeleteDialog from "../../../../Components/Masters/DeleteDialog";
import ColumnHeadings from "../../../../Components/Masters/ColumnHeadings";
import MenuActions from "../../../../Components/Masters/MenuActions";
import TableContent from "../../../../Components/Masters/TableContent";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import MessagePopup from "../../../../Components/Masters/MessagePopup";
import TableHeading from "../../../../Components/Masters/TableHeading";
import MenuIcon from "../../../../Components/Masters/MenuIcon";
import { companyId } from "../../../../utils/companyId";
 import AddOffRoad from "./AddOffRoad";
 import ViewOffRoad from "./ViewOffRoad";
import DateRangePickerComponent from "../../../Masters/Roles and Permissions/Filters/Date";
import MultiSelect from "../../../Masters/Roles and Permissions/Filters/MultiSelect";
import Range from "../../../Masters/Roles and Permissions/Filters/Range";
import Cards from "../../../../Components/Masters/Cards";
import SelectCard from "../../../../Components/Masters/SelectCard";

const OffRoad = () => {
  const [selectedPeriod, setSelectedPeriod] = React.useState("July");
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addData, setAddData] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    "Industrial Process": false,
    "Equipment Type": false,
    "Industrial Equipment": false,
    "Fuel Type":false,
    "Quantity Consumed": false,
    "Hours Used": false,
  });
  const [viewData, setViewData] = useState(false);
  const [element, setElement] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [errors, setErrors] = useState({
    Date: false,
    "Industrial Process": false,
    "Equipment Type": false,
    "Industrial Equipment": false,
    "Fule Type": false,
    "Quantity of Fuel Consumed": false,
    "Hours Used": false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    machineryType: [],
    fuelType: [],
    machineCategory: [],
  });
  const [quantityRange, setQuantityRange] = useState({ min: "", max: "" });
  const [hoursRange, setHoursRange] = useState({ min: "", max: "" });

  //   const[minRange,setMinRange]=useState({
  //     quantityConsumed:{min:"",max:""},
  //     hoursUsed:{min:"",max:""}
  //   })

  const filters = [
    "Date",
    "Machinery Category",
    "Machinery Type",
    "Fuel Type",
    "Quantity of Fuel Consumed",
    "Hours Used",
  ];

  const inputData = [
    // {
    //   fieldName: "industrialProcess",
    //   title: "Category of Machinery",
    //   placeholder: "Tap to Select",
    //   type: "select",
    //   url: `${api}/master/getAllIndustrialProcess`,
    //   field: "industryProcess",
    //   required: true,
    // },
    {
      fieldName: "machineryCategory",
      title: "Category of Machinery",
      placeholder: "Select equipment type",
      type: "select",
      options: ["Construction Machinery", "Industrial Machinery"],
      required: true,
    },
    {
      fieldName: "machineryType",
      title: "Machinery Type",
      placeholder: "Select equipment type",
      type: "machineType",
      url: `${api}/master/scope1/getAllMachinery`,
      required: true,
    },
    {
      fieldName: "machineryName",
      title: "Machinery Name",
      placeholder: "Select equipment type",
      type: "select",
      url: `${api}/master/scope1/getAllMachinery`,
      required: true,
    },
    {
      fieldName: "typeOfEntry",
      title: "Type of Entry",
      placeholder: "Type here",
      type: "select",
      options: ["Cumulative", "Individual"],
      required: true,
    },
    {
      fieldName: "uniqueIdOfEquipment",
      title: "Unique ID of Entry",
      placeholder: "Select industrial equipment",
      type: "number",
      url: `${api}/master/scope1/getAllMachinery`,
      // url: `${api}/master/getAllIndustrialEquipment`,
      condition: true,
    },
    {
      fieldName: "fuelType",
      title: "Fule Type",
      placeholder: "Auto filed",
      type: "text",
      required: true,
    },
    {
      fieldName: "quantityOfFuelConsumed",
      title: "Quantity of Fuel Consumed",
      placeholder: "Litre",
      type: "number",
      condition: true,
    },
    {
      fieldName: "hoursUsed",
      title: "Hours Used",
      placeholder: "Enter hours",
      type: "number",
      condition: true,
    },
    // {
    //   fieldName: "unit",
    //   title: "Unit of Quantity",
    //   placeholder: "Select unit",
    //   type: "select",
    //   options: [
    //     "NG-m3",
    //     "Coal-MT",
    //     "Petrol-litres",
    //     "Diesel-litres",
    //     "Fuel Oil-litres",
    //   ],
    //   required: true,
    // },
    // {
    //   fieldName: "calorificValue",
    //   title: "Calorific Value",
    //   placeholder: "10000",
    //   type: "number",
    //   required: true,
    // },
    // {
    //   fieldName: "calorificValue.unit",
    // //   title: "Calorific Value Unit",
    //   placeholder: "Select unit",
    //   type: "select",
    //   options: ["MJ/kg", "MJ/m3"],
    //   required: true,
    // },
    {
      fieldName: "remarks",
      title: "Remark",
      placeholder: "Additional remarks",
      type: "text",
    },
    {
      fieldName: "documents",
      title: "Upload Documents",
      type: "file",
    },
  ];

  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(
        `${api}/scope1/mobileCombustion/getAllOFRoadVehicles`
      );
      const data = response.data.data;
      console.log(data);

      const uniqueIndustrialProcess = [
        ...new Set(
          data
            .map((el) => el.machineryCategory)
            .filter(Boolean) // Filter out undefined values
        ),
      ];

      console.log(uniqueIndustrialProcess);

      const uniqueEquipmentType = [
        ...new Set(
          data.map((el) => el.machineryType?.name).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniqueIndustrialEquipment = [
        ...new Set(
          data.map((el) => el.fuelType).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniquequantityOfFuelConsumed = [
        ...new Set(
          data.map((el) => el.quantityOfFuelConsumed).filter(Boolean) // Filter out undefined values
        ),
      ];

      return {
        machineryCategory: uniqueIndustrialProcess,
        machineryType: uniqueEquipmentType,
        fuelType: uniqueIndustrialEquipment,
        quantityOfFuelConsumed:uniquequantityOfFuelConsumed,
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  // fetching Vehicle data
  const fetchData = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    console.log(filters);
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
        company: companyId,
        createdBy: companyId,
      }).toString();
      const response = await axios.get(
        `${api}/scope1/mobileCombustion/getAllOFRoadVehicles?${queryParams}`
      );

      setData(response.data.data); // Update the user list
      console.log(response.data.data);
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortConfig]);

  const handleMenuClick = (event, element) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(element);
    setElement(element);
    console.log(element);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedData(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddData(true);
    setViewData(false);
  };

  const handleView = () => {
    handleMenuClose();
    setViewData(true);
    setAddData(false);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = async () => {
    // console.log("indexhandleDeleteAggree", vehicle, vehicle._id);
    try {
      console.log(element._id);
      const res = await axios.put(
        `${api}/scope1/mobileCombustion/deleteOFRoadVehicles?Id=${element._id}`
      );
      setDeleteDialog(false);
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
    } catch (err) {
      console.log(err);
      setsnackOpen({
        open: true,
        message: err.data?.response.message ?? "network Error",
        severity: "error",
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filtersToApply = {
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        acc[key] = selectedOptions.join(","); // Change to an array if needed
      }
      return acc;
    }, {}),
    minQuantity: quantityRange.min || "",
    maxQuantity: quantityRange.max || "",
    minHours: hoursRange.min || "",
    maxHours: hoursRange.max || "",
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
      "Machinery Category": false,
      "Machinery Type": false,
      "Fuel Type": false,
      "Quantity of Fuel Consumed": false,
      "Hours Used": false,
    };

    console.log(selectedFilter, "hi");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select start date & end date";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }

    if (
      selectedFilter === "Machinery Category" &&
      (selectedFields.machineryCategory?.length || 0) === 0
    ) {
      newErrors["Machinery Category"] =
        "Please select atleast one Machinery Category";
      isValid = false;
    }

    if (
      selectedFilter === "Machinery Type" &&
      selectedFields.machineryType.length === 0
    ) {
      newErrors["Machinery Type"] =
        "Please select atleast one Machinery Type";
      isValid = false;
    }

    if (
      selectedFilter === "fuelType" &&
      selectedFields.equipmentType.length === 0
    ) {
      newErrors["Fuel Type"] = "Please select atleast one fuelType";
      isValid = false;
    }

    if (selectedFilter === "Quantity of Fuel Consumed") {
      const min = parseFloat(quantityRange.min);
      const max = parseFloat(quantityRange.max);

      if (!min && !max) {
        newErrors["Quantity of Fuel Consumed"] =
          "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Quantity of Fuel Consumed"] =
          "Min consumption must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Quantity of Fuel Consumed"] =
          "Max consumption must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Quantity of Fuel Consumed"] =
          "Min consumption cannot be greater than max consumption";
        isValid = false;
      }
    }
    if (selectedFilter === "Hours Used") {
      const min = parseFloat(hoursRange.min);
      const max = parseFloat(hoursRange.max);

      if (!min && !max) {
        newErrors["Hours Used"] = "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Hours Used"] = "Min hours must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Hours Used"] = "Max hours must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Hours Used"] =
          "Min hours cannot be greater than max consumption";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    console.log(selectedFields);
    if (!validateFilters()) return;
    setPage(1);
    fetchData(filtersToApply);
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
      fuelType: [],
      machineryType: [],
      machineryCategory: [],
    });
    setQuantityRange({ min: "", max: "" });
    setHoursRange({ min: "", max: "" });

    // Call fetchData without any filters
    await fetchData({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate;
    const isMachineCategoryApplied =
      selectedFields.machineCategory?.length > 0;
    const isEquipmentTypeApplied = selectedFields.equipmentType?.length > 0;
    const isMachineryCategory =
      selectedFields.machineryCategory?.length > 0;
      const isfuelTypeApplied =
      selectedFields.fuelType?.length > 0;
    const isQuantityRangeApplied = quantityRange.min && quantityRange.max;

    const isHoursRangeApplied = hoursRange.min && hoursRange.max;

    setIsFiltersApplied(
      isDateApplied ||
      isMachineCategoryApplied ||
        isEquipmentTypeApplied ||
        isQuantityRangeApplied ||
        isHoursRangeApplied ||
        isMachineryCategory ||
        isfuelTypeApplied
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      "Machinery Category": isMachineryCategory,
      "Equipment Type": isEquipmentTypeApplied,
      "Industrial Equipment":isMachineCategoryApplied ,
      "Fuel Type":isfuelTypeApplied,
      "Quantity Consumed": isQuantityRangeApplied,
      "Hours Used": isHoursRangeApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    // setErrors({ Date: false, Role: false });
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const columnHeadings = [
    { title: "Date", fieldName: "createdAt" },
    { fieldName: "machineryType", title: "Machinery Type" },
    { fieldName: "machineryName", title: "Name of Machinery" },
    { fieldName: "fuelType", title: "Fuel Type" },
    { fieldName: "quantityOfFuelConsumed", title: "Quantitye of Fuel" },
  ];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/scope1/mobileCombustion/getAllOFRoadVehicles`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
      case "Machinery Type":
        return (
          <MultiSelect
            title="Machinery Type"
            selectedField={selectedFields.machineryType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Machinery Type"]}
            setErrors={setErrors}
            field="machineryType"
            data={uniqueValues.machineryType}
          />
        );
      case "Name of Machinery":
        return (
          <MultiSelect
            title="Name of Machinery"
            url={`${api}/scope1/mobileCombustion/getAllOFRoadVehicles`}
            selectedField={selectedFields.equipmentType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Name of Machinery"]}
            setErrors={setErrors}
            field="machineryName"
            data={uniqueValues.equipmentType}
          />
        );
      case "Machinery Category":
        return (
          <MultiSelect
            title="Machinery Category"
            selectedField={selectedFields.machineryCategory}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Machine Category"]}
            setErrors={setErrors}
            field="machineryCategory"
            data={uniqueValues.machineryCategory}
          />
        );
        case "Fuel Type":
          return (
            <MultiSelect
              title="Fuel Type"
              selectedField={selectedFields.fuelType}
              selectedFields={selectedFields}
              setSelectedFields={setSelectedFields}
              errors={errors["Fuel Type"]}
              setErrors={setErrors}
              field="fuelType"
              data={uniqueValues.fuelType}
            />
          );
      case "Quantity of Fuel Consumed":
        return (
          <Range
            title="Quantity of Fuel Consumed"
            selectedRange={quantityRange}
            setSelectedRange={setQuantityRange}
            errors={errors["Quantity of Fuel Consumed"]}
            setErrors={setErrors}
            placeholderMin={"Min(litre)"}
            placeholderMax={"Max(litre)"}
          />
        );
      case "Hours Used":
        return (
          <Range
            title="Hours Used"
            selectedRange={hoursRange}
            setSelectedRange={setHoursRange}
            errors={errors["Hours Used"]}
            setErrors={setErrors}
            placeholderMin={"Min(hours)"}
            placeholderMax={"Max(hours)"}
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/scope1/mobileCombustion/getAllOFRoadVehicles`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.date,
            }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <MessagePopup {...{ snackOpen, setsnackOpen }} />{" "}
      {/*Message popup for success or failure */}
      {/* View log, Edit, delete menus */}
      <AddOffRoad
        id={element ? element._id : ""}
        addData={addData}
        setAddData={setAddData}
        data={element}
        addUrl={`${api}/scope1/mobileCombustion/addOFRoadVehicles`}
        updateUrl={`${api}/scope1/mobileCombustion/updateOFRoadVehicles`}
        // details={vehicle}
        fetchData={fetchData}
        setsnackOpen={setsnackOpen}
        inputData={inputData}
      />
      <ViewOffRoad
        id={element ? element._id : ""}
        data={element}
        viewData={viewData}
        setViewData={setViewData}
        inputData={inputData}
        handleEdit={handleEdit}
      />
      <Grid item xs={6} sx={{ margin: "0% 5% -3% 23%", width: "100%" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Mobile Combustion
        </Typography>
        <Box sx={{ display: "flex", width: "83%", mt: 2, gap: 2 }}>
          {/* Card 1: Total Natural Gas Consumed */}
          <Cards title="Total Fuel Consumed" content="10,000 m³" />
          <Cards
            title="Top Fuel Consumption Machinery(Type of Fuel)"
            content="Machinery 1 | Machinery 2"
          />
          <SelectCard
            title="Consumption Period"
            content="700 m³"
            handleChange={handlePeriodChange}
            value={selectedPeriod}
            list={months}
          />
          {/* Card 3: Consumption Period */}
        </Box>
      </Grid>
      {deleteDialog && (
        <DeleteDialog
          {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
        />
      )}
      {/* Filter Drawer */}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setElement}
          setAddData={setAddData}
          {...{
            // heading: "Coal",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "3% 5% 0% 23%" }}
      >
        <Box>
          <TableHeading heading={"Off Road Vechile & Equipments"} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "12% 20% 20% 25% 12% 8%",
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            {columnHeadings.map((el) => (
              <ColumnHeadings
                key={el}
                {...{
                  title: el.title,
                  key: el.title,
                  handleSort,
                  field: el.fieldName,
                  sortConfig,
                }}
              />
            ))}
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {data &&
            data.map((item) => (
              <Box
                key={item._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "12% 20% 20% 25% 12% 8%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {columnHeadings.map((el) => (
                  <TableContent
                    key={el.fieldName}
                    {...{
                      fieldData:
                        typeof [el.fieldName] === "object" &&
                        // [el.fieldName] !== null &&
                         [el.fieldName].name
                          ? [el.fieldName].name
                          : typeof item[el.fieldName] === "object" &&
                            item[el.fieldName] !== null &&
                            item[el.fieldName].name
                          ? item[el.fieldName].name
                          : item[el.fieldName],
                      dateField: el.fieldName === "createdAt",
                    }}
                  />

                    // console.log(el.fieldName,item,item[el.fieldName],"el.fieldName")
                ))}
                {/* Menu Action Icon */}
               
                <MenuIcon {...{ handleMenuClick, field: item }} />
              </Box>
            ))}
        </Box>
      </TableContainer>
      {/* Pagination */}
      <PaginationBox
        {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
      />
      <MenuActions
        {...{
          id: element?._id,
          handleView,
          handleEdit,
          handleDelete,
          anchorEl,
          handleMenuClose,
        }}
      />
      {/*Add,Edit,Delete Icons & function calls */}
    </Box>
  );
};

export default OffRoad;
