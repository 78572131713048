import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import contries from "../Roles and Permissions/Country.json";
import { api } from "../../../utils/api";
import { companyId } from "../../../utils/companyId";
import {textError,numberError,listError} from "../../../utils/validationMessage";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from '@mui/material/LinearProgress';
import FileField from "../../../Components/Masters/FileField";



function AddEquipment(props) {
  const [fileNames, setfileNames] = useState([]);
  const [alreadyEquipment, setAlreadyEquipment] = useState(false);
  const [Units,setUnit]=useState(["MMBTU","MJ","GJ"]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); 
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

const initialState ={
    company: companyId,
    user:"",
    // facility:"Tap to Select",
    equipment: "",
    country:"Tap to Select",
    brand: "",
    documents: [],
    ratedConsumption: "",
    unit: "Tap to Select",
    remark:""
  };
  const resetForm = () => {
    console.log("_idddddd",props.selectedEquipment?._id)
    setFromValue(props.selectedEquipment?._id === undefined?initialState:props.selectedEquipment);
    setErrors({});
  };

  const [fromValue, setFromValue] = useState(initialState);
  console.log("propsEquipment",props)
  // useEffect(()=>{
  //   axios.get(`${api}/master/getAllFacility`).then((res)=>{console.log("resForPermission",res.data.data)
  //     setmoduleName(res.data.data)
  //   }).catch((err)=>{console.log("err",err)})
  // },[])


  useEffect(() => {
    setFromValue({
      company: companyId,
    // facility:props.selectedEquipment?.facility ??"Tap to Select",
    equipment:props.selectedEquipment?.equipment ?? "",
    model:props.selectedEquipment?.model ?? "",
    brand: props.selectedEquipment?.brand ??"",
    // documents: props.selectedEquipment?.documents ??[],
    ratedConsumption:props.selectedEquipment?.ratedConsumption ?? "",
    unit: props.selectedEquipment?.unit ??"Tap to Select",
    remark:props.selectedEquipment?.remark ??""
    });
    setUploadedFiles(props.selectedEquipment?.documents?? []);
    setAlreadyEquipment(props.selectedEquipment === null ? false : true);
  }, [props.selectedEquipment]);

 
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("nam===e", name, value);
    setFromValue({
      ...fromValue,
      [name]: value,
    });
    setErrors({...errors,[name]:""});
  };
  const validateForm = () => {
    const newErrors = {};

    const {
      model,
      // facilityName,
      equipment,
      documents,
      brand,
      // facility,
      ratedConsumption,
      unit,
    } = fromValue;

    // Validate `facilityName`
    // if (!facility || facility.trim()==="Tap to Select"|| facility.trim() === "") {
    //   newErrors.facility = textError;
    // }

    // Validate `user`
    if (!model || model.trim() === "") {
      newErrors.model = textError;
    }

    // Validate `nickname`
    if (!equipment || equipment.trim() === "") {
      newErrors.equipment = textError;
    }

    // Validate `incharge`
    if (!brand || brand.trim() === "") {
      newErrors.brand = textError;
    }
    // if (!ratedConsumption || ratedConsumption.trim() === "" ) {
    //   newErrors.ratedConsumption = numberError;
    // }
    if (!ratedConsumption || isNaN(ratedConsumption) || ratedConsumption === "" || ratedConsumption <= 0
  || !/^\d*\.?\d*$/.test(ratedConsumption)
  ) {
      newErrors.ratedConsumption = numberError;
    }
    // if (!documents || documents.length === 0) {
    //   newErrors.documents = "At least one document must be uploaded.";
    // }

    if (uploadedFiles.length === 0) {
      // console.log(input.fieldName,uploadedFiles)
       newErrors["documents"] = `Please upload Documents`;
     }
    if (!unit || unit.trim() === "" || unit.trim() === "Tap to Select") {
      newErrors.unit = listError;
    }

    // Validate `country`
 

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleModule =(e)=>{
    const {name,value}=e.target
  console.log(e.key,value,"forModule")
    setFromValue({
      ...fromValue,
      [name]:value
    })
  }


  const handleDeleteImage = (index) => {
    // Create a new documents array without the deleted item
    console.log(index,"indexValueForDeleteImage",fromValue.documents,fromValue.documents.filter((_, i) => i !== index))
    const updatedDocuments = fromValue.documents.filter((_, i) => i !== index);

    // Update the state with the modified documents
    setFromValue((prevEquipment) => ({
      ...prevEquipment,
      documents: updatedDocuments,
    }));
  };


  const handleFileChange = async (e) => {
    const files = e.target.files;
    console.log("filesOpen",files,files.FileList)
    if (!files || files.length === 0) return;
    const fileNames = Array.from(files).map(file => file.name);
    setfileNames([...fileNames,fileNames])
    // Limit to max 5 files
    if (files.length > 5) {
      alert("You can only upload a maximum of 5 files.");
      return;
    }

    const allowedTypes = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls,
      "image/png",
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        alert(`File type not allowed: ${file.name}`);
        return;
      }

      // Check file size
      if (file.size > maxFileSize) {
        alert(`File size exceeds 5MB: ${file.name}`);
        return;
      }
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      setLoading(true)
      const uploadResponse = await axios.post(`${api}/cloud/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const uploadedUrls = uploadResponse.data.urls; // Assuming your backend sends back an array of URLs
      // setFromValue((prev) => ({
        
      //   ...prev,
      //   documents: uploadedUrls,
      // }));


      setFromValue((prev) => {
        // If documents already exist, append new ones, else just set the new URLs
        const updatedDocuments = prev.documents.length > 0 
          ? [...prev.documents, ...uploadedUrls] 
          : uploadedUrls;
  
        return {
          ...prev,
          documents: updatedDocuments,
        };
      });
      setLoading(false);
    } catch (err) {
      setLoading(false)
      console.error("Error uploading files:", err);
    }
  };

  const Save = () => {
    if (!validateForm()) {
      return;
    }
    console.log(fromValue);
    const dataToSend = {
      ...fromValue,
      documents:uploadedFiles, // This should include all uploaded files
    };
    axios({
      method: alreadyEquipment ? "put" : "post",
      url: alreadyEquipment
        ? `${api}/master/Steam/updateEquipment`
        : `${api}/master/Steam/creatEquipment`,
      data: dataToSend, // Send data only if it's a POST request
      params: alreadyEquipment ? { Id: props.selectedEquipment?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        console.log(res, res.data.data);
        props.setAddEquipment(false);
        props.fetchHeatEquipment();
        resetForm();
        setUploadedFiles([])
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        setFromValue((pre)=>{
          return{
            ...pre
          }
        })
        //  localStorage.setItem("roleId",res.data.data._id)
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          // If there is a message in the error response, use it
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{width:400,
        // maxWidth:"100%",
        height: '100vh',
        display:"flex",justifyContent:"space-btween",
          backgroundColor:"transparent"
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {props.setAddEquipment(false);resetForm();}}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll"}}>
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          {props.selectedEquipment?._id === undefined?
          " Add Equipment"
             : "Edit Equipment"}
        </Typography>
         
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Equipment Name
        </Typography>
        <CustomTextField
          // select
          variant="outlined"
          value={fromValue.equipment}
          onChange={handleChange}
          fullWidth
          name="equipment"
          placeholder="Name"
          error={!!errors.equipment} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.equipment ? "#D34040" : undefined },
          }}
        ></CustomTextField>
         {errors.equipment && (
          <Typography variant="body2" color="#D34040">
            {errors.equipment}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Brand
        </Typography>
        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.brand}
          onChange={handleChange}
          fullWidth
          name="brand"
          placeholder="Name"
          error={!!errors.brand} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.brand ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.brand && (
          <Typography variant="body2" color="#D34040">
            {errors.brand}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Rated Steam Consumption/Hr
        </Typography>
        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.ratedConsumption}
          onChange={handleChange}
          fullWidth
          name="ratedConsumption"
          placeholder="1200"
          error={!!errors.ratedConsumption} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.ratedConsumption ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.ratedConsumption && (
          <Typography variant="body2" color="#D34040">
            {errors.ratedConsumption}
          </Typography>
        )}
           <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        Model
        </Typography>

        <CustomTextField
          //   select
          variant="outlined"
          value={fromValue.model}
          onChange={handleChange}
          fullWidth
          name="model"
          placeholder="Name"
          error={!!errors.model} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.model ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.model && (
          <Typography variant="body2" color="#D34040">
            {errors.model}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Unit
        </Typography>
       
        <CustomTextField
           select
          variant="outlined"
          value={fromValue.unit}
          onChange={handleChange}
          fullWidth
          name="unit"
          placeholder="Unit"
          error={!!errors.unit} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.unit ? "#D34040" : undefined },
          }}
        >
           <MenuItem value={fromValue.unit==="" ? "Tap to Select" :fromValue.unit} disabled>
      {fromValue.unit==="" ? "Tap to Select" :fromValue.unit} 
  </MenuItem>
  {
  Units.map((item,index)=>{
      return <MenuItem key={index} value={item}>
       {item}
       </MenuItem> 
      })}
        </CustomTextField>
        {errors.unit && (
          <Typography variant="body2" color="#D34040">
            {errors.unit}
          </Typography>
        )}
         <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Remark
        </Typography>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          name="remark"
          value={fromValue.remark}
          onChange={handleChange}
          placeholder="Brief"
        />
        {/* <Typography
          variant="subtitle2"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Upload Documents
        </Typography> */}
        <FileField
          title={"Upload Documents"}
          name={"documents"}
          errors={errors["documents"]}
          fileUrls={uploadedFiles}
          progress={uploadProgress["documents"] || []}
          {...{setUploadedFiles,setUploadProgress,setErrors}}
          // onDelete={handleDeleteFile}
        />
     {/* {loading?<LinearProgress/> :
        <Box
          sx={{
            border: "1px dashed grey",
            borderRadius: 1,
            p: 2,
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            hidden
            id="upload-document"
             onChange={handleFileChange}
            multiple // Allow multiple file selection
          />
          <label htmlFor="upload-document">
          
            <Box display="flex" flexDirection="column" alignItems="center">
              <CloudUploadIcon />
              <Typography>Drop files here or click to upload</Typography>
              <Typography variant="caption">
                Upload your file (CSV, Excel, PNG, max 5 MB, recommended size
                1200x800 pixels).
              </Typography>
            </Box>
          </label>
        </Box>} */}
        
        {/* {errors.documents ? (
          <Typography variant="body2" color="#D34040">
            {errors.documents}
          </Typography>
        ):""
        // fileNames.map((item)=>item).join(',')
        } */}

        {/* <CustomTextField
          //  select
      variant="outlined"
        value={fromValue.outsourceFacility}
      onChange={handleChange}
      fullWidth
      name="outsourceFacility"
      placeholder="Outsource"
    >
    </CustomTextField> */}
        {/* <Box> */}
        <Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0a3f73",
            textTransform: "none",
            marginTop: "10%",
          }}
          onClick={Save}
        >
          Submit
        </Button>
        </Box>
       
        {/* </Box> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addEquipment}
        onClose={() =>{ props.setAddEquipment(false); resetForm();}}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddEquipment;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
