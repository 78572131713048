import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PaginationItem,
  Drawer,
  Alert,
  Snackbar,
  DialogTitle,
  Card,
  CardContent,
  CardHeader,
  Select,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
import AddTransportation from "./AddTransportation";
import ViewTransportation from "./ViewTransportation";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import { get, api, put, del } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import { styled } from "@mui/material/styles";
// import Cards from "../../../../Components/Masters/Cards";
import Cards from "../../../../Components/Common/Cards";
import ViewTable from "../../../../Components/Common/ViewTable";
import FilterModal from "../../../../Components/Common/FilterModal";
import ActionButtons from "../../../../Components/Common/ActionButtons";

const TransportationDistrubution = () => {
  const [petroleums, setPetroleums] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [element, setElement] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [addData, setAddData] = useState(false);
  const [view, setView] = useState(false);
  const [dataView, setdataView] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [tableData, setTableData] = useState([]);
  // const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries
  const [selectedFacilities, setSelectedFacilities] = useState([]); // Store selected petroleum name
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [filterOrigin, setFilterOrigin] = useState([]);
  const [filterDestination, setFilterDestination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);

  const [errors, setErrors] = useState({
    Date: false,
    industrialProcess: false,
    equipmentType: false,
    industrialEquipment: false,
    quantityConsumed: false,
    hoursUsed: false,
  });
  const [dataforView, setdataForView] = useState(null);
  const [quantityRange, setQuantityRange] = useState({ min: "", max: "" });
  const [hoursRange, setHoursRange] = useState({ min: "", max: "" });
  const [cardConsumptions, setCardConsumptions] = useState({
    "Total Petrol Consumed": "",
    "Top Equipment By Consumption": "",
    "Consumption Period": "",
  });
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterObj, setFilterObj] = useState({});

  const headers = [
    { key: "createdAt", label: "Date", sortable: true },
    {
      key: "origin",
      label: "Source",
      sortable: true,
    },
    {
      key: "destination",
      label: "Destination",
      sortable: true,
    },
    {
      key: "freightLoad",
      label: "Freight Load(Tonnes)",
      sortable: true,
    },
    {
      key: "distanceCovered",
      label: "Distance Travelled(Km)",
      sortable: true,
    },
    // { key: "unit", label: "Vendor Name", sortable: true },
  ];

  const inputData = [
    {
      fieldName: "origin",
      title: "Source",
      placeholder: "Enter Source",
      type: "String",
      required: true,
    },
    {
      fieldName: "destination",
      title: "Destination",
      placeholder: "Enter Destination",
      type: "String",
      required: true,
    },
    {
      fieldName: "freightLoad",
      title: "Freight Load",
      placeholder: "10000",
      type: "number",
      required: true,
    },
    {
      fieldName: "distanceCovered",
      title: "Distance Travelled",
      placeholder: "10000",
      type: "number",
      required: true,
    },

    {
      fieldName: "remark",
      title: "Remark",
      placeholder: "Additional remarks",
      type: "text",
    },
    {
      fieldName: "documents",
      title: "Upload Documents",
      type: "file",
    },
  ];

  const filterData = [
    { title: "Date", type: "date", key: "createdAt", value: [] },
    {
      title: "Origin",
      type: "option",
      key: "origin",
      value: filterOrigin,
    },
    {
      title: "Destination",
      type: "option",
      key: "destination",
      value: filterDestination,
    },
    {
      title: "Freight Load (Tonnes)",
      type: "limit",
      key: "freightLoad",
      value: ["one", "two"],
    },
  ];

  const fetchFilters = async () => {
    await get(`/scope3/airTransportation/air/filter`)
      .then((res) => {
        console.log(res?.data);
        setFilterOrigin(res?.data?.data?.origin);
        setFilterDestination(res?.data?.data?.destination);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const handleFilterChange = (newFilterObj) => {
    setFilterObj(newFilterObj);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const onResetFilters = () => {
    setFilterObj({}); // Reset the filter object
    fetchData(); // Fetch data without filters
  };

  const handleMenuClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    // setSelectedPetroleum(petroleum);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // setSelectedPetroleum(null);
  };

  const handleEdit = (data) => {
    console.log("Edit petroleum:", data?._id);
    handleMenuClose();
    setAddData(true);
    setView(false);
    setdataView(data?._id !== undefined ? data : data?.data);
  };

  const handleView = (data) => {
    console.log("View petroleum:");
    handleMenuClose();
    setView(true);
    setAddData(false);
    setdataForView(data?.data);
  };

  const handleDelete = (index) => {
    console.log("Delete petroleum:", index.id);
    handleMenuClose();
    setDeleteDialog(true);
    setdataView(index.id);
  };

  const handleDeleteAggree = () => {
    console.log("indexhandleDeleteAggree", dataView, dataView);

    del(`/scope3/airTransportation/delete`, {
      params: {
        Id: dataView, // Pass as query parameter
      },
    })
      .then(async (res) => {
        console.log(res, "res++");
        setDeleteDialog(false);
        setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        setsnackOpen({
          open: true,
          message: err.response?.data?.message ?? "Network Error",
          severity: "error",
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchData = async () => {
    let filter = { ...filterObj };
    // if (filter.load) {
    //   filter.minLoad = parseInt(filterObj.load.min);
    //   filter.maxLoad = parseInt(filterObj.load.max);
    // }
    if (
      filter.freightLoad &&
      filter.freightLoad.min &&
      filter.freightLoad.max
    ) {
      filter.minLoad = parseInt(filterObj.freightLoad.min);
      filter.maxLoad = parseInt(filterObj.freightLoad.max);
    }

    if (filter.createdAt?.start) {
      filter.startDate = filter.createdAt.start;
    }
    if (filter.createdAt?.end) {
      filter.endDate = filter.createdAt.end;
    }

    const queryParams = new URLSearchParams({
      ...filter,
      page: currentPage,
      limit: 10,
      sortField: sortConfig.key,
      sortOrder: sortConfig.direction,
    }).toString();
    await get(`/scope3/airTransportation/get?${queryParams}`)
      .then((res) => {
        console.log(res?.data);
        let arr = res?.data?.data?.map((item) => {
          return {
            createdAt: new Date(item.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
            origin: item?.origin,
            destination: item.destination,
            freightLoad: item.freightLoad?.value, // Accessing nested `value`
            distanceCovered: item.distanceCovered?.value, // Accessing nested `value`
            action: "View",
            id: item?._id,
            data: item,
          };
        });
        setTableData(arr);
        setPageSize(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
    // fetchData()
  }, [currentPage, rowsPerPage, sortConfig, filterObj]);

  // default filters to apply

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    // setErrors({ Date: false, Role: false });
  };

  let bannerData = [
    {
      title: "Natural Gas Consumption",
      description: "This is the description of the banner.",
    },
    {
      title: "Natural Gas Consumption",
      description: ["one", "two"],
    },
    {
      title: "Natural Gas Consumption",
      description: "This is the description of the banner.",
    },
  ];

  const openModal = (type, dataForEdit) => {
    setAddData(true);
  };

  console.log("curre", currentPage);

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "hidden",
        overflowX: "hidden",
      }}
    >
      <Snackbar
        open={snackOpen.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setsnackOpen({ ...snackOpen, open: false })}
      >
        <Alert
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackOpen.message}
        </Alert>
      </Snackbar>

      <AddTransportation
        addData={addData}
        setAddData={setAddData}
        SelectedData={dataView}
        fetchData={fetchData}
        inputData={inputData}
        addUrl={`${api}/scope3/airTransportation/add`}
        updateUrl={`${api}/scope3/airTransportation/update`}
        setsnackOpen={setsnackOpen}
      />
      <ViewTransportation
        view={view}
        setView={setView}
        selectedData={dataforView}
        handleEdit={handleEdit}
      />

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        filters={filterData}
        onFilterChange={handleFilterChange}
        title="Filter"
      />

      <Cards data={bannerData} title={" Air Transportation & Distribution"} />

      {deleteDialog && (
        <Dialog open={deleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this Facility?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteAggree} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "1rem",
          gap: "1rem",
        }}
      >
        <Button
          style={{
            backgroundColor: "#fff",
            color: "#000000",
            borderRadius: "4px",
            gap: "1rem",
          }}
          onClick={() => setIsFilterModalOpen(true)}
        >
          Filter <FilterListIcon />
        </Button>
        <Button
          style={{
            backgroundColor: "#1C486B",
            color: "#ffffff",
            borderRadius: "4px",
            gap: "0.5rem",
          }}
          onClick={() => setAddData(true)}
        >
          Add Data
          <AddIcon />
        </Button>
      </div> */}

      <ActionButtons
        onFilterClick={() => setIsFilterModalOpen(true)}
        onAddClick={() => openModal("add")}
        filterObj={filterObj} // Pass the filter object here
      />

      <ViewTable
        headers={headers}
        data={tableData}
        onSort={handleSort}
        sortConfig={sortConfig}
        onMenuClick={handleMenuClick}
        page={currentPage}
        count={pageSize}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        onView={handleView}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>

        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TransportationDistrubution;
