export function capitalizeFirstLetter(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
}

export const validateFile = (file, allowedTypes, maxFileSize) => {
  if (!allowedTypes.includes(file.type)) {
    return `File type not allowed: ${file.name}`;
  }
  if (file.size > maxFileSize) {
    return `File size exceeds 5MB: ${file.name}`;
  }
  return null; // No errors
};

export const checkImageDimensions = (file, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const fileURL = URL.createObjectURL(file);
    image.src = fileURL;

    image.onload = () => {
      URL.revokeObjectURL(fileURL); // Release memory
      if (image.width > maxWidth || image.height > maxHeight) {
        reject(new Error(`Image dimensions exceed 1200x800: ${file.name}`));
      } else {
        resolve();
      }
    };

    image.onerror = () => {
      URL.revokeObjectURL(fileURL); // Release memory
      reject(new Error(`Unable to load image: ${file.name}`));
    };
  });
};
