import { Box } from "@mui/material";
import React from "react";

const NoDataFound = () => {
  console.log("no data")
  return (
    <Box
      sx={{
        textAlign: "center",
        color: "red",
        fontWeight: "bold",
        m: "8%",
        fontSize: "30px",
      }}
    >
      No Data found
    </Box>
  );
};

export default NoDataFound;
