// import React, { useState } from "react";
// import {
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Collapse,
//   Typography,
//   Box,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   Description as ScopeIcon,
//   SaveAs as SaveAsIcon,
//   Close as CloseIcon,
//   ExpandMore as ExpandMoreIcon,
//   RadioButtonUnchecked as RadioButtonUncheckedIcon,
//   CheckCircle as CheckCircleIcon,
//   Padding,
// } from "@mui/icons-material";
// import "./MasterSidebar.css";
// import { useLocation } from "react-router-dom";

// const MasterSidebar = () => {
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const location = useLocation();

//   const [openSections, setOpenSections] = useState({
//     scope1: false,
//     scope2: false,
//     scope3: false,
//     scope3_transportation: false, // Track nested section state
//     scope2_electricity: false,
//     scope2_heat: false,
//     scope2_steam: false,
//   });

// const sidebarRoutes = [
//   {
//     module: "Roles and Permission",
//     icon: <SaveAsIcon />,
//     path: "/masters/roles-permission",
//   },
//   {
//     module: "Facility Master",
//     icon: <SaveAsIcon />,
//     path: "/masters/facility-master",
//   },
//   {
//     title: "Scope 1",
//     isHeading:true,
//     children: [
//       {
//         module: "Industrial Process Master",
//         icon: <ScopeIcon />,
//         path: "/masters/scope1/industrial-process",
//       },
//       {
//         module: "Industrial Equipment Master",
//         icon: <ScopeIcon />,
//         path: "/masters/scope1/industrial-equipment",
//       },
//       {
//         module: "Vehicle Master",
//         icon: <ScopeIcon />,
//         path: "/masters/scope1/vehicle-master",
//       },
//       {
//         module: "Machinery Master",
//         icon: <ScopeIcon />,
//         path: "/masters/scope1/machinery-master",
//       },
//     ],
//   },
//   {
//     title: "Scope 2",
//     isHeading:true,
//     children: [
//       {
//         module: "Electricity Master",
//         icon: <ScopeIcon />,
//         sectionKey: "electricity",
//         path: "/masters/scope2/electricity-master",
//         children: [
//           {
//             module: "Equipment Master",
//             path: "/masters/scope2/electricity-master/equipment-master",
//             parentPath: "/masters/scope2/electricity-master",
//           },
//         ],
//       },
//       {
//         module: "Heat Master",
//         icon: <ScopeIcon />,
//         sectionKey: "heat",
//         path: "/masters/scope2/heat-master",
//         children: [
//           {
//             module: "Equipment Master",
//             path: "/masters/scope2/heat-master/equipment-master",
//             parentPath: "/masters/scope2/heat-master",
//           },
//           {
//             module: "Vendor Master",
//             path: "/masters/scope2/heat-master/vendor-master",
//             parentPath: "/masters/scope2/heat-master",
//           },
//         ],
//       },
//       {
//         module: "Steam Master",
//         icon: <ScopeIcon />,
//         sectionKey: "steam",
//         path: "/masters/scope2/steam-master",
//         children: [
//           {
//             module: "Equipment Master",
//             path: "/masters/scope2/steam-master/equipment-master",
//             parentPath: "/masters/scope2/steam-master",
//           },
//           {
//             module: "Vendor Master",
//             path: "/masters/scope2/steam-master/vendor-master",
//             parentPath: "/masters/scope2/steam-master",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: "Scope 3",
//     isHeading:true,
//     children: [
//       {
//         module: "Transportation and Distribution",
//         icon: <ScopeIcon />,
//         sectionKey: "transportation",
//         path: "/masters/scope3/transportation-distrubution",
//         children: [
//           {
//             module: "Logistics Vendor Master",
//             path: "/masters/scope3/transportation-distribution/logistics-vendor-master",
//             parentPath: "/masters/scope3/transportation-distribution",
//           },
//           {
//             module: "Vehicle Master",
//             path: "/masters/scope3/transportation-distribution/vehicle-master",
//             parentPath: "/masters/scope3/transportation-distribution",
//           },
//           {
//             module: "Driver Master",
//             path: "/masters/scope3/transportation-distribution/driver-master",
//             parentPath: "/masters/scope3/transportation-distribution",
//           },
//         ],
//       },
//       {
//         module: "Waste Generated in Operations",
//         icon: <ScopeIcon />,
//         path: "/masters/scope3/waste-generated",
//       },
//       {
//         module: "Purchased Goods and Services",
//         icon: <ScopeIcon />,
//         path: "/masters/scope3/purchased-goods",
//       },
//     ],
//   },
// ];

//   const [selectedItem, setSelectedItem] = useState(null); // State to keep track of selected item

//   const handleSectionClick = (sectionKey, parentSectionKey) => {
//     setOpenSections((prevState) => {
//       const newState = {
//         scope1: false,
//         scope2: false,
//         scope3: false,
//         scope3_transportation: false,
//         scope2_electricity: false,
//         scope2_heat: false,
//         scope2_steam: false,
//       };

//       // Determine the parent section key
//       // const parentSectionKey = sectionKey.split('_')[0];

//       // Set the parent section and the clicked section open
//       if (parentSectionKey) {
//         newState[parentSectionKey] = true; // Open parent
//         newState[sectionKey] = true; // Toggle clicked section
//       } else {
//         newState[sectionKey] = true; // Toggle clicked section
//       }

//       return newState;
//     });
//   };

//   const handleItemClick = (route) => {
//     console.log(route);
//     if (selectedItem === route.path) {
//       setSelectedItem(null);
//       if (route.parentPath) {
//         console.log(route.parentPath);
//         navigate(route.parentPath); // Navigate to parent route
//       }
//     } else {
//       // select the item and navigate to the current route
//       setSelectedItem(route.path);
//       navigate(route.path);
//     }
//   };

//   const handleCloseIconClick = (sectionKey) => {
//     // Explicitly close the section when close icon is clicked
//     setOpenSections((prevState) => ({
//       ...prevState,
//       [sectionKey]: false, // Close the section
//     }));
//   };

//   // Update getClassName to handle open sections
//   const getClassName = (route) => {
//     return selectedItem === route.path && !route.parentPath ? "active" : "";
//   };

//   // Update renderSidebarItems to use the proper sectionKey
//   const renderSidebarItems = (routes) => {
//     return routes.map((route) => {
//       if (route.isHeading) {
//         // Render the heading item (Masters in this case)
//         return (
//           <div key={route.module}>
//             <ListItemText
//               primary={route.module}
//               primaryTypographyProps={{
//                 fontSize: "16px",
//                 fontWeight: "bold",
//                 color: "white",
//               }}
//               sx={{ mt: 2, ml: 3 }}
//             />
//             {renderSidebarItems(route.children)}{" "}
//             {/* Render the children under this heading */}
//           </div>
//         );
//       }
//       if (route.children) {
//         const hasSectionKey = route.sectionKey;

//         return (
//           <div key={route.module} sx={{ width: "80%", py: "5px" }}>
//             <ListItem
//               button
//               // onClick={() => hasSectionKey && handleSectionClick(route.sectionKey)}
//               className={`list-item ${
//                 openSections[route.sectionKey] && !route.isSection
//                   ? "active"
//                   : ""
//               }`}
//               sx={{ backgroundColor:(openSections[route.sectionKey]) && (route.sectionKey==="Scope 1" || route.sectionKey==="Scope 2" || route.sectionKey==="Scope 3") && "green"}}
//             >
//               <ListItemIcon
//                 sx={{
//                   minWidth: "35px",
//                   fontSize: "15px",
//                   color:
//                     openSections[route.sectionKey] && !route.isSection
//                       ? "#488257"
//                       : "white",
//                 }}
//               >
//                 {route.icon}
//               </ListItemIcon>
//               <ListItemText
//                 primaryTypographyProps={{ fontSize: "13px" }}
//                 primary={route.module}
//                 sx={{
//                   color:
//                     openSections[route.sectionKey] && !route.isSection
//                       ? "#488257"
//                       : "white",
//                   mt: 1,
//                   fontSize: "15px",
//                 }}
//               />
//               {hasSectionKey &&
//                 (openSections[route.sectionKey] ? (
//                   <CloseIcon
//                     onClick={() =>
//                       hasSectionKey && handleCloseIconClick(route.sectionKey)
//                     }
//                     sx={{
//                       color: "white", // Change the color to white when section is open
//                       fontSize: "15px",
//                       mt: 1,
//                       minWidth: "30px",
//                     }}
//                   />
//                 ) : (
//                   <ExpandMoreIcon
//                     onClick={() =>
//                       hasSectionKey &&
//                       handleSectionClick(
//                         route.sectionKey,
//                         route.parentSectionKey
//                       )
//                     }
//                     sx={{
//                       color: "#ffffff",
//                       fontSize: "20px",
//                       minWidth: "30px",
//                     }}
//                   />
//                 ))}
//             </ListItem>
//             <Collapse
//               in={hasSectionKey ? openSections[route.sectionKey] : true}
//               timeout="auto"
//               unmountOnExit
//               sx={{ mb: 0 }}
//             >
//               <List component="div" disablePadding>
//                 {renderSidebarItems(route.children)}{" "}
//                 {/* Recursively render nested items */}
//               </List>
//             </Collapse>
//           </div>
//         );
//       }

//       // Render normal menu items
//       return (
//         <ListItem
//           component={Link}
//           to={route.path}
//           key={route.module}
//           className={`list-item ${
//             (selectedItem === route.path || location.pathname === route.path) &&
//             !route.parentSectionKey
//               ? "active"
//               : ""
//           }`}
//           onClick={() => handleItemClick(route)}
//         >
//           <ListItemIcon
//             sx={{
//               minWidth: "40px",
//               color:
//                 selectedItem === route.path || location.pathname === route.path
//                   ? "#488257"
//                   : "white",
//             }}
//           >
//             {route.icon ? (
//               route.icon
//             ) : selectedItem === route.path ||
//               location.pathname === route.path ? (
//               <CheckCircleIcon
//                 sx={{ fontSize: "15px", color: "#ffffff", minWidth: "30px" }}
//               />
//             ) : (
//               <RadioButtonUncheckedIcon
//                 sx={{ fontSize: "15px", minWidth: "30px" }}
//               />
//             )}
//           </ListItemIcon>
//           <ListItemText
//             primary={route.module}
//             primaryTypographyProps={{ fontSize: "13px" }}
//             className={`list-item-text ${getClassName(route)}`}
//           />
//         </ListItem>
//       );
//     });
//   };
//   return (
//     <nav>
//       <Drawer
//         variant="permanent"
//         open
//         className="sidebar"
//         sx={{
//           "& .MuiDrawer-paper": {
//             width: "18%",
//             backgroundColor: "#488257",
//             ml: "50px",
//             overflowY: "auto",
//             overflowX: "hidden",
//             paddingLeft: "10px", // Adjust left padding for scrollbar spacing effect
//           },
//         }}
//       >
//         <Box sx={{ width: "80%", ml: "10%", mt: "20%" }}>
//           <Typography
//             variant="h6"
//             sx={{
//               color: "white",
//               borderBottom: "2px solid white",
//               fontSize: "18px",
//             }}
//           >
//             Setup
//           </Typography>
//         </Box>
//         <List>{renderSidebarItems(sidebarRoutes)}</List>
//       </Drawer>
//     </nav>
//   );
// };

// export default MasterSidebar;

import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import {
  Description as ScopeIcon,
  SaveAs as SaveAsIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  CheckCircle as CheckCircleIcon,
  Padding,
} from "@mui/icons-material";
import "./MasterSidebar.css";
import { useLocation } from "react-router-dom";

const MasterSidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [openSections, setOpenSections] = useState({
    electricity: false,
    heat: false,
    steam: false,
    transportation: false,
  });

  const [selectedItem, setSelectedItem] = useState(null); // State to keep track of selected item

  const sidebarRoutes = [
    {
      module: "Roles and Permission",
      icon: <SaveAsIcon />,
      path: "/setup/roles-permission",
    },
    {
      module: "Facility Master",
      icon: <SaveAsIcon />,
      path: "/setup/facility-master",
    },
    {
      module: "Masters",
      isHeading: true,
      sectionKey:"master",
      icon: <ScopeIcon />,
      // isSection:true,
      children: [
        {
          module: "Scope 1",
          isSection: true,
          children: [
            {
              module: "Industrial Process Master",
              icon: <ScopeIcon />,
              sectionModule:true,
              path: "/setup/masters/scope1/industrial-process",
            },
            {
              module: "Industrial Equipment Master",
              icon: <ScopeIcon />,
              sectionModule:true,
              path: "/setup/masters/scope1/industrial-equipment",
            },
            {
              module: "Logistics Vendor Master",
              icon: <ScopeIcon />,
              sectionModule:true,
              path: "/setup/masters/scope1/logistics-vendor-master",
            },
            {
              module: "Vehicle Master",
              icon: <ScopeIcon />,
              sectionModule:true,
              path: "/setup/masters/scope1/vehicle-master",
            },
            {
              module: "Machinery Master",
              icon: <ScopeIcon />,
              sectionModule:true,
              path: "/setup/masters/scope1/machinery-master",
            },
          ],
        },
        {
          module: "Scope 2",
          isSection: true,
          children: [
            {
              module: "Electricity Master",
              icon: <ScopeIcon />,
              sectionKey: "electricity",
              path: "/setup/masters/scope2/electricity-master",
              children: [
                {
                  module: "Equipment Master",
                  path: "/setup/masters/scope2/electricity-master/equipment-master",
                  parentPath: true,
                },
                {
                  module: "Vendor Master",
                  path: "/setup/masters/scope2/electricity-master/vendor-master",
                  parentPath: true,
                },
              ],
            },
            {
              module: "Heat Master",
              icon: <ScopeIcon />,
              sectionKey: "heat",
              path: "/setup/masters/scope2/heat-master",
              children: [
                {
                  module: "Equipment Master",
                  path: "/setup/masters/scope2/heat-master/equipment-master",
                  parentPath: true,
                },
                {
                  module: "Vendor Master",
                  path: "/setup/masters/scope2/heat-master/vendor-master",
                  parentPath: true,
                },
              ],
            },
            {
              module: "Steam Master",
              icon: <ScopeIcon />,
              sectionKey: "steam",
              path: "/setup/masters/scope2/steam-master",
              children: [
                {
                  module: "Equipment Master",
                  path: "/setup/masters/scope2/steam-master/equipment-master",
                  parentPath: true,
                },
                {
                  module: "Vendor Master",
                  path: "/setup/masters/scope2/steam-master/vendor-master",
                  parentPath: true,
                },
              ],
            },
          ],
        },
        {
          module: "Scope 3",
          isSection: true,
          children: [
            {
              module: "Transportation and Distribution",
              icon: <ScopeIcon />,
              sectionKey: "transportation",
              path: "/setup/masters/scope3/transportation-distrubution",
              children: [
                {
                  module: "Logistics Vendor Master",
                  path: "/setup/masters/scope3/transportation-distribution/logistics-vendor-master",
                  parentPath: true,
                },
                {
                  module: "Vehicle Master",
                  path: "/setup/masters/scope3/transportation-distribution/vehicle-master",
                  parentPath: true,
                },
                {
                  module: "Driver Master",
                  path: "/setup/masters/scope3/transportation-distribution/driver-master",
                  parentPath: true,
                },
              ],
            },
            {
              module: "Waste Generated in Operations",
              icon: <ScopeIcon />,
              sectionModule:true,
              path: "/setup/masters/scope3/waste-generated",
            },
            {
              module: "Purchased Goods and Services",
              icon: <ScopeIcon />,
              sectionModule:true,
              path: "/setup/masters/scope3/purchased-goods",
            },
          ],
        },
      ],
    },
  ];

  console.log(location.pathname);
  const handleSectionClick = (sectionKey, route) => {
    // Update the open sections state
    setOpenSections((prevOpenSections) => {
      const newState = {
        master: true, // Always keep the master section open when clicked
        electricity: false,
        heat: false,
        steam: false,
        transportation: false,
      };
      newState[sectionKey] = true; // Open the current section
      return newState;
    });
  
    // Reset selected item
    setSelectedItem(null);
  
    // Automatically navigate to the first child route when the section opens
    if (route.children && route.children.length > 0) {
      // Navigate to the first child's path, ensure it's either an array or an object
      const firstChild = Array.isArray(route.children[0].children) ? route.children[0].children[0] : route.children[0];
      
      // Check if first child exists and has a path
      if (firstChild.path) {
        setSelectedItem(firstChild.path); // Update selected item with the first child path
        navigate(firstChild.path); // Navigate to the first child's path
      }
    }
  };

  // Effect to set active section based on route
  useEffect(() => {
    const currentRoute = location.pathname;

    // Check if the current route matches any sidebar route
    sidebarRoutes.forEach((route) => {
      if (route.path === currentRoute) {
        setSelectedItem(route.path);
      }

      // Check for nested routes in the sidebar
      if (route.children) {
        route.children.forEach((child) => {
          if (child.children) {
            child.children.forEach((grandChild) => {
              if (grandChild.path === currentRoute) {
                setSelectedItem(grandChild.path);
                // Open the parent section
                setOpenSections((prev) => ({
                  ...prev,
                  [child.sectionKey]: true,
                  master: true, // Keep the master section open
                }));
              }
            });
          } else if (child.path === currentRoute) {
            setSelectedItem(child.path);
            // Open the parent section
            setOpenSections((prev) => ({
              ...prev,
              [child.sectionKey]: true,
              master: true, // Keep the master section open
            }));
          }
        });
      }
    });
  }, []);

    const handleItemClick = (route) => {
     if (!route.sectionModule && !route.children && !route.parentPath) {
        setOpenSections({});
      }
      else  if (!route.sectionModule && !route.children ) {
        setOpenSections({...openSections,master:true,[route.sectionKey]:true});
      }
      // if(route.parentPath)
      // {
      //   setOpenSections({...openSections,})
      // }
      if (selectedItem === route.path) {
        setSelectedItem(null);
        if (route.parentPath) {
          navigate(route.parentPath); // Navigate to parent route
        }
      } else {
        setSelectedItem(route.path);
        navigate(route.path);
      }
    };
  
  const handleCloseIconClick = (sectionKey) => {
    console.log(sectionKey);
    // Explicitly close the section when close icon is clicked
    setOpenSections((prevState) => ({
      ...prevState,
      [sectionKey]: false, // Close the section
    }));
  };

  const getClassName = (route) => {
    return selectedItem === route.path && !route.parentPath ? "active" : "";
  };

  const renderSidebarItems = (routes) => {
    return routes.map((route) => {
      if (route.isSection) {
        // Render the heading item (Masters in this case)
        return (
          <Box key={route.module}>
            <ListItemText
              primary={route.module}
              primaryTypographyProps={{
                fontSize: "14px",
                // fontWeight: "bold",
                color: "white",
              }}
              sx={{ mt: 2, ml: 2 }}
            />
            {renderSidebarItems(route.children)}{" "}
            {/* Render the children under this heading */}
          </Box>
        );
      }
      if (route.children) {
        const hasSectionKey = route.sectionKey;

        return (
          <Box key={route.module} sx={{ width: "100%"}}>
            <ListItem
              button
              onClick={() =>
                hasSectionKey && handleSectionClick(route.sectionKey,route)
              }
              className={`list-item ${
                openSections[route.sectionKey] ? "active" : ""
              }`}
              sx={{ mt: 0 }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "35px",
                  fontSize: "15px",
                  color: openSections[route.sectionKey] ? "#488257" : "white",
                }}
              >
                {route.icon}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "13px" }}
                primary={route.module}
                sx={{
                  color: openSections[route.sectionKey] ? "#488257" : "white",
                  mt: 1,
                  fontSize: "15px",
                }} // Apply fontSize here
              />
              {hasSectionKey &&
                (openSections[route.sectionKey] ? (
                  <CloseIcon
                    sx={{
                      color: "#488257",
                      fontSize: "15px",
                      mt: 1,
                      minWidth: "30px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Stop the event from propagating to the parent
                      handleCloseIconClick(route.sectionKey); // Close the section
                    }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{
                      color: "#ffffff",
                      fontSize: "20px",
                      minWidth: "30px",
                    }}
                  />
                ))}
            </ListItem>
            <Collapse
              in={hasSectionKey ? openSections[route.sectionKey] : true}
              timeout="auto"
              unmountOnExit
              sx={{ mb: 0 }}
            >
              <List component="div" disablePadding>
                {renderSidebarItems(route.children)}{" "}
                {/* Recursively render nested items */}
              </List>
            </Collapse>
          </Box>
        );
      }

      // Render normal menu items
      return (
        <Box key={route.module} sx={{ width: "100%" }}>
        <ListItem
          component={Link}
          to={route.path}
          key={route.module}
          className={`list-item ${
            (selectedItem === route.path || location.pathname === route.path) &&
            !route.parentPath
              ? "active"
              : ""
          }`}
          onClick={() => handleItemClick(route)}
        >
          <ListItemIcon
            sx={{
              minWidth: "40px",
              color:
                selectedItem === route.path || location.pathname === route.path
                  ? "#488257"
                  : "white",
            }}
          >
            {route.icon ? (
              route.icon
            ) : selectedItem === route.path ||
              location.pathname === route.path ? (
              <CheckCircleIcon
                sx={{ fontSize: "15px", color: "#ffffff", minWidth: "30px" }}
              />
            ) : (
              <RadioButtonUncheckedIcon
                sx={{ fontSize: "15px", minWidth: "30px" }}
              />
            )}
          </ListItemIcon>
          <ListItemText
            primary={route.module}
            primaryTypographyProps={{ fontSize: "13px" }}
            className={`list-item-text ${getClassName(route)}`}
          />
        </ListItem>
        </Box>
      );
    });
  };

  return (
    <nav>
      <Drawer
        variant="permanent"
        open
        className="sidebar"
        sx={{
          "& .MuiDrawer-paper": {
            width: "18%",
            backgroundColor: "#488257",
            ml: "50px",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingLeft: "10px",
            paddingRight:"10px" // Adjust left padding for scrollbar spacing effect
          },
        }}
      >
        <Box sx={{ width: "80%", ml: "10%",mt: "20%" }}>
          <Typography
            variant="h6"
            sx={{
              color: "white",
              borderBottom: "2px solid white",
              fontSize: "18px",
            }}
          >
            Setup
          </Typography>
        </Box>
        <List>{renderSidebarItems(sidebarRoutes)}</List>
      </Drawer>
    </nav>
  );
};

export default MasterSidebar;
