import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  Drawer,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { api } from "../../../../../utils/api";
import DisableFutureDate from "../../../../../Components/Masters/DisableFutureDate";
import { allowedTypes, maxFileSize } from "../../../../../utils/constant";
import LinearProgress from "@mui/material/LinearProgress";
import contries from "../../../Roles and Permissions/Country.json";
import {textError} from "../../../../../utils/validationMessage";

function AddLogisticsVendorMaster(props) {
  const [alreadyFacility, setAlreadyFacility] = useState(false);
  const [facilities, setFacilities] = useState(["Select Faciltiy"]); // Store list of facilities
  const [vendor, setVendors] = useState(["Select"]); // Store list of facilities
  const [loadingAadhar, setLoadingAadhar] = useState(false);
  const [loadingLicense, setLoadingLicense] = useState(false);
  const [verifybtn, setverifybtn] = useState({ email: false, contactPersonMobile: false });
  const [verify, setVerify] = useState({
    phone: false,
    mail: false,
    emailMsg: "",
    phoneMsg: "",
  });
  const [TaskAltIcons, setTaskAltIcons] = useState({
    phone: false,
  });
  // Time state
  const [otp, setOTP] = useState({
    email: null,
  });
  const [timerActive, setTimerActive] = useState({email:false,phone:false});
 
  const [selectedEmail , setEmail] = useState("");
  const [timerPhone, setTimerPhone] = useState(120);
  const [time, setTime] = useState(120); 
const initialState ={
    logisticVendorLegalName:"",
    addressLine1:"",
    addressLine2:"",
    country:"",
    contactPersonName:"",
    phone:"",
    email:"",
    state:"",
    countryCode:"",
    pinCode:""
}
  const [errors, setErrors] = useState({});
  const [fromValue, setFromValue] = useState(initialState);

  useEffect(() => {
    fetchFacilities();

    // Only set form values if props.selectedLogestic is not null or undefined
    if (props.selectedLogestic) {
      setFromValue({
        logisticVendorLegalName: props.selectedLogestic.logisticVendorLegalName?? "",
        addressLine1: props.selectedLogestic.addressLine1 ?? "Owned", // Default ownership type
        addressLine2: props.selectedLogestic.addressLine2 ?? "",
        country: props.selectedLogestic.country ?? "",
        contactPersonName: props.selectedLogestic.contactPersonName ?? "",
        phone: props.selectedLogestic.contactPersonMobile ?? "",
        countryCode: props.selectedLogestic.countryCode ?? "", // Default country code
        email: props.selectedLogestic.contactPersonEmail ?? "",
        country: props.selectedLogestic.country ?? "",
        state: props.selectedLogestic.state ?? "",
        pinCode: props.selectedLogestic.pinCode ?? "",
      });
      setAlreadyFacility(true);
      setEmail(props.selectedLogestic.contactPersonEmail??"")
    } else {
      resetForm(); // Reset form when there's no selected facility
      setAlreadyFacility(false);
    }
  }, [props.selectedLogestic]);



  useEffect(() => {
    if (timerActive.email && time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setTimerActive((prev) => ({ ...prev, email: false }));
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timerActive.email]);
  useEffect(() => {
    if (timerActive.phone && timerPhone > 0) {
      const timer = setInterval(() => {
        setTimerPhone((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setTimerActive((prev) => ({ ...prev, phone: false }));
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timerActive.phone]); 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFromValue({
      ...fromValue,
      [name]: value,
    });
    setErrors({...errors, [name]: "" }); // Reset error message for each field when it changes
  };

  const handleCountryChange = (event) => {
    setErrors({...errors,country:""});
    const selectedCountryCode = event.target.value;
    const selectedCountry = contries.countries.find(
      (country) => country.name === selectedCountryCode
    ); // Find the selected country object

    if (selectedCountry) {
      setFromValue({
        ...fromValue,
        country: selectedCountry.name, // Set country name
        countryCode: selectedCountry.dial_code, // Set country code
      });

      if (selectedCountry.dial_code && fromValue.phone !== "") {
        console.log(fromValue.phone,"fromValue.phone");
        setVerify({ ...verify, phone: true });
        setErrors({ ...errors, [event.target.name]: ""});
    }
      // setErrors({...errors, [event.target.name]: "" }); 
    }
  };
  const handleResendPhone = () => {
    setTimerPhone(180); // Reset time to 180 seconds
    handleVerifyPhone(180); // Call the email verification function
  };

  const handleVerifyPhone = (timePhone) => {
    if (timePhone > 0) {
      setTimerPhone(120)
      axios
        .post(`${api}/auth/sendOtpToPhone`, {
          phone: fromValue.phone,
          countryCode: fromValue.countryCode,
          module: "LogesticsVendorTD"
        })
        .then((res) => {
          console.log("res", res);

          setVerify({...verify,phone:true})
          setTimerActive((prev) => ({ ...prev, phone: true }));
          setErrors({...errors,phone:"",OTPphone:""});
        })
        .catch((err) => {
          console.log(err);
          fromValue.countryCode===""? setErrors({ ...errors, contactPersonMobile:"select Country" }): setErrors({ ...errors, contactPersonMobile: err?.response?.data?.message });
          // setErrors({...errors,contactPersonMobile:err?.response?.data?.message});
          setVerify({...verify,phone:false})
          setverifybtn({...verifybtn,phone:false});
        });
    } else {
      setVerify({ ...verify, phone: false });
      setverifybtn({ ...verifybtn, phone: false });
    }
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, phone: "" });
    setOTP({
     ...otp,
      phone:null
    })
    setErrors({ ...errors, contactPersonMobile: "" });
    // const numericValue = parseInt(value, /^[0-9]{10}$/) || 0;
    // const phoneNumberPattern = /^[0-9]{10}$/;
    const phoneNumberPattern = /^[0-9]*$/;
    if (phoneNumberPattern.test(value) && value.length <= 10) {
      setFromValue({
        ...fromValue,
        [name]: value,
      });
      value.length === 10? setverifybtn({ ...verifybtn, phone: true }):setverifybtn({ ...verifybtn, phone: false })
      setTaskAltIcons({...TaskAltIcons, phone:false});
    
    } else {
      setverifybtn({ ...verifybtn, phone: true });
    }
  };

 
  const handleDateChange = ({ name, value }) => {
    setFromValue((prevValues) => ({
      ...prevValues,
      [name]: value, // Update date field with formatted value
    }));
  };

  const handleDeleteImage = (type, index) => {
    const updatedDocuments = fromValue[type + "Doc"].filter(
      (_, i) => i !== index
    );

    setFromValue((prev) => ({
      ...prev,
      [`${type}Doc`]: updatedDocuments,
    }));
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setFromValue({
      ...fromValue,
      [name]: value,
    });
  };

  const fetchFacilities = async () => {
    try {
      const response = await axios.get(
        `${api}/master/getAllFacility?page=${1}&limit=${300}`
      );
      const result = await axios.get(
        `${api}/master/getAllVendors?page=${1}&limit=${300}`
      );

      setFacilities(response.data.data);
      setVendors(result.data.data);
    } catch (error) {
      console.log("Error fetching facilities:", error);
    }
  };

  const Save = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const dataToSubmit = { ...fromValue };
    if (!validateForm()) {
      return;
    }

    axios({
      method: alreadyFacility ? "put" : "post",
      url: alreadyFacility
        ? `${props.url}/logistic/vendorMasterUpdate`
        : `${props.url}/logistic/vendorMaster`,
      data: dataToSubmit, // Send data only if it's a POST request
      params: alreadyFacility ? { id: props.selectedLogestic?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        props.setAddVendor(false);
        props.fetchLogesticVendor();
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        // setNoOfEquipment(null);
        resetForm();

        //  localStorage.setItem("roleId",res.data.data._id)
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          // If there is a message in the error response, use it
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const handleOTPVerifyPhone = (e) => {
    const { value } = e.target;

    // Only allow numeric values and ensure length does not exceed 4
    const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion

    // Update state only if the value is numeric and has a length <= 4
    if (isNumeric && value.length <= 4) {
      setOTP((prevOtp) => ({
        ...prevOtp,
        phone: value, // Update the OTP value
      }));
      // Trigger API call when exactly 4 digits are entered
      if (value.length === 4) {
        setErrors({ ...errors, contactPersonMobile: "", OTPphone: "" });
        axios
          .post(`${api}/auth/verifyOtpForPhone`, {
            phone: fromValue.phone,
            countryCode: fromValue.countryCode,
            otp: value,
          })
          .then((res) => {
            console.log("OTP verified successfully:", res, res.status);
            if (res.status === 200) {
              setVerify({ ...verify, phone: false });
              setverifybtn({ ...verifybtn, phone: false });
              setTaskAltIcons({ ...TaskAltIcons, phone: true });
            }
          })
          .catch((err) => {
            console.error("Error verifying OTP:", err);
            setVerify({ ...verify, phone: true, phoneMsg: "Invalid OTP" });
            setverifybtn({ ...verifybtn, phone: true });
            setTaskAltIcons({ ...TaskAltIcons, phone: false });
          });
      }
    }
  };


  const handleEmailChange = (e)=>{
    const {name,value}= e.target
    setEmail(value)
    const trimmedValue = value.trim();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setOTP({
      ...otp,
       email:null
     })
    console.log(emailPattern.test(trimmedValue),trimmedValue);
    if (emailPattern.test(trimmedValue)) {
      setFromValue({
        ...fromValue,
        [name]: trimmedValue,
      });
      setVerify({...verify,email:false})
      setverifybtn({...verifybtn,email:true});
      setTaskAltIcons({
        ...TaskAltIcons,
        email:false
      })
      // setErrorMessage(""); // Clear the error message if the email is valid
    } else {
      // setErrorMessage("Invalid email format");
      console.log("Invalid email format")
      setverifybtn({...verifybtn,email:false});
    }
    
  }

  const handleVerifyEmail = (time)=>{
    console.log(time,"timeforOTP")
    // setverifybtn({...verifybtn,email:false});
    if(time>0){
      setTime(120);
      setErrors({ ...errors, email: "" });
      
      axios.post(`${api}/auth/sendOtpToEmail`,{email:fromValue.email,module: "LogesticsVendorTD"}).then((res)=>{
        console.log("resForSendOtpEmail",res)
        
        setTimerActive((prev) => ({ ...prev, email: true }));
        if(res.status===200){
         
          setVerify({...verify,email:true})
          setErrors({...errors,email:"",OTPemail:""});
        }
       
      }).catch((err)=>{console.log(err)

        setErrors({...errors,email:err?.response?.data?.message});
        setVerify({...verify,email:false})
        setverifybtn({...verifybtn,email:false});
      })
    }else{
      setVerify({...verify,email:false})
      setverifybtn({...verifybtn,email:false});
    }
  
    }

    const handleOTPVerify = (e) => {
        const { value } = e.target;
      
        // Only allow numeric values and ensure length does not exceed 4
        const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion
      
        // Update state only if the value is numeric and has a length <= 4
        if (isNumeric && value.length <= 4) {
          setOTP((prevOtp) => ({
            ...prevOtp,
            email: value, // Update the OTP value
          }));
          // Trigger API call when exactly 4 digits are entered
          if (value.length === 4) {
            axios
              .post(`${api}/auth/verifyOtpForEmail`, { email: fromValue.email, otp: value })
              .then((res) => {
                console.log("OTP verified successfully:", res,res.status);
                if(res.status===200){
                setVerify({...verify,email:false})
                setverifybtn({...verifybtn,email:false});
                setTaskAltIcons({...TaskAltIcons,email:true})
                }
              })
              .catch((err) => {
                console.error("Error verifying OTP:", err);
                setVerify({...verify,email:true,emailMsg:"Invalid OTP"})
                setverifybtn({...verifybtn,email:true});
                setTaskAltIcons({...TaskAltIcons,email:false})
                setErrors({ ...errors, email: "", OTPemail: "Incorrect Otp" });
              });
          }
        }
      };

      const formatTime = (time) => {
        console.log("timmerforPhone",time);
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
      };

  const handleResend = () => {
    setTime(120); // Reset time to 180 seconds
    handleVerifyEmail(120); // Call the email verification function
  };

  const validateForm = () => {
    const newErrors = {};
    const {
        logisticVendorLegalName,
        addressLine1,
        addressLine2,
        country,
        contactPersonName,
        phone,
        email,
        state,
        countryCode,
        pinCode
    } = fromValue;

    if (!logisticVendorLegalName || logisticVendorLegalName.trim() === "") {
      newErrors.logisticVendorLegalName = textError;
    }

    if (!country || country === "Select Country") {
      newErrors.country = "Please select a country.";
    }

    if (phone) {
      if (verifybtn.phone === true){
        newErrors.contactPersonMobile = "Please Validate ur phone Click on verify";
      }
      // If phone number is present but countryCode is missing
      if (!countryCode || countryCode.trim() === "") {
        newErrors.phone = "Country code is required,  select the country.";
      } else if (!/^\d{10,15}$/.test(phone)) {
        newErrors.contactPersonMobile = "A valid phone number is required."; // Validates phone number format
      }
    } else {
      // If phone is missing but countryCode is provided
      if (countryCode && countryCode.trim() !== "") {
        newErrors.contactPersonMobile = "Phone number is required .";
      } else {
        newErrors.contactPersonMobile = "A valid phone number is required."; // Generic phone validation
      }
    }
    if (verifybtn.phone===true && verifybtn.phone===true && !otp.phone) {
      newErrors.OTPphone = "Please enter the OTP";
    }
    // if (!logisticsVendor || logisticsVendor === "Select") {
    //   newErrors.logisticsVendor = "Please select a logistics vendor.";
    // }

    if (!contactPersonName || contactPersonName.trim() === "") {
      newErrors.contactPersonName = "Person name is required.";
    }

    if (!addressLine1 || addressLine1.trim() === "" ) {
        newErrors.addressLine1 = "Address is required.";
      }
      if (!addressLine1 || addressLine1.trim() === "" ) {
        newErrors.addressLine1 = "Address is required.";
      }
    if (!state || state.trim()=== "") {
      newErrors.state = textError;
    }
    if (!pinCode || String(pinCode).length > 7 ||!/^\d{6}$/.test(pinCode)) {
        newErrors.pinCode = "Number must be 6 digits.";
      }
    // if (!licenseNumber || licenseNumber.trim() === "") {
    //   newErrors.licenseNumber = "License number is required.";
    // }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (verifybtn.email === true && email){
      newErrors.email = "Please Validate ur email Click on verify";
    }
    if (verifybtn.email===true  && !otp.email) {
      newErrors.OTPemail = "Please enter the OTP";
    }
   console.log(newErrors,pinCode,pinCode.length,"newErrors")
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const resetForm = () => {
   setFromValue(props.selectedLogestic?._id === undefined? 
    initialState:{...props.selectedLogestic,
      phone: props.selectedLogestic.contactPersonMobile ?? "",
        email: props.selectedLogestic.contactPersonEmail ?? "",
    })
    setErrors({}); // Reset errors
    setEmail(props.selectedLogestic?._id === undefined?"":props.selectedLogestic.contactPersonEmail)
    setTaskAltIcons({
      email:false,
      phone:false
    })
    setOTP({
      phone:null,
      email:null
    })
    setVerify({
      phone: false,
      mail: false,
    })
    setverifybtn({ email: false, phone: false });
  };
  

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 400,
        // maxWidth:"100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-btween",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {
            props.setAddVendor(false);
            resetForm();
          }}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: "24px",
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          {props.selectedLogestic?._id === undefined
            ? "Add Vendor Master"
            : "Edit Vendor Master"}
        </Typography>


        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
       Country
        </Typography>
        <CustomTextField
      variant="outlined"
       select
    //   label="Select Country"
      value={fromValue.country||"Select Country"}
      onChange={handleCountryChange}
      fullWidth
      name="country"
      placeholder="Select Country"
      error={!!errors.country} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.country ? '#D34040' : undefined },
      }}

    >

        <MenuItem value="Select Country" disabled>
        Select Country
  </MenuItem>
      {contries.countries.map((country) => (
        // console.log(country.name,country.code)
        <MenuItem key={country.dial_code} value={country.name}>
          {country.name}
        </MenuItem>
      ))}
       
    </CustomTextField>
    {errors.country && (
          <Typography variant="body2" color="#D34040">
            {errors.country}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Logistics Vendor Legal Name
        </Typography>
        <CustomTextField
        //   select
          variant="outlined"
          fullWidth
          name="logisticVendorLegalName"
          onChange={handleChange}
          value={fromValue.logisticVendorLegalName} // Use empty string as fallback if value is not in the list
          // onChange={handleDropdownChange}
          placeholder="Logistics Vendor Legal Name"
          error={!!errors.logisticVendorLegalName} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.logisticVendorLegalName ? "#D34040" : undefined },
          }}
        >
         
        </CustomTextField>
        {errors.logisticVendorLegalName && (
          <Typography variant="body2" color="#D34040">
            {errors.logisticVendorLegalName}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
         Contact Person's Name
        </Typography>
        <CustomTextField
          variant="outlined"
          fullWidth
          name="contactPersonName"
           onChange={handleChange}
          value={fromValue.contactPersonName } // Use empty string as fallback if value is not in the list
          // onChange={handleDropdownChange}
          placeholder="Contact Person's Name"
          error={!!errors.contactPersonName} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.contactPersonName ? "#D34040" : undefined },
          }}
        >
         
        </CustomTextField>
        {errors.contactPersonName && (
          <Typography variant="body2" color="#D34040">
            {errors.contactPersonName}
          </Typography>
        )}

    

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
         Contact Person's Mobile Number
        </Typography>
        <Box
          sx={{
            display: `${TaskAltIcons.phone === true ? "flex" : "block"}`,
            width: `${TaskAltIcons.phone === true ? "105%" : "100%"}`,
          }}
        >
          <CustomTextField
            variant="outlined"
            value={fromValue.phone}
            onChange={handlePhoneChange}
            fullWidth
            name="phone"
            placeholder="Type Here"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "10px" }}>
                  <span style={{ marginLeft: "-3px" ,marginRight:"3px",textAlign:"center"}}>
                    {fromValue.countryCode}
                  </span>
                  <div
                    style={{
                      borderLeft: "1px solid #000", // Color of the vertical line
                      height: "24px", // Height of the line
                      marginRight: "8px", // Space between the line and the percentage symbol
                    }}
                  />
                </InputAdornment>
              ),
            }}
            error={!!errors.contactPersonMobile} // Show error styling if there's an error
            InputLabelProps={{
              style: { color: errors.contactPersonMobile ? "#D34040" : undefined },
            }}
          />
          {TaskAltIcons.phone === true && (
            <TaskAltIcon sx={{ color: "green", textAlign: "right" }} />
          )}
        </Box>
        {errors.contactPersonMobile && (
          <Typography variant="body2" color="#D34040">
            {errors.contactPersonMobile}
          </Typography>
        )}
            {verifybtn.phone && (  <Box onClick={()=>handleVerifyPhone(180)}  sx={{fontSize:"14px",color:"green",textDecoration:"underline",textAlign: "right",cursor:"pointer"}}>
  Verify
</Box>)}
{ verifybtn.phone===true && verify.phone &&(
  <Box>
<Typography>Enter OTP For Phone</Typography>
<CustomTextField
      variant="outlined"
      value={otp.phone}
      onChange={handleOTPVerifyPhone}
      fullWidth
      name="otpPhone"
      placeholder="Enter OTP"
      inputProps={{ maxLength: 4,  inputMode: "numeric", pattern: "[0-9]*"}}
      error={!!errors.phone} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.phone ? '#D34040' : undefined },
      }}
    />
     <Typography variant="body2" color="#D34040">
            {errors.OTPphone}
          </Typography>
     {timerPhone>0?( <Box sx={{textAlign:"right"}}> 
     {formatTime(timerPhone)}

   </Box>)
   :(<Box   sx={{fontSize:"14px",color:"green",textDecoration:"underline",textAlign: "right",cursor:"pointer"}}
    onClick={()=>handleResendPhone()}
    >
  Resend
</Box>)}
    </Box>)}

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Contact Person's Email Id
        </Typography>
        <Box sx={{display:`${TaskAltIcons.email===true?"flex":"block"}`,width:`${TaskAltIcons.email===true?"105%":"100%"}`}}>
          <CustomTextField
      variant="outlined"
      // value={fromValue.email}
      value = {selectedEmail}
      onChange={handleEmailChange}
      fullWidth
      name="email"
      placeholder="Enter Email"
      error={!!errors.email} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.email ? '#D34040' : undefined },
      }}

    />{errors.email && (
      <Typography variant="body2" color="#D34040">
        {errors.email}
      </Typography>
    )}

   {TaskAltIcons.email===true && (<TaskAltIcon sx={{color:"green",textAlign:"right"}}/>)} 

  {verifybtn.email&&(<Box   sx={{fontSize:"14px",color:"green",textDecoration:"underline",textAlign: "right",cursor:"pointer"}}
    onClick={()=>handleVerifyEmail(180)}
    >
  Verify
</Box>)}  
{ verifybtn.email===true && verify.email &&(
<Box>
<Typography>Enter OTP For Email</Typography>

<CustomTextField
      variant="outlined"
      value={otp.email}
      onChange={handleOTPVerify}
      fullWidth
      name="otpEmail"
      placeholder="Enter OTP"
      inputProps={{ maxLength: 4,  inputMode: "numeric", pattern: "[0-9]*"}}
      // error={!!errors.phone} // Show error styling if there's an error
           
      // InputLabelProps={{
      //   style: { color: errors.phone ? '#D34040' : undefined },
      // }}
    />
      <Typography variant="body2" color="#D34040">
            {verify.emailMsg}
          </Typography>
  {time>0?( <Box sx={{textAlign:"right"}}> 
     {formatTime(time)}

   </Box>)
   :(<Box   sx={{fontSize:"14px",color:"green",textDecoration:"underline",textAlign: "right",cursor:"pointer"}}
    onClick={()=>handleResend()}
    >
  Resend
</Box>)}
    </Box>)}
    </Box>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Address
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Address Line 1
        </Typography>
        <CustomTextField
          variant="outlined"
          value={fromValue.addressLine1}
          onChange={handleChange}
          fullWidth
          name="addressLine1"
          placeholder=" Address Line 1"
          error={!!errors.addressLine1} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.addressLine1 ? "#D34040" : undefined },
          }}
        />
        {errors.addressLine1 && (
          <Typography variant="body2" color="#D34040">
            {errors.addressLine1}
          </Typography>
        )}
         <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Address Line 2
        </Typography>
        <CustomTextField
          variant="outlined"
          value={fromValue.addressLine2}
          onChange={handleChange}
          fullWidth
          name="addressLine2"
          placeholder=" Address Line 2"
          error={!!errors.addressLine2} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.addressLine2 ? "#D34040" : undefined },
          }}
        />
        {/* drivering license */}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
         State
        </Typography>
        <CustomTextField
          variant="outlined"
          value={fromValue.state}
          onChange={handleChange}
          fullWidth
          name="state"
          placeholder="State"
          error={!!errors.state} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.state ? "#D34040" : undefined },
          }}
        />

        {errors.state && (
          <Typography variant="body2" color="#D34040">
            {errors.state}
          </Typography>
        )}
        
<Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
         Pin Code
        </Typography>
        <CustomTextField
          variant="outlined"
          value={fromValue.pinCode}
          onChange={handleChange}
          fullWidth
          name="pinCode"
          placeholder="Pin Code"
          error={!!errors.pinCode} // Show error styling if there's an error
          InputProps={{
            inputProps: {
              maxLength: 6, // Restrict the input to 6 digits
              inputMode: 'numeric', // Mobile-friendly input for numbers
              pattern: '[0-9]*', // Accept only numbers
            },
          }}
          InputLabelProps={{
            style: { color: errors.pinCode ? "#D34040" : undefined },
          }}
        />
          {errors.pinCode && (
          <Typography variant="body2" color="#D34040">
            {errors.pinCode}
          </Typography>
        )}
       
     
     
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0a3f73",
            textTransform: "none",
            marginTop: "10%",
          }}
          onClick={Save}
        >
          Submit
        </Button>
        {/* </Box> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addVendor}
        onClose={() => {
          props.setAddVendor(false);
          resetForm();
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddLogisticsVendorMaster;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "39px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
