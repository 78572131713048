import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import { ProgressContext } from "../ContextAPI";
import InputCard from "./InputCard";
import { numberError, percentageError, unitError } from "../../../utils/validationMessage";
import { validateNumber, validatePercentage } from "../../../utils/validateFields";

const EnergyManagement = () => {
  const navigate = useNavigate();
  const { setUpdate } = useContext(ProgressContext);

  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    totalEnergyConsumption: { value: "", unit: "" },
    renewableEnergyConsumption: { value: "" },
    energyIntensity: { value: "" },
    energyEfficiencyImprovements: { value: "" },
  });

  const [errors, setErrors] = useState({
    totalEnergyConsumption: { value: "", unit: "" },
    renewableEnergyConsumption: { value: "" },
    energyIntensity: { value: "" },
    energyEfficiencyImprovements: { value: "" },
  });

  const energyData = [
    {
      title: "Total Energy Consumption",
      placeholder: "1000",
      name: "totalEnergyConsumption",
      units: ["MJ", "GJ", "MWh", "GWh"],
    },
    {
      title: "Renewable Energy Consumption",
      placeholder: "% of total",
      name: "renewableEnergyConsumption",
      adornmentText: "%",
    },
    {
      title: "Energy Intensity",
      placeholder: "Energy per unit of output",
      name: "energyIntensity",
      adornmentText: "MWh per product unit",
    },
    {
      title: "Energy Efficiency Improvements",
      placeholder: "% reduction year over year",
      name: "energyEfficiencyImprovements",
      adornmentText: "%",
    },
  ];

  const Back = () => {
    navigate("/material-topics");
    setUpdate("MATERIAL");
  };

  const validateForm = () => {
    const newErrors = {
      totalEnergyConsumption: { value: "", unit: "" },
      renewableEnergyConsumption: { value: "" },
      energyIntensity: { value: "" },
      energyEfficiencyImprovements: { value: "" },
    };

    let isValid = true;

    if (!validateNumber(formData.totalEnergyConsumption.value)) {
      newErrors.totalEnergyConsumption.value = numberError;
      isValid = false;
    }
    if (formData.totalEnergyConsumption.unit === "") {
      newErrors.totalEnergyConsumption.unit = unitError;
      isValid = false;
    }
    if (!validatePercentage(formData.renewableEnergyConsumption.value)) {
      newErrors.renewableEnergyConsumption.value = percentageError;
      isValid = false;
    }
    if (!validateNumber(formData.energyIntensity.value)) {
      newErrors.energyIntensity.value = numberError;
      isValid = false;
    }
    if (!validatePercentage(formData.energyEfficiencyImprovements.value)) {
      newErrors.energyEfficiencyImprovements.value = percentageError;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    setUpdate("ESG");
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api}/environment/energy-management/${companyId}`);
        setFormData(response.data.energyData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, [setUpdate]);

  const Save = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/energy-management${isExist ? `/${companyId}` : ""}`,
        data: { ...formData, companyId },
      });
      // Optionally reset formData here after saving
      navigate("/environment/ghg-emissions");
    } catch (err) {
      console.error(err.response.data.message);
      setErrors(prevErrors => ({
        ...prevErrors,
        save: err.response.data.message // Set error message for display
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: {
        ...prevFormData[name],
        value:  value ,
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: { ...prevErrors[name], value: "" },
    }));
  };

  const handleUnitChange = (e, name) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        unit: value,
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: { ...prevErrors[name], unit: "" },
    }));
  };

  return (
    <Box
  sx={{
    display: "flex",
    backgroundColor: "#EDEDED",
    height: "100vh",
    overflow: "hidden", // Ensure no overflow on the main container
    scrollBehavior: "smooth", // Smooth scrolling for the main container
    "-webkit-overflow-scrolling": "touch", // For touchpad/mobiles
  }}
>
  <Sidebar title="Energy Management" />
  <Box
    sx={{
      p: 2,
      mt: 3,
      width: "100%",
      height: "100vh",
      overflowY: "scroll", // Ensure vertical scrollability
      overflowX: "hidden", // Prevent horizontal scrolling
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      scrollBehavior: "smooth", // Smooth scrolling
      overscrollBehavior: "none", // Disable touchpad overscroll
    }}
  >
        <Typography variant="h5" sx={{
          fontWeight: "bold",
          zIndex: 1,
          padding: "10px",
          borderRadius: "4px",
          alignSelf: "flex-start",
          ml: "5rem"
        }}>  
          ENVIRONMENT: ENERGY MANAGEMENT
        </Typography>
        <Box sx={{ mt: 3, width: "80%" }}>
          {energyData.map((item, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <InputCard
                title={item.title}
                placeholder={item.placeholder}
                name={item.name}
                value={formData[item.name].value}
                unitValue={formData[item.name].unit}
                handleChange={handleInputChange}
                handleUnitChange={handleUnitChange}
                units={item.units}
                borderColor={ errors[item.name].unit || errors[item.name].value ? "#D34040" : "inherit"}
                adornmentText={item.adornmentText}
                
              />
              {errors[item.name].value && (
                <Typography variant="body2" color="#D34040" sx={{ mt: 1 }}>
                  {errors[item.name].value}
                </Typography>
              )}
              {item.name === "totalEnergyConsumption" && errors.totalEnergyConsumption.unit && (
                <Typography variant="body2" color="#D34040" sx={{ mt: 1 }}>
                  {errors.totalEnergyConsumption.unit}
                </Typography>
              )}
            </Box>
          ))}
          <ActionButtons Back={Back} Move={Save} />
          {errors.save && (
            <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
              {errors.save}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EnergyManagement;
