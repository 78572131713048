import React from 'react'
import { api } from '../../../../utils/api'
import LogisticsVendorMaster from '../../Scope3/Transportation&Distribution/LogisticVendorMaster/LogisticsVendorMaster'
import { Box } from '@mui/material'

const Scope1LogisticsVendorMaster = () => {
    const url=`${api}/master/scope1`
  return (
    <LogisticsVendorMaster url={url}/>
      )
}

export default Scope1LogisticsVendorMaster