import React from "react";
import DashboardLayout from "../../../../Layout/DashboardLayout";
import RailTransport from "./RailTransport";
const index = () => {
  return (
    <DashboardLayout>
      <RailTransport />
    </DashboardLayout>
  );
};

export default index;
