import React, { useEffect, useState,useContext } from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './Cybersecurity.css';
import axios from 'axios';
import { api } from '../../../utils/api';
import { companyId } from "../../../utils/companyId";
import {unitError,listError,textError,percentageError, numberError} from "../../../utils/validationMessage";
import ActionButtons from '../InitialKickoff/MaterialTopics/ActionButtons';
import {ProgressContext} from "../ContextAPI";

const Cybersecurity = () => {
  const { setUpdate } = useContext(ProgressContext);
 const navigate = useNavigate();
 const[value,setValue]=useState([{
  high:'Number of Breaches',
  medium: 'Number of Breaches', 
  low:'Number of Breaches'}])
 const [isExist,setisExist] = useState(false); 
 const[formData,setFromData]=useState({
  companyId:companyId,
  cybersecurityIncidents:{
    high:"",
    medium:"",
    low:"",
    total:""
  },
  dataPrivacyBreaches:{
    high:"",
    medium:"",
    low:"",
    total:""
  },
  implementationCybersecurityMeasure:{
    radio:"yes",
    Scope:""
  }
 });
 const [errors, setErrors] = useState({});
 const handleChangeCybersecurity = (e)=>{
  console.log(e.target.name)
  const {name,value}=e.target
  setFromData({
    ...formData,
    implementationCybersecurityMeasure:{
      ...formData.implementationCybersecurityMeasure,
      [name]:value
    }
  })
 
  setErrors((prevErrors) => ({
    ...prevErrors,
    implementationCybersecurityMeasureScope: "",  // Clear the error for total field
  }));
 
 }
 const handleChangeDataPrivacy =(e)=>{
  const {name,value}=e.target
  // const numericValue = parseInt(value, 10) || 0;
  // const numericValue = value === "" ? "" : parseInt(value, 10);
  const numericValue = value === "" || isNaN(Number(value)) ? "" : Math.max(0, parseInt(value, 10)); 
  // Calculate the new total
  const newCybersecurityIncidents = {
    ...formData.dataPrivacyBreaches,
    [name]: numericValue,
  };

  newCybersecurityIncidents.total =
    (newCybersecurityIncidents.high) +
   ( newCybersecurityIncidents.medium)+
   ( newCybersecurityIncidents.low);

    setFromData({
    ...formData,
    dataPrivacyBreaches: newCybersecurityIncidents,
 });
 setErrors((prevErrors) => ({
  ...prevErrors,
  dataPrivacyBreachesTotal: "",  // Clear the error for total field
}));

 }
 const handleImplementation =(e)=>{
  console.log(e.target.name,e.target.value)
  const {name,value}=e.target
  // setFromData({
  //   ...formData,
  //   cybersecurityIncidents:{
  //     ...formData.cybersecurityIncidents,
  //    [name]:value
  //   }
  // })
  // const numericValue = parseInt(value, 10) || 0;
  const numericValue = value === "" || isNaN(Number(value)) ? "" : Math.max(0, parseInt(value, 10)); 
  // Calculate the new total
  const newCybersecurityIncidents = {
    ...formData.cybersecurityIncidents,
    [name]: numericValue,
  };

  newCybersecurityIncidents.total =
    (newCybersecurityIncidents.high) +
    (newCybersecurityIncidents.medium)+
    (newCybersecurityIncidents.low);

    setFromData({
    ...formData,
    cybersecurityIncidents: newCybersecurityIncidents,
 });
 setErrors((prevErrors) => ({
  ...prevErrors,
  cybersecurityIncidentsTotal: "",  // Clear the error for total field
}));
 }
 console.log("data",formData)
 const validateForm = () => {
  const newErrors = {};
  
  const { 
    cybersecurityIncidents,
    dataPrivacyBreaches,
    implementationCybersecurityMeasure
  } = formData;

  // Validate `cybersecurityIncidents`
  // ['total'].forEach((severity) => {
  //   if (isNaN(cybersecurityIncidents[severity]) || cybersecurityIncidents[severity] <= 0) {
  //     newErrors[`cybersecurityIncidents${severity.charAt(0).toUpperCase() + severity.slice(1)}`] = numberError;
  //   }
  // });
  const validateCybersecurityIncidents = () => {
    // Check for the 'total' field
    // if (isNaN(cybersecurityIncidents.total) || cybersecurityIncidents.total <= 0) {
    //   newErrors.cybersecurityIncidentsTotal = numberError;
    // }

    // Validate high, medium, low if they exist
    ['high', 'medium', 'low'].forEach((severity) => {
      if (isNaN(cybersecurityIncidents[severity]) || cybersecurityIncidents[severity] < 0|| cybersecurityIncidents[severity]==="") {
        newErrors[`cybersecurityIncidents${severity.charAt(0).toUpperCase() + severity.slice(1)}`] = numberError;
      }
    });
  };
  const validateDataPrivacyBreaches = () => {
    // Check for the 'total' field
    // if (isNaN(dataPrivacyBreaches.total) || dataPrivacyBreaches.total <= 0) {
    //   newErrors.dataPrivacyBreachesTotal = numberError;
    // }

    // Validate high, medium, low if they exist
    ['high', 'medium', 'low'].forEach((severity) => {
      if (isNaN(dataPrivacyBreaches[severity]) || dataPrivacyBreaches[severity] < 0 || dataPrivacyBreaches[severity]==="") {
        newErrors[`dataPrivacyBreaches${severity.charAt(0).toUpperCase() + severity.slice(1)}`] = numberError;
      }
    });
  };
  // if (isNaN(cybersecurityIncidents.total) || cybersecurityIncidents.total <= 0) {
  //   newErrors.cybersecurityIncidentsTotal = numberError;
  // }
  

  // Validate `dataPrivacyBreaches`

    // if (isNaN(dataPrivacyBreaches.total) || dataPrivacyBreaches.total <= 0) {
    //   newErrors.dataPrivacyBreachesTotal= numberError;
    // }

  // Validate `implementationCybersecurityMeasure`
  if (!implementationCybersecurityMeasure.Scope && implementationCybersecurityMeasure.radio === "yes" || implementationCybersecurityMeasure.Scope.trim() === "" && implementationCybersecurityMeasure.radio === "yes") {
    newErrors.implementationCybersecurityMeasureScope = textError;
  }
  validateCybersecurityIncidents();
  validateDataPrivacyBreaches();
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

useEffect(()=>{
  setUpdate("GOVERNANCE");
  // const companyId = companyId
  axios.get(`${api}/governance/cybersecurity-dataprivacy/${companyId}`).then((res)=>{
    console.log(res.data)
    setFromData({
      cybersecurityIncidents:{
        high:res.data.cybersecurityIncidents.high,
        medium:res.data.cybersecurityIncidents.medium,
        low:res.data.cybersecurityIncidents.low,
        total:res.data.cybersecurityIncidents.total
      },
      dataPrivacyBreaches:{
        high:res.data.dataPrivacyBreaches.high,
        medium:res.data.dataPrivacyBreaches.medium,
        low:res.data.dataPrivacyBreaches.low,
        total:res.data.dataPrivacyBreaches.total
      },
      implementationCybersecurityMeasure:{
        radio:res.data.implementationCybersecurityMeasure.radio===true?"yes":"no",
        Scope:res.data.implementationCybersecurityMeasure.Scope
      }
     })
     setisExist(true)
  }).catch((err)=>{
    console.log(err)
  })
},[])

console.log("isExist",isExist)
 const Save =()=>{
  if (!validateForm()) {
    return;
  }
  // const companyId = companyId
  axios({method: isExist ?'put':'post',
    url:`${api}/governance/cybersecurity-dataprivacy${isExist?`/${companyId}`:""}`,
    data:formData
  }).then((res)=>{
    console.log(res,res.data)
  // alert(res.data.message)
  }).catch((err)=>{
    console.log(err)
  // console.log(err)
  alert(err.message)
  })
 }
  const Back = () => {
    navigate('/governance/stakeholder');
  };

  return (
    <Box display="flex" sx={{backgroundColor: "#EDEDED",  display: "flex",
      backgroundColor: "#EDEDED",
      height: "100vh",
      overflow: "hidden"}}>
      <Sidebar title="Cybersecurity and data Privacy" />
      <Box width="100%" sx={{
          p: 2,
          mt: 3,
          mb:3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}>
        <Typography variant="h5" 
        sx={{
          fontWeight: "bold",
           mt: "2rem",
          zIndex: 1,
          padding: "10px",
          borderRadius: "4px",
          alignSelf: "flex-start",
          ml:"5rem"}}>
          GOVERNANCE: CYBERSECURITY AND DATA PRIVACY
        </Typography>

        <Box sx={{width:"80%"}}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }} className="heading">
            Cybersecurity Incidents
          </Typography>
          <Box sx={{ display: 'flex', width: '100%', gap: '50px', mb: 3 }}>
          <Box sx={{ display: 'flex',flexDirection: 'column', gap: 2,textTransform:"uppercase" }}>
              {value.map((severity, index) => (
                // console.log("inside map",severity,index,
              <>    {Object.keys(severity).map((item,i)=>{
                  //  {console.log("item",item,i)}
                  return  <TextField
                         key={i}
                        variant="outlined"
                        value={item}
                        // name={}
                        // onChange={handleChangeValue}
                        InputProps={{
                          readOnly: true,
                          
                          // disableUnderline: true,
                          sx: {
                            '& input':{
                            textAlign: 'start',
                            textTransform:'capitalize',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor:'none' ,
                            height: '26px', // Adjust height as needed
                           } },
                          '&:hover': {
                              backgroundColor: 'transparent',
                              border: 'none'
                            },
                            '&:focus': {
                              outline: 'none',
                              border: 'none'
                            }
                          // inputComponent: OutlinedInput,
                        }}
                        sx={{ textAlign: 'center',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          border:'1px solid #958f8f' ,
                          borderRadius:'5px',
                          // cursor:'default'
                           '& .MuiOutlinedInput-root': {
                             '& fieldset': {
                              border: 'none', // Remove the outline border when focused
                             },
                          },
                        }}
                      />
                  })
                  }  </>
                )
             

              )}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 2, width: '90%' }}>
              {value.map((placeholder, index) => (
                //  console.log(Object.values(placeholder),Object(placeholder))
                 <>
                 {
                  Object.keys(placeholder).map(i=>(
                   <> <TextField
                  key={i}
                  variant="outlined"
                  placeholder={placeholder[i]}
                   name={i}
                  value={formData.cybersecurityIncidents[i]!==undefined?formData.cybersecurityIncidents[i]:""} 
                  // className="inputfieldCustomer"
                  onChange={handleImplementation}
                  aria-label={placeholder}
                  fullWidth
                  InputProps={{
                    style: { borderColor: errors[`cybersecurityIncidents${i.charAt(0).toUpperCase() + i.slice(1)}`] ? '#D34040' : undefined },
                  }}
                  sx={{ marginRight: "5px", width: "100%" }}
                  error={!!errors[`cybersecurityIncidents${i.charAt(0).toUpperCase() + i.slice(1)}`]} // Check error state for total
                  InputLabelProps={{
                    style: { color: errors[`cybersecurityIncidents${i.charAt(0).toUpperCase() + i.slice(1)}`] ? '#D34040' : undefined },
                  }}
                />
                 {errors[`cybersecurityIncidents${i.charAt(0).toUpperCase() + i.slice(1)}`] && (
                    <Typography variant="body2" color="#D34040">
                      {errors[`cybersecurityIncidents${i.charAt(0).toUpperCase() + i.slice(1)}`]}
                    </Typography>
                  )}
                  </>
                  ))
                 
                 }
             
                 
                <TextField
                  key={index}
                  variant="outlined"
                  placeholder='Total Breaches'
                  value={formData.cybersecurityIncidents.total===""?"":formData.cybersecurityIncidents.total}
                  // className="inputfieldCustomer"
                  // onChange={handleChangeValue}
                  aria-label={placeholder}
                  fullWidth
                  InputProps={{
                    style: { borderColor:errors.cybersecurityIncidentsTotal? '#D34040' : undefined},
                  }}
                   sx={{marginRight:"5px",width:"100%"}}
                  error={!!errors.cybersecurityIncidentsTotal} // Show error styling if there's an error
                  InputLabelProps={{
                    style: { color: errors.cybersecurityIncidentsTotal ? '#D34040' : undefined },
                  }}
                />
                 {errors.cybersecurityIncidentsTotal && (
      <Typography variant="body2" color="#D34040">
        {errors.cybersecurityIncidentsTotal}
      </Typography>
    )}
                </>
              ))}
      
            </Box>
          </Box>
        </Box>

        <Box sx={{width:"80%"}}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }} className="heading">
            Data Privacy Breaches
          </Typography>
          <Box sx={{ display: 'flex', width: '100%', gap: '50px', mb: 3 }}>
              <Box sx={{ display: 'flex',flexDirection: 'column', gap: 2,  }}>
           
                {value.map((severity, index) => (
                // console.log("inside map",severity,index,
              <>    {Object.keys(severity).map((item,i)=>{
                  //  {console.log("item",item,i)}
                  return <> <TextField
                         key={i}
                        // variant="outlined"
                        value={item}
                        // name={i}
                        // onChange={handleChangeValue}
                        InputProps={{
                          readOnly: true,
                          
                          // disableUnderline: true,
                          sx: {'& input':{
                            textAlign: 'start',
                            fontWeight: 'bold',
                            textTransform:"capitalize",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor:'none' ,
                            height: '26px',
                            border: 'none', // Adjust height as needed
                          }},
                          '&:hover': {
                              backgroundColor: 'transparent',
                              border: 'none'
                            },
                            '&:focus': {
                              outline: 'none',
                              border: 'none'
                            },
                            '&.Mui-focused': {
                           outline: 'none',
                          border: 'none',
                         boxShadow: 'none', // Ensure focus state doesn't show
      }
                          // inputComponent: OutlinedInput,
                        }}
                        sx={{ textAlign: 'center',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          border:'1px solid #958f8f' ,
                          borderRadius:'5px',
                          // cursor:'default'
                           '& .MuiOutlinedInput-root': {
                             '& fieldset': {
                              border: 'none', // Remove the outline border when focused
                             },
                          },
                        }}
                      />
                    
                      </>
                  })
                  }  </>
                ))}
            </Box>


            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 2, width: '80%' }}>
            {value.map((placeholder, index) => (
                //  console.log(Object.values(placeholder),Object(placeholder))
                 <>
                 {
                  Object.keys(placeholder).map(i=>(
                  <>  <TextField
                  key={i}
                  variant="outlined"
                  placeholder={placeholder[i]}
                   name={i}
                   value={formData.dataPrivacyBreaches[i] !== undefined ? formData.dataPrivacyBreaches[i] : ""}

                  // value={formData.dataPrivacyBreaches[i]===0?"":formData.dataPrivacyBreaches[i]} 
                  // className="inputfieldCustomer"
                  onChange={handleChangeDataPrivacy}
                  aria-label={placeholder}
                  fullWidth
                  InputProps={{
                    style: { borderColor: errors[`dataPrivacyBreaches${i
                        ?.charAt(0).toUpperCase() + i?.slice(1)
                    }`] ? '#D34040' : undefined },
                  }}
                 
                  error={!!errors[`dataPrivacyBreaches${i
                    ?.charAt(0).toUpperCase() + i?.slice(1)
                  }`]} // Check error state for total
                  InputLabelProps={{
                    style: { color: errors[`dataPrivacyBreaches${i
                       ?.charAt(0).toUpperCase() + i?.slice(1)
                    }`] ? '#D34040' : undefined },
                   }}
                 
                />
                {errors[`dataPrivacyBreaches${i
                    ?.charAt(0).toUpperCase() + i?.slice(1)
                }`] && (
                    <Typography variant="body2" color="#D34040">
                      {errors[`dataPrivacyBreaches${i
                          ?.charAt(0).toUpperCase() + i?.slice(1)
                        }`]}
                    </Typography>
                  )}</>
                  ))
                 }
                 
                <TextField
                  key={index}
                  variant="outlined"
                  placeholder='Total Breaches'
                  value={formData.dataPrivacyBreaches.total===""?"":formData.dataPrivacyBreaches.total}
                  // className="inputfieldCustomer"
                  // onChange={handleChangeValue}
                  aria-label={placeholder}
                  fullWidth
                  InputProps={{
                    style: { borderColor:errors.dataPrivacyBreachesTotal? '#D34040' : undefined},
                  }}
                   sx={{marginRight:"5px",width:"100%"}}
                  error={!!errors.dataPrivacyBreachesTotal} // Show error styling if there's an error
                  InputLabelProps={{
                    style: { color: errors.dataPrivacyBreachesTotal ? '#D34040' : undefined },
                  }}
                />
                 {errors.dataPrivacyBreachesTotal && (
      <Typography variant="body2" color="#D34040">
        {errors.dataPrivacyBreachesTotal}
      </Typography>
      
    )}
                </>
              ))}
            </Box>
          </Box>
        </Box>

        <Box sx={{width:"80%"}} >
          <Typography variant="subtitle1"
           sx={{ fontWeight: 'bold', mb: 2 }}>
            Implementation of Cybersecurity Measure
          </Typography>
          <RadioGroup row  
          name="radio"
          value={formData.implementationCybersecurityMeasure.radio} 
          onChange={handleChangeCybersecurity}
          sx={{ mb: 2 }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
       {formData.implementationCybersecurityMeasure.radio==="yes"? 
       <Box>
       <TextField
            variant="outlined"
            placeholder="Scope..."
            // className="brief"
            name='Scope'
            value={formData.implementationCybersecurityMeasure.Scope}
            onChange={handleChangeCybersecurity}
            multiline
            rows={3}
            // sx={{ width: '100%' }}
            InputProps={{
              style: { borderColor:errors.implementationCybersecurityMeasureScope? '#D34040' : undefined},
            }}
             sx={{marginRight:"5px",width:"100%"}}
            error={!!errors.implementationCybersecurityMeasureScope} // Show error styling if there's an error
            InputLabelProps={{
              style: { color: errors.implementationCybersecurityMeasureScope ? '#D34040' : undefined },
            }}
          />
          {errors.implementationCybersecurityMeasureScope && (
            <Typography variant="body2" color="#D34040">
              {errors.implementationCybersecurityMeasureScope}
            </Typography>
          )}
          </Box>
          :""}
          <ActionButtons Back={Back} Move={Save}/>
        </Box>

        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4, width: '80%' }}>
          <Button
            variant="outlined"
            sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}}
            onClick={Back}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{backgroundColor:"#0a3f73",textTransform:"none"}}
            onClick={Save}
            endIcon={<ArrowForwardIcon />}
          >
            Save and continue
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Cybersecurity;
