import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddEquipment from "./AddEquipmet";
import DocumentItem from "../../../../../Components/Masters/DocumentItem";

function ViewEquipment(props) {
  const [edit, setEdit] = useState(false);
  const [valueEdit, setValueEdit] = useState(false);
  const [addEquipment, setAddEquipment] = useState(false);
  useEffect(() => {
    console.log(props.selectedEquipment,"selectedEquipment")
  }, [props]);

  const handleDownload = (url) => {
    const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/"); // Force download as an attachment
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const Edit = () => {
    setAddEquipment(true);
  };


  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 420,
        height: '100vh',
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "transparent",
      }}
    >
      <AddEquipment
        addEquipment={addEquipment}
        setAddEquipment={setAddEquipment}
        selectedEquipment={props.selectedEquipment}
      />
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setViewEquipment(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: '24px',
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Typography
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Electricity Consumption Details
        </Typography>
        <Box display="grid" gap={3} sx={{ gridTemplateColumns: '1fr 1fr', mb: 2 }}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Date
            </Typography>
            <p>
              {new Date(props.selectedEquipment?.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) ?? ""}
            </p>
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Equipment Name
            </Typography>
            <p>{props.selectedEquipment?.equipment ?? ""}</p>
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Brand:
            </Typography>
            <p>{props.selectedEquipment?.brand ?? ""}</p>
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Model:
            </Typography>
            <p>{props.selectedEquipment?.model ?? ""}</p>
          </Box>
        </Box>
  
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
            Rated Electricity Consumption/Hour
          </Typography>
          <p>{props.selectedEquipment?.ratedConsumption ?? ""}</p>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
            Unit of Electricity
          </Typography>
          <p>{props.selectedEquipment?.unit ?? ""}</p>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
            Remark
          </Typography>
          <p>{props.selectedEquipment?.remark ? props.selectedEquipment.remark : "-"}</p>
        </Box>
        
        {/* <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Documents
        </Typography>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 2 }}>
          {props.selectedEquipment?.documents?.map((doc, index) => (
            <Box key={index} sx={{ position: "relative" }}>
              <img
                src={doc}
                alt={`Document ${index + 1}`}
                style={{
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
              <IconButton
                onClick={() => handleDownload(doc)}
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  color: "white",
                  backgroundColor: "rgba(0,0,0,0.5)",
                }}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
          ))}
        </Box> */}
         <Box sx={{mt:2}}>
        <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold" }}
                  >
                    Documents
                  </Typography>

                  
                  <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{ width: "100%" }}
          >
            {/* Render Images First */}
            {props.selectedEquipment && props.selectedEquipment?.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedEquipment?.documents
                  .filter((url) =>
                    ["png", "jpg", "jpeg"].includes(
                      url.split(".").pop().toLowerCase()
                    )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`image-${index}`} url={url} />
                  ))}
              </Box>
            )}

            {/* Render Other Documents */}
            {props.selectedEquipment && props.selectedEquipment?.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedEquipment?.documents
                  .filter(
                    (url) =>
                      !["png", "jpg", "jpeg"].includes(
                        url.split(".").pop().toLowerCase()
                      )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`file-${index}`} url={url} />
                  ))}
              </Box>
            )}
          </Box>
        </Box>
  
        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={props.handleEdit}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );
  

  

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.viewEquipment}
        onClose={() => props.setViewEquipment(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default ViewEquipment;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
