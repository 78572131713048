import { Card, CardContent, Typography, Box } from '@mui/material'
import React from 'react'

const Cards = ({title,content, contentSpacing = "20px",contentMarginTop = "20%"}) => {
  return (
    <Card sx={{ width: "30%",height:"140px",borderRadius:"10px" }}>
    <CardContent sx={{ paddingBottom: "2px !important",display:"flex",flexDirection:"column",justifyContent:"spa" }}>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
       {title}
      </Typography>
      {/* <Typography  sx={{marginTop: "20%"}}>{content}</Typography> */}
      <Box sx={{ display: "flex", gap: contentSpacing, }}>
          {/* Check if content is an array */}
          {Array.isArray(content) ? (
            content.map((item, index) => (
              <Typography key={index} sx={{marginTop: contentMarginTop}} variant="body1">
                {item}
              </Typography>
            ))
          ) : (
            <Typography variant="body1" sx={{marginTop: "20%"}}>{content}</Typography>
          )}
        </Box>
    </CardContent>
  </Card>
  )
}

export default Cards





