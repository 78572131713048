import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PaginationItem,
  Drawer,
  Alert,
  Snackbar,
  DialogTitle
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
 import AddVendorMaster from "./AddVendorMaster";
//  import ViewVendorMaster from "./ViewVendorMaster";
import DateRangePickerComponent from "../../../Roles and Permissions/Filters/Date";
import { api } from "../../../../../utils/api";
import FilterSidebar from "../../../../../Components/Masters/Filter/FilterSidebar";

import ApplyButton from "../../../Roles and Permissions/Filters/ApplyButton";
import ResetButton from "../../../Roles and Permissions/Filters/ResetButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from '@mui/material/styles';
 import ViewVendor from "./ViewVendorMaster";
import PaginationBox from "../../../../../Components/Masters/Pagination";
import NoDataFound from "../../../../../Components/Masters/NoDataFound";

const VendorMaster = () => {
  
  const [vendors, setVendors] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addVendor, setAddVendor] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
  });
  const [viewVendor, setViewVendor] = useState(false);
  const [dataView, setdataView] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen,setsnackOpen]= useState({open:false,message:"",severity:""}); 
  const[errors, setErrors] = useState({
    Date: false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const filters = ["Date"];

  const facilityData = [
    { header: "Date", field: "createdAt" },
    // { header: "Facility Name", field: "facilityName" },
    { header: "Brand", field: "brand" },
    // { header: "Outsource Facility", field: "outsourceFacility" },
    { header: "Country", field: "country" },
    // { header: "Action" },
  ];
  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/master/Electricity/getVendor`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/master/Electricity/getVendor`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
    }
  };
  const handleMenuClick = (event, vendor) => {
    setAnchorEl(event.currentTarget);
    setSelectedVendor(vendor);
    setdataView(vendor);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedVendor(null);
  };

  const handleEdit = () => {
    console.log("Edit vendor:", selectedVendor);
    handleMenuClose();
    setAddVendor(true);
    setViewVendor(false);
  };

  const handleView = () => {
    console.log("View vendor:", selectedVendor);
    handleMenuClose();
    setViewVendor(true);
  };

  const handleDelete = () => {
    console.log("Delete:", selectedVendor);
    handleMenuClose();
    setDeleteDialog(true);
  };

 
  const handleDeleteAggree = () => {
    console.log("Deleting:", dataView, dataView._id);
    axios.delete(`${api}/master/Electricity/DeleteVendor`, {
      params: { Id: dataView._id },
      data: { deactivated: true },
    })
      .then((res) => {
        console.log("Delete Response:", res);
        setDeleteDialog(false);
        setsnackOpen({ open: true, message: res.data.message, severity: "success" });
  
        // Update vendors state after deletion
        const updatedVendors = vendors.filter((item) => item._id !== res.data.data._id);
        setVendors(updatedVendors);
  
        // Check if we need to load more data to maintain `rowsPerPage`
        if (updatedVendors.length < rowsPerPage && page < totalPages) {
          fetchSteamVendor();
        }
      })
      .catch((err) => {
        console.error("Error deleting vendor:", err);
        setsnackOpen({ open: true, message: err.response?.data?.message ?? "Network Error", severity: "error" });
      });
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  
// fetching  data
  // const fetchSteamVendor = async (filters = filtersToApply) => {
    const fetchSteamVendor = async (filters = isFiltersApplied?filtersToApply:{}) => {
    try {
      console.log(sortConfig);
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      console.log("Query Params sent to API:", queryParams, filters); // Debugging line
      const response = await axios.get(
        `${api}/master/Electricity/getVendor?${queryParams}`
      );

      console.log("Fetched data after filtering:", response.data); // Debugging line
      setVendors(response.data.data); // Update the user list
      setTotalPages(response.data.totalPages); // Update total pages for pagination
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchSteamVendor();
  }, [page, rowsPerPage, sortConfig]);

  // default filters to apply
  const filtersToApply = {
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
  };



  const validateFilters = () => {
    let isValid = true;
    const newErrors = { Date: ""};
    if (selectedFilter === "Date") {
      if (startDate > endDate) {
        console.log(startDate, endDate, Date.now());
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
      if (!startDate && !endDate) {
        newErrors.Date = "Please select a date range";
        isValid = false;
      }
    }

    

    console.log(newErrors)
  
  setErrors(newErrors)
    return isValid;
  }

  // Applying selected filters
  const handleApplyFilters = () => {
    if(!validateFilters())
      return;
    
    // fetchSteamVendor();
    fetchSteamVendor({
      ...filtersToApply, // All the previously selected filters
    
    });
    setPage(1);
    setDrawerFilterOpen(false);
    handleAppliedFilters();
  };

  

  
  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setAppliedFilters({});
    setIsFiltersApplied(false);
    fetchSteamVendor({});
    setErrors({});
    setDrawerFilterOpen(false);
  };

  
  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate ? true : false;

    setIsFiltersApplied(
      isDateApplied 
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const getManualWidth = (header) => {
    switch (header) {
      case 'Date':
        return '12%';
      case 'FacilityName':
        return '22%';
      case 'Incharge':
        return '20%';
      case 'Outsource Facility':
        return '15%';
      case 'Country':
        return '21%';
      case 'Action':
        return '10%';
      default:
        return 'auto'; // default width if header doesn't match
    }
  };

  const handleFilterDrawer=()=>{
    setDrawerFilterOpen(false);
    setErrors({Date:false,Role:false});
  }
  
  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        
      }}
    >
       <Snackbar open={snackOpen.open} autoHideDuration={3000}
         anchorOrigin={{ vertical:"top", horizontal:"center" }}
          onClose={()=>setsnackOpen({...snackOpen,open:false})}

         >
        <Alert
          // onClose={handleClose}
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackOpen.message}
          {/* Add user Successfully */}
        </Alert>
      </Snackbar>
      <AddVendorMaster
       addVendor={addVendor}
       setAddVendor={setAddVendor}
       fetchSteamVendor={fetchSteamVendor}
       setsnackOpen={setsnackOpen}
      //  addFacility={addFacility}
      selectedVendor={dataView}
      />
      
      <ViewVendor
        viewVendor={viewVendor}
        setViewVendor={setViewVendor}
        selectedVendor={dataView}
        handleEdit={handleEdit}
      />
  
      {deleteDialog && (
        <Dialog open={deleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Do you really want to delete this entry?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteAggree} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <Drawer
            anchor="right"
            open={drawerFilterOpen}
            onClose={() => handleFilterDrawer()}
            sx={{
              backgroundColor: "transparent",
              "& .MuiDrawer-paper": {
                // This targets the paper component within the Drawer
                backgroundColor: "transparent", // Make paper transparent
                boxShadow: "none", // Optional: Remove any shadow
                width: "50%",
              },
            }}
          >
            <Box
              display="flex"
              gap={3}
              sx={{
                width: "100%",
                display: "flex",
                overflowX: "hidden",
                maxWidth: "100%",
                justifyContent: "space-btween",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  height: "3%",
                  padding: "13px",
                  margin: "30px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "80%",
                  width: "3%",
                }}
              >
                <IconButton
                  onClick={() => setDrawerFilterOpen(false)}
                  sx={{ color: "black" }}
                >
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
                {/* Sidebar for selecting the filter */}
                <FilterSidebar
                  {...{
                    setSelectedFilter,
                    selectedFilter,
                    filters,
                    appliedFilters,
                  }}
                />

                {/* Filter content area */}
                <Box
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#F9F9F9",
                    padding: "20px",
                  }}
                >
                  {renderSelectedFilter()}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: "3%",
                      position: "fixed",
                      bottom: "20px",
                      ml: 3,
                      mr: 3,
                    }}
                  >
                    <ApplyButton handleApply={handleApplyFilters} />
                    <ResetButton handleReset={handleResetFilters} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}{" "}
        <Grid
          container
          alignItems="center"
          sx={{ width: "80%", margin: "5% 0.5% 0% 22%" }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "8px 0px 20px 0px", textDecoration: "underline" }}
            >
              Electricity Master
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              // endIcon={<FilterListIcon />}
              sx={{
                margin: "10px 10px -15px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                "&:hover .dot-icon": {
                  color: "#FFFFFF", // Change dot color on button hover
                },
                // display:"flex"
              }}
            >
              Filter
              <FilterListIcon />
              {/* {( startDate ||
                endDate) && (
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              )} */}
              {isFiltersApplied && (
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              )}
            </Button>

            <Button
              variant="contained"
              endIcon={<AddIcon />}
              sx={{
                margin: "10px -36px -15px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              onClick={() => {
                setAddVendor(true);
                 setdataView(null);
              }}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Toolbar>

      

        <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      > 
      <Box>
      <Box sx={{ margin: '16px' }}>
   
         <Box 
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          textAlign: "left",
          // padding: "0px",
          }}
          >Electricity Vendor Master</Box>
       </Box>
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "12% 78% 10%",
      borderBottom: "1px solid #ddd",
      borderTop: "1px solid #ddd",
    }}
  >
    <Typography sx={{ fontWeight: "bold", padding: "10px 12px 10px 18px ", textAlign: "left", borderRight: "1px solid #ddd", display: "flex",  
         flexDirection: "row" }}>
      Date
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("createdAt", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "createdAt" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("createdAt","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "createdAt" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "10px 12px 10px 12px", textAlign: "left", borderRight: "1px solid #ddd",display: "flex",  
         flexDirection: "row" }}>
     Vendor Legal Full Name
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("VendorLegalFullName", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "VendorLegalFullName" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("VendorLegalFullName","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "VendorLegalFullName" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>

    <Typography sx={{ fontWeight: "bold", padding: "10px 12px 10px 18px",  textAlign: "left", borderRight: "1px solid #ddd", display: "flex", flexDirection: "row" }}>
     Action
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      </Box>
    </Typography>
  </Box>
  {vendors.length === 0 ? (
      <NoDataFound />
    ) : (

  vendors.map((vendors) => (
    <Box
      key={vendors._id}
      sx={{
        display: "grid",
        gridTemplateColumns: "12%  78% 10%",
        borderBottom: "1px solid #ddd",
      }}
    >
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "center",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // margin: "7px 0px -7px 0px",
          fontSize: "14px",
          color: "black"
        }}
      >
        {/* {new Date(vendors.createdAt).toLocaleDateString()} */}
        {new Date(vendors.createdAt).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 10px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -7px 0px",
        }}
      >
        {vendors.VendorLegalFullName}
      </Typography>
      
      
      <Box
        sx={{
          padding: "1px",
          textAlign: "center",
        }}
      >
        <IconButton onClick={(e) => handleMenuClick(e, vendors)}>
          <MoreHorizIcon sx={{ fontWeight: "bold", color: "black" }} />
        </IconButton>
      </Box>
    </Box>
  )))}
</Box>




       
</TableContainer>



      {/* </TableContainer> */}

      {/* Pagination */}
      {totalPages>1 && (
      <PaginationBox
          {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
        />
      )}
      {/* Menu for Actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>
        
        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default VendorMaster;
