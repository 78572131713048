import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../sidebar";
import { useNavigate } from "react-router-dom";
import "./Sustainability.css";
import axios from "axios";
import { api } from "../../../utils/api";
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {
  Grid,
  Container,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Button,
   Chip, IconButton 
} from "@mui/material";
import { ProgressContext } from "../ContextAPI";
import { DataArraySharp } from "@mui/icons-material";
import ActionButton from './MaterialTopics/ActionButtons';

const Sustainability = () => {
  const {companyId}=localStorage.getItem("companyId");
  const { setTriggerGetRequest } = useContext(ProgressContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    implementing: "",
    brief1: "",
    brief2: "",
    brief3: "",
    primaryReasons: [],
    customerDemand: "",
    regulatoryCompliance: "",
    other: "",
    reportingStandardsRadio:"yes",
    governmentRegulations: "",
    reportingStandards: [],
    sustainabilityOverview: '',
    progressMeasurement: '',
    // companyId:companyId,
  });
  const [paragraph,SetPragraph] = useState([]);
  const [currentInput, setCurrentInput] = useState('');
  const [ExistingData,setexistingData]=useState(false);
const [radio,SetRadio]=useState("yes");
const [reportingStandardsRadios,setreportingStandardsRadio]=useState({
  reportingStandardsRadio:"yes",
  regulatoryComplianceRadio:"yes",
  customerDemandRadio:"yes",
  governmentRegulationRadios:"yes"
});
const [errors, setErrors] = useState({});
console.log("companyId",companyId)

  useEffect( () => {
   const companyId = localStorage.getItem('companyId')
    // if (storedCompanyId) {
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     comany_id: storedCompanyId,
    //   }));
    // }
    // Define the async function inside the effect
    const fetchData = async () => {
    if (companyId) {
      // console.log("storedCompanyId",storedCompanyId)
      const response = axios
        .get(`${api}/api/sustainabilityById/${companyId}`)
        .then(async (data) => {
          console.log("dataof", data.data, data.data.reportingStandards);
          await setFormData({
            implementing:
              data.data.sustainabilityInitiatives.implementing || "",
            brief1: data.data.sustainabilityInitiatives.brief || "",
            brief2: data.data.motivationForIncorporation || "",
            brief3: data.data.sustainabilityGoals || "",
            primaryReasons: data.data.primaryReasons || [],
            customerDemand: data.data.customerDemand || "",
            regulatoryCompliance: data.data.regulatoryCompliance || "",
            other: data.data.other || "",
            governmentRegulations: data.data.governmentRegulations || "",
            reportingStandards: data.data.reportingStandards || [],
            sustainabilityOverview: data.data.sustainabilityOverview || "",
            progressMeasurement: data.data.progressMeasurement || "",
          });

          await (SetPragraph(data.data.reportingStandards),
          setexistingData(true),
          SetRadio(
            data.data.sustainabilityInitiatives.implementing ? "yes" : "no"
          ));
          setreportingStandardsRadio ({
            ...reportingStandardsRadios,
            governmentRegulationRadios:data.data.governmentRegulationsRadio,
            customerDemandRadio:data.data.customerDemandRadio
          })
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    };
   
    // Call the async function
    fetchData();
    // Cleanup function (if needed)
    // return () => {
    //   // Cleanup logic here (if necessary)
    // };
  }, []);

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setCurrentInput(event.target.value);
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log("nameerrors",name, setErrors({ ...errors, name: undefined }))
    // setCurrentInput(e.target.value)
    setErrors({ ...errors, [name]: undefined });
  };
  const handleChangeRadio = (e) => {
    const { name, value } = e.target;
    // SetData(e.target.value)
    console.log("radiobutton", name, value);
    // setFormData({
    //   ...formData,
    //   [name]: value,
    // });
    SetRadio(e.target.value);
    console.log("for the field", e.target.value);
  };
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    console.log("data of checked", e.target, checked, value);
    setFormData((prevState) => {
      const newPrimaryReasons = checked
        ? [...prevState.primaryReasons, value]
        : prevState.primaryReasons.filter((reason) => reason !== value);
      return {
        ...prevState,
        primaryReasons: newPrimaryReasons,
      };
    });
    setErrors({
      ...errors,
      primaryReasons:""
    })
  };

 
  // const validateForm = () => {
  //   const newErrors = {};
  //   let isValid = true;

  //   for (const key in formData) {
  //     if (!formData[key].trim()) {
  //       newErrors[key] = 'This field is required.';
  //       isValid = false;
  //     }
  //   }

  //   setErrors(newErrors);
  //   return isValid;
  // };


  const validateForm=() => {
    const newErrors = {};

    const { 
      // implementing,
       brief1, brief2, brief3, 
      customerDemand,
       regulatoryCompliance, 
      other,
      primaryReasons,
      reportingStandards, 
      governmentRegulations, 
      sustainabilityOverview,
       progressMeasurement } = formData;

    // if (!implementing) {
    //   newErrors.implementing = "This field is required";
    // }
    if (formData.primaryReasons.length === 0) {
      // alert("please select atleast one option");
      newErrors.primaryReasons = "please select at least one option";
  }
    if (radio === "yes" && !brief1) {
      newErrors.brief1 = "This field is required";
    }
    
    if (!brief2) {
      newErrors.brief2 = "This field is required";
    }
    
    if (!brief3) {
      newErrors.brief3 = "This field is required";
    }
    
    if ( visible.includes("Customer Demand") && reportingStandardsRadios.customerDemandRadio==="yes"&&!customerDemand) {
      newErrors.customerDemand = "This field is required";
    }
    
    // if (visible==="Regulatory Compliance" && reportingStandardsRadios.regulatoryComplianceRadio === "yes" && !regulatoryCompliance) {
    //   newErrors.regulatoryCompliance = "This field is required";
    // }
  
    if (visible.includes("Regulatory Compliance") && reportingStandardsRadios.regulatoryComplianceRadio === "yes") {
      console.log(`======visible`,visible === "Regulatory Compliance" && reportingStandardsRadios.regulatoryComplianceRadio === "yes")
      if (!regulatoryCompliance || regulatoryCompliance.trim() === "") {
          newErrors.regulatoryCompliance = "This field is required";
      }
  }

    
    if (visible.includes("Other") && !other) {
      newErrors.other = "This field is required";
    }
    // if (reportingStandardsRadios.reportingStandardsRadio==="yes" && !reportingStandards) {
    //   const trimmedInput = reportingStandards.trim();
      
    //   // If the input is empty or whitespace, show an error message
    //   if (trimmedInput === '') {
    //     // setErrors((prev) => ({ ...prev, reportingStandards: 'This field is required' }));
    //     newErrors.reportingStandards = "This field is required";
    //     return;
    //   }
    //   newErrors.reportingStandards = "This field is required";
      
    // }
    if (reportingStandardsRadios.reportingStandardsRadio === "yes") {
      // Check if reportingStandards is an array and convert it to a string, or handle it as needed
      const trimmedInput = paragraph
        // If it's not an array, handle as a string
    
      // If the input is empty or whitespace, show an error message
      if (paragraph.length<=0) {
        newErrors.reportingStandards = "This field is required";
      } 
    }
    
    

    if ( reportingStandardsRadios.governmentRegulationRadios === "yes" && visible.includes("Leadership in Sustainability")&&!governmentRegulations) {
      newErrors.governmentRegulations = "This field is required";
    }
    
    if (!sustainabilityOverview) {
      newErrors.sustainabilityOverview = "This field is required";
    }

    if (!progressMeasurement) {
      newErrors.progressMeasurement = "This field is required";
    }
    console.log(
      "newErrors",newErrors
    )

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};
console.log("errors",errors)

  const Save = async () => {
    console.log("validateForm()",validateForm(),errors)
    if (!validateForm()) {
      return;
    }
// console.log("reportingStandards",reportingStandards)
     const companyId = localStorage.getItem('companyId');
    try {
      const payload = {
        sustainabilityInitiatives: {
          implementing: radio === "yes" ? true : false,
          brief: formData.brief1,
        },
        motivationForIncorporation: formData.brief2,
        sustainabilityGoals: formData.brief3,
        primaryReasons: formData.primaryReasons,
        governmentRegulations: formData.governmentRegulations,
        governmentRegulationsRadio: reportingStandardsRadios.governmentRegulationRadios,
        reportingStandards:paragraph,
        customerDemandRadio:reportingStandardsRadios.customerDemandRadio,
        reportingStandardsRadios:reportingStandardsRadios.reportingStandardsRadio,
        sustainabilityOverview: formData.sustainabilityOverview,
        progressMeasurement: formData.progressMeasurement,
        companyId: companyId,
        regulatoryCompliance: formData.regulatoryCompliance,
        customerDemand: formData.customerDemand,
        other: formData.other,
        // company_id:companyId
      };
      console.log("ExistingData", ExistingData);
      await (ExistingData
        ? axios.put(`${api}/api/sustainabilityById/${companyId}`, payload)
        : axios.post(`${api}/api/sustainability`, payload));
      console.log("Data saved successfully");
      // alert("Data saved successfully");
      navigate("/challenges-concerns");
    } catch (error) {
      console.error("Error saving data:", error);
      // alert("Please fill all the fields");
    }
  };
  //  const handleAddReportingStandard = (event)=>{
  //   console.log("paragraph====",)
  //   event.preventDefault();
  //   if (event.key === 'Enter') {
  //    // Prevent form submission or newline in the input
  //     // setFormData((prevData) => ({
  //     //   ...prevData,
  //     //   reportingStandards: [...prevData.reportingStandards, prevData.reportingStandardsInput],
  //     //   reportingStandardsInput: '' // Clear input after adding
  //     // }));
  //     let update = currentInput.trim()
  //     console.log("update",update)
  //      SetPragraph((prevParagraphs) => [...prevParagraphs, currentInput]);
  //     //  SetPragraph([currentInput])
  //     setCurrentInput('');
  //   }

  //   console.log("event",event.target.value)
  //   console.log("paragraph",paragraph)
  // };



  const handleInputChange = (event) => {
    setCurrentInput(event.target.value);
  };
  const handleAddReportingStandard = (event) => {
    event.preventDefault();
  
    // First, check if the radio button is set to "yes"
    if (reportingStandardsRadios.reportingStandardsRadio === "yes") {
      
      // Trim the input to remove any leading/trailing whitespace
      const trimmedInput = currentInput.trim();
      
      // If the input is empty or whitespace, show an error message
      if (trimmedInput === '') {
        setErrors((prev) => ({ ...prev, reportingStandards: 'This field is required' }));
        return;
      }
  
      // If input is valid, update the reportingStandards array and clear the error
      setFormData((prevData) => ({
        ...prevData,
        reportingStandards: [...prevData.reportingStandards, trimmedInput], // Add new input to array
      }));
  
      SetPragraph((prevData) => [...prevData, trimmedInput]); // Add new input to paragraph array
  
      // Clear the input and errors after successful addition
      setCurrentInput(""); 
      setErrors((prev) => ({ ...prev, reportingStandards: '' }));
    }
  };
  
  
  // const handleAddReportingStandard = (event) => {
  //   // if (event.key === "Enter") {
  //     // Check for Enter key and non-empty input
  //     event.preventDefault();
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       reportingStandards: [
  //         ...prevData.reportingStandards,
  //         currentInput.trim(),
  //       ], // Add new input to array
  //     }
  //   ));
  //     SetPragraph(
  //       (prevData) => [...prevData, currentInput.trim()] // Add new input to array
  //     );
  //     if (currentInput.trim() === '') {
  //       setErrors((prev) => ({ ...prev, currentInput: 'This field is required' }));
  //       return;
  //     }
  //     setCurrentInput(""); // Clear the input field after adding
  //   // }
  // };
  const handleDelete = (i) => {
    console.log(i, paragraph);
    // SetPragraph((prev) => {
    const updatedValues = paragraph.filter((value) => value !== i);
    // Check if the array is empty and add "Select" if needed
    // if (updatedValues.length === 0) {
    //   updatedValues.push("Select");
    // }
    SetPragraph(updatedValues);
    console.log("updatedValues", updatedValues);
    // return {
    //   ...prev,
    //   [category]: updatedValues,
    // };
    // });
  };
   
 const handleChangeRadioRequired=(e)=>{
  const {name,value}= e.target
  console.log(name,value,"for dataof radio required")
  setreportingStandardsRadio({
    ...reportingStandardsRadios,
    [name]:value
  })
if(name=== "reportingStandardsRadio" && value=== "no") {
  SetPragraph([]);  // Set paragraph to an empty array
} 
  // SetPragraph({reportingStandardsRadios.reportingStandardsRadio==="no"?.[]})
  
 }
  // const Back = () => {
  //   navigate('/');
  // };

  const Back = () => {
    setTriggerGetRequest(true); // Set trigger to true
    navigate("/");
  };
  const visible = formData.primaryReasons.filter((i) => i).map((item) => item);
  console.log("visible", visible[visible.length - 1]);
  return (
    // <Container  maxWidth={false} className="sustainability" sx={{ display: 'flex', width: '100%' }}>
    <Box
    sx={{backgroundColor: "#EDEDED",  display: "flex",
      backgroundColor: "#EDEDED",
      height: "100vh",
      overflow: "hidden"}}
    >
      <Sidebar title="Sustainabilty" />
      <Box  sx={{
          p: 2,
          mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}>
       <Typography variant="h5" sx={{
          fontWeight: "bold",
          // mt: "7rem",
          zIndex: 1,
          padding: "10px",
          borderRadius: "4px",
          alignSelf: "flex-start",
          ml:"6rem" }}>
          SUSTAINABILITY
        </Typography>
        <Grid container spacing={3} sx={{width:"80%"}}>
          <Grid item xs={12} width="100%">
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              Are you currently implementing any sustainability initiatives or
              practices within your company?
              <br /> If yes, please provide a brief.
            </Typography>
            <RadioGroup
              row
              aria-label="implementing"
              name="implementing"
              value={radio}
              onChange={handleChangeRadio}
            >
              <FormControlLabel
                value="yes"
                // value={formData.implementing ? 'yes' : 'no'} checked={formData.implementing === true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                //  checked={formData.implementing === false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            {console.log(
              "datainsidefile",
              formData,
              formData.implementing,
              radio
            )}
            {radio === "yes" ? (
              <>
              <TextField
                name="brief1"
                variant="outlined"
                className="brief"
                placeholder="Brief"
                value={formData.brief1}
                multiline
                fullWidth
                rows={5}
                onChange={handleChange}
                // error={!!errors} // Show error styling if there's an error
                // helperText={errors} 
                error={!!errors.brief1} // Show error styling if there's an error
                InputProps={{
                  style: { borderColor: errors.brief1 ? '#D34040' : undefined,
                   
                   },
                }}
                InputLabelProps={{
                  style: { color: errors.brief1 ? '#D34040' : undefined },
                }}
        
              />
             
                {errors.brief1 && (
          <Typography variant="body2" color="#D34040">
            {errors.brief1}
          </Typography>
        )}
              </>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12} width="100%">
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              What motivated your company to start incorporating sustainability
              practices?
            </Typography>
            <TextField
              name="brief2"
              variant="outlined"
              className="brief"
              placeholder="Brief"
              fullWidth
              multiline
              value={formData.brief2}
              rows={5}
              onChange={handleChange}
              error={!!errors.brief2} // Show error styling if there's an error
              InputProps={{
                style: { borderColor: errors.brief2 ? '#D34040' : undefined },
              }}
              InputLabelProps={{
                style: { color: errors.brief2 ? '#D34040' : undefined },
              }}
            />
             {errors.brief2 && (
          <Typography variant="body2" color="#D34040">
            {errors.brief2}
          </Typography>
        )}
          </Grid>

          <Grid item xs={12} width="100%">
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              Are there any specific sustainability goals your company aims to
              achieve?
            </Typography>
            <TextField
              name="brief3"
              variant="outlined"
              fullWidth
              placeholder="Brief"
              multiline
              rows={3}
              value={formData.brief3}
              onChange={handleChange}
              error={!!errors.brief3} // Show error styling if there's an error
              InputProps={{
                style: { borderColor: errors.brief3 ? '#D34040' : undefined },
              }}
              InputLabelProps={{
                style: { color: errors.brief3 ? '#D34040' : undefined },
              }}
            />
             {errors.brief3 && (
          <Typography variant="body2" color="#D34040">
            {errors.brief3}
          </Typography>
        )}
          </Grid>

          <Grid item xs={12} width="100%">
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              What are the primary reasons your company wants to incorporate
              sustainability into its practices? <br />
              (Select all that apply)
            </Typography>
            <Grid container spacing={2}>
              {[
                "Regulatory Compliance",
                "Customer Demand",
                "Leadership in Sustainability",
                "Cost Savings",
                "Ethical / Moral Responsibilities",
                "Brand Reputation Enhancement",
                "Other",
              ].map((reason, index) => (
                <Grid item xs={6} md={6} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={reason}
                        onChange={handleCheckboxChange}
                        checked={formData.primaryReasons.includes(reason)}
                      />
                    }
                    label={reason}
                  />
                </Grid>
              ))}
            </Grid>
            {errors.primaryReasons && (
   <Typography variant="body2" color="#D34040">
     {errors.primaryReasons}
   </Typography>
 )}
          </Grid>
          {visible && visible.includes("Other") && (
            <Grid item xs={6} width="100%">
              {/* <Typography className="paragraph">
            Is there a desire within your company to become a leader in sustainability within your industry?<br/> If yes, what specific initiatives or strategies are being considered to achieve this goal?
            </Typography> */}
              <TextField
                name="other"
                variant="outlined"
               fullWidth
                placeholder="Text..."
                multiline
                rows={1.5}
                value={formData.other}
                onChange={handleChange}
                // sx={{height:"30px"}}
                error={!!errors.other} // Show error styling if there's an error
                InputProps={{
                  style: { borderColor: errors.other ? '#D34040' : undefined },
                }}
                InputLabelProps={{
                  style: { color: errors.other ? '#D34040' : undefined },
                }}
              />
               {errors.other && (
          <Typography variant="body2" color="#D34040">
            {errors.other}
          </Typography>
        )}
            </Grid>
          )}
          {visible && visible.includes("Regulatory Compliance") && (
            <Grid item xs={12}>
              <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
                Are there any government regulations or mandates related to
                sustainability that your company needs  to comply with? If
                yes, please provide details.
              </Typography>
              <RadioGroup
              row
              aria-label="implementing"
              name="regulatoryComplianceRadio"
              value={reportingStandardsRadios.regulatoryComplianceRadio}
              onChange={handleChangeRadioRequired}
            >
              <FormControlLabel
                value="yes"
                // value={formData.implementing ? 'yes' : 'no'} checked={formData.implementing === true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                //  checked={formData.implementing === false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
        {reportingStandardsRadios.regulatoryComplianceRadio==="yes"? <>
         <TextField
         name="regulatoryCompliance"
         variant="outlined"
        fullWidth
         placeholder="Text..."
         multiline
         value={formData.regulatoryCompliance}
         rows={3}
         onChange={handleChange}
         error={!!errors.regulatoryCompliance} // Show error styling if there's an error
         InputProps={{
           style: { borderColor: errors.regulatoryCompliance ? '#D34040' : undefined },
         }}
         InputLabelProps={{
           style: { color: errors.regulatoryCompliance ? '#D34040' : undefined },
         }}
       />
        {errors.regulatoryCompliance && (
   <Typography variant="body2" color="#D34040">
     {errors.regulatoryCompliance}
   </Typography>
 )}
 </>
        :""}    
         
            </Grid>
          )}
          {visible && visible.includes("Customer Demand") && (
            <Grid item xs={12} width="100%">
              <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
                Have your customers expressed any specific sustainability
                requirements or preferences that influence your company's
                practices? If yes, please elaborate.
              </Typography>
              <RadioGroup
              row
              aria-label="implementing"
              name="customerDemandRadio"
              value={reportingStandardsRadios.customerDemandRadio}
              onChange={handleChangeRadioRequired}
            >
              <FormControlLabel
                value="yes"
                // value={formData.implementing ? 'yes' : 'no'} checked={formData.implementing === true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                //  checked={formData.implementing === false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            { reportingStandardsRadios.customerDemandRadio==="yes" &&(<>
              <TextField
                name="customerDemand"
                variant="outlined"
                fullWidth
                placeholder="Text..."
                multiline
                value={formData.customerDemand}
                rows={3}
                onChange={handleChange}
                error={!!errors.customerDemand} // Show error styling if there's an error
                InputProps={{
                  style: { borderColor: errors.customerDemand ? '#D34040' : undefined },
                }}
                InputLabelProps={{
                  style: { color: errors.customerDemand ? '#D34040' : undefined },
                }}
              />
               {errors.customerDemand && (
          <Typography variant="body2" color="#D34040">
            {errors.customerDemand}
          </Typography>
        )}</>)}
            </Grid>
          )}
          {visible && visible.includes("Leadership in Sustainability") && (
            <Grid item xs={12} width="100%">
              <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
                Is there a desire within your company to become a leader in
                sustainability within your industry?
                If yes, what specific initiatives or strategies are being
                considered to achieve this goal?
              </Typography>
              <RadioGroup
              row
              aria-label="implementing"
              name="governmentRegulationRadios"
              value={reportingStandardsRadios.governmentRegulationRadios}
              onChange={handleChangeRadioRequired}
            >
              <FormControlLabel
                value="yes"
                // value={formData.implementing ? 'yes' : 'no'} checked={formData.implementing === true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                //  checked={formData.implementing === false}
                control={<Radio />}
                label="No"
              />
              </RadioGroup>
       {reportingStandardsRadios.governmentRegulationRadios==="yes"&&( 
        <>
            <TextField
                name="governmentRegulations"
                variant="outlined"
                fullWidth
                placeholder="Text..."
                multiline
                value={formData.governmentRegulations}
                rows={3}
                onChange={handleChange}
                error={!!errors.governmentRegulations} // Show error styling if there's an error
                InputProps={{
                  style: { borderColor: errors.governmentRegulations ? '#D34040' : undefined },
                }}
                InputLabelProps={{
                  style: { color: errors.governmentRegulations ? '#D34040' : undefined },
                }}
              />
              {errors.governmentRegulations && (
                <Typography variant="body2" color="#D34040">
                  {errors.governmentRegulations}
                </Typography>
              )}
              </>
              )}
              
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              Has your company selected any particular sustainability reporting
              standard or framework? <br /> If yes, please specify.
            </Typography>
            <RadioGroup
              row
              aria-label="implementing"
              name="reportingStandardsRadio"
              value={reportingStandardsRadios.reportingStandardsRadio}
              onChange={handleChangeRadioRequired}
              
            >
              <FormControlLabel
                value="yes"
                // value={formData.implementing ? 'yes' : 'no'} checked={formData.implementing === true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                //  checked={formData.implementing === false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            {console.log("formData.reportingStandards", paragraph.length)}
            <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      gap: 0.5,
      color: "white",
      mt: 2,
      mb:1
    }}
  >
              {paragraph.length > 0 &&
                paragraph.map((item) => {
                  return (
                    // <p
                    //   style={{
                    //     display: "flex",
                    //     backgroundColor: "#0d336b",
                    //     borderRadius: "5px",
                    //     padding: "2px",
                    //   }}
                    // >
                    //   {item}
                    //   <CloseIcon
                    //     style={{
                    //       width: "15px",
                    //       margin: "3px 0px 0px 4px",
                    //       color: "#fff",
                    //       cursor: "pointer",
                    //     }}
                    //     onClick={() => handleDelete(item)}
                    //   />
                    // </p>
                    <Chip
          key={item}
          label={item}
          onDelete={() => handleDelete(item)}
          sx={{
            bgcolor: "#1C486B",
            color: "white",
            height:"28px",
            "& .MuiChip-deleteIcon": {
              color: "white",
              "&:hover": {
                color: "white",
              },
            },
          }}
          deleteIcon={
            <IconButton sx={{ color: "white"}}>
              <CancelIcon sx={{height:"18px",width:"18px"}}/>
            </IconButton>
          }
        />
                  );
                })}
            </Box>
           { reportingStandardsRadios.reportingStandardsRadio==="yes" &&
            <Box  
            sx={{ width: "105%", display: "flex"}}>   
          <Box sx={{width:"100%"}}>   <TextField
              name="reportingStandards"
              variant="outlined"
             
              placeholder="Type here"
              multiline
              value={currentInput}
              rows={3}
              fullWidth
              onChange={handleInputChange}
              // onKeyUp={handleAddReportingStandard}
              error={!!errors.reportingStandards} // Show error styling if there's an error
                InputProps={{
                  style: { borderColor: errors.reportingStandards ? '#D34040' : undefined },
                }}
                InputLabelProps={{
                  style: { color: errors.reportingStandards ? '#D34040' : undefined },
                }}
            />
             {errors.reportingStandards && (
          <Typography variant="body2" color="#D34040">
            {errors.reportingStandards}
          </Typography>
          
        )}</Box>
         <IconButton onClick={handleAddReportingStandard}>
        <SaveIcon />
      </IconButton>
       </Box>  }
       
          </Grid>

          <Grid item xs={12}>
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              Can you provide an overview of your company's sustainability?
            </Typography>
            <TextField
              name="sustainabilityOverview"
              variant="outlined"
              fullWidth
              placeholder="Overview..."
              multiline
              value={formData.sustainabilityOverview}
              rows={3}
              onChange={handleChange}
              error={!!errors.sustainabilityOverview} // Show error styling if there's an error
              InputProps={{
                style: { borderColor: errors.sustainabilityOverview ? '#D34040' : undefined },
              }}
              InputLabelProps={{
                style: { color: errors.sustainabilityOverview ? '#D34040' : undefined },
              }}
            />
             {errors.sustainabilityOverview && (
          <Typography variant="body2" color="#D34040">
            {errors.sustainabilityOverview}
          </Typography>
        )}
          </Grid>

          <Grid item xs={12}>
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              How does your company plan to measure and track the progress of
              its sustainability initiatives?
            </Typography>
            <TextField
              name="progressMeasurement"
              variant="outlined"
              fullWidth
              placeholder="Text..."
              multiline
              value={formData.progressMeasurement}
              rows={3}
              onChange={handleChange}
              error={!!errors.progressMeasurement} // Show error styling if there's an error
              InputProps={{
                style: { borderColor: errors.progressMeasurement ? '#D34040' : undefined },
              }}
              InputLabelProps={{
                style: { color: errors.progressMeasurement ? '#D34040' : undefined },
              }}
            />
             {errors.progressMeasurement && (
          <Typography variant="body2" color="#D34040">
            {errors.progressMeasurement}
          </Typography>
        )}
         <ActionButton Move={Save} Back={Back}/>
          </Grid>

          {/* <Grid
            item
            xs={12}
            container
            spacing={1}
            className="savaAnBacksustainability"
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  color: "#0a3f73",
                  borderColor: "#0a3f73",
                  textTransform: "capitalize",
                }}
                onClick={Back}
              >
                <i class="bi bi-arrow-left arrow"></i>Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#0a3f73", textTransform: "none" }}
                onClick={Save}
              >
                Save & continue <i class="bi bi-arrow-right-short arrow"></i>
              </Button>
            </Grid>
          </Grid> */}
         
       
        </Grid>
      </Box>
    </Box>
  );
};

export default Sustainability;
