import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// initial kickoff routes
import FuturePlan from "./Components/Quetionary/InitialKickoff/FuturePlan/FuturePlan";
import Question from "./Components/Quetionary/InitialKickoff/question";
import Company from "./Components/Quetionary/InitialKickoff/CompanyInformation";
import Sustainability from "./Components/Quetionary/InitialKickoff/Sustainability";
import MaterialTopics from "./Components/Quetionary/InitialKickoff/MaterialTopics/MaterialTopics";
// social routes
import DevelopmentAndIncusion from "./Components/Quetionary/Social/Diversity";
import Employeedevelopment from "./Components/Quetionary/Social/EmployeeDevelopment";
import Community from "./Components/Quetionary/Social/Community";
import HumanRight from "./Components/Quetionary/Social/HumanRight";
import CustomerRelation from "./Components/Quetionary/Social/CustomerRelation";
import SupplyChain from "./Components/Quetionary/Social/SupplyChain";

// governance routes
import BoardComposition from "./Components/Quetionary/Governance/BoardComposition";
import ExclutiveCompensation from "./Components/Quetionary/Governance/ExcutiveCompensation";
import Stakeholder from "./Components/Quetionary/Governance/Stakeholder";
import Ethics from "./Components/Quetionary/Governance/EthicsCompliance";
import Cybersecurity from "./Components/Quetionary/Governance/Cybersecurity";
import RiskManagement from "./Components/Quetionary/Governance/RiskMangement";

// Environment routes
import WaterManagement from "./Components/Quetionary/EnvironmentTopics/WaterManagement";
import EnergyManagement from "./Components/Quetionary/EnvironmentTopics/EnergyManagement";
import Pollution from "./Components/Quetionary/EnvironmentTopics/Pollution";
import WasteManagement from "./Components/Quetionary/EnvironmentTopics/WasteManagement";
import Biodiversity from "./Components/Quetionary/EnvironmentTopics/Biodiversity";
import SustainableSourcing from "./Components/Quetionary/EnvironmentTopics/SustainableSourcing";
import GHGEmissions from "./Components/Quetionary/EnvironmentTopics/GHGEmissions";

//Masters
import CheckboxLabels from "./Pages/Masters/Roles and Permissions/Filters/Checkbox";
import MasterRoutes from "./Components/Masters/MasterSidebar/MasterRoutes";
import DashboardRoutes from "./Components/DashboardSidebar/DashboardRoutes";
import FilterPage from "./Components/Masters/Filter/FilterPage";
// import Facility from "./Pages/Masters/Facility Master/Facility";
import PetroleumGasoline from "./Pages/Scope1/StationaryCombustion.js/PetroleumGasoline/PetroleumGasoline";
import NaturalGasEquipment from "./Pages/Scope1/StationaryCombustion.js/Natural_Gas/Main";
import OnRoadVahicle from "./Pages/Scope1/MobileCombustion/OnRoadVahicles/Vehicle";
import MobileOffRoad from "./Pages/Scope1/MobileCombustion/OffRoad/OffRoad";
import FugitiveEmmissions from "./Pages/Scope1/FugitiveEmmissions/FireExtingulsher";
import Refrigeration from "./Pages/Scope1/FugitiveEmission/RefrigerationAirConditioning/Refrigeration";

//Scope2 importa
import ConsumptionByEquipment from "./Pages/Scope2/Heat/ConsumptionByEquipment/ConsumptionEquipment";
import ConsumptionByFacility from "./Pages/Scope2/Heat/ConsumptionByFacility";

import Test from "./Pages/Scope1/StationaryCombustion.js/Test";
import Dashboard from "./Pages/Scope1/Dashboard";
import Login from "./Pages/Login/Login";
import ElectrictyConsumptionFacility from "./Pages/Scope2/ElectricityConsumption";
// import Overview from "./Pages/Overview";
import RailTransport from "./Pages/Scope3/TransportationDistribution/Rail";
import ShipTransport from "./Pages/Scope3/TransportationDistribution/Ship";
import Emissions from "./Pages/Scope3/PurchasedGoods&Services/Emissions/Emissions";
import Coal from "./Pages/Scope1/StationaryCombustion.js/Coal/Coal";
import FuelOil from "./Pages/Scope1/StationaryCombustion.js/FuelOil/FuelOil";
import Diesel from "./Pages/Scope1/StationaryCombustion.js/Diesel/Diesel";
import ElectrictyConsumptionEquipment from "./Pages/Scope2/ElectricityConsumption/ConsumptionByEquipment";

 //Scope3 Imports
 import AirTransportation from "./Pages/Scope3/TransportationDistribution/Air";
import BusinessTravel from "./Pages/Scope3/BusinessTravel";
import WasteGenerated from "./Pages/Scope3/WasteGenerated";
import CO2EmissionEstimator from "./Pages/Scope3/TransportationDistribution/CO2EmissionCalculator";

function Home() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Company />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/challenges-concerns" element={<Question />} />
          <Route path="/future-plans" element={<FuturePlan />} />
          <Route path="/material-topics" element={<MaterialTopics />} />
          {/* environment quetionary routes */}
          <Route
            path="/environment/energy-management"
            element={<EnergyManagement />}
          />
          <Route path="/environment/ghg-emissions" element={<GHGEmissions />} />
          <Route
            path="/environment/water-management"
            element={<WaterManagement />}
          />
          <Route
            path="/environment/waste-management"
            element={<WasteManagement />}
          />
          <Route path="/environment/biodiversity" element={<Biodiversity />} />
          <Route path="/environment/pollution" element={<Pollution />} />
          <Route
            path="/environment/sustainable-sourcing"
            element={<SustainableSourcing />}
          />
          <Route
            path="/social/employee-development"
            element={<Employeedevelopment />}
          />
          <Route
            path="/social/diversity"
            element={<DevelopmentAndIncusion />}
          />
          <Route path="/social/community" element={<Community />} />
          <Route path="/social/human-right" element={<HumanRight />} />
          <Route
            path="/social/customer-relation"
            element={<CustomerRelation />}
          />
          <Route path="/social/supply-chain" element={<SupplyChain />} />

          <Route
            path="/governance/board-composition"
            element={<BoardComposition />}
          />
          <Route
            path="/governance/exclutive-compensation"
            element={<ExclutiveCompensation />}
          />
          <Route path="/governance/ethics" element={<Ethics />} />
          <Route
            path="/governance/risk-management"
            element={<RiskManagement />}
          />
          <Route path="/governance/stakeholder" element={<Stakeholder />} />
          <Route path="/governance/cybersecurity" element={<Cybersecurity />} />

          {/* <Route path="/masters/user" element={<User />} /> */}
          {/* <Route path="/masters/role" element={<Role />} /> */}

          {/* Filters */}
          <Route path="/master/filter/checkbox" element={<CheckboxLabels />} />
          <Route path="master/role/filter" element={<FilterPage />} />
          {/* Scope 1 Routes */}
          <Route path="scope1/stationary-combustion/coal" element={<Coal />} />
          <Route
            path="scope1/stationary-combustion/diesel"
            element={<Diesel />}
          />
          <Route
            path="scope1/stationary-combustion/fuel-oil"
            element={<FuelOil />}
          />

          <Route
            path="scope1/stationary-combustion/petroleumGasoline"
            element={<PetroleumGasoline />}
          />
          <Route
            path="/scope1/Mobile-combustion/OffRoad"
            element={<MobileOffRoad />}
          />
          <Route
            path="/scope1/FugitiveEmmissions/FireExtingulsher"
            element={<FugitiveEmmissions />}
          />
          {/* <Route path="masters/facility" element={<Facility />}/> */}
          <Route path="scope1/natural-gas" element={<NaturalGasEquipment />} />
          <Route path="scope1/onroad-vahicles" element={<OnRoadVahicle />} />
          <Route
            path="scope1/fugitive-emission/refrigeration"
            element={<Refrigeration />}
          />

          {/* Scope2 Routes */}
          <Route
            path="scope2/heat/consumption-by-equipment"
            element={<ConsumptionByEquipment />}
          />
          <Route
            path="scope2/heat/consumption-by-facility"
            element={<ConsumptionByFacility />}
          />

          <Route
            path="scope2/ElectrictyConsumptionFacility"
            element={<ElectrictyConsumptionFacility />}
          />
          <Route
            path="scope2/ElectrictyConsumptionEquipment"
            element={<ElectrictyConsumptionEquipment />}
          />


          {/* Scope3 */}
          <Route
            path="/scope3/transportation/air"
            element={<AirTransportation />}
          />

          <Route
            path="/scope3/CO2EmissionEstimator"
            element={<CO2EmissionEstimator />}
          />



          <Route path="test" element={<Test />} />
          <Route path="scope1/dashboard" element={<Dashboard />} />
          <Route path="login" element={<Login />} />
          {/* <Route path="dashboard" element={<Overview />} /> */}


          {/* Scope 3 Routes */}
          <Route
            path="/scope3/purchased-goods/emissions"
            element={<Emissions />}
          />
          <Route
            path="/scope3/transportation/rail"
            element={<RailTransport />}
          />
          <Route
            path="/scope3/transportation/ship"
            element={<ShipTransport />}
          />
          <Route path="/scope3/business-travel" element={<BusinessTravel />} />
          <Route path="/scope3/waste-generated" element={<WasteGenerated />} />
        </Routes>
        {/* Dashboard Sidebar Routes */}
        <DashboardRoutes />

        {/* Masters routes */}
        <MasterRoutes />
      </Router>
    </div>
  );
}

export default Home;
