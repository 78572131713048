"use client";
import { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  CssBaseline,
  Avatar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Sidebar from "./Sidebar";
import styles from "../../src/styles/layout/dashboard.module.css";

const DashboardLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const navbarData = [
    { title: "Search", icon: SearchIcon },
    { title: "Setting", icon: SettingsIcon },
    { title: "Notification", icon: NotificationsIcon },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box className={styles.sidebar}>
        <Sidebar />
      </Box>
      <Box className={styles.main}>
        <Box className={styles.navbar}>
          {navbarData.map((el, index) => (
            <IconButton
              key={index}
              title={el.title}
              sx={{ color: "black", fontSize: "1rem" }}
            >
              <el.icon />
            </IconButton>
          ))}
          <Avatar
            src="https://cms.interiorcompany.com/wp-content/uploads/2024/01/aster-attractive-flower-images.jpg"
            alt="Profile"
            sx={{ width: 30, height: 30, mr: 3 }}
          />
        </Box>

        <Box className={styles.children}>{children}</Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
