import { Alert, Snackbar } from '@mui/material'
import React from 'react'

const MessagePopup = ({snackOpen,setsnackOpen}) => {
  return (
    <Snackbar
    open={snackOpen.open}
    autoHideDuration={3000}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    onClose={() => setsnackOpen({ ...snackOpen, open: false })}
  >
    <Alert
      severity={snackOpen.severity}
      variant="filled"
      sx={{ width: "100%" }}
    >
      {snackOpen.message}
    </Alert>
  </Snackbar>
  )
}

export default MessagePopup