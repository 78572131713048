import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PaginationItem,
  Drawer,
  Alert,
  Snackbar,
  DialogTitle,
} from "@mui/material";
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Assignment as AssignmentIcon,
  ExpandLess,
  ExpandMore,
  FilterList as FilterListIcon,
  Add as AddIcon,
  MoreHoriz as MoreHorizIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

import axios from "axios";
import AddIndustrialProcess from "./AddIndustrialProcess";
import DateRangePickerComponent from "../../../Roles and Permissions/Filters/Date";
import { api } from "../../../../../utils/api";
import FilterSidebar from "../../../../../Components/Masters/Filter/FilterSidebar";
import ApplyButton from "../../../Roles and Permissions/Filters/ApplyButton";
import ResetButton from "../../../Roles and Permissions/Filters/ResetButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from "@mui/material/styles";
import LocationFilter from "../../../Roles and Permissions/Filters/UserFilters/LocationFilter";
import ViewIndustrialProcess from "./ViewIndusrialProcess";
import NoDataFound from "../../../../../Components/Masters/NoDataFound";
import PaginationBox from "../../../../../Components/Masters/Pagination";

const ListIndustrialProcess = () => {
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [facilities, setFacilities] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addFacility, setAddFacility] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    Location: false,
  });
  const [viewFacility, setViewFacility] = useState(false);
  const [dataView, setdataView] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries
  const [selectedFacilities, setSelectedFacilities] = useState([]); // Store selected facility name
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [errors, setErrors] = useState({
    Date: false,
    Location: false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);

  const filters = ["Date", "Location"];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/master/getAllIndustrialProcess?page=${1}&limit=${500}`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
      case "Location":
        return (
          <LocationFilter
            url={`${api}/master/getAllIndustrialProcess?page=${1}&limit=${500}`}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            errors={errors.Location}
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/master/getAllIndustrialProcess?page=${1}&limit=${500}`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
    }
  };
  const handleMenuClick = (event, facility) => {
    setAnchorEl(event.currentTarget);
    setdataView(facility);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddFacility(true);
    setViewFacility(false);
  };

  const handleView = () => {
    handleMenuClose();
    setViewFacility(true);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = () => {
    axios
      .put(
        `${api}/master/deteteIndustrialProcess`,
        { deactivated: true },
        {
          params: {
            Id: dataView._id, // Pass as query parameter
          },
        }
      )
      .then((res) => {
        setDeleteDialog(false);
        setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        fetchFacilities();
      })
      .catch((err) => {
        setsnackOpen({
          open: true,
          message: err.data?.response.message ?? "network Error",
          severity: "error",
        });

        console.log(err);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // fetching facility data
  const fetchFacilities = async (filters = filtersToApply) => {
    try {
      console.log(sortConfig);
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      const response = await axios.get(
        `${api}/master/getAllIndustrialProcess?${queryParams}`
      );

      setFacilities(response.data.data); // Update the user list
      setTotalPages(response.data.totalPages); // Update total pages for pagination
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchFacilities();
  }, [page, rowsPerPage, sortConfig]);

  // default filters to apply
  const filtersToApply = {
    location: selectedCountries.join(","),
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = { Date: "", Location: "" };
    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date

      if (!startDate && !endDate) {
        newErrors.Date = "Please select a date range";
        isValid = false;
      }

      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      // if (startDate && endDate && startDate >= endDate) {
      //   newErrors.Date = "End date must be greater than Start date";
      //   isValid = false;
      // }
    }

    if (selectedFilter === "Facility" && selectedFacilities.length === 0) {
      newErrors.Facility = "Please select Facility";
      console.log("Facility");
      isValid = false;
    }
    if (selectedFilter === "Location" && selectedCountries.length === 0) {
      newErrors.Location = "Please select Location";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    if (!validateFilters()) return;
    setPage(1);
    fetchFacilities();
    setDrawerFilterOpen(false);
    handleAppliedFilters();
  };

  // Applying reset filters
  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedCountries([]);
    setSelectedFacilities([]);
    setIsFiltersApplied(false);
    setAppliedFilters({ Facility: false, Date: false, Country: false });
    setErrors({});
    fetchFacilities({
      country: [],
      facilityName: [],
      startDate: "",
      endDate: "",
    });
    setDrawerFilterOpen(false);
  };

  // handle the applied filters for styling
  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate ? true : false;
    const isCountryApplied = selectedCountries.length > 0;
    const isFacilityApplied = selectedFacilities.length > 0;
    // const isFunctionalityOfRoleApplied = functionalityOfRoleFilter.length > 0;

    setIsFiltersApplied(isDateApplied || isCountryApplied || isFacilityApplied);

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      Country: isCountryApplied,
      Facility: isFacilityApplied,
      // FunctionalityOfRole: isFunctionalityOfRoleApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(() => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    setErrors({ Date: false, Location: false });
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Snackbar
        open={snackOpen.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setsnackOpen({ ...snackOpen, open: false })}
      >
        <Alert
          // onClose={handleClose}
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackOpen.message}
          {/* Add user Successfully */}
        </Alert>
      </Snackbar>

      <AddIndustrialProcess
        addFacility={addFacility}
        setAddFacility={setAddFacility}
        selectedFacility={dataView}
        setsnackOpen={setsnackOpen}
        fetchFacilities={fetchFacilities} // Pass fetchFacilities as a prop
      />
      <ViewIndustrialProcess
        viewFacility={viewFacility}
        setViewFacility={setViewFacility}
        selectedFacility={dataView}
        setsnackOpen={setsnackOpen}
        fetchFacilities={fetchFacilities}
        handleEdit={handleEdit}
      />
      {deleteDialog && (
        <Dialog
          open={deleteDialog}
          // onClose={handleCancelDelete}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to delete this entry?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteAggree} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <Drawer
            anchor="right"
            open={drawerFilterOpen}
            onClose={() => handleFilterDrawer()}
            sx={{
              backgroundColor: "transparent",
              "& .MuiDrawer-paper": {
                // This targets the paper component within the Drawer
                backgroundColor: "transparent", // Make paper transparent
                boxShadow: "none", // Optional: Remove any shadow
                width: "50%",
              },
            }}
          >
            <Box
              display="flex"
              gap={3}
              sx={{
                width: "100%",
                display: "flex",
                overflowX: "hidden",
                maxWidth: "100%",
                justifyContent: "space-btween",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  height: "3%",
                  padding: "13px",
                  margin: "30px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "80%",
                  width: "3%",
                }}
              >
                <IconButton
                  onClick={() => setDrawerFilterOpen(false)}
                  sx={{ color: "black" }}
                >
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
                {/* Sidebar for selecting the filter */}
                <FilterSidebar
                  {...{
                    setSelectedFilter,
                    selectedFilter,
                    filters,
                    appliedFilters,
                  }}
                />

                {/* Filter content area */}
                <Box
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#F9F9F9",
                    padding: "20px",
                    height: "100vh",
                    paddingBottom: "20%",
                  }}
                >
                  {renderSelectedFilter()}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: "3%",
                      position: "fixed",
                      bottom: "20px",
                      ml: 3,
                      mr: 3,
                    }}
                  >
                    <ApplyButton handleApply={handleApplyFilters} />
                    <ResetButton handleReset={handleResetFilters} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}{" "}
        <Grid
          container
          alignItems="center"
          sx={{ width: "80%", margin: "5% 0.5% 0% 22%" }}
        >
          <Grid item xs={6}>
            {/* <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                margin: "8px 0px 20px 0px",
                textDecoration: "underline",
              }}
            >
              Masters
            </Typography> */}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              // endIcon={<FilterListIcon />}
              sx={{
                margin: "10px 10px -15px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                "&:hover .dot-icon": {
                  color: "#FFFFFF", // Change dot color on button hover
                },
                // display:"flex"
              }}
            >
              Filter
              <FilterListIcon />
              {isFiltersApplied && (
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              )}
            </Button>

            <Button
              variant="contained"
              endIcon={<AddIcon />}
              sx={{
                margin: "10px -36px -15px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              onClick={() => {
                setAddFacility(true);
                setdataView(null);
              }}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Toolbar>

      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "77.5%",
          margin: "0% 5% 0% 23%",
          overflow: "hidden", // Ensure no scroll bar appears
        }}
      >
        <Box>
          <Box sx={{ margin: "16px" }}>
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                textAlign: "left",
                // padding: "0px",
              }}
            >
              Industrial Process Master
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "12% 16% 15% 26% 21% 10%",
              // width: "100%",
              border: "1px solid #ddd",
              height: "100%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 19px",
                marginBottom: facilities?.length === 0 ? "0px" : "-10px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Month
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("createdAt", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "createdAt" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("createdAt", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "createdAt" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                marginBottom: facilities?.length === 0 ? "0px" : "-10px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Location
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("location", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "location" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("location", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "location" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                marginBottom: facilities?.length === 0 ? "0px" : "-10px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Facility
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("facility", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "facility" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("facility", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "facility" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                marginBottom: facilities?.length === 0 ? "0px" : "-10px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Industry Process
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("industryProcess", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "industryProcess" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("industryProcess", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "industryProcess" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                marginBottom: facilities?.length === 0 ? "0px" : "-10px",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Final Manufactured Product
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2px",
                  marginLeft: "10px",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSort("finalManufacturedProduct", "asc")}
                >
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === "finalManufacturedProduct" &&
                        sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort("finalManufacturedProduct", "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === "finalManufacturedProduct" &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              </Box>
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {facilities.map((facility) => (
            <Box
              key={facility._id}
              sx={{
                display: "grid",
                gridTemplateColumns: "12% 16% 15% 26% 21% 10%",
                borderBottom: "1px solid #ddd",
              }}
            >
              <Typography
                sx={{
                  padding: "1px 4px",
                  textAlign: "center",
                  borderRight: "1px solid #ddd",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  margin: "7px 0px -7px 0px",
                  fontSize: "14px",
                  color: "black",
                }}
              >
                {new Date(facility.createdAt).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </Typography>
              <Typography
                sx={{
                  padding: "1px 4px",
                  textAlign: "left",
                  borderRight: "1px solid #ddd",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  color: "black",
                  margin: "7px 0px -7px 0px",
                }}
              >
                {facility.location}
              </Typography>
              <Typography
                sx={{
                  padding: "1px 4px",
                  textAlign: "left",
                  borderRight: "1px solid #ddd",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  color: "black",
                  margin: "7px 0px -7px 0px",
                }}
              >
                {facility.facility.facilityName}
              </Typography>
              <Typography
                sx={{
                  padding: "1px 4px",
                  textAlign: "left",
                  borderRight: "1px solid #ddd",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  color: "black",
                  margin: "7px 0px -7px 0px",
                }}
              >
                {facility.industryProcess}
              </Typography>
              <Typography
                sx={{
                  padding: "1px 4px",
                  textAlign: "left",
                  borderRight: "1px solid #ddd",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  color: "black",
                  margin: "7px 0px -7px 0px",
                }}
              >
                {facility.finalManufacturedProduct}
              </Typography>
              <Box
                sx={{
                  padding: "1px",
                  textAlign: "center",
                }}
              >
                <IconButton onClick={(e) => handleMenuClick(e, facility)}>
                  <MoreHorizIcon sx={{ fontWeight: "bold", color: "black" }} />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
        {facilities.length === 0 && <NoDataFound />}
      </TableContainer>

      {/* Pagination */}
      {totalPages > 1 && (
        <PaginationBox
          {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
        />
      )}
      {/* Menu for Actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>

        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ListIndustrialProcess;
