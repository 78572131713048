
// import React, { useState, useContext, useEffect } from 'react';
// import Sidebar from '../sidebar';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { ProgressContext } from '../ContextAPI';
// import { Button, Box, Typography, TextField } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { companyId } from "../../../utils/companyId";
// import { api } from "../../../utils/api"; 

// const Question = () => {
//   const { setUpdate, setTriggerGetRequest } = useContext(ProgressContext);
//   const navigate = useNavigate();

//   // State for the form fields
//   const [challenges, setChallenges] = useState('');
//   const [concerns, setConcerns] = useState('');
//   const [isExist, setIsExist] = useState(false);


 
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${api}/company/challenges_and_concerns/${companyId}`);
//         console.log('API response:', response.data);
        
//         // Update state only if data is available
//         if (response.data) {
//           setChallenges(response.data.challenges || ''); // Directly set the data
//           setConcerns(response.data.concerns || ''); // Directly set the data
//           setIsExist(true); // Set existence flag
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
  
//     fetchData();
//   }, []); 
  

//   useEffect(() => {
//     console.log('Challenges:', challenges);
//     console.log('Concerns:', concerns);
//   }, [challenges, concerns]);
  

//   // Handle the back button (navigate to the previous page)
//   const Back = () => {
//       navigate('/sustainability');
//       };


//   const Save = async () => {
//     try {
//         const data = {
//             challenges,
//             concerns,
//             companyId: companyId,
//         };

//         let response;
//         if (isExist) {
//             // PUT request to update the challenges and concerns data
//             response = await axios.put(`${api}/company/challenges_and_concerns/${companyId}`, data);
//             alert('Data updated successfully!'); // Show alert for PUT request
//         } else {
//             // POST request to save the challenges and concerns data
//             response = await axios.post(`${api}/company/challenges_and_concerns`, data);
//             alert('Data saved successfully!'); // Show alert for POST request
//         }

//         if (response.status === 200 || response.status === 201) {
//             // setUpdate("MATERIAL");
//             navigate("/future-plans"); // Navigate to the next page after saving
//             // navigate("/sustainability");

//         }
//     } catch (error) {
//         console.error('Error saving data:', error);
//         alert('Failed to save data. Please try again.');
//     }
// };


//   return (
//     <Box display="flex" width="80%" className="question" sx={{ backgroundColor: "#d6d7d9", minHeight: '100vh' }}>
//       <Sidebar title="Challenges & Concern" />

//       <Box width="80%" p={3} className="challenge">
//         <Typography variant="h5" component="h3" sx={{ mt: -2, mb: 2, fontWeight: 'bold' }}>
//           CHALLENGES & CONCERNS
//         </Typography>

//         <Box mb={3}>
//           <Typography variant="body1" sx={{ mb: 1, mt: '25px', width: '80%', fontWeight: 'bold' }}>
//             What do you perceive as the main challenges or barriers to implementing sustainable practices within your company?
//           </Typography>
//           <TextField
//             variant="outlined"
//             multiline
//             rows={3}
//             placeholder="Brief"
//             name="challenges"
//             fullWidth
//             value={challenges}
//             onChange={(e) => setChallenges(e.target.value)}
//             sx={{ backgroundColor: '#d6d7d9', width: '80%', mb: '20px', borderRadius: 1 }}
//           />
//         </Box>

//         <Box mb={3}>
//           <Typography variant="body1" sx={{ mb: 1, width: '80%', fontWeight: 'bold' }}>
//             Are there any specific concerns or limitations regarding sustainability initiatives that your company faces?
//           </Typography>
//           <TextField
//             variant="outlined"
//             multiline
//             rows={3}
//             placeholder="Brief"
//             name="concerns"
//             fullWidth
//             value={concerns}
//             onChange={(e) => setConcerns(e.target.value)}
//             sx={{ backgroundColor: '#d6d7d9', width: '80%', mb: '10px', borderRadius: 1 }}
//           />
//         </Box>

//         <Box display="flex" justifyContent="space-between" mt={5}>
//           <Button
//             variant="outlined"
//             startIcon={<ArrowBackIcon />}
//             onClick={Back}
//             sx={{ textTransform: 'none' }}
//           >
//             Back
//           </Button>

//           <Button
//             variant="contained"
//             sx={{ bgcolor: "#1C486B", textTransform: "none" }}
//             onClick={Save}
//           >
//             Save and Continue
//           </Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Question;


/////////////////////////////////////////

// import React, { useState, useContext, useEffect } from 'react';
// import Sidebar from '../sidebar';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { ProgressContext } from '../ContextAPI';
// import { Button, Box, Typography, TextField } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { companyId } from "../../../utils/companyId";
// import { api } from "../../../utils/api"; 
// import ActionButtons from "./MaterialTopics/ActionButtons";
// import { textError } from "../../../utils/validationMessage";

// const Question = () => {
//   const { setUpdate, setTriggerGetRequest } = useContext(ProgressContext);
//   const navigate = useNavigate();

//   // State for the form fields
//   const [challenges, setChallenges] = useState('');
//   const [concerns, setConcerns] = useState('');
//   const [isExist, setIsExist] = useState(false);

//   // State for errors
//   const [errors, setErrors] = useState({
//     challenges: false,
//     concerns: false,
//   });

//   useEffect(() => {
//     console.log(companyId)
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${api}/company/challenges_and_concerns/${companyId}`);
//         console.log('API response:', response.data);
        
//         if (response.data) {
//           setChallenges(response.data.challenges || '');
//           setConcerns(response.data.concerns || '');
//           setIsExist(true);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
  
//     fetchData();
//   }, []); 
  

//   const validateFields = () => {
//     let valid = true;
//     const newErrors = { challenges: false, concerns: false };

//     if (challenges.trim() === '') {
//       newErrors.challenges = true;
//       valid = false;
//     }

//     if (concerns.trim() === '') {
//       newErrors.concerns = true;
//       valid = false;
//     }

//     setErrors(newErrors);
//     return valid;
//   };

//   const Save = async () => {
//     if (!validateFields()) {
//       return; // Prevent submission if validation fails
//     }

//     try {
//       const data = {
//         challenges,
//         concerns,
//         companyId: companyId,
//       };

//       let response;
//       if (isExist) {
//         // PUT request to update the challenges and concerns data
//         response = await axios.put(`${api}/company/challenges_and_concerns/${companyId}`, data);
//       } else {
//         // POST request to save the challenges and concerns data
//         response = await axios.post(`${api}/company/challenges_and_concerns`, data);
//       }

//       if (response.status === 200 || response.status === 201) {
//         navigate("/future-plans"); // Navigate to the next page after saving
//       }
//     } catch (error) {
//       console.error('Error saving data:', error);
//     }
//   };

//   const Back = () => {
//     navigate('/sustainability');
//   };

//   return (
//     <Box sx={{
//       display: "flex",
//       backgroundColor: "#EDEDED",
//       height: "100vh",
//       overflow: "hidden" // Ensure no content gets hidden by scrollbars
//     }}>
//       <Sidebar title="Challenges & Concern" />
//       <Box sx={{
//         p: 2,
//         mt: 3,
//         width: "100%",
//         height: "100vh", // Full viewport height
//         overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "flex-start", // Adjust to start at the top of the box
//       }}>
//         <Typography variant="h5" sx={{
//           fontWeight: "bold",
//           zIndex: 1,
//           padding: "10px",
//           borderRadius: "4px",
//           alignSelf: "flex-start",
//           ml: "5rem"
//         }}>
//          CHALLENGES & CONCERNS
//         </Typography>

//         <Box mb={3} sx={{width: '80%'}}>
//           <Typography variant="body1" sx={{ mb: 1, mt: '25px', fontWeight: 'bold' }}>
//             What do you perceive as the main challenges or barriers to implementing sustainable practices within your company?
//           </Typography>
//           <TextField
//             variant="outlined"
//             multiline
//             rows={3}
//             placeholder="Brief"
//             name="challenges"
//             fullWidth
//             value={challenges}
//             onChange={(e) => setChallenges(e.target.value)}
//             sx={{ backgroundColor: '#EDEDED', width: '100%', mb: '20px', borderRadius: 1 }}
//             error={errors.challenges} // Show red border if error
//           />
//           {errors.challenges && (
//             <Typography
//               variant="body2"
//               sx={{ color: '#D34040', marginTop: '-15px' }} // Custom styling for the error message
//             >
              
//               {textError}
//             </Typography>
//           )}
//         </Box>

//         <Box mb={3} sx={{width: '80%'}}>
//           <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
//             Are there any specific concerns or limitations regarding sustainability initiatives that your company faces?
//           </Typography>
//           <TextField
//             variant="outlined"
//             multiline
//             rows={3}
//             placeholder="Brief"
//             name="concerns"
//             fullWidth
//             value={concerns}
//             onChange={(e) => setConcerns(e.target.value)}
//             sx={{ backgroundColor: '#EDEDED', width: '100%', mb: '10px', borderRadius: 1 }}
//             error={errors.concerns} // Show red border if error
//           />
//           {errors.concerns && (
//             <Typography
//               variant="body2"
//               sx={{ color: '#D34040', marginTop: '-5px'  }} // Custom styling for the error message
//             >
//                {textError}
//             </Typography>
//           )}
//           <ActionButtons Back={Back} Move={Save} />
//         </Box>
        
//       </Box>
//     </Box>
//   );
// };

// export default Question;



import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../sidebar";
import { ProgressContext } from "../ContextAPI";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import QuestionCard from "./FuturePlan/QuestionCard";
import ActionButtons from "./MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import { api } from "../../../utils/api";
import { BorderColor } from "@mui/icons-material";
import { textError } from "../../../utils/validationMessage";

const Question = () => {
  const { setUpdate } = useContext(ProgressContext);
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);

  const questions = [
    {
      question:
        "What do you perceive as the main challenges or barriers to implementing sustainable practices within your company?",
      name: "challenges",
    },
    {
      question:
        " Are there any specific concerns or limitations regarding sustainability initiatives that your company faces?",
      name: "concerns",
    },
    
  ];

  const [formData, setFormData] = useState({
    challenges: "",
    concerns: "",
  });

  const [errors, setErrors] = useState({
    challenges: "",
    concerns: "",
    
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/company/challenges_and_concerns/${companyId}`
        );
        setFormData(response.data.challengesAndConcernsData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear the error when the user types
    });
  };

  const validateForm = () => {
    const newErrors = {
      challenges: "",
      concerns: ""
    };

    let isValid = true;

    if (!formData.challenges.trim()) {
      newErrors.challenges =textError;
      isValid = false;
    }

    if (!formData.concerns.trim()) {
      newErrors.concerns = textError;
      isValid = false;
    }

   

    setErrors(newErrors);
    return isValid;
  };

  const Move = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/company/challenges_and_concerns${isExist ? `/${companyId}` : ""}`,
        data: { ...formData, companyId: companyId },
      });
      navigate("/future-plans");
    } catch (error) {
      console.log("Error:", error);
      alert("There was an error submitting the data!", error);
    }
  };

  const Back = () => {
    navigate('/sustainability');
  };

  return (
    <Box sx={{ 
      display: "flex", 
      backgroundColor: "#EDEDED", 
      height: "100vh", 
      overflow: "hidden" // Ensure no content gets hidden by scrollbars
    }}>
       <Sidebar title="Challenges & Concern" />
      <Box sx={{
        p: 2,
        mt: 3,
        width: "100%",
        height: "100vh", // Full viewport height
        overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start", // Adjust to start at the top of the box
      }}>
        <Typography variant="h5" sx={{
          fontWeight: "bold",
          zIndex: 1,
          padding: "10px",
          borderRadius: "4px",
          alignSelf: "flex-start",
          ml: "5rem"
        }}>
          CHALLENGES & CONCERNS
        </Typography>
        <Box sx={{ width: "80%" }}>
          {questions.map((item, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <QuestionCard
                question={item.question}
                name={item.name}
                value={formData[item.name]}
                onChange={handleChange}
                borderColor={errors[item.name] && "#D34040"}
              />
              {errors[item.name] && (
                <Typography variant="body2" color="#D34040" sx={{mt:1}}>
                  {errors[item.name]}
                </Typography>
              )}
            </Box>
          ))}
          <ActionButtons Back={Back} Move={Move} />
        </Box>
      </Box>
    </Box>
  );
};

export default Question;
