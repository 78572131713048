import React, { useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";
import { getNestedValue } from "../../../utils/getNestedValue";
import {
  numberError,
  percentageError,
  textError,
} from "../../../utils/validationMessage";
import {
  validateNumber,
  validatePercentage,
} from "../../../utils/validateFields";
import {ProgressContext} from "../ContextAPI";

const Biodiversity = () => {
  const { setUpdate } = useContext(ProgressContext);

  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    impactOnBiodiversity: { value: "" },
    initiativesForBiodiversityPreservation: {
      numberOfProjects: "",
      scopeOfProjects: "",
    },
    landRehabilitation: { value: "" },
    companyId,
  });

  const [errors, setErrors] = useState({
    impactOnBiodiversity: { value: "" },
    initiativesForBiodiversityPreservation: {
      numberOfProjects: "",
      scopeOfProjects: "",
    },
    landRehabilitation: { value: "" },
  });

  // Biodiversity data for creating input cards
  const biodiversityData = [
    {
      title: "Impact on Biodiversity",
      placeholder: "1000",
      name: "impactOnBiodiversity.value",
      adornmentText: "Hectares of land affected",
    },
    {
      title: "Initiatives for Biodiversity Preservation",
      nestedData: [
        {
          placeholder: "10",
          name: "initiativesForBiodiversityPreservation.numberOfProjects",
          // adornmentText: "Number of projects",
        },
        {
          placeholder: "Scope of projects",
          name: "initiativesForBiodiversityPreservation.scopeOfProjects",
          // adornmentText: "Scope of projects",
        },
      ],
    },
    {
      title: "Land Rehabilitation",
      placeholder: "80",
      name: "landRehabilitation.value",
      adornmentText: "% of total land rehabilitated",
    },
  ];

  // Navigating back
  const Back = () => {
    navigate("/environment/waste-management");
  };

  // Validating input values
  const validateForm = () => {
    let newErrors = {
      impactOnBiodiversity: { value: "" },
      initiativesForBiodiversityPreservation: {
        numberOfProjects: "",
        scopeOfProjects: "",
      },
      landRehabilitation: { value: "" },
    };

    const {
      impactOnBiodiversity,
      initiativesForBiodiversityPreservation,
      landRehabilitation,
    } = formData;

    if (!validateNumber(impactOnBiodiversity.value)) {
      newErrors.impactOnBiodiversity.value = numberError;
    }

    if (
      !validateNumber(initiativesForBiodiversityPreservation.numberOfProjects)
    ) {
      newErrors.initiativesForBiodiversityPreservation.numberOfProjects =
        numberError;
    }

    if (initiativesForBiodiversityPreservation.scopeOfProjects === "") {
      newErrors.initiativesForBiodiversityPreservation.scopeOfProjects =
        textError;
    }

    if (!validatePercentage(landRehabilitation.value)) {
      newErrors.landRehabilitation.value = percentageError;
    }

    setErrors(newErrors);

    return Object.values(newErrors).every(
      (error) =>
        error === "" ||
        (typeof error === "object" &&
          Object.values(error).every((e) => e === ""))
    );
  };

  // Fetching data if already exists
  useEffect(() => {
    setUpdate("ESG");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/biodiversity-and-land-use/${companyId}`
        );
        console.log(response.data.biodiversityData);
        setFormData(response.data.biodiversityData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // Save the form data and make the post request
  const Save = async () => {
    console.log(formData);
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/biodiversity-and-land-use${
          isExist ? `/${companyId}` : ""
        }`,
        data: { ...formData, companyId },
      });
      navigate("/environment/pollution");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [prefix, key] = name.split(".");
    console.log(prefix, key);
    setFormData((prevFormData) => {
      // Handle nested updates for fields like `initiativesForBiodiversityPreservation`
      return {
        ...prevFormData,
        [prefix]: {
          ...prevFormData[prefix],
          [key]:  value.trim() || Number(value) || "" ,
     
        },
      };
    });

    // Clear error on change
    setErrors((prevErrors) => ({
      ...prevErrors,
      [prefix]: {
        ...prevErrors[prefix],
        [key]: "",
      },
    }));
  };

  const getErrorMessage = (name) => {
    if (name === "impactOnBiodiversity.value") {
      return errors.impactOnBiodiversity.value || "";
    } else if (name === "landRehabilitation.value") {
      return errors.landRehabilitation.value || "";
    } else if (name.includes("initiativesForBiodiversityPreservation")) {
      return (
        errors.initiativesForBiodiversityPreservation[name.split(".")[1]] || ""
      );
    } else {
      return errors[name] || "";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#EDEDED",
        height: "100vh",
        overflow: "hidden", // Ensure no content gets hidden by scrollbars
      }}
    >
      <Sidebar title="Biodiversity and Land Use" />
      <Box
        sx={{
          p: 2,
          mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            zIndex: 1,
            padding: "10px",
            borderRadius: "4px",
            alignSelf: "flex-start",
            ml: "5rem",
          }}
        >
          ENVIRONMENT: BIODIVERSITY AND LAND USE
        </Typography>
        <Box sx={{ mt: 3, width: "80%" }}>
          {/* Maps the biodiversity data */}
          {biodiversityData.map((item, index) => (
            <React.Fragment key={index}>
              {item.nestedData ? (
                <Box key={index} sx={{}}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {item.title}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      gap: 1,
                      display: "flex", // Display items in the same line
                      alignItems: "center",
                    }}
                  >
                    {item.nestedData.map((el, i) => (
                      <Box key={i} sx={{ flex: 1 }}>
                        <InputCard
                          placeholder={el.placeholder}
                          name={el.name}
                          value={getNestedValue(formData, el.name)}
                          handleChange={handleChange}
                          adornmentText={el.adornmentText}
                          multiline={el.name==="initiativesForBiodiversityPreservation.scopeOfProjects"}
                          borderColor={
                            getErrorMessage(el.name) ? "#D34040" : "initial"
                          }
                        />
                        <Box
                          sx={{
                            minHeight: "24px", // Reserve space for the error message
                            mt: 1,
                            display: "flex",
                          }}
                        >
                          <Typography variant="body2" color="error">
                            {getErrorMessage(el.name)}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box key={index}>
                  <InputCard
                    title={item.title}
                    placeholder={item.placeholder}
                    name={item.name}
                    value={getNestedValue(formData, item.name)}
                    borderColor={
                      getErrorMessage(item.name) ? "#D34040" : "initial"
                    }
                    handleChange={handleChange}
                    adornmentText={item.adornmentText}
                    error={errors[item.name]} // Pass the error state to InputCard
                  />
                  <Box
                    sx={{
                      minHeight: "24px", // Reserve space for the error message
                      mt: 1,
                      display: "flex",
                    }}
                  >
                    <Typography variant="body2" color="error">
                      {getErrorMessage(item.name)}
                    </Typography>
                  </Box>
                </Box>
              )}
            </React.Fragment>
          ))}
          <ActionButtons Move={Save} Back={Back} />
        </Box>
      </Box>
    </Box>
  );
};

export default Biodiversity;
