

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PaginationItem,
  Drawer,
  Alert,
  Snackbar,
  DialogTitle,
  Card,
  CardContent,
  CardHeader,
  Select,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
 import AddConsumptionFacility from "./AddConsumptionFacility";
 import ViewConsumptionFacility from "./ViewConsumptionFacility";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import { get, api, put, del } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import FilterSidebar from "../../../../Components/Masters/Filter/FilterSidebar";
import ApplyButton from "../../../Masters/Roles and Permissions/Filters/ApplyButton";
import DateRangePickerComponent from "../../../Masters/Roles and Permissions/Filters/Date";
import MultiSelect from "../../../Masters/Roles and Permissions/Filters/MultiSelect";
import Range from "../../../Masters/Roles and Permissions/Filters/Range";
import ResetButton from "../../../Masters/Roles and Permissions/Filters/ResetButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from "@mui/material/styles";
// import Cards from "../../../../Components/Masters/Cards";
import Cards from "../../../../Components/Common/Cards";
import SelectCard from "../../../../Components/Masters/SelectCard";
import ViewTable from "../../../../Components/Common/ViewTable";

const ConsumptionByFacility = () => {
  const [petroleums, setPetroleums] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [element, setElement] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addData, setAddData] = useState(false);
  const [uniqueValues, setUniqueValues] = useState({});
  const [selectedFields, setSelectedFields] = useState({
    industrialProcess: [],
    equipmentType: [],
    industrialEquipment: [],
  });
  const [appliedFilters, setAppliedFilters] = useState({
    date: false,
    industrialProcess: false,
    equipmentType: false,
    industrialEquipment: false,
    quantityConsumed: false,
    hoursUsed: false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [view, setView] = useState(false);
  const [dataView, setdataView] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [tableData, setTableData] = useState([]);
  // const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries
  const [selectedFacilities, setSelectedFacilities] = useState([]); // Store selected petroleum name
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [selectedPeriod, setSelectedPeriod] = React.useState("July");
  const [previousMeterReading, setPreviousMeterReading] = useState({
    min: "",
    max: "",
  });
  const [currentMeterReading, setCurrentMeterReading] = useState({
    min: "",
    max: "",
  });
  const [unitOfConsumption, setUnitOfConsumption] = useState({
    min: "",
    max: "",
  });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [errors, setErrors] = useState({
    Date: false,
    industrialProcess: false,
    equipmentType: false,
    industrialEquipment: false,
    quantityConsumed: false,
    hoursUsed: false,
  });
  const [dataforView, setdataForView] = useState(null);
  const [quantityRange, setQuantityRange] = useState({ min: "", max: "" });
  const [hoursRange, setHoursRange] = useState({ min: "", max: "" });
  const [cardConsumptions, setCardConsumptions] = useState({
    "Total Petrol Consumed": "",
    "Top Equipment By Consumption": "",
    "Consumption Period": "",
  });

  const headers = [
    { key: "createdAt", label: "Date", sortable: true },
    {
      key: "previousMeterReading",
      label: "Previous meter reading",
      sortable: true,
    },
    {
      key: "currentMeterReading",
      label: "Current meter reading",
      sortable: true,
    },
    {
      key: "unitOfConsumption",
      label: "Unit of energy consumption(KWH)",
      sortable: true,
    },
    {
      key: "vendor",
      label: "Vendor Name",
      sortable: true,
    },
    // { key: "unit", label: "Vendor Name", sortable: true },
  ];

  const inputData = [
        {
          fieldName: "vendor",
          title: "Vendor Name",
          placeholder: "Select vendor name",
          type: "select",
          url: `${api}/master/Heat/getVendor`,
          field: "VendorLegalFullName",
          required: true,
        },
        {
          fieldName: "previousMeterReading",
          title: "Previous Meter Reading",
          placeholder: " Enter previous Meter Reading",
          type: "number",
        },
        {
          fieldName: "currentMeterReading",
          title: "Current Meter Reading",
          placeholder: " Enter Current Meter Reading",
          type: "number",
        },
    
        {
          fieldName: "unitOfConsumption",
          title: "Unit Of Consumption(KW)",
          placeholder: " Enter unit",
          type: "text",
        },
    
        {
          fieldName: "equipment",
          title: "Equipment",
          placeholder: "Select equipment",
          type: "select",
          url: `${api}/master/Heat/getEquipment`,
          field: "equipment",
          required: true,
        },
    
        {
          fieldName: "reasonForDeviation",
          title: "Reason For Deviation",
          placeholder: "Reason For Deviation",
          type: "text",
        },
        
        {
          fieldName: "remarks",
          title: "Remark",
          placeholder: "Additional remarks",
          type: "text",
        },
        {
          fieldName: "documents",
          title: "Upload Documents",
          type: "file",
        },
      ];

  const filters = [
    "Date",
    "Previous Reading",
    "Current Reading",
    "Units Consumption",
    "Equipment",
  ];

  // Function to render the selected filter component based on user's choice

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/scope2/heatFacility/getHeatFacility`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.date,
            }}
          />
        );
      case "Equipment":
        return (
          <MultiSelect
            title="Equipment"
            selectedField={selectedFields.equipment}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Equipment"]}
            setErrors={setErrors}
            field="equipment"
            data={uniqueValues.equipment}
          />
        );
      case "Equipment Type":
        return (
          <MultiSelect
            title="Equipment Type"
            url={`${api}/master/getAllEquipmentTypes`}
            selectedField={selectedFields.equipmentType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors.equipmentType}
            setErrors={setErrors}
            field="equipmentType"
            data={uniqueValues.equipmentType}
          />
        );
      case "Industrial Equipment":
        return (
          <MultiSelect
            title="Industrial Equipment"
            selectedField={selectedFields.industrialEquipment}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors.industrialEquipment}
            setErrors={setErrors}
            field="industrialEquipment"
            data={uniqueValues.industrialEquipment}
          />
        );
      case "Units Consumption":
        return (
          <Range
            title="Units Consumed"
            selectedRange={unitOfConsumption}
            setSelectedRange={setUnitOfConsumption}
            errors={errors["Units Consumed"]}
            setErrors={setErrors}
            placeholderMax="Max(KW)"
            placeholderMin="Min(KW)"
          />
        );
      case "Current Reading":
        return (
          <Range
            title="Current Reading"
            selectedRange={currentMeterReading}
            setSelectedRange={setCurrentMeterReading}
            errors={errors["Current Reading"]}
            setErrors={setErrors}
            placeholderMax="Max()"
            placeholderMin="Min()"
          />
        );

      case "Previous Reading":
        return (
          <Range
            title="Previous Reading"
            selectedRange={previousMeterReading}
            setSelectedRange={setPreviousMeterReading}
            errors={errors["Previous Reading"]}
            setErrors={setErrors}
            placeholderMax="Max(kwh)"
            placeholderMin="Min(kwh)"
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/scope2/heatFacility/getHeatFacility`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.date,
            }}
          />
        );
    }
  };

  const fetchUniqueValues = async () => {
    try {
      const response = await get(`/scope2/heatFacility/getHeatFacility`);
      const data = response.data.data;
      console.log(data);

      const uniqueEquipmentType = [
        ...new Set(
          data.map((el) => el.equipment?.equipment).filter(Boolean) // Filter out undefined values
        ),
      ];
      const uniqueIndustrialEquipment = [
        ...new Set(
          data.map((el) => el?.currentMeterReading).filter(Boolean) // Filter out undefined values
        ),
      ];
      // const uniqueUnit = [
      //   ...new Set(
      //     data.map((el) => el?.unitOfConsumption).filter(Boolean) // Filter out undefined values
      //   ),
      // ];

      return {
        equipment: uniqueEquipmentType,
        currentMeterReading: uniqueIndustrialEquipment,
        // unitOfConsumption:uniqueUnit
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  const columnHeadings = [
    { title: "Date", fieldName: "createdAt" },
    { fieldName: "previousMeterReading", title: "Previous Reading" },
    { fieldName: "currentMeterReading", title: "Current Reading" },
    { fieldName: "unitOfConsumption", title: "Units Consumed" },
    { fieldName: "vendor", title: "Vender Name" },
  ];
  const handleMenuClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    // setSelectedPetroleum(petroleum);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // setSelectedPetroleum(null);
  };

  const handleEdit = (data) => {
    console.log("Edit petroleum:", data?._id);
    handleMenuClose();
    setAddData(true);
    setView(false);
    setdataView(data?._id !== undefined ? data : data?.data);
  };

  const handleView = (data) => {
    console.log("View petroleum:");
    handleMenuClose();
    setView(true);
    setAddData(false);
    setdataForView(data?.data);
  };

  const handleDelete = (index) => {
    console.log("Delete petroleum:", index.id);
    handleMenuClose();
    setDeleteDialog(true);
    setdataView(index.id);
  };

  const handleDeleteAggree = () => {
    console.log("indexhandleDeleteAggree", dataView, dataView);

    del(`/scope2/steamFacility/deleteSteamFacility`, {
      params: {
        Id: dataView, // Pass as query parameter
      },
    })
      .then(async (res) => {
        console.log(res, "res++");
        setDeleteDialog(false);
        setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        setsnackOpen({
          open: true,
          message: err.response?.data?.message ?? "Network Error",
          severity: "error",
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // fetching petroleum data
  const fetchData = async (
    filters = isFiltersApplied ? filtersToApply : {}
  ) => {
    // const fetchData = async (filters = {}) => {

    console.log(filters, "");
    try {
      console.log(sortConfig);
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
        company: companyId,
        createdBy: companyId,
      }).toString();
      console.log("Query Params sent to API:", queryParams, filters); // Debugging line
      const response = await get(
        `/scope2/heatFacility/getHeatFacility?${queryParams}`
      );
      let arr = response?.data?.data?.map((item) => {
        return {
          createdAt: new Date(item.createdAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }),
          unitOfConsumption: item?.unitOfConsumption,
          currentMeterReading: item.currentMeterReading,
          previousMeterReading: item.previousMeterReading,
          equipment: item.equipment,
          vendor: item.vendor?.VendorLegalFullName,
          action: "View",
          id: item?._id,
          data: item,
        };
      });
      console.log(arr, "for ListData");
      setTableData(arr);
      console.log(
        "Fetched data after filtering:",
        response.data,
        response.data.data,
        response.data.totalPages
      ); // Debugging line
      setPetroleums(response.data.data); // Update the user list
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData(filtersToApply);
    // fetchData()
  }, [page, rowsPerPage, sortConfig]);

  // default filters to apply
  const filtersToApply = {
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...Object.keys(selectedFields).reduce((acc, key) => {
      const selectedOptions = selectedFields[key];

      // Check if there are selected options
      if (selectedOptions && selectedOptions.length > 0) {
        // Join options into a string or keep them as an array
        acc[key] = selectedOptions.join(","); // Change to an array if needed
      }
      return acc;
    }, {}),
    minPreviousMeterReading: previousMeterReading.min || "",
    maxPreviousMeterReading: previousMeterReading.max || "",
    minCurrentMeterReading: currentMeterReading.min || "",
    maxCurrentMeterReading: currentMeterReading.max || "",
    minUnitConsumption: unitOfConsumption.min || "",
    maxUnitConsumption: unitOfConsumption.max || "",
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
      "Previous Reading": false,
      "Current Reading": false,
      "Industrial Equipment": false,
      "Units Consumption": false,
      Equipment: false,
    };

    console.log(selectedFilter, "hi");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select start date & end date";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }

    if (selectedFilter === "Previous Reading") {
      const min = parseFloat(previousMeterReading.min);
      const max = parseFloat(previousMeterReading.max);

      if (!min || !max) {
        newErrors["Previous Reading"] =
          "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Previous Reading"] = "Min hours must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Previous Reading"] = "Max hours must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Previous Reading"] =
          "Min hours cannot be greater than max consumption";
        isValid = false;
      }
    }

    if (selectedFilter === "Current Reading") {
      const min = parseFloat(currentMeterReading.min);
      const max = parseFloat(currentMeterReading.max);

      if (!min || !max) {
        newErrors["Current Reading"] = "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Current Reading"] = "Min hours must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Current Reading"] = "Max hours must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Current Reading"] =
          "Min hours cannot be greater than max consumption";
        isValid = false;
      }
    }

    if (selectedFilter === "Units Consumed") {
      const min = parseFloat(unitOfConsumption.min);
      const max = parseFloat(unitOfConsumption.max);

      if (!min || !max) {
        newErrors["Units Consumed"] = "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Units Consumed"] = "Min hours must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Units Consumed"] = "Max hours must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Units Consumed"] =
          "Min hours cannot be greater than max consumption";
        isValid = false;
      }
    }

    if (
      selectedFilter === "Equipment" &&
      selectedFields.equipment.length === 0
    ) {
      newErrors["Equipment"] = "Please select atleast one Equipment";
      isValid = false;
    }

    if (selectedFilter === "Hours Used") {
      const min = parseFloat(hoursRange.min);
      const max = parseFloat(hoursRange.max);

      if (!min && !max) {
        newErrors["Hours Used"] = "Please provide both min and max values";
        isValid = false;
      }

      if (min && isNaN(min)) {
        newErrors["Hours Used"] = "Min hours must be a valid number";
        isValid = false;
      }

      if (max && isNaN(max)) {
        newErrors["Hours Used"] = "Max hours must be a valid number";
        isValid = false;
      }

      if (!isNaN(min) && !isNaN(max) && min > max) {
        newErrors["Hours Used"] =
          "Min hours cannot be greater than max consumption";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    if (!validateFilters()) return;
    console.log("Applying filters...");

    // Reset to first page when applying filters
    setPage(1);
    fetchData(filtersToApply);
    setDrawerFilterOpen(false);
    handleAppliedFilters();
    setPreviousMeterReading({ min: "", max: "" });
    setCurrentMeterReading({ min: "", max: "" });
  };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({});

    // Call fetchData without any filters
    await fetchData({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate;
    const isEquipmentTypeApplied = selectedFields.vendor?.length > 0;
    const isEquipmentApplied = selectedFields.equipment?.length > 0;

    const isHoursRangeApplied =
      previousMeterReading.min && previousMeterReading.max;
    const isCurrentReadingRangeApplied =
      currentMeterReading.min && currentMeterReading.max;
    const isUnitOfConsumptionApplied =
      unitOfConsumption.min && unitOfConsumption.max;

    setIsFiltersApplied(
      isDateApplied ||
        isEquipmentTypeApplied ||
        isHoursRangeApplied ||
        isEquipmentApplied ||
        isCurrentReadingRangeApplied
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      Equipment: isEquipmentApplied,
      "Previous Reading": isHoursRangeApplied,
      "Current Reading": isCurrentReadingRangeApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    // setErrors({ Date: false, Role: false });
  };

  let bannerData = [
    {
      title: "Natural Gas Consumption",
      description: "This is the description of the banner.",
    },
    {
      title: "Natural Gas Consumption",
      description: ["one", "two"],
    },
    {
      title: "Natural Gas Consumption",
      description: "This is the description of the banner.",
    },
  ];

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "hidden",
        overflowX: "hidden",
      }}
    >
      <Snackbar
        open={snackOpen.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setsnackOpen({ ...snackOpen, open: false })}
      >
        <Alert
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackOpen.message}
        </Alert>
      </Snackbar>

      <AddConsumptionFacility
        addData={addData}
        setAddData={setAddData}
        SelectedData={dataView}
        fetchData={fetchData}
        inputData={inputData}
        addUrl={`${api}/scope2/heatFacility/addHeatFacility`}
       updateUrl={`${api}/scope2/heatFacility/updateHeatFacility`}
        setsnackOpen={setsnackOpen}
      />
      <ViewConsumptionFacility
        view={view}
        setView={setView}
        selectedData={dataforView}
        handleEdit={handleEdit}
      />

      {/* <Box sx={{ margin: "0% 13% -3% 3%" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: 2,
            margin: "5% 55% 0% 22%",
          }}
        ></Box>
      </Box> */}
      <Cards data={bannerData} title={"Consumption by Facility"} />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "1rem",
          gap: "1rem",
        }}
      ></div> */}
      {deleteDialog && (
        <Dialog open={deleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this Facility?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteAggree} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setdataView}
          setAddData={setAddData}
          {...{
            // heading: "Coal",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>

      <ViewTable
        headers={headers}
        data={tableData}
        onSort={handleSort}
        sortConfig={sortConfig}
        onMenuClick={handleMenuClick}
        page={2}
        count={10}
        onPageChange={() => {
          console.log("first");
        }}
        onView={handleView}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>

        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ConsumptionByFacility;
