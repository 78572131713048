import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import style from "../../../../styles/common/formModal.module.css";

const Form = ({
  isOpen,
  onClose,
  onSubmit,
  header,
  initialData,
  isEditing,
}) => {
  const initialFormData = {
    createdAt: initialData?.createdAt || "",
    origin: initialData?.origin || "",
    destination: initialData?.destination || "",
    freightLoad: initialData?.freightLoad || "",
    freightLoadUnit: initialData?.freightLoadUnit || "Kg",
    distance: initialData?.distance || "",
    distanceUnit: initialData?.distanceUnit || "Km",
    remark: initialData?.remark || "",
    document: initialData?.document || "",
  };

  console.log("ins", initialData);

  const initialErrors = {};

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (isOpen && isEditing) {
      setFormData(initialData);
    } else {
      setFormData(initialFormData);
    }
  }, [isOpen, isEditing, initialData]);

  const handleCancel = () => {
    setFormData(initialFormData);
    setErrors(initialErrors);
    onClose();
  };

  const validateFormData = () => {
    const newErrors = {};
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = () => {
    if (validateFormData()) {
      onSubmit(formData, isEditing);
      onClose();
    }
  };

  const handleChange = (field) => (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className={style.main_div}>
        <Typography
          id="modal-title"
          variant="h5"
          component="h2"
          align="left"
          style={{
            textDecoration: "underline",
            marginBottom: "1rem",
            fontSize: "24px",
            fontWeight: "600",
          }}
        >
          {header}
        </Typography>
        <form>
          <div style={{ margin: "1rem 0" }}>
            <Typography>Date</Typography>
            <TextField
              type="date"
              size="small"
              fullWidth
              value={formData.createdAt}
              onChange={handleChange("createdAt")}
            />

            <Typography style={{ marginTop: "0.5rem" }}>Source</Typography>
            <TextField
              type="text"
              size="small"
              fullWidth
              value={formData.origin}
              onChange={handleChange("source")}
            />

            <Typography style={{ marginTop: "0.5rem" }}>Destination</Typography>
            <TextField
              type="text"
              size="small"
              fullWidth
              value={formData.destination}
              onChange={handleChange("destination")}
            />

            <Box marginTop="0.5rem">
              <Typography>Freight Load</Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                fullWidth
              >
                <TextField
                  type="text"
                  size="small"
                  name="freightLoad"
                  placeholder="Enter Load"
                  value={formData.freightLoad}
                  onChange={handleChange("freightLoad")}
                  sx={{ width: "50%" }}
                />
                <Select
                  size="small"
                  name="freightLoadUnit"
                  value={formData.freightLoadUnit}
                  onChange={handleChange("freightLoadUnit")}
                  sx={{ width: "45%" }}
                >
                  <MenuItem value="Kg">Kg</MenuItem>
                  <MenuItem value="Tons">Tons</MenuItem>
                </Select>
              </Box>
            </Box>

            <Box marginTop="0.5rem">
              <Typography>Distance Travelled</Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                fullWidth
              >
                <TextField
                  type="text"
                  size="small"
                  name="distance"
                  value={formData.distance}
                  placeholder="Enter Distance"
                  onChange={handleChange("distance")}
                  sx={{ width: "50%" }}
                />
                <Select
                  size="small"
                  name="distanceUnit"
                  value={formData.distanceUnit}
                  onChange={handleChange("distanceUnit")}
                  sx={{ width: "45%" }}
                >
                  <MenuItem value="Km">Km</MenuItem>
                  <MenuItem value="Mile">Mile</MenuItem>
                </Select>
              </Box>
            </Box>

            <Typography style={{ marginTop: "0.5rem" }}>Remark</Typography>
            <TextField
              type="text"
              size="small"
              fullWidth
              value={formData.remark}
              onChange={handleChange("remark")}
            />

            <Box
              sx={{
                border: "2px solid rgba(28, 72, 107, 1)",
                borderRadius: 1,
                p: 2,
                textAlign: "center",
                cursor: "pointer",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <input
                type="file"
                hidden
                id={`upload-document`}
                name="document"
                onChange={handleChange("document")}
                multiple
              />
              <label>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CloudUploadIcon />
                  <Typography>Drop files here or click to upload</Typography>
                  <Typography variant="caption">
                    Upload your file (CSV, Excel, PNG, max 5 MB, recommended
                    size 1200x800 pixels).
                  </Typography>
                </Box>
              </label>
            </Box>
          </div>
        </form>

        <Button onClick={handleCancel} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          sx={{ ml: 4 }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default Form;
