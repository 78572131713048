import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import axios from 'axios';
import { api } from '../../../../utils/api';
import "./Scrollbar.css"
const CheckboxDrawer = ({ setSelectedRoles, selectedRoles,errors,setErrors }) => {
  const [roles, setRoles] = useState([]); // Store complete role objects from API
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch roles from backend to populate checkboxes
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${api}/auth/getRoles`);
        console.log(response.data)
         const roleNames = response.data.data
        .map(role => role.name)
        // .filter(name => name); // Map only the role names      
        setRoles([...new Set(roleNames)]);

      } catch (error) {
        setError('Error fetching roles');
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
    fetchRoles();
  }, []);

  // Handle checkbox toggle for the selected role
  const handleRoleToggle = (role) => {
    setErrors((prev) => ({
      ...prev,
      Role: "", // Clear the error for Date
    }));
    setSelectedRoles((prevSelected) =>
      prevSelected.includes(role)
        ? prevSelected.filter((r) => r !== role) // Remove if already selected
        : [...prevSelected, role] // Add if not selected
    );
  };

  
  if (loading) {
    return <Typography>Loading roles...</Typography>; // Show loading message
  }

  if (error) {
    return <Typography color="error">{error}</Typography>; // Show error message
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column',ml:2,height:"100%" }}>
       <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          marginBottom: '30px',
          
          // textAlign: 'left',
        }}
      >
        Role
      </Typography>
      <Box className="scrollable-container">
      {roles && roles.length > 0 ? (
        roles.map((role) => (
          <FormControlLabel
            key={role}
            control={
              <Checkbox
                value={selectedRoles} // Check if the role object is selected
                onChange={() => handleRoleToggle(role)} // Handle toggle based on role object
                checked={selectedRoles?.includes(role)}
              />
            }
            label={role} // Show only the role name as the label
          />
        ))
      ) : (
        <Typography>No roles available</Typography>
      )}
      {/* <ApplyButton handleApply={handleApplyFilter} /> */}
     
    </Box>
    {errors && (
            <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
              {errors}
            </Typography>
          )}
    </Box>
  );
};

export default CheckboxDrawer;
