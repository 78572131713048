import React, { useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";
import { getNestedValue } from "../../../utils/getNestedValue";
import AddButton from "./AddButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { validateNumber } from "../../../utils/validateFields";
import { numberError, textError } from "../../../utils/validationMessage";
import {ProgressContext} from "../ContextAPI";
const Pollution = () => {
  const { setUpdate } = useContext(ProgressContext);

  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [errors, setErrors] = useState({
    airEmissions: {},
    complianceWithEnvironmentalRegulations: [],
    spillsAndAccidents: [],
  });
  const [fieldToRemove, setFieldToRemove] = React.useState({
    fieldName: "",
    index: null,
  });
  const [formData, setFormData] = useState({
    airEmissions: {
      nox: { value: "" },
      sox: { value: "" },
      vocs: { value: "" },
      pm25: { value: "" },
      pm10: { value: "" },
    },
    complianceWithEnvironmentalRegulations: [
      { regulations: "", finesPaid: "" },
    ],
    spillsAndAccidents: [{ spills: "", accidents: "" }],
    companyId,
  });

  // AirEmissions Data used for creating input fields
  const airEmissionsData = [
    {
      title: "Air Emissions",
      placeholder: "NOx",
      name: "airEmissions.nox",
    },
    {
      placeholder: "SOx",
      name: "airEmissions.sox",
    },
    {
      placeholder: "VOCs",
      name: "airEmissions.vocs",
    },
    {
      placeholder: "PM 2.5",
      name: "airEmissions.pm25",
    },
    {
      placeholder: "PM 10",
      name: "airEmissions.pm10",
    },
  ];

  // Navigate back to the biodiversity
  const Back = () => {
    navigate("/environment/biodiversity");
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      airEmissions: {},
      complianceWithEnvironmentalRegulations: [],
      spillsAndAccidents: [],
    };

    // Validate air emissions
    Object.keys(formData.airEmissions).forEach((key) => {
      const value = formData.airEmissions[key].value;
      const error = !validateNumber(value) && numberError;
      if (error) isValid = false;
      newErrors.airEmissions[key] = error;
    });

    console.log(newErrors);

    // Validate compliance with environmental regulations
    formData.complianceWithEnvironmentalRegulations.forEach((item, index) => {
      const finesError = !validateNumber(item.finesPaid) && numberError;
      const regulationsError = item.regulations ? "" : textError;
      if (finesError || regulationsError) isValid = false;
      newErrors.complianceWithEnvironmentalRegulations[index] = {
        finesPaid: finesError,
        regulations: regulationsError,
      };
    });

    // Validate spills and accidents
    formData.spillsAndAccidents.forEach((item, index) => {
      const spillsError = item.spills ? "" : textError;
      const accidentsError = !validateNumber(item.accidents) && numberError;
      if (spillsError || accidentsError) isValid = false;
      newErrors.spillsAndAccidents[index] = {
        spills: spillsError,
        accidents: accidentsError,
      };
    });

    setErrors(newErrors);
    return isValid;
  };

  // Fetching Data if already exists
  useEffect(() => {
    setUpdate("ESG");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/pollution/${companyId}`
        );
        setFormData(response.data.pollutionData || formData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // Save the data and make post request
  const Save = async () => {
    console.log(formData);
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/pollution${isExist ? `/${companyId}` : ""}`,
        data: formData,
      });
      // alert(response.data.message);
      navigate("/environment/sustainable-sourcing");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);
    }
  };

  // Handling the input value
  const handleInputChange = (e, index, fieldName) => {
    const { name, value } = e.target;
    clearError(name, index, fieldName);
    if (name.includes("airEmissions")) {
      const [prefix, key] = name.split(".");
      setFormData((prevFormData) => ({
        ...prevFormData,
        airEmissions: {
          ...prevFormData.airEmissions,
          [key]: {
            ...prevFormData.airEmissions[key],
            value: value,
          },
        },
      }));
    } else if (
      name.includes("complianceWithEnvironmentalRegulations") ||
      name.includes("spillsAndAccidents")
    ) {
      const [prefix] = name.split(".");
      setFormData((prevFormData) => {
        const updatedArray = (prevFormData[prefix] || []).map((item, i) => {
          if (i === index) {
            return {
              ...item,
              [fieldName]:value ,
            };
          }
          return item;
        });

        return {
          ...prevFormData,
          [prefix]: updatedArray,
        };
      });
    }
  };

  // Handles Add New Array Field
  const handleAddField = (field) => {
    setFormData((prevFormData) => {
      const existingData = prevFormData[field] || [];
      const newItem =
        field === "complianceWithEnvironmentalRegulations"
          ? { regulations: "", finesPaid: "" }
          : { spills: "", accidents: "" };

      return {
        ...prevFormData,
        [field]: [...existingData, newItem],
      };
    });
  };

  // Clear the specific error when a valid value is entered
  const clearError = (name, index, fieldName) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (name.includes("airEmissions")) {
        const key = name.split(".")[1];
        newErrors.airEmissions[key] = false; // Assuming a valid number resets the error
      } else if (name.includes("complianceWithEnvironmentalRegulations")) {
        const fieldErrors =
          newErrors.complianceWithEnvironmentalRegulations[index] || {};
        if (fieldName === "finesPaid") {
          fieldErrors.finesPaid = false;
        } else if (fieldName === "regulations") {
          fieldErrors.regulations = "";
        }
        newErrors.complianceWithEnvironmentalRegulations[index] = fieldErrors;
      } else if (name.includes("spillsAndAccidents")) {
        const fieldErrors = newErrors.spillsAndAccidents[index] || {};
        if (fieldName === "accidents") {
          fieldErrors.accidents = false;
        } else if (fieldName === "spills") {
          fieldErrors.spills = "";
        }
        newErrors.spillsAndAccidents[index] = fieldErrors;
      }
      return newErrors;
    });
  };

  const handleRemoveField = (fieldName, index) => {
    setOpenDialog(true);
    setFieldToRemove({ fieldName, index });
  };

  const confirmRemoveField = () => {
    setFormData((prevData) => {
      const updatedField = [...prevData[fieldToRemove.fieldName]];
      updatedField.splice(fieldToRemove.index, 1);
      return {
        ...prevData,
        [fieldToRemove.fieldName]: updatedField,
      };
    });
    setOpenDialog(false);
  };

  const cancelRemoveField = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      {/* Render your fields here and use handleRemoveField on delete icon click */}

      <Dialog open={openDialog} onClose={cancelRemoveField}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this field?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelRemoveField} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRemoveField} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#EDEDED",
          height: "100vh",
          overflow: "hidden", // Ensure no content gets hidden by scrollbars
        }}
      >
        <Sidebar title="Pollution and Environmental Compliance" />
        <Box
          sx={{
            p: 2,
            mt: 3,
            width: "100%",
            height: "100vh", // Full viewport height
            overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start", // Adjust to start at the top of the box
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              zIndex: 1,
              padding: "10px",
              borderRadius: "4px",
              alignSelf: "flex-start",
              ml: "5rem",
            }}
          >
            ENVIRONMENT: POLLUTION & ENVIRONMENTAL COMPLIANCE
          </Typography>
          <Box sx={{ mt: 3, width: "80%" }}>
            {/* Maps with the air Emissions Data */}
            {airEmissionsData.map((item, index) => (
              <Box>
                <InputCard
                  key={index}
                  title={item.title}
                  placeholder={item.placeholder}
                  name={item.name}
                  value={getNestedValue(formData, item.name).value || ""}
                  handleChange={handleInputChange}
                  adornmentText="Metric tons"
                />
                {errors.airEmissions[item.name.split(".")[1]] && ( // Extract the last part after the dot
                  <Typography variant="body2" color="#D34040" sx={{ mt: 1 }}>
                    {errors.airEmissions[item.name.split(".")[1]]}{" "}
                    {/* Error message */}
                  </Typography>
                )}
              </Box>
            ))}
            <Typography variant="body1" sx={{ fontWeight: "bold", mt: 3 }}>
              Compliance with Environmental Regulations
            </Typography>
            {/* Maps the compliance with Environemnetal regualtions data */}
            {formData.complianceWithEnvironmentalRegulations.map(
              (el, index) => (
                <Box
                  key={index}
                  sx={{ width: "110%", display: "flex", gap: 1 }}
                >
                  {/* First Input Field for Fines Paid */}
                  <Box sx={{ display: "flex", gap: 1, width: "91%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "91%",
                      }}
                    >
                      <InputCard
                        placeholder="Enter number of fines paid"
                        name={`complianceWithEnvironmentalRegulations.${index}.finesPaid`}
                        borderColor={
                          errors.complianceWithEnvironmentalRegulations[index]
                            ?.finesPaid && "#D34040"
                        }
                        value={el.finesPaid || ""}
                        handleChange={(e) =>
                          handleInputChange(e, index, "finesPaid")
                        }
                      />
                      {errors.complianceWithEnvironmentalRegulations[index]
                        ?.finesPaid && (
                        <Typography
                          variant="body2"
                          color="#D34040"
                          sx={{ mt: 1 }}
                        >
                          {
                            errors.complianceWithEnvironmentalRegulations[index]
                              ?.finesPaid
                          }
                        </Typography>
                      )}
                    </Box>
                    {/* Second Input Field for Regulations */}
                    <Box
                      sx={{
                        width: "91%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <InputCard
                        placeholder="Enter the Regulation"
                        name={`complianceWithEnvironmentalRegulations.${index}.regulations`}
                        value={el.regulations || ""}
                        multiline={true}
                        borderColor={
                          errors.complianceWithEnvironmentalRegulations[index]
                            ?.regulations && "#D34040"
                        }
                        handleChange={(e) =>
                          handleInputChange(e, index, "regulations")
                        }
                      />
                      {errors.complianceWithEnvironmentalRegulations[index]
                        ?.regulations && (
                        <Typography
                          variant="body2"
                          color="#D34040"
                          sx={{ mt: 1 }}
                        >
                          {
                            errors.complianceWithEnvironmentalRegulations[index]
                              ?.regulations
                          }
                        </Typography>
                      )}
                    </Box>
                    {/* Delete Icon Button */}
                  </Box>
                  {index > 0 && (
                    <IconButton sx={{ mt: 2 }}>
                      <DeleteIcon
                        onClick={() =>
                          handleRemoveField(
                            "complianceWithEnvironmentalRegulations",
                            index
                          )
                        }
                      />
                    </IconButton>
                  )}
                </Box>
              )
            )}

            <AddButton
              onClick={() =>
                handleAddField("complianceWithEnvironmentalRegulations")
              }
            />
            <Typography variant="body1" sx={{ fontWeight: "bold", mt: 3 }}>
              Spills and Accidents
            </Typography>
            {formData.spillsAndAccidents.map((item, index) => (
              <Box key={index} sx={{ width: "110%", display: "flex", gap: 1 }}>
                {/* First Input Field for Fines Paid */}
                <Box sx={{ display: "flex", gap: 1, width: "91%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "91%",
                    }}
                  >
                    <InputCard
                      placeholder="Enter number of accidents"
                      name={`spillsAndAccidents.${index}.accidents`}
                      borderColor={
                        errors.spillsAndAccidents[index]?.accidents && "#D34040"
                      }
                      value={item.accidents || ""}
                      handleChange={(e) =>
                        handleInputChange(e, index, "accidents")
                      }
                    />
                    {errors.spillsAndAccidents[index]?.accidents && (
                      <Typography
                        variant="body2"
                        color="#D34040"
                        sx={{ width: "100%", mt: 1 }}
                      >
                        {errors.spillsAndAccidents[index]?.accidents}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ display: "column", width: "91%", gap: 1 }}>
                    <InputCard
                      placeholder="Enter the spills"
                      name={`spillsAndAccidents.${index}.spills`}
                      multiline={true}
                      value={item.spills || ""}
                      borderColor={
                        errors.spillsAndAccidents[index]?.spills && "#D34040"
                      }
                      handleChange={(e) =>
                        handleInputChange(e, index, "spills")
                      }
                    />
                    {errors.spillsAndAccidents[index]?.spills && (
                      <Typography
                        variant="body2"
                        color="#D34040"
                        sx={{ width: "100%", mt: 1 }}
                      >
                        {errors.spillsAndAccidents[index]?.spills}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {index > 0 && (
                  <IconButton sx={{ mt: 2 }}>
                    <DeleteIcon
                      onClick={() =>
                        handleRemoveField("spillsAndAccidents", index)
                      }
                    />
                  </IconButton>
                )}
              </Box>
            ))}
            {/* Add buttons for adding array input fields for spills and accidents */}
            <AddButton onClick={() => handleAddField("spillsAndAccidents")} />
            {/* Back, Save Buttons */}
            <ActionButtons Back={Back} Move={Save} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Pollution;
