export const validateNumber=(number)=>{
    if(number === "" ||
        isNaN(number) || number<0)
        return false;
    return true;
}

export const validatePercentage=(percentage)=>{
    if(percentage === "" ||
        isNaN(percentage) ||percentage<0 || percentage>100)
        return false;
    return true;
}


export const validateString=(string)=>{
    if(string==="")
        return false;
    return true;
}



export const validateDates=({startDate,endDate,newErrors,isValid})=>{
      const today = new Date(); // Get the current date
      
        // Ensure that startDate and endDate are not greater than today's date
        if (startDate && startDate > today) {
          newErrors.Date = "Start date cannot be in the future";
          isValid = false;
        }
      
        if (endDate && endDate > today) {
          newErrors.Date = "End date cannot be in the future";
          isValid = false;
        }
      
        // Ensure that the startDate is less than or equal to the endDate
        if (startDate && endDate && startDate >= endDate) {
          newErrors.Date = "End date must be greater than Start date";
          isValid = false;
        }
      return {newErrors,isValid}
}