import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import contries from "./Country.json";
import SelectField from "../../../Components/Masters/SelectField";
import { companyId } from "../../../utils/companyId";
import axios from "axios";
import { api } from "../../../utils/api";
import { ProgressContext } from "../../../Components/Quetionary/ContextAPI";
function Add(props) {
  // console.log("companyId",companyId,)
  const roleID = localStorage.getItem("roleId");
  const [companyNames, setCompanyName] = useState(null);
  const [countryOperations, setcountryOperations] = useState(null);
  const [facilities, setFacility] = useState(["Tap to Select"]);
  const [Selectedfacilities, setSelectedFacility] = useState(["Tap to Select"]);
  const [roles, setRole] = useState(["Tap to Select"]);
  const [FunctionalityRole, setFunctinalityRole] = useState([]);
  const [alreadyUser, setAlreadyUser] = useState(false);
  const [selectedEmail, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [verifybtn, setverifybtn] = useState({ email: false, phone: false });
  const [verify, setVerify] = useState({
    phone: false,
    mail: false,
    emailMsg: "",
    phoneMsg: "",
  });
  const [TaskAltIcons, setTaskAltIcons] = useState({
    email: false,
    phone: false,
  });
  // Time state
  const [timerActive, setTimerActive] = useState({
    email: false,
    phone: false,
  });
  const [otp, setOTP] = useState({
    phone: null,
    email: null,
  });

  const initialState = {
    company: companyId,
    country: "",
    facility: "",
    createdBy: roleID,
    companyName: "",
    name: "",
    email: "",
    phone: "",
    role: ["Tap to Select"],
    countryOperation: "",
    countryCode: "",
    functionlityOfRole: FunctionalityRole,
    remark: "",
  };

  const [fromValue, setFromValue] = useState(initialState);

  const [time, setTime] = useState(120); // Initialize time to 2 minutes (120 seconds)
  const [timerPhone, setTimerPhone] = useState(120);

  console.log("_idddddd", props.index);
  const resetForm = () => {
    setTaskAltIcons({
      email:false,
      phone:false
    })
    setOTP({
      phone:null,
      email:null
    })
    setVerify({
      phone: false,
      mail: false,
    })
  setverifybtn({ email: false, phone: false });
  setEmail(props.index === 0?"":props.index?.email);
    setFromValue(props.index === 0? initialState : {...props.index,
      role: props.index?.role?._id ?? "",
    });
    setErrors({});
  };


  useEffect(() => {
    if (timerActive.email && time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setTimerActive((prev) => ({ ...prev, email: false }));
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timerActive.email]);
  useEffect(() => {
    if (timerActive.phone && timerPhone > 0) {
      const timer = setInterval(() => {
        setTimerPhone((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setTimerActive((prev) => ({ ...prev, phone: false }));
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timerActive.phone]);

useEffect(() => {
  if (timerActive.email && time > 0) {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setTimerActive((prev) => ({ ...prev, email: false }));
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }
}, [timerActive.email]);

useEffect(() => {
  if (timerActive.phone && timerPhone > 0) {
    const timer = setInterval(() => {
      setTimerPhone((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setTimerActive((prev) => ({ ...prev, phone: false }));
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }
}, [timerActive.phone]); 

  const formatTime = (time) => {
    console.log("timmerforPhone", time);
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
    const trimmedValue = value.trim();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setErrors({ ...errors, email: "" });
    setOTP({
      ...otp,
       email:null
     })
    
    // Check if the email matches the pattern
    console.log(emailPattern.test(trimmedValue), trimmedValue);
    if (emailPattern.test(trimmedValue)) {
      setFromValue({
        ...fromValue,
        [name]: trimmedValue,
      });
      setverifybtn({ ...verifybtn, email: true });
      setTaskAltIcons({
        ...TaskAltIcons,
        email:false,
      })
      // setErrorMessage(""); // Clear the error message if the email is valid
    } else {
      // setErrorMessage("Invalid email format");
      console.log("Invalid email format");
      setverifybtn({ ...verifybtn, email: false });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "forUpdate");
    setFromValue({
      ...fromValue,
      [name]: value,
    });
  };

  useEffect(() => {
    // console.log("hiiii")
    if (companyId) {
      axios
        .get(`${api}/company/companies/${companyId}`)
        .then((response) => {
          console.log(response.data.companyName);

          setCompanyName(response.data.companyName);
          setcountryOperations(response.data.headquarters);
        })
        .catch((error) => {
          console.error("Error details:", error);
          console.log("URL being requested:"); // Log the URL to check if it's undefined
        });
    } else {
    }
    const fetchRole = async () => {
      await axios
        .get(`${api}/auth/FetchUserRoles`)
        .then((res) => {
          console.log(res.data.data, "datafacility");
          setFunctinalityRole(res.data.data);
          setRole(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchRole();
    const Facility = async () => {
      axios
        .get(`${api}/master/getAllFacility`)
        .then((res) => {
          console.log(res.data.data, "resfacility");
          setFacility(res.data.data);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    };
    Facility();
  }, [props.index]);

  useEffect(() => {
    console.log("propIndexValue", props.index);
    if (props.index !== 0) {
      // Fetch user data when props.index is not 0
      console.log("data=====", props.index);
      setFromValue({
        companyName: companyNames,
        countryOperation: countryOperations,
        company: companyId,
        country: props.index?.country ?? "Select Country",
        //  facility:  props.index?.facility?.map((item) => item.facilityName).join(""),
        facility: props.index?.facility?._id ?? "",
        createdBy: roleID,
        email: props.index?.email ?? "",
        name: props.index?.name ?? "",
        phone: props.index?.phone ?? "",
        role: props.index?.role?._id ?? "",
        countryCode: props.index?.countryCode ?? "",
        functionlityOfRole: FunctionalityRole ?? "",
        remark: props.index?.remark ?? "",
      });
      setSelectedFacility(props.index?.facility?.facilityName);
      //  setViewInner(false)
      setEmail(props.index?.email);
      setAlreadyUser(true);
    } else {
      // Reset form values when index is 0
      setFromValue({
        companyName: companyNames,
        countryOperation: countryOperations,
        company: companyId,
        country: "",
        facility: "",
        createdBy: roleID,
        email: "",
        name: "",
        phone: "",
        role: ["Tap to Select"],
        countryCode: "",
        functionlityOfRole: FunctionalityRole,
        remark: "",
      });

      setEmail("");
      setAlreadyUser(false);
    }
  }, [props.index, companyId, roleID, FunctionalityRole, roles]); // Add necessary dependencies

 

  console.log("fromValue++", fromValue);
  const Submit = () => {
    console.log("fromValue-----", fromValue, alreadyUser, props.index);
    if (!validateForm()) {
      return;
    }
    // axios({method:alreadyUser?'put':'post',
    //   url:`${api}/governance/Board-composition${alreadyUser ? `/${form.companyId}` : ""}`,
    //   data:`${alreadyUser?"":fromValue}`,
    //   ${alreadyUser? params:
    //     Id: props.index :""}
    //   })
    // axios.post(`${api}/auth/createUser`,fromValue)
    axios({
      method: alreadyUser ? "put" : "post",
      url: alreadyUser ? `${api}/auth/updateUser` : `${api}/auth/createUser`,
      data: fromValue, // Send data only if it's a POST request
      params: alreadyUser ? { Id: props.index?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        console.log(res, res.data.message);
        props.setValue(false);
        props.fetchUsers();
        resetForm();
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        //  props.setindexUser(null)
      })
      .catch((err) => {
        console.log(err, "errData", err?.response?.data?.message);
        if (err?.response?.data?.message==="Email Already exists.") {
          setErrors({ ...errors, email: err?.response?.data?.message });
        }else{
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const validateForm = () => {
    const newErrors = {};

    const {
      company,
      country,
      facility,
      companyName,
      name,
      email,
      phone,
      countryOperation,
      role,
      functionlityOfRole,
      countryCode,
    } = fromValue;

    // Validate `company`
    if (!company || company.trim() === "") {
      newErrors.companyName = "Company is required.";
      newErrors.countryOperation = "countryOperation is required.";
    }

    // if (!company || company.trim() === "") {
    //   newErrors.countryOperation = "countryOperation is required.";
    // }
    // Validate `country`
    if (!country || country === "Tap to select") {
      newErrors.country = "Please select a country.";
    }

    // Validate `facility`

    if (!facility || facility.length === 0 || facility[0] === "Tap to Select") {
      newErrors.facility = "Facility is required.";
    }

    // Validate `name`
    if (!name || name.trim() === "") {
      newErrors.name = "Name is required.";
    }

    // Validate `email`
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = "A valid email is required.";
    }

    if (verifybtn.email === true && email) {
      newErrors.email = "Please Validate ur email Click on verify";
    }

    if (verifybtn.email === true && !otp.email) {
      newErrors.OTPemail = "Please enter the OTP";
    }
    // Validate `phone` and `countryCode`
    if (phone) {
      if (verifybtn.phone === true) {
        newErrors.phone = "Please Validate ur phone Click on verify";
      }
      // If phone number is present but countryCode is missing
      if (!countryCode || countryCode.trim() === "") {
        newErrors.phone = "Country code is required,  select the country.";
      } else if (!/^\d{10,15}$/.test(phone)) {
        newErrors.phone = "A valid phone number is required."; // Validates phone number format
      }
    } else {
      // If phone is missing but countryCode is provided
      if (countryCode && countryCode.trim() !== "") {
        newErrors.phone = "Phone number is required .";
      } else {
        newErrors.phone = "A valid phone number is required."; // Generic phone validation
      }
    }

    if (verifybtn.phone === true && verifybtn.phone === true && !otp.phone) {
      newErrors.OTPphone = "Please enter the OTP";
    }

    console.log(role, roles, "roleForTheData");
    // Validate `role`
    if (!role || role.length === 0 || role[0] === "Tap to Select") {
      newErrors.role = "Role is required.";
    }
    console.log(functionlityOfRole, "inside ValidatedFilled");
    // Validate `functionlityOfRole`
    // if (!functionlityOfRole || functionlityOfRole.length === 0) {
    //   newErrors.functionlityOfRole = "Functionality of role is required.";
    // }

    // Validate `countryCode`
    // if (!countryCode || countryCode.trim() === "") {
    //   newErrors.countryCode = "Country code is required.";
    // }

    // Set the errors and return the validation result
    console.log(newErrors, verifybtn.phone, "newErrorsData");
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;

    console.log("Dropdown change:", value, name);

    setFromValue((prevFromValue) => ({
      ...prevFromValue,
      [name]: value,
      facility: name === "facility" ? value._id : prevFromValue.facility,
    }));

    if (name === "facility") {
      setSelectedFacility(value?.facilityName);
    }
  };

  const handleFunChange = (e) => {
    const { name, value } = e.target;
    console.log("vhh", e, name, value);
    setFromValue({
      ...fromValue,
      [name]: value,
    });
  };
  const handleCountryChange = (event) => {
    console.log("event.target.value", event.target.value);
    const selectedCountryCode = event.target.value;
    const selectedCountry = contries.countries.find(
      (country) => country.name === selectedCountryCode
    ); // Find the selected country object

    if (selectedCountry) {
      setFromValue({
        ...fromValue,
        country: selectedCountry.name, // Set country name
        countryCode: selectedCountry.dial_code, // Set country code
      });
      
      if (selectedCountry.dial_code && fromValue.phone !== "") {
        console.log(fromValue.phone,"fromValue.phone");
        setVerify({ ...verify, phone: true });
        setErrors({ ...errors, phone: ""});
    }
      
    }
    // setFromValue({
    //   ...fromValue,
    //   country : event.target.value.name,
    //   //  phone:event.target.value.dial_code
    //   countryCode:event.target.value.dial_code
    // });
  };
  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    // const numericValue = parseInt(value, /^[0-9]{10}$/) || 0;
    // const phoneNumberPattern = /^[0-9]{10}$/;
    setErrors({ ...errors, phone: "" });
    setOTP({
     ...otp,
      phone:null
    })
    
    const phoneNumberPattern = /^[0-9]*$/;
    console.log(
      "phoneNumberPattern.test(value)",
      phoneNumberPattern.test(value)
    );
    if (phoneNumberPattern.test(value) && value.length <= 10) {
      setFromValue({
        ...fromValue,
        [name]: value,
      });
      value.length === 10? setverifybtn({ ...verifybtn, phone: true }):setverifybtn({ ...verifybtn, phone: false })
     
      setTaskAltIcons({...TaskAltIcons, phone:false});
    } else {
      setverifybtn({ ...verifybtn, phone: true });
    }
  };
  console.log("otp.email", otp.email);

  const handleVerifyEmail = (time) => {
    console.log(time, "timeforOTP");
    setErrors({ ...errors, email: "" });
    if (time > 0) {
      setTime(120);
      setErrors({ ...errors, email: "" });
      axios
        .post(`${api}/auth/sendOtpToEmail`, { email: fromValue.email,module: "User" })
        .then((res) => {
          console.log("resForSendOtpEmail", res);
          
          setTimerActive((prev) => ({ ...prev, email: true }));
          if (res.status === 200) {
            setVerify({ ...verify, email: true });
            setverifybtn({ ...verifybtn, email: false });    
            setErrors({ ...errors, email: "", OTPemail: "" });
            // setverifybtn({...verifybtn,email:false});
          }
        })
        .catch((err) => {
          console.log(err, err?.response?.data?.message);
          setErrors({ ...errors, email: err?.response?.data?.message });
          setVerify({ ...verify, email: false });
          setverifybtn({ ...verifybtn, email: false });
         
        });
    } else {
      setVerify({ ...verify, email: false });
      setverifybtn({ ...verifybtn, email: false });
    }
  };
  const handleResend = () => {
    setTime(120); // Reset time to 180 seconds
    handleVerifyEmail(120); // Call the email verification function
  };
  const handleResendPhone = () => {
    setTimerPhone(120); // Reset time to 180 seconds
    handleVerifyPhone(120); // Call the email verification function
  };
  const handleOTPVerify = (e) => {
    const { value } = e.target;

    // Only allow numeric values and ensure length does not exceed 4
    const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion

    // Update state only if the value is numeric and has a length <= 4
    if (isNumeric && value.length <= 4) {
      setOTP((prevOtp) => ({
        ...prevOtp,
        email: value, // Update the OTP value
      }));
      // Trigger API call when exactly 4 digits are entered
      if (value.length === 4) {
        axios
          .post(`${api}/auth/verifyOtpForEmail`, {
            email: fromValue.email,
            otp: value,
          })
          .then((res) => {
            console.log("OTP verified successfully:", res, res.status);
            if (res.status === 200) {
              setVerify({ ...verify, email: false });
              setverifybtn({ ...verifybtn, email: false });
              setTaskAltIcons({ ...TaskAltIcons, email: true });
            }
          })
          .catch((err) => {
            console.error("Error verifying OTP:", err);
            setVerify({ ...verify, email: true, emailMsg: "Invalid OTP" });
            setverifybtn({ ...verifybtn, email: true });
            setTaskAltIcons({ ...TaskAltIcons, email: false });
            setErrors({ ...errors, email: "", OTPemail: "Incorrect Otp" });
          });
      }
    }
  };

  const handleVerifyPhone = (timerPhone) => {
  
    setErrors({ ...errors, phone: "" });
    if (timerPhone > 0) {
      setTimerPhone(120)
      axios
        .post(`${api}/auth/sendOtpToPhone`, {
          phone: fromValue.phone,
          countryCode: fromValue.countryCode,
          module: "User"
        })
        .then((res) => {
          console.log("res", res);
        
          setVerify({ ...verify, phone: true });
          setverifybtn({ ...verifybtn, phone: false });
          setTimerActive((prev) => ({ ...prev, phone: true }));
          setErrors({ ...errors, phone: "", OTPphone: "" });
        })
        .catch((err) => {
          console.log(err);
          fromValue.countryCode===""? setErrors({ ...errors, phone:"select Country" }): setErrors({ ...errors, phone: err?.response?.data?.message });
          setVerify({ ...verify, phone: false });
          setverifybtn({ ...verifybtn, phone: false });
         
        });
    } else {
      setVerify({ ...verify, phone: false });
      setverifybtn({ ...verifybtn, phone: false });
    }
  };

  const handleOTPVerifyPhone = (e) => {
    const { value } = e.target;

    // Only allow numeric values and ensure length does not exceed 4
    const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion

    // Update state only if the value is numeric and has a length <= 4
    if (isNumeric && value.length <= 4) {
      setOTP((prevOtp) => ({
        ...prevOtp,
        phone: value, // Update the OTP value
      }));
      setErrors({ ...errors, phone: "" });
      // Trigger API call when exactly 4 digits are entered
      if (value.length === 4) {
        setErrors({ ...errors, phone: "", OTPphone: "" });
        axios
          .post(`${api}/auth/verifyOtpForPhone`, {
            phone: fromValue.phone,
            countryCode: fromValue.countryCode,
            otp: value,
          })
          .then((res) => {
            console.log("OTP verified successfully:", res, res.status);
            if (res.status === 200) {
              setVerify({ ...verify, phone: false });
              setverifybtn({ ...verifybtn, phone: false });
              setTaskAltIcons({ ...TaskAltIcons, phone: true });
            }
          })
          .catch((err) => {
            console.error("Error verifying OTP:", err);
            setVerify({ ...verify, phone: true, phoneMsg: "Invalid OTP" });
            setverifybtn({ ...verifybtn, phone: true });
            setTaskAltIcons({ ...TaskAltIcons, phone: false });
            setErrors({ ...errors, phone: "", OTPphone: "Incorrect Otp" });
          });
      }
    }
  };

  const list = (anchor) => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 400,
        // maxWidth:"100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-btween",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {
            props.setValue(false);
            resetForm();
          }}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: "24px",
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {props.index !== 0 ? "Edit User" : "Add New User"}
        </Typography>
        {/* <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Country
        </Typography> */}
        {/* <CustomTextField
          variant="outlined"
          select
          //   label="Select Country"
          value={fromValue.country || "Select Country"}
          onChange={handleCountryChange}
          fullWidth
          name="country"
          placeholder="Select Country"
          error={!!errors.country} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.country ? "#D34040" : undefined },
          }}
        >
          <MenuItem value="Select Country" disabled>
            Select Country
          </MenuItem>
          {contries.countries.map((country) => (
            // console.log(country.name,country.code)
            <MenuItem key={country.dial_code} value={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.country && (
          <Typography variant="body2" color="#D34040">
            {errors.country}
          </Typography>
        )} */}
         <SelectField
          title={"Country"}
          name={"country"}
          value={fromValue.country}
          handleChange={handleCountryChange}
          options={contries.countries}
          errors={errors["country"]}
          //   url={el.url}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Company Name
        </Typography>
        <CustomTextField
          variant="outlined"
          value={fromValue.companyName}
          fullWidth
          name="companyName"
          placeholder="Prefilled"
          InputProps={{
            readOnly: true, // Makes the field read-only
          }}
          error={!!errors.companyName} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.companyName ? "#D34040" : undefined },
          }}
        />
        {errors.companyName && (
          <Typography variant="body2" color="#D34040">
            {errors.companyName}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Employee Name
        </Typography>
        <CustomTextField
          variant="outlined"
          value={fromValue.name}
          onChange={handleChange}
          fullWidth
          name="name"
          placeholder="Enter Name"
          error={!!errors.name} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.name ? "#D34040" : undefined },
          }}
        />
        {errors.name && (
          <Typography variant="body2" color="#D34040">
            {errors.name}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Company Email
        </Typography>
        <Box
          sx={{
            display: `${TaskAltIcons.email === true ? "flex" : "block"}`,
            width: `${TaskAltIcons.email === true ? "100%" : "100%"}`,
          }}
        >
          <CustomTextField
            variant="outlined"
            value={selectedEmail}
            onChange={handleEmailChange}
            fullWidth
            name="email"
            placeholder="Enter Email"
            error={!!errors.email} // Show error styling if there's an error
            InputLabelProps= {{
              style: { color: errors.email ? "#D34040" : undefined },
            }}
            InputProps={{
              endAdornment: (
                TaskAltIcons.email && (
                  <TaskAltIcon sx={{ color: "green", textAlign: "right" }} />
                )
              ),
            }}
          />

        
        </Box>
        {errors.email && (
          <Typography variant="body2" color="#D34040">
            {errors.email}
          </Typography>
        )}
        {verifybtn.email && (
          <Box
            sx={{
              fontSize: "14px",
              color: "green",
              textDecoration: "underline",
              textAlign: "right",
              cursor: "pointer",
            }}
            onClick={() => handleVerifyEmail(120)}
          >
            Verify
          </Box>
        )}
        {verify.email && (
          <Box>
            <Typography  variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}>Enter OTP For Email</Typography>

            <CustomTextField
              variant="outlined"
              value={otp.email}
              onChange={handleOTPVerify}
              fullWidth
              name="otpEmail"
              placeholder="Enter OTP"
              inputProps={{
                maxLength: 4,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              error={!!errors.OTPemail} // Show error styling if there's an error
              InputLabelProps={{
                style: { color: errors.OTPemail ? "#D34040" : undefined },
              }}
            />
            <Typography variant="body2" color="#D34040">
              {errors.OTPemail}
            </Typography>
            {time > 0 ? (
              <Box sx={{ textAlign: "right" }}>{formatTime(time)}</Box>
            ) : (
              <Box
                sx={{
                  fontSize: "14px",
                  color: "green",
                  textDecoration: "underline",
                  textAlign: "right",
                  cursor: "pointer",
                }}
                onClick={() => handleResend()}
              >
                Resend
              </Box>
            )}
          </Box>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Mobile No.
        </Typography>
        <Box
          sx={{
            display: `${TaskAltIcons.phone === true ? "flex" : "block"}`,
            width: `${TaskAltIcons.phone === true ? "100%" : "100%"}`,
          }}
        >
          <CustomTextField
            variant="outlined"
            value={fromValue.phone}
            onChange={handlePhoneChange}
            fullWidth
            name="phone"
            placeholder="Type Here"
            InputProps={{
              endAdornment: (
                TaskAltIcons.phone && (
                  <TaskAltIcon sx={{ color: "green", textAlign: "right" }} />
                )
              ),
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "10px" }}>
                  <span style={{ marginLeft: "8px" }}>
                    {fromValue.countryCode}
                  </span>
                  <div
                    style={{
                      borderLeft: "1px solid #000", // Color of the vertical line
                      height: "24px",               // Height of the line
                      marginRight: "8px",           // Space between the line and the percentage symbol
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
         
        </Box>
        {errors.phone && (
          <Typography variant="body2" color="#D34040">
            {errors.phone}
          </Typography>
        )}
        {verifybtn.phone && (
          <Box
            onClick={() => handleVerifyPhone(180)}
            sx={{
              fontSize: "14px",
              color: "green",
              textDecoration: "underline",
              textAlign: "right",
              cursor: "pointer",
            }}
          >
            Verify
          </Box>
        )}
        {verify.phone && (
          <Box>
            <Typography  variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}>Enter OTP For Phone</Typography>
            <CustomTextField
              variant="outlined"
              value={otp.phone}
              onChange={handleOTPVerifyPhone}
              fullWidth
              name="otpPhone"
              placeholder="Enter OTP"
              inputProps={{
                maxLength: 4,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              error={!!errors.OTPphone} // Show error styling if there's an error
              InputLabelProps={{
                style: { color: errors.OTPphone ? "#D34040" : undefined },
              }}
            />
            <Typography variant="body2" color="#D34040">
              {errors.OTPphone}
            </Typography>
            {timerPhone > 0 ? (
              <Box sx={{ textAlign: "right" }}>{formatTime(timerPhone)}</Box>
            ) : (
              <Box
                sx={{
                  fontSize: "14px",
                  color: "green",
                  textDecoration: "underline",
                  textAlign: "right",
                  cursor: "pointer",
                }}
                onClick={() => handleResendPhone()}
              >
                Resend
              </Box>
            )}
          </Box>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Company Headquarters Location
        </Typography>
        <CustomTextField
          variant="outlined"
          // select
          value={fromValue.countryOperation}
          // onChange={handleCountryChange}
          fullWidth
          name="countryOperation"
          placeholder="Prefilled"
          InputProps={{
            readOnly: true, // Makes the field read-only
          }}
          error={!!errors.countryOperation} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.countryOperation ? "#D34040" : undefined },
          }}
        />
        {errors.countryOperation && (
          <Typography variant="body2" color="#D34040">
            {errors.countryOperation}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Facility
        </Typography>
        {console.log(
          "fromValue.facility",
          fromValue.facility,
          Selectedfacilities
        )}
        <CustomTextField
          select
          variant="outlined"
          //  value = {fromValue.facility === "" || undefined ? "Tap to Select" : fromValue.facility}
          // value= {fromValue.facility }
          // value={Array.isArray(fromValue.facility)?
          //   fromValue.facility.map((item) => item.facilityName).join(""):fromValue.facility?.facilityName || "Tap to Select"}
          value={
            fromValue.facility === "" || fromValue.facility === undefined
              ? "Tap to Select"
              : Selectedfacilities
          }
          onChange={handleDropdownChange}
          fullWidth
          name="facility"
          placeholder="Tap to Select"
          error={!!errors.facility} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.facility ? "#D34040" : undefined },
          }}
        >
          <MenuItem
            value={
              fromValue.facility === "" || undefined
                ? "Tap to Select"
                : Selectedfacilities
            }
            disabled
          >
            {/* // Array.isArray(fromValue.facility)?
    // // fromValue.facility.map((item) => item.facilityName).join(""):fromValue.facility}
    //  disabled> */}
            {/* Tap to Select */}
            {fromValue.facility === "" || undefined
              ? "Tap to Select"
              : Selectedfacilities}

            {/* // Array.isArray(fromValue.facility)?  
    // // fromValue.facility.map((item) => item.facilityName).join(""):fromValue.facility} */}
          </MenuItem>

          {facilities.map((item, i) => (
            <MenuItem key={i} value={item}>
              {item.facilityName}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.facility && (
          <Typography variant="body2" color="#D34040">
            {errors.facility}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Assign Role
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          value={fromValue.role}
          onChange={handleDropdownChange}
          fullWidth
          name="role"
          placeholder="Tap to Select"
          error={!!errors.role} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.role ? "#D34040" : undefined },
          }}
        >
          <MenuItem
            value={fromValue.role === "" ? "Tap to Select" : fromValue.role}
            disabled
          >
            Tap to Select
          </MenuItem>
          {roles.map((item, i) => (
            <MenuItem key={i} value={item._id}>
              {item.name}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.role && (
          <Typography variant="body2" color="#D34040">
            {errors.role}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Assigned Permission
        </Typography>
        <CustomTextField
          // select
          InputProps={{
            readOnly: true, // Makes the field read-only
          }}
          variant="outlined"
          // value={{FunctionalityRole.length > 0 && FunctionalityRole.map((item) =>
          //   item._id === fromValue.role
          //     ? item.allowed_permissions.map((permission) => (permission.name ))
          //     : [])}}
          value={
            FunctionalityRole.length > 0
              ? FunctionalityRole.filter(
                  (item) => item._id === fromValue.role
                ).map((item) =>
                  item.allowed_permissions
                    .map((permission) => permission.name)
                    .join(", ")
                )
              : ""
          }
          onChange={handleFunChange} // Correctly passing selected value
          fullWidth
          name="functionlityOfRole"
          placeholder="Prefilled According to Role"
          // error={!!errors.functionlityOfRole} // Show error styling if there's an error

          // InputLabelProps={{
          //   style: { color: errors.functionlityOfRole ? '#D34040' : undefined },
          // }}
        />
        {/* {errors.functionlityOfRole && (
          <Typography variant="body2" color="#D34040">
            {errors.functionlityOfRole}
          </Typography>
        )} */}
        {/* {FunctionalityRole.length > 0 && FunctionalityRole.map((item, i) => (
    <CustomTextField
    select
    variant="outlined"
    value={fromValue.functionlityOfRole}
    // onChange={(event) => handleFunChange(event.target.value)}
    onChange={handleFunChange}  // Correct way to pass the function
    fullWidth
    name="functionlityOfRole"
    placeholder="Tap to Select"
  >
      {item.allowed_permissions.map((permission, index) => (
        <MenuItem key={permission._id} value={permission._id}>
          {permission.name}
        </MenuItem>
      ))}
 
    </CustomTextField>
  ))} */}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Remark
        </Typography>
        <TextField
          // select
          rows={2}
          variant="outlined"
          value={fromValue.remark}
          onChange={handleChange}
          fullWidth
          name="remark"
          placeholder="Brief"
          multiline
        ></TextField>
        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Submit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          width: "100%",
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.value}
        onClose={() => {
          props.setValue(false);
          resetForm();
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}
export default Add;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}));
