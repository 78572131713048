// import React, { useState, useEffect } from 'react';
// import { Button, TextField, TextareaAutosize, Box, Typography } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import Sidebar from '../sidebar';
// import { useNavigate } from 'react-router-dom';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import EastIcon from '@mui/icons-material/East';
// import axios from 'axios';
// import { companyId } from "../../../utils/companyId";
// import { api } from "../../../utils/api";

// const HumanRight = () => {
//   const navigate = useNavigate();
//   const [numberOfAssessments, setNumberOfAssessments] = useState('');
//   const [percentageOperationsAssessed, setPercentageOperationsAssessed] = useState('');
//   const [policies, setPolicies] = useState([{ existence: '', implementation: '' }]);
//   const [totalIncidents, setTotalIncidents] = useState('');
//   const [percentageResolution, setPercentageResolution] = useState('');
//   const [getData, setGetData] = useState(true);
//   const [isExist, setIsExist] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (companyId) {
//         try {
//           const response = await axios.get(`${api}/social/human_rights/${companyId}`);
//           const data = response.data;

//           // Set state with fetched data
//           setNumberOfAssessments(data.humanRightsAssessments.numberOfAssessmentsDone || '');
//           setPercentageOperationsAssessed(data.humanRightsAssessments.percentageOfOperationsAssessed.value || '');
//           setPolicies(data.policiesAndProcedures || [{ existence: '', implementation: '' }]);
//           setTotalIncidents(data.incidentsOfHumanRightsViolations.totalIncidentsRaised || '');
//           setPercentageResolution(data.incidentsOfHumanRightsViolations.percentageOfResolution.value || '');
//           setIsExist(true);
//         } catch (error) {
//           console.error('Error fetching data:', error);
//         } finally {
//           setGetData(false);
//         }
//       } else {
//         setGetData(false);
//       }
//     };

//     fetchData();
//   }, []);



//   const Save = async () => {
//     const humanRightsData = {
//       humanRightsAssessments: {
//         numberOfAssessmentsDone: Number(numberOfAssessments),
//         percentageOfOperationsAssessed: {
//           value: Number(percentageOperationsAssessed),
//           unit: "%"
//         }
//       },
//       policiesAndProcedures: policies,
//       incidentsOfHumanRightsViolations: {
//         totalIncidentsRaised: Number(totalIncidents),
//         percentageOfResolution: {
//           value: Number(percentageResolution),
//           unit: "%"
//         }
//       },
//       companyId: companyId // Replace with actual companyId
//     };
  
//     try {
//       if (isExist) {
//         // PUT request if isExist exists
//         await axios.put(`${api}/social/human_rights/${companyId}`, humanRightsData);
//         window.alert('Human Rights record updated successfully.'); // Alert for update
//       } else {
//         // POST request if isExist does not exist
//         await axios.post(`${api}/social/human_rights`, humanRightsData);
//         window.alert('Human Rights record created successfully.'); // Alert for creation
//       }
//       navigate("/social/customer-relation");
//     } catch (error) {
//       console.error('Error saving data:', error);
//       window.alert('An error occurred while saving data.'); // Alert for error
//     }
//   };
  

//   const handleBack = () => {
//     navigate("/social/community");
//   };

//   const handleAddPolicy = () => {
//     setPolicies([...policies, { existence: '', implementation: '' }]);
//   };

//   return (
//     <Box display="flex" sx={{ height: '100%', backgroundColor: 'rgb(214, 215, 216)' }}>
//       <Sidebar title="Human Rights" />
//       <Box sx={{ width: '100%', padding: '20px' }}>
//         <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', margin: '32px 0px' }}>
//           SOCIAL: HUMAN RIGHTS
//         </Typography>

//         {/* Human Rights Assessments Section */}
//         <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '2px' }}>
//           Human Rights Assessments
//         </Typography>
//         <Box display="flex" gap={2} mb={2}>
//           <TextField
//             variant="outlined"
//             placeholder="Number of assessment done"
//             value={numberOfAssessments}
//             onChange={(e) => setNumberOfAssessments(e.target.value)}
//             sx={{
//               width: '36%',
//               backgroundColor: '#EDEDED',
//               borderRadius: '4px',
//               borderColor: '#6b6c6d',
//               '& .MuiOutlinedInput-root': {
//                 height: '50px',
//                 '& fieldset': {
//                   borderColor: '#6b6c6d',
//                 },
//               },
//             }}
//             InputProps={{
//               sx: {
//                 padding: '0 10px',
//               },
//             }}
//           />
//           <TextField
//             variant="outlined"
//             placeholder="% of operations assessed"
//             value={percentageOperationsAssessed}
//             onChange={(e) => setPercentageOperationsAssessed(e.target.value)}
//             sx={{
//               width: '36%',
//               backgroundColor: '#EDEDED',
//               borderRadius: '4px',
//               borderColor: '#6b6c6d',
//               '& .MuiOutlinedInput-root': {
//                 height: '50px',
//                 '& fieldset': {
//                   borderColor: '#6b6c6d',
//                 },
//               },
//             }}
//             InputProps={{
//               sx: {
//                 padding: '0 10px',
//               },
//             }}
//           />
//         </Box>

//         {/* Policies and Procedures Section */}
//         <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '20px 0px 2px 0px' }}>
//           Policies and Procedures on Human Rights
//         </Typography>
//         {policies.map((policy, index) => (
//           <Box display="flex" gap={2} mb={2} key={index}>
//             <TextareaAutosize
//               minRows={3}
//               placeholder="Existence"
//               value={policy.existence}
//               onChange={(e) => {
//                 const updatedPolicies = [...policies];
//                 updatedPolicies[index].existence = e.target.value;
//                 setPolicies(updatedPolicies);
//               }}
//               style={{
//                 width: '36%',
//                 padding: '8px',
//                 backgroundColor: '#EDEDED',
//                 borderRadius: '4px',
//                 borderColor: '#6b6c6d',
//               }}
//             />
//             <TextareaAutosize
//               minRows={3}
//               placeholder="Implementation"
//               value={policy.implementation}
//               onChange={(e) => {
//                 const updatedPolicies = [...policies];
//                 updatedPolicies[index].implementation = e.target.value;
//                 setPolicies(updatedPolicies);
//               }}
//               style={{
//                 width: '36%',
//                 padding: '8px',
//                 backgroundColor: '#EDEDED',
//                 borderRadius: '4px',
//                 borderColor: '#6b6c6d',
//               }}
//             />
//           </Box>
//         ))}
//         {/* <Button
//           variant="outlined"
//           startIcon={<AddIcon />}
//           onClick={handleAddPolicy}
//           sx={{
//             color: '#204769',
//             borderColor: '#204769',
//             '&:hover': {
//               backgroundColor: 'rgba(25, 118, 210, 0.04)',
//               color: '#115293',
//               borderColor: '#115293',
//             },
//           }}
//         >
//           Add Policy
//         </Button> */}
//         <Button
//           variant="outlined"
//           startIcon={<AddIcon />}
//           onClick={handleAddPolicy}
//           sx={{
//             backgroundColor: 'transparent',
//             color: '#204769',
//             borderColor: '#204769',
//             borderRadius: '4px',
//             padding: '6px 16px',
//             fontSize: '0.875rem',
//             fontWeight: 500,
//             lineHeight: 1.75,
//             letterSpacing: '0.02857em',
//             textTransform: 'uppercase',
//             margin: '2px 0px 0px 610px',
//             '&:hover': {
//               backgroundColor: 'rgba(25, 118, 210, 0.04)',
//               color: '#115293',
//               borderColor: '#115293',
//             },
//             display: 'inline-flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             transition: 'background-color 0.3s, color 0.3s',
//           }}
//         >
//           Add
//         </Button>

//         {/* Incidents of Human Rights Violations Section */}
//         <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '2px' }}>
//           Incidents of Human Rights Violations
//         </Typography>
//         <Box display="flex" gap={2} mb={2}>
//           <TextField
//             variant="outlined"
//             placeholder="Total Number of Incidents Raised"
//             value={totalIncidents}
//             onChange={(e) => setTotalIncidents(e.target.value)}
//             sx={{
//               width: '36%',
//               backgroundColor: '#EDEDED',
//               borderRadius: '4px',
//               borderColor: '#6b6c6d',
//               '& .MuiOutlinedInput-root': {
//                 height: '50px',
//                 '& fieldset': {
//                   borderColor: '#6b6c6d',
//                 },
//               },
//             }}
//             InputProps={{
//               sx: {
//                 padding: '0 10px',
//               },
//             }}
//           />
//           <TextField
//             variant="outlined"
//             placeholder="Percentage of Resolution"
//             value={percentageResolution}
//             onChange={(e) => setPercentageResolution(e.target.value)}
//             sx={{
//               width: '36%',
//               backgroundColor: '#EDEDED',
//               borderRadius: '4px',
//               borderColor: '#6b6c6d',
//               '& .MuiOutlinedInput-root': {
//                 height: '50px',
//                 '& fieldset': {
//                   borderColor: '#6b6c6d',
//                 },
//               },
//             }}
//             InputProps={{
//               sx: {
//                 padding: '0 10px',
//               },
//             }}
//           />
//         </Box>

//         <Box display="flex" justifyContent="space-between" sx={{ marginTop: '70px' }}>
//           <Button
//             variant="outlined"
//             startIcon={<ArrowBackIcon />}
//             onClick={handleBack}
//             sx={{
//               color: '#204769',
//               borderColor: '#204769',
//               '&:hover': {
//                 backgroundColor: 'rgba(25, 118, 210, 0.04)',
//                 color: '#115293',
//                 borderColor: '#115293',
//               },
//             }}
//           >
//             Back
//           </Button>
//           <Button
//             variant="contained"
//             endIcon={<EastIcon />}
//             onClick={Save}
//             sx={{
//               backgroundColor: '#1C486B',
//               '&:hover': {
//                 backgroundColor: '#163B57',
//               },
//             }}
//           >
//             Save and Continue
//           </Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default HumanRight;

///////////////////////////////////////////////////







import React, { useState, useEffect,useContext } from 'react';
import { Button, TextField, Box, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import EastIcon from '@mui/icons-material/East';
import { companyId } from "../../../utils/companyId";
import { api } from "../../../utils/api";
import {ProgressContext} from "../ContextAPI";
import { textError, numberError, percentageError, twoFieldError } from "../../../utils/validationMessage";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const HumanRight = () => {
  const { setUpdate } = useContext(ProgressContext);
  const navigate = useNavigate();
  const [numberOfAssessments, setNumberOfAssessments] = useState('');
  const [percentageOperationsAssessed, setPercentageOperationsAssessed] = useState('');
  const [policies, setPolicies] = useState([{ existence: '', implementation: '' }]);
  const [totalIncidents, setTotalIncidents] = useState('');
  const [percentageResolution, setPercentageResolution] = useState('');
  const [getData, setGetData] = useState(true);
  const [isExist, setIsExist] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [policyToDeleteIndex, setPolicyToDeleteIndex] = useState(null);
  const [errors, setErrors] = useState({
    numberOfAssessments: '',
    percentageOperationsAssessed: '',
    policies: [],
    totalIncidents: '',
    percentageResolution: ''
  });

  useEffect(() => {
    setUpdate("SOCIAL");
    const fetchData = async () => {
      if (companyId) {
        try {
          const response = await axios.get(`${api}/social/human_rights/${companyId}`);
          const data = response.data;

          // Set state with fetched data
          setNumberOfAssessments(data.humanRightsAssessments.numberOfAssessmentsDone ?? '');
          setPercentageOperationsAssessed(data.humanRightsAssessments.percentageOfOperationsAssessed.value ?? '');
          setPolicies(data.policiesAndProcedures || [{ existence: '', implementation: '' }]);
          setTotalIncidents(data.incidentsOfHumanRightsViolations.totalIncidentsRaised ?? '');
          setPercentageResolution(data.incidentsOfHumanRightsViolations.percentageOfResolution.value ?? '');
          setIsExist(true);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setGetData(false);
        }
      } else {
        setGetData(false);
      }
    };

    fetchData();
  }, []);

  const validateFields = () => {
    let isValid = true;
    const newErrors = {
      numberOfAssessments: '',
      percentageOperationsAssessed: '',
      policies: [],
      totalIncidents: '',
      percentageResolution: ''
    };

    // if (!numberOfAssessments) {
      if (numberOfAssessments === ''  || numberOfAssessments === null) {
      newErrors.numberOfAssessments = textError;
      isValid = false;
    } else if (isNaN(numberOfAssessments) || numberOfAssessments < 0) {
      newErrors.numberOfAssessments = numberError;
      isValid = false;
    }

    // if (!percentageOperationsAssessed) {
      if (percentageOperationsAssessed === ''  || percentageOperationsAssessed === null) {
      newErrors.percentageOperationsAssessed = textError;
      isValid = false;
    } else if (isNaN(percentageOperationsAssessed) || percentageOperationsAssessed < 0 || percentageOperationsAssessed > 100) {
      newErrors.percentageOperationsAssessed = percentageError;
      isValid = false;
    }

    policies.forEach((policy, index) => {
      if (!policy.existence || !policy.implementation) {
        newErrors.policies[index] = twoFieldError;
        isValid = false;
      } else {
        newErrors.policies[index] = ''; // Clear error if valid
      }
    });

    // if (!totalIncidents) {
      if (totalIncidents === ''  || totalIncidents === null) {
      newErrors.totalIncidents = textError;
      isValid = false;
    } else if (isNaN(totalIncidents) || totalIncidents < 0) {
      newErrors.totalIncidents = numberError;
      isValid = false;
    }

    // if (!percentageResolution) {
      if (percentageResolution === ''  || percentageResolution === null) {
      newErrors.percentageResolution = textError;
      isValid = false;
    } else if (isNaN(percentageResolution) || percentageResolution < 0 || percentageResolution > 100) {
      newErrors.percentageResolution = percentageError;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const Save = async () => {
    if (!validateFields()) {
      return;
    }

    const humanRightsData = {
      humanRightsAssessments: {
        numberOfAssessmentsDone: Number(numberOfAssessments),
        percentageOfOperationsAssessed: {
          value: Number(percentageOperationsAssessed),
          unit: '%',
        },
      },
      policiesAndProcedures: policies,
      incidentsOfHumanRightsViolations: {
        totalIncidentsRaised: Number(totalIncidents),
        percentageOfResolution: {
          value: Number(percentageResolution),
          unit: '%',
        },
      },
      companyId: companyId,
    };

    try {
      if (isExist) {
        await axios.put(`${api}/social/human_rights/${companyId}`, humanRightsData);
        // alert('Human Rights record updated successfully.');
      } else {
        await axios.post(`${api}/social/human_rights`, humanRightsData);
        // alert('Human Rights record created successfully.');
      }
      navigate("/social/customer-relation");
    } catch (error) {
      console.error('Error saving data:', error);
      // alert('An error occurred while saving data.');
    }
  };

  const Back = () => {
    navigate("/social/community");
  };

  const handleAddPolicy = () => {
    setPolicies([...policies, { existence: '', implementation: '' }]);
  };

  // const handleDeletePolicy = (index) => {
  //   const updatedPolicies = policies.filter((_, i) => i !== index);
  //   setPolicies(updatedPolicies);
  //   setErrors((prevErrors) => {
  //     const newPoliciesErrors = [...prevErrors.policies];
  //     newPoliciesErrors.splice(index, 1);
  //     return { ...prevErrors, policies: newPoliciesErrors };
  //   });
  // };
  const handleDeletePolicy = (index) => {
    setPolicyToDeleteIndex(index);
    setOpenDialog(true);
  };

  const handleConfirmDelete = () => {
    const updatedPolicies = policies.filter((_, i) => i !== policyToDeleteIndex);
    setPolicies(updatedPolicies);
    setErrors((prevErrors) => {
      const newPoliciesErrors = [...prevErrors.policies];
      newPoliciesErrors.splice(policyToDeleteIndex, 1);
      return { ...prevErrors, policies: newPoliciesErrors };
    });
    setOpenDialog(false);
    setPolicyToDeleteIndex(null); // Reset the index
  };
  
  const handleCancelDelete = () => {
    setOpenDialog(false);
    setPolicyToDeleteIndex(null); // Reset the index
  };

  return (
    <Box>
      <Dialog open={openDialog} onClose={handleCancelDelete}>
  <DialogTitle>Confirm Delete</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Are you sure you want to delete this policy?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCancelDelete} color="primary">
      Cancel
    </Button>
    <Button onClick={handleConfirmDelete} color="secondary">
      Confirm
    </Button>
  </DialogActions>
</Dialog>
    <Box sx={{
      display: "flex",
      backgroundColor: "#EDEDED",
      height: "100vh",
      overflow: "hidden" // Ensure no content gets hidden by scrollbars
    }}>
      <Sidebar title="Human Rights" />
      <Box sx={{
        p: 2,
        mt: 3,
        width: "100%",
        height: "100vh", // Full viewport height
        overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start", // Adjust to start at the top of the box
      }}>
        <Typography variant="h5" sx={{
          fontWeight: "bold",
          zIndex: 1,
          padding: "10px",
          borderRadius: "4px",
          alignSelf: "flex-start",
          ml: "5rem"
        }}>
        SOCIAL: HUMAN RIGHTS
        </Typography>

        <Box sx={{width: '80%'}}>
        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '2px', marginTop: '20px',  width: '100%' }}>
          Human Rights Assessments
        </Typography>
        <Box display="flex" gap={2} mb={2} sx={{width: '100%'}}>
          <TextField
            variant="outlined"
            placeholder="Number of assessments done"
            value={numberOfAssessments}
            onChange={(e) => setNumberOfAssessments(e.target.value)}
            sx={{
              width: '100%',
              backgroundColor: '#EDEDED',
              borderRadius: '4px',
              borderColor: errors.numberOfAssessments ? 'red' : '#6b6c6d',
              '& .MuiOutlinedInput-root': {
                height: '50px',
                '& fieldset': {
                  borderColor: errors.numberOfAssessments ? 'red' : '#6b6c6d',
                },
              },
            }}
            InputProps={{ sx: { padding: '0 10px' } }}
            error={!!errors.numberOfAssessments}
            // helperText={errors.numberOfAssessments}
            helperText={
              <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                {errors.numberOfAssessments}
              </Typography>
            }
          />
          <TextField
            variant="outlined"
            placeholder="% of operations assessed"
            value={percentageOperationsAssessed}
            onChange={(e) => setPercentageOperationsAssessed(e.target.value)}
            sx={{
              width: '100%',
              backgroundColor: '#EDEDED',
              borderRadius: '4px',
              borderColor: errors.percentageOperationsAssessed ? 'red' : '#6b6c6d',
              '& .MuiOutlinedInput-root': {
                height: '50px',
                '& fieldset': {
                  borderColor: errors.percentageOperationsAssessed ? 'red' : '#6b6c6d',
                },
              },
            }}
            InputProps={{ sx: { padding: '0 10px' } }}
            error={!!errors.percentageOperationsAssessed}
            // helperText={errors.percentageOperationsAssessed}
            helperText={
              <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                {errors.percentageOperationsAssessed}
              </Typography>
            }
          />
        </Box>

        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '20px 0px 2px 0px',  width: '80%' }}>
          Policies and Procedures on Human Rights
        </Typography>
        {policies.map((policy, index) => (
            <Box
            key={index}
            sx={{ width: "110%", display: "flex", gap: 1 }}
          >
          <Box display="flex" gap={2} mb={2} key={index} sx={{ width: '91%'}}>
           
            <TextField
              variant="outlined"
              placeholder="Existence"
              multiline
              value={policy.existence}
              rows={3} // Adjust the number of rows as needed
              onChange={(e) => {
                const updatedPolicies = [...policies];
                updatedPolicies[index].existence = e.target.value;
                setPolicies(updatedPolicies);
              }}
              sx={{
                width: '100%',
                backgroundColor: '#EDEDED',
                borderRadius: '4px',
                borderColor: errors.policies[index] ? '#D34040' : '#6b6c6d',
                '& .MuiOutlinedInput-root': {
                  height: 'auto', // Set height to auto for multiline
                  '& fieldset': {
                    borderColor: errors.policies[index] ? '#D34040' : '#6b6c6d',
                  },
                },
                overflow: 'hidden', // Prevent overflow
                textOverflow: 'ellipsis', // Handle overflow
              }}
              error={!!errors.policies[index]}
              // helperText={errors.policies[index]}
              helperText={
                <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                  {errors.policies[index]}
                </Typography>
              }
              
            />

            <TextField
              variant="outlined"
              placeholder="Implementation"
              multiline
              value={policy.implementation}
              rows={3} // Adjust the number of rows as needed
              onChange={(e) => {
                const updatedPolicies = [...policies];
                updatedPolicies[index].implementation = e.target.value;
                setPolicies(updatedPolicies);
              }}
              sx={{
                width: '100%',
                backgroundColor: '#EDEDED',
                borderRadius: '4px',
                borderColor: errors.policies[index] ? 'red' : '#6b6c6d',
                '& .MuiOutlinedInput-root': {
                  height: 'auto', // Set height to auto for multiline
                  '& fieldset': {
                    borderColor: errors.policies[index] ? 'red' : '#6b6c6d',
                  },
                },
                overflow: 'hidden', // Prevent overflow
                textOverflow: 'ellipsis', // Handle overflow
              }}
              error={!!errors.policies[index]}
              // helperText={errors.policies[index]}
              helperText={
                <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                  {errors.policies[index]}
                </Typography>
              }
            />
            </Box>
             {index > 0 && (
            <IconButton onClick={() => handleDeletePolicy(index)} sx={{ mt: 1}}>
              <DeleteIcon />
            </IconButton>
              )}
                         
          </Box>
        ))}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddPolicy}
          sx={{
            alignSelf: "flex-end",
            color: "#74757E",
            borderColor: "#74757E",
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            "&:hover": {
              backgroundColor: "rgba(25, 118, 210, 0.04)",
              color: "#115293",
              borderColor: "#115293",
            },
          }}
        >
          Add
        </Button>
        </Box>

        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '20px 0px 2px 0px', width: '100%' }}>
          Incidents of Human Rights Violations
        </Typography>
        <Box display="flex" gap={2} mb={2} sx={{ width: '100%'}}>
          <TextField
            variant="outlined"
            placeholder="Total number of incidents raised"
            value={totalIncidents}
            onChange={(e) => setTotalIncidents(e.target.value)}
            sx={{
              width: '100%',
              backgroundColor: '#EDEDED',
              borderRadius: '4px',
              borderColor: errors.totalIncidents ? '#D34040' : '#6b6c6d',
              '& .MuiOutlinedInput-root': {
                height: '50px',
                '& fieldset': {
                  borderColor: errors.totalIncidents ? '#D34040' : '#6b6c6d',
                },
              },
            }}
            InputProps={{ sx: { padding: '0 10px' } }}
            error={!!errors.totalIncidents}
            // helperText={errors.totalIncidents}
            helperText={
              <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                {errors.totalIncidents}
              </Typography>
            }
            
          />
          <TextField
            variant="outlined"
            placeholder="% of resolution"
            value={percentageResolution}
            onChange={(e) => setPercentageResolution(e.target.value)}
            sx={{
              width: '100%',
              backgroundColor: '#EDEDED',
              borderRadius: '4px',
              borderColor: errors.percentageResolution ? '#D34040' : '#6b6c6d',
              '& .MuiOutlinedInput-root': {
                height: '50px',
                '& fieldset': {
                  borderColor: errors.percentageResolution ? '#D34040' : '#6b6c6d',
                },
              },
            }}
            InputProps={{ sx: { padding: '0 10px' } }}
            error={!!errors.percentageResolution}
            // helperText={errors.percentageResolution}
            helperText={
              <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px' }}>
                {errors.percentageResolution}
              </Typography>
            }
          />
          
        </Box>
        <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
    </Box>
  );
};

export default HumanRight;
