import React from "react";
import DashboardLayout from "../../../../Layout/DashboardLayout";
import TransportationDistrubution from "./TransportationDistrubution";
const index = () => {
  return (
    <DashboardLayout>
      <TransportationDistrubution />
      {/* <ElectricityConsumptionByFacility/> */}
    </DashboardLayout>
  );
};

export default index;
