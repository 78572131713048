import React, { useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";
import {
  validateNumber,
  validatePercentage,
} from "../../../utils/validateFields";
import { numberError, percentageError } from "../../../utils/validationMessage";
import {ProgressContext} from "../ContextAPI";

const GHGEmissions = () => {
  const navigate = useNavigate();
  const { setUpdate } = useContext(ProgressContext);
  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    scope1: { value: "" },
    scope2: { value: "" },
    scope3: { value: "" },
    ghgEmissionIntensity: { value: "" },
    reductionInGhgEmissions: { value: "" },
    carbonOffsetting: { value: "" },
    companyId,
  });

  const [errors, setErrors] = useState({});

  // GHG data for creating input cards
  const ghgData = [
    {
      title: "Scope 1",
      placeholder: "1000",
      name: "scope1",
      adornmentText: "tCO2e",
    },
    {
      title: "Scope 2",
      placeholder: "1000",
      name: "scope2",
      adornmentText: "tCO2e",
    },
    {
      title: "Scope 3",
      placeholder: "1000",
      name: "scope3",
      adornmentText: "tCO2e",
    },
    {
      title: "GHG Emissions Intensity",
      placeholder: "Emissions per unit of output ",
      name: "ghgEmissionIntensity",
      adornmentText: "CO2e per unit",
    },
    {
      title: "Reduction in GHG Emissions",
      placeholder: "% reduction over year",
      name: "reductionInGhgEmissions",
      adornmentText: "%",
    },
    {
      title: "Carbon Offsetting",
      placeholder: "Metric tons CO2e offset",
      name: "carbonOffsetting",
      adornmentText: "Metric tons CO2e offset",
    },
  ];

  // Navigating back
  const Back = () => {
    navigate("/environment/energy-management");
  };

  // Validating input fields
  const validateForm = () => {
    const newErrors = {};

    ghgData.forEach((item) => {
      const value = formData[item.name]?.value;
      if (item.name === "reductionInGhgEmissions") {
        if (!validatePercentage(value)) newErrors[item.name] = percentageError;
      } else if (!validateNumber(value)) {
        newErrors[item.name] = numberError;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Fetching data if already exists
  useEffect(() => {
    setUpdate("ESG");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/ghg-emissions/${companyId}`
        );
        console.log(response);
        setFormData(response.data.ghgEmissionsData || formData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // Save the formData and make the post request
  const Save = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/ghg-emissions${
          isExist ? `/${companyId}` : ""
        }`,
        data: formData,
      });
      // alert(response.data.message);
      navigate("/environment/water-management");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);
    }
  };

  // Handling the input value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: {
        ...prevFormData[name],
        value: value ,
      },
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error on change
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#EDEDED",
        height: "100vh",
        overflow: "hidden", // Ensure no content gets hidden by scrollbars
      }}
    >
      <Sidebar title="Greenhouse Gas Emissions" />
      <Box
        sx={{
          p: 2,
          mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            zIndex: 1,
            padding: "10px",
            borderRadius: "4px",
            alignSelf: "flex-start",
            ml: "5rem",
          }}
        >
          ENVIRONMENT: GREENHOUSE GAS EMISSIONS
        </Typography>
        <Box sx={{ mt: 3, width: "80%" }}>
          {/* Maps GHG data */}
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
            Total GHG Emissions
          </Typography>
          {ghgData.map((item, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <InputCard
                key={index}
                title={item.title}
                placeholder={item.placeholder}
                name={item.name}
                value={formData[item.name].value}
                handleChange={handleChange}
                borderColor={errors[item.name] && "#D34040"}
                adornmentText={item.adornmentText} // Pass error prop
              />
              {errors[item.name] && (
                <Typography variant="body2" color="#D34040" sx={{ mt: 1 }}>
                  {errors[item.name]}
                </Typography>
              )}
            </Box>
          ))}
          {/* Back, Save Buttons */}
          <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};

export default GHGEmissions;
