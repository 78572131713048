import { Button } from '@mui/material'
import React from 'react'

const ResetButton = ({handleReset}) => {
  return (
    <Button
        variant="contained"
        onClick={handleReset}
        sx={{
          marginTop: '20px',
          backgroundColor: '#FFFFFF',
          color: 'BLACK',
          '&:hover': { backgroundColor: '#FFFFEF' },
          width: '10%',
        }}
      >
        Reset
      </Button>
  )
}

export default ResetButton