import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'

const RadioField = ({title,fieldName,handleChange,formData,value}) => {
  return (
    <Box>
         <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          {title}
        </Typography><RadioGroup
    row
    value={value}
    name={fieldName}
    onChange={handleChange}
  >
    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
    <FormControlLabel value="no" control={<Radio />} label="No" />
  </RadioGroup>
  </Box>
  )
}

export default RadioField