import { Box, Pagination } from '@mui/material'
import React from 'react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const PaginationBox = ({totalPages,page,handleChangePage,StyledPaginationItem}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 3,
        backgroundColor: "#ffffff",
        width: "80%",
        margin: "2% -0.5% 0% 23%",
        borderRadius: "2%",
        padding: "3px",
      }}
    >
      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        renderItem={(item) => (
          <StyledPaginationItem
          // <PaginationItem
            {...item}
            components={{
              previous: () => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mr: 27,
                  }}
                >
                  <ArrowBackIcon sx={{ mr: 0.5, color: "green" }} />
                  Previous
                </Box>
              ),
              next: () => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ml: 27,
                  }}
                >
                  Next
                  <ArrowForwardIcon sx={{ ml: 0.5, color: "green" }} />
                </Box>
              ),
            }}
            sx={{
              "& .MuiPaginationItem-root": {
                mx: 1,
              },
              "& .MuiPagination-ul": {
                display: "flex",
                alignItems: "center",
              },
            }}
          />
        )}
      />
      </Box>
    </Box>
  )
}

export default PaginationBox;