import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useRef } from "react";

const MaterialCategory = ({
  category,
  formData,
  handleChange,
  renderChips,
  open,
  setOpen,
  error,
  borderColor,
}) => {
  const selected = Array.isArray(formData[category.key])
    ? formData[category.key]
    : [];
  const dropdownRef = useRef(null);

  const handleDropdownOpen = () => {
    // Scroll to the top of the dropdown when it's opened
    if (dropdownRef.current) {
      dropdownRef.current.scrollTop = 0;
    }
    setOpen((prev) => ({ ...prev, [category.key]: true }));
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h8" sx={{ fontWeight: "bold" }}>
        {category.title}
      </Typography>
      {renderChips(selected, category.key)}
      <FormControl
        sx={{
          width: "100%",
          backgroundColor: "#EDEDED",
          borderColor: error ? borderColor : "inherit",
          borderRadius: 1,
          // mt:5
        }}
      >
        <Select
          sx={{ mt: "-20px" }}
          multiple
          input={<OutlinedInput />}
          value={selected}
          onChange={(e) => {
            handleChange(e, category.key);
          }}
          // ref={dropdownRef}
          MenuProps={{
            PaperProps: {
              style: {
                overflowY: "auto",
              },
            },
          }}
          renderValue={() => "Select"} // Always show "Select" in the text field
          open={open[category.key]}
          onOpen={() => setOpen((prev) => ({ ...prev, [category.key]: true }))}
          onClose={() =>
            setOpen((prev) => ({ ...prev, [category.key]: false }))
          }
        >
          {category.list.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && (
        <Typography variant="body2" color="error" sx={{mt:1}}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default MaterialCategory;
