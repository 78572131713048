import React from "react";
import Dashboard from "./Dashboard";
import DashboardLayout from "../../../Layout/DashboardLayout";

const index = () => {
  return (
    <DashboardLayout>
      <Dashboard />
    </DashboardLayout>
  );
};

export default index;
