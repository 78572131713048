import React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import BusinessTravel from "./BusinessTravel";
const index = () => {
  return (
    <DashboardLayout>
      <BusinessTravel />
    </DashboardLayout>
  );
};

export default index;
