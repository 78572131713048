// import React, { useState, useEffect } from 'react'; 
// import { TextField, Box, Typography } from '@mui/material';

// const RatedConsumptionFilter = ({ onApply }) => {
//   const [minConsumption, setMinConsumption] = useState('');
//   const [maxConsumption, setMaxConsumption] = useState('');

//   useEffect(() => {
//     // Trigger the onApply whenever minConsumption or maxConsumption changes
//     onApply(minConsumption, maxConsumption);
//   }, [minConsumption, maxConsumption, onApply]);

//   return (
//     // <Box sx={{ padding: "-10px" }}>
//     <Box sx={{m:"5%",mt:0}}>
//       {/* <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
//         Rated Consumption
//       </Typography> */}
//       <Typography
//         variant="h5"
//         gutterBottom
//         sx={{
//           fontWeight: 'bold',
//           marginBottom: '30px',
//           // m:"10%"
//          }}
//       >
//         Rated Consumption
//       </Typography>
//       <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
//         <TextField
//           label="Min"
//           variant="outlined"
//           size="small"
//           value={minConsumption}
//           onChange={(e) => setMinConsumption(e.target.value)}
//           sx={{ width: '135px' }}
//         />
//         <Typography variant="body1">to</Typography>
//         <TextField
//           label="Max"
//           variant="outlined"
//           size="small"
//           value={maxConsumption}
//           onChange={(e) => setMaxConsumption(e.target.value)}
//           sx={{ width: '135px' }}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default RatedConsumptionFilter;




// import React from 'react'; 
// import { TextField, Box, Typography } from '@mui/material';

// const RatedConsumptionFilter = ({ minConsumption, maxConsumption, setMinConsumption, setMaxConsumption }) => {
//   return (
//     <Box sx={{ m: "5%", mt: 0 }}>
//       <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '30px' }}>
//         Rated Consumption
//       </Typography>
//       <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
//         <TextField
//           label="Min"
//           variant="outlined"
//           size="small"
//           value={minConsumption}
//           onChange={(e) => setMinConsumption(e.target.value)}
//           sx={{ width: '135px' }}
//         />
//         <Typography variant="body1">to</Typography>
//         <TextField
//           label="Max"
//           variant="outlined"
//           size="small"
//           value={maxConsumption}
//           onChange={(e) => setMaxConsumption(e.target.value)}
//           sx={{ width: '135px' }}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default RatedConsumptionFilter;


import { TextField, Box, Typography, Button } from '@mui/material';

// const RatedConsumptionFilter = ({ minConsumption, maxConsumption, setMinConsumption, setMaxConsumption, onApply }) => {
    const RatedConsumptionFilter = ({ minConsumption, maxConsumption, setMinConsumption, setMaxConsumption,errors }) => {
  return (
    <Box sx={{ m: '5%', mt: 0 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '30px' }}>
        Rated Consumption
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
        <TextField
          label="Min"
          variant="outlined"
          size="small"
          value={minConsumption}
          onChange={(e) => setMinConsumption(e.target.value)}
          sx={{ width: '135px' }}
        />
        <Typography variant="body1">to</Typography>
        <TextField
          label="Max"
          variant="outlined"
          size="small"
          value={maxConsumption}
          onChange={(e) => setMaxConsumption(e.target.value)}
          sx={{ width: '135px' }}
        />
      </Box>
      {errors && (
            <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
              {errors}
            </Typography>
          )}
    </Box>
   
  );
};

export default RatedConsumptionFilter;