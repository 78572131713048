import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { api } from "../../../../../utils/api";
import { companyId1, userId } from "../../../../../utils/companyId";
import LinearProgress from "@mui/material/LinearProgress";
import { capitalizeFirstLetter } from "../../../../../utils/common";
import DescriptionIcon from "@mui/icons-material/Description"; // For CSV
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import FileField from "../../../../../Components/Masters/FileField";
function AddIndustrialProcess(props) {
  const [alreadyFacility, setAlreadyFacility] = useState(false);
  const [facilities, setFacilities] = useState(["Select"]); // Store list of facilities
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  
  const initialState = {
    facility: "",
    location: "",
    industryProcess: "",
    finalManufacturedProduct: "",
    remark: "",
    documents: [],
    companyId: companyId1,
    userId: userId,
  };
  const [fromValue, setFromValue] = useState(initialState);

  useEffect(() => {
    fetchFacilities();
    setFromValue({
      facility: props.selectedFacility?.facility?._id || "",
      location: props.selectedFacility?.location || "",
      industryProcess: props.selectedFacility?.industryProcess || "",
      finalManufacturedProduct:
        props.selectedFacility?.finalManufacturedProduct || "",
      remark: props.selectedFacility?.remark || "",
      // documents: props.selectedFacility?.documents || [],
      companyId: companyId1,
      userId: userId,
    });
    setUploadedFiles(props.selectedFacility?.documents ?? []);

    setAlreadyFacility(props.selectedFacility === null ? false : true);
  }, [props.selectedFacility]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form values
    setFromValue({
      ...fromValue,
      [name]: value,
    });

    // Clear the error for the specific field if there was one
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the specific field error
      }));
    }
  };

  const handleDeleteImage = (index) => {
    const updatedDocuments = fromValue.documents.filter((_, i) => i !== index);

    setFromValue((prevEquipment) => ({
      ...prevEquipment,
      documents: updatedDocuments,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    const {
      documents,
      location,
      industryProcess,
      finalManufacturedProduct,
      facility, // Add facility to validation
    } = fromValue;

    // Validate `Location`
    if (!location || location.trim() === "") {
      newErrors.location = "Location is required.";
    }

    // Validate `address`
    if (!industryProcess || industryProcess.trim() === "") {
      newErrors.industryProcess = "Industry process is required.";
    }

    // Validate `finalManufacturedProduct`
    if (!finalManufacturedProduct || finalManufacturedProduct.trim() === "") {
      newErrors.finalManufacturedProduct =
        "Final Manufacture Product is required.";
    }

    // Validate `Facility`
    if (!facility || facility === "Select Facility") {
      newErrors.facility = "Please select a facility.";
    }

    // Validate `Documents`
    // if (!documents || documents.length === 0) {
    //   newErrors.documents = "At least one document must be uploaded.";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    // if (files.length > 5) {
    //   alert("You can only upload a maximum of 5 files.");
    //   return;
    // }
    const totalFiles = fromValue?.documents?.length || 0;
    if (totalFiles + files.length > 5) {
      // alert("");
      setErrors({
        ...errors,
        documents: `You can only upload a maximum of 5 files in total.`,
      });
      return;
    }

    const allowedTypes = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls
      "image/png",
      // "image/jpeg", // For .jpg and .jpeg files
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB
    const maxWidth = 1200;
    const maxHeight = 800;

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        setErrors({
          ...errors,
          documents: `File type not allowed: ${file.name}`,
        });
        return;
      }

      // Check file size
      if (file.size > maxFileSize) {
        setErrors({
          ...errors,
          documents: `File size exceeds 5MB: ${file.name}`,
        });
        return;
      }

      // If the file is an image, check its dimensions
      if (file.type.startsWith("image/")) {
        const image = new Image();
        const fileURL = URL.createObjectURL(file);
        image.src = fileURL;

        // Wait for image to load to get dimensions
        try {
          await new Promise((resolve, reject) => {
            image.onload = () => {
              if (image.width > maxWidth || image.height > maxHeight) {
                setErrors({
                  ...errors,
                  documents: `Image dimensions exceed 1200x800: ${file.name}`,
                });
                URL.revokeObjectURL(fileURL); // Release memory
                reject(
                  new Error(`Image dimensions exceed 1200x800: ${file.name}`)
                );
              } else {
                URL.revokeObjectURL(fileURL); // Release memory
                resolve();
              }
            };

            image.onerror = () => {
              URL.revokeObjectURL(fileURL); // Release memory
              reject(new Error(`Unable to load image: ${file.name}`));
            };
          });
        } catch (error) {
          console.error(error.message); // Log the error message
          continue; // Skip this file and proceed to the next
        }
      }

      // Add the file to FormData
      formData.append("files", file);
    }

    try {
      setLoading(true);
      const uploadResponse = await axios.post(`${api}/cloud/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const uploadedUrls = uploadResponse.data.urls; // Assuming your backend sends back an array of URLs
      setFromValue((prev) => {
        // If documents already exist, append new ones, else just set the new URLs
        const updatedDocuments =
          prev.documents.length > 0
            ? [...prev.documents, ...uploadedUrls]
            : uploadedUrls;

        return {
          ...prev,
          documents: updatedDocuments,
        };
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error uploading files:", err);
    }
  };

  // Fetch facilities data from API
  const fetchFacilities = async () => {
    try {
      const response = await axios.get(`${api}/master/getAllFacility`);
      setFacilities(response.data.data);
    } catch (error) {
      console.log("Error fetching facilities:", error);
    }
  };

  const getDropDownFieldId = (data) => {
    if (typeof data === "string") {
      return data; // If it's a direct ID, return it
    }
    return data?._id || ""; // If it's an object, return the _id
  };

  const Save = () => {
    const dataToSend = { ...fromValue, documents: uploadedFiles };
    if (!validateForm()) {
      return;
    }

    dataToSend.location = capitalizeFirstLetter(dataToSend.location);
    dataToSend.finalManufacturedProduct = capitalizeFirstLetter(
      dataToSend.finalManufacturedProduct
    );
    dataToSend.industryProcess = capitalizeFirstLetter(
      dataToSend.industryProcess
    );
    dataToSend.companyId = companyId1;
    dataToSend.userId = userId;

    axios({
      method: alreadyFacility ? "put" : "post",
      url: alreadyFacility
        ? `${api}/master/updateIndustrialProcess`
        : `${api}/master/addIndustrialProcess`,
      data: dataToSend, // Send data only if it's a POST request
      params: alreadyFacility ? { Id: props.selectedFacility?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        resetForm();
        props.setAddFacility(false);
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        props.fetchFacilities(); // Call the fetchFacilities function after success
        resetForm();
        //  localStorage.setItem("roleId",res.data.data._id)
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          // If there is a message in the error response, use it
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const resetForm = () => {
    setFromValue(
      props.selectedFacility === null ? initialState : props.selectedFacility
    );
    // setFacilities([]);
    setErrors({}); // Clear any existing errors
    setAlreadyFacility(false); // Reset alreadyFacility state
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: "390px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {
            props.setAddFacility(false);
            props.fetchFacilities();
            resetForm();
          }}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "90%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          {props.selectedFacility?._id === undefined
            ? "Add Industrial Process"
            : "Edit Industrial Process"}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Location
        </Typography>
        <CustomTextField
          variant="outlined"
          fullWidth
          name="location"
          value={fromValue.location}
          onChange={handleChange}
          placeholder="Brief"
          error={!!errors.location} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.location ? "#D34040" : undefined },
          }}
        />
        {errors.location && (
          <Typography variant="body2" color="#D34040">
            {errors.location}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 1, mt: 1 }}
        >
          Facility
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          // value={getDropDownFieldId(fromValue.facility) || "Tap to Select"}
          value={
            fromValue.facility === "" || undefined
              ? "Tap to Select"
              : getDropDownFieldId(fromValue.facility)
          }
          onChange={handleChange}
          fullWidth
          name="facility"
          placeholder="Tap to Select"
          error={!!errors.facility} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.facility ? "#D34040" : undefined },
          }}
        >
          {/* {console.log("fromValue.facility",fromValue.facility)} */}
          <MenuItem
            value={
              fromValue.facility === "" || undefined
                ? "Tap to Select"
                : fromValue.facility
            }
            // Array.isArray(fromValue.facility)?
            // fromValue.facility.map((item) => item.facilityName).join(""):fromValue.facility}
            disabled
          >
            {/* Tap to Select */}
            {fromValue.facility === "" || undefined
              ? "Tap to Select"
              : fromValue.facility?.facilityName || fromValue.facility}
          </MenuItem>{" "}
          {facilities.map((item, i) => (
            <MenuItem key={i} value={item._id}>
              {item.facilityName}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.facility && (
          <Typography variant="body2" color="#D34040">
            {errors.facility}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Industry Process
        </Typography>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          fullWidth
          name="industryProcess"
          value={fromValue.industryProcess}
          onChange={handleChange}
          placeholder="Brief"
          error={!!errors.industryProcess} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.industryProcess ? "#D34040" : undefined },
          }}
        />
        {errors.industryProcess && (
          <Typography variant="body2" color="#D34040">
            {errors.industryProcess}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Final Manufactured Product
        </Typography>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          fullWidth
          name="finalManufacturedProduct"
          value={fromValue.finalManufacturedProduct}
          onChange={handleChange}
          placeholder="Brief"
          error={!!errors.finalManufacturedProduct} // Show error styling if there's an error
          InputLabelProps={{
            style: {
              color: errors.finalManufacturedProduct ? "#D34040" : undefined,
            },
          }}
        />
        {errors.finalManufacturedProduct && (
          <Typography variant="body2" color="#D34040">
            {errors.finalManufacturedProduct}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Remark
        </Typography>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          name="remark"
          value={fromValue.remark}
          onChange={handleChange}
          placeholder="Brief"
        />

        <FileField
          title={"Upload Documents"}
          name={"documents"}
          errors={errors["documents"]}
          fileUrls={uploadedFiles}
          progress={uploadProgress["documents"] || []}
          {...{ setUploadedFiles, setUploadProgress, setErrors }}
          // onDelete={handleDeleteFile}
        />
        {errors.documents ? (
          <Typography variant="body2" color="#D34040">
            {/* {errors.documents} */}
          </Typography>
        ) : (
          ""
        )}
        {/* <Typography
          variant="subtitle2"
          sx={{ fontWeight: "bold", mb: 1, mt: 1 }}
        >
          Upload Documents
        </Typography>

        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 2 }}>
          {fromValue?.documents?.length > 0 &&
            fromValue?.documents.map((doc, index) => {
              const fileName = doc.split("/").pop();
              const fileExtension = fileName.split(".").pop().toLowerCase();
              const isImage = ["jpg", "jpeg", "png"].includes(fileExtension);
              const isCsv = fileExtension === "csv";
              const isExcel = ["xls", "xlsx"].includes(fileExtension);

              return (
                <Box key={index} sx={{ position: "relative" }}>
                  {isImage ? (
                    <img
                      src={doc}
                      alt={`Document ${index + 1}`}
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        borderRadius: "4px",
                        marginBottom: "10px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "80px",
                        height: "80px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        marginBottom: "10px",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      {isCsv ? (
                        <DescriptionIcon
                          sx={{ fontSize: "20px", color: "#1A73E8" }}
                        />
                      ) : isExcel ? (
                        <FontAwesomeIcon
                          icon={faFileExcel}
                          style={{ color: "#0a8f08" }}
                        />
                      ) : null}
                      <Typography variant="caption" noWrap>
                        {fileName}
                      </Typography>
                    </Box>
                  )}
                  <IconButton
                    onClick={() => handleDeleteImage(index)}
                    sx={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      color: "white",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "10px" }} />
                  </IconButton>
                </Box>
              );
            })}
        </Box>

        {loading ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{
              border: "1px dashed grey",
              borderRadius: 1,
              p: 2,
              mt: -2,
              textAlign: "center",
              cursor: "pointer",
              display: fromValue?.documents?.length < 5 ? "block" : "none",
            }}
          >
            <input
              type="file"
              hidden
              id="upload-document"
              onChange={handleFileChange}
              multiple
            />
            <label htmlFor="upload-document">
              <Box display="flex" flexDirection="column" alignItems="center">
                <CloudUploadIcon />
                <Typography>Drop files here or click to upload</Typography>
                <Typography variant="caption">
                  Upload your file (CSV, Excel, PNG, max 5 MB, recommended size
                  1200x800 pixels).
                </Typography>
              </Box>
            </label>
          </Box>
        )}

        {errors.documents && (
          <Typography variant="body2" color="#D34040">
            {errors.documents}
          </Typography>
        )} */}

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addFacility}
        onClose={() => {
          props.setAddFacility(false);
          resetForm();
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddIndustrialProcess;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
