import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import axios from "axios";
import "../Scrollbar.css";

const LogisticVendorFilter = ({
  selectedLogisticVendor = [],
  setSelectedLogisticVendor,
  url,
  errors,
}) => {
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    // Fetch all logistic vendors from the backend
    const fetchVendors = async () => {
      try {
        const response = await axios.get(url);
        // Extract unique vendor names from the data
        const uniqueVendors = response.data.data
          ? [
              ...new Set(
                response.data.data.map(
                  (user) => user.logisticsVendor.logisticVendorLegalName
                )
              ),
            ]
          : [
              ...new Set(
                response.data.data.map(
                  (user) => user.logisticsVendor.logisticVendorLegalName
                )
              ),
            ];
        setVendors(uniqueVendors);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    fetchVendors();
  }, []);

  const handleVendorChange = (event) => {
    const selectedVendor = event.target.value;
    if (event.target.checked) {
      // Add vendor to selected list
      setSelectedLogisticVendor((prevSelected) => [
        ...prevSelected,
        selectedVendor,
      ]);
    } else {
      // Remove vendor from selected list
      setSelectedLogisticVendor((prevSelected) =>
        prevSelected.filter((vendor) => vendor !== selectedVendor)
      );
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", ml: 2, height: "90%" }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: "bold",
          marginBottom: "30px",
        }}
      >
        Logistic Vendor
      </Typography>
      <Box className="scrollable-container">
        {vendors.length > 0 ? (
          vendors.map((vendor, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={vendor}
                  checked={selectedLogisticVendor?.includes(vendor)}
                  onChange={handleVendorChange}
                />
              }
              label={vendor}
            />
          ))
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default LogisticVendorFilter;
