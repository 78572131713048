import React from 'react';
import DashboardLayout from '../../../Layout/DashboardLayout';
import ElectricityConsumption from './ConsumptionByFacility';
import ElectricityConsumptionByFacility from './ConsumptionByEquipment/ConsumptionByEquipment';
const index = () => {
    return (
        <DashboardLayout>
            <ElectricityConsumption />
            {/* <ElectricityConsumptionByFacility/> */}
        </DashboardLayout>
    )
}

export default index;
