import React from "react";
// import DashboardLayout from '../../../../Layout/DashboardLayout';
import DashboardLayout from "../../../../Layout/DashboardLayout";
import ConsumptionByFacility from "./ConsumptionFacility";
const index = () => {
  return (
    <DashboardLayout>
      <ConsumptionByFacility />
      {/* <ElectricityConsumptionByFacility/> */}
    </DashboardLayout>
  );
};

export default index;
