



import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputBase,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
// import contries from "../Roles and Permissions/Country.json";
import contries from "../../../Masters/Roles and Permissions/Country.json"
import { api } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import {textError,numberError,listError} from "../../../../utils/validationMessage";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from '@mui/material/LinearProgress';

import FileField from "../../../../Components/Masters/FileField";




function AddEquipment(props) {
  const [fileNames, setfileNames] = useState([]);
  const [alreadyEquipment, setAlreadyEquipment] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState([]); 
  const [industrialProcesses, setIndustrialProcesses] = useState([]);
  const [industrialEquipments, setIndustrialEquipments] = useState([]);
  const [calorificValues,setCalorificValues]=useState(["MJ/kg", "MJ/m3"]);
  const [typeOfEntries,settypeOfEntries]=useState([
            "Cumulative",
        "Individual"]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); 
  const [isFocused, setIsFocused] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
const initialState ={
    company: companyId,
    createdBy: "6711e910d4ff6a9e9029d2f9",
    industrialProcess:"Tap to Select",
    equipmentType: "Tap to Select",
    typeOfEntry:"Tap to Select",
    industrialEquipment: "Tap to Select",
    // quantityOfNaturalGasConsumed: "",
    quantityOfNaturalGasConsumed: {
      value: "", // For numeric value
      unit: "m3", // Default unit
    },
    hours: "",
    // calorificValue: "Tap to Select",
    calorificValue: {
        value: "", // For the numeric value
        unit: "Tap to Select" // For the unit, default to "Tap to Select"
    },
    remarks:"",
    documents: [],
  };
  const resetForm = () => {
    console.log("_idddddd",props.selectedEquipment?._id)
    setFromValue(props.selectedEquipment?._id === undefined?initialState:props.selectedEquipment);
    setErrors({});
  };

  const [fromValue, setFromValue] = useState(initialState);
  console.log("propsEquipment",props)

  



useEffect(() => {
    // Fetch the list of industrial processes
    const fetchIndustrialProcesses = async () => {
      try {
        const response = await axios.get(`${api}/master/getAllIndustrialProcess`);
        setIndustrialProcesses(response.data.data); // Assuming the data is in response.data.data
      } catch (error) {
        console.error("Error fetching industrial processes:", error);
      }
    };

    fetchIndustrialProcesses();

    setFromValue({
      company: companyId,
      createdBy: "6711e910d4ff6a9e9029d2f9",
      industrialProcess: props.selectedEquipment?.industrialProcess?._id ?? "",
      equipmentType: props.selectedEquipment?.equipmentType?._id ?? "",
      typeOfEntry: props.selectedEquipment?.typeOfEntry ?? "Tap to Select",
      // quantityOfNaturalGasConsumed: props.selectedEquipment?.quantityOfNaturalGasConsumed ?? "",
      quantityOfNaturalGasConsumed: {
        value: props.selectedEquipment?.quantityOfNaturalGasConsumed?.value ?? "",
        unit: props.selectedEquipment?.quantityOfNaturalGasConsumed?.unit ?? "m3", // Default to "m3"
      },
    
      calorificValue: {
        value: props.selectedEquipment?.calorificValue?.value ?? "", 
        unit: props.selectedEquipment?.calorificValue?.unit ?? "Tap to Select"
      },
      remarks: props.selectedEquipment?.remarks ?? "",
      // documents: props.selectedEquipment?.documents ?? [],
      industrialEquipment: props.selectedEquipment?.industrialEquipment?._id ?? (fromValue.typeOfEntry === "Individual" ? "Tap to Select" : ""),
      hours: props.selectedEquipment?.hours ?? (fromValue.typeOfEntry === "Individual" ? "" : ""),
    });
    setUploadedFiles(props.selectedEquipment?.documents?? []);
    setAlreadyEquipment(props.selectedEquipment === null ? false : true);
  }, [props.selectedEquipment]);


  useEffect(() => {
    // Fetch the list of equipment types
    const fetchEquipmentTypes = async () => {
      try {
        const response = await axios.get(`${api}/master/getAllEquipmentType`);
        setEquipmentTypes(response.data.data); // Assuming the data is in response.data.data
      } catch (error) {
        console.error("Error fetching equipment types:", error);
      }
    };

    fetchEquipmentTypes();
  }, []); 


  useEffect(() => {
    // Fetch the list of industrial processes
    const fetchIndustrialProcesses = async () => {
      try {
        const response = await axios.get(`${api}/master/getAllIndustrialProcess`);
        setIndustrialProcesses(response.data.data); // Assuming the data is in response.data.data
      } catch (error) {
        console.error("Error fetching industrial processes:", error);
      }
    };

    fetchIndustrialProcesses();
  }, []);


  useEffect(() => {
    // Fetch the list of equipment types
    const fetchAllIndustrialEquipment = async () => {
      try {
        const response = await axios.get(`${api}/master/getAllIndustrialEquipment`);
        setIndustrialEquipments(response.data.data); // Assuming the data is in response.data.data
      } catch (error) {
        console.error("Error fetching equipment types:", error);
      }
    };

    fetchAllIndustrialEquipment();
  }, []);


 
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("nam===e", name, value);
    if (name === "typeOfEntry" && value !== "Individual") {
      setFromValue((prev) => ({
        ...prev,
        hours: "", // Clear hours if not 'Individual'
        industrialEquipment: "", // Clear industrialEquipment if not 'Individual'
      }));
    }
    setFromValue({
      ...fromValue,
      [name]: value,
    });
  };


  const handleQuantityChange = (e) => {
    const { name, value } = e.target;

    setFromValue((prev) => ({
      ...prev,
      quantityOfNaturalGasConsumed: {
        ...prev.quantityOfNaturalGasConsumed,
        [name]: value,
      },
    }));
  };
 
  const handleCalorificChange = (event, type) => {
    const { value } = event.target;
  
    setFromValue((prev) => ({
      ...prev,
      calorificValue: {
        ...prev.calorificValue,
        [type]: value, // Update either 'value' or 'unit'
      },
    }));
  };



const validateForm = () => {
    const newErrors = {};
  
    const {
      documents,
      industrialProcess,
      equipmentType,
      typeOfEntry,
      industrialEquipment,
      quantityOfNaturalGasConsumed,
      hours,
      calorificValue,
    } = fromValue;
  

    if (fromValue.typeOfEntry === "Individual") {
      if (!hours || isNaN(hours) || parseFloat(hours) <= 0) {
        newErrors.hours = "Valid hours are required.";
      }
      if (!industrialEquipment || industrialEquipment.trim() === "" || industrialEquipment.trim() === "Tap to Select") {
        newErrors.industrialEquipment = "Please select a valid industrial equipment.";
      }
    }
  
    // Validate `quantityOfNaturalGasConsumed`
    // if (
    //   !quantityOfNaturalGasConsumed ||
    //   isNaN(quantityOfNaturalGasConsumed) ||
    //   parseFloat(quantityOfNaturalGasConsumed) <= 0
    // ) {
    //   newErrors.quantityOfNaturalGasConsumed = "Valid quantity of natural gas consumed is required.";
    // }
  
    
  
    // Validate `documents`
    if (!documents || documents.length === 0) {
      newErrors.documents = "At least one document must be uploaded.";
    }
  
  
  
    // Validate `typeOfEntry`
    if (!typeOfEntry || typeOfEntry.trim() === "" || typeOfEntry.trim() === "Tap to Select") {
      newErrors.typeOfEntry = "Please select a valid entry type.";
    }
  
    // Validate `industrialProcess`
    if (!industrialProcess || industrialProcess.trim() === "" || industrialProcess.trim() === "Tap to Select") {
      newErrors.industrialProcess = "Please select a valid industrial process.";
    }
  
    // Validate `equipmentType`
    if (!equipmentType || equipmentType.trim() === "" || equipmentType.trim() === "Tap to Select") {
      newErrors.equipmentType = "Please select a valid equipment type.";
    }

     // Validate `quantityOfNaturalGasConsumed.value`
     if (!quantityOfNaturalGasConsumed.value || isNaN(quantityOfNaturalGasConsumed.value) || parseFloat(quantityOfNaturalGasConsumed.value) <= 0) {
      newErrors.quantityOfNaturalGasConsumed = "Valid quantity of natural gas consumed is required.";
    }
  
   
  


if (
    fromValue.calorificValue.value &&
    typeof fromValue.calorificValue.value === "string" &&
    fromValue.calorificValue.value.trim() === ""
  ) {
    errors.calorificValue = "Calorific Value cannot be empty.";
  }

if (!fromValue.calorificValue.unit || fromValue.calorificValue.unit === "Tap to Select") {
  newErrors.calorificUnit = "Please select a unit";
}
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  
  const handleModule =(e)=>{
    const {name,value}=e.target
  console.log(e.key,value,"forModule")
    setFromValue({
      ...fromValue,
      [name]:value
    })
  }


  const handleDeleteImage = (index) => {
    // Create a new documents array without the deleted item
    console.log(index,"indexValueForDeleteImage",fromValue.documents,fromValue.documents.filter((_, i) => i !== index))
    const updatedDocuments = fromValue.documents.filter((_, i) => i !== index);

    // Update the state with the modified documents
    setFromValue((prevEquipment) => ({
      ...prevEquipment,
      documents: updatedDocuments,
    }));
  };


  const handleFileChange = async (e) => {
    const files = e.target.files;
    console.log("filesOpen",files,files.FileList)
    if (!files || files.length === 0) return;
    const fileNames = Array.from(files).map(file => file.name);
    setfileNames([...fileNames,fileNames])
    // Limit to max 5 files
    if (files.length > 5) {
      alert("You can only upload a maximum of 5 files.");
      return;
    }

    const allowedTypes = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls,
      "image/png",
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        alert(`File type not allowed: ${file.name}`);
        return;
      }

      // Check file size
      if (file.size > maxFileSize) {
        alert(`File size exceeds 5MB: ${file.name}`);
        return;
      }
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      setLoading(true)
      const uploadResponse = await axios.post(`${api}/cloud/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const uploadedUrls = uploadResponse.data.urls; // Assuming your backend sends back an array of URLs
      // setFromValue((prev) => ({
        
      //   ...prev,
      //   documents: uploadedUrls,
      // }));


      setFromValue((prev) => {
        // If documents already exist, append new ones, else just set the new URLs
        const updatedDocuments = prev.documents.length > 0 
          ? [...prev.documents, ...uploadedUrls] 
          : uploadedUrls;
  
        return {
          ...prev,
          documents: updatedDocuments,
        };
      });
      setLoading(false);
    } catch (err) {
      setLoading(false)
      console.error("Error uploading files:", err);
    }
  };

 

  const Save = async () => {
    console.log("Submitting data");
  
    const { selectedEquipment, setAddEquipment, fetchHeatEquipment, setsnackOpen } = props;
  
    // Validate form before proceeding
    if (!validateForm()) {
      return;
    }
  
    // Prepare payload based on conditions
    const payload = { 
      ...fromValue,
      documents:uploadedFiles,
     };
  
    // Remove `industrialEquipment` and `hours` if `typeOfEntry` is not "Individual"
    if (fromValue.typeOfEntry !== "Individual") {
      delete payload.industrialEquipment;
      delete payload.hours;
    }
  
    // Determine API endpoint and HTTP method
    const isUpdate = alreadyEquipment;
    const apiUrl = isUpdate
      ? `${api}/stationaryCombustion/updateNaturalGas`
      : `${api}/stationaryCombustion/addNaturalGas`;
  
    try {
      const response = await axios({
        method: isUpdate ? "put" : "post",
        url: apiUrl,
        data: payload,
        params: isUpdate ? { Id: selectedEquipment?._id } : null,
      });
  
      console.log("Response:", response.data);
  
      // Handle successful response
      setAddEquipment(false);
      fetchHeatEquipment();
      resetForm();
      setFromValue(initialState);
  
      // Show success message
      setsnackOpen({
        open: true,
        message: response.data.message,
        severity: "success",
      });
  
    } catch (error) {
      console.error("Request failed:", error);
  
      // Show error message from response, if available
      setsnackOpen({
        open: true,
        message: error?.response?.data?.message || "An error occurred. Please try again.",
        severity: "error",
      });
    }
  };
  
  

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{width:400,
        // maxWidth:"100%",
        height: '100vh',
        display:"flex",justifyContent:"space-btween",
          backgroundColor:"transparent"
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {props.setAddEquipment(false);resetForm();}}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll"}}>
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          {props.selectedEquipment?._id === undefined?
          " Add Data"
             : "Edit Data"}
        </Typography>

        

    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        Industrial Process
        </Typography>
       
        <CustomTextField
           select
          variant="outlined"
          value = {fromValue.industrialProcess==="" ? "Tap to Select" : fromValue.industrialProcess}
          onChange={handleChange}
          fullWidth
          name="industrialProcess"
          placeholder="industrialProcess"
          error={!!errors.industrialProcess} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.industrialProcess ? "#D34040" : undefined },
          }}
        >
        <MenuItem value="Tap to Select" disabled>
         Tap to Select
       </MenuItem>
        {industrialProcesses.map((process) => (
          <MenuItem key={process._id} value={process._id}>
            {process.industryProcess}
          </MenuItem>
        ))}
       
        </CustomTextField>
        {errors.industrialProcess && (
          <Typography variant="body2" color="#D34040">
            {errors.industrialProcess}
          </Typography>
        )}

    



    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
    Equipment Type
        </Typography>
       
        <CustomTextField
           select
          variant="outlined"
          value = {fromValue.equipmentType==="" ? "Tap to Select" : fromValue.equipmentType}
          onChange={handleChange}
          fullWidth
          name="equipmentType"
          placeholder="equipmentType"
          error={!!errors.equipmentType} 
          InputLabelProps={{
            style: { color: errors.equipmentType ? "#D34040" : undefined },
          }}
        >
         <MenuItem value="Tap to Select" disabled>
            Tap to Select
        </MenuItem>
        {equipmentTypes.map((type) => (
          <MenuItem key={type._id} value={type._id}>
            {type.name}
          </MenuItem>
        ))}
        </CustomTextField>
        {errors.equipmentType && (
          <Typography variant="body2" color="#D34040">
            {errors.equipmentType}
          </Typography>
        )}


    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Type Of Entry
        </Typography>
       
        <CustomTextField
           select
          variant="outlined"
          value={fromValue.typeOfEntry}
          onChange={handleChange}
          fullWidth
          name="typeOfEntry"
          placeholder="typeOfEntry"
          error={!!errors.typeOfEntry} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.typeOfEntry ? "#D34040" : undefined },
          }}
        >
           <MenuItem value={fromValue.typeOfEntry==="" ? "Tap to Select" :fromValue.typeOfEntry} disabled>
      {fromValue.typeOfEntry==="" ? "Tap to Select" :fromValue.typeOfEntry} 
  </MenuItem>
  {typeOfEntries.map((item,index)=>{
      return <MenuItem key={index} value={item}>
       {item}
       </MenuItem> 
      })}
        </CustomTextField>
        {errors.typeOfEntry && (
          <Typography variant="body2" color="#D34040">
            {errors.typeOfEntry}
          </Typography>
        )}


    {fromValue.typeOfEntry === "Individual" && (
        <Box>
    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
     Equipment
        </Typography>
       
        <CustomTextField
           select
          variant="outlined"
          value = {fromValue.industrialEquipment==="" ? "Tap to Select" : fromValue.industrialEquipment}
          onChange={handleChange}
          fullWidth
          name="industrialEquipment"
          placeholder="industrialEquipment"
          error={!!errors.industrialEquipment} 
          InputLabelProps={{
            style: { color: errors.industrialEquipment ? "#D34040" : undefined },
          }}
        >
        <MenuItem value="Tap to Select" disabled>
          Tap to Select
        </MenuItem>
        {industrialEquipments.map((type) => (
          <MenuItem key={type._id} value={type._id}>
            {type.name}
          </MenuItem>
        ))}
        </CustomTextField>
        {errors.industrialEquipment && (
          <Typography variant="body2" color="#D34040">
            {errors.industrialEquipment}
          </Typography>
        )}
        </Box>
    )}


        


        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Quantity Consumed
        </Typography>
       
           <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
         border: `1px solid ${isFocused ? 'black' : '#ddd'}`, // Blue when focused, default to light grey
        // border: isFocused ? '2px solid black' : '1px solid #ddd',
        borderRadius: '4px',
        padding: '1px',
        transition: 'border 0.3s ease', // Smooth transition for border change
        '&:hover': {
          border: '1px solid black', // Black when hovered
        },
      }}
      onFocus={() => setIsFocused(true)} // Set focus state to true
      onBlur={() => setIsFocused(false)} // Set focus state to false
      tabIndex={0} // Make Box focusable
    >
      <InputBase
        type="text"
        name="value" // This corresponds to the value field in quantityConsumed
        value={fromValue.quantityOfNaturalGasConsumed?.value || ''} // Default to empty string
        onChange={handleQuantityChange} // Call the change handler
        placeholder="Enter Quantity Consumed"
        sx={{
          flex: 1,
          padding: '4px',
          border: 'none',
          outline: 'none',
        }}
      />
      <Typography
        sx={{
          padding: '6px 10px',
          borderLeft: '1px solid #d2d2d2', // Add a dividing line
          whiteSpace: 'nowrap',
        }}
      >
        m³
      </Typography>
    </Box>
        {errors.quantityOfNaturalGasConsumed && (
          <Typography variant="body2" color="#D34040">
            {errors.quantityOfNaturalGasConsumed}
          </Typography>
        )}

        
        

{fromValue.typeOfEntry === "Individual" && (
  <Box>
    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Hours
        </Typography>
        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.hours}
          onChange={handleChange}
          fullWidth
          name="hours"
          placeholder="hours"
          error={!!errors.hours} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.hours ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.hours && (
          <Typography variant="body2" color="#D34040">
            {errors.hours}
          </Typography>
        )}
        </Box>
      )}


<Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
  Calorific Value
</Typography>

<Box sx={{ display: "flex", gap: 2 }}>
  {/* Input Field for Calorific Value */}
  <CustomTextField
    variant="outlined"
    value={fromValue.calorificValue.value} // Access the value
    onChange={(e) => handleCalorificChange(e, 'value')} // Handle change
    name="calorificValue"
    placeholder="Enter Value"
    error={!!errors.calorificValue} // Check for errors
    InputLabelProps={{
      style: { color: errors.calorificValue ? "#D34040" : undefined },
    }}
    fullWidth
    sx={{ flex: 1 }}
  />
  
  {/* Dropdown for Unit Selection */}
  <CustomTextField
    select
    variant="outlined"
    value={fromValue.calorificValue.unit || "Tap to Select"} // Default to "Tap to Select"
    onChange={(e) => handleCalorificChange(e, 'unit')} // Handle change
    name="calorificUnit"
    error={!!errors.calorificUnit} // Check for errors
    InputLabelProps={{
      style: { color: errors.calorificUnit ? "#D34040" : undefined },
    }}
    fullWidth
    sx={{ flex: 1 }}
  >
    <MenuItem value="Tap to Select" disabled>
      Tap to Select
    </MenuItem>
    {calorificValues.map((unit, index) => (
      <MenuItem key={index} value={unit}>
        {unit}
      </MenuItem>
    ))}
  </CustomTextField>
</Box>

{/* Show validation messages */}
{errors.calorificValue && (
  <Typography variant="body2" color="#D34040">
    {errors.calorificValue}
  </Typography>
)}
{errors.calorificUnit && (
  <Typography variant="body2" color="#D34040">
    {errors.calorificUnit}
  </Typography>
)}





         <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 2 }}
        >
          Remark
        </Typography>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          name="remarks"
          value={fromValue.remarks}
          onChange={handleChange}
          placeholder="Brief"
        />

        <FileField
          title={"Upload Documents"}
          name={"documents"}
          errors={errors["documents"]}
          fileUrls={uploadedFiles}
          progress={uploadProgress["documents"] || []}
          {...{setUploadedFiles,setUploadProgress,setErrors}}
          // onDelete={handleDeleteFile}
        />

        {errors.documents ? (
          <Typography variant="body2" color="#D34040">
            {errors.documents}
          </Typography>
        ) : (
          ""
        )}
        
      
        <Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0a3f73",
            textTransform: "none",
            marginTop: "10%",
          }}
          onClick={Save}
        >
          Submit
        </Button>
        </Box>
       
        {/* </Box> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addEquipment}
        onClose={() =>{ props.setAddEquipment(false); resetForm();}}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddEquipment;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
