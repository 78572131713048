import React,{useEffect, useState,useContext} from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './Risk.css';
import axios from 'axios';
import {api} from '../../../utils/api';
import { companyId } from "../../../utils/companyId";
import {unitError,listError,textError,percentageError} from "../../../utils/validationMessage";
import ActionButtons from '../InitialKickoff/MaterialTopics/ActionButtons';
import {ProgressContext} from "../ContextAPI";

const RiskManagement = () => {
  const { setUpdate } = useContext(ProgressContext);
  const[isExist,setisExist]=useState(false);
  const [formData,setFromData]=useState({
    companyId:companyId,
    enterpriseRiskMangementFramework:{
          radio:"yes",
          Scope:""
        },
        indentificationMitigationEsgRisk:{
          esg_risk:[""],
          mitigation:[""]
        },
        IncidenceResponsePlan:{
          existence:[""],
          effectiveness:[""],

        },

});
const [DeleteIndex,setIndexDelete]=useState(0);
const[addLength,SetAddLength]=useState(0);
const [openDialog, setOpenDialog] = useState({
  });
const [fieldToRemove, setFieldToRemove] = React.useState({
  fieldName: "",
  index: null,
});
const [errors, setErrors] = useState({});
const handleChangeRisk = (e)=>{
      const{name,value}=e.target
      setFromData({
        ...formData,
        enterpriseRiskMangementFramework:{
          ...formData.enterpriseRiskMangementFramework,
          [name]:value
        },
      })

}
const validateForm = () => {
  const newErrors = {};
  
  const { 
    enterpriseRiskMangementFramework,
    indentificationMitigationEsgRisk,
    IncidenceResponsePlan
  } = formData;

  // Validate `enterpriseRiskMangementFramework`
  // if (!enterpriseRiskMangementFramework.radio || enterpriseRiskMangementFramework.radio.trim() === "") {
  //   newErrors.enterpriseRiskMangementFrameworkRadio = textError;
  // }
  if (!enterpriseRiskMangementFramework.Scope && formData.enterpriseRiskMangementFramework.radio==="yes" || enterpriseRiskMangementFramework.Scope.trim() === "" && formData.enterpriseRiskMangementFramework.radio==="yes") {
    newErrors.enterpriseRiskMangementFrameworkScope = textError;
  }

  // Validate `indentificationMitigationEsgRisk`
  indentificationMitigationEsgRisk.esg_risk.forEach((risk, index) => {
    if (!risk || risk.trim() === "") {
      newErrors[`indentificationMitigationEsgRiskEsgRisk${index}`] = textError;
    }
  });

  indentificationMitigationEsgRisk.mitigation.forEach((mitigation, index) => {
    if (!mitigation || mitigation.trim() === "") {
      newErrors[`indentificationMitigationEsgRiskMitigation${index}`] = textError;
    }
  });

  // Validate `IncidenceResponsePlan`
  IncidenceResponsePlan.existence.forEach((existence, index) => {
    if (!existence || existence.trim() === "") {
      newErrors[`IncidenceResponsePlanExistence${index}`] = textError;
    }
  });

  IncidenceResponsePlan.effectiveness.forEach((effectiveness, index) => {
    if (!effectiveness || effectiveness.trim() === "") {
      newErrors[`IncidenceResponsePlanEffectiveness${index}`] = textError;
    }
  });

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

const handleChangeDesciption=(e,index,fieldType)=>{
  const{name,value}=e.target
  // setFromData({
  //   ...formData,
  //   indentificationMitigationEsgRisk:{
  //     ...formData.indentificationMitigationEsgRisk,
  //     [name]:value
  //   }
  // })
  const updatedCommittees = { ...formData.indentificationMitigationEsgRisk };
  updatedCommittees[fieldType][index] = value;
  setFromData({
    ...formData,
    indentificationMitigationEsgRisk: updatedCommittees,
  });
}
const addIncidenceResponsePlan =()=>{
  SetAddLength(1)
  const updatedCommittees = { ...formData.IncidenceResponsePlan};
  updatedCommittees.existence.push('');
  updatedCommittees.effectiveness.push('');

  setFromData({
    ...formData,
    IncidenceResponsePlan: updatedCommittees,
  });
}
const handleChangeResponse=(e,index,fieldType)=>{
  const {name,value}=e.target
//   setFromData({
//   ...formData,
//   IncidenceResponsePlan:{
//     ...formData.IncidenceResponsePlan,
//    [name]:value
//   }
// })
const updatedCommittees = { ...formData.IncidenceResponsePlan };
updatedCommittees[fieldType][index] = value;
setFromData({
  ...formData,
  IncidenceResponsePlan: updatedCommittees,
});
}
useEffect(()=>{
  setUpdate("GOVERNANCE");
  // const companyId = companyId;
  axios.get(`${api}/governance/risk-mangement/${companyId}`).then((res)=>{
  //  console.log(res)
   setFromData({
    enterpriseRiskMangementFramework:{
          radio:res.data.enterpriseRiskMangementFramework.radio===true?
          "yes":"no",
          Scope:res.data.enterpriseRiskMangementFramework.Scope
        },
        indentificationMitigationEsgRisk:{
          esg_risk:res.data.indentificationMitigationEsgRisk.esg_risk,
          mitigation:res.data.indentificationMitigationEsgRisk.mitigation
        },
        IncidenceResponsePlan:{
          existence:res.data.IncidenceResponsePlan.existence,
          effectiveness:res.data.IncidenceResponsePlan.effectiveness,

        },

})
setisExist(true);
  }).catch((err)=>{
    console.log(err)
  })
},[])

const handleRemoveField = (fieldName, index) => {
  console.log(fieldName,"fieldName")
  setOpenDialog({...openDialog,[fieldName]:true});
  setFieldToRemove({ fieldName, index });
  setIndexDelete(index)
};

// const handleRemoveFieldResponsePlan = (fieldName, index) => {
//   setOpenDialog({...openDialog,ResponsePlan:true});
//   setFieldToRemove({ fieldName, index });
//   setIndexDelete(index)
// };
// const confirmRemoveField = () => {
//   console.log("fieldToRemove",fieldToRemove)
//   const updatedIncidenceResponsePlan = { ...formData[fieldToRemove.fieldName] };

//   // Ensure index is valid before removing
//   if (updatedIncidenceResponsePlan.existence.length > DeleteIndex) {
//     updatedIncidenceResponsePlan.existence.splice(DeleteIndex, 1);  // Remove item at specific index
//   }
  
//   if (updatedIncidenceResponsePlan.effectiveness.length > DeleteIndex) {
//     updatedIncidenceResponsePlan.effectiveness.splice(DeleteIndex, 1);  // Remove item at specific index
//   }

//   setFromData({
//     ...formData,
//     IncidenceResponsePlan: updatedIncidenceResponsePlan,  // Update formData with modified arrays
//   });

  

//   setOpenDialog(false);
// };
// const confirmRemoveField = () => {
//   console.log("fieldToRemove",fieldToRemove)
//   if (!fieldToRemove) return;  // Ensure fieldToRemove exists

//   const updatedPlan = { ...formData.IncidenceResponsePlan};
//   const updatedPlan1 = {...formData.indentificationMitigationEsgRisk};
//   // Ensure index is valid and perform removal
//   if (updatedPlan[fieldToRemove.fieldName]?.length > DeleteIndex) {
//     console.log("data of updatePlan",updatedPlan[fieldToRemove.fieldName], updatedPlan[fieldToRemove.fieldName].splice(DeleteIndex, 1))
//     updatedPlan[fieldToRemove.fieldName].splice(DeleteIndex, 1);  // Remove the item from the correct array
//   }
//   else if (updatedPlan1[fieldToRemove.fieldName]?.length > DeleteIndex) {
//     console.log("data of updatePlan1",updatedPlan1[fieldToRemove.fieldName])
//     updatedPlan1[fieldToRemove.fieldName].splice(DeleteIndex, 1);  // Remove the item from the correct array
//   }
//   // Update formData with modified IncidenceResponsePlan
//   setFromData({
//     ...formData,
//     IncidenceResponsePlan: updatedPlan,  // Merge updatedPlan if field belongs to IncidenceResponsePlan
//     indentificationMitigationEsgRisk: updatedPlan1  
    
//   });

//   // Close dialog and reset state
//   setOpenDialog({ ...openDialog, [fieldToRemove.fieldName]: false });
//   setFieldToRemove(null);
//   setIndexDelete(null);
// };
const confirmRemoveField = () => {
  if (!fieldToRemove) return;  // Ensure fieldToRemove exists

  // Clone current state for both objects
  const updatedPlan = { ...formData.IncidenceResponsePlan };
  const updatedPlan1 = { ...formData.indentificationMitigationEsgRisk };

  console.log("fieldToRemove:", fieldToRemove, "DeleteIndex:", DeleteIndex);

  // Remove items from both `existence` and `effectiveness` in IncidenceResponsePlan simultaneously
  if (fieldToRemove.fieldName === "existence" || fieldToRemove.fieldName === "effectiveness") {
    if (updatedPlan.existence?.length > DeleteIndex) {
      console.log("Before splice (existence):", updatedPlan.existence);
      updatedPlan.existence.splice(DeleteIndex, 1);  // Remove from existence
      console.log("After splice (existence):", updatedPlan.existence);
    }

    if (updatedPlan.effectiveness?.length > DeleteIndex) {
      console.log("Before splice (effectiveness):", updatedPlan.effectiveness);
      updatedPlan.effectiveness.splice(DeleteIndex, 1);  // Remove from effectiveness
      console.log("After splice (effectiveness):", updatedPlan.effectiveness);
    }
  } 
  // Remove items from both `esg_risk` and `mitigation` in indentificationMitigationEsgRisk simultaneously
  else if (fieldToRemove.fieldName === "esg_risk" || fieldToRemove.fieldName === "mitigation") {
    if (updatedPlan1.esg_risk?.length > DeleteIndex) {
      console.log("Before splice (esg_risk):", updatedPlan1.esg_risk);
      updatedPlan1.esg_risk.splice(DeleteIndex, 1);  // Remove from esg_risk
      console.log("After splice (esg_risk):", updatedPlan1.esg_risk);
    }

    if (updatedPlan1.mitigation?.length > DeleteIndex) {
      console.log("Before splice (mitigation):", updatedPlan1.mitigation);
      updatedPlan1.mitigation.splice(DeleteIndex, 1);  // Remove from mitigation
      console.log("After splice (mitigation):", updatedPlan1.mitigation);
    }
  } 
  else {
    console.warn(`No valid field found for ${fieldToRemove.fieldName}`);
    return;
  }

  // Merge the updated plans back into formData
  setFromData((prevState) => ({
    ...prevState,
    IncidenceResponsePlan: updatedPlan,  // Update IncidenceResponsePlan
    indentificationMitigationEsgRisk: updatedPlan1  // Update indentificationMitigationEsgRisk
  }));

  console.log("Updated formData:", formData);

  // Close the dialog and reset state
  setOpenDialog({ ...openDialog, [fieldToRemove.fieldName]: false });
  setFieldToRemove(null);
  setIndexDelete(null);
};

const cancelRemoveField = (data) => {
  console.log("datacancelRemoveField",data)
  setOpenDialog(false);
};

console.log(formData)
  const navigate = useNavigate();

  const Save = () => {
    if (!validateForm()) {
      return;
    }
    // const companyId = companyId;
    axios({method:isExist?'put':'post',
      url:`${api}/governance/risk-mangement${isExist ? `/${companyId}` : ""}`,
    data:formData}).then((res)=>{
      console.log(res,"data")
      // alert(res.data.message)
      navigate('/governance/stakeholder');
    }).catch((err)=>{
      console.log(err,"error data",err.message)
      alert(err.message)
    })
    // 
  };
  
const AddIdentification=()=>{
  const updatedCommittees = { ...formData.indentificationMitigationEsgRisk };
  updatedCommittees.esg_risk.push('');
  updatedCommittees.mitigation.push('');

  setFromData({
    ...formData,
    indentificationMitigationEsgRisk: updatedCommittees,
  });
}
  const Back = () => {
    navigate('/governance/ethics');
  };

  return (
    <Box display="flex" sx={{backgroundColor: "#EDEDED",  display: "flex",
      backgroundColor: "#EDEDED",
      height: "100vh",
      overflow: "hidden"}}>
      <Dialog open={openDialog[fieldToRemove?.fieldName]} onClose={()=>cancelRemoveField(openDialog)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this field?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>cancelRemoveField(openDialog)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRemoveField} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Sidebar title="Risk Management" />
      <Box width="100%" sx={{
          p: 2,
          mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}>
        <Typography variant="h5"
          sx={{
            fontWeight: "bold",
             mt: "2rem",
            zIndex: 1,
            padding: "10px",
            borderRadius: "4px",
            alignSelf: "flex-start",
            ml:"5rem"}}
        >
          GOVERNANCE: RISK MANAGEMENT
        </Typography>

        <Box sx={{width:"90%",ml:"6rem"}}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading" gutterBottom>
            Enterprise Risk Management Framework
          </Typography>
          <RadioGroup row name="radio"
           value={formData.enterpriseRiskMangementFramework.radio}
           onChange={handleChangeRisk}
           sx={{ mb: 2 }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>

        {formData.enterpriseRiskMangementFramework.radio==="yes"?
          <Box sx={{width:"88%"}}>
       <TextField
       variant="outlined"
       placeholder="Scope..."
       className="brief"
       name='Scope'
       value={formData.enterpriseRiskMangementFramework.Scope}
       onChange={handleChangeRisk}
       multiline
       rows={3}
      //  sx={{ mb: 3, width: '88%' }}
       InputProps={{
        style: { borderColor:errors.enterpriseRiskMangementFrameworkScope? '#D34040' : undefined},
      }}
       sx={{marginRight:"5px",width:"100%",mb:3}}
      error={!!errors.enterpriseRiskMangementFrameworkScope} // Show error styling if there's an error
      InputLabelProps={{
        style: { color: errors.enterpriseRiskMangementFrameworkScope ? '#D34040' : undefined },
      }}
     />
     {errors.enterpriseRiskMangementFrameworkScope && (
      <Typography variant="body2" color="#D34040">
        {errors.enterpriseRiskMangementFrameworkScope}
      </Typography>
    )}
  </Box>
     :""  
      }  
       

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading" gutterBottom>
            Identification and Mitigation of ESG Risks
          </Typography>
         
        {formData.indentificationMitigationEsgRisk.esg_risk.map((item,i)=>{
          return <Box
          key={i}
          sx={{ width: "100%", display: "flex", gap: 1 }}
        > 
         <Grid container spacing={2} sx={{ mb: 3,width:"90%" }}>
            <Grid item xs={12} md={6} sx={{width:"100%"}}>
              <TextField
                variant="outlined"
                placeholder="Description of ESG Risk"
                name='esg_risk'
                value={item}
                onChange={(e)=>handleChangeDesciption(e,i,'esg_risk')}
                multiline
                rows={3}
                fullWidth
                InputProps={{
                  style: { borderColor:errors[`indentificationMitigationEsgRiskEsgRisk${i}`]? '#D34040' : undefined},
                }}
                 sx={{marginRight:"5px",width:"100%"}}
                error={!!errors[`indentificationMitigationEsgRiskEsgRisk${i}`]} // Show error styling if there's an error
                InputLabelProps={{
                  style: { color: errors[`indentificationMitigationEsgRiskEsgRisk${i}`] ? '#D34040' : undefined },
                }}
              />
              {errors[`indentificationMitigationEsgRiskEsgRisk${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`indentificationMitigationEsgRiskEsgRisk${i}`]}
            </Typography>
          )}
            </Grid>
            <Grid item xs={12} md={6} sx={{width:"100%"}}>
            <Box display="flex" width='100%'>
              <TextField
                variant="outlined"
                placeholder="Mitigation"
                // className="riskText"
                name='mitigation'
                value={formData.indentificationMitigationEsgRisk.mitigation[i]}
                onChange={(e)=>handleChangeDesciption(e,i,'mitigation')}
                multiline
                rows={3}
                fullWidth
                InputProps={{
                  style: { borderColor:errors[`indentificationMitigationEsgRiskMitigation${i}`]? '#D34040' : undefined},
                }}
                 sx={{marginRight:"5px",width:"100%"}}
                error={!!errors[`indentificationMitigationEsgRiskMitigation${i}`]} // Show error styling if there's an error
                InputLabelProps={{
                  style: { color: errors[`indentificationMitigationEsgRiskMitigation${i}`] ? '#D34040' : undefined },
                }}
              /> 
                  </Box>
              {errors[`indentificationMitigationEsgRiskMitigation${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`indentificationMitigationEsgRiskMitigation${i}`]}
            </Typography>
          )}
          </Grid>
            </Grid>
            {i > 0 && (
                    <IconButton sx={{ mt: 0,width:"5%" }}>
                      <DeleteIcon
                        onClick={() =>
                          handleRemoveField("mitigation", i)
                        }
                      />
                    </IconButton>
                  )} 
          </Box>
        })}  
            <Grid item xs={12} sx={{width:"87%"}}
            display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                sx={{ color: "#0a3f73",textTransform:"none" }}
                className="add"
                onClick={AddIdentification}
              >
                Add
              </Button>
            </Grid>
          {/* </Grid> */}

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading" gutterBottom>
            Incidence Response Plan
          </Typography>
          
      {formData.IncidenceResponsePlan.existence.map((item,i)=>{
        return  <Box
        key={i}
        sx={{ width: "101%", display: "flex", gap: 1 }}
      > 
        <Grid container spacing={2} sx={{ mb: 3 , width:"90%"}}>
          <Grid item xs={12} md={6}>
            {/* <Box> */}
              <TextField
                variant="outlined"
                placeholder="Existence"
                // className="riskText"
                name='existence'
                value={item}
                onChange={(e)=>handleChangeResponse(e,i,'existence')}
                multiline
                rows={3}
                fullWidth
                InputProps={{
                  style: { borderColor:errors[`IncidenceResponsePlanExistence${i}`]? '#D34040' : undefined},
                }}
                 sx={{marginRight:"5px",width:"100%"}}
                error={!!errors[`IncidenceResponsePlanExistence${i}`]} // Show error styling if there's an error
                InputLabelProps={{
                  style: { color: errors[`IncidenceResponsePlanExistence${i}`] ? '#D34040' : undefined },
                }}
              />
            {errors[`IncidenceResponsePlanExistence${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`IncidenceResponsePlanExistence${i}`]}
            </Typography>
          )}
          {/* </Box> */}
            </Grid>  
            <Grid item xs={12} md={6} sx={{width:"130%"}}>
              <Box display="flex" sx={{width:"100%"}}>
              <TextField
                variant="outlined"
                placeholder="Effectiveness"
                // className="riskText"
                name='effectiveness'
                value={formData.IncidenceResponsePlan.effectiveness[i]}
               onChange={(e)=>handleChangeResponse(e,i,'effectiveness')}
                multiline
                rows={3}
                fullWidth
                InputProps={{
                  style: { borderColor:errors[`IncidenceResponsePlanEffectiveness${i}`]? '#D34040' : undefined},
                }}
                 sx={{marginRight:"5px",width:"100%"}}
                error={!!errors[`IncidenceResponsePlanEffectiveness${i}`]} // Show error styling if there's an error
                InputLabelProps={{
                  style: { color: errors[`IncidenceResponsePlanEffectiveness${i}`] ? '#D34040' : undefined },
                }}
              />
               
                  </Box>
              {errors[`IncidenceResponsePlanEffectiveness${i}`] && (
            <Typography variant="body2" color="#D34040">
              {errors[`IncidenceResponsePlanEffectiveness${i}`]}
            </Typography>
          )}
            </Grid>
            </Grid>
            {i > 0 && (
                    <IconButton sx={{ mt: 0,width:"5%" }}>
                      <DeleteIcon
                        onClick={() =>
                          handleRemoveField("effectiveness", i)
                        }
                      />
                    </IconButton>
                  )}  
        </Box>
      })}    
            <Grid item xs={12} display="flex" sx={{width:"88%"}} justifyContent="flex-end">
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                sx={{ color: "#0a3f73", textTransform:"none" }}
                className="add"
                onClick={addIncidenceResponsePlan}
              >
                Add
              </Button>
            </Grid>
          {/* </Grid> */}
          <ActionButtons Back={Back} Move={Save}/>
        </Box>
{/* 
        <Box display="flex" justifyContent="space-between" width="70%" sx={{ mt: 4 }}>
          {/* <Button
            variant="outlined"
            sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}}
            onClick={Back}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{backgroundColor:"#0a3f73",textTransform:"none"}}
            onClick={Save}
            endIcon={<ArrowForwardIcon />}
          >
            Save and continue
          </Button> */}
        {/* <ActionButtons Back={Back} Move={Save}/> */}
        {/* </Box>  */}
      </Box>
    </Box>
  );
};

export default RiskManagement;
