import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import { api } from "../../../../utils/api";
import {numberError} from "../../../../utils/validationMessage";
import { companyId } from "../../../../utils/companyId";

function AddPetrolGasOline(props) {
  const [loading, setLoading] = useState(false);
  const [IndustrialProcess,setIndustrialProcess] = useState([]);
  const [EquipmentType,setEquipementType] = useState([]);
  const [EntryType,setEntryType] = useState( ["Cumulative", "Individual"]);
  const[quantityUnits,setQuantityUnits]=useState(["NG-m3/litre/gallon","Coal-kg/MT","Petrol-gallon/liters/m3/km","Diesel-gallon/litres/m3/km","Fuel Oil-gallon/litres/m3/km"]);
  const[calorificUnit,setCalorificUnit] = useState(["MJ/kg", "MJ/m3"]);
  const [Equipment,setEquipement] = useState([]);
  const[alreadyIndurstialProcess,setAlreadyIndurstialProcess]=useState(false);
  const [fileExtensions,setFileExtensions] = useState("");
  const [errors, setErrors] = useState({});
  const initialState={  company: companyId,
    createdBy:"671243618c92ebf6997fe221",
    industrialProcess:"",
      industrialEquipment:"",
      equipmentType:"",
      quantityOfPetrolConsumed:"",
      unit:"",
      calorificValue:{
        value:"",
        unit:""
      },
      remarks:"",
      documents:[],
      hours:"",
      typeOfEntry:"",
      remarks:""
}

    
  const [fromValue, setFromValue] = useState(initialState);

  const resetForm = () => {
    console.log(" props.selectedPetroleum", props.selectedPetroleum)
    setFromValue(props.selectedPetroleum===null ?initialState:{...props.selectedPetroleum,
        industrialProcess: props.selectedPetroleum?.industrialProcess?.industryProcess ?? "",
        equipmentType: props.selectedPetroleum?.equipmentType?.name ?? "",
        ...(props.selectedPetroleum?.typeOfEntry==="Individual" &&
          {
        industrialEquipment: props.selectedPetletonEquipment?.industrialEquipment?.name??""})
    });
   setErrors({});
 };

const fetchIndurstialProcess=()=>{
    axios.get(`${api}/master/getAllIndustrialProcess`).then((res) => {
      console.log("resForPermission", res.data.data);
      setIndustrialProcess(res.data.data);
    }).catch((err) => {
      console.log("err", err);
    });
  };


  const fetchEquipmentType = ()=>{
    axios.get(`${api}/master/getAllEquipmentType`).then((res) => {
      console.log("resForPermission", res.data.data);
      setEquipementType(res.data.data);
    }).catch((err) => {
      console.log("err", err);
    });
  };

 const fetchEqupment = ()=>{
    axios.get(`${api}/master/getAllIndustrialEquipment`).then((res) => {
      console.log("resForPermission", res.data.data);
      setEquipement(res.data.data);
    }).catch((err) => {
      console.log("err", err);
    });
 
 };


  useEffect(() => {
    setFromValue({
      company: companyId,
      createdBy:"671243618c92ebf6997fe221",
      industrialProcess: props.selectedPetroleum?.industrialProcess?.industryProcess ?? "",
      equipmentType: props.selectedPetroleum?.equipmentType?.name ?? "",
      quantityOfPetrolConsumed: props.selectedPetroleum?.quantityOfPetrolConsumed ?? "",
      unit: props.selectedPetroleum?.unit ?? "",
      calorificValue:{
        value: props.selectedPetroleum?.calorificValue?.value??"",
        unit:props.selectedPetroleum?.calorificValue?.unit??""
      },
      ...(props.selectedPetroleum?.typeOfEntry==="Individual" &&
        {
      industrialEquipment: props.selectedPetletonEquipment?.industrialEquipment?.name??""}),
      documents: props.selectedPetroleum?.documents || [],
      hours: props.selectedPetroleum?.hours || "",
      remarks:props.selectedPetroleum?.remarks || "",
      typeOfEntry:props.selectedPetroleum?.typeOfEntry ||"",
    });
    setAlreadyIndurstialProcess(props.selectedPetroleum === null ? false : true);
    fetchIndurstialProcess();
    fetchEquipmentType();
    fetchEqupment();
  }, [props.selectedPetroleum]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("nam===e", name, value);
    setFromValue({
      ...fromValue,
      [name]: value,
    });
    setErrors(
       { ...errors,
        [name]:""}
    )
  };

const handleChangeCalorificValue =(e)=>{
    const { name, value } = e.target;
    console.log("nam===e", name, value);
    setFromValue({
      ...fromValue,
      calorificValue:{
        ...fromValue.calorificValue,
        [name === "calorificValue" ? "value" : "unit"]: value
      },
    });
    setErrors(
       { ...errors,
        [name]:""}
    )
}

  const validateForm = () => {
    const newErrors = {};

    const {
      industrialProcess,
      equipmentType,
      typeOfEntry,
      quantityOfPetrolConsumed,
     industrialEquipment,
      unit,
      hours,
      documents,
      calorificValue
    } = fromValue;

    // Validate `companyName`
    if (fromValue.typeOfEntry==="Individual" && (!industrialEquipment || industrialEquipment.trim() === "" || industrialEquipment === "Tap to Select")) {
      newErrors.industrialEquipment = " industrialEquipment is required.";
    }

    // Validate `facilityName`
    if (!industrialProcess || industrialProcess.trim() === "" ||industrialProcess === "Tap to Select") {
      newErrors.industrialProcess = "industrialProcess is required";
    }

    // Validate `address`
    if (!equipmentType || equipmentType.trim() === "" || equipmentType=== "Tap to Select") {
      newErrors.equipmentType = "equipmentType is required.";
    }

    // Validate `nickname`
    if (!typeOfEntry || typeOfEntry.trim() === "") {
      newErrors.typeOfEntry = "Entry is required.";
    }

    // Validate `incharge`
    if (!quantityOfPetrolConsumed || isNaN(quantityOfPetrolConsumed) || quantityOfPetrolConsumed === "" || quantityOfPetrolConsumed <= 0
    || !/^\d*\.?\d*$/.test(quantityOfPetrolConsumed)) {
      newErrors.quantityOfPetrolConsumed = numberError;
    }


    if (!unit || unit.trim() === "" || unit ==="Tap to Select") {
        newErrors.unit = "Unit  is required.";
      }
    // Validate `outsourceFacility`
    if (!documents || documents.length === 0) {
        newErrors.documents = "At least one document must be uploaded.";
      }

      if (!calorificValue.value || isNaN(calorificValue.value) || calorificValue.value === "" || calorificValue.value <= 0
      || !/^\d*\.?\d*$/.test(calorificValue.value)) {
        newErrors.calorificValue = numberError;
      }
      if (!calorificValue.unit || calorificValue.unit.trim() === "" ||calorificValue.unit===" Select") {
        newErrors.calorificUnit = "Entry is required.";
      }
      
    // Validate `country`
     
   
    if ( fromValue.typeOfEntry==="Individual" && (!hours || isNaN(hours) || hours === "" || hours <= 0
    || !/^\d*\.?\d*$/.test(hours))) {
      newErrors.hours = numberError;
    }
    console.log(newErrors,"newErrors")
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  const handleDeleteImage = (index) => {
    const updatedDocuments = fromValue.documents.filter((_, i) => i !== index);

    setFromValue((prevEquipment) => ({
      ...prevEquipment,
      documents: updatedDocuments,
    }));
  };

  const handleFileChange = async (e) => {
    
    const files = e.target.files;
    if (!files || files.length === 0) return;

    // if (files.length > 5) {
    //   alert("You can only upload a maximum of 5 files.");
    //   return;
    // }
    const totalFiles = fromValue?.documents?.length || 0;
    if (totalFiles + files.length > 5) {
      alert("You can only upload a maximum of 5 files in total.");
      return;
    }

    const allowedTypes = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls
      "image/png",
      // "image/jpeg", // For .jpg and .jpeg files
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB
    const maxWidth = 1200;
    const maxHeight = 800;

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();
      setFileExtensions(fileExtension)
      console.log("datafor the file", fileExtension)
      // Check file type
      if (!allowedTypes.includes(file.type)) {
        alert(`File type not allowed: ${file.name}`);
        return;
      }

      // Check file size
      if (file.size > maxFileSize) {
        alert(`File size exceeds 5MB: ${file.name}`);
        return;
      }

      // If the file is an image, check its dimensions
      if (file.type.startsWith("image/")) {
        const image = new Image();
        const fileURL = URL.createObjectURL(file);
        image.src = fileURL;

        // Wait for image to load to get dimensions
        try {
          await new Promise((resolve, reject) => {
            image.onload = () => {
              if (image.width > maxWidth || image.height > maxHeight) {
                alert(`Image dimensions exceed 1200x800: ${file.name}`);
                URL.revokeObjectURL(fileURL); // Release memory
                reject(
                  new Error(`Image dimensions exceed 1200x800: ${file.name}`)
                );
              } else {
                URL.revokeObjectURL(fileURL); // Release memory
                resolve();
              }
            };

            image.onerror = () => {
              URL.revokeObjectURL(fileURL); // Release memory
              reject(new Error(`Unable to load image: ${file.name}`));
            };
          });
        } catch (error) {
          console.error(error.message); // Log the error message
          continue; // Skip this file and proceed to the next
        }
      }

      // Add the file to FormData
      formData.append("files", file);
    }

    try {
      setLoading(true);
      const uploadResponse = await axios.post(`${api}/cloud/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const uploadedUrls = uploadResponse.data.urls; // Assuming your backend sends back an array of URLs
      setFromValue((prev) => {
        // If documents already exist, append new ones, else just set the new URLs
        const updatedDocuments =
          prev.documents.length > 0
            ? [...prev.documents, ...uploadedUrls]
            : uploadedUrls;

        return {
          ...prev,
          documents: updatedDocuments,
        };
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error uploading files:", err);
    }
  };



  console.log(props.selectedPetroleum?._id,props.selectedPetroleum, "props.selectedPetroleum");
  const Save = () => {
    console.log(fromValue,"fromValueinside Save");
    if (!validateForm()) {
      return;
    }
    const data = {...fromValue};

    if (fromValue.typeOfEntry !== "Individual") {
      delete data.hours;
      delete data.industrialEquipment;
    }
    console.log(fromValue,"fromValueinside Save");
    axios({
      method: alreadyIndurstialProcess ? "put" : "post",
      url: alreadyIndurstialProcess
        ? `${api}/stationaryCombustion/updatePetrolOrGasoline`
        : `${api}/stationaryCombustion/addPetrolOrGasoline`,
      data: data, // Send data only if it's a POST request
      params: alreadyIndurstialProcess ? { Id: props.selectedPetroleum?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        console.log(res, res.data.data);
        props.setAddPetrolGas(false);
        props.fetchPetroleum();
        resetForm();
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        //  localStorage.setItem("roleId",res.data.data._id)
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          // If there is a message in the error response, use it
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{width:400,
        // maxWidth:"100%",
        height: '100vh',
        display:"flex",justifyContent:"space-btween",
          backgroundColor:"transparent"
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {props.setAddPetrolGas(false);resetForm();}}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll",
}}>
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          {props.selectedPetroleum?._id === undefined? "Add Data" : "Edit Data"}
        </Typography>
         {console.log("fromValue.industrialProcess",fromValue.industrialProcess)}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        Industrial process
        </Typography>

        <CustomTextField
            select
          variant="outlined"
          value={fromValue.industrialProcess === "" ? "Tap to Select" : fromValue.industrialProcess}
          onChange={handleChange}
          fullWidth
          name="industrialProcess"
          placeholder="Enter Name"
          error={!!errors.industrialProcess} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.industrialProcess ? "#D34040" : undefined },
          }}
        >
              <MenuItem value = {fromValue.industrialProcess === "" ? "Tap to Select" : fromValue.industrialProcess } disabled>
              {fromValue.industrialProcess === "" ? "Tap to Select" : fromValue.industrialProcess }
              {/* Tap to Select */}
              </MenuItem>
            {IndustrialProcess.map(item=>{
                console.log("industilaProcess", item)
                return(
                <MenuItem key={item._id} value={item._id}>
                  {item.industryProcess}
                </MenuItem>
                )
            })}
        </CustomTextField>
        {errors.industrialProcess && (
          <Typography variant="body2" color="#D34040">
            {errors.industrialProcess}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        Equipment Type
        </Typography>
        <CustomTextField
           select
          variant="outlined"
          value={fromValue.equipmentType === "" ? "Tap to Select" : fromValue.equipmentType}
          onChange={handleChange}
          fullWidth
          name="equipmentType"
          placeholder="equipmentType"
          error={!!errors.equipmentType} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.address ? "#D34040" : undefined },
          }}
        >
                <MenuItem value = {fromValue.equipmentType === "" ? "Tap to Select" : fromValue.equipmentType} disabled>
                {fromValue.equipmentType === "" ? "Tap to Select" : fromValue.equipmentType }
              </MenuItem>
            {EquipmentType.map(item=>{
                console.log("EquipmentType", item)
                return(
                <MenuItem key={item._id} value={item._id}>
                  {item.name}
                </MenuItem>
                )
            })}
        </CustomTextField>
        {errors.equipmentType && (
          <Typography variant="body2" color="#D34040">
            {errors.equipmentType}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        Type of Entry
        </Typography>
      
        <CustomTextField
           select
          variant="outlined"
          value={fromValue.typeOfEntry === "" ? "Tap to Select" : fromValue.typeOfEntry}
          onChange={handleChange}
          fullWidth
          name="typeOfEntry"
          placeholder="typeOfEntry"
          error={!!errors.typeOfEntry} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.typeOfEntry ? "#D34040" : undefined },
          }}
        >
            <MenuItem value = {fromValue.typeOfEntry === "" ? "Tap to Select" : fromValue.typeOfEntry } disabled>
            {fromValue.typeOfEntry === "" ? "Tap to Select" : fromValue.typeOfEntry }
           </MenuItem>
           {EntryType.map((item)=>{
             console.log("EntryType", item)
             return(
             <MenuItem key={item} value={item}>
               {item}
             </MenuItem>
             )
 
           })}
        </CustomTextField>
        {errors.typeOfEntry && (
          <Typography variant="body2" color="#D34040">
            {errors.typeOfEntry}
          </Typography>
        )}
    { 
     fromValue.typeOfEntry==="Individual"?
     <>   <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        Equipment
        </Typography>
       {console.log("fromValue.industrialEquipment?.name",fromValue.industrialEquipment,fromValue.industrialEquipment)}
        <CustomTextField
           select
          variant="outlined"
          value={fromValue.industrialEquipment === "" ? "Tap to Select" : fromValue.industrialEquipment }
          onChange={handleChange}
          fullWidth
          name="industrialEquipment"
          placeholder="industrialEquipment"
          error={!!errors.industrialEquipment} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.industrialEquipment ? "#D34040" : undefined },
          }}
        >
            <MenuItem value = {fromValue.industrialEquipment === "" ? "Tap to Select" : fromValue.industrialEquipment} disabled>
            {fromValue.industrialEquipment === "" ? "Tap to Select" : fromValue.industrialEquipment }
           </MenuItem>
            {Equipment.map((item)=>{
                console.log("Equipment", item)
                return(
                <MenuItem key={item._id} value={item._id}>
                  {item.name}
                </MenuItem>
                )
            })}
        </CustomTextField>
        {errors.industrialEquipment && (
          <Typography variant="body2" color="#D34040">
            {errors.industrialEquipment}
          </Typography>
        )}
        </>:""} 
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        Quantity Consumed
        </Typography>
      
        <CustomTextField
        //   select
      variant="outlined"
        value={fromValue.quantityOfPetrolConsumed}
      onChange={handleChange}
      fullWidth
      name="quantityOfPetrolConsumed"
      placeholder="1000"
      error={!!errors.quantityOfPetrolConsumed} // Show error styling if there's an error
      InputLabelProps={{
        style: { color: errors.quantityOfPetrolConsumed ? "#D34040" : undefined },
      }}
    >
    </CustomTextField>
    {errors.quantityOfPetrolConsumed && (
          <Typography variant="body2" color="#D34040">
            {errors.quantityOfPetrolConsumed}
          </Typography>
        )}
    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
       Unit of Quantity
        </Typography>
      
        <CustomTextField
        select
      variant="outlined"
        value={fromValue.unit  === "" ? "Tap to Select" : fromValue.unit}
      onChange={handleChange}
      fullWidth
      name="unit"
      placeholder="1000"
      error={!!errors.unit} // Show error styling if there's an error
      InputLabelProps={{
        style: { color: errors.unit ? "#D34040" : undefined },
      }}
    >

<MenuItem value = {fromValue.unit === "" ? "Tap to Select" : fromValue.unit } disabled>
           Tap to Select
           </MenuItem>
            {quantityUnits.map((item)=>{
                console.log("unit", item)
                return(
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
                )
            })}
    </CustomTextField>
    {errors.unit && (
          <Typography variant="body2" color="#D34040">
            {errors.unit}
          </Typography>
        )}
     {
     
     fromValue.typeOfEntry==="Individual"?
     <>
     <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Hours
        </Typography>
        { console.log(fromValue.hours,"fromValue.country")}

        <CustomTextField
          variant="outlined"
        //   select
          //   label="Select Country"
           value={fromValue.hours}
        //   value={fromValue.country===""?"Select Country":fromValue.country}
          onChange={handleChange}
          fullWidth
          name="hours"
          placeholder="Enter hours used"
          error={!!errors.hours} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.hours ? "#D34040" : undefined },
          }}
        >
          
        </CustomTextField>
        {errors.hours && (
          <Typography variant="body2" color="#D34040">
            {errors.hours}
          </Typography>
        )}
     </>:""
     }

         <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Calorific Value
        </Typography>
        
     <Box sx={{display:"flex",gap:"10px"}}>
        <Box sx={{width:"70%"}}>
        <CustomTextField
          variant="outlined"
        //   select
          //   label="Select Country"
           value={fromValue.calorificValue.value}
        //   value={fromValue.country===""?"Select Country":fromValue.country}
          onChange={handleChangeCalorificValue}
          fullWidth
          name="calorificValue"
          placeholder="Select"
          error={!!errors.calorificValue} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.calorificValue ? "#D34040" : undefined },
          }}
        >
          </CustomTextField>
          {errors.calorificValue && (
          <Typography variant="body2" color="#D34040">
            {errors.calorificValue}
          </Typography>
        )}
        </Box>
        <Box sx={{width:"50%"}}>
        <CustomTextField
          variant="outlined"
           select
          //   label="Select Country"
           value={fromValue.calorificValue.unit ===""?"Select":fromValue.calorificValue.unit}
        //   value={fromValue.country===""?"Select Country":fromValue.country}
          onChange={handleChangeCalorificValue}
          fullWidth
          name="calorificUnit"
          placeholder="Select"
          error={!!errors.calorificUnit} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.calorificUnit ? "#D34040" : undefined },
          }}
        >
         
         <MenuItem value = {fromValue.calorificValue.unit === "" ? "Select" : fromValue.calorificValue.unit } disabled>
            Select
           </MenuItem>
            {calorificUnit.map((item)=>{
                console.log("Equipment", item)
                return(
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
                )
            })}

         
        </CustomTextField>
        {errors.calorificUnit && (
          <Typography variant="body2" color="#D34040">
            {errors.calorificUnit}
          </Typography>
        )}
        </Box>
        </Box>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Remark
        </Typography>

        <TextField
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          name="remarks"
          value={fromValue.remarks}
          onChange={handleChange}
          placeholder="Brief"
        />
        {errors.incharge && (
          <Typography variant="body2" color="#D34040">
            {errors.incharge}
          </Typography>
        )}

<Typography
          variant="subtitle2"
          sx={{ fontWeight: "bold", mb: 1, mt: 1 }}
        >
          Upload Documents
        </Typography>
{console.log("props.selectedPetroleum?._id",fromValue?.documents?.length,loading,props.selectedPetroleum?._id,props.selectedPetroleum)}
        {props.selectedPetroleum?._id !== undefined ? 
        
        (
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap",mb:2 }}>
            {fromValue?.documents?.map((doc, index) => (
              <Box key={index} sx={{ position: "relative" }}>
                {/* Render different file types */}
                {doc.endsWith(".png") ||
                doc.endsWith(".jpg") ||
                doc.endsWith(".jpeg") ? (
                  <img
                    src={doc}
                    alt={`Document ${index + 1}`}
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                      borderRadius: "4px",
                      marginBottom: "10px",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "80px",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginBottom: "10px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    {/* Display the file name and provide a download link */}
                    <a
                      href={doc}
                      download
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Typography variant="caption">
                        {doc.split("/").pop()}
                      </Typography>
                    </a>
                  </Box>
                )}
                <IconButton
                  onClick={() => handleDeleteImage(index)}
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    width: "20px",
                    height: "20px",
                  }}
                >
                  <CloseIcon sx={{ fontSize: "10px" }} />
                </IconButton>
              </Box>
            ))}
          </Box>
        ) 
        : 
        (
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 2 }}>
            {fromValue?.documents?.map((doc, index) => {
               const fileName = doc.split('/').pop(); // Get the last part of the URL (e.g., 'bug list')
               const isImage = fileName.toLowerCase().includes('png') || fileName.toLowerCase().includes('jpg') || fileName.toLowerCase().includes('jpeg');

            return  <Box key={index} sx={{ position: "relative" }}>
                 
                {
                // doc.endsWith(".png") ||
                // doc.endsWith(".jpg") ||
                // doc.endsWith(".jpeg")
                isImage
                 ? (
                  <img
                    src={doc}
                    alt={`Document ${index + 1}`}
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                      borderRadius: "4px",
                      marginBottom: "10px",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "80px",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginBottom: "10px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <a
                      // href={doc}
                      // download
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Typography variant="caption">
                        {doc.split("/").pop()}
                      </Typography>
                    </a>
                  </Box>
                )}
                <IconButton
                  onClick={() => handleDeleteImage(index)}
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    width: "20px",
                    height: "20px",
                  }}
                >
                  <CloseIcon sx={{ fontSize: "10px" }} />
                </IconButton>
              </Box>
            })}
          </Box>
        )
      
        }

        {loading === true ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{
              border: "1px dashed grey",
              borderRadius: 1,
              p: 2,
              mt: -2,
              textAlign: "center",
              cursor: "pointer",
               display: fromValue?.documents?.length < 5 ? "block" : "none",
            }}
          >
            <input
              type="file"
              hidden
              id="upload-document"
              onChange={handleFileChange}
              multiple
            />
            <label htmlFor="upload-document">
              <Box display="flex" flexDirection="column" alignItems="center">
                <CloudUploadIcon />
                <Typography>Drop files here or click to upload</Typography>
                <Typography variant="caption">
                  Upload your file (CSV, Excel, PNG, max 5 MB, recommended size
                  1200x800 pixels).
                </Typography>
              </Box>
            </label>
          </Box>
        )}

        {errors.documents ? (
          <Typography variant="body2" color="#D34040">
            {errors.documents}
          </Typography>
        ) : (
          ""
        )}

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0a3f73",
            textTransform: "none",
            marginTop: "10%",
          }}
          onClick={Save}
        >
          Submit
        </Button>
        {/* </Box> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.AddPetroleumGas}
        onClose={() => {props.setAddPetrolGas(false);resetForm();}}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddPetrolGasOline;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
