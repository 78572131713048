

// import React, { useState, useContext, useEffect} from 'react';
// import Sidebar from '../sidebar';
// import { useNavigate } from 'react-router-dom';
// import { ProgressContext } from '../ContextAPI';
// // import { TextField, Button, Box, Typography, TextareaAutosize } from '@mui/material';
// import { TextField, Button,IconButton, Box, Typography, TextareaAutosize, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
// import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
// import { Add as AddIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
// import EastIcon from '@mui/icons-material/East';
// import DeleteIcon from '@mui/icons-material/Delete';
// import axios from 'axios';
// import { companyId } from "../../../utils/companyId";
// import { api } from "../../../utils/api";
// import { textError, numberError, percentageError, twoFieldError } from "../../../utils/validationMessage";

// const SupplyChain = () => {
//     const { setUpdate } = useContext(ProgressContext);
//     const navigate = useNavigate();

//     // State to manage form data
//     const [numberOfSuppliersAssessed, setNumberOfSuppliersAssessed] = useState('');
//     const [percentageOfSuppliersAssessed, setPercentageOfSuppliersAssessed] = useState('');

//     // State for fair labor practices
//     const [fairLaborPractices, setFairLaborPractices] = useState([{ having: '', notHaving: '' }]);

//     // State for child labor practices
//     const [childLaborPractices, setChildLaborPractices] = useState([{ having: '', notHaving: '' }]);
//     const [isExist, setIsExist] = useState(false);
//     const [errors, setErrors] = useState({
//         numberOfSuppliers: '',
//         percentageOfSuppliers: '',
//         fairLabor: [],
//         childLabor: []
//     });

//     const [openDialog, setOpenDialog] = useState(false);
//     const [deleteIndex, setDeleteIndex] = useState({ type: '', index: null });

//     useEffect(() => {
//         const fetchSupplyChainData = async () => {
//             try {
//                 const response = await axios.get(`${api}/social/supply_chain/${companyId}`);
//                 const supplyChainData = response.data;
    
//                 // Populate the form fields with the data from the API
//                 setNumberOfSuppliersAssessed(supplyChainData.supplierSocialAssessments.numberOfSuppliersAssessed);
//                 setPercentageOfSuppliersAssessed(supplyChainData.supplierSocialAssessments.percentageOfSuppliersAssessed.value);
//                 setFairLaborPractices(supplyChainData.fairLaborPractices.map(practice => ({
//                     having: practice.numberOfSuppliersHavingFairLaborPractices,
//                     notHaving: practice.numberOfSuppliersNotHavingFairLaborPractices
//                 })));
//                 setChildLaborPractices(supplyChainData.childLaborPractices.map(practice => ({
//                     having: practice.numberOfSuppliersHavingFairLaborPractices,
//                     notHaving: practice.numberOfSuppliersNotHavingFairLaborPractices
//                 })));
//                 setIsExist(true);
//             } catch (error) {
//                 console.error('Error fetching Supply Chain Social Responsibility data:', error);
//                 // You may want to handle this error or show an alert to the user.
//             }
//         };
//         // Only fetch data if a companyId exists
//         if (companyId) {
//             fetchSupplyChainData();
//         }
//     }, [companyId]);

//     const validateForm = () => {
//         const newErrors = { numberOfSuppliers: '', percentageOfSuppliers: '', fairLabor: [], childLabor: [] };
//         let isValid = true;
    
//         if (numberOfSuppliersAssessed === '' || isNaN(numberOfSuppliersAssessed)) {
//             newErrors.numberOfSuppliers = textError;
//             isValid = false;
//         } else if (Number(numberOfSuppliersAssessed) < 0) {
//             newErrors.numberOfSuppliers = numberError;
//             isValid = false;
//         }
    
//         if (percentageOfSuppliersAssessed === '' || isNaN(percentageOfSuppliersAssessed)) {
//             newErrors.percentageOfSuppliers = textError;
//             isValid = false;
//         } else if (Number(percentageOfSuppliersAssessed) < 0 || Number(percentageOfSuppliersAssessed) > 100) {
//             newErrors.percentageOfSuppliers = percentageError;
//             isValid = false;
//         }
    
//         fairLaborPractices.forEach((practice, index) => {
//             const practiceErrors = {};
//             if (practice.having === '' || isNaN(practice.having)) {
//                 practiceErrors.having = textError;
//                 isValid = false;
//             } else if (Number(practice.having) < 0) {
//                 practiceErrors.having = numberError;
//                 isValid = false;
//             }
//             if (practice.notHaving === '' || isNaN(practice.notHaving)) {
//                 practiceErrors.notHaving = textError;
//                 isValid = false;
//             } else if (Number(practice.notHaving) < 0) {
//                 practiceErrors.notHaving = numberError;
//                 isValid = false;
//             }
//             newErrors.fairLabor.push(practiceErrors);
//         });
    
//         childLaborPractices.forEach((practice, index) => {
//             const practiceErrors = {};
//             if (practice.having === '' || isNaN(practice.having)) {
//                 practiceErrors.having = textError;
//                 isValid = false;
//             } else if (Number(practice.having) < 0) {
//                 practiceErrors.having = numberError;
//                 isValid = false;
//             }
//             if (practice.notHaving === '' || isNaN(practice.notHaving)) {
//                 practiceErrors.notHaving = textError;
//                 isValid = false;
//             } else if (Number(practice.notHaving) < 0) {
//                 practiceErrors.notHaving = numberError;
//                 isValid = false;
//             }
//             newErrors.childLabor.push(practiceErrors);
//         });
    
//         setErrors(newErrors);
//         return isValid;
//     };
    
    
    

//     // Function to handle adding new fields
//     const addFairLaborPractice = () => {
//         setFairLaborPractices([...fairLaborPractices, { having: '', notHaving: '' }]);
//     };

//     const addChildLaborPractice = () => {
//         setChildLaborPractices([...childLaborPractices, { having: '', notHaving: '' }]);
//     };

//     // Function to handle Save and Continue
//     const Save = async () => {
//         if (!validateForm()) {
//             return; // Stop if validation fails
//         }

//         const payload = {
//             supplierSocialAssessments: {
//                 numberOfSuppliersAssessed: Number(numberOfSuppliersAssessed),
//                 percentageOfSuppliersAssessed: {
//                     value: Number(percentageOfSuppliersAssessed),
//                     unit: '%'
//                 }
//             },
//             fairLaborPractices: fairLaborPractices.map(practice => ({
//                 numberOfSuppliersHavingFairLaborPractices: Number(practice.having),
//                 numberOfSuppliersNotHavingFairLaborPractices: Number(practice.notHaving)
//             })),
//             childLaborPractices: childLaborPractices.map(practice => ({
//                 numberOfSuppliersHavingFairLaborPractices: Number(practice.having),
//                 numberOfSuppliersNotHavingFairLaborPractices: Number(practice.notHaving)
//             })),
//             companyId: companyId
//         };

//         try {
//           if (isExist) {
//               // PUT request
//               const response = await axios.put(`${api}/social/supply_chain/${companyId}`, payload);
            
//               console.log('Updated Record:', response.data);
//           } else {
//               // POST request
//               const response = await axios.post(`${api}/social/supply_chain`, payload);
          
//               console.log('Created Record:', response.data);
//           }
//           navigate("/governance/board-composition");
//           setUpdate("GOVERNANCE");
//       } catch (error) {
//           console.error('Error saving Supply Chain Social Responsibility data:', error);
       
//       }


//     };

//     // // Delete Fair Labor Practice field
//     // const deleteFairLaborPractice = (index) => {
//     //     const updatedPractices = [...fairLaborPractices];
//     //     updatedPractices.splice(index, 1);
//     //     setFairLaborPractices(updatedPractices);
//     // };

//     // // Delete Child Labor Practice field
//     // const deleteChildLaborPractice = (index) => {
//     //     const updatedPractices = [...childLaborPractices];
//     //     updatedPractices.splice(index, 1);
//     //     setChildLaborPractices(updatedPractices);
//     // };


//     const deleteFairLaborPractice = (index) => {
//         const updatedPractices = [...fairLaborPractices];
//         updatedPractices.splice(index, 1);
//         setFairLaborPractices(updatedPractices);
//     };

//     // Delete Child Labor Practice field
//     const deleteChildLaborPractice = (index) => {
//         const updatedPractices = [...childLaborPractices];
//         updatedPractices.splice(index, 1);
//         setChildLaborPractices(updatedPractices);
//     };

//     const handleOpenDialog = (type, index) => {
//         setDeleteIndex({ type, index });
//         setOpenDialog(true);
//     };

//     const handleConfirmDelete = () => {
//         if (deleteIndex.type === 'fair') {
//             deleteFairLaborPractice(deleteIndex.index);
//         } else if (deleteIndex.type === 'child') {
//             deleteChildLaborPractice(deleteIndex.index);
//         }
//         setOpenDialog(false);
//     };
    

//     const Back = () => {
//         navigate("/social/customer-relation");
//     };

//     return (
//         <Box sx={{
//             display: "flex",
//             backgroundColor: "#EDEDED",
//             height: "100vh",
//             overflow: "hidden" // Ensure no content gets hidden by scrollbars
//           }}>
//             <Sidebar title="Supply chain social responsibility" />
//             <Box sx={{
//               p: 2,
//               mt: 3,
//               width: "100%",
//               height: "100vh", // Full viewport height
//               overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "flex-start", // Adjust to start at the top of the box
//             }}>
//               <Typography variant="h5" sx={{
//                 fontWeight: "bold",
//                 zIndex: 1,
//                 padding: "10px",
//                 borderRadius: "4px",
//                 alignSelf: "flex-start",
//                 ml: "5rem"
//               }}>
//               SOCIAL: SUPPLY CHAIN SOCIAL RESPONSIBILITY
//               </Typography>

//                 <Box sx={{width: '80%'}}>
//                 {/* Supplier Social Assessments */}
//                 <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '2px', marginTop: '20px' }}>
//                     Supplier Social Assessments
//                 </Typography>
//                 <Box display="flex" gap={2} mb={2} sx={{width: '100%'}}>
//                     <TextField
//                         variant="outlined"
//                         placeholder="Number of suppliers assessed"
//                         value={numberOfSuppliersAssessed}
//                         onChange={(e) => setNumberOfSuppliersAssessed(e.target.value)}
//                         error={!!errors.numberOfSuppliers}
//                         // helperText={errors.numberOfSuppliers}
//                         helperText={
//                             <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
//                               {errors.numberOfSuppliers}
//                             </Typography>
//                           }
//                         sx={{
//                             width: '100%',
//                             backgroundColor: '#EDEDED',
//                             borderRadius: '4px',
//                             borderColor: '#6b6c6d',
//                             '& .MuiOutlinedInput-root': {
//                                 height: '50px',
//                                 '& fieldset': {
//                                     borderColor: '#6b6c6d',
//                                 },
//                             },
//                         }}
//                         InputProps={{
//                             sx: {
//                                 padding: '0 10px',
//                             },
//                         }}
//                     />
//                     <TextField
//                         variant="outlined"
//                         placeholder="% of suppliers assessed"
//                         value={percentageOfSuppliersAssessed}
//                         onChange={(e) => setPercentageOfSuppliersAssessed(e.target.value)}
//                         error={!!errors.percentageOfSuppliers}
//                         // helperText={errors.percentageOfSuppliers}
//                         helperText={
//                             <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
//                               {errors.percentageOfSuppliers}
//                             </Typography>
//                           }
//                         sx={{
//                             width: '100%',
//                             backgroundColor: '#EDEDED',
//                             borderRadius: '4px',
//                             borderColor: '#6b6c6d',
//                             '& .MuiOutlinedInput-root': {
//                                 height: '50px',
//                                 '& fieldset': {
//                                     borderColor: '#6b6c6d',
//                                 },
//                             },
//                         }}
//                         InputProps={{
//                             sx: {
//                                 padding: '0 10px',
//                             },
//                         }}
//                     />
//                 </Box>

//                 {/* Fair Labor Practices */}
//                 <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '20px 0px 2px 0px' }}>
//                     Fair Labor Practices in Supply Chain
//                 </Typography>
//                 {fairLaborPractices.map((practice, index) => (
//                      <Box
//                      key={index}
//                      sx={{ width: "110%", display: "flex", gap: 1 }}
//                    >
//                     <Box key={index} display="flex" gap={2} mb={2} sx={{width: '91%'}}>
//                         <TextField
//                             variant="outlined"
//                             placeholder="No. of suppliers having fair labor practices"
//                             name="product"
//                             value={practice.having}
//                             onChange={(e) => {
//                                 const updatedPractices = [...fairLaborPractices];
//                                 updatedPractices[index].having = e.target.value;
//                                 setFairLaborPractices(updatedPractices);
//                             }}
//                             error={!!errors.fairLabor[index]?.having}
//                             // helperText={errors.fairLabor[index]?.having}
//                             helperText={
//                                 <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
//                                   {errors.fairLabor[index]?.having}
//                                 </Typography>
//                               }
//                             multiline
//                             rows={3}
//                             sx={{ 
//                             width: '100%',
//                             backgroundColor: '#EDEDED',
//                             borderRadius: '4px',
//                             borderColor: '#6b6c6d',
                            
//                             '& .MuiOutlinedInput-root': {
//                                 height: '86px',
//                              }
//                             }}
//                         />
//                         {/* <Box display="flex" flexDirection="column" gap={2} alignItems="flex-end" width="100%"> */}
//                         <TextField
//                             variant="outlined"
//                             placeholder="No. of suppliers not having fair labor practices"
//                             name="product"
//                             value={practice.notHaving}
//                             onChange={(e) => {
//                                 const updatedPractices = [...fairLaborPractices];
//                                 updatedPractices[index].notHaving = e.target.value;
//                                 setFairLaborPractices(updatedPractices);
//                             }}
//                             error={!!errors.fairLabor[index]?.notHaving}
//                             // helperText={errors.fairLabor[index]?.notHaving}
//                             helperText={
//                                 <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
//                                   {errors.fairLabor[index]?.notHaving}
//                                 </Typography>
//                               }
//                             multiline
//                             rows={3}
//                             sx={{ 
//                             width: '100%',
//                             backgroundColor: '#EDEDED',
//                             borderRadius: '4px',
//                             borderColor: '#6b6c6d',
//                             '& .MuiOutlinedInput-root': {
//                                 height: '86px',
//                              }
//                             }}
//                         />
//                         </Box>
//                         {index > 0 && (
//                         //   <Button  color="error" sx={{border: 'none'}} onClick={() => deleteFairLaborPractice(index)}>
//                         //     <DeleteIcon />
//                         //   </Button>
//                         <IconButton  sx={{ border: 'none' }} onClick={() => handleOpenDialog('fair', index)}>
//                                 <DeleteIcon />
//                             </IconButton>
//                             )}
//                     </Box>
//                 ))}
//                 <Button variant="outlined" startIcon={<AddIcon />}
//                     onClick={addFairLaborPractice}
//                     sx={{
//                         alignSelf: 'flex-start',
//                         color: '#204769',
//                         borderColor: '#204769',
//                         marginLeft: '89%',
//                         '&:hover': {
//                             backgroundColor: 'rgba(25, 118, 210, 0.04)',
//                             color: '#115293',
//                             borderColor: '#115293',
//                         },
//                     }}
//                 >
//                     Add
//                 </Button>

//                 {/* Child Labor Practices */}
//                 <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '20px 0px 2px 0px' }}>
//                     Child Labor Practices in Supply Chain
//                 </Typography>
//                 {childLaborPractices.map((practice, index) => (
//                     <Box
//                     key={index}
//                     sx={{ width: "110%", display: "flex", gap: 1 }}
//                   >
//                     <Box key={index} display="flex" gap={2} mb={2} sx={{ width: "91%"}}>
//                         <TextField
//                             variant="outlined"
//                             placeholder="No. of suppliers having fair labor practices"
//                             name="product"
//                             value={practice.having}
//                             onChange={(e) => {
//                                 const updatedPractices = [...childLaborPractices];
//                                 updatedPractices[index].having = e.target.value;
//                                 setChildLaborPractices(updatedPractices);
//                             }}
//                             error={!!errors.childLabor[index]?.having}
//                             // helperText={errors.childLabor[index]?.having}
//                             helperText={
//                                 <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
//                                   {errors.childLabor[index]?.having}
//                                 </Typography>
//                               }
//                             multiline
//                             rows={3}
//                             sx={{ 
//                             width: '100%',
//                             backgroundColor: '#EDEDED',
//                             borderRadius: '4px',
//                             borderColor: '#6b6c6d',
                            
//                             '& .MuiOutlinedInput-root': {
//                                 height: '86px',
//                              }
//                             }}
//                         />
//                         {/* <Box display="flex" flexDirection="column" gap={2} alignItems="flex-end" width="100%"> */}
//                         <TextField
//                             variant="outlined"
//                             placeholder="No. of suppliers not having fair labor practices"
//                             name="product"
//                             value={practice.notHaving}
//                             onChange={(e) => {
//                                 const updatedPractices = [...childLaborPractices];
//                                 updatedPractices[index].notHaving = e.target.value;
//                                 setChildLaborPractices(updatedPractices);
//                             }}
//                             error={!!errors.childLabor[index]?.notHaving}
//                             // helperText={errors.childLabor[index]?.notHaving}
//                             helperText={
//                                 <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
//                                   {errors.childLabor[index]?.notHaving}
//                                 </Typography>
//                               }
//                             multiline
//                             rows={3}
//                             sx={{ 
//                             width: '100%',
//                             backgroundColor: '#EDEDED',
//                             borderRadius: '4px',
//                             borderColor: '#6b6c6d',
                            
//                             '& .MuiOutlinedInput-root': {
//                                 height: '86px',
//                              }
//                             }}
//                         />
//                         </Box>
//                         {index > 0 && (
//                                 // <Button  color="error" sx={{border: 'none'}} onClick={() => deleteChildLaborPractice(index)}>
//                                 //     <DeleteIcon />
//                                 // </Button>
//                             <IconButton  sx={{ border: 'none' }} onClick={() => handleOpenDialog('child', index)}>
//                             <DeleteIcon />
//                             </IconButton>
//                             )}
//                         {/* </Box> */}
//                     </Box>
//                 ))}
//                 <Button variant="outlined" startIcon={<AddIcon />}
//                     onClick={addChildLaborPractice}
//                     sx={{
//                         alignSelf: 'flex-start',
//                         color: '#204769',
//                         borderColor: '#204769',
//                         marginLeft: '89%',
//                         '&:hover': {
//                             backgroundColor: 'rgba(25, 118, 210, 0.04)',
//                             color: '#115293',
//                             borderColor: '#115293',
//                         },
//                     }}
//                 >
//                     Add
//                 </Button>
//                 <ActionButtons Back={Back} Move={Save} />
//                 </Box>
//                 <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
//                     <DialogTitle>Confirm Delete</DialogTitle>
//                     <DialogContent>
//                         <DialogContentText>
//                             Are you sure you want to delete this practice?
//                         </DialogContentText>
//                     </DialogContent>
//                     <DialogActions>
//                         <Button onClick={() => setOpenDialog(false)} color="primary">
//                             Cancel
//                         </Button>
//                         <Button onClick={handleConfirmDelete} color="secondary">
//                             Confirm
//                         </Button>
//                     </DialogActions>
//                 </Dialog>
//             </Box>
//         </Box>
//     );
// };

// export default SupplyChain;







import React, { useState, useContext, useEffect} from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import { ProgressContext } from '../ContextAPI';
// import { TextField, Button, Box, Typography, TextareaAutosize } from '@mui/material';
import { TextField, Button,IconButton, Box, Typography, TextareaAutosize, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { Add as AddIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { companyId } from "../../../utils/companyId";
import { api } from "../../../utils/api";
import { textError, numberError, percentageError, twoFieldError } from "../../../utils/validationMessage";


const SupplyChain = () => {
    const { setUpdate } = useContext(ProgressContext);
    const navigate = useNavigate();

    // State to manage form data
    const [numberOfSuppliersAssessed, setNumberOfSuppliersAssessed] = useState('');
    const [percentageOfSuppliersAssessed, setPercentageOfSuppliersAssessed] = useState('');

    // State for fair labor practices
    const [fairLaborPractices, setFairLaborPractices] = useState([{ having: '', notHaving: '' }]);

    // State for child labor practices
    const [childLaborPractices, setChildLaborPractices] = useState([{ having: '', notHaving: '' }]);
    const [isExist, setIsExist] = useState(false);
    const [errors, setErrors] = useState({
        numberOfSuppliers: '',
        percentageOfSuppliers: '',
        fairLabor: [],
        childLabor: []
    });

    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState({ type: '', index: null });

    useEffect(() => {
        setUpdate("SOCIAL");
        const fetchSupplyChainData = async () => {
            try {
                const response = await axios.get(`${api}/social/supply_chain/${companyId}`);
                const supplyChainData = response.data;
    
                // Populate the form fields with the data from the API
                setNumberOfSuppliersAssessed(supplyChainData.supplierSocialAssessments.numberOfSuppliersAssessed);
                setPercentageOfSuppliersAssessed(supplyChainData.supplierSocialAssessments.percentageOfSuppliersAssessed.value);
                setFairLaborPractices(supplyChainData.fairLaborPractices.map(practice => ({
                    having: practice.numberOfSuppliersHavingFairLaborPractices,
                    notHaving: practice.numberOfSuppliersNotHavingFairLaborPractices
                })));
                setChildLaborPractices(supplyChainData.childLaborPractices.map(practice => ({
                    having: practice.numberOfSuppliersHavingFairLaborPractices,
                    notHaving: practice.numberOfSuppliersNotHavingFairLaborPractices
                })));
                setIsExist(true);
            } catch (error) {
                console.error('Error fetching Supply Chain Social Responsibility data:', error);
                // You may want to handle this error or show an alert to the user.
            }
        };
        // Only fetch data if a companyId exists
        if (companyId) {
            fetchSupplyChainData();
        }
    }, [companyId]);

    // const validateForm = () => {
    //     const newErrors = { numberOfSuppliers: '', percentageOfSuppliers: '', fairLabor: [], childLabor: [] };
    //     let isValid = true;
    
    //     if (numberOfSuppliersAssessed === '' || isNaN(numberOfSuppliersAssessed)) {
    //         newErrors.numberOfSuppliers = textError;
    //         isValid = false;
    //     } else if (Number(numberOfSuppliersAssessed) < 0) {
    //         newErrors.numberOfSuppliers = numberError;
    //         isValid = false;
    //     }
    
    //     if (percentageOfSuppliersAssessed === '' || isNaN(percentageOfSuppliersAssessed)) {
    //         newErrors.percentageOfSuppliers = textError;
    //         isValid = false;
    //     } else if (Number(percentageOfSuppliersAssessed) < 0 || Number(percentageOfSuppliersAssessed) > 100) {
    //         newErrors.percentageOfSuppliers = percentageError;
    //         isValid = false;
    //     }
    
    //     fairLaborPractices.forEach((practice, index) => {
    //         const practiceErrors = {};
    //         if (practice.having === '' || isNaN(practice.having)) {
    //             practiceErrors.having = textError;
    //             isValid = false;
    //         } else if (Number(practice.having) < 0 ) {
    //             practiceErrors.having = numberError;
    //             isValid = false;
    //         }
    //         if (practice.notHaving === '' || isNaN(practice.notHaving)) {
    //             practiceErrors.notHaving = textError;
    //             isValid = false;
    //         } else if (Number(practice.notHaving) < 0) {
    //             practiceErrors.notHaving = numberError;
    //             isValid = false;
    //         }
    //         newErrors.fairLabor.push(practiceErrors);
    //     });
    
    //     childLaborPractices.forEach((practice, index) => {
    //         const practiceErrors = {};
    //         if (practice.having === '' || isNaN(practice.having)) {
    //             practiceErrors.having = textError;
    //             isValid = false;
    //         } else if (Number(practice.having) < 0) {
    //             practiceErrors.having = numberError;
    //             isValid = false;
    //         }
    //         if (practice.notHaving === '' || isNaN(practice.notHaving)) {
    //             practiceErrors.notHaving = textError;
    //             isValid = false;
    //         } else if (Number(practice.notHaving) < 0) {
    //             practiceErrors.notHaving = numberError;
    //             isValid = false;
    //         }
    //         newErrors.childLabor.push(practiceErrors);
    //     });
    
    //     setErrors(newErrors);
    //     return isValid;
    // };
    
    
    const validateForm = () => {
        const newErrors = { numberOfSuppliers: '', percentageOfSuppliers: '', fairLabor: [], childLabor: [] };
        let isValid = true;
    
        // Validate numberOfSuppliersAssessed
        if (numberOfSuppliersAssessed === '') {
            newErrors.numberOfSuppliers = textError; // Show error if empty
            isValid = false;
        } else if (isNaN(numberOfSuppliersAssessed) || Number(numberOfSuppliersAssessed) < 0) {
            newErrors.numberOfSuppliers = numberError; // Show error if not a number or negative
            isValid = false;
        }
    
        // Validate percentageOfSuppliersAssessed
        if (percentageOfSuppliersAssessed === '') {
            newErrors.percentageOfSuppliers = textError; // Show error if empty
            isValid = false;
        } else if (isNaN(percentageOfSuppliersAssessed) || Number(percentageOfSuppliersAssessed) < 0 || Number(percentageOfSuppliersAssessed) > 100) {
            newErrors.percentageOfSuppliers = percentageError; // Show error if not a number or out of range
            isValid = false;
        }
    
        // Validate fairLaborPractices
        fairLaborPractices.forEach((practice, index) => {
            const practiceErrors = {};
            if (practice.having === '') {
                practiceErrors.having = textError; // Show error if empty
                isValid = false;
            } else if (isNaN(practice.having) || Number(practice.having) < 0) {
                practiceErrors.having = numberError; // Show error if not a number or negative
                isValid = false;
            }
            if (practice.notHaving === '') {
                practiceErrors.notHaving = textError; // Show error if empty
                isValid = false;
            } else if (isNaN(practice.notHaving) || Number(practice.notHaving) < 0) {
                practiceErrors.notHaving = numberError; // Show error if not a number or negative
                isValid = false;
            }
            newErrors.fairLabor.push(practiceErrors);
        });
    
        // Validate childLaborPractices
        childLaborPractices.forEach((practice, index) => {
            const practiceErrors = {};
            if (practice.having === '') {
                practiceErrors.having = textError; // Show error if empty
                isValid = false;
            } else if (isNaN(practice.having) || Number(practice.having) < 0) {
                practiceErrors.having = numberError; // Show error if not a number or negative
                isValid = false;
            }
            if (practice.notHaving === '') {
                practiceErrors.notHaving = textError; // Show error if empty
                isValid = false;
            } else if (isNaN(practice.notHaving) || Number(practice.notHaving) < 0) {
                practiceErrors.notHaving = numberError; // Show error if not a number or negative
                isValid = false;
            }
            newErrors.childLabor.push(practiceErrors);
        });
    
        setErrors(newErrors);
        return isValid;
    };
    

    // Function to handle adding new fields
    const addFairLaborPractice = () => {
        setFairLaborPractices([...fairLaborPractices, { having: '', notHaving: '' }]);
    };

    const addChildLaborPractice = () => {
        setChildLaborPractices([...childLaborPractices, { having: '', notHaving: '' }]);
    };

    // Function to handle Save and Continue
    const Save = async () => {
        if (!validateForm()) {
            return; // Stop if validation fails
        }

        const payload = {
            supplierSocialAssessments: {
                numberOfSuppliersAssessed: Number(numberOfSuppliersAssessed),
                percentageOfSuppliersAssessed: {
                    value: Number(percentageOfSuppliersAssessed),
                    unit: '%'
                }
            },
            fairLaborPractices: fairLaborPractices.map(practice => ({
                numberOfSuppliersHavingFairLaborPractices: Number(practice.having),
                numberOfSuppliersNotHavingFairLaborPractices: Number(practice.notHaving)
            })),
            childLaborPractices: childLaborPractices.map(practice => ({
                numberOfSuppliersHavingFairLaborPractices: Number(practice.having),
                numberOfSuppliersNotHavingFairLaborPractices: Number(practice.notHaving)
            })),
            companyId: companyId
        };

        try {
          if (isExist) {
              // PUT request
              const response = await axios.put(`${api}/social/supply_chain/${companyId}`, payload);
            
              console.log('Updated Record:', response.data);
          } else {
              // POST request
              const response = await axios.post(`${api}/social/supply_chain`, payload);
          
              console.log('Created Record:', response.data);
          }
          navigate("/governance/board-composition");
          setUpdate("GOVERNANCE");
      } catch (error) {
          console.error('Error saving Supply Chain Social Responsibility data:', error);
       
      }


    };

   

    const deleteFairLaborPractice = (index) => {
        const updatedPractices = [...fairLaborPractices];
        updatedPractices.splice(index, 1);
        setFairLaborPractices(updatedPractices);
    };

    // Delete Child Labor Practice field
    const deleteChildLaborPractice = (index) => {
        const updatedPractices = [...childLaborPractices];
        updatedPractices.splice(index, 1);
        setChildLaborPractices(updatedPractices);
    };

    const handleOpenDialog = (type, index) => {
        setDeleteIndex({ type, index });
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        if (deleteIndex.type === 'fair') {
            deleteFairLaborPractice(deleteIndex.index);
        } else if (deleteIndex.type === 'child') {
            deleteChildLaborPractice(deleteIndex.index);
        }
        setOpenDialog(false);
    };
    

    const Back = () => {
        navigate("/social/customer-relation");
    };

    return (
        <Box sx={{
            display: "flex",
            backgroundColor: "#EDEDED",
            height: "100vh",
            overflow: "hidden" // Ensure no content gets hidden by scrollbars
          }}>
            <Sidebar title="Supply chain social responsibility" />
            <Box sx={{
              p: 2,
              mt: 3,
              width: "100%",
              height: "100vh", // Full viewport height
              overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start", // Adjust to start at the top of the box
            }}>
              <Typography variant="h5" sx={{
                fontWeight: "bold",
                zIndex: 1,
                padding: "10px",
                borderRadius: "4px",
                alignSelf: "flex-start",
                ml: "5rem"
              }}>
              SOCIAL: SUPPLY CHAIN SOCIAL RESPONSIBILITY
              </Typography>

                <Box sx={{width: '80%'}}>
                {/* Supplier Social Assessments */}
                <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '2px', marginTop: '20px' }}>
                    Supplier Social Assessments
                </Typography>
                <Box display="flex" gap={2} mb={2} sx={{width: '100%'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Number of suppliers assessed"
                        value={numberOfSuppliersAssessed}
                        onChange={(e) => setNumberOfSuppliersAssessed(e.target.value)}
                        error={!!errors.numberOfSuppliers}
                        // helperText={errors.numberOfSuppliers}
                        helperText={
                            <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
                              {errors.numberOfSuppliers}
                            </Typography>
                          }
                        sx={{
                            width: '100%',
                            backgroundColor: '#EDEDED',
                            borderRadius: '4px',
                            borderColor: '#6b6c6d',
                            '& .MuiOutlinedInput-root': {
                                height: '50px',
                                '& fieldset': {
                                    borderColor: '#6b6c6d',
                                },
                            },
                        }}
                        InputProps={{
                            sx: {
                                padding: '0 10px',
                            },
                        }}
                    />
                    <TextField
                        variant="outlined"
                        placeholder="% of suppliers assessed"
                        value={percentageOfSuppliersAssessed}
                        onChange={(e) => setPercentageOfSuppliersAssessed(e.target.value)}
                        error={!!errors.percentageOfSuppliers}
                        // helperText={errors.percentageOfSuppliers}
                        helperText={
                            <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
                              {errors.percentageOfSuppliers}
                            </Typography>
                          }
                        sx={{
                            width: '100%',
                            backgroundColor: '#EDEDED',
                            borderRadius: '4px',
                            borderColor: '#6b6c6d',
                            '& .MuiOutlinedInput-root': {
                                height: '50px',
                                '& fieldset': {
                                    borderColor: '#6b6c6d',
                                },
                            },
                        }}
                        InputProps={{
                            sx: {
                                padding: '0 10px',
                            },
                        }}
                    />
                </Box>

                {/* Fair Labor Practices */}
                <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '20px 0px 2px 0px' }}>
                    Fair Labor Practices in Supply Chain
                </Typography>
                {fairLaborPractices.map((practice, index) => (
                     <Box
                     key={index}
                     sx={{ width: "110%", display: "flex", gap: 1 }}
                   >
                    <Box key={index} display="flex" gap={2} mb={2} sx={{width: '91%'}}>
                        <TextField
                            variant="outlined"
                            placeholder="No. of suppliers having fair labor practices"
                            name="product"
                            value={practice.having}
                            onChange={(e) => {
                                const updatedPractices = [...fairLaborPractices];
                                updatedPractices[index].having = e.target.value;
                                setFairLaborPractices(updatedPractices);
                            }}
                            error={!!errors.fairLabor[index]?.having}
                            // helperText={errors.fairLabor[index]?.having}
                            helperText={
                                <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
                                  {errors.fairLabor[index]?.having}
                                </Typography>
                              }
                            multiline
                            rows={3}
                            sx={{ 
                            width: '100%',
                            backgroundColor: '#EDEDED',
                            borderRadius: '4px',
                            borderColor: '#6b6c6d',
                            
                            '& .MuiOutlinedInput-root': {
                                height: '86px',
                             }
                            }}
                        />
                        {/* <Box display="flex" flexDirection="column" gap={2} alignItems="flex-end" width="100%"> */}
                        <TextField
                            variant="outlined"
                            placeholder="No. of suppliers not having fair labor practices"
                            name="product"
                            value={practice.notHaving}
                            onChange={(e) => {
                                const updatedPractices = [...fairLaborPractices];
                                updatedPractices[index].notHaving = e.target.value;
                                setFairLaborPractices(updatedPractices);
                            }}
                            error={!!errors.fairLabor[index]?.notHaving}
                            // helperText={errors.fairLabor[index]?.notHaving}
                            helperText={
                                <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
                                  {errors.fairLabor[index]?.notHaving}
                                </Typography>
                              }
                            multiline
                            rows={3}
                            sx={{ 
                            width: '100%',
                            backgroundColor: '#EDEDED',
                            borderRadius: '4px',
                            borderColor: '#6b6c6d',
                            '& .MuiOutlinedInput-root': {
                                height: '86px',
                             }
                            }}
                        />
                        </Box>
                        {index > 0 && (
                        //   <Button  color="error" sx={{border: 'none'}} onClick={() => deleteFairLaborPractice(index)}>
                        //     <DeleteIcon />
                        //   </Button>
                        <IconButton  sx={{ border: 'none' }} onClick={() => handleOpenDialog('fair', index)}>
                                <DeleteIcon />
                            </IconButton>
                            )}
                    </Box>
                ))}
                  <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
                <Button variant="outlined" startIcon={<AddIcon />}
                    onClick={addFairLaborPractice}
                    sx={{
                        alignSelf: "flex-end",
                        color: "#74757E",
                        borderColor: "#74757E",
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                        "&:hover": {
                          backgroundColor: "rgba(25, 118, 210, 0.04)",
                          color: "#115293",
                          borderColor: "#115293",
                        },
                      }}
                >
                    Add
                </Button>
                </Box>

                {/* Child Labor Practices */}
                <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '20px 0px 2px 0px' }}>
                    Child Labor Practices in Supply Chain
                </Typography>
                {childLaborPractices.map((practice, index) => (
                    <Box
                    key={index}
                    sx={{ width: "110%", display: "flex", gap: 1 }}
                  >
                    <Box key={index} display="flex" gap={2} mb={2} sx={{ width: "91%"}}>
                        <TextField
                            variant="outlined"
                            placeholder="No. of suppliers having fair labor practices"
                            name="product"
                            value={practice.having}
                            onChange={(e) => {
                                const updatedPractices = [...childLaborPractices];
                                updatedPractices[index].having = e.target.value;
                                setChildLaborPractices(updatedPractices);
                            }}
                            error={!!errors.childLabor[index]?.having}
                            // helperText={errors.childLabor[index]?.having}
                            helperText={
                                <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
                                  {errors.childLabor[index]?.having}
                                </Typography>
                              }
                            multiline
                            rows={3}
                            sx={{ 
                            width: '100%',
                            backgroundColor: '#EDEDED',
                            borderRadius: '4px',
                            borderColor: '#6b6c6d',
                            
                            '& .MuiOutlinedInput-root': {
                                height: '86px',
                             }
                            }}
                        />
                        {/* <Box display="flex" flexDirection="column" gap={2} alignItems="flex-end" width="100%"> */}
                        <TextField
                            variant="outlined"
                            placeholder="No. of suppliers not having fair labor practices"
                            name="product"
                            value={practice.notHaving}
                            onChange={(e) => {
                                const updatedPractices = [...childLaborPractices];
                                updatedPractices[index].notHaving = e.target.value;
                                setChildLaborPractices(updatedPractices);
                            }}
                            error={!!errors.childLabor[index]?.notHaving}
                            // helperText={errors.childLabor[index]?.notHaving}
                            helperText={
                                <Typography variant="body2" sx={{ fontSize: '14px', marginLeft: '-10px', color: '#D34040' }}>
                                  {errors.childLabor[index]?.notHaving}
                                </Typography>
                              }
                            multiline
                            rows={3}
                            sx={{ 
                            width: '100%',
                            backgroundColor: '#EDEDED',
                            borderRadius: '4px',
                            borderColor: '#6b6c6d',
                            
                            '& .MuiOutlinedInput-root': {
                                height: '86px',
                             }
                            }}
                        />
                        </Box>
                        {index > 0 && (
                                // <Button  color="error" sx={{border: 'none'}} onClick={() => deleteChildLaborPractice(index)}>
                                //     <DeleteIcon />
                                // </Button>
                            <IconButton  sx={{ border: 'none' }} onClick={() => handleOpenDialog('child', index)}>
                            <DeleteIcon />
                            </IconButton>
                            )}
                        {/* </Box> */}
                    </Box>
                ))}
                   <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
                <Button variant="outlined" startIcon={<AddIcon />}
                    onClick={addChildLaborPractice}
                    sx={{
                        alignSelf: "flex-end",
                        color: "#74757E",
                        borderColor: "#74757E",
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                        "&:hover": {
                          backgroundColor: "rgba(25, 118, 210, 0.04)",
                          color: "#115293",
                          borderColor: "#115293",
                        },
                      }}
                >
                    Add
                </Button>
                </Box>
                <ActionButtons Back={Back} Move={Save} />
                </Box>
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this practice?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmDelete} color="secondary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default SupplyChain;
