import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import InputField from "../../../../Components/Masters/InputField";
import SelectField from "../../../../Components/Masters/SelectField";
import FileField from "../../../../Components/Masters/FileField";
import { validateNumber } from "../../../../utils/validateFields";
import { numberError } from "../../../../utils/validationMessage";
import Multiline from "../../../../Components/Masters/Multiline";

function AddOnRoadVahicle({
  inputData,
  fetchData,
  id,
  data,
  setAddData,
  addData,
  setsnackOpen,
  addUrl,
  updateUrl,
}) {
  const [errors, setErrors] = useState({});
  const initialState = {
    ownershipType: "",
    vehicleType: "",
    vehicleNumber: "",
    logisticsVendor: "",
    fuelType: "",
    // fuelConsumed: "",
    fuelConsumed: { value: "", unit: "" },
    distanceCovered: "",
    remarks: "",
    documents: [],
    company: companyId,
    createdBy: companyId,
  };
  const [formData, setFormData] = useState(initialState);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});



  
  useEffect(() => {
    if (id && data) {
      console.log("Data received for editing:", data);
      
      setFormData({
        ownershipType: data.ownershipType || "",
        vehicleType: data.vehicleType || "",
        vehicleNumber: data.vehicleNumber || "",
        logisticsVendor: data.logisticsVendor?._id || "",
        fuelType: data.fuelType || "",
        // fuelConsumed: data.fuelConsumed || "",
        fuelConsumed: {
          value: data.fuelConsumed?.value || "",
          unit: data.fuelConsumed?.unit || "",
        },
        distanceCovered: data.distanceCovered || "",
        remarks: data.remarks || "",
        documents: data.documents || [], // Handle empty array
      });
      
      // Set uploaded files based on the documents array
      if (data.documents && data.documents.length > 0) {
        setUploadedFiles(data.documents);
      } else {
        setUploadedFiles([]); // Explicitly set to empty if no documents
      }
      
      console.log("Uploaded files set:", uploadedFiles); // Check the uploaded files state
    } else if (!id) {
      setFormData(initialState);
      setUploadedFiles([]);
    }
  }, [data, id]);
  
  
 
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  
    // Check if the value is empty for logisticsVendor
    if (name === "logisticsVendor" && value.trim() === "") {
      setFormData((prevState) => ({ ...prevState, [name]: undefined })); // Set to undefined or remove the field
    } 
   else if (name.startsWith("fuelConsumed.")) {
      const key = name.split(".")[1];
      setFormData((prevState) => ({
        ...prevState,
        fuelConsumed: {
          ...prevState.fuelConsumed,
          [key]: value,
        },
      }));
      setErrors((prevState) => ({
        ...prevState,
        fuelConsumed: {
          ...prevState.fuelConsumed,
          [key]: "",
        },
      }));
    }
    else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }

  };



  
  const handleClose = () => {
    setAddData(false);
    setErrors({});
    // setFormData(initialState); // Reset form data to initial state
    // setUploadedFiles([]); // Reset uploaded files
};


  const validateForm = () => {
 
    const newErrors = {};
    console.log(formData)

    inputData.forEach((input) => {
        const value = formData[input.fieldName];

          // Check for fuelConsumed separately
        if (input.fieldName === "fuelConsumed") {
          const cvValue = formData[input.fieldName]?.value || ""; // Ensure it's a string
          const cvUnit = formData[input.fieldName]?.unit || ""; // Ensure it's a string
          console.log("Fuel Consumed Value:", formData.fuelConsumed?.value);
          console.log("Fuel Consumed Unit:", formData.fuelConsumed?.unit);

        if (!validateNumber(cvValue)) {
              newErrors[input.fieldName] = {
                  ...newErrors[input.fieldName],
                  value: numberError,
              };
          }

          if (!cvUnit.trim()) {
              newErrors[input.fieldName] = {
                  ...newErrors[input.fieldName],
                  unit: "Unit is required.",
              };
          }
          return; // Ensure to return after handling errors for fuelConsumed
      }


        // Validation for numeric inputs
        if (input.type === "number") {
            if (!validateNumber(value)) {
                newErrors[input.fieldName] = numberError;
                return; // Stop further checks for this input
            }
        }

        if (input.fieldName === "documents" && uploadedFiles.length === 0) {
          console.log(input.fieldName,uploadedFiles)
           newErrors[input.fieldName] = `Please upload Documents`;
         }

        // Additional checks for required fields
        if (input.required && input.type!=="number" && (!value || value.trim() === ""))
         {
            newErrors[input.fieldName] = `${input.title} is required.`;
        }
    });

    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};



// const Save = async () => {
//   if (!validateForm()) return;

//   // Prepare data to send
//   const dataToSend = {
//     ...formData,
//     documents: uploadedFiles,
//   };

//   // Conditionally delete fields if typeOfEntry is not "Individual"
//   if (dataToSend.logisticsVendor === "") {
//     delete dataToSend.logisticsVendor;
//   }
//   console.log("Payload to send:", dataToSend);

//   await axios({
//     method: id ? "put" : "post",
//     url: id ? `${updateUrl}` : addUrl,
//     data: dataToSend,
//     params: id ? { Id: id } : null, // Send params only for PUT request
//   })
//     .then((res) => {
//       setsnackOpen({
//         open: true,
//         message: res.data.message,
//         severity: "success",
//       });
//       fetchData();
//       setFormData({});
//       handleClose();
//     })
//     .catch((err) => {
//       if (err.response && err.response.data && err.response.data.errors) {
//         const errorMessages = err.response.data.errors
//           .map((error) => error.msg)
//           .join(", ");
//         setsnackOpen({
//           open: true,
//           message: errorMessages,
//           severity: "error",
//         });
//       }
//     });
// };
const Save = async () => {
  if (!validateForm()) return;

  // Prepare data to send
  const dataToSend = {
    ...formData,
    documents: uploadedFiles,
  };

  // Conditionally delete fields if typeOfEntry is not "Individual"
  if (dataToSend.logisticsVendor === "") {
    delete dataToSend.logisticsVendor;
  }
  console.log("Payload to send:", dataToSend);

  try {
    const res = await axios({
      method: id ? "put" : "post",
      url: id ? `${updateUrl}` : addUrl,
      data: dataToSend,
      params: id ? { Id: id } : null, // Send params only for PUT request
    });

    setsnackOpen({
      open: true,
      message: res.data.message,
      severity: "success",
    });
    fetchData();
    setFormData({});
    handleClose();
  } catch (err) {
    // Check if the error is related to vehicle number duplication
    if (
      err.response &&
      err.response.data &&
      err.response.data.message === "Vehicle Number already exists."
    ) {
      // Set a custom error message for vehicleNumber
      setErrors((prevErrors) => ({
        ...prevErrors,
        vehicleNumber: "This Vehicle Number already exists.",
      }));
    } else if (err.response && err.response.data && err.response.data.errors) {
      // Handle other validation errors from the backend
      const errorMessages = err.response.data.errors
        .map((error) => error.msg)
        .join(", ");
      setsnackOpen({
        open: true,
        message: errorMessages,
        severity: "error",
      });
    }
  }
};


  const renderInputField = (el) => {
    if (el.options || el.type === "select") {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          options={el.options}
          errors={errors[el.fieldName]}
          url={el.url}
          field={el.field}
        />
      );
    }

    if (el.type === "file") {
      return (
        <FileField
          title={el.title}
          name={el.fieldName}
          errors={errors[el.fieldName]}
          fileUrls={uploadedFiles}
          progress={uploadProgress[el.fieldName] || []}
          {...{setUploadedFiles,setUploadProgress,setErrors}}
          // onDelete={handleDeleteFile}
        />
      );
    }

    if (el.fieldName === "fuelConsumed") {
      return (
        <Box display="flex" gap={1} sx={{ width: "100%" }}>
          <Box sx={{ width: "50%" }}>
            <InputField
              title={el.title}
              handleChange={handleChange}
              placeholder={el.placeholder}
              value={formData.fuelConsumed?.value || ""}
              errors={errors[el.fieldName]?.value} // Access error for value
              fieldName={"fuelConsumed.value"}
            />
          </Box>
          <Box sx={{ width: "50%", mt: "9%" }}>
            <SelectField
              name={"fuelConsumed.unit"}
              value={formData.fuelConsumed?.unit}
              handleChange={handleChange}
              options={["Liters", "Kg", "KWH"]}
              errors={errors[el.fieldName]?.unit} // Access error for unit
              field={el.field}
            />
          </Box>
        </Box>
      );
    }

    
    
   

    if (el.fieldName === "remarks")
      return (
        <Multiline
          title={el.title}
          handleChange={handleChange}
          placeholder={el.placeholder}
          value={formData[el.fieldName] || ""}
          errors={errors[el.fieldName]}
          fieldName={el.fieldName}
        />
      );

    return (
      <InputField
        title={el.title}
        handleChange={handleChange}
        placeholder={el.placeholder}
        value={formData[el.fieldName] || ""}
        errors={errors[el.fieldName]}
        fieldName={el.fieldName}
      />
    );
  };

  const list = () => (
    <Box display="flex" gap={3} sx={{ width: "400px",maxWidth:"100%" }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton onClick={handleClose} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "88%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}
        >
          {id ? "Edit Entry" : "Add Entry"}
        </Typography>
        {inputData.map((el) => {
          // Conditional rendering for hours and industrial equipment
          if (
            (el.fieldName === "hours" ||
              el.fieldName === "industrialEquipment") &&
            formData.typeOfEntry !== "Individual"
          ) {
            return null; // Skip rendering these fields if typeOfEntry is not "Individual"
          }

          return <div key={el.fieldName}>{renderInputField(el)}</div>;
        })}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      sx={{
        backgroundColor: "transparent",
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          height: "100vh",
          minHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
      anchor="right"
      open={addData}
      onClose={handleClose}
    >
      {list()}
    </Drawer>
  );
}

export default AddOnRoadVahicle;
