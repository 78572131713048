import React, { useEffect, useState } from "react";
import ViewTable from "../../../../Components/Common/ViewTable";
import { Button, PaginationItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { get } from "../../../../utils/api";
import styled from "@emotion/styled";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterModal from "../../../../Components/Common/FilterModal";
// import FormModal from "../../../../Components/Common/FormModal";
import Cards from "../../../../Components/Common/Cards";

const Test = () => {
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterObj, setFilterObj] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const headers = [
    { key: "createdAt", label: "Date", sortable: true },
    { key: "industrialProcess", label: "Industrial Process", sortable: true },
    { key: "equipmentType", label: "Equipment Type", sortable: true },
    {
      key: "quantityOfNaturalGasConsumed",
      label: "Quantity Consumed",
      sortable: true,
    },
    { key: "unit", label: "Unit", sortable: true },
  ];

  const fetchData = async () => {
    await get("/stationaryCombustion/getAllNaturalGas")
      .then((res) => {
        console.log(res);
        let arr = res?.data?.data?.map((item) => {
          return {
            createdAt: new Date(item.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
            industrialProcess: item?.industrialProcess?.industryProcess,
            equipmentType: item.equipmentType?.name,
            quantityOfNaturalGasConsumed:
              item.quantityOfNaturalGasConsumed?.value,
            unit: item.quantityOfNaturalGasConsumed?.unit,
            action: "View",
            id: item?._id,
          };
        });
        setTableData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      setEditData(dataForEdit);
    }
  };

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  console.log("first", isModalOpen);

  const handleSort = (key, direction) => {
    setSortConfig({ key, direction });
    // Add sort logic here
  };

  const handleMenuClick = (event, row) => {
    // Action menu logic here
    console.log("menu");
  };

  const handleEdit = (data) => {
    console.log("Edit equipment:", data);
  };

  let bannerData = [
    {
      title: "Natural Gas Consumption",
      description: "This is the description of the banner.",
    },
    {
      title: "Natural Gas Consumption",
      description: ["one", "two"],
    },
    {
      title: "Natural Gas Consumption",
      description: "This is the description of the banner.",
    },
  ];

  const handleSubmit = (data) => {
    console.log("Form submitted:", data);
    setIsModalOpen(false);
  };

  const eventsFormFields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      required: false,
      disabled: false,
    },
    {
      name: "type",
      label: "Event Type",
      type: "text",
      required: false,
      disabled: false,
    },
    {
      name: "organizer",
      label: "Organizer",
      type: "text",
      required: false,
      disabled: false,
    },
    {
      name: "startDate",
      label: "Event Start Date",
      type: "date",
      required: false,
    },
    {
      name: "endDate",
      label: "Event End Date",
      type: "date",
      required: false,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      required: false,
      disabled: false,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: false,
      disabled: false,
    },
    {
      name: "country",
      label: "Country",
      type: "text",
      required: false,
      disabled: false,
    },
  ];

  const filterData = [
    {
      title: "Filter 1",
      type: "option",
      key: "filter1",
      value: ["one", "two"],
    },
    { title: "Filter 2", type: "date", key: "filter2", value: [] },
  ];

  const handleFilterChange = (newFilterObj) => {
    setFilterObj(newFilterObj);
    console.log("Applied Filters:", newFilterObj);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  console.log("data", currentPage);
  return (
    <>
      <Cards data={bannerData} title={"Natural Gas Consumption"} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "1rem",
          gap: "1rem",
        }}
      >
        <Button
          style={{
            backgroundColor: "#fff",
            color: "#000000",
            borderRadius: "4px",
            gap: "1rem",
          }}
          onClick={() => setIsFilterModalOpen(true)}
        >
          Filter <FilterListIcon />
        </Button>
        <Button
          style={{
            backgroundColor: "#1C486B",
            color: "#ffffff",
            borderRadius: "4px",
            gap: "0.5rem",
          }}
          onClick={() => openModal("add")}
        >
          Add Data
          <AddIcon />
        </Button>
      </div>

      <ViewTable
        headers={headers}
        data={tableData}
        onSort={handleSort}
        sortConfig={sortConfig}
        onMenuClick={handleMenuClick}
        page={currentPage}
        count={pageSize}
        onPageChange={handlePageChange}
        onView={() => {
          console.log("first");
        }}
        onEdit={(row) => openModal("edit", row)}
        onDelete={() => {
          console.log("first");
        }}
      />

      {/* <FormModal
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        fields={eventsFormFields}
        header={editModal ? "Edit Event" : "Add Event"}
        initialData={editData}
        isEditing={editModal}
      /> */}

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        filters={filterData}
        onFilterChange={handleFilterChange}
        title="Test Filter"
      />
    </>
  );
};

export default Test;
