import React from "react";
import {
  Box,
  Typography,
  IconButton,
 } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DescriptionIcon from '@mui/icons-material/Description'; // For CSV
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

function DocumentItem({ url }) {
  const fileName = decodeURIComponent(url.split('/').pop());
  const fileExtension = fileName.split('.').pop().toLowerCase();
  const isImage = ['png', 'jpg', 'jpeg'].includes(fileExtension);
  const isCsv = fileExtension === 'csv';
  const isExcel = ['xls', 'xlsx'].includes(fileExtension);


  const handleDownload = (url) => {
    const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/");
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        border: "1px solid #ddd",
        borderRadius: "4px",
        backgroundColor: "#f9f9f9",
        width: isImage ? "auto" : "48%",
        minWidth: "100px",
      }}
    >
      {isImage ? (
        <img
          src={url}
          alt={fileName}
          style={{
            width: "100px",
            height: "80px",
            objectFit: "cover",
            borderRadius: "4px",
          }}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            height: "30px",
            alignItems: 'center',
            gap: 1,
            padding: 1,
            width: "100%",
          }}
        >
          {isCsv ? (
            <DescriptionIcon sx={{ fontSize: "20px", color: '#1A73E8' }} />
          ) : isExcel ? (
            <FontAwesomeIcon icon={faFileExcel} style={{ fontSize: "17px",padding:1, color: '#0a8f08' }} />
          ) : (
            <DescriptionIcon sx={{fontSize: "20px", color: '#ccc' }} />
          )}
          <Typography variant="caption" noWrap sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}>
            {fileName}
          </Typography>
        </Box>
      )}
      <IconButton
        onClick={() => handleDownload(url)}
        sx={{
          position: "absolute",
          top: "0",
          right: "0",
          color: "white",
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "20px",
          height: "20px",
          '&:hover': {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        }}
      >
        <ArrowDownwardIcon sx={{ width: "10px", height: "10px" }} />
      </IconButton>
    </Box>
  );
}

export default DocumentItem;
