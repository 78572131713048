import { Grid, Typography } from '@mui/material'
import React from 'react'
import { formatDate } from '../../utils/formatDate'

const ViewDate = ({data}) => {
  return (
    <Grid item xs={12} sm={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  Date
                </Typography>
                <div>
                  {formatDate(data?.createdAt)}
                </div>
              </Grid>
  )
}

export default ViewDate