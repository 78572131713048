import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import contries from "../Roles and Permissions/Country.json";
import { api } from "../../../utils/api";
import { companyId } from "../../../utils/companyId";
import SelectField from "../../../Components/Masters/SelectField";

function AddFacility(props) {
  const [moduleName, setmoduleName] = useState([]);
  const [alreadyFacility, setAlreadyFacility] = useState(false);
  const [errors, setErrors] = useState({});
  const initialState={  companyName: companyId,
    facilityName: "",
    address: "",
    nickname: "",
    incharge: "",
    outsourceFacility: "yes",
    country: "",}

    
  const [fromValue, setFromValue] = useState(initialState);

  const resetForm = () => {
    console.log(" props.selectedFacility", props.selectedFacility)
    setFromValue(props.selectedFacility===null ?initialState:props.selectedFacility);
   setErrors({});
 };
  useEffect(() => {
    setFromValue({
      companyName: companyId,
      facilityName: props.selectedFacility?.facilityName ?? "",
      address: props.selectedFacility?.address ?? "",
      nickname: props.selectedFacility?.nickname ?? "",
      incharge: props.selectedFacility?.incharge ?? "",
      outsourceFacility: props.selectedFacility?.outsourceFacility ?? "yes",
      country: props.selectedFacility?.country ?? "",
    });
    setAlreadyFacility(props.selectedFacility === null ? false : true);
  }, [props.selectedFacility]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("nam===e", name, value);
    setFromValue({
      ...fromValue,
      [name]: value,
    });
  };
  const validateForm = () => {
    const newErrors = {};

    const {
      companyName,
      facilityName,
      address,
      nickname,
      incharge,
      outsourceFacility,
      country,
    } = fromValue;

    // Validate `companyName`
    // if (!companyName || companyName.trim() === "") {
    //   newErrors.companyName = "Company name is required.";
    // }

    // Validate `facilityName`
    if (!facilityName || facilityName.trim() === "") {
      newErrors.facilityName = "Facility name is required.";
    }

    // Validate `address`
    if (!address || address.trim() === "") {
      newErrors.address = "Address is required.";
    }

    // Validate `nickname`
    if (!nickname || nickname.trim() === "") {
      newErrors.nickname = "Nickname is required.";
    }

    // Validate `incharge`
    if (!incharge || incharge.trim() === "") {
      newErrors.incharge = "Incharge name is required.";
    }

    // Validate `outsourceFacility`
    if (
      !outsourceFacility ||
      (outsourceFacility !== "yes" && outsourceFacility !== "no")
    ) {
      newErrors.outsourceFacility =
        "Outsource facility must be either 'yes' or 'no'.";
    }

    // Validate `country`
    if (!country || country.trim() === "") {
      newErrors.country = "Country is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log(props.selectedFacility?._id,props.selectedFacility, "props.selectedFacility");
  const Save = () => {
    if (!validateForm()) {
      return;
    }
    console.log(fromValue);
    axios({
      method: alreadyFacility ? "put" : "post",
      url: alreadyFacility
        ? `${api}/master/updateFacility`
        : `${api}/master/addFacility`,
      data: fromValue, // Send data only if it's a POST request
      params: alreadyFacility ? { Id: props.selectedFacility?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        console.log(res, res.data.data);
        props.setAddFacility(false);
        props.fetchFacilities();
        resetForm();
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        //  localStorage.setItem("roleId",res.data.data._id)
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          // If there is a message in the error response, use it
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{width:400,
        // maxWidth:"100%",
        height: '100vh',
        display:"flex",justifyContent:"space-btween",
          backgroundColor:"transparent"
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {props.setAddFacility(false);resetForm();}}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll",
}}>
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          {props.selectedFacility?._id === undefined? "Add Facility" : "Edit New Facility"}
        </Typography>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Name of Facility
        </Typography>

        <CustomTextField
          //   select
          variant="outlined"
          value={fromValue.facilityName}
          onChange={handleChange}
          fullWidth
          name="facilityName"
          placeholder="Enter Name"
          error={!!errors.facilityName} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.facilityName ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.facilityName && (
          <Typography variant="body2" color="#D34040">
            {errors.facilityName}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Address
        </Typography>
        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.address}
          onChange={handleChange}
          fullWidth
          name="address"
          placeholder="Address"
          error={!!errors.address} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.address ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.address && (
          <Typography variant="body2" color="#D34040">
            {errors.address}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Nickname
        </Typography>
        {console.log(
          "console.log(fromValue.allowed_permissions)",
          fromValue.allowed_permissions
        )}
        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.nickname}
          onChange={handleChange}
          fullWidth
          name="nickname"
          placeholder="Nickname"
          error={!!errors.nickname} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.nickname ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.nickname && (
          <Typography variant="body2" color="#D34040">
            {errors.nickname}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Incharge
        </Typography>
        {console.log(
          "console.log(fromValue.allowed_permissions)",
          fromValue.allowed_permissions
        )}
        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.incharge}
          onChange={handleChange}
          fullWidth
          name="incharge"
          placeholder="Incharge"
          error={!!errors.incharge} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.incharge ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.incharge && (
          <Typography variant="body2" color="#D34040">
            {errors.incharge}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Outsource Facility
        </Typography>
        {console.log(
          "console.log(fromValue.allowed_permissions)",
          fromValue.allowed_permissions
        )}
        {/* <CustomTextField
          //  select
      variant="outlined"
        value={fromValue.outsourceFacility}
      onChange={handleChange}
      fullWidth
      name="outsourceFacility"
      placeholder="Outsource"
    >
    </CustomTextField> */}

        <RadioGroup
          row
          value={fromValue.outsourceFacility}
          name="outsourceFacility"
          onChange={handleChange}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        {/* <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Country
        </Typography> */}
        { console.log(fromValue.country,"fromValue.country")}

        {/* <CustomTextField
          variant="outlined"
          select
          //   label="Select Country"
          // value={fromValue.country}
          value={fromValue.country===""?"Select Country":fromValue.country}
          onChange={handleChange}
          fullWidth
          name="country"
          placeholder="Select Country"
          error={!!errors.country} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.country ? "#D34040" : undefined },
          }}
        >
         
           <MenuItem value={fromValue.country==="" ? "Select Country" : fromValue.country} disabled>
           {fromValue.country===""? "Select Country" : fromValue.country}
            </MenuItem>

          {contries.countries.map((country) => (
            // console.log(country.name,country.code)
            <MenuItem key={country.code} value={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.country && (
          <Typography variant="body2" color="#D34040">
            {errors.country}
          </Typography>
        )} */}
        <SelectField
          title={"Country"}
          name={"country"}
          value={fromValue.country}
          handleChange={handleChange}
          options={contries.countries}
          errors={errors["country"]}
          //   url={el.url}
        />
        {/* <Box> */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0a3f73",
            textTransform: "none",
            marginTop: "10%",
          }}
          onClick={Save}
        >
          Submit
        </Button>
        {/* </Box> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addFacility}
        onClose={() => {props.setAddFacility(false);resetForm();}}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddFacility;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
