/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import style from "../../../styles/common/formModal.module.css";

const Form = ({
  isOpen,
  onClose,
  onSubmit,
  header,
  initialData,
  isEditing,
}) => {
  const initialFormData = {
    createdAt: initialData?.createdAt || "",
    origin: initialData?.origin || "",
    destination: initialData?.destination || "",
    freightLoad: initialData?.freightLoad || "",
    freightLoadUnit: initialData?.freightLoadUnit || "Kg",
    distance: initialData?.distance || "",
    distanceUnit: initialData?.distanceUnit || "Km",
    categoryOfShipment: initialData?.categoryOfShipment || "",
    typeOfShipment: initialData?.typeOfShipment || "",
    remark: initialData?.remark || "",
    document: initialData?.document || "",
  };

  console.log("ins", initialData);

  const initialErrors = {};

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (isOpen && isEditing) {
      console.log("check", initialData);
      setFormData(initialData);
      console.log(
        "check",
        formData?.categoryOfShipment,
        formData?.typeOfShipment
      );
    } else {
      setFormData(initialFormData);
    }
  }, [isOpen, isEditing, initialData]);

  const handleCancel = () => {
    setFormData(initialFormData);
    setErrors(initialErrors);
    onClose();
  };

  const validateFormData = () => {
    const newErrors = {};
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = () => {
    if (validateFormData()) {
      onSubmit(formData, isEditing);
      onClose();
    }
  };

  const handleChange = (field) => (event) => {
    const { value } = event.target;
    console.log("one", field, value);
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
      ...(field === "categoryOfShipment" && { typeOfShipment: "" }),
    }));
  };

  const getShipmentOptions = () => {
    if (formData?.categoryOfShipment === "Liquid Shipment") {
      return [
        <MenuItem key="Crude tanker" value="Crude tanker">
          Crude tanker
        </MenuItem>,
        <MenuItem key="Products tanker" value="Products tanker">
          Products tanker
        </MenuItem>,
        <MenuItem key="Chemical tanker" value="Chemical tanker">
          Chemical tanker
        </MenuItem>,
        <MenuItem key="LNG tanker" value="LNG tanker">
          LNG tanker
        </MenuItem>,
        <MenuItem key="LPG tanker" value="LPG tanker">
          LPG tanker
        </MenuItem>,
      ];
    } else if (formData?.categoryOfShipment === "Dry Shipment") {
      return [
        <MenuItem key="Bulk carrier" value="Bulk carrier">
          Bulk carrier
        </MenuItem>,
        <MenuItem key="General cargo" value="General cargo">
          General cargo
        </MenuItem>,
        <MenuItem key="Container ship" value="Container ship">
          Container ship
        </MenuItem>,
        <MenuItem key="Vehicle transport" value="Vehicle transport">
          Vehicle transport
        </MenuItem>,
        <MenuItem key="RoRo-Ferry" value="RoRo-Ferry">
          RoRo-Ferry
        </MenuItem>,
        <MenuItem key="Large RoPax Ferry" value="Large RoPax Ferry">
          Large RoPax Ferry
        </MenuItem>,
        <MenuItem key="Refrigerated cargo" value="Refrigerated cargo">
          Refrigerated cargo
        </MenuItem>,
      ];
    }
    return [
      <MenuItem key="default" value="">
        Select
      </MenuItem>,
    ];
  };

  console.log("form1", formData);

  return (
    <Modal
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className={style.main_div}>
        <Typography
          id="modal-title"
          variant="h5"
          component="h2"
          align="left"
          style={{
            textDecoration: "underline",
            marginBottom: "1rem",
            fontSize: "24px",
            fontWeight: "600",
          }}
        >
          {header}
        </Typography>
        <form>
          <div style={{ margin: "1rem 0" }}>
            <Typography>Date</Typography>
            <TextField
              type="date"
              size="small"
              fullWidth
              value={formData.createdAt}
              onChange={handleChange("createdAt")}
            />

            <Typography style={{ marginTop: "0.5rem" }}>Source</Typography>
            <TextField
              type="text"
              size="small"
              fullWidth
              value={formData.origin}
              onChange={handleChange("source")}
            />

            <Typography style={{ marginTop: "0.5rem" }}>Destination</Typography>
            <TextField
              type="text"
              size="small"
              fullWidth
              value={formData.destination}
              onChange={handleChange("destination")}
            />
            <Typography style={{ marginTop: "0.5rem" }}>
              Shipment Category
            </Typography>
            <Select
              size="small"
              name="categoryOfShipment"
              value={formData?.categoryOfShipment}
              fullWidth
              onChange={handleChange("categoryOfShipment")}
            >
              <MenuItem value="Liquid Shipment">Liquid Shipment</MenuItem>
              <MenuItem value="Dry Shipment">Dry Shipment</MenuItem>
            </Select>

            <Typography style={{ marginTop: "0.5rem" }}>
              Shipment Type
            </Typography>
            <Select
              size="small"
              name="typeOfShipment"
              fullWidth
              value={formData?.typeOfShipment || ""}
              onChange={(event) => {
                const { value } = event.target;
                setFormData((prevData) => ({
                  ...prevData,
                  typeOfShipment: value,
                }));
              }}
            >
              {getShipmentOptions()}
            </Select>

            <Box marginTop="0.5rem">
              <Typography>Distance Travelled</Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                fullWidth
              >
                <TextField
                  type="text"
                  size="small"
                  name="freightLoad"
                  value={formData.freightLoad}
                  placeholder="Enter Distance"
                  onChange={handleChange("distance")}
                  sx={{ width: "50%" }}
                />
                <Select
                  size="small"
                  name="distanceUnit"
                  value={"Km"}
                  onChange={handleChange("distanceUnit")}
                  sx={{ width: "45%" }}
                >
                  <MenuItem value="Km">Km</MenuItem>
                  <MenuItem value="Mile">Mile</MenuItem>
                </Select>
              </Box>
            </Box>

            <Typography style={{ marginTop: "0.5rem" }}>Remark</Typography>
            <TextField
              type="text"
              size="small"
              fullWidth
              value={formData.remark}
              onChange={handleChange("remark")}
            />

            <Box
              sx={{
                border: "2px solid rgba(28, 72, 107, 1)",
                borderRadius: 1,
                p: 2,
                textAlign: "center",
                cursor: "pointer",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <input
                type="file"
                hidden
                id={`upload-document`}
                name="document"
                onChange={handleChange("document")}
                multiple
              />
              <label>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CloudUploadIcon />
                  <Typography>Drop files here or click to upload</Typography>
                  <Typography variant="caption">
                    Upload your file (CSV, Excel, PNG, max 5 MB, recommended
                    size 1200x800 pixels).
                  </Typography>
                </Box>
              </label>
            </Box>
          </div>
        </form>

        <Button onClick={handleCancel} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          sx={{ ml: 4 }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default Form;
