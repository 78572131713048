import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DocumentItem from "../../../../Components/Masters/DocumentItem";
import ViewDate from "../../../../Components/Masters/ViewDate";

function ViewData({
  title,
  inputData,
  element,
  viewData,
  setViewData,
  handleEdit,
}) {
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if (element) {
      console.log(element);
      setFilteredData(element);
    }
  }, [element]);

  const renderQuantityBought = (value) => (
    <div>
      <span>{value.value} </span>
      <span>{value.unit}</span>
    </div>
  );

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: "410px",
        maxWidth: "100%",
        height: "100vh",
        justifyContent: "space-between",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton onClick={() => setViewData(false)} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{ p: 3, width: "100%", backgroundColor: "#fff", overflow: "auto" }}
      >
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Purchased Goods & Services Details
        </Typography>

        <Grid container spacing={2}>
          <ViewDate data={element}/>
          {inputData.map((input) => {
            const value = filteredData?.[input.fieldName];

            if (value === undefined || value === null) return null;

            if (input.fieldName === "documents") return null;
            console.log(input.fieldName,value)

            let displayValue;
            // if (input.fieldName === "quantityBought") {
            //   displayValue = renderQuantityBought(value);
            // } else {
            //   displayValue =input.fieldName==="quantityBought" ?
            //   value.value + " " + value.unit:
            //     typeof value === "object" && value !== null
                   displayValue=value.vendorLegalName || value.value
                  || value || "-";
            // }

            return (
              <Grid item xs={12} sm={12} key={input.fieldName}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {input.title}
                </Typography>
                <div>
                  {/* {Array.isArray(input.options)
                    ? input.options.includes(displayValue)
                      ? displayValue
                      : "-" */}
                    { displayValue}
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Documents
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{ width: "100%" }}
          >
            {/* Render Images First */}
            {element && element.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {element.documents
                  .filter((url) =>
                    ["png", "jpg", "jpeg"].includes(
                      url.split(".").pop().toLowerCase()
                    )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`image-${index}`} url={url} />
                  ))}
              </Box>
            )}

            {/* Render Other Documents */}
            {element && element.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {element.documents
                  .filter(
                    (url) =>
                      !["png", "jpg", "jpeg"].includes(
                        url.split(".").pop().toLowerCase()
                      )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`file-${index}`} url={url} />
                  ))}
              </Box>
            )}
          </Box>
        </Box>

        <Button
          variant="contained"
          sx={{ mt: 2, backgroundColor: "#0a3f73", textTransform: "none" }}
          onClick={handleEdit}
        >
          Edit
        </Button>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={viewData}
      onClose={() => setViewData(false)}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      {list()}
    </Drawer>
  );
}

export default ViewData;
