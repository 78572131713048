import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DocumentItem from "../../../Components/Masters/DocumentItem";


function ViewLogisticsVendorMaster(props) {
  const [edit, setEdit] = useState(false);
  const [valueEdit, setValueEdit] = useState(false);
  const [addEquipment, setAddEquipment] = useState(false);
  useEffect(() => {
    console.log(props.selectedData,props.selectedData?.model,"selectedData")
  }, [props]);

  const handleDownload = (url) => {
    const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/"); // Force download as an attachment
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };


  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{width:400,
        // maxWidth:"100%",
        height: '100vh',
        display:"flex",justifyContent:"space-btween",
          backgroundColor:"transparent"
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setView(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll"}}
      >
        <Typography
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
         Consumption by Facility Data
        </Typography>
        <Box>
          <Box display="flex" gap={10}>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                Date
              </Typography>

              <p>
                
                {new Date(props.selectedData?.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) ?? ""}
                {/* {new Date(props.selectedData?.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }) ?? ""} */}
              </p>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                Previous Reading(kwh)
              </Typography>
              <p> {props.selectedData?.previousMeterReading ?? ""}</p>
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap={5.5} >
          <Box style={{width:"42%"}}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
        Current Reading(kwh)
        </Typography>
        <p  style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
          {props.selectedData?.currentMeterReading ?? ""}
        </p>
        </Box>
        <Box style={{width:"42%"}}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1}}>
        Unit Of Consumption
        </Typography>
        <p  style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{props.selectedData?.unitOfElectricityConsumption==="" ? "-":props.selectedData?.unitOfElectricityConsumption}</p>
        </Box>
        </Box>
        <Box display="flex" gap={5.5} >
          <Box style={{width:"42%"}}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
        Equipment
        </Typography>
        <p  style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
          {props.selectedData?.equipment?.equipment ?? ""}
        </p>
        </Box>
        <Box style={{width:"42%"}}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1}}>
        Vendor Name
        </Typography>
        <p  style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{props.selectedData?.vendor?.VendorLegalFullName??""}</p>
        </Box>
        </Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
        Remarks
        </Typography>
        <p>{props.selectedData?.remarks ?? "-"}</p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
       Reson's For Deviation
        </Typography>
        <p>{props.selectedData?.reasonForDeviation ?? "-"}</p>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Documents
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{ width: "100%" }}
          >
            {/* Render Images First */}
            {props?.selectedData && props.selectedData?.documents?.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedData?.documents
                  .filter((url) =>
                    ["png", "jpg", "jpeg"].includes(
                      url.split(".").pop().toLowerCase()
                    )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`image-${index}`} url={url} />
                  ))}
              </Box>
            )}

            {/* Render Other Documents */}
            {props.selectedData && props.selectedData?.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedData.documents
                  .filter(
                    (url) =>
                      !["png", "jpg", "jpeg"].includes(
                        url.split(".").pop().toLowerCase()
                      )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`file-${index}`} url={url} />
                  ))}
              </Box>
            )}
          </Box>
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={() => props.selectedData?._id !== null && props.handleEdit(props.selectedData)}

            // onClick={props.selectedData?._id !== null && props.handleEdit(props.selectedData)}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.view}
        onClose={() => props.setView(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default ViewLogisticsVendorMaster;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
