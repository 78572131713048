import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../sidebar";
import { ProgressContext } from "../../ContextAPI";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import QuestionCard from "./QuestionCard";
import ActionButtons from "../MaterialTopics/ActionButtons";
import { companyId } from "../../../../utils/companyId";
import { api } from "../../../../utils/api";
import { BorderColor } from "@mui/icons-material";
import { textError } from "../../../../utils/validationMessage";

const FuturePlan = () => {
  const { setUpdate } = useContext(ProgressContext);
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);

  const questions = [
    {
      question:
        "Where do you envision your company in terms of sustainability practices in the next 5-10 years?",
      name: "sustainabilityVision",
    },
    {
      question:
        "Are there any specific areas or aspects of sustainability that your company plans to focus on in the future?",
      name: "focusAreas",
    },
    {
      question:
        "Is there any additional information you would like to provide regarding your company's approach to sustainability?",
      name: "additionalInfo",
    },
  ];

  const [formData, setFormData] = useState({
    sustainabilityVision: "",
    focusAreas: "",
    additionalInfo: "",
  });

  const [errors, setErrors] = useState({
    sustainabilityVision: "",
    focusAreas: "",
    additionalInfo: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/company/future-plans/${companyId}`
        );
        setFormData(response.data.futurePlans);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    setUpdate("DATA")
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear the error when the user types
    });
  };

  const validateForm = () => {
    const newErrors = {
      sustainabilityVision: "",
      focusAreas: "",
      additionalInfo: "",
    };

    let isValid = true;

    if (!formData.sustainabilityVision.trim()) {
      newErrors.sustainabilityVision = textError;
      isValid = false;
    }

    if (!formData.focusAreas.trim()) {
      newErrors.focusAreas = textError;
      isValid = false;
    }

    if (!formData.additionalInfo.trim()) {
      newErrors.additionalInfo = textError;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const Move = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/company/future-plans${isExist ? `/${companyId}` : ""}`,
        data: { ...formData, companyId: companyId },
      });
      navigate("/material-topics");
    } catch (error) {
      console.log("Error:", error);
      alert("There was an error submitting the data!", error);
    }
  };

  const Back = () => {
    navigate("/challenges-concerns");
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#EDEDED",
        height: "100vh",
        overflow: "hidden", // Ensure no content gets hidden by scrollbars
      }}
    >
      <Sidebar title="Future Plans" />
      <Box
        sx={{
          p: 2,
          mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            zIndex: 1,
            padding: "10px",
            borderRadius: "4px",
            alignSelf: "flex-start",
            ml: "5rem",
          }}
        >
          FUTURE PLANS
        </Typography>
        <Box sx={{ width: "80%" }}>
          {questions.map((item, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <QuestionCard
                question={item.question}
                name={item.name}
                value={formData[item.name]}
                onChange={handleChange}
                borderColor={errors[item.name] && "#D34040"}
              />
              {errors[item.name] && (
                <Typography variant="body2" color="#D34040" sx={{ mt: 1 }}>
                  {errors[item.name]}
                </Typography>
              )}
            </Box>
          ))}
          <ActionButtons Back={Back} Move={Move} />
        </Box>
      </Box>
    </Box>
  );
};

export default FuturePlan;
