// import React, { useState } from 'react';
// import { TextField, Button, Typography, MenuItem } from '@mui/material';

// const CO2EmissionEstimator = () => {
//   const [formData, setFormData] = useState({
//     vehicle: '',
//     fuel: '',
//     vehicleAge: '',
//     source: '',
//     destination: ''
//   });

//   const handleChange = (event) => {
//     setFormData({
//       ...formData,
//       [event.target.name]: event.target.value
//     });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log('Form data submitted:', formData);
//     // Add form submission logic here
//   };

//   return (
//     <div style={{ padding: '20px', width:"80%" }}>
//       <Typography variant="h5" fontWeight="bold" gutterBottom>
//         CO₂ Emission Estimator
//       </Typography>
//       <form onSubmit={handleSubmit}>
//         <TextField
//           select
//           placeholder="Vehicle"
//           name="vehicle"
//           required
//           fullWidth
//           margin="normal"
//           value={formData.vehicle}
//           onChange={handleChange}
//         >
//           <MenuItem value="Car">Car</MenuItem>
//           <MenuItem value="Truck">Truck</MenuItem>
//           <MenuItem value="Bus">Bus</MenuItem>
//           {/* Add more options as needed */}
//         </TextField>

//         <TextField
//           placeholder="Fuel"
//           name="fuel"
//           fullWidth
//           margin="normal"
//           value={formData.fuel}
//           onChange={handleChange}
//         />

//         <TextField
//           placeholder="Vehicle Age"
//           name="vehicleAge"
//           fullWidth
//           margin="normal"
//           value={formData.vehicleAge}
//           onChange={handleChange}
//         />

//         <TextField
//           placeholder="Source"
//           name="source"
//           required
//           fullWidth
//           margin="normal"
//           value={formData.source}
//           onChange={handleChange}
//         />

//         <TextField
//           placeholder="Destination"
//           name="destination"
//           required
//           fullWidth
//           margin="normal"
//           value={formData.destination}
//           onChange={handleChange}
//         />

//         <Button
//           type="submit"
//           variant="contained"
//           fullWidth
//           sx={{ mt: 2, bgcolor: '#1a3e72' }}
//         >
//           Estimate Emission
//         </Button>
//       </form>
//     </div>
//   );
// };

// export default CO2EmissionEstimator;



import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { styled } from "@mui/material/styles";

const CO2EmissionEstimator = () => {
  const [formData, setFormData] = useState({
    vehicle: '',
    fuel: '',
    vehicleAge: '',
    source: '',
    destination: ''
  });

  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch vehicle types from API
  useEffect(() => {
    const fetchVehicleTypes = async () => {
      setLoading(true);
      try {
        const response = await fetch('http://localhost:8081/master/scope1/getAllVehicle');
        const data = await response.json();
        if (data && data.data) {
          const types = data.data.map((vehicle) => vehicle.vehicleType);
          setVehicleTypes(types);
        }
      } catch (err) {
        setError('Failed to load vehicle types');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleTypes();
  }, []);

  const handleChange = (event) => {
    console.log('Dropdown value selected:', event.target.value); // Log selected value
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };



  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form data submitted:', formData);
    // Add form submission logic here
  };

  return (
    <div style={{ padding: '20px', width: '80%' }}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        CO₂ Emission Estimator
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* <FormControl fullWidth margin="normal" required>
          <InputLabel id="vehicle-label">Vehicle</InputLabel>
          <Select
            labelId="vehicle-label"
            name="vehicle"
            value={formData.vehicle} // The state value should control the dropdown selection
            onChange={handleChange}
            label="Vehicle"
            displayEmpty
            disabled={loading} // Disable dropdown during loading state
          >
            {loading ? (
              <MenuItem value="">Loading...</MenuItem>
            ) : error ? (
              <MenuItem value="">Error loading vehicles</MenuItem>
            ) : (
              <>
                <MenuItem value="" disabled>
                  Select a vehicle
                </MenuItem>
                {vehicleTypes.map((vehicleType, index) => (
                  <MenuItem key={index} value={vehicleType}>
                    {vehicleType}
                  </MenuItem>
                ))}
              </>
            )}
          </Select>
        </FormControl> */}

<Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
  Vehicle
</Typography>
<CustomTextField
  select
  variant="outlined"
  value={formData.vehicle === "" ? "Tap to Select" : formData.vehicle}
  fullWidth
  name="fuelType"
  onChange={handleChange}
  placeholder="Tap to Select"
//   InputLabelProps={{
//     style: { color: errors.fuelType ? "#D34040" : undefined },
//   }}
>
  {loading ? (
    <MenuItem value="">Loading...</MenuItem>
  ) : error ? (
    <MenuItem value="">Error loading vehicles</MenuItem>
  ) : (
    vehicleTypes.map((vehicleType, index) => (
      <MenuItem key={index} value={vehicleType}>
        {vehicleType}
      </MenuItem>
    ))
  )}
</CustomTextField>

        <TextField
          placeholder="Fuel"
          name="fuel"
          fullWidth
          margin="normal"
          value={formData.fuel}
          onChange={handleChange}
        />

        <TextField
          placeholder="Vehicle Age"
          name="vehicleAge"
          fullWidth
          margin="normal"
          value={formData.vehicleAge}
          onChange={handleChange}
        />

        <TextField
          placeholder="Source"
          name="source"
          required
          fullWidth
          margin="normal"
          value={formData.source}
          onChange={handleChange}
        />

        <TextField
          placeholder="Destination"
          name="destination"
          required
          fullWidth
          margin="normal"
          value={formData.destination}
          onChange={handleChange}
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 2, bgcolor: '#1a3e72' }}
        >
          Estimate Emission
        </Button>
      </form>
    </div>
  );
};

export default CO2EmissionEstimator;

const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
      height: "40px", // Adjust height as needed
    },
    "& .MuiSelect-select": {
      height: "56px", // Adjust height as needed
      display: "flex",
      alignItems: "center",
    },
  }));