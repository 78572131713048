import { Box, Typography, TextField, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

// Styled TextField
const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        height: "40px", // Set height of input field
    },
    "& .MuiSelect-select": {
        height: "40px", // Ensure select dropdown also has 40px height
        display: "flex",
        alignItems: "center",
    },
}));

const DateField = ({ title, name,purchaseDate,lastServiceDate, handleChange, placeholder, value, errors }) => {
    const today = dayjs();
    const formattedValue = value ? dayjs(value) : null;
console.log(name,purchaseDate)
    return (
        <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
                {title}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box>
                    <DatePicker
                        sx={{ width: "100%" }}
                        name={name}
                        value={formattedValue}
                        onChange={(newValue) => {
                            if (newValue && newValue.isAfter(today)) {
                                handleChange({ name, value: null }); // Reset value or handle as needed
                            } else {
                                handleChange({ name, value: newValue ? newValue.format("YYYY-MM-DD") : null });
                            }
                        }}
                        format="DD/MM/YYYY"
                        maxDate={lastServiceDate?dayjs(lastServiceDate):today}
                        minDate={name==="lastServiceDate" ? dayjs(purchaseDate) :null}
                        renderInput={(params) => (
                            <CustomTextField
                                fullWidth
                                {...params}
                                error={!!errors}
                                InputLabelProps={{
                                    style: { color: errors ? "#D34040" : undefined },
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: placeholder || 'DD/MM/YYYY',
                                }}
                            />
                        )}
                    />
                </Box>
            </LocalizationProvider>
            {errors && (
                <Typography variant="body2" color="#D34040">
                    {errors}
                </Typography>
            )}
        </Box>
    );
};

export default DateField;
