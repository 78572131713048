import { MenuItem, Select } from "@mui/material";
import React from "react";
// import styles from "../../styles/common/banner.module.css";

const Cards = ({ data, title, onFilterChange }) => {
  const handleSelect = (title, value) => {
    if (onFilterChange) onFilterChange(value);
  };
  return (
    <>
      <p
        style={{
          fontWeight: "700",
          fontSize: "22px",
          color: "#001632",
          fontFamily: "Inter",
          textDecoration: "underline",
          marginTop: "1rem",
          marginBottom: "1.5rem",
        }}
      >
        {title}
      </p>
      <div
        style={{
          display: "flex",
          // flexWrap: "wrap",
          gap: "1rem",
          justifyContent: "space-between",
        }}
      >
        {data &&
          data.map((item, idx) => {
            return (
              <>
                <div
                  key={idx}
                  style={{
                    backgroundColor: "#FCFCFC",
                    borderRadius: "12px",
                    padding: "1.25rem",
                    boxShadow: "rgba(0, 0, 0, 0.15)",
                    width: "32%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000000",
                        lineHeight: "24px",
                        marginBottom: "2rem",
                      }}
                    >
                      {item?.title}
                    </p>
                    {item?.filter ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "40%",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#000000",
                            marginBottom: "0.5rem",
                          }}
                        >
                          {item?.filterTitle}
                        </p>
                        <Select
                          size="small"
                          style={{ height: "30px", width: "100%" }}
                          onChange={(e) =>
                            handleSelect(item.title, e.target.value)
                          }
                        >
                          {item?.filterValues?.map((item, idx) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        </Select>
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: "-1rem" }}>
                    {Array.isArray(item?.description) ? (
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#000000",
                        }}
                      >
                        {item.description.join(" | ")}
                      </p>
                    ) : (
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#000000",
                        }}
                      >
                        {item?.description}
                      </p>
                    )}
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
};

export default Cards;
