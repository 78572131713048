import React from "react";
import Test from "./Test";
import DashboardLayout from "../../../../Layout/DashboardLayout";

const index = () => {
  return (
    <DashboardLayout>
      <Test />
    </DashboardLayout>
  );
};

export default index;
