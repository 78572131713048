import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
// import contries from "../Roles and Permissions/Country.json";
import contries from "../../../Roles and Permissions/Country.json"
import { api } from "../../../../../utils/api";
import { companyId } from "../../../../../utils/companyId";
import {textError,numberError,listError} from "../../../../../utils/validationMessage";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from '@mui/material/LinearProgress';
// import FileField from "../../../Components/Masters/FileField";
import FileField from "../../../../../Components/Masters/FileField";




function AddEquipment(props) {
  const [fileNames, setfileNames] = useState([]);
  const [alreadyEquipment, setAlreadyEquipment] = useState(false);
  const [Units,setUnit]=useState(["Ampere","Watt","KWH","Volts", "Resistance", "Ohm"]);
  const [errors, setErrors] = useState({});
  const [equipmentList, setEquipmentList] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
const initialState ={
    company: companyId,
    // facility:"Tap to Select",
    equipment: "",
    brand: "",
    model:"",
    documents: [],
    ratedConsumption: "",
    unit: "Tap to Select",
    remark:""
  };
  const resetForm = () => {
    console.log("_idddddd",props.selectedEquipment?._id)
    setFromValue(props.selectedEquipment?._id === undefined?initialState:props.selectedEquipment);
    setErrors({});
  };

  const [fromValue, setFromValue] = useState(initialState);
  console.log("propsEquipment",props)
 
  useEffect(() => {
    const fetchEquipment = async () => {
      try {
        const response = await axios.get(`${api}/master/Electricity/getEquipment`);
        setEquipmentList(response.data.data || []);
      } catch (error) {
        console.error("Error fetching equipment list:", error);
      }
    };
    fetchEquipment();
  }, []);

  useEffect(() => {
    setFromValue({
      company: companyId,
    // facility:props.selectedEquipment?.facility ??"Tap to Select",
    equipment:props.selectedEquipment?.equipment ?? "",
    brand: props.selectedEquipment?.brand ??"",
    model: props.selectedEquipment?.model ??"",
    // documents: props.selectedEquipment?.documents ??[],
    ratedConsumption:props.selectedEquipment?.ratedConsumption ?? "",
    unit: props.selectedEquipment?.unit ??"Tap to Select",
    // remark:props.selectedEquipment?.remark ??""
    remark: props.selectedEquipment?.remark === "" ? "-" : props.selectedEquipment?.remark || ""
    });
    setUploadedFiles(props.selectedEquipment?.documents?? []);
    setAlreadyEquipment(props.selectedEquipment === null ? false : true);
  }, [props.selectedEquipment]);

 
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("nam===e", name, value);
    setFromValue({
      ...fromValue,
      [name]: value,
    });
    validateField(name, value);
   
  };
  
  
  const validateField = (name, value) => {
    const newErrors = { ...errors };
    switch (name) {
      case "equipment":
        // if (!value.trim()) {
        //   newErrors.equipment = textError;
        // } else {
        //   delete newErrors.equipment; // Clear error if valid
        // }
        if (!value || value.trim() === "") {
          newErrors.equipment = "Equipment is required.";
        } else {
          const isEditing = props.selectedEquipment?._id;
          const vendorExists = equipmentList.some(
            (vendor) =>
              vendor.equipment.toLowerCase() === value.toLowerCase() &&
              (!isEditing || vendor._id !== props.selectedEquipment._id) // Exclude current equipment in check
          );
          if (vendorExists) {
            newErrors.equipment = "Equipment already exists";
          } else {
            delete newErrors.equipment; // Clear error if valid
          }
        }
        break;
      case "brand":
        if (!value.trim()) {
          newErrors.brand = textError;
        } else {
          delete newErrors.brand; // Clear error if valid
        }
        break;
      case "model":
        if (!value.trim()) {
          newErrors.model = textError;
        } else {
          delete newErrors.model; // Clear error if valid
        }
        break;
      case "ratedConsumption":
        if (!value || isNaN(value) || value <= 0 || !/^\d*\.?\d*$/.test(value)) {
          newErrors.ratedConsumption = numberError;
        } else {
          delete newErrors.ratedConsumption; // Clear error if valid
        }
        break;
      case "unit":
        if (!value || value.trim() === "Tap to Select") {
          newErrors.unit = "Please Select Unit";
        } else {
          delete newErrors.unit; // Clear error if valid
        }
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = {};
    const { equipment, brand, model, ratedConsumption, documents, unit } = fromValue;
  
    // if (!equipment || equipment.trim() === "") {
    //   newErrors.equipment = textError;
    // }

    if (!equipment || equipment.trim() === "") {
      newErrors.equipment = "Equipment is required.";
    } else {
      const isEditing = props.selectedEquipment?._id;
      const vendorExists = equipmentList.some(
        (vendor) =>
          vendor.equipment.toLowerCase() === equipment.toLowerCase() &&
          (!isEditing || vendor._id !== props.selectedEquipment._id) // Exclude current equipment in check
      );
      if (vendorExists) {
        newErrors.equipment = "Equipment already exists";
      }
    }
    if (!brand || brand.trim() === "") {
      newErrors.brand = textError;
    }
    if (!model || model.trim() === "") {
      newErrors.model = textError;
    }
    if (!ratedConsumption || isNaN(ratedConsumption) || ratedConsumption === "" || ratedConsumption <= 0 || !/^\d*\.?\d*$/.test(ratedConsumption)) {
      newErrors.ratedConsumption = numberError;
    }
    

    if (!unit || unit.trim() === "" || unit.trim() === "Tap to Select") {
      newErrors.unit = "Please Select Unit";
    }
  
    setErrors(newErrors); // Update state with any errors found
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleModule =(e)=>{
    const {name,value}=e.target
  console.log(e.key,value,"forModule")
    setFromValue({
      ...fromValue,
      [name]:value
    })
  }


 



  const Save = () => {
    const isValid = validateForm(); // Validate the form
    if (!isValid) return; // Stop if there are validation errors
  
    const dataToSend = {
      ...fromValue,
      documents:uploadedFiles, // This should include all uploaded files
    };
    axios({
      method: alreadyEquipment ? "put" : "post",
      url: alreadyEquipment
        ? `${api}/master/Electricity/updateEquipment`
        : `${api}/master/Electricity/creatEquipment`,
      data: dataToSend,
      params: alreadyEquipment ? { Id: props.selectedEquipment?._id } : null,
    })
      .then((res) => {
        // Handle successful response
        props.setAddEquipment(false);
        props.fetchHeatEquipment();
        resetForm();
        setUploadedFiles([])
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
      })
      .catch((err) => {
        console.error("Request failed:", err);
        if (err.response && err.response.data.message) {
          // Check for specific backend validation errors
          const errorMessage = err.response.data.message;
  
          // Extract the equipment name from the error message
          const equipmentNameMatch = errorMessage.match(/([A-Za-z\s]+)/);
          const equipmentName = equipmentNameMatch ? equipmentNameMatch[0].trim() : "this equipment";
  
          // Set a dynamic error message based on the equipment name
          if (errorMessage.includes("Already exists.")) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              equipment: `${equipmentName} ` // Show specific equipment error
            }));
          } else {
            // Handle other backend validation errors
            const backendErrors = err.response.data.errors;
            const newErrors = {};
            backendErrors.forEach((error) => {
              newErrors[error.param] = error.msg; // Set error messages to respective fields
            });
            setErrors((prevErrors) => ({
              ...prevErrors,
              ...newErrors, // Merge with previous errors
            }));
          }
        }
      });
  };
  
  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{width:400,
        // maxWidth:"100%",
        height: '100vh',
        display:"flex",justifyContent:"space-btween",
          backgroundColor:"transparent"
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => {props.setAddEquipment(false);resetForm();}}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll"}}>
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          {props.selectedEquipment?._id === undefined?
          " Add Equipment"
             : "Edit Equipment"}
        </Typography>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Equipment Name
        </Typography>
        <CustomTextField
          // select
          variant="outlined"
          value={fromValue.equipment}
          onChange={handleChange}
          fullWidth
          name="equipment"
          placeholder="Name"
          error={!!errors.equipment} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.equipment ? "#D34040" : undefined },
          }}
        ></CustomTextField>
         {errors.equipment && (
          <Typography variant="body2" color="#D34040">
            {errors.equipment}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Brand
        </Typography>
        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.brand}
          onChange={handleChange}
          fullWidth
          name="brand"
          placeholder="Name"
          error={!!errors.brand} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.brand ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.brand && (
          <Typography variant="body2" color="#D34040">
            {errors.brand}
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Model
        </Typography>
        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.model}
          onChange={handleChange}
          fullWidth
          name="model"
          placeholder="Name"
          error={!!errors.model} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.model ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.model && (
          <Typography variant="body2" color="#D34040">
            {errors.model}
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Rated Electricity Consumption/Hr
        </Typography>
        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.ratedConsumption}
          onChange={handleChange}
          fullWidth
          name="ratedConsumption"
          placeholder="1200"
          error={!!errors.ratedConsumption} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.ratedConsumption ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.ratedConsumption  && (
          <Typography variant="body2" color="#D34040">
            {errors.ratedConsumption}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Unit
        </Typography>
       
        <CustomTextField
           select
          variant="outlined"
          value={fromValue.unit}
          onChange={handleChange}
          fullWidth
          name="unit"
          placeholder="Unit"
          error={!!errors.unit} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.unit ? "#D34040" : undefined },
          }}
        >
           <MenuItem value={fromValue.unit==="" ? "Tap to Select" :fromValue.unit} disabled>
      {fromValue.unit==="" ? "Tap to Select" :fromValue.unit} 
  </MenuItem>
  {Units.map((item,index)=>{
      return <MenuItem key={index} value={item}>
       {item}
       </MenuItem> 
      })}
        </CustomTextField>
        {errors.unit && (
          <Typography variant="body2" color="#D34040">
            {errors.unit}
          </Typography>
        )}
         <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Remark
        </Typography>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          name="remark"
          value={fromValue.remark}
          onChange={handleChange}
          placeholder="Brief"
        />
        <FileField
          title={"Upload Documents"}
          name={"documents"}
          errors={errors["documents"]}
          fileUrls={uploadedFiles}
          progress={uploadProgress["documents"] || []}
          {...{setUploadedFiles,setUploadProgress,setErrors}}
          // onDelete={handleDeleteFile}
        />
        {errors.documents ? (
          <Typography variant="body2" color="#D34040">
            {/* {errors.documents} */}
          </Typography>
        ):""
        // fileNames.map((item)=>item).join(',')
        }

        {/* <CustomTextField
          //  select
      variant="outlined"
        value={fromValue.outsourceFacility}
      onChange={handleChange}
      fullWidth
      name="outsourceFacility"
      placeholder="Outsource"
    >
    </CustomTextField> */}
        {/* <Box> */}
        <Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0a3f73",
            textTransform: "none",
            marginTop: "10%",
          }}
          onClick={Save}
        >
          Submit
        </Button>
        </Box>
       
        {/* </Box> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addEquipment}
        onClose={() =>{ props.setAddEquipment(false); resetForm();}}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddEquipment;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
