import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Toolbar,
  Typography,
  Box,
  PaginationItem,
} from "@mui/material";
import axios from "axios";
import { api } from "../../../../utils/api";
import { styled } from "@mui/material/styles";
// import VehicleFilter from "../../Roles and Permissions/Filters/VehicleFilter";
import DateRangePickerComponent from "../../Roles and Permissions/Filters/Date";
import PaginationBox from "../../../../Components/Masters/Pagination";
import DeleteDialog from "../../../../Components/Masters/DeleteDialog";
import ColumnHeadings from "../../../../Components/Masters/ColumnHeadings";
import MenuActions from "../../../../Components/Masters/MenuActions";
import TableContent from "../../../../Components/Masters/TableContent";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import MessagePopup from "../../../../Components/Masters/MessagePopup";
import TableHeading from "../../../../Components/Masters/TableHeading";
import MenuIcon from "../../../../Components/Masters/MenuIcon";
import MultiSelect from "../../Roles and Permissions/Filters/MultiSelect";
import { companyId } from "../../../../utils/companyId";
import AddPurchasedGoods from "./AddPurchasedGoods";
import ViewPurchasedGoods from "./ViewPurchasedGoods";
import NoDataFound from "../../../../Components/Masters/NoDataFound";

const PurchasedGoods = () => {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addData, setAddData] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    Country: false,
  });
  const [viewData, setViewData] = useState(false);
  const [element, setElement] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [errors, setErrors] = useState({
    Date: false,
    Country: false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    country: [],
  });
  const filters = ["Date", "Country"];

  const inputData = [
    {
      fieldName: "vendorLegalName",
      title: "Vendor Legal Name",
      placeholder: "Enter Legal Name",
    //   url: `${api}/vendor/getAll`, // Assuming there's an API to get all vendors
    },
    {
        fieldName:"country",
        title:"Country",
        placeholder:"Tap to Select",
        type:"select"
    },
    {
      fieldName: "goodsAndServicesName",
      title: "Name of Goods and Services",
      placeholder: "Enter Name",
    },
    {
      fieldName: "gstNumber",
      title: "GST Number",
      placeholder: "Enter 15 digit GST Number",
    },
    {
      fieldName: "address",
      title: "Address",
      placeholder: "Enter Address",
    },
    {
      fieldName: "personInChargeName",
      title: "Name of Person in Charge",
      placeholder: "Enter Incharge Name",
    },
    // {
    //     fieldName: "contactNumber.countryCode",
    //     // title: "Contact Number",
    //     // placeholder: "Type here",
    //   },
    {
      fieldName: "contactNumber",
      title: "Contact Number",
      placeholder: "Enter Mobile No ",
    },
    {
      fieldName: "email",
      title: "Email ID",
      placeholder: "Enter Email",
    }
  ];
  
  

  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(`${api}/master/purchasedGoods`);
      const data = response.data.data;
      console.log(data);

      const uniqueCountries = [
        ...new Set(
          data.map((el) => el.country).filter(Boolean) // Filter out undefined values
        ),
      ];
     
      console.log(uniqueCountries)
     
      return {
        country: uniqueCountries,
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };

  // fetching Vehicle data
  const fetchData = async (filters = isFiltersApplied?filtersToApply:{}) => {
    console.log(filters)
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      const response = await axios.get(
        `${api}/master/purchasedGoods?${queryParams}`
      );

      setData(response.data.data); // Update the user list
      console.log(response.data);
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortConfig]);

  const handleMenuClick = (event, element) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(element);
    setElement(element);
    console.log(element)
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedData(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddData(true);
    setViewData(false);
  };

  const handleView = () => {
    handleMenuClose();
    setViewData(true);
    setAddData(false);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = async () => {
    // console.log("indexhandleDeleteAggree", vehicle, vehicle._id);
    try {
      console.log(element._id)
      const res = await axios.delete(
        `${api}/master/purchasedGoods/${element._id}`
      );
      setDeleteDialog(false);
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
    } catch (err) {
      console.log(err);
      setsnackOpen({
        open: true,
        message: err.data?.response.message ?? "network Error",
        severity: "error",
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filtersToApply = {
    // company: companyId,
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    country: selectedFields?.country.join(",") || [], 
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: false,
      Country: false,
    };

    console.log(selectedFilter, "hi");

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date
      if (!startDate && !endDate) {
        newErrors.Date = "Please select a date range";
        isValid = false;
      }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }

    if (selectedFilter === "Country" && selectedFields.country.length === 0) {
      newErrors.Country = "Please select Country";
      isValid = false;
    }

    
    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    if (!validateFilters()) return;
    setPage(1)
    console.log(selectedFields)
    fetchData(filtersToApply);
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  const handleResetFilters = async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
      country: [],
    });

    // Call fetchData without any filters
    await fetchData({});
    setErrors({});
    setAppliedFilters({});
    setIsFiltersApplied(false);
    setDrawerFilterOpen(false);
  };

  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate ? true : false;
    const isCountryApplied = selectedFields.country?.length > 0 ? true : false;
   
    setIsFiltersApplied(
      isDateApplied || isCountryApplied );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      Country: isCountryApplied,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    setErrors({ Date: false, Country: false });
  };

  const columnHeadings = [
    {
        fieldName:"createdAt",
        title:"Date"
    },
    {
        fieldName: "vendorLegalName",
        title: "Vendor Legal Name",
      },
      {
          fieldName:"country",
          title:"Country",
      },
      {
        fieldName: "gstNumber",
        title: "GST Number",
      },
      {
        fieldName: "goodsAndServicesName",
        title: "Name of Goods and Services",
      },   
      
  ];

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/master/purchasedGoods`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
      case "Country":
        return (
          <MultiSelect
            title="Country"
            url={`${api}/master/purchasedGoods`}
            selectedField={selectedFields.country}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors.Country}
            setErrors={setErrors}
            field="country"
            data={uniqueValues.country}
          />
        );
      
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/master/purchasedGoods`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              setErrors,
              errors: errors.Date,
            }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <MessagePopup {...{ snackOpen, setsnackOpen }} />{" "}
      {/*Message popup for success or failure */}
      {/* View log, Edit, delete menus */}
      <AddPurchasedGoods
        id={element ? element._id : ""}
        addData={addData}
        setAddData={setAddData}
        data={data}
        element={element}
        addUrl={`${api}/master/purchasedGoods`}
        updateUrl={`${api}/master/purchasedGoods`}
        // details={vehicle}
        fetchData={fetchData}
        setsnackOpen={setsnackOpen}
        inputData={inputData}
      />
      <ViewPurchasedGoods
        id={element ? element._id : ""}
        data={element}
        viewData={viewData}
        setViewData={setViewData}
        inputData={inputData}
        handleEdit={handleEdit}
      />
      {deleteDialog && (
        <DeleteDialog
          {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
        />
      )}
      {/* Filter Drawer */}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setElement}
          setAddData={setAddData}
          {...{
            heading: "Purchased Goods and Services",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied,
          }}
        />
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
        <Box>
          <TableHeading heading={"Purchased Goods & Services Vendor"} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "12% 17% 17% 20% 25% 8%",
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            {columnHeadings.map((el) => (
              <ColumnHeadings
                key={el}
                {...{
                  title: el.title,
                  key: el.title,
                  handleSort,
                  field: el.fieldName,
                  sortConfig,
                }}
              />
            ))}
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {data.length === 0 ? (
           <NoDataFound/>
          ) : (data.map((item) => (
            <Box
              key={item._id}
              sx={{
                display: "grid",
                gridTemplateColumns: "12% 17% 17% 20% 25% 8%",
                borderBottom: "1px solid #ddd",
              }}
            >
              {columnHeadings.map((el) => (
                <TableContent
                  key={el.field}
                  {...{
                    fieldData:
                    typeof item[el.fieldName] === "object" &&
                        item[el.fieldName] !== null
                      ? item[el.fieldName]
                      : item[el.fieldName],dateField: el.fieldName === "createdAt",
                  }}
                />
              ))}
              {/* Menu Action Icon */}
              <MenuIcon {...{ handleMenuClick, field: item }} />
            </Box>
          )))}
        </Box>
      </TableContainer>
      {/* Pagination */}
      {totalPages>1  && <PaginationBox
        {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
      />}
      <MenuActions
        {...{
          id: element?._id,
          handleView,
          handleEdit,
          handleDelete,
          anchorEl,
          handleMenuClose,
        }}
      />
      {/*Add,Edit,Delete Icons & function calls */}
    </Box>
  );
};

export default PurchasedGoods;
