import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  Pagination,
  PaginationItem,
  Menu,
  MenuItem,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styled from "@emotion/styled";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import NoDataFound from "../../Components/Masters/NoDataFound";

const ViewTable = ({
  headers,
  data,
  onSort,
  sortConfig,
  page,
  count,
  onPageChange,
  onView,
  onEdit,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ width: "100%", borderRadius: "8px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={header.key}
                  sx={{
                    border: "1px solid #ddd",
                    padding: "10px",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Typography
                      sx={{
                        fontWeight: "600",
                        flexGrow: 1,
                        fontSize: "16px",
                      }}
                    >
                      {header.label}
                    </Typography>
                    {header.sortable && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <IconButton
                          size="small"
                          onClick={() => onSort(header.key, "asc")}
                        >
                          <ExpandLess
                            sx={{
                              fontSize: "20px",
                              margin: "-10px",
                              fontWeight: "700",
                              color:
                                sortConfig.key === header.key &&
                                sortConfig.direction === "asc"
                                  ? "blue"
                                  : "black",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => onSort(header.key, "desc")}
                        >
                          <ExpandMore
                            sx={{
                              fontSize: "20px",
                              fontWeight: "700",
                              margin: "-10px",
                              color:
                                sortConfig.key === header.key &&
                                sortConfig.direction === "desc"
                                  ? "blue"
                                  : "black",
                            }}
                          />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </TableCell>
              ))}
              <TableCell
                sx={{
                  textAlign: "center",
                  borderBottom: "1px solid #ddd",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "10px",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.length === 0 ? (
              <NoDataFound />
            ) : (
              data?.map((row) => (
                <TableRow key={row.id} size="small">
                  {headers.map((header) => (
                    <TableCell
                      key={header?.key}
                      size="small"
                      sx={{
                        textAlign: "left",
                        borderRight: "1px solid #ddd",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        padding: "6px",
                        fontSize: "14px",
                      }}
                    >
                      {header.key === "test"
                        ? `${row[header.key]?.value} ${row[header.key]?.unit}`
                        : row[header.key] || "N/A"}
                    </TableCell>
                  ))}
                  <TableCell sx={{ textAlign: "center", padding: "6px" }}>
                    <IconButton onClick={(event) => handleMenuOpen(event, row)}>
                      <MoreHorizIcon
                        sx={{ fontWeight: "bold", color: "black" }}
                      />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      PaperProps={{
                        sx: {
                          width: "120px",
                          border: "1px solid #ddd",
                          marginLeft: "-47px",
                          boxShadow: "none",
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleMenuClose();
                          onView(selectedRow);
                        }}
                        sx={{ fontSize: "14px" }}
                      >
                        <AssignmentIcon
                          sx={{ marginRight: "8px", fontSize: "large" }}
                        />{" "}
                        View Logs
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleMenuClose();
                          onEdit(selectedRow);
                        }}
                        sx={{ fontSize: "14px" }}
                      >
                        <EditIcon
                          sx={{ marginRight: "8px", fontSize: "large" }}
                        />{" "}
                        Edit
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleMenuClose();
                          onDelete(selectedRow);
                        }}
                        sx={{ fontSize: "14px" }}
                      >
                        <DeleteIcon
                          sx={{ marginRight: "8px", fontSize: "large" }}
                        />{" "}
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={count}
        page={page}
        onChange={handlePageChange}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "#fff",
          border: "1px solid #ddd",
          marginTop: "1rem",
          padding: "4px 16px",
          borderRadius: "4px",
        }}
        renderItem={(item) => (
          <StyledPaginationItem
            {...item}
            components={{
              previous: () => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mr: 1,
                  }}
                >
                  <ArrowBackIcon sx={{ mr: 1, color: "green" }} />
                  Previous
                </Box>
              ),
              next: () => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ml: 1,
                  }}
                >
                  Next
                  <ArrowForwardIcon sx={{ ml: 1, color: "green" }} />
                </Box>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default ViewTable;
