import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PaginationItem,
  Drawer,
  Alert,
  Snackbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
import AddEquipment from "./AddEquipment";
// import ViewFacility from "./ViewFacility";
// import DateRangePickerComponent from "../Roles and Permissions/Filters/Date";
import UserDateFilter from "../Roles and Permissions/Filters/UserFilters/UserDateFilter";
import { api } from "../../../utils/api";
import FilterSidebar from "../../../Components/Masters/Filter/FilterSidebar";
import ApplyButton from "../Roles and Permissions/Filters/ApplyButton";
import CountryFilter from "../Roles and Permissions/Filters/UserFilters/CountryFilter";
import BrandFilter from "../Roles and Permissions/Filters/BrandFilter";
import ResetButton from "../Roles and Permissions/Filters/ResetButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from '@mui/material/styles';
import ViewEquipment from "./ViewEquipment";
import NoDataFound from "../../../Components/Masters/NoDataFound";
import UnitFilter from "./HeatMaster/EquipmentMaster/Filters/unitFilter";
import RatedConsumptionFilter from "./HeatMaster/EquipmentMaster/Filters/ratedConsumptionFilter";
import ModelFilter from "./HeatMaster/EquipmentMaster/Filters/modelFilter";
import DeleteDialog from "../../../Components/Masters/DeleteDialog";


const Equipment = () => {
  
  const [equipments, setEquipments] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addEquipment, setAddEquipment] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    Country: false,
    Brand: false,
    Model: false,
    Unit: false,
    RatedConsumption: false,
  });
  const [ratedConsumptionRange, setRatedConsumptionRange] = useState({
    min: '',
    max: ''
  });
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [minConsumption, setMinConsumption] = useState('');
  const [maxConsumption, setMaxConsumption] = useState('');
  const [viewEquipment, setViewEquipment] = useState(false);
  const [dataView, setdataView] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries
  const [selectedEqupments, setSelectedEquipments] = useState([]); 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen,setsnackOpen]= useState({open:false,message:"",severity:""}); 
  const[errors, setErrors] = useState({
    Date: false,
    Brand: false,
    Country: false,
  });

  const filters = ["Date",
    // "Country"
    ,"Brand",
  "Model", "Unit", "Rated Consumption"
  ];

  const facilityData = [
    { header: "Date", field: "createdAt" },
    // { header: "Facility Name", field: "facilityName" },
    { header: "Brand", field: "brand" },
    // { header: "Outsource Facility", field: "outsourceFacility" },
    // { header: "Country", field: "country" },
    // { header: "Action" },
  ];
  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          // <DateRangePickerComponent
          //   url={`${api}/master/Steam/Equipement`}
          //   {...{ startDate, endDate, setStartDate, setEndDate,errors:errors.Date }}
          // />
          <UserDateFilter
          // setDrawerFilterOpen={setDrawerFilterOpen}
          // setFilteredRoles={setFilteredRoles}
          // errors={errors.Date}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          errors={errors.Date}
        />
        );
      case "Brand":
        return (
          <BrandFilter
            url={`${api}/master/Steam/Equipement`}
            selectedEqupments={selectedEqupments}
            setSelectedEquipments={setSelectedEquipments}
            errors={errors.Brand}
          />
        ); 
        case "Model":
          return (
            <ModelFilter
              url={`${api}/master/Steam/Equipement`}
              selectedModels={selectedModels}
              setSelectedModels={setSelectedModels}
              errors={errors.Model}
            />
            
          ); 
          case "Unit":
              return (
                <UnitFilter
                  url={`${api}/master/Steam/Equipement`}
                  selectedUnits={selectedUnits}
                  setSelectedUnits={setSelectedUnits}
                  errors={errors.Unit}
                />
                
              ); 
              case "Rated Consumption":
                  return (
                  <RatedConsumptionFilter
                  minConsumption={minConsumption}
                  maxConsumption={maxConsumption}
                  setMinConsumption={setMinConsumption}
                  setMaxConsumption={setMaxConsumption}
                  errors={errors.RatedConsumption}
                  />            
                  ); 
      default:
        return (
          <UserDateFilter
            // setDrawerFilterOpen={setDrawerFilterOpen}
            // setFilteredRoles={setFilteredRoles}
            // errors={errors.Date}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            errors={errors.Date}
          />
        );
    }
  };
  const handleMenuClick = (event, equipment) => {
    setAnchorEl(event.currentTarget);
    setSelectedEquipment(equipment);
    setdataView(equipment);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedEquipment(null);
  };

  const handleEdit = () => {
    console.log("Edit equipment:", selectedEquipment);
    handleMenuClose();
    setAddEquipment(true);
    setViewEquipment(false);
  };

  const handleView = () => {
    console.log("View equipment:", selectedEquipment);
    handleMenuClose();
    setViewEquipment(true);
  };

  const handleDelete = () => {
    console.log("Delete:", selectedEquipment);
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = () => {
    console.log("indexhandleDeleteAggree", dataView, dataView._id);
    axios.delete(
        `${api}/master/Steam/DeleteEquipment`,{
       
          params: {
            Id: dataView._id, // Pass as query parameter
          },
          data:{deactivated: true} ,
        
  })
      .then((res) => {
        console.log(res, "res++");
        setDeleteDialog(false);
        setsnackOpen({open:true,message:res.data.message,severity:"success"})
        setEquipments(
          equipments.filter((item) => item._id !== res.data.data._id)
        );
        //   setAnchorEl(null);

        //   setSelectedUserIndex(null);
      })
      .catch((err) => {
        console.log(err,"errOfEquipment");
        setsnackOpen({open:true,message:err.data?.response.message??"network Error",severity:"error"})
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  
// fetching  data
  const fetchHeatEquipment = async (filters = filtersToApply) => {
    try {
      console.log(sortConfig);
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      console.log("Query Params sent to API:", queryParams, filters); // Debugging line
      const response = await axios.get(
        `${api}/master/Steam/Equipement?${queryParams}`
      );

      console.log("Fetched data after filtering:", response.data); // Debugging line
      setEquipments(response.data.data); // Update the user list
      setTotalPages(response.data.totalPages); // Update total pages for pagination
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchHeatEquipment();
  }, [page, rowsPerPage, sortConfig]);

  // default filters to apply
  const filtersToApply = {
    // country: selectedCountries.join(","),
     brand: selectedEqupments.join(","),
     model: selectedModels.join(","),
     unit: selectedUnits.join(","),
     minConsumption: minConsumption || "",  // ensure rated consumption is part of filters
     maxConsumption: maxConsumption || "",
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
  };


// function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = { Date: "", Facility: "", Country: "" };
    if (selectedFilter === "Date") 
    {
      const today = new Date(); // Get the current date
      
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }
    
      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }
      if (selectedFilter === "Rated Consumption") {
        const min = parseFloat(minConsumption);
        const max = parseFloat(maxConsumption);
    
        if (!minConsumption || !maxConsumption) {
            newErrors.RatedConsumption = "Please provide both min and max values";
            isValid = false;
          }
        
    
        if (minConsumption && isNaN(min)) {
          newErrors.RatedConsumption = "Min consumption must be a valid number";
          isValid = false;
        }
    
        if (maxConsumption && isNaN(max)) {
          newErrors.RatedConsumption = "Max consumption must be a valid number";
          isValid = false;
        }
    
        if (!isNaN(min) && !isNaN(max) && min > max) {
          newErrors.RatedConsumption = "Min consumption cannot be greater than max consumption";
          isValid = false;
        }
      }
      if (selectedFilter === "Model" && selectedModels.length===0) {
        newErrors.Model = "Please select Model";
        console.log("Model")
       isValid = false;
    }
    
    if (selectedFilter === "Unit" && selectedUnits.length===0) {
        newErrors.Unit = "Please select Unit";
        console.log("Unit")
       isValid = false;
    }
      // Ensure that the startDate is less than or equal to the endDate
      // if (startDate && endDate 
      //   && startDate >= endDate
      // ) {
      //   newErrors.Date = "End date must be greater than Start date";
      //   isValid = false;
      // }
    } 

    if (selectedFilter === "Brand" && selectedEqupments.length===0) {
       newErrors.Brand = "Please select Brand";
       console.log("Brand")
      isValid = false;
}
    // if (selectedFilter === "Country" && selectedCountries.length===0) {
    //     newErrors.Country = "Please select Country";
    //   isValid = false;
    // }

    console.log(newErrors)
  
  setErrors(newErrors)
    return isValid;
  }


  // Applying selected filters
  const handleApplyFilters = () => {
    if(!validateFilters())
      return;
    const consumptionFilter = {
      minConsumption: minConsumption || "",
      maxConsumption: maxConsumption || "",
    };
    setPage(1);
    fetchHeatEquipment({ ...filtersToApply, // `qAll the previously selected filters
      ...consumptionFilter});
    setDrawerFilterOpen(false);
    handleAppliedFilters();
  };

  // Applying reset filters
  const handleResetFilters = () => {
    console.log(selectedCountries, selectedEqupments, startDate, endDate);

    if (selectedFilter === "Date") {
      setStartDate(null);
      setEndDate(null);
      setErrors({...errors,Date:false});
      setAppliedFilters({ ...appliedFilters, Date: false });
    }
    if (selectedFilter === "Country") {
      setSelectedCountries([]);
      setErrors({...errors,Country:false});
      setAppliedFilters({ ...appliedFilters, Country: false });
    }
    if (selectedFilter === "Brand") {
      setSelectedEquipments([]);
      setErrors({...errors,brand:false});
      setAppliedFilters({ ...appliedFilters, brand: false });
    }
    setSelectedCountries([]);
    setSelectedEquipments([]);
    setSelectedModels([]);
    setSelectedUnits([]);
    setRatedConsumptionRange([]);
    setAppliedFilters({});
    setIsFiltersApplied(false);
    fetchHeatEquipment({});
    setErrors({});
    setDrawerFilterOpen(false);
  
    
  };

  // handle the applied filters for styling
  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate ? true : false;
    const isModelApplied = selectedModels.length > 0;
    const isBrandApplied = selectedEqupments.length > 0;
    const isUnitApplied = selectedUnits.length > 0;
    // const isFunctionalityOfRoleApplied = functionalityOfRoleFilter.length > 0;
    const isRatedConsumptionFilter = minConsumption || maxConsumption ? true : false;
    setIsFiltersApplied(
      isDateApplied ||
       
        isBrandApplied ||
        isModelApplied ||
        isUnitApplied ||
       
        isRatedConsumptionFilter
    );

    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
     
      Brand: isBrandApplied,
      Model: isModelApplied,
      Unit: isUnitApplied, 
      RatedConsumptionFilter: isRatedConsumptionFilter,
    }));
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  // const getManualWidth = (header) => {
  //   switch (header) {
  //     case 'Date':
  //       return '12%';
  //     case 'FacilityName':
  //       return '22%';
  //     case 'Incharge':
  //       return '20%';
  //     case 'Outsource Facility':
  //       return '15%';
  //     case 'Country':
  //       return '21%';
  //     case 'Action':
  //       return '10%';
  //     default:
  //       return 'auto'; // default width if header doesn't match
  //   }
  // };

  const handleFilterDrawer=()=>{
    setDrawerFilterOpen(false);
    setErrors({Date:false,Role:false});
  }
  
  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        
      }}
    >
       <Snackbar open={snackOpen.open} autoHideDuration={3000}
         anchorOrigin={{ vertical:"top", horizontal:"center" }}
          onClose={()=>setsnackOpen({...snackOpen,open:false})}

         >
        <Alert
          // onClose={handleClose}
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackOpen.message}
          {/* Add user Successfully */}
        </Alert>
      </Snackbar>
      <AddEquipment
       addEquipment={addEquipment}
       setAddEquipment={setAddEquipment}
       fetchHeatEquipment={fetchHeatEquipment}
       setsnackOpen={setsnackOpen}
      //  addFacility={addFacility}
     
      selectedEquipment={dataView}
      />
      
      <ViewEquipment
        viewEquipment={viewEquipment}
        setViewEquipment={setViewEquipment}
        selectedEquipment={dataView}
        handleEdit={handleEdit}
      />
      {/* <AddFacility
        addFacility={addFacility}
        selectedEquipment={dataView}
        fetchHeatEquipment={fetchHeatEquipment}
        setsnackOpen={setsnackOpen}
      />
      <ViewFacility
        viewFacility={viewFacility}
        setViewFacility={setViewFacility}
        selectedEquipment={dataView}
      /> */}
      {deleteDialog && (
       <DeleteDialog
       {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
     />
      )}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <Drawer
            anchor="right"
            open={drawerFilterOpen}
            onClose={() => handleFilterDrawer()}
            sx={{
              backgroundColor: "transparent",
              "& .MuiDrawer-paper": {
                // This targets the paper component within the Drawer
                backgroundColor: "transparent", // Make paper transparent
                boxShadow: "none", // Optional: Remove any shadow
                width: "50%",
              },
            }}
          >
            <Box
              display="flex"
              gap={3}
              sx={{
                width: "100%",
                display: "flex",
                overflowX: "hidden",
                maxWidth: "100%",
                justifyContent: "space-btween",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  height: "3%",
                  padding: "13px",
                  margin: "30px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "80%",
                  width: "3%",
                }}
              >
                <IconButton
                  onClick={() => setDrawerFilterOpen(false)}
                  sx={{ color: "black" }}
                >
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
                {/* Sidebar for selecting the filter */}
                <FilterSidebar
                  {...{
                    setSelectedFilter,
                    selectedFilter,
                    filters,
                    appliedFilters,
                  }}
                />

                {/* Filter content area */}
                <Box
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#F9F9F9",
                    padding: "20px",
                  }}
                >
                  {renderSelectedFilter()}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: "3%",
                      position: "fixed",
                      bottom: "20px",
                      ml: 3,
                      mr: 3,
                    }}
                  >
                    <ApplyButton handleApply={handleApplyFilters} />
                    <ResetButton handleReset={handleResetFilters} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}{" "}
        <Grid
          container
          alignItems="center"
          sx={{ width: "80%", margin: "5% 0.5% 0% 22%" }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "8px 0px 20px 0px", textDecoration: "underline" }}
            >
              Steam Master
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              // endIcon={<FilterListIcon />}
              sx={{
                margin: "10px 10px -15px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                "&:hover .dot-icon": {
                  color: "#FFFFFF", // Change dot color on button hover
                },
                // display:"flex"
              }}
            >
              Filter
              <FilterListIcon />
              {(selectedCountries.length > 0 ||
                selectedEqupments.length > 0 ||
                startDate ||
                endDate) && (
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              )}
            </Button>

            <Button
              variant="contained"
              endIcon={<AddIcon />}
              sx={{
                margin: "10px -36px -15px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              onClick={() => {
                setAddEquipment(true);
                 setdataView(null);
              }}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Toolbar>

      

        <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      > 
      <Box>
      <Box sx={{ margin: '16px' }}>
   
         <Box 
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          textAlign: "left",
          // padding: "0px",
          }}
          >Steam Equipment Master</Box>
       </Box>
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "12% 20% 13% 12% 19% 15% 9%",
      borderBottom: "1px solid #ddd",
      borderTop: "1px solid #ddd",
    }}
  >
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd", display: "flex",  
         flexDirection: "row" }}>
      Date
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("createdAt", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "createdAt" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("createdAt","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "createdAt" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd",display: "flex",  
         flexDirection: "row" }}>
     Equipment Name
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("equipment", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "equipment" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("equipment","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "equipment" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd" ,display: "flex", flexDirection: "row"}}>
      Brand
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("brand", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "brand" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("brand","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "brand" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd",display: "flex",  
         flexDirection: "row" }}>
      Model
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("country", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "country" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("country","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "country" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px", marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd", display: "flex", flexDirection: "row" }}>
      Rated Steam <br/>
      Consumption/hr
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("ratedConsumption", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "ratedConsumption" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("ratedConsumption","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "ratedConsumption" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px", marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd", display: "flex", flexDirection: "row" }}>
     Unit of Steam
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("unit", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "unit" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("unit","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "unit" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px", marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd", display: "flex", flexDirection: "row" }}>
     Action
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      </Box>
    </Typography>
  </Box>
{console.log("equipment++",equipments)}
  { equipments.length === 0 ? (
            <NoDataFound />
          ) : (equipments.map((equipments) => (
    <Box
      key={equipments._id}
      sx={{
        display: "grid",
        gridTemplateColumns: "12% 20% 13% 12% 19% 15% 9%",
        borderBottom: "1px solid #ddd",
      }}
    >
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "center",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // margin: "7px 0px -7px 0px",
          fontSize: "14px",
          color: "black"
        }}
      >
        {/* {new Date(equipments.createdAt).toLocaleDateString()} */}
        {new Date(equipments.createdAt).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -7px 0px",
        }}
      >
        {equipments.equipment}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -7px 0px",
        }}
      >
        {equipments.brand}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -7px 0px",
        }}
      >
        {equipments.model}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -7px 0px",
        }}
      >
        {equipments.ratedConsumption}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -7px 0px",
        }}
      >
        {equipments.unit}
      </Typography>
      <Box
        sx={{
          padding: "1px",
          textAlign: "center",
        }}
      >
        <IconButton onClick={(e) => handleMenuClick(e, equipments)}>
          <MoreHorizIcon sx={{ fontWeight: "bold", color: "black" }} />
        </IconButton>
      </Box>
    </Box>
  )))}
</Box>




       
</TableContainer>



      {/* </TableContainer> */}

      {/* Pagination */}
      {totalPages>1&&    <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            backgroundColor: "#ffffff",
            width: "90%",
            margin: "2% -0.5% 0% 23%",
            borderRadius: "2%",
            padding: "3px",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            renderItem={(item) => (
              <StyledPaginationItem
              // <PaginationItem
                {...item}
                components={{
                  previous: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 27,
                      }}
                    >
                      <ArrowBackIcon sx={{ mr: 0.5, color: "green" }} />
                      Previous
                    </Box>
                  ),
                  next: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: 27,
                      }}
                    >
                      Next
                      <ArrowForwardIcon sx={{ ml: 0.5, color: "green" }} />
                    </Box>
                  ),
                }}
                sx={{
                  "& .MuiPaginationItem-root": {
                    mx: 1,
                  },
                  "& .MuiPagination-ul": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>}
      {/* Menu for Actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>
        
        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Equipment;
