import React from "react";
import { Box, Typography } from "@mui/material";
import InputField from "../../../../Components/Masters/InputField";

const Range = ({
  title,
  selectedRange, // Now this directly contains quantityConsumed (min and max)
  setSelectedRange,
  errors,
  placeholderMax,
  placeholderMin,
  setErrors,
}) => {
  // Function to handle min range change
  const handleMinRange = (e) => {
    const { value } = e.target;
    console.log(value, selectedRange);

    setErrors((prev) => ({
      ...prev,
      [title]: "", // Clear the error for min field
    }));

    setSelectedRange((prev)=>({
        ...prev,...selectedRange,min:value
    }));

    console.log(selectedRange)
  };

  // Function to handle max range change
  const handleMaxRange = (e) => {
    const { value } = e.target;
    console.log(value, "max");

    setErrors((prev) => ({
      ...prev,
      [title]: "", // Clear the error for max field
    }));

    setSelectedRange((prev) => ({
      ...prev,...selectedRange,max:value
    }));
  };

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        borderRadius: "8px",
        width: "320px",
        mt: 0,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          marginBottom: "30px",
        }}
      >
        {title}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box sx={{ width: "47%" }}>
          <InputField
            placeholder={placeholderMin}
            value={selectedRange.min || ""} // Use selectedRange.min
            handleChange={handleMinRange}
            // errors={errors}
            fieldName="min" // Adjust to match your error structure if needed
          />
        </Box>
        <Typography sx={{ fontWeight: "bold", mx: "5%" }}>to</Typography>
        <Box width="47%" sx={{ marginRight: "-20px" }}>
          <InputField
            placeholder={placeholderMax}
            value={selectedRange.max || ""} // Use selectedRange.max
            handleChange={handleMaxRange}
            // errors={errors}
            fieldName="max" // Adjust to match your error structure if needed
          />
          
        </Box>
      </Box>
      <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
    </Box>
  );
};

export default Range;
