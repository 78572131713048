import { Box, Typography, TextField, styled, InputAdornment } from "@mui/material";
import React from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

// Move CustomTextField outside the InputField to prevent re-creation on each render
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px",
  },
  "&.highlight-disabled .MuiInputBase-input.Mui-disabled": {
    // backgroundColor: "#f0f0f5", // Light background color to highlight
    color: "#090808" , // Slightly darker color for readability
    cursor: "not-allowed", // Cursor to indicate disabled
    opacity: 1, // Override default opacity for disabled fields
  },
  "& .MuiInputBase-input.Mui-disabled": {
    color: "#090808",
  },
}));

const InputField = ({
  title,
  adornmentText,
  handleChange,
  placeholder,
  value,
  errors,
  disabled,
  fieldName,
  showIcon,
}) => {
  return (
    <Box>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        {title}
      </Typography>
      <CustomTextField
        variant="outlined"
        value={value || ""}
        disabled={disabled}
        onChange={handleChange}
        fullWidth
        name={fieldName}
        placeholder={placeholder}
        error={!!errors}
        className={disabled ? "highlight-disabled" : ""} 
        InputLabelProps={{
          style: { color: errors ? "#D34040" : undefined },
        }}
        InputProps={{
          endAdornment: (showIcon && <TaskAltIcon sx={{ color: "green" }} />) ||
            (adornmentText && (
              <InputAdornment position="end">
                <Box sx={{ display: "flex", alignItems: "center",minWidth:"40px" }}>
                  <Box sx={{ height: "40px", width: "1px", bgcolor: "#6b6c6d", marginRight: "8px" }} />
                  {adornmentText}
                </Box>
              </InputAdornment>
            )),
        }}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            color: "#090808",
            cursor: "not-allowed",
            opacity: 1,
          },
          "& .MuiInputBase-input.Mui-disabled": {
            color: "#090808",
          },
        }}
      />
      {/* Check if errors is a string or a valid ReactNode */}
      {errors && typeof errors === 'string' && (
        <Typography variant="body2" color="#D34040">
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default InputField;
