import React, { useState, useEffect } from "react";
import {
 TableContainer,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PaginationItem,
  Drawer,
  Alert,
  Snackbar,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
import AddFacility from "./AddFacility";
import ViewFacility from "./ViewFacility";
import DateRangePickerComponent from "../Roles and Permissions/Filters/Date";
import { api } from "../../../utils/api";
import FilterSidebar from "../../../Components/Masters/Filter/FilterSidebar";
import ApplyButton from "../Roles and Permissions/Filters/ApplyButton";
import CountryFilter from "../Roles and Permissions/Filters/UserFilters/CountryFilter";
import FacilityFilter from "../Roles and Permissions/Filters/FacilityFilter";
import ResetButton from "../Roles and Permissions/Filters/ResetButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from '@mui/material/styles';
 
const Facility = () => {
  const [facilities, setFacilities] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addFacility, setAddFacility] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    Country: false,
    Facility: false,
  });
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [viewFacility, setViewFacility] = useState(false);
  const [dataView, setdataView] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  // const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries
  const [selectedFacilities, setSelectedFacilities] = useState([]); // Store selected facility name
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen,setsnackOpen]= useState({open:false,message:"",severity:""});
  const [currentPage, setCurrentPage] = useState(1);
  const[errors, setErrors] = useState({
    Date: false,
    Facility: false,
    Country: false,
  });
 
  const filters = ["Date", "Facility", "Country"];
 
  const facilityData = [
    { header: "Date", field: "createdAt" },
    { header: "Facility Name", field: "facilityName" },
    { header: "Incharge", field: "incharge" },
    { header: "Outsource Facility", field: "outsourceFacility" },
    { header: "Country", field: "country" },
    { header: "Action" },
  ];

  useEffect(() => {
    fetchFacilities(currentPage);
  }, [currentPage]);

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/master/getAllFacility`}
            {...{ startDate, endDate, setStartDate, setEndDate,setErrors,errors:errors.Date }}
          />
        );
      case "Facility":
        return (
          <FacilityFilter
            url={`${api}/master/getAllFacility`}
            selectedFacilities={selectedFacilities}
            setErrors={setErrors}
            setSelectedFacilities={setSelectedFacilities}
            errors={errors.Facility}
          />
        ); //
      case "Country":
        return (
          <CountryFilter
            url={`${api}/master/getAllFacility`}
            selectedCountries={selectedCountries}
            setErrors={setErrors}
            setSelectedCountries={setSelectedCountries}
            errors={errors.Country}
          />
        );
      default:
        return (
          <DateRangePickerComponent
          url={`${api}/master/getAllFacility`}
          {...{ startDate, endDate, setStartDate, setEndDate,setErrors,errors:errors.Date }}
       />
        );
    }
  };
  const handleMenuClick = (event, facility) => {
    setAnchorEl(event.currentTarget);
    setSelectedFacility(facility);
    setdataView(facility);
  };
 
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedFacility(null);
  };
 
  const handleEdit = () => {
    console.log("Edit facility:", selectedFacility);
    handleMenuClose();
    setAddFacility(true);
    setViewFacility(false);
  };
 
  const handleView = () => {
    console.log("View facility:", selectedFacility);
    handleMenuClose();
    setViewFacility(true);
    setAddFacility(false)
  };
 
  const handleDelete = () => {
    console.log("Delete facility:", selectedFacility);
    handleMenuClose();
    setDeleteDialog(true);
  };
 
 
  

  const handleDeleteAggree = () => {
    console.log("indexhandleDeleteAggree", dataView, dataView._id);
    axios
      .put(
        `${api}/master/deleteFacility`,
        { deactivated: true },
        {
          params: {
            Id: dataView._id,
          },
        }
      )
      .then(async (res) => {
        console.log(res, "res++");
        setDeleteDialog(false);
        setsnackOpen({ open: true, message: res.data.message, severity: "success" });

        // Remove the deleted facility from the current list
        const updatedFacilities = facilities.filter((item) => item._id !== res.data.data._id);
        setFacilities(updatedFacilities);

        // If we have less than `rowsPerPage`, fetch more data to fill it up
        if (updatedFacilities.length < rowsPerPage) {
          try {
            // Fetch the current page data again
            await fetchFacilities(currentPage);
          } catch (error) {
            console.error("Error fetching facilities:", error);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setsnackOpen({ open: true, message: err.response?.data?.message ?? "Network Error", severity: "error" });
      });
  };
  

  
  
  
  
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
 
// fetching facility data
  const fetchFacilities = async (filters = isFiltersApplied?filtersToApply:{}
  ) => {
  // const fetchFacilities = async (filters = {}) => {
 
    console.log(filters)
    try {
      console.log(sortConfig);
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      console.log("Query Params sent to API:", queryParams, filters); // Debugging line
      const response = await axios.get(
        `${api}/master/getAllFacility?${queryParams}`
      );
 
      console.log("Fetched data after filtering:", response.data); // Debugging line
      setFacilities(response.data.data); // Update the user list
      setTotalPages(response.data.totalPages); // Update total pages for pagination
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
 
  useEffect(() => {
    fetchFacilities();
  }, [page, rowsPerPage, sortConfig]);
 
  // default filters to apply
  const filtersToApply = {
    country: selectedCountries.join("|"), // Use | as separator
    facilityName: selectedFacilities.join(","),
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
};
 
 
// function to validate filters
  const validateFilters = () => {
    let isValid = true;
    console.log(selectedFilter,selectedCountries)
    const newErrors = { Date: "", Facility: "", Country: "" };
    if (selectedFilter === "Date")
    {
      const today = new Date(); // Get the current date
     // Check if both dates are selected
     if (!startDate && !endDate) {
      newErrors.Date = "Please select a date range";
      isValid = false;
    }
      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }
   
      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }
   
      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate > endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }
 
    if (selectedFilter === "Facility" && selectedFacilities.length===0) {
       newErrors.Facility = "Please select Facility";
       console.log("Facility")
      isValid = false;
}
    if (selectedFilter === "Country" && selectedCountries.length===0) {
        newErrors.Country = "Please select Country";
      isValid = false;
    }
 
  setErrors(newErrors)
    return isValid;
  }
 
 
  // Applying selected filters
  const handleApplyFilters = () => {
    if(!validateFilters())
      return;
    console.log("Applying filters...");
 
    // Reset to first page when applying filters
    setPage(1);
    fetchFacilities(filtersToApply);
    setDrawerFilterOpen(false);
    handleAppliedFilters();
  };
 
  // Applying reset filters
  const handleResetFilters = () => {
   
    setStartDate(null);
      setEndDate(null);
       setSelectedCountries([]);
       setSelectedFacilities([]);
       setIsFiltersApplied(false);
      setAppliedFilters({ Facility: false,Date:false,Country:false });
      setErrors({})
    fetchFacilities({
      country:  [] ,
      facilityName:[] ,
      startDate:  "" ,
      endDate:  "",
    });
    setDrawerFilterOpen(false);
 
  };
 
  // handle the applied filters for styling
  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate ? true : false;
    const isCountryApplied = selectedCountries.length > 0;
    const isFacilityApplied = selectedFacilities.length > 0;
    // const isFunctionalityOfRoleApplied = functionalityOfRoleFilter.length > 0;
   
    setIsFiltersApplied(
      isDateApplied ||
      isCountryApplied ||
      isFacilityApplied
      );
 
    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      Country: isCountryApplied,
      Facility: isFacilityApplied,
      // FunctionalityOfRole: isFunctionalityOfRoleApplied,
      }));
  };
 
 
  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };
 
  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));
 
 
  const handleFilterDrawer=()=>{
    setDrawerFilterOpen(false);
    setErrors({Date:false,Role:false});
  }
 
  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
       
      }}
    >
       <Snackbar open={snackOpen.open} autoHideDuration={3000}
         anchorOrigin={{ vertical:"top", horizontal:"center" }}
          onClose={()=>setsnackOpen({...snackOpen,open:false})}
 
         >
        <Alert
          // onClose={handleClose}
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackOpen.message}
          {/* Add user Successfully */}
        </Alert>
      </Snackbar>
 
      <AddFacility
        addFacility={addFacility}
        setAddFacility={setAddFacility}
        selectedFacility={dataView}
        fetchFacilities={fetchFacilities}
        setsnackOpen={setsnackOpen}
      />
      <ViewFacility
        viewFacility={viewFacility}
        setViewFacility={setViewFacility}
        selectedFacility={dataView}
        handleEdit={handleEdit}
      />
      {deleteDialog && (
        <Dialog open={deleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Do you really want to delete this entry?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAggree} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      )}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <Drawer
            anchor="right"
            open={drawerFilterOpen}
            onClose={() => handleFilterDrawer()}
            sx={{
              backgroundColor: "transparent",
              "& .MuiDrawer-paper": {
                // This targets the paper component within the Drawer
                backgroundColor: "transparent", // Make paper transparent
                boxShadow: "none", // Optional: Remove any shadow
                width: "50%",
              },
            }}
          >
            <Box
              display="flex"
              gap={3}
              sx={{
                width: "100%",
                display: "flex",
                overflowX: "hidden",
                maxWidth: "100%",
                justifyContent: "space-btween",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  height: "3%",
                  padding: "13px",
                  margin: "30px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "80%",
                  width: "3%",
                }}
              >
                <IconButton
                  onClick={() => setDrawerFilterOpen(false)}
                  sx={{ color: "black" }}
                >
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
                {/* Sidebar for selecting the filter */}
                <FilterSidebar
                  {...{
                    setSelectedFilter,
                    selectedFilter,
                    filters,
                    appliedFilters,
                  }}
                />
 
                {/* Filter content area */}
                 <Box
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#F9F9F9",
                    padding: "20px",
                    // overflow:"scroll",
                    height: "100vh",
                    paddingBottom: "20%",
                  }}
                >
                  {renderSelectedFilter()}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: "3%",
                      position: "fixed",
                      bottom: "20px",
                      ml: 3,
                      mr: 3,
                    }}
                  >
                    <ApplyButton handleApply={handleApplyFilters} />
                    <ResetButton handleReset={handleResetFilters} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}{" "}
        <Grid
          container
          alignItems="center"
          sx={{ width: "80%", margin: "5% 0.5% 0% 22%" }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "8px 0px 20px 0px", textDecoration: "underline" }}
            >
              Facility Master
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              // endIcon={<FilterListIcon />}
              sx={{
                margin: "10px 10px -15px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                "&:hover .dot-icon": {
                  color: "#FFFFFF", // Change dot color on button hover
                },
                // display:"flex"
              }}
            >
              Filter
              <FilterListIcon />
              {isFiltersApplied && (
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              )}
            </Button>
 
            <Button
              variant="contained"
              endIcon={<AddIcon />}
              sx={{
                margin: "10px -36px -15px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              onClick={() => {
                setAddFacility(true);
                setdataView(null);
              }}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
 
     
 
        <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
      <Box>
      <Box sx={{ margin: '16px' }}>
   
         <Box
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          textAlign: "left",
          // padding: "0px",
          }}>List of Facilities</Box>
       </Box>
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "12% 22% 20% 15% 21% 10%",
      borderBottom: "1px solid #ddd",
      borderTop: "1px solid #ddd",
    }}
  >
    <Typography sx={{ fontWeight: "bold", padding: "8px 19px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd", display: "flex",  
         flexDirection: "row" }}>
      Date
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("createdAt", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "createdAt" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("createdAt","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "createdAt" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd",display: "flex",  
         flexDirection: "row" }}>
      Facility Name
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("facilityName", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "facilityName" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("facilityName","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "facilityName" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd",display: "flex",  
         flexDirection: "row" }}>
      Incharge
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("incharge", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "incharge" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("incharge","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "incharge" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px",marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd" ,display: "flex", flexDirection: "row"}}>
      Outsource Facility
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("outsourceFacility", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "outsourceFacility" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("outsourceFacility","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "outsourceFacility" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px", marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd", display: "flex", flexDirection: "row" }}>
      Country
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("country", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "country" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("country","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "country" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Typography>
    <Typography sx={{ fontWeight: "bold", padding: "8px 12px", textAlign: "center" }}>
      Action
     
    </Typography>
  </Box>
 
  {facilities.map((facility) => (
    <Box
      key={facility._id}
      sx={{
        display: "grid",
        gridTemplateColumns: "12% 22% 20% 15% 21% 10%",
        borderBottom: "1px solid #ddd",
      }}
    >
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "center",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // margin: "7px 0px -1px 0px",
          fontSize: "14px",
          color: "black"
        }}
      >
        {/* {new Date(facility.createdAt).toLocaleDateString()} */}
        {new Date(facility.createdAt).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -1px 0px",
        }}
      >
        {facility.facilityName}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -1px 0px",
        }}
      >
        {facility.incharge}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -1px 0px",
        }}
      >
        {facility.outsourceFacility}
      </Typography>
      <Typography
        sx={{
          padding: " 9px 8px",
          textAlign: "left",
          borderRight: "1px solid #ddd",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        fontSize: "14px",
          color: "black",
        // margin: "7px 0px -1px 0px",
        }}
      >
        {facility.country}
      </Typography>
      <Box
        sx={{
          padding: "1px",
          textAlign: "center",
        }}
      >
        <IconButton onClick={(e) => handleMenuClick(e, facility)}>
          <MoreHorizIcon sx={{ fontWeight: "bold", color: "black" }} />
        </IconButton>
      </Box>
    </Box>
  ))}
</Box>
 
 
 
 
       
</TableContainer>
 
 
 
      {/* </TableContainer> */}
 
      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            backgroundColor: "#ffffff",
            width: "80%",
            margin: "2% -0.5% 0% 23%",
            borderRadius: "2%",
            padding: "3px",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            renderItem={(item) => (
              <StyledPaginationItem
              // <PaginationItem
                {...item}
                components={{
                  previous: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 27,
                      }}
                    >
                      <ArrowBackIcon sx={{ mr: 0.5, color: "green" }} />
                      Previous
                    </Box>
                  ),
                  next: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: 27,
                      }}
                    >
                      Next
                      <ArrowForwardIcon sx={{ ml: 0.5, color: "green" }} />
                    </Box>
                  ),
                }}
                sx={{
                  "& .MuiPaginationItem-root": {
                    mx: 1,
                  },
                  "& .MuiPagination-ul": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
      {/* Menu for Actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>
       
        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};
 
export default Facility;
 
 