import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import AddVendor from "./AddVendorMaster";

function ViewVendorMaster(props) {
  const [edit, setEdit] = useState(false);
  const [valueEdit, setValueEdit] = useState(false);
  const [addVendor, setAddVendor] = useState(false);
  useEffect(() => {
    console.log(props.selectedVendor,"selectedVendor")
  }, [props]);

  const handleDownload = (url) => {
    const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/"); // Force download as an attachment
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const Edit = () => {
    setAddVendor(true);
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{width:400,
        // maxWidth:"100%",
        height: '100vh',
        display:"flex",justifyContent:"space-btween",
          backgroundColor:"transparent"
      }}
    >
      <AddVendor
      addVendor={addVendor}
      setAddVendor={setAddVendor}
        selectedVendor={props.selectedVendor}
      />
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setViewVendor(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll"}}
      >
        <Typography
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          Electricity Vendor Master Details
        </Typography>
        <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mt: 1 }}
              >
                Date
              </Typography>

              <p>
                
                {new Date(props.selectedVendor?.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) ?? ""}
                {/* {new Date(props.selectedVendor?.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }) ?? ""} */}
              </p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
        Vendor Legal Full Name
        </Typography>
        <p>
          {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
          {props.selectedVendor?.VendorLegalFullName ?? ""}
        </p>
        

        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={props.handleEdit}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.viewVendor}
        onClose={() => props.setViewVendor(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default ViewVendorMaster;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
