import React, { useEffect, useState } from "react";
import ViewTable from "../../../../Components/Common/ViewTable";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { get } from "../../../../utils/api";
import Cards from "../../../../Components/Common/Cards";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterModal from "../../../../Components/Common/FilterModal";
import Form from "./Form";
import View from "./View";

const ShipTransport = () => {
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [selectedValue, setSelectedValue] = useState(null);

  const [filterOrigin, setFilterOrigin] = useState([]);
  const [filterDestination, setFilterDestination] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);

  const headers = [
    { key: "createdAt", label: "Date", sortable: true },
    { key: "facilityName", label: "Facility", sortable: true },
    { key: "origin", label: "Source", sortable: true },
    {
      key: "destination",
      label: "Destination",
      sortable: true,
    },
    {
      key: "categoryOfShipment",
      label: "Shipment Category",
      sortable: true,
    },
    {
      key: "typeOfShipment",
      label: "Shipment Type",
      sortable: true,
    },
    {
      key: "freightLoad",
      label: "Distance Travelled (Km)",
      sortable: true,
    },
  ];

  let bannerData = [
    {
      title: "Most Common Water Route",
      description: ["Port of chennai", "Port of shri lanka"],
    },
    {
      title: "Total Freight Load",
      description: "10000 MT",
    },
    {
      title: "Total Distance Travelled",
      description: "1000 Km",
      filter: true,
      filterTitle: "Shipment Category",
      filterValues: ["Liquid Shipment", "Dry Shipment"],
    },
  ];

  const filterData = [
    { title: "Date", type: "date", key: "createdAt", value: [] },
    {
      title: "Origin",
      type: "option",
      key: "origin",
      value: filterOrigin,
    },
    {
      title: "Destination",
      type: "option",
      key: "destination",
      value: filterDestination,
    },
    {
      title: "Freight Load (Tonnes)",
      type: "limit",
      key: "load",
      value: ["one", "two"],
    },
  ];

  const fetchData = async () => {
    let filter = { ...filterObj };
    if (filter.load) {
      filter.minLoad = parseInt(filterObj.load.min);
      filter.maxLoad = parseInt(filterObj.load.max);
    }
    const queryParams = new URLSearchParams({
      ...filter,
      page: currentPage,
      limit: 1,
      sortField: sortConfig.key,
      sortOrder: sortConfig.direction,
    }).toString();
    await get(`/scope3/shipTransportation/get?${queryParams}`)
      .then((res) => {
        console.log(res?.data);
        let arr = res?.data?.data?.map((item) => {
          return {
            createdAt: new Date(item.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
            facilityName: item?.facility?.facilityName,
            origin: item?.origin,
            destination: item?.destination,
            categoryOfShipment: item?.categoryOfShipment,
            typeOfShipment: item?.typeOfShipment,
            freightLoad: item?.freightLoad,
            remark: item?.remark,
            action: "View",
            id: item?._id,
            data: item,
          };
        });
        setTableData(arr);
        setPageSize(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [sortConfig, filterObj]);

  const fetchFilters = async () => {
    await get(`/scope3/shipTransportation/ship/filter`)
      .then((res) => {
        console.log(res?.data);
        setFilterOrigin(res?.data?.data?.origin);
        setFilterDestination(res?.data?.data?.destination);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      console.log("data", dataForEdit);
      dataForEdit.createdAt = dataForEdit?.data?.createdAt.split("T")[0];
      setEditData(dataForEdit);
    }
  };

  const openViewModal = (data) => {
    console.log("data12", data);
    data.createdAt = data?.data?.createdAt.split("T")[0];
    setEditData(data);
    setViewModal(true);
  };

  const handleSort = (key, direction) => {
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (newFilterObj) => {
    setFilterObj(newFilterObj);
    console.log("Applied Filters:", newFilterObj);
  };

  console.log("editdata", editData);

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleSubmit = (data) => {
    console.log("Form submitted:", data);
    setIsModalOpen(false);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  console.log("filere", selectedValue);

  return (
    <>
      <Cards
        data={bannerData}
        title={"Ship Transport"}
        onFilterChange={handleSelectChange}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "1rem",
          gap: "1rem",
        }}
      >
        <Button
          style={{
            backgroundColor: "#fff",
            color: "#000000",
            borderRadius: "4px",
            gap: "1rem",
          }}
          onClick={() => setIsFilterModalOpen(true)}
        >
          Filter <FilterListIcon />
        </Button>
        <Button
          style={{
            backgroundColor: "#1C486B",
            color: "#ffffff",
            borderRadius: "4px",
            gap: "0.5rem",
          }}
          onClick={() => openModal("add")}
        >
          Add Data
          <AddIcon />
        </Button>
      </div>

      <ViewTable
        headers={headers}
        data={tableData ?? []}
        onSort={handleSort}
        sortConfig={sortConfig}
        page={currentPage}
        count={pageSize}
        onPageChange={handlePageChange}
        onView={(row) => {
          openViewModal(row);
        }}
        onEdit={(row) => openModal("edit", row)}
        onDelete={() => {
          console.log("first");
        }}
      />

      <Form
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        header={editModal ? "Edit Data" : "Add Data"}
        initialData={editData}
        isEditing={editModal}
      />

      <View
        isOpen={viewModal}
        onClose={() => setViewModal(false)}
        header={"View Data"}
        initialData={editData}
        handleEdit={(row) => openModal("edit", row)}
      />

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        filters={filterData}
        onFilterChange={handleFilterChange}
        title="Filter"
      />
    </>
  );
};

export default ShipTransport;
