import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddEquipment from "./Add";
import DocumentItem from "../../../../Components/Masters/DocumentItem";

function ViewEquipment(props) {
  const [edit, setEdit] = useState(false);
  const [valueEdit, setValueEdit] = useState(false);
  const [addEquipment, setAddEquipment] = useState(false);
  useEffect(() => {
    console.log(props.selectedEquipment, "selectedEquipment");
  }, [props]);

  const selectedEquipment = props.selectedEquipment || {};

  useEffect(() => {
    console.log("Selected Equipment:", selectedEquipment);
  }, [selectedEquipment]);

  const handleDownload = (url) => {
    const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/"); // Force download as an attachment
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const Edit = () => {
    setAddEquipment(true);
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 420,
        // maxWidth:"100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-btween",
        backgroundColor: "transparent",
      }}
    >
      <AddEquipment
        // addFacility={addFacility}
        // setAddFacility={setAddFacility}
        addEquipment={addEquipment}
        setAddEquipment={setAddEquipment}
        selectedEquipment={props.selectedEquipment}
      />
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setViewEquipment(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: "24px",
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Typography
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          Natural Gas Combustion Detail
        </Typography>

        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
          {/* Date and Industrial Process */}
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Date
            </Typography>
            <Typography>
              {new Date(props.selectedEquipment?.createdAt).toLocaleDateString(
                "en-GB",
                { day: "2-digit", month: "2-digit", year: "numeric" }
              ) ?? ""}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Industrial Process
            </Typography>
            <Typography>
              {props.selectedEquipment?.industrialProcess.industryProcess ?? ""}
            </Typography>
          </Box>

          {/* Equipment Type and Type of Entry */}
          {/* <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Equipment Type</Typography>
            <Typography>{props.selectedEquipment?.equipmentType.name ?? ""}</Typography>
          </Box> */}
          <Box sx={{ gridColumn: "1 / -1" }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Equipment Type
            </Typography>
            <Typography>
              {props.selectedEquipment?.equipmentType.name ?? ""}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Quantity Consumed</Typography>
            {/* <Typography>{props.selectedEquipment?.quantityOfNaturalGasConsumed ?? ""}</Typography> */}
            <Typography>{`${props.selectedEquipment?.quantityOfNaturalGasConsumed.value || ''} ${props.selectedEquipment?.quantityOfNaturalGasConsumed.unit || ''}`}</Typography>

            
          </Box>

  
        

          {props.selectedEquipment?.typeOfEntry?.toLowerCase() ===
            "individual" && (
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Hours Used
              </Typography>
              <Typography>{props.selectedEquipment?.hours ?? ""}</Typography>
            </Box>
          )}

          {/* Calorific Value */}
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Calorific Value
            </Typography>
            <Typography>
              {props.selectedEquipment?.calorificValue?.value ?? ""}{" "}
              {props.selectedEquipment?.calorificValue?.unit ?? ""}
            </Typography>
          </Box>
        </Box>
        {/* Remarks */}
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 4 }}>
            Remarks
          </Typography>
          <Typography>{props.selectedEquipment?.remarks ?? ""}</Typography>
        </Box>

        {/* <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 4 }}>
          Documents
        </Typography>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {props.selectedEquipment?.documents?.map((doc, index) => (
            <Box key={index} sx={{ position: "relative" }}>
              <img
                src={doc}
                alt={`Document ${index + 1}`}
                style={{
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
              <IconButton
                onClick={() => handleDownload(doc)}
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  color: "white",
                  backgroundColor: "rgba(0,0,0,0.5)",
                }}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
          ))}
        </Box> */}

<Box sx={{mt:2}}>
        <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold" }}
                  >
                    Documents
                  </Typography>

                  
                  <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{ width: "100%" }}
          >
            {/* Render Images First */}
            {props.selectedEquipment && props.selectedEquipment?.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedEquipment?.documents
                  .filter((url) =>
                    ["png", "jpg", "jpeg"].includes(
                      url.split(".").pop().toLowerCase()
                    )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`image-${index}`} url={url} />
                  ))}
              </Box>
            )}

            {/* Render Other Documents */}
            {props.selectedEquipment && props.selectedEquipment?.documents.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}>
                {props.selectedEquipment?.documents
                  .filter(
                    (url) =>
                      !["png", "jpg", "jpeg"].includes(
                        url.split(".").pop().toLowerCase()
                      )
                  )
                  .map((url, index) => (
                    <DocumentItem key={`file-${index}`} url={url} />
                  ))}
              </Box>
            )}
          </Box>
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={props.handleEdit}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.viewEquipment}
        onClose={() => props.setViewEquipment(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default ViewEquipment;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
