import React, { useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";
import { getNestedValue } from "../../../utils/getNestedValue";
import {ProgressContext} from "../ContextAPI";
import {
  numberError,
  percentageError,
  textError,
} from "../../../utils/validationMessage";
import {
  validateNumber,
  validatePercentage,
} from "../../../utils/validateFields";

const SustainableSourcing = () => {
  const navigate = useNavigate();
  const { setUpdate } = useContext(ProgressContext);

  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    percentageOfSustainableMaterialUsed: { value: "" },
    supplierEnvironmentalAssesments: { number: "", percentage: "" },
    initiativesforSustainableSourcing: { number: "", impact: "" },
    companyId,
  });

  // Track errors
  const [errors, setErrors] = useState({});

  // Sustainable data for creating
  const sustainableSourcingData = [
    {
      title: "Percentage of Sustainable Materials Used",
      placeholder: "10",
      name: "percentageOfSustainableMaterialUsed.value",
      adornmentText: "%",
    },
    {
      title: "Supplier Environmental Assessments",
      nestedData: [
        {
          name: "supplierEnvironmentalAssesments.number",
          placeholder: "Number of suppliers assessed",
          // adornmentText: "Number",
        },
        {
          name: "supplierEnvironmentalAssesments.percentage",
          placeholder: "% of suppliers assessed",
          adornmentText: "%",
        },
      ],
    },
    {
      title: "Initiatives for Sustainable Sourcing",
      nestedData: [
        {
          placeholder: "Number of Projects",
          name: "initiativesforSustainableSourcing.number",
          // adornmentText: "Number",
        },
        {
          placeholder: "Impact of Projects",
          name: "initiativesforSustainableSourcing.impact",
          // adornmentText: "Impact",
        },
      ],
    },
  ];

  // Navigating back
  const Back = () => {
    navigate("/environment/pollution");
  };

  const validateForm = () => {
    const {
      percentageOfSustainableMaterialUsed,
      supplierEnvironmentalAssesments,
      initiativesforSustainableSourcing,
    } = formData;

    const newErrors = {};

    // Validate the first input field
    if (!validatePercentage(percentageOfSustainableMaterialUsed.value)) {
      newErrors["percentageOfSustainableMaterialUsed.value"] = percentageError;
    }

    // Validate the other fields
    if (!validateNumber(supplierEnvironmentalAssesments.number)) {
      newErrors["supplierEnvironmentalAssesments.number"] = numberError;
    }

    if (!validatePercentage(supplierEnvironmentalAssesments.percentage)) {
      newErrors["supplierEnvironmentalAssesments.percentage"] = percentageError;
    }

    if (!validateNumber(initiativesforSustainableSourcing.number)) {
      newErrors["initiativesforSustainableSourcing.number"] = numberError;
    }

    if (initiativesforSustainableSourcing.impact === "") {
      newErrors["initiativesforSustainableSourcing.impact"] = textError;
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Fetching data if already exists
  useEffect(() => {
    setUpdate("ESG");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/sustainable-sourcing-and-supply-chain/${companyId}`
        );
        setFormData(response.data.sustainableSourcingData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // Save the formData and make the post request
  const Save = async () => {
    console.log(formData);
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/sustainable-sourcing-and-supply-chain${
          isExist ? `/${companyId}` : ""
        }`,
        data: { ...formData, companyId },
      });
      navigate("/social/employee-development");
    } catch (err) {
      console.log(err.response.data.message);
      // alert(err.response.data.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [prefix, key] = name.split(".");

    setFormData((prevFormData) => {
      // Special handling for the first input field
      return {
        ...prevFormData,
        [prefix]: {
          ...prevFormData[prefix],
          [key]: value ,
        },
      };
    });

    // Clear the error for the field being updated
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#EDEDED",
        height: "100vh",
        overflow: "hidden", // Ensure no content gets hidden by scrollbars
      }}
    >
      {" "}
      <Sidebar title="Sustainable Sourcing and Supply Chain" />
      <Box
        sx={{
          p: 2,
          mt: 3,
          width: "100%",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Adjust to start at the top of the box
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            zIndex: 1,
            padding: "10px",
            borderRadius: "4px",
            alignSelf: "flex-start",
            ml: "5rem",
          }}
        >
          ENVIRONMENT:SUSTAINABLE SOURCING & SUPPLY CHAIN
        </Typography>
        <Box sx={{ mt: 3, width: "80%" }}>
          {/* Maps the sustainable data */}
          {sustainableSourcingData.map((item, index) => (
            <React.Fragment key={index}>
              {item.nestedData ? (
                <Box key={index} sx={{ mt: 3 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {item.title}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      gap: 1,
                      display: "flex", // Display items in the same line
                      alignItems: "center",
                    }}
                  >
                    {item.nestedData.map((el, i) => (
                      <Box key={i} sx={{ flex: 1, mb: "-10px" }}>
                        <InputCard
                          placeholder={el.placeholder}
                          name={el.name}
                          value={getNestedValue(formData, el.name)}
                          handleChange={handleChange}
                          adornmentText={el.adornmentText}
                          borderColor={errors[el.name] && "#D34040"}
                          multiline={
                            el.name ===
                            "initiativesforSustainableSourcing.impact"
                          }
                          error={errors[el.name]} // Pass error state
                        />
                        <Box
                          sx={{
                            minHeight: "20px", // Reserve space for the error message
                            display: "flex",
                            mt: 1,
                          }}
                        >
                          {errors[el.name] && (
                            <Typography variant="body2" color="error">
                              {errors[el.name]}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box key={index}>
                  <InputCard
                    title={item.title}
                    placeholder={item.placeholder}
                    name={item.name}
                    value={getNestedValue(formData, item.name)}
                    borderColor={errors[item.name] && "#D34040"}
                    handleChange={handleChange}
                    adornmentText={item.adornmentText}
                    error={errors[item.name]} // Pass error state
                  />
                  {errors[item.name] && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                      {errors[item.name]}
                    </Typography>
                  )}
                </Box>
              )}
            </React.Fragment>
          ))}
          {/* Back, Save Buttons */}
          <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};

export default SustainableSourcing;
