

// import React, { useState } from 'react';
// import { Card, CardContent, Typography, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';

// const CustomCard = ({ title, value, unit, dropdowns = [], width, marginTop }) => {
//   // State to track selected values for each dropdown
//   const [selectedValues, setSelectedValues] = useState(dropdowns.map(() => ''));

//   // Function to handle change in dropdown selection
//   const handleChange = (index, newValue) => {
//     const updatedValues = [...selectedValues];
//     updatedValues[index] = newValue;
//     setSelectedValues(updatedValues);
//   };

//   return (
//     <Card sx={{ width: width, display: "flex", flexDirection: "column", height: "140px", borderRadius: "10px" }}>
//       <CardContent>
//         <Box display="flex" alignItems="center" justifyContent="space-between">
//           <Typography component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
//             {title}
//           </Typography>
//           {dropdowns.map((dropdown, index) => (
//             <FormControl variant="outlined" size="small" sx={{ marginLeft: 1, width: '80%' }} key={index}>
//               {!selectedValues[index] && <InputLabel>{dropdown.label}</InputLabel>}
//               <Select
//                 value={selectedValues[index]}
//                 onChange={(e) => handleChange(index, e.target.value)}
//               >
//                 {dropdown.options.map((option) => (
//                   <MenuItem key={option} value={option}>
//                     {option}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           ))}
//         </Box>
//         <Typography color="text.primary" sx={{ marginTop: marginTop }}>
//           {value} {unit}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

// export default CustomCard;



import React, { useState } from 'react';
import { Card, CardContent, Typography, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';

const CustomCard = ({ title, value, unit, dropdowns = [], width, marginTop }) => {
  const [selectedValues, setSelectedValues] = useState(dropdowns.map(() => ''));

  const handleChange = (index, newValue) => {
    const updatedValues = [...selectedValues];
    updatedValues[index] = newValue;
    setSelectedValues(updatedValues);
  };

  // Define menu properties to limit visible items and add a scroll bar
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 3 + 6, // Each item is about 48px in height, adjust as necessary
        width: 80,
      },
    },
  };

  return (
    <Card sx={{ width: width, display: "flex", flexDirection: "column", height: "140px", borderRadius: "10px" }}>
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
            {title}
          </Typography>
          {dropdowns.map((dropdown, index) => (
            <FormControl variant="outlined" size="small" sx={{ marginLeft: 1, width: '80%' }} key={index}>
              {!selectedValues[index] && <InputLabel>{dropdown.label}</InputLabel>}
              <Select
                value={selectedValues[index]}
                onChange={(e) => handleChange(index, e.target.value)}
                MenuProps={MenuProps}
              >
                {dropdown.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </Box>
        <Typography color="text.primary" sx={{ marginTop: marginTop }}>
          {value} {unit}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CustomCard;

