import { Box, IconButton } from '@mui/material'
import React from 'react'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const MenuIcon = ({handleMenuClick,field}) => {
  return (
    <Box
    sx={{
      padding: "1px",
      textAlign: "center",
    }}
  >
    <IconButton onClick={(e) => handleMenuClick(e, field)}>
      <MoreHorizIcon sx={{ fontWeight: "bold", color: "black" }} />
    </IconButton>
  </Box>
  )
}

export default MenuIcon